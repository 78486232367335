import React, { useState, FormEvent } from 'react';
import { Container, WrapperInput, ButtonPlus } from './styles';
import { SearchIcon, PlusIcon } from '../../../styles/icons';

import { IFuncVoid } from '../../../interfaces';

interface FilterPlusProps{
  handleSearch(filter: string):void;
  handlePlus?: IFuncVoid;
  border?: boolean;
}

const FilterPlus: React.FC<FilterPlusProps> = ({
  children, border = false, handleSearch, handlePlus,
}) => {
  const [filter, setFilter] = useState('');

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    handleSearch(filter);
  }

  return (
    <Container border={border}>
      <form onSubmit={handleSubmit}>
        <WrapperInput>
          <input
            type="search"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <button type="submit">
            <SearchIcon />
          </button>
        </WrapperInput>
        {children}
      </form>
      {handlePlus && (
      <ButtonPlus onClick={handlePlus}>
        <PlusIcon />
      </ButtonPlus>
      )}

    </Container>

  );
};

export default FilterPlus;
