import styled, { css } from 'styled-components';

interface Props{
  size: 'large' | 'medium' | 'small';
  color: 'primary' | 'secondary' ;
  isLoading?: boolean;
  disableForce?: boolean;
}

export const Container = styled.button<Props>`
      width: 100%;
      height: 40px;
      border: 0;
      border-radius: 4px;
      padding: 0 16px;

      font-size: 1.1rem;
      font-weight: 400;
      letter-spacing: .1rem;

      color: var(--color-white);

      ${(props) => props.isLoading && css`cursor: not-allowed;`}

      background: #2c2f38;
      ${(props) => props.color === 'secondary' && css`background: var(--color-button-secondary);`}

      ${(props) => props.size === 'large' && css`height: 56px;`}

      margin-top: 16px;
      transition: background-color 0.2s;

      &:hover{
        background-color: var(--color-button-h);
        ${(props) => props.color === 'secondary' && css`background-color: var(--color-button-secondary-h);`}
      }

      ${(props) => props.disableForce && css`
        cursor: not-allowed;
        background: #999;
        &:hover{background: #999;}
      `}

`;
