import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import {
  Button,
  ButtonIcon,
  Input,
  InputHidden,
  InputMask,
  Select,
  WrapperLabel,
} from 'components/Forms';
import React, { useCallback, useRef, useState } from 'react';
import ModalFactory from 'react-modal-promise';
import { useHistory, useParams } from 'react-router-dom';
import { InfoIcon } from 'styles/icons';
import { validHours } from 'utils';
import * as Yup from 'yup';
import { ModalOption } from '../../../../components/ModalOption';
import { useHttp } from '../../../../hooks/http';
import { useToast } from '../../../../hooks/toast';
import getValidationError from '../../../../utils/getValidationsErros';
import {
  Container,
  GridButtons, WrapperBtnCancel, WrapperBtnInfo, WrapperBtnSubmit,
} from './styles';

interface ISection {
    id: string;
}

interface IFormSection {
    section: ISection;
}

const SectionTwo: React.FC<IFormSection> = ({ section }) => {
  const formRef = useRef<FormHandles>(null);
  const { httpPost } = useHttp();
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [btnLoading, setBtnLoading] = useState(false);

  const handleSubmit = useCallback(async (payload: any) => {
    const _sectionTwo = {
      ...payload,
      type_action: payload?.type_action?.value,
      type_audience: payload?.type_audience?.value,
    };

    setBtnLoading(true);

    try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
              place_action: Yup.string().required('Local da Ação é obrigatório'),
              type_action: Yup.string().required('Tipo de Ação é obrigatório'),
              type_audience: Yup.string().required('Tipo de Público é obrigatório'),
              institution_name: Yup.string().required('Nome da Instituição é obrigatório'),
              start_time: Yup.string().test('is-valid', 'Horário de Início é obrigatório, somente números', (value: any) => validHours(value)),
              final_hour: Yup.string().test('is-valid', 'Horário de Encerramento é obrigatório, somente números', (value: any) => validHours(value)),
              approach: Yup.number().typeError('Abordagens Realizadas é obrigatório, somente números'),
              tests: Yup.number().typeError('Testes Realizados é obrigatório, somente números'),
              used_caps: Yup.number().typeError('Biqueiras Utilizadas é obrigatório, somente números'),
              available_caps: Yup.number().typeError('Biqueiras Disponíveis é obrigatório, somente números'),
              distributed_folders: Yup.number().typeError('Folders Distribuídos é obrigatório, somente números'),
              cricris: Yup.number().typeError('Cricris Utilizados é obrigatório, somente números'),
              vetarolas: Yup.number().typeError('Vetarolas Utilizadas é obrigatório, somente números'),
              used_adhesives: Yup.number().typeError('Adesivos Utilizados é obrigatório, somente números'),
              sequence_certificates: Yup.number().typeError('Sequencial de Certificados é obrigatório, somente números'),
              gibis: Yup.number().typeError('Gibis Distribuídos é obrigatório, somente números'),
              distributed_certificates: Yup.number().typeError('Certificados Distribuídos obrigatório, somente números'),
            });

            await schema.validate(_sectionTwo, { abortEarly: false });

            const { status } = await httpPost('reducols/sectiontwo', _sectionTwo);

            if (status === 200) {
              const response = await ModalOption({
                title: 'ATENÇÃO',
                content: 'EXISTE OUTRO LOCAL DE AÇÃO ?',
                buttons: 'sim_nao',
              });

              if (!response) {
                setBtnLoading(false);
                addToast({ title: 'Salvo com Sucesso!', type: 'success' });
                history.push('/reducols');
              } else {
                setBtnLoading(false);
                    formRef.current?.reset();

                    formRef.current?.setData({
                      id,
                    });
              }
            } else {
              setBtnLoading(false);
              addToast({ title: 'Não foi possível salvar!', type: 'error' });
              // history.push('/dashboard');
            }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setBtnLoading(false);
        const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
      }
    }
  }, [addToast, history, httpPost, id]);

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <InputHidden name="id" defaultValue={id} />
        <Grid container spacing={1}>
          <WrapperLabel label="Tipo de Ação" xs={12} md={6}>
            <Select
              name="type_action"
              options={[
                { value: 'PALESTRA EMPRESA', label: 'PALESTRA EMPRESA' },
                { value: 'PALESTRA UNIVERSIDADE', label: 'PALESTRA UNIVERSIDADE' },
                { value: 'PALESTRA ESCOLA', label: 'PALESTRA ESCOLA' },
                { value: 'ESCOLA NOTA 10', label: 'ESCOLA NOTA 10' },
                { value: 'AÇÃO - FUTEBOL', label: 'AÇÃO - FUTEBOL' },
                { value: 'AÇÃO - ESPORTES', label: 'AÇÃO - ESPORTES' },
                { value: 'AÇÃO - BARES e COMÉRCIO', label: 'AÇÃO - BARES e COMÉRCIO' },
                { value: 'AÇÃO - OUTRAS', label: 'AÇÃO - OUTRAS' },
              ]}
            />
          </WrapperLabel>
          <WrapperLabel label="Tipo de Público" xs={12} md={6}>
            <Select
              name="type_audience"
              options={[
                { value: 'INFANTIL', label: 'INFANTIL' },
                { value: 'ADOLESCENTE', label: 'ADOLESCENTE' },
                { value: 'ADULTO', label: 'ADULTO' },
                { value: 'MISTO (no caso de ação)', label: 'MISTO (no caso de ação)' },
              ]}
            />
          </WrapperLabel>
          <WrapperLabel label="Local da Ação" xs={12}>
            <Input name="place_action" />
          </WrapperLabel>
          <WrapperLabel label="Nome da Instituição" xs={12} md={6}>
            <Input name="institution_name" maxLength={200} />
          </WrapperLabel>
          <WrapperLabel label="Horário de Início da Ação" xs={12} md={6}>
            <InputMask type="tel" mask="99:99" name="start_time" placeholder="__:__" />
          </WrapperLabel>
          <WrapperLabel label="Horário de Encerramento da Ação" xs={12} md={6}>
            <InputMask type="tel" mask="99:99" name="final_hour" placeholder="__:__" />
          </WrapperLabel>
          <WrapperLabel label="Abordagens Realizadas" xs={12} md={6}>
            <Input name="approach" maxLength={4} />
          </WrapperLabel>
          <WrapperLabel label="Testes Realizados" xs={12} md={6}>
            <Input name="tests" maxLength={4} />
          </WrapperLabel>
          <WrapperLabel label="Biqueiras Utilizadas" xs={12} md={6}>
            <Input name="used_caps" maxLength={4} />
          </WrapperLabel>
          <WrapperLabel label="Biqueiras Disponíveis (restantes)" xs={12} md={6}>
            <Input name="available_caps" maxLength={4} />
          </WrapperLabel>
          <WrapperLabel label="Folders Distribuídos" xs={12} md={6}>
            <Input name="distributed_folders" maxLength={4} />
          </WrapperLabel>
          <WrapperLabel label="Cricris Utilizados" xs={12} md={6}>
            <Input name="cricris" maxLength={4} />
          </WrapperLabel>
          <WrapperLabel label="Ventarolas Utilizadas" xs={12} md={6}>
            <Input name="vetarolas" maxLength={4} />
          </WrapperLabel>
          <WrapperLabel label="Adesivos Utilizados (Colabore)" xs={12} md={6}>
            <Input name="used_adhesives" maxLength={4} />
          </WrapperLabel>
          <WrapperLabel label="Sequencial de Certificados Emitidos" xs={12} md={6}>
            <Input name="sequence_certificates" maxLength={4} />
          </WrapperLabel>
          <WrapperLabel label="Gibis Distribuídos" xs={12} md={6}>
            <Input name="gibis" maxLength={4} />
          </WrapperLabel>
          <WrapperLabel label="Certificados Distribuídos" xs={12} md={6}>
            <Input name="distributed_certificates" maxLength={4} />
          </WrapperLabel>
        </Grid>
        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
            >
              <InfoIcon />
            </ButtonIcon>
          </WrapperBtnInfo>
          <WrapperBtnCancel>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </WrapperBtnCancel>
          <WrapperBtnSubmit>
            <Button
              type="submit"
              isLoading={btnLoading}
            >
              Avançar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>
      </UForm>
      <ModalFactory />
    </Container>
  );
};

export default SectionTwo;
