import React, {
  useEffect, useState, useCallback,
} from 'react';

import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

/** hooks */
import { useHttp } from '../../hooks/http';

/** componentes */
import Layout from '../../components/Layout';
import HeaderSavePlus from '../../components/Headers/SavePlus';
import Tabs, { TabsItem } from '../../components/Tabs';
import FormProfile from './Profile';
import FormAddress from '../../components/Addresses/Form';

import { BuildingIcon } from '../../styles/icons';
import { Container } from './styles';

/** interfaces */
import { IManagement } from '../../interfaces';

const Form: React.FC = () => {
  const { id } = useParams<{id: string}>();
  const { httpGet } = useHttp();
  const [management, setManagement] = useState<IManagement>({} as IManagement);
  const history = useHistory();

  useEffect(() => {
    async function loadManagement(): Promise<void> {
      const { data } = await httpGet(`/managements/${id}`);
      setManagement(data);
    }
    if (id) {
      loadManagement();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handlePlus = useCallback(() => {
    history.push('/management');
  }, [history]);

  const breadcrumbs = {
    icon: BuildingIcon,
    links: [
      { path: '/managements', title: 'Gerências' },
      { path: '', title: 'Gerência' },
    ],
  };
  return (

    <Layout breadcrumbs={breadcrumbs}>

      <Container>
        <HeaderSavePlus
          hiddenButton={!id}
          handlePlus={handlePlus}
        >
          {id ? 'Editar Gerência' : 'Criar Gerência'}
        </HeaderSavePlus>

        <Tabs>
          <TabsItem title="Perfil" show>
            {id && <FormProfile profile={management} />}
            {!id && <FormProfile profile={{} as IManagement} />}

          </TabsItem>
          <TabsItem title="Endereço" enable={!!id}>
            <FormAddress address={management.address} />
          </TabsItem>
        </Tabs>

      </Container>
    </Layout>
  );
};

export default Form;
