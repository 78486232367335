import React, {
  InputHTMLAttributes, useEffect, useRef, useState, useCallback,
} from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

interface InputProp extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  disabled?: boolean;
}

const Input: React.FC<InputProp> = ({
  name, disabled = false, ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const {
    fieldName, defaultValue, error, registerField,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',

      // setValue(ref: any, value: string) {
      //   ref.setInputValue(value);
      // },
      // clearValue(ref: any) {
      //   ref.setInputValue('');
      // },
    });
  }, [fieldName, registerField]);

  const handleFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Container
      readOnly={disabled}
      isErrored={!!error}
      isFocused={isFocused}
      onBlur={handleInputBlur}
    >
      <input
        ref={inputRef}
        onFocus={handleFocused}
        defaultValue={defaultValue}
        disabled={disabled}
        {...rest}

      />

      <Error title={error || ''}>
        <FiAlertCircle color="#c53030" size={20} />
      </Error>

    </Container>
  );
};

export default Input;
