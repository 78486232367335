import React, { useState, useEffect } from 'react';
import { useHttp } from '../../../hooks/http';
import Layout from '../../../components/Layout';
import HeaderFilterPlus from '../../../components/Headers/FilterPlus';
import Card from './Card';
import { ListIcon } from '../../../styles/icons';
import { Container } from './styles';
import { useAuth } from 'hooks/auth';

const Form: React.FC = () => {
    const [mrcols, setMrcols] = useState([]);
    const { httpGet, loading } = useHttp();
    const { user } = useAuth();

    const breadcrumbs = {
        icon: ListIcon,
        links: [
            { title: `LeiSeca Mrcols`, path: '' },
        ],
    };

    useEffect(() => {
        async function loadMrcols(): Promise<void> {
            const { status, data } = await httpGet('rcols/list', {
                params: {
                    user,
                },
            });
            if (status === 200) {
                setMrcols(data);
            }
        }

        loadMrcols();

    }, []); // eslint-disable-line

    return (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
            <Container>
                <HeaderFilterPlus handleSearch={() => { }} />
                {mrcols && mrcols.map((mrcol: any) => (
                    <Card key={mrcol.id} mrcol={mrcol} />
                ))}
            </Container>
        </Layout>
    );
};

export default Form;
