import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Form as UForm } from '@unform/web';
import { FormHandles } from '@unform/core';
/** hooks */
import { useHttp } from '../../hooks/http';

/** components */
import Layout from '../../components/Layout';
import { Grid } from '@material-ui/core';
import { Button, Select, WrapperLabel } from 'components/Forms';

import { Container, Content } from './styles';
import { IPaginate, IMake, IModel } from '../../interfaces';
import { ClipboardCopyIcon } from 'styles/icons';
import Pagination from 'components/Pagination';
import Card from './Card';

const Makes: React.FC = () => {
    const [makes, setMakes] = useState<IMake[]>([]);
    const [models, setModels] = useState<IModel[]>([]);
    const formRef = useRef<FormHandles>(null);
    const [paginate, setPaginate] = useState<IPaginate>({
        page: 1, perPage: 10, lastPage: 1, total: 0,
    });

    const { httpGet } = useHttp();

    useEffect(() => {
        async function loadMake(): Promise<void> {
            const { status, data } = await httpGet('marcas', {
                params: {
                    ...paginate,
                },
            });

            if (status === 200) {
                const makeParsed = data.data
                    .map((assistance: any) => ({
                        value: assistance.id,
                        label: `${assistance.name}`,
                    }));
                setMakes(makeParsed);
                setPaginate(data.pagination);
            }
        }

        loadMake();
    }, [paginate.page]); // eslint-disable-line

    const handleSubmit = useCallback(async (payload: any) => {
        const { make } = payload
        const { status, data } = await httpGet(`marcas/modelos/${make.value}`, {
            params: {
                ...paginate,
            },
        });

        if (status === 200) {
            setModels(data);
        }
    }, [httpGet, paginate]);

    const handledPagination = useCallback((payload: IPaginate) => {
        setPaginate(payload);
    }, []);

    const breadcrumbs = {
        icon: ClipboardCopyIcon,
        links: [
            { title: `${paginate.total} Marcas`, path: '' },
        ],
    };
    return (
        <Layout breadcrumbs={breadcrumbs}>
            <Container>
                <Content>
                    <UForm ref={formRef} onSubmit={handleSubmit}>
                        <Grid container spacing={1} justify="center">
                            <WrapperLabel label="Marca" xs={12} sm={6} md={6}>
                                <Select
                                    name="make"
                                    options={makes}
                                />
                            </WrapperLabel>
                        </Grid>
                        <Grid container justify="center">
                            <Grid item xs={6}>
                                <Button type="submit">Buscar</Button>
                            </Grid>
                        </Grid>
                    </UForm>
                </Content>
                <Grid container spacing={1}>
                    {models && models.map((model: IModel) => (
                        <Card
                            key={model.id}
                            make={model}
                        />
                    ))}
                </Grid>
                <Pagination paginate={paginate} handlePaginate={handledPagination} />
            </Container>
        </Layout>
    );
}

export default Makes;