import React, { useState, useEffect } from 'react';

/** hooks */
import { useHttp } from '../../hooks/http';

/** components */
import Layout from '../../components/Layout';
import HeaderFilterPlus from '../../components/Headers/FilterPlus';
import Pagination from '../../components/Pagination';
import Card from './Card';

import { BuildingIcon } from '../../styles/icons';

import { Container } from './styles';

/** interfaces */
import { IPaginate } from '../../interfaces';

const Managements: React.FC = () => {
  const [managements, setManagements] = useState([]);
  const [filter, setFilter] = useState('');

  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const { httpGet, loading } = useHttp();

  const breadcrumbs = {
    icon: BuildingIcon,
    links: [
      { title: `${paginate.total} Gerências`, path: '' },
    ],
  };

  useEffect(() => {
    async function loadManagements(): Promise<void> {
      const { status, data } = await httpGet('managements', {
        params: {
          filter,
          ...paginate,
        },
      });

      if (status === 200) {
        setManagements(data.data);
        setPaginate(data.pagination);
      }
    }

    loadManagements();

  }, [filter, paginate.page]); // eslint-disable-line

  const handleSearch = (value: string): void => {
    setPaginate({ ...paginate, page: 1 });
    setFilter(value);
  };

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderFilterPlus handleSearch={handleSearch} />

        {managements && managements.map((management: any) => (
          <Card key={management.id} management={management} />
        ))}

        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default Managements;
