import React, {
  useState,
  useEffect,
} from 'react';
import { useParams } from 'react-router';
import Grid from '@material-ui/core/Grid';

/** hooks */
import { useHttp } from '../../../../hooks/http';
import { useAuth } from '../../../../hooks/auth';

/** Componentes */
import Layout from '../../../../components/Layout';
import HeaderFilterPlus from '../../../../components/Headers/FilterPlus';
import Card from '../Card';
import Pagination from '../../../../components/Pagination';

import {
  ListIcon,
} from '../../../../styles/icons';

import { Container } from './styles';

/** interfaces */
import { IProximity, IPaginate } from '../../../../interfaces';

const List: React.FC = () => {
  const { type } = useParams<{type: string}>();
  const [proximities, setProximities] = useState([]);
  const [filter, setFilter] = useState('');
  const { user } = useAuth();

  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const { httpGet, loading } = useHttp();

  const breadcrumbs = {
    icon: ListIcon,
    links: [
      { title: `${paginate.total} Abord. de Proximidade`, path: '' },
    ],
  };

  useEffect(() => {
    async function load():Promise<void> {
      const { status, data } = await httpGet(`/proximities/${type}`, {
        params: {
          user,
          filter,
          ...paginate,
        },
      });
      if (status === 200) {
        setProximities(data.data);
        setPaginate(data.pagination);
      }
    }

    load();
  }, [filter, user, paginate.page, type]); // eslint-disable-line

  const handleSearch = (value: string): void => {
    setPaginate({ ...paginate, page: 1 });
    setFilter(value);
  };

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderFilterPlus handleSearch={handleSearch} />
        <Grid container spacing={1}>
          {proximities && proximities.map((proximity: IProximity) => (
            <Card
              key={proximity.id}
              proximity={proximity}
              type={type}
            />
          ))}
        </Grid>
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default List;
