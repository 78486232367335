import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 4px;
`;

export const GridButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 40px 1fr 1fr 1fr;
  grid-template-rows: 56px;
  grid-template-areas:
    'btn-inf space btn-cancel btn-submit';


  @media (max-width: 767.98px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 56px 56px;
    grid-template-areas:
      'btn-cancel btn-submit'
      'btn-inf space';
  }
`;

export const WrapperButton = styled.span`
  display: flex;
  width: 100%;
  > div{
    width: calc(100% - 48px);
    input{
      width: calc(100% - 46px);
    }
  }

  button{
    width: 40px;
  }
`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;

export const WrapperBtnCancel = styled.div`
  grid-area: btn-cancel;
`;

export const WrapperBtnSubmit = styled.div`
  grid-area: btn-submit;
`;

interface Props{
  show?: boolean;
}

export const WrapperHidden = styled.div<Props>`
  width: 100%;
  padding: 0 4px;
  ${(props) => !props.show && css`
    position: absolute;
    right: 9999px;
  `}
`;

export const WrapperModal = styled.div`
  color: #212529;
  .modal__inner{
    background: #FFCD39;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    svg {
      flex-shrink: 0;
      font-size: 1.5em;
    }
  }
  p{
    margin-bottom: 8px;
  }
  b{
    font-size: 1.1rem;
  }
  span{
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
`;

export const Red = styled.div`
  color: red !important
`;
