import Layout from 'components/Layout';
import React from 'react';
import { ClickIcon, ReadmeIcon } from '../../styles/icons';
import HeaderSimle from '../../components/Headers/Simple';
import { Container } from './styles';

const breadcrumbs = {
  icon: ReadmeIcon,
  links: [
    { path: '/pops', title: 'Procedimento Operacional Padrão' },
  ],
};

const Pops: React.FC = () => (
  <Layout breadcrumbs={breadcrumbs}>
    <Container>
      <HeaderSimle>
        POP-301 ACESSO AO SISTEMA HÓRUS
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop301.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-302 AVERIGUAÇÃO DE VEÍCULO EM ATITUDE SUSPEITA
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop302.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-303 AVERIGUAÇÃO DE PESSOA EM ATITUDE SUSPEITA
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop303.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-304 ABORDAGEM DE PROXIMIDADE
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop304.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-307 E-MAIL INSTITUCIONAL SEGOV.RJ.GOV.BR
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop307.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-308 PERMISSÕES DO SISTEMA ELETRÔNICO DE INFORMAÇÕES - SEI/SEGOV
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop308.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-313 BDT DIGITAL
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop313.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-315 RETIRADA E DEVOLUÇÃO DA CÂMERA OPERACIONAL PORTÁTIL
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop315.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-316 UTILIZAÇÃO DA CÂMERA OPERACIONAL PORTÁTIL
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop316.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-317 ORIENTAÇÕES SOBRE O NÃO ACIONAMENTO DO MODO OCORRÊNCIA NAS COPs
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop317.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-318 AÇÕES PARA RESOLUÇÃO DE PROBLEMAS NA DISTRIBUIÇÃO DAS COPs
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop318.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-319 VERIFICAÇÃO DOS SISTEMAS DAS COPs
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop319.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
      <HeaderSimle>
        POP-320 CADASTRO DE USUÁRIO E SENHA PARA ACESSO À REDE SEM FIO SEGOV
        <a
          href="https://popshorus.s3.sa-east-1.amazonaws.com/pop320.pdf"
          target="_blank"
          rel="noopener noreferrer"
        > <ClickIcon size={24} />
        </a>
      </HeaderSimle>
    </Container>
  </Layout>
);

export default Pops;
