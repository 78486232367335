import React from 'react';
import { formatDateTime, formatCpf } from '../../../../utils';

import Flex, { Grid } from '../../../../components/Flex';
import { Container } from './styles';

interface ICard{
  occurrence: any;
}
const Card: React.FC<ICard> = ({ occurrence }) => (
  <Container>
    <Grid container spacing={1}>
      <Flex title="Ocorrência" textAutoSize xs={6}>
        {occurrence.id}
      </Flex>
      <Flex title="Data" textAutoSize xs={3}>
        {formatDateTime(occurrence.created_at)}
      </Flex>
      <Flex title="Resultado" textAutoSize xs={3}>
        {occurrence.result}
      </Flex>
      <Flex title="Pessoa" textAutoSize xs={6}>
        {occurrence.name}
      </Flex>
      <Flex title="CPF" textAutoSize xs={3}>
        {formatCpf(occurrence.document)}
      </Flex>
      <Flex title="RG" textAutoSize xs={3}>
        {occurrence.document_secondary}
      </Flex>
    </Grid>
  </Container>
);

export default Card;
