import { AxiosError } from 'axios';
import { isNetworkError } from '../../services/api';
import session from '../../utils/session';

export interface IToastMessage {
  type?: 'info' | 'success' | 'error';
  title: string;
  description?: string;
  time?: number;
  link?: { url: string; title: string };
}

interface IHandleAxiosError {
  toasts: IToastMessage[];
  isSignOut: boolean;
  isReturn: boolean;
  error: AxiosError;
}

const handleAxiosError = (error: AxiosError): IHandleAxiosError => {
  const token = session.get('token');
  const toasts: IToastMessage[] = [];

  let isSignOut = false;
  let isReturn = false;

  if (isNetworkError(error)) {
    toasts.push({
      type: 'error',
      title: 'Erro na conexão',
      description: 'Você está sem acesso à internet ou o servidor está off-line.',
    });

    isReturn = true;
  }

  if (error.response?.status === 301) {
    const { data } = error.response;

    if (data.message) {
      toasts.push({
        type: 'error',
        title: data.message,
        time: 20,
        link: data.link || undefined,
      });
    }
  }

  if (error.response?.status === 400) {
    if (error.response.data?.errors) {
      error.response.data?.errors.forEach((item: any) => {
        toasts.push({
          type: 'error',
          title: item.message,
        });
      });
    } else if (error.response?.status === 400) {
      toasts.push({
        type: 'error',
        title: error.response.data.message,
        time: error.response.data.time || 5,
      });
    }
  }

  if (error.response?.status === 401) {
    if (error.response.data.message) {
      toasts.push({
        type: 'error',
        title: error.response.data.message,
        time: error.response.data?.time || 5,
      });
    } else {
      toasts.push({
        type: 'info',
        title: 'Sessão expirada.',
        description: 'Sua sessão expirou, ou você foi desconectado. Faça login outra vez.',
      });
    }

    isSignOut = true;
  }

  if (error.response?.status === 403) {
    if (token) {
      toasts.push({
        type: 'error',
        title: 'Sem permissão',
        description: 'Você tentou acessar um recurso o qual não possui permissão.',
      });
    }
  }

  if (error.response?.status === 405) {
    if (error.response.data.message) {
      toasts.push({
        type: 'error',
        title: error.response.data.message,
        time: error.response.data?.time || 5,
      });
    }

    isReturn = true;
  }

  if (error.response?.status === 429) {
    toasts.push({
      type: 'error',
      title: 'Atenção',
      description: 'Você está fazendo muitas requisições para o servidor.',
    });
  }
  if (error.response?.status === 500) {
    toasts.push({
      type: 'error',
      title: 'Erro',
      description: 'O servidor não conseguiu processar sua requisição.',
    });
  }

  return {
    toasts,
    isSignOut,
    isReturn,
    error,
  };
};

export default handleAxiosError;
