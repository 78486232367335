const brandCars = [
  { value: 'Sem Marca', label: 'Sem Marca' },
  { value: 'Abarth', label: 'Abarth' },
  { value: 'Aiways', label: 'Aiways' },
  { value: 'Alfa Romeo', label: 'Alfa Romeo' },
  { value: 'Alpine', label: 'Alpine' },
  { value: 'Aston Martin', label: 'Aston Martin' },
  { value: 'Audi', label: 'Audi' },
  { value: 'Avelloz', label: 'Avelloz' },
  { value: 'Bentley', label: 'Bentley' },
  { value: 'BMW', label: 'BMW' },
  { value: 'BRP', label: 'BRP' },
  { value: 'Bull', label: 'Bull' },
  { value: 'CFMoto', label: 'CFMoto' },
  { value: 'Citroën', label: 'Citroën' },
  { value: 'Cupra', label: 'Cupra' },
  { value: 'Dacia', label: 'Dacia' },
  { value: 'Dafra', label: 'Dafra' },
  { value: 'DS', label: 'DS' },
  { value: 'Ducati', label: 'Ducati' },
  { value: 'Ferrari', label: 'Ferrari' },
  { value: 'Fiat', label: 'Fiat' },
  { value: 'Ford', label: 'Ford' },
  { value: 'Haojue', label: 'Haojue' },
  { value: 'Harley-Davidson', label: 'Harley-Davidson' },
  { value: 'Honda', label: 'Honda' },
  { value: 'Hyundai', label: 'Hyundai' },
  { value: 'Jaguar', label: 'Jaguar' },
  { value: 'JEEP', label: 'JEEP' },
  { value: 'Kawasaki', label: 'Kawasaki' },
  { value: 'KIA', label: 'KIA' },
  { value: 'KTM', label: 'KTM' },
  { value: 'Kymco', label: 'Kymco' },
  { value: 'Lamborghini', label: 'Lamborghini' },
  { value: 'Land Rover', label: 'Land Rover' },
  { value: 'Lexus', label: 'Lexus' },
  { value: 'LL Motors', label: 'LL Motors' },
  { value: 'Lotus', label: 'Lotus' },
  { value: 'Maserati', label: 'Maserati' },
  { value: 'Mazda', label: 'Mazda' },
  { value: 'Mercedes-Benz', label: 'Mercedes-Benz' },
  { value: 'MG', label: 'MG' },
  { value: 'MINI', label: 'MINI' },
  { value: 'Mitsubishi', label: 'Mitsubishi' },
  { value: 'Motocar', label: 'Motocar' },
  { value: 'Motorino', label: 'Motorino' },
  { value: 'Nissan', label: 'Nissan' },
  { value: 'Opel', label: 'Opel' },
  { value: 'Peugeot', label: 'Peugeot' },
  { value: 'Polestar', label: 'Polestar' },
  { value: 'Porsche', label: 'Porsche' },
  { value: 'Renault', label: 'Renault' },
  { value: 'Royal Enfield', label: 'Royal Enfield' },
  { value: 'SEAT', label: 'SEAT' },
  { value: 'Shineray', label: 'Shineray' },
  { value: 'Škoda', label: 'Škoda' },
  { value: 'Smart', label: 'Smart' },
  { value: 'Sousa', label: 'Sousa' },
  { value: 'Suzuki', label: 'Suzuki' },
  { value: 'TESLA', label: 'TESLA' },
  { value: 'Toyota', label: 'Toyota' },
  { value: 'Traxx', label: 'Traxx' },
  { value: 'Triumph', label: 'Triumph' },
  { value: 'TVS', label: 'TVS' },
  { value: 'Vespa', label: 'Vespa' },
  { value: 'Vmoto', label: 'Vmoto' },
  { value: 'Volkswagen', label: 'Volkswagen' },
  { value: 'Voltz', label: 'Voltz' },
  { value: 'Volvo', label: 'Volvo' },
  { value: 'Yamaha', label: 'Yamaha' },
];

export default brandCars;
