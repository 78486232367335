import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 64px;
  padding: 0 16px;
  margin: 4px 0;
  display:flex;
  justify-content: end;
  align-items: center;
  background: #fff;

  flex-shrink:0;
  border-radius: 4px;
  margin-bottom: 8px;


  @media (max-width: 767.98px) {
      form{
        width: 100%;
      }
    }
`;
