import React, {
  InputHTMLAttributes, useEffect, useRef,
} from 'react';
import { useField } from '@unform/core';

import styled from 'styled-components';

interface InputProp extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const WrapperHidden = styled.span`
  display:none;
`;

const InputHidden: React.FC<InputProp> = ({ name, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    fieldName, defaultValue, registerField,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <WrapperHidden>
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
    </WrapperHidden>

  );
};

export default InputHidden;
