import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import Loading from 'components/Loading';
import api, { isNetworkError } from '../../services/api';
import getValidationError from '../../utils/getValidationsErros';
import {
  InputHidden, Select, TextArea, WrapperLabel,
} from '../../components/Forms';
import Button from '../../components/Forms/Button';
import logoImg from '../../assets/logo_osp_header.png';
import {
  Container, Content, Background, AnimationContainer,
} from './styles';

/** hooks */
import { useToast } from '../../hooks/toast';

interface ResetData {
 value: number;
 label: string;
}

interface ResetOption {
  value: string;
  label: string;
}

interface IPorps {
  proximity_id: string,
  sla: ResetData,
  qos: ResetData,
  si: ResetOption,
  mc: ResetOption,
  comment: string,
}

const Assessments: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const { addToast } = useToast();
  const history = useHistory();

  const [optionStates] = useState<ResetData[]>([
    { value: 1, label: 'Ótimo' },
    { value: 2, label: 'Bom' },
    { value: 3, label: 'Regular' },
    { value: 4, label: 'Ruim' },
  ]);
  const [optionTow] = useState<ResetOption[]>([
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);

  useEffect(() => {
    async function load(): Promise<any> {
      const { status, data } = await api.get(`/assessments/${id}`);
      if (status === 200) {
        if (data.name === 'error') {
          addToast({
            type: 'warning',
            title: 'Atenção!',
            description: 'Assistência não localizada!',
            time: 2,
          });
          setTimeout(() => window.location.replace('https://www.segurancapresente.rj.gov.br'), 2000);
        } else {
          addToast({
            title: 'Obrigado!',
            description: 'Sua Avaliação já foi concluída!',
            time: 2,
          });
          setTimeout(() => window.location.replace('https://www.segurancapresente.rj.gov.br'), 2000);
        }
      }
      if (status === 204) {
        setLoading(false);
      }
    }
    setLoading(true);
    id && load();
    !id && history.push('/');
  }, [id, history, addToast]);

  const handleSubmit = useCallback(async (dado: IPorps) => {
    const _dado = {
      proximity_id: id,
      sla: dado?.sla?.value,
      qos: dado?.qos?.value,
      si: dado?.si?.value,
      mc: dado?.mc?.value,
      comment: dado.comment,
    };

    formRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        sla: Yup.string().required('Tempo de atendimento? é obrigatório'),
        qos: Yup.string().required('O qual achou do serviço prestado? é obrigatório'),
        si: Yup.string().required('Indicaria o serviço? é obrigatório'),
        mc: Yup.string().required('O problema foi resolvido? é obrigatória'),
      });

      await schema.validate(_dado, { abortEarly: false });

      setIsLoading(true);
      const response: any = await api.post('/assessments', {
        ..._dado,
      });

      if (response.status === 200) {
        setIsLoading(false);
        addToast({
          title: 'Segurança Presente!',
          description: 'Obrigado por sua participação!',
          time: 2,
        });
        setTimeout(() => window.location.replace('https://www.segurancapresente.rj.gov.br'), 2000);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);

        return;
      }

      if (isNetworkError(err)) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Você está sem acesso à internet ou o servidor está off-line.',
        });
        return;
      }

      if (err.response && err.response.status === 400) {
        const { message } = Array.isArray(err.response.data)
          ? err.response.data[0]
          : err.response.data;
        addToast({
          type: 'error',
          title: message,
        });
      }
    }
  }, []); // eslint-disable-line

  if (loading) {
    return (
      <Loading loading />
    );
  }

  return (
    <Container>
      <Background />
      <Content>
        <div style={{ background: '#000 ', borderRadius: '5%' }}>
          <img src={logoImg} width="200" height="120" alt="DSCi" />
        </div>
        <AnimationContainer>

          <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
            <InputHidden name="proximity_id" id="proximity_id" />
            <Grid container spacing={1}>
              <h2 style={{ marginBottom: '18px' }}>Avalie seu atendimento</h2>
              <WrapperLabel label="Tempo de atendimento?" xs={12} md={12}>
                <Select
                  name="sla"
                  options={optionStates}
                />
              </WrapperLabel>
              <WrapperLabel label="O qual achou do serviço prestado?" xs={12} md={12}>
                <Select
                  name="qos"
                  options={optionStates}
                />
              </WrapperLabel>
              <WrapperLabel label="Indicaria o serviço?" xs={12} md={12}>
                <Select
                  name="si"
                  options={optionTow}
                />
              </WrapperLabel>
              <WrapperLabel label="O problema foi resolvido?" xs={12} md={12}>
                <Select
                  name="mc"
                  options={optionTow}
                />
              </WrapperLabel>
              <WrapperLabel label="Sugestões e críticas" xs={12}>
                <TextArea
                  name="comment"
                  row={4}
                />
              </WrapperLabel>
              <Button
                type="submit"
                color="secondary"
                isLoading={isLoading}
                size="medium"
              >Enviar
              </Button>
              <Link to="/signin">Voltar para login</Link>
            </Grid>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Assessments;
