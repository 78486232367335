import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const WrapperMap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before{
    content: 'aguarde...'
  }

  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-bg);
`;

export const WrapperTarget = styled.div`
  padding: 4px 6px;
  z-index: 4;
  border: 2px solid rgba(255, 1, 0, 0.1);
  margin-left: -72px;

  border-radius: 50%;
  svg {
    color: red;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;

  @media (max-width: 958px) {
    justify-content: center;
  }

  span:last-child { margin-left: 8px;}

  button{
    display:flex;
    justify-content:center;
    align-items:center;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 2px;
    background: var(--color-button);

    &:hover{
      background: var(--color-button-h);
    }

    > svg {
      color: #fff;
    }
  }
`;

export const WrapperSaveButton = styled.footer`
  @media (max-width: 958px) {
    margin-bottom: 16px;
  }
`;

export const WrapperButtonFooter = styled.footer`
  position: absolute;
  bottom: 16px;
  right: 0;
  width: 200px;
  display: flex;
  flex-grow: 1;
  z-index: 4;

  > button{
    margin-right: 24px;
  }
`;
