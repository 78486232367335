import React, { ReactNode } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import {
  Container, Main, Header, NavLink,
} from './styles';

interface LayoutProps {
  title?: string;
  loading?: boolean;
  links: any[];
  children: ReactNode;
}
const Laydash: React.FC<LayoutProps> = ({ links, children, loading = false }) => (
  <Container>
    <Header>
      {
          links.map((link) => (
            <NavLink to={link.path} key={link.key}>{link.title}</NavLink>
          ))
        }
    </Header>
    <Main isLoading={loading}>
      <article>
        <BeatLoader size={16} margin={8} color="#7F8C8D" />
      </article>
      {children}
    </Main>
  </Container>
);

export default Laydash;
