import React, {
  useRef, useEffect, useCallback,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import getValidationError from '../../../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../../../hooks/http';
import { useToast } from '../../../../../hooks/toast';

/** componentes */
import { Grid } from '../../../../../components/Flex';
import {
  WrapperLabel, Input, Button,
} from '../../../../../components/Forms';
import { Container } from './styles';

const Save: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();
  const { id } = useParams<{ id?: string}>();
  const {
    httpGet, httpPost, httpPut, loading,
  } = useHttp();

  useEffect(() => {
    (async () => {
      if (id && id !== 'new') {
        const { status, data } = await httpGet(`/upajs/${id}`);
        if (status === 200) {
          formRef.current?.setData(data);
        }
      }
    })();
  }, [httpGet, id]);

  const handleSubmit = useCallback(async (payload: any) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        initials: Yup.string().required('Sigla é obrigatória'),
        name: Yup.string().required('Nome é obrigatório'),
        institution: Yup.string().required('Instituição é obrigatória'),
      });

      await schema.validate(payload, { abortEarly: false });

      if (id && id !== 'new') {
        await httpPut(`/upajs/${id}`, payload);
        addToast({ title: 'Editado com sucesso!' });
      } else {
        const { status, data } = await httpPost('upajs', payload);
        if (status === 200) {
          addToast({ title: 'Salvo com sucesso!' });
          history.push(`/settings/upajs/${data.id}`);
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
      formRef.current?.setErrors(erros);
      }
    }
  }, [id, httpPut, addToast, httpPost, history]);

  return (
    <Container>
      <h1>{id && id !== 'new' ? 'Editar' : 'Nova'} UPAJ</h1>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Sigla" xs={12} sm={12} md={3}>
            <Input name="initials" />
          </WrapperLabel>
          <WrapperLabel label="Nome" xs={12} sm={12} md={6}>
            <Input name="name" />
          </WrapperLabel>
          <WrapperLabel label="Instituição" xs={12} sm={12} md={3}>
            <Input name="institution" />
          </WrapperLabel>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item xs={12} md={2}>
            <Button type="submit" isLoading={loading}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </UForm>
    </Container>
  );
};

export default Save;
