import axios from 'axios';

import { IAddress } from '../../../interfaces';

export default async function searchZipToAddress(zip: string): Promise<IAddress | null> {
  const _zip = zip.replace(/\D/, '');

  const response = await axios.get(`https://viacep.com.br/ws/${_zip}/json`);

  if (response.status !== 200 || response.data.error) {
    return null;
  }

  const { data } = response;
  const address: IAddress = {
    street: data.logradouro,
    neighborhood: data.bairro,
    city: data.localidade,
    state: data.uf,
  };

  return address;
}
