import React, { useState, useEffect, useCallback } from 'react';
/** hooks */
import { useHttp } from '../../../../hooks/http';
import { Container } from './styles';

/** componentes */
import Pagination from '../../../../components/Pagination';
import Header from '../components/Header';
import Card, { Flex, Grid } from '../components/Card';
import { IPaginate } from '../../../../interfaces';

const UPAJs: React.FC = () => {
  const { httpGet } = useHttp();
  const [upajs, setUpajs] = useState([]);
  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 20, lastPage: 1,
  });
  const [filter, setFilter] = useState<string|null>(null);

  const getUpajs = useCallback(async (filter: string | null, paginate: IPaginate) => {
    const { status, data } = await httpGet('upajs', {
      params: {
        filter,
        ...paginate,
      },
    });

    if (status === 200) {
      setUpajs(data.data);
      setPaginate(data.pagination);
    }
  }, [httpGet]);

  useEffect(() => {
    getUpajs(null, { page: 1, perPage: 20 } as IPaginate);
  }, [getUpajs]);

  const handledPagination = useCallback((payload: IPaginate) => {
    setPaginate(payload);
    getUpajs(filter, payload);
  }, [filter, getUpajs]);

  const handledFilter = useCallback((filter: string) => {
    setFilter(filter);
    getUpajs(filter, { page: 1, perPage: 20 } as IPaginate);
  }, [getUpajs]);

  return (
    <Container>
      <Header
        handledSearch={handledFilter}
        uriAdd="/settings/upajs/new"
      />
      {upajs && upajs.map((upaj: any) => (
        <Card key={upaj.id} UriUpdate={`/settings/upajs/${upaj.id}`}>
          <Grid container spacing={1}>
            <Flex textAutoSize title="Sigla" xl={12} sm={3} md={3}>
              {upaj.initials}
            </Flex>
            <Flex textAutoSize title="Nome" xl={12} sm={6} md={6}>
              {upaj.name}
            </Flex>
            <Flex textAutoSize title="Instituição" xl={12} sm={3} md={3}>
              {upaj.institution}
            </Flex>
          </Grid>
        </Card>
      )) }
      <Pagination handlePaginate={handledPagination} paginate={paginate} />
    </Container>
  );
};

export default UPAJs;
