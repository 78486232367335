import React, {
  createContext, useCallback, useContext, useState,
} from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import handleAxiosError, { IToastMessage } from './services/handleAxiosError';
import session from '../utils/session';
import { useAuth } from './auth';
import { useToast } from './toast';
import api from '../services/api';

interface HttpContextData {
  httpGet(
    url: string,
    params?: object,
    loading?: boolean,
    header?: object,
  ): Promise<AxiosResponse>;

  httpPost(
    url: string,
    data: object,
    contentType?: string,
    loading?: boolean,
  ): Promise<AxiosResponse>;

  httpPut(
    url: string,
    data: object,
    contentType?: string,
  ): Promise<AxiosResponse>;

  httpDelete(
    url: string,
    params?: object,
  ): Promise<AxiosResponse>;

  loading: boolean;
  unLoading(): void;
}

const HttpContext = createContext<HttpContextData>({} as HttpContextData);

const HttpProvider: React.FC = ({ children }) => {
  const token = session.get('token');
  const { signOut } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);

  api.defaults.headers.authorization = `Bearer ${token}`;

  const handleErro = useCallback((error: AxiosError) => {
    const handle = handleAxiosError(error);

    if (handle.toasts.length) {
      handle.toasts.forEach((toast: IToastMessage) => {
        addToast(toast);
      });
    }

    if (handle.isReturn) {
      return error;
    }

    if (handle.isSignOut) {
      signOut();
    }

    return undefined;
  }, [addToast, signOut]);

  const httpGet = useCallback(async (
    url: string,
    params?: object,
    loading = true,
    header?: any,
  ) => {
    try {
      setLoading(loading);
      api.defaults.headers.contentType = 'application/json';

      if (header) {
        const [[key, value]] = Object.entries(header);
        api.defaults.headers[key] = value;
      }
      const response = await api.get(url, params).finally(() => { setLoading(false); });

      return response;
    } catch (err) {
      handleErro(err);
      return err;
    }
  }, [handleErro]);

  const httpPost = useCallback(async (
    url: string,
    data: object,
    contentType = 'json',
  ) => {
    try {
      setLoading(true);
      api.defaults.headers.contentType = contentType === 'json' ? 'application/json' : 'multipart/form-data';
      const response = await api.post(url, data).finally(() => { setLoading(false); });

      return response;
    } catch (err) {
      handleErro(err);
      return err;
    }
  }, [handleErro]);

  const httpPut = useCallback(async (
    url: string,
    data: object,
    contentType = 'json',
  ) => {
    try {
      setLoading(true);
      api.defaults.headers.contentType = contentType === 'json' ? 'application/json' : 'multipart/form-data';
      const response = await api.put(url, data).finally(() => { setLoading(false); });
      return response;
    } catch (err) {
      handleErro(err);
      return err;
    }
  }, [handleErro]);

  const httpDelete = useCallback(async (
    url: string,
    params?: object,
  ) => {
    try {
      setLoading(true);
      api.defaults.headers.contentType = 'application/json';
      const response = await api.delete(url, params).finally(() => { setLoading(false); });
      return response;
    } catch (err) {
      handleErro(err);
      return err;
    }
  }, [handleErro]);

  const unLoading = useCallback(() => {
    setLoading(false);
  }, []);

  return (
    <HttpContext.Provider value={{
      httpGet, httpPost, httpPut, httpDelete, loading, unLoading,
    }}
    >
      {children}
    </HttpContext.Provider>
  );
};

function useHttp(): HttpContextData {
  const context = useContext(HttpContext);

  if (!context) {
    throw new Error('useHttp must be used within an HttpProvider');
  }

  return context;
}

export { HttpProvider, useHttp };
