import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useHttp } from '../../hooks/http';

const Bopm: React.FC = () => {
  const history = useHistory();
  const { httpGet } = useHttp();
  const { user } = useAuth();

  useEffect(() => {
    async function load(): Promise<any> {
      const { status } = await httpGet('/bopms/pesquise');
      const service = await httpGet(`/bopms/userService/${user?.id}`);

      if (status === 200) {
        history.push('/RO/step');
      } else {
        if (service.status === 200) {
          localStorage.setItem('service_management_id', service.data.management_id);
          localStorage.setItem('base', service.data.initials);
          localStorage.setItem('presence', service.data.presence);
        }
        history.push('/RO/occurrence/storage');
      }
    }
    load();
  }, [history, httpGet, user]);

  return (<></>);
};

export default Bopm;
