import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import Grid from '@material-ui/core/Grid';
import getValidationError from '../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../hooks/http';
import { useToast } from '../../hooks/toast';

/** componentes */
import Layout from '../../components/Layout';
import Header from '../../components/Headers/SavePlus';
import {
  WrapperLabel, Input, TextArea, Select, Button,
} from '../../components/Forms';
import { MapIcon } from '../../styles/icons';
import { Container, Content } from './styles';

//* * interfaces */
import { ISector, IManagement } from '../../interfaces';

const Sectors: React.FC = () => {
  const { id } = useParams<{id: string}>();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const {
    httpGet, httpPost, httpPut, loading,
  } = useHttp();
  const [managements, setManagements] = useState([]);
  const [sector, setSector] = useState<ISector>({} as ISector);

  const breadcrumbs = {
    icon: MapIcon,
    links: [
      { title: 'Setores', path: '/sectors' },
      { title: 'Setor', path: '' }],
  };

  useEffect(() => {
    async function loadManagements():Promise<any> {
      const { status, data } = await httpGet('/sectors/managements');
      if (status === 200) {
        const managementParsed = data
          .map((management: any) => ({
            value: management.id,
            label: management.initials,
          }));
        setManagements(managementParsed);
      }
    }

    loadManagements();

    async function loadSector():Promise<any> {
      const { status, data } = await httpGet(`/sectors/${id}`);
      if (status === 200) {
        setSector(data);
      }
    }

    if (id) {
      loadSector();
    } else {
      const sector: ISector = {
        id: 0,
        order: 0,
        initials: '',
        name: '',
        description: '',
        management_id: 0,
        management: {} as IManagement,
        status: true,
      };
      setSector(sector);
    }
  }, [httpGet, id]);

  useEffect(() => {
    formRef.current?.setData({
      ...sector,
      management_id: sector.management_id
        ? { value: sector.management_id, label: sector.management.initials }
        : null,
      status: sector.status
        ? { value: true, label: 'Ativo' }
        : { value: false, label: 'Inativo' },
    });
  }, [sector]);

  const handleSubmit = useCallback(async (payload: any) => {
    const sector = {
      ...payload,
      management_id: payload?.management_id?.value || null,
      status: payload?.status?.value || null,
    };
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        management_id: Yup.string().ensure().required('Gerência é obrigatória'),
        order: Yup.number().typeError('Ordem é obrigatória'),
        initials: Yup.string()
          .required('Sigla é obrigatória')
          .max(20, 'a Sigla não deve conter mais que 20 caracteres'),
        name: Yup.string().required('Nome é obrigatório'),
      });

      await schema.validate(sector, { abortEarly: false });

      if (id) {
        const { status } = await httpPut(`sectors/${id}`, sector);
        if (status === 200) {
          addToast({ title: 'Editado com sucesso.' });
        }
      } else {
        const { status, data } = await httpPost('sectors', sector);
        if (status === 200) {
          addToast({ title: 'Salvo com sucesso.' });
          history.push(`/sector/${data.id}`);
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [id]);// eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Header handlePlus={() => history.push('/sector')}>
          {!id && 'Novo Setor'}
          {id && `Editar ${sector.initials}`}
        </Header>
        <Content>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <WrapperLabel label="Gerência" xs={12} sm={6} md={8}>
                <Select
                  name="management_id"
                  options={managements}
                />
              </WrapperLabel>

              <WrapperLabel label="Status" xs={12} sm={6} md={4}>
                <Select
                  name="status"
                  options={[
                    { value: true, label: 'Ativo' },
                    { value: false, label: 'Inativo' },
                  ]}
                />
              </WrapperLabel>
            </Grid>

            <Grid container spacing={1}>
              <WrapperLabel label="Sigla" xs={12} md={4}>
                <Input name="initials" />
              </WrapperLabel>
              <WrapperLabel label="Nome" xs={12} md={8}>
                <Input name="name" />
              </WrapperLabel>

              <WrapperLabel label="Descrição" xs={12}>
                <TextArea name="description" />
              </WrapperLabel>
            </Grid>
            <Grid container justify="flex-end">
              <Grid item xs={12} md={4}>
                <Button type="submit" isLoading={loading}>Salvar</Button>
              </Grid>
            </Grid>
          </UForm>
        </Content>
      </Container>
    </Layout>
  );
};

export default Sectors;
