import styled, { css } from 'styled-components';

interface IBox{
  isWarner?: boolean;
}
export const Container = styled.div<IBox>`
  width: 100%;

  box-shadow: var(--shadow);
  border-radius:4px;
  cursor: pointer;

  ${(props) => props.isWarner && css`
  border-left: 4px solid var(--color-yellow);
  border-radius: 0 4px 4px 0;`}

  padding: 8px 16px;
  background: #fff;

  position: relative;

  + div{
    margin-top: 6px;
  }

  transition: transform 0.2s;
  &:hover{
     transform: translateX(4px);
  }
`;

export const WrapperRef = styled.span`
  height: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  /* > svg{
    flex-shrink: 0;
    font-size: 24px;
    margin-right: 8px;
  } */

`;

export const WrapperModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  h2 {
    font-size: 1.1em;
    font-weight: 700;
  }
  p {
    margin: 8px 0;
    width: 100%;
  }
  button {
    padding: 16px;
    width: 100%;
    border: none;
    border-radius: 4px;
    color: var(--color-white);
    background-color: var(--color-button);

    &:hover{
      background-color: var(--color-button-h);
    }
  }
`;

export const WrapperStatus = styled.span`
  display: flex;
  align-items: center;
  width:100%;
  padding-right: 8px;
  span{
    width:calc(100% - 16px);
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  > label{
    margin-right: 4px;
    cursor: pointer;

    svg {
      &:hover {
        color: var(--color-primary-h);
      }
    }
  }
`;

export const WrapperLink = styled.span`

  display: flex;
  align-items: center;

  a {
    margin-left: 8px;
    color: var(--color-primary);
    text-decoration: none;

    &:hover{
      color: var(--color-primary-h);
      text-decoration: underline;
    }
  }
  svg {
    margin-right: 4px;
  }

`;

export const WrapperProcess = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 0 4px 4px 0;
  background: rgba(0,0,0,0.6);
  z-index: 2;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #ebebeb;
  font-size: 1.2em;
  font-weight: 700;

  &:hover{
    color: #fff;
  }
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span{
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  svg{
    font-size: 24px;
    margin-right: 8px;
  }
`;
