const colors = [
  { value: 'AMARELO', label: 'AMARELO' },
  { value: 'AZUL', label: 'AZUL' },
  { value: 'BEGE', label: 'BEGE' },
  { value: 'BRANCA', label: 'BRANCA' },
  { value: 'CINZA', label: 'CINZA' },
  { value: 'DOURADA', label: 'DOURADA' },
  { value: 'GRENÁ', label: 'GRENÁ' },
  { value: 'LARANJA', label: 'LARANJA' },
  { value: 'MARROM', label: 'MARROM' },
  { value: 'PRATA', label: 'PRATA' },
  { value: 'PRETA', label: 'PRETA' },
  { value: 'ROSA', label: 'ROSA' },
  { value: 'ROXA', label: 'ROXA' },
  { value: 'VERDE', label: 'VERDE' },
  { value: 'VERMELHA', label: 'VERMELHA' },
  { value: 'FANTASIA', label: 'FANTASIA' },
];

export default colors;
