import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import { ViewListIcon } from 'styles/icons';
/** hooks */
import Grid from '@material-ui/core/Grid';
import { useHttp } from '../../../hooks/http';
import HeaderFilterPlus from '../../../components/Headers/FilterPlus';
import Pagination from '../../../components/Pagination';
import Card from './Card';
import { Container } from './styles';
/** interfaces */
import { IPaginate } from '../../../interfaces';
import { useAuth } from 'hooks/auth';

const List: React.FC = () => {
    const [reducols, setReducols] = useState([]);
    const [filter, setFilter] = useState('');
    const { user } = useAuth();

    const [paginate, setPaginate] = useState<IPaginate>({
        page: 1, perPage: 10, lastPage: 1, total: 0,
    });

    const { httpGet, loading } = useHttp();

    useEffect(() => {
        async function loadSocials(): Promise<void> {
            const { status, data } = await httpGet(`/reducols`, {
                params: {
                    user,
                    filter,
                    ...paginate,
                },
            });

            if (status === 200) {
                setReducols(data.data);
                setPaginate(data.pagination);
            }
        }

        loadSocials();

    }, [filter, user, paginate.page]); // eslint-disable-line


    const handleSearch = (value: string): void => {
        setPaginate({ ...paginate, page: 1 });
        setFilter(value);
    };

    const handlePaginate = (payload: IPaginate): void => {
        setPaginate(payload);
    };

    const breadcrumbs = {
        icon: ViewListIcon,
        links: [{ path: '', title: 'LeiSeca Reducols' }],
    };

    return (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
            <Container>
                <HeaderFilterPlus handleSearch={handleSearch} />
                <Grid container spacing={1}>
                    {reducols && reducols.map((reducol: any) => (
                        <Card
                            key={reducol.id}
                            reducol={reducol}
                        />
                    ))}
                </Grid>
                <Pagination paginate={paginate} handlePaginate={handlePaginate} />
            </Container>
        </Layout>
    );
}

export default List;