/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import Laydash from 'components/Layout/Laydash';
import { useAuth } from 'hooks/auth';
import { useHttp } from 'hooks/http';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { DatePicker, Select, WrapperLabel } from '../../../../components/Forms';
import { Table } from '../../../../components/Table';
import { Container, Img } from './styles';
import WalletBox from './WalletBox';

import logoImg from '../../../../assets/leiseca.png';
import WalletBoxAction from './WalletBoxAction';

const links = [
  { path: '/dashboard', title: 'HOME', key: 1 },
  { path: '/leiseca/dashboard-educacao', title: 'GERAL', key: 2 },
];

const Dashboard: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [filter, setFilter] = useState('');
  const [date, setDate] = useState('');
  const [rcols, setRcols] = useState([]);
  const [report, setReport] = useState(0);
  const [abc, setAbc] = useState(0);
  const [age, setAge] = useState({
    INFANTIL: 0,
    ADOLESCENTE: 0,
    ADULTO: 0,
    'MISTO (no caso de ação)': 0,
  });
  const [dataction, setDataAction] = useState(
    {
      'AÇÃO - FUTEBOL': 0,
      'AÇÃO - ESPORTES': 0,
      'AÇÃO - BARES e COMÉRCIO': 0,
      'AÇÃO - OUTRAS': 0,
    },
  );
  const [datarec, setDataRec] = useState(
    {
      'PALESTRA EMPRESA': 0,
      'PALESTRA UNIVERSIDADE': 0,
      'PALESTRA ESCOLA': 0,
    },
  );
  const [nota, setNota] = useState(0);
  const [action, setAction] = useState(0);
  const { user } = useAuth();
  const { httpGet } = useHttp();

  useEffect(() => {
    async function getLeiSeca(): Promise<void> {
      const { status, data } = await httpGet('/reducols/leiseca', {
        params: {
          date,
          filter,
        },
      });

      if (status === 200) {
        const cols = data.map((v: any) => ({
          team: v.team,
          civil: v.user.name,
          addressOperation: handleAddressOperations(v.sectiontwo),
          locality: handleLocation(v.sectiontwo),
          approach: handleApproachs(v.sectiontwo),
          extends: handleExtends(v.sectiontwo),
          audiences: handleAudience(v.sectiontwo),
          tests: handleRefusals(v.sectiontwo),
          thirtyfour_ml: handleThirtyfour(v.sectiontwo),
        }));
        const total = cols.length;
        const abc = handleApproachs(cols);
        setReport(total);
        handleActions(cols);
        setAbc(abc);
        setRcols(cols);
      }
    }

    getLeiSeca();

    }, [filter, date, user]); // eslint-disable-line

  function handleExtends(sectiontwo: any) {
    const result = sectiontwo
      .map((p: any) => p.type_action);
    return result;
  }

  function handleAudience(sectiontwo: any) {
    const result = sectiontwo
      .map((p: any) => p.type_audience);
    return result;
  }

  function handleActions(sectiontwo: any) {
    let totalAction = 0;
    let totalLecture = 0;
    let totalNota = 0;

    let totalFUT = 0;
    let totalESP = 0;
    let totalBA = 0;
    let totalOU = 0;

    let totalEMP = 0;
    let totalESC = 0;
    let totalUNI = 0;

    let totalINF = 0;
    let totalADO = 0;
    let totalADU = 0;
    let totalMISTO = 0;

    sectiontwo
      .map((p: any) => p.extends.map((a: any) => (
        a.includes('AÇÃO - FUTEBOL') && totalFUT++
      )));
    sectiontwo
      .map((p: any) => p.extends.map((a: any) => (
        a.includes('AÇÃO - ESPORTES') && totalESP++
      )));
    sectiontwo
      .map((p: any) => p.extends.map((a: any) => (
        a.includes('AÇÃO - BARES e COMÉRCIO') && totalBA++
      )));
    sectiontwo
      .map((p: any) => p.extends.map((a: any) => (
        a.includes('AÇÃO - OUTRAS') && totalOU++
      )));
    sectiontwo
      .map((p: any) => p.extends.map((a: any) => (
        a.includes('PALESTRA EMPRESA') && totalEMP++
      )));
    sectiontwo
      .map((p: any) => p.extends.map((a: any) => (
        a.includes('PALESTRA ESCOLA') && totalESC++
      )));
    sectiontwo
      .map((p: any) => p.extends.map((a: any) => (
        a.includes('PALESTRA UNIVERSIDADE') && totalUNI++
      )));
    sectiontwo
      .map((p: any) => p.extends.map((a: any) => (
        a.includes('ESCOLA NOTA 10') && totalNota++
      )));
    sectiontwo
      .map((p: any) => p.audiences.map((a: any) => (
        a.includes('INFANTIL') && totalINF++
      )));
    sectiontwo
      .map((p: any) => p.audiences.map((a: any) => (
        a.includes('ADOLESCENTE') && totalADO++
      )));
    sectiontwo
      .map((p: any) => p.audiences.map((a: any) => (
        a.includes('ADULTO') && totalADU++
      )));
    sectiontwo
      .map((p: any) => p.audiences.map((a: any) => (
        a.includes('MISTO (no caso de ação)') && totalMISTO++
      )));

    totalAction = totalFUT + totalESP + totalBA + totalOU;
    totalLecture = totalEMP + totalESC + totalUNI;

    setAction(totalAction + totalLecture + totalNota);
    setNota(totalNota);
    setAge({
      INFANTIL: totalINF,
      ADOLESCENTE: totalADO,
      ADULTO: totalADU,
      'MISTO (no caso de ação)': totalMISTO,
    });
    setDataAction({
      'AÇÃO - FUTEBOL': totalFUT,
      'AÇÃO - ESPORTES': totalESP,
      'AÇÃO - BARES e COMÉRCIO': totalBA,
      'AÇÃO - OUTRAS': totalOU,
    });
    setDataRec({
      'PALESTRA EMPRESA': totalEMP,
      'PALESTRA ESCOLA': totalESC,
      'PALESTRA UNIVERSIDADE': totalUNI,
    });
  }

  function handleAddressOperations(sectiontwo: any) {
    // const result = sectiontwo.map((v: any) => `${v.addressOperation}###`);
    const resultOperations = sectiontwo
      .map((p: any) => (p.place_action === '' ? p.place_action : p.place_action));
    return resultOperations;
  }

  function handleLocation(sectiontwo: any) {
    const resultLocation = sectiontwo.map((v: any) => `${v.locality}, `);
    return resultLocation;
  }

  function handleApproachs(sectiontwo: any) {
    function getTotalApproachs(total: any, item: any) {
      return total + item.approach;
    }
    const resultApproachs = sectiontwo.reduce(getTotalApproachs, 0);
    return resultApproachs;
  }

  function handleRefusals(sectiontwo: any) {
    function getTotalRefusals(total: any, item: any) {
      return total + item.tests;
    }
    const resultRefusals = sectiontwo.reduce(getTotalRefusals, 0);
    return resultRefusals;
  }

  function handleThirtyfour(sectiontwo: any) {
    function getTotalThirtyfour(total: any, item: any) {
      return total + item.thirtyfour_ml;
    }
    const resultThirtyfour = sectiontwo.reduce(getTotalThirtyfour, 0);
    return resultThirtyfour;
  }

  function handleSubmit() {

  }

  const columns = useMemo(
    () => [
      {
        Header: 'Equipe',
        accessor: 'team',
      },
      {
        Header: 'Endereço',
        accessor: 'addressOperation',
      },
      {
        Header: 'Chefe Equipe',
        accessor: 'civil',
      },
      {
        Header: 'Abordagens',
        accessor: 'approach',
      },
      {
        Header: 'Testes',
        accessor: 'tests',
      },
    ],
    [],
  );

  const handleSearch = useCallback((partipation: any) => {
    setFilter(partipation.value);
  }, []);

  const handleDate = useCallback((data: any) => {
    const date = new Date(data);
    const mnth = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    const dd = [date.getFullYear(), mnth, day].join('-');
    setDate(dd);
  }, []);

  return (
    <Laydash
      links={links}
    >
      <Container>
        <Img
          src={logoImg}
        />
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={1} alignItems="flex-end">
            <WrapperLabel label="" xs={12} md={6}>
              <DatePicker
                name="date_poll"
                maxDate={new Date()}
                onSelect={handleDate}
                placeholderText="Data"
              />
            </WrapperLabel>
            <WrapperLabel label="" xs={12} md={6}>
              <Select
                placeholderText="Equipe"
                name="team"
                options={[
                  { value: 'A', label: 'A' },
                  { value: 'B', label: 'B' },
                  { value: 'C', label: 'C' },
                  { value: 'D', label: 'D' },
                  { value: 'E', label: 'E' },
                  { value: 'F', label: 'F' },
                  { value: 'G', label: 'G' },
                  { value: 'EXTRA I', label: 'EXTRA I' },
                  { value: 'EXTRA II', label: 'EXTRA II' },
                  { value: 'EXTRA III', label: 'EXTRA III' },
                ]}
                onChange={handleSearch}
              />
            </WrapperLabel>
          </Grid>
        </UForm>
        <WalletBox
          title="ABORDAGENS"
          amount={abc}
          color="#2E7D32"
        />
        <WalletBox
          title="RELATÓRIOS"
          amount={report}
          color="#702e7d"
        />
        <WalletBoxAction
          title="AÇÕES"
          amount={action}
          color="#1c79b7"
          dataction={dataction}
          datarec={datarec}
          nota={nota}
          age={age}
        />
      </Container>
      <Table columns={columns} data={rcols} />
    </Laydash>
  );
};

export default Dashboard;
