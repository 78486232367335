import styled, { css } from 'styled-components';
import { lighten } from 'polished';

interface IProps{
    isWarner?: boolean;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
`;

export const Content = styled.div`
    display: flex;
    background: #fff;
    padding: 8px 16px;
`;

export const Display = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 8px 16px;
    
    h4 {
        color: #333;
        margin-bottom:20px
    }

    h3 {
        color: 000;
        margin-bottom:15px
    }
`;

export const Header = styled.div`
    font-size: larger;
    font-weight: 700;
    padding: 12px;
    align-items: center;
`;

export const Box = styled.h3`
    width: 100%;
`;

export const BoxWallet = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    background-color: ${lighten(0.3, '#c53030')};
    border-radius: 4px;
    padding: 6px;
`;

export const Text = styled.div`
    font-size: medium;
    font-weight: 700;
    padding: 6px;
`;

export const Success = styled.div`
    font-size: medium;
    font-weight: 500;
    background-color: ${lighten(0.2, '#007E33')};
    padding: 6px;
    border-radius: 6px;
`;

export const Item = styled.div<IProps>`
    width: 100%;
    padding: 8px;
    border: 1px solid gray;
    border-radius: 1%;
    margin: 2px;

    cursor: pointer;

    ${(props) => props.isWarner && css`
    border: 1px solid var(--color-yellow);
    border-radius: 0 4px 4px 0;
    background: var(--color-yellow); `}

    &:hover {
        background: #CCC
        ${(props) => props.isWarner && css`
    border: 1px solid var(--color-yellow);
    border-radius: 0 4px 4px 0;
    background: var(--color-yellow); `}
    }
`;

export const GridButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 56px;
  grid-template-areas:
    'btn-cancel space btn-submit';


  @media (max-width: 767.98px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 56px 56px;
    grid-template-areas:
      'btn-cancel btn-submit'
  }
`;
