import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { format, isAfter } from 'date-fns';
import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../hooks/http';

/** Componentes */
import Layout from '../../../components/Layout';
import Drawer from '../../../components/Drawer';
import HeaderFilterDrawer from '../../../components/Headers/FilterDrawer';
import Pagination from '../../../components/Pagination';
import Card from './Card';
import {
  WrapperLabel, Input, DatePicker, Select, Button, ButtonIcon,
} from '../../../components/Forms';

import {
  ArrowBarRightIcon,
  ListIcon, TrashIcon,
} from '../../../styles/icons';
import { Container, DrawerContent } from './styles';

/** interfaces */
import { IOrder, IPaginate } from '../../../interfaces';

interface IOptions {
  label: string;
  value: string;
}

interface IFilter {
  id: string;
  startDate: Date;
  endDate: Date;
  management_id: number;
  user_id: string;
  operator_id: string;
  plate: string;
  document: string;
}

const breadcrumbs = {
  icon: ListIcon,
  links: [
    { title: 'Historico de manutencao', path: '' },
  ],
};
const History: React.FC = () => {
  const { httpGet, loading } = useHttp();
  const formRef = useRef<FormHandles>(null);
  const RefLabelToggle = useRef<HTMLLabelElement>(null);
  const [orders, setOrders] = useState([]);
  const [managements, setManagements] = useState([]);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [paginate, setPaginate] = React.useState<IPaginate>({ page: 1, lastPage: 1, perPage: 10 });
  const [user, setUser] = useState<IOptions | null>(null);
  const [operator, setOperator] = useState<IOptions | null>(null);

  const getOrders = useCallback(async (filter: IFilter, paginate: IPaginate) => {
    const { status, data } = await httpGet('/fleetMechanicals/fiscal', {
      params: { ...filter, ...paginate },
    });

    if (status === 200) {
      setOrders(data.data);
      setPaginate(data.pagination);
    }
  }, [httpGet]);

  useEffect(() => {
    (async () => {
      const { status, data } = await httpGet('/managements/all', undefined, false);
      if (status === 200) {
        const managementParsed = data
          .map((management: any) => ({
            value: management.id,
            label: management.initials,
          }));
        setManagements(managementParsed);
      }
    })();
    getOrders(
      { id: '' } as IFilter,
      { page: 1, lastPage: 1, perPage: 10 },
    );
  }, [getOrders, httpGet]);

  const handleSearch = useCallback((value: string) => {
    setPaginate((oldPaginate: any) => ({ ...oldPaginate, page: 1 }));
    setFilter((oldState: any) => ({ ...oldState, id: value }));

    getOrders(
      { id: value } as IFilter,
      { page: 1, lastPage: 1, perPage: 10 },
    );
  }, [getOrders]);

  const handlePaginate = useCallback((payload: IPaginate) => {
    setPaginate(payload);
    getOrders(filter, payload);
  }, [filter, getOrders]);

  const validateDate = useCallback((startDate: any, endDate: any) => {
    if (!startDate && !endDate) {
      return true;
    }

    if (!startDate || !endDate) {
      return false;
    }

    if (isAfter(new Date(startDate), new Date(endDate))) {
      return false;
    }

    return true;
  }, []);

  const handleSubmit = useCallback(async (payload: any) => {
    try {
      const filter = {
        ...payload,
        id: null,
        page: 1,
        perPage: 10,
        startDate: payload.startDate ? format(payload.startDate, 'yyyy-MM-dd') : null,
        endDate: payload.endDate ? format(payload.endDate, 'yyyy-MM-dd') : null,
        type: payload.type ? payload.type.value : null,
        management_id: payload.management_id ? payload.management_id.value : null,
        user_id: user ? user.value : null,
        operator_id: operator ? operator.value : null,
        results: payload.results ? payload.results
          .map((results: any) => results.value) : null,
      };

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        startDate: Yup.string().ensure()
          .test(
            'is-valid',
            'a Data Início não pode ser maior que a data de Término.',
            () => validateDate(filter.startDate, filter.endDate),
          ),
        endDate: Yup.string().ensure()
          .test(
            'is-valid',
            'a Data Término não pode ser menor que a data de Início.',
            () => validateDate(filter.startDate, filter.endDate),
          ),

      });

      await schema.validate(filter, { abortEarly: false });
      setFilter(filter);

      const { status, data } = await httpGet('orders/answered', { params: filter });

      if (status === 200) {
        setOrders(data.data);
        setPaginate(data.pagination);

        if (data.data.length > 0) {
          RefLabelToggle?.current?.click();
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [httpGet, operator, user, validateDate]);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderFilterDrawer
          handleSearch={handleSearch}
          placeholder="#protocolo"
        />

        <Drawer>
          <DrawerContent>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>

                <WrapperLabel label="Data Início" xs={12} md={6}>
                  <DatePicker
                    name="startDate"
                    isClearable
                    maxDate={filter?.endDate || new Date()}
                  />
                </WrapperLabel>
                <WrapperLabel label="Data Término" xs={12} md={6}>
                  <DatePicker
                    name="endDate"
                    isClearable
                    maxDate={filter?.endDate || new Date()}
                  />
                </WrapperLabel>

                <WrapperLabel label="Gerência" xs={12} md={6}>
                  <Select
                    name="management_id"
                    isClearable
                    options={managements}
                  />
                </WrapperLabel>

                <WrapperLabel label="Placa" xs={12} md={6}>
                  <Input name="plate" />
                </WrapperLabel>
                <WrapperLabel label="Chassi" xs={12} md={6}>
                  <Input name="chassi" />
                </WrapperLabel>

                <WrapperLabel label="Observação" xs={12}>
                  <Input name="note" />
                </WrapperLabel>
              </Grid>
              <Grid container spacing={1} justify="space-between" alignItems="baseline">

                <Grid item xs={10} md={4}>
                  <span style={{ display: 'flex', gap: 8, marginTop: 8 }}>
                    <ButtonIcon title="Fechar">
                      <label ref={RefLabelToggle} htmlFor="pure-toggle-right" data-toggle-label="right">
                        <ArrowBarRightIcon />
                      </label>
                    </ButtonIcon>
                    <ButtonIcon
                      title="Limpar"
                      onClick={() => {
                        setUser(null);
                        setOperator(null);
                      formRef.current?.reset();
                      }}
                    >
                      <TrashIcon />
                    </ButtonIcon>
                  </span>

                </Grid>

                <Grid item xs={12} md={4}>
                  <Button type="submit" isLoading={loading}> Filtrar </Button>
                </Grid>
              </Grid>
            </UForm>
          </DrawerContent>
        </Drawer>

        <Grid container spacing={1}>
          {orders && orders.map((order: IOrder) => (
            <Card
              key={order.id}
              order={order}
            />
          ))}
        </Grid>
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default History;
