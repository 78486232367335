import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useHttp } from 'hooks/http';
import { Form as UForm } from '@unform/web';
import { FormHandles } from '@unform/core';
import AsyncSelect from 'react-select/async';
import { Grid } from '../../../components/Flex';
import getValidationError from '../../../utils/getValidationsErros';
import customStyle from './customStyle';

import {
  WrapperLabel,
  Button,
  InputHidden,
  Input,
  Select,
} from '../../../components/Forms';

import {
  Container,
  ContentWapper,
  GridButtons,
  WrapperBtnCancel,
  WrapperBtnSubmit,
} from './styles';

interface IData {
    labelAbsRef: any
    id: string
    readonly handlerLoad: () => void
}

interface IOptions {
    label: string;
    value: string;
}

const Supply: React.FC<IData> = ({ labelAbsRef, id, handlerLoad }) => {
  const formRefAbst = useRef<FormHandles>(null);
  const [gases, setGases] = useState<IOptions | null>(null);
  const { httpPut, httpGet } = useHttp();

  const handleToAbst = useCallback(async (payload: any) => {
    const _payload = {
      ...payload,
      gases_id: gases?.value || null,
      fuel: payload.fuel?.value,
      liters: payload.liters && payload.liters.replace(',', '.'),
    };
    try {
            formRefAbst.current?.setErrors({});
            const schema = Yup.object().shape({
              km_fueling: Yup.number().required()
                .typeError('Km abastecimento é obrigatório, somente números'),
              liters: Yup.number().required('Litros, somente números'),
              fuel: Yup.string().ensure().required('Tipo é obrigatório'),
              gases_id: Yup.string().required('Código do posto é obrigatório'),
            });

            await schema.validate(_payload, { abortEarly: false });

            const response: any = await httpPut(`bdtds/supply/${_payload.id}`, {
              ..._payload,
            });

            if (response.status === 200) {
              handlerLoad();
            }
            labelAbsRef.current.click();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
                formRefAbst.current?.setErrors(erros);
      }
    }
  }, [httpPut, labelAbsRef, handlerLoad, gases]);

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 2) return;
    const gas: any = [];
    const { status, data } = await httpGet(`/gas/filter/${inputValue}`, undefined, false);

    if (status === 200) {
      data.forEach((item: any) => {
        gas.push({
          value: item.id,
          label: `${item.id} - ${item.fantasy}`,
        });
      });
    }

    callback(gas);
  };

  const handleChangeUser = useCallback((event: any) => {
    setGases(event);
  }, []);

  return (
    <Container>
      <h2>Abastecimento</h2>
      <ContentWapper>
        <UForm ref={formRefAbst} onSubmit={handleToAbst}>
          <Grid container spacing={1}>
            <WrapperLabel label="Km abastecimento" xs={12} sm={6}>
              <InputHidden name="id" defaultValue={id} />
              <Input name="km_fueling" maxLength={11} />
            </WrapperLabel>
            <WrapperLabel label="Tipo" xs={12} sm={6}>
              <Select
                name="fuel"
                options={[{
                  value: 'ALCOOL',
                  label: 'ÁLCOOL',
                }, {
                  value: 'GASOLINA',
                  label: 'GASOLINA',
                }, {
                  value: 'DIESEL',
                  label: 'DIESEL',
                }, {
                  value: 'GNV',
                  label: 'GNV',
                },
                ]}
              />
            </WrapperLabel>
            <WrapperLabel label="Cod Posto" xs={12}>
              <div style={{ width: '100%' }}>
                <AsyncSelect
                  placeholder="Cod Posto"
                  noOptionsMessage={() => 'Sem registos'}
                  cacheOptions
                  isClearable
                  name="gases_id"
                  loadOptions={loadOptions}
                  onChange={handleChangeUser}
                  value={gases}
                  styles={customStyle}
                />
              </div>
            </WrapperLabel>
            <WrapperLabel label="Litros" xs={12}>
              <Input
                name="liters"
                maxLength={6}
              />
            </WrapperLabel>

            <GridButtons>
              <WrapperBtnCancel>
                <Button
                  type="button"
                  onClick={() => labelAbsRef.current.click()}
                >
                  Cancelar
                </Button>
              </WrapperBtnCancel>
              <WrapperBtnSubmit>
                <Button
                  type="submit"
                >
                  Salvar
                </Button>
              </WrapperBtnSubmit>
            </GridButtons>
          </Grid>
        </UForm>
      </ContentWapper>
    </Container>
  );
};

export default Supply;
