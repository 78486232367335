import styled from 'styled-components';

interface IContainerProps {
    color: string
}

export const ContainerSelect = styled.div<IContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 600px){
        width: 96%;
    }
    width: 100%;
    height: 70px;
    background-color: ${props => props.color};
    border: 1px solid;
    border-radius: 5px;
    margin-top: 15px ;
    margin-right:30px;

    padding: 8px ;

    span {
        color: white;
    }

    h1 {
        color: white;
    }
`;