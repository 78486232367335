import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;

  padding: 8px 16px;

  form{
    width: 100%;
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 5px;
  text-align: center;
  border: none;
  border-radius: 4px;
  color: var(--color-white);
  background: var(--color-button);
  font-size: 1.1rem;
  font-weight: 400;
`;

export const ContainerButtons = styled.div`
  border-top: 16px;
  width: 100%;
  padding: 16px;
`;

export const Status = styled.div`
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  display:flex;
`;

export const WrapperRemove = styled.div`
  width: 100%;
  font-size: 24px;
  display: flex;
  justify-content: flex-end;

  cursor: pointer;
  svg{
    font-size: 24px;
    color: brown;
  }

  &:hover{
    svg{
      color: var(--color-secondary-h);
    }
  }


`;

export const WrapperName = styled.div`
  font-size: 18px;
  font-weight: bold;
  display:flex;
  align-items:center;
  margin-bottom: 10px;

  span{
    font-size: 15px;
    margin-left: 8px;
  }

  svg{
    font-size: 24px;
    margin-right: 8px;
  }
`;

export const WrapperGrid = styled.div`
  border: 1px solid #999;
  border-radius: 4px;
  padding: 20px;
`;

export const WrapperOccurrence = styled.div`
  width: 100%;
  margin-bottom: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperAddress = styled.div`
  padding: 16px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 8px;

  h3{
    color: var(--color-primary);
    margin-bottom: 8px;

  }

`;

export const TitleModal = styled.div`
  .delete{
    color:#fff;
    svg{
      color: brown;
  }}

  .alert{
    color: var(--color-primary-h);
  }

  font-size: 24px;
  display:flex;
  align-items:center;
  margin-bottom: 18px;

  span{
    font-size: 20px;
    margin-left: 8px;
    text-decoration: line-through;
  }

  svg{
    font-size: 30px;
    margin-right: 8px;
  }
`;

export const WrapperTitle = styled.div`
  width: 100%;
  padding: 5px;
  color: #959ea3;
  margin-bottom:10px;
  border-bottom: 1px solid;
  border-bottom-style: dotted;
`;

export const LineTitle = styled.div`
  width: 100%;
  padding: 15px;
  border: 1px solid;
  border-style: dotted;
  border-radius:8px;

`;

export const Number = styled.div`
  margin-left: 40px;
  width: 100%;
  padding: 15px;
  font-size: 17px;
  font-weight: bold;
  color: brown;

`;

export const GridButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 40px 1fr 1fr 1fr;
  grid-template-rows: 56px;
  grid-template-areas:
    'btn-inf space btn-cancel btn-submit';


  @media (max-width: 767.98px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 56px 56px;
    grid-template-areas:
      'btn-cancel btn-submit'
      'btn-inf space';
  }
`;

export const WrapperButton = styled.span`
  display: flex;
  width: 100%;
  > div{
    width: calc(100% - 48px);
    input{
      width: calc(100% - 46px);
    }
  }

  button{
    width: 40px;
  }

`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;

export const WrapperBtnCancel = styled.div`
  grid-area: btn-cancel;
`;

export const WrapperBtnSubmit = styled.div`
  grid-area: btn-submit;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Props{
  show?: boolean;
}

export const WrapperHidden = styled.div<Props>`
  width: 100%;
  padding: 0 4px;
  ${(props) => !props.show && css`
    position: absolute;
    right: 9999px;
  `}
`;

export const WrapperModal = styled.div`
   color: #212529;
  .modal__inner{
    background: #FFCD39;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    svg {
      flex-shrink: 0;
      font-size: 1.5em;
    }
  }
  p{
    margin-bottom: 8px;
  }
  b{
    font-size: 1.1rem;
  }
  span{
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
`;

export const WrapperModalDelete = styled.div`
   color: #212529;
  .modal__inner{
    background: #FF9090;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    svg {
      flex-shrink: 0;
      font-size: 1.5em;
    }
  }
  p{
    margin-bottom: 8px;
  }
  b{
    font-size: 1.1rem;
  }
  span{
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
`;

export const Death = styled.div`
  font-weight: bold;
  color: #CC0001;
`;

export const RedStyle = styled.div`
  padding-top: 15px;
  font-weight: bold;
  color: #CC0001;
`;

export const Card = styled.div`
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span{
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  svg{
    font-size: 24px;
    margin-right: 8px;
  }

  h2{
    font-size: 1rem;
    font-weight: 700;
  }
`;
