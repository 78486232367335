import React, {
  useRef, useState, useCallback,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuth } from 'hooks/auth';
import { InfoIcon } from '../../../styles/icons';
import { useHttp } from '../../../hooks/http';
import getValidationError from '../../../utils/getValidationsErros';
import { useToast } from '../../../hooks/toast';
import {
  WrapperLabel,
  TextArea,
  Select,
  ButtonIcon,
  Button,
} from '../../../components/Forms';
import {
  GridButtons,
  WrapperBtnInfo,
  WrapperBtnCancel,
  WrapperBtnSubmit,
} from './styles';

interface IPros {
  id: string
}

const Problem: React.FC<IPros> = ({ id }) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const {
    httpPost, loading,
  } = useHttp();

  const [statusProblem] = useState([
    { value: 'ADESIVAGEM', label: 'ADESIVAGEM' },
    { value: 'PANE ELÉTRICA', label: 'PANE ELÉTRICA' },
    { value: 'VEÍCULO ENGUIÇADO', label: 'VEÍCULO ENGUIÇADO' },
    { value: 'SINISTRO', label: 'SINISTRO' },
    { value: 'ARCONDICIONADO', label: 'ARCONDICIONADO' },
    { value: 'GPS', label: 'GPS' },
    { value: 'REVISÃO', label: 'REVISÃO' },
    { value: 'FREIO', label: 'FREIO' },
    { value: 'SUSPENSÃO', label: 'SUSPENSÃO' },
    { value: 'EMBREAGEM', label: 'EMBREAGEM' },
    { value: 'PNEU', label: 'PNEU' },
    { value: 'ÓLEO', label: 'ÓLEO' },
    { value: 'ARREFECIMENTO', label: 'ARREFECIMENTO' },
    { value: 'PORTAS', label: 'PORTAS' },
    { value: 'MOTOR', label: 'MOTOR' },
    { value: 'LÂMPADAS', label: 'LÂMPADAS' },
    { value: 'PLACA', label: 'PLACA' },
    { value: 'GIROFLEX', label: 'GIROFLEX' },
    { value: 'SIRENE', label: 'GIROFLEX' },
    { value: 'RODA', label: 'RODA' },
    { value: 'AVARIA NA LATARIA', label: 'AVARIA NA LATARIA' },
    { value: 'ESTOFADO', label: 'ESTOFADO' },
    { value: 'VAZAMENTO', label: 'VAZAMENTO' },
    { value: 'OUTROS', label: 'OUTROS' }]);

  const [statusOptions] = useState([
    { value: 'REBOQUE', label: 'REBOQUE' },
    { value: 'MANUTENÇÃO', label: 'MANUTENÇÃO' },
  ]);

  const handleSubmit = useCallback(async (payload: any) => {
  // eslint-disable-next-line no-underscore-dangle
    const _fleet = {
      fleet_id: id,
      user_id: user?.id,
      problem: payload?.problem?.value,
      management_id: user?.allocation?.management_id,
      order: payload?.order?.value,
      description: payload?.description,
      situation: 'Solicitação',
    };

  formRef.current?.setErrors({});

  try {
    const schema = Yup.object().shape({
      problem: Yup.string().required('Problema é obrigatório'),
      order: Yup.string().required('Ação é obrigatório'),
    });

    await schema.validate(_fleet, { abortEarly: false });

    const { status, data } = await httpPost('fleetMechanicals', _fleet);
    if (status === 200) {
      addToast({ title: 'Salvo com sucesso!', type: 'success' });
      history.push('/maintenances');
    }
    if (status === 203) {
      addToast({ title: data.message, type: 'warning' });
      history.push('/maintenances');
    }
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      const erros = getValidationError(err);
      formRef.current?.setErrors(erros);
    }
  }
  }, [addToast, history, httpPost, id, user]);

  return (
    <>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Problema" xs={12} sm={12} md={6}>
            <Select
              name="problem"
              options={statusProblem}
            />
          </WrapperLabel>
          <WrapperLabel label="Ação" xs={12} sm={12} md={6}>
            <Select
              name="order"
              options={statusOptions}
            />
          </WrapperLabel>
          <WrapperLabel label="Observações" xs={12}>
            <TextArea row={2} name="description" />
          </WrapperLabel>
        </Grid>
        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
            >
              <InfoIcon />
            </ButtonIcon>
          </WrapperBtnInfo>
          <WrapperBtnCancel>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </WrapperBtnCancel>
          <WrapperBtnSubmit>
            <Button
              type="submit"
              isLoading={loading}
            >
              Salvar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>
      </UForm>
    </>
  );
};

export default Problem;
