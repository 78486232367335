/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const customStyle = {
  control: (base: any): any => ({
    ...base,
    '&:hover': '1px solid #ccc',
    minHeight: 40,
    border: '1px solid #ccc',
    background: 'transparent',
    boxShadow: 'none',
  }),
};

export default customStyle;
