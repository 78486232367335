import axios from 'axios';

import { IAddress } from '../../../interfaces';

export default async function resolveCoordToAddress(
  coord: {lat: number, lng: number},
): Promise<IAddress | null> {
  const uri = `latlng=${coord.lat},${coord.lng}`;

  const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?${uri}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`);

  if (response.status !== 200) {
    return null;
  }

  const { data } = response;
  const result = data.results[0];
  const address = {} as IAddress;
  try {
    address.number = result.address_components.filter((item: any) => item.types[0] === 'street_number')[0].long_name;
    address.street = result.address_components.filter((item: any) => item.types[0] === 'route')[0].long_name;
    address.neighborhood = result.address_components.filter((item: any) => item.types[0] === 'political')[0].long_name;
    address.state = result.address_components.filter((item: any) => item.types[0] === 'administrative_area_level_1')[0].short_name;
    address.city = result.address_components.filter((item: any) => item.types[0] === 'administrative_area_level_2')[0].long_name;
    address.zip = result.address_components.filter((item: any) => item.types[0] === 'postal_code')[0].long_name;
  } catch (e) {
    console.log(e);
  }

  address.latitude = parseFloat(coord.lat.toFixed(6));
  address.longitude = parseFloat(coord.lng.toFixed(6));
  return address;
}

/**
 * https://developers.google.com/maps/documentation/javascript/geocoding#ReverseGeocoding
 * https://jsfiddle.net/api/post/library/pure/
 */
