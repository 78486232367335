import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width:100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  align-items: center;
  height: 65px;
  width: 100%;

  border-bottom: 1px solid var(--color-border);
`;

export const NavLink = styled(Link)`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #000000;
  }
`;

interface IMain {
  isLoading: boolean;
}

export const Main = styled.main<IMain>`
    position: relative;
    padding: 0;
    height: calc(100vh - 65px);

    ${(props) => !props.isLoading && css`overflow-y: auto;`}

    article{
      position: absolute;
      width: 100%;
      height: 100%;

      z-index: 9998;

      display: none;
      ${(props) => props.isLoading && css`display: flex;`}

      justify-content: center;
      align-items: center;
      background: rgba(229,229,229,0.9);

      cursor: progress;
    }

    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: var(--color-bg);
    }
    ::-webkit-scrollbar-thumb {
      background: var(--color-button);
    }
    ::-webkit-scrollbar-thumb:hover {
      background: var(--color-button-h);
    }

`;

export const Alert = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-yellow);

  display:flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  p {
    display: none;
    color: var(--color-secondary);
    font-size: 1.1em;
    font-weight: 700;
  }

  width: 100%;
  height: 8px;

  transition: height 0.2s;
  &:hover{
    height: 40px;
  }


  &:hover p{
    display: block;
  }

  z-index: 9999;
`;

export const Brand = styled.div`
  padding: '24px';
  text-transform: 'uppercase';
  font-weight: 'bold';
  font-size: 14;
  letter-spacing: '1px';
  overflow: 'hidden';
  text-overflow: 'ellipsis';
  white-space: 'nowrap';
`;
