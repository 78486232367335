import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;

  padding: 8px 16px;

  form{
    width: 100%;
  }
`;

export const Button = styled.button`
  display: flex; 
  width: 100%;
  padding: 14px;
  justify-content: center;

  align-items: center;
  border: none;
  border-radius: 4px;
  color: var(--color-white);
  background: var(--color-button);

  font-size: 1.1rem;
  font-weight: 300;

  span{
    color: green;
    margin-left: 4px;
  }

  #volta{
    background-color: red;
  }

  svg{
    margin-right: 12px;
    margin-left: 12px;
  }
`;

export const TopCard = styled.div`
   width: 100%;
   border-radius: 4px;
`;

export const StepperContainer = styled.div`
  margin: 5px;
  width: 145px;
  right: 145px;
  text-align: center;
  padding: 2px 35px 20px 35px;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  border: solid 3px #999;
  border-radius: 4px;
  color: #999;
  background: #fff;
  font-size: 1.1rem;
  font-weight: 300;

  svg {
    font-size: 75px;
  }
  
  a {
    position: relative;
    left: 15px;
  }

  b{
    index: -10;
    position: relative;
    top: 10px;
    left: 25px;
    border: solid 1px #999;
    color: #fff;
    background: green;
    padding: 5px;
    border-radius: 50%;
  }

  span{
    index: -10;
    position: relative;
    top: 10px;
    left: 45px;

    svg{    
    font-size: 30px;
    }

  }

  transition: transform 0.2s;
  &:hover{
     transform: translateY(-4px);
      box-shadow: 10px 10px 8px ;
  }

`;

export const StepperContainerIcon = styled.div`
  width: 70px;
  right: 70px;
  text-align: center;
  padding: 16px 15px 16px 15px ;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  border: solid 3px #999;
  border-radius: 50%;
  color: #999;
  background: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  position: relative;
  top: 10px;
  left: 25%;
    
  svg{
    font-size: 30px;
  }

  transition: transform 0.2s;
  &:hover{
     transform: translateY(-4px);
      box-shadow: 10px 10px 8px ;
  }

`;

export const WrapperAddress = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  align-items: center;

  h3{
    color: var(--color-primary);
    margin-bottom: 8px;

  }
`;

export const Title = styled.div`
  height: 50px;
  text-align: center;
  font-size: 24px;
  align-items:center;

  span{
    font-size: 20px;
    margin-left: 8px;
    text-decoration: line-through;
  }

  svg{
    font-size: 30px;
    margin-right: 8px;
  }
`;

//--------------------------------------

export const Number = styled.div`
  margin-left: 40px;
  width: 100%;
  padding: 15px;
  font-size: 17px;
  font-weight: bold;
  color: brown;

`;

export const Caption = styled.div`
  text-align: center;
  font-size: 16px;
  align-items:center;
  font-weight: bold;

  svg{
    font-size: 30px;
    margin-right: 8px;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  margin-left: 20px;
  font-weight: lighter;
  font-weight: 100;

  a{
   font-weight: 600; 
  }

  b{
    margin-left: 10px;
    font-weight: 400;

    svg{
      font-size: 10px;
      margin-right: 8px;
    }
  }

  svg{
    font-size: 20px;
    margin-right: 8px;
  }
`;

export const IconAdd = styled.div`
  width: 60px;
  right: 60px;
  text-align: center;
  padding: 6px 8px 6px 8px ;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  border-radius: 50%;
  color: var(--color-primary);
  background: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  position: relative;
  left: 20px;
  svg{
    font-size: 40px;
  }

  &:hover{
      color: #3f51b5;
      transform: scale(1.1);
  }
`;

export const IconDel = styled.div`
  width: 60px;
  right: 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  color: var(--color-primary);
  background: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  position: relative;
  top: 18px;
  left: 60%;

  svg{
    font-size: 35px;
  }

  &:hover{
      color: #c53030;
      transform: scale(1.2);
  }
`;

export const IconFinish = styled.div`
  width: 60px;
  right: 60px;
  text-align: center;
  padding: 6px 8px 6px 8px ;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  border-radius: 50%;
  color: var(--color-primary);
  background: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  position: relative;
  top: 10px;
  left: 20px;
  svg{
    font-size: 40px;
  }
  &:hover{
      color: #34a853;
      transform: scale(1.2);
  }
`;

export const IconEdit = styled.div`
  width: 60px;
  right: 60px;
  text-align: center;
  padding: 6px 8px 6px 8px ;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  border: solid 3px #999;
  border-radius: 50%;
  color: var(--color-primary);
  background: #fff;
  font-size: 1.1rem;
  font-weight: 200;
  position: relative;
  top: 10px;
  left: 90%;
  svg{
    font-size: 40px;
  }
  &:hover{
      color: #3f51b5;
      transform: scale(1.2);
  }
`;

export const IconList = styled.div`
   
  svg{
    font-size: 30px;
    margin-right: 8px;
    color: var(--color-primary);
  }
`;

export const Icon = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  align-items:center;

  svg{
    font-size: 40px;
  }
`;


export const Subchapter = styled.div`
  text-align: left;
  font-size: 16px;
  align-items:center;
  margin-bottom: 8px;

  span{
    font-weight: bolder;
    font-weight: 500;
  }

  svg{
    font-size: 30px;
    margin-right: 18px;
  }
`;
//-------------------------------------

export const WrapperTitle = styled.div`
  border-bottom: 1px solid;
`;

export const WrapperModal = styled.div`
   color: #212529;
  .modal__inner{
    background: #FFCD39;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    svg {
      flex-shrink: 0;
      font-size: 1.5em;
    }
  }
  p{
    margin-bottom: 8px;
  }
  b{
    font-size: 1.1rem;
  }
  span{
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
`;

export const WrapperModalDelete = styled.div`
   color: #212529;
  .modal__inner{
    background: #FF9090;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    svg {
      flex-shrink: 0;
      font-size: 1.5em;
    }
  }
  p{
    margin-bottom: 8px;
  }
  b{
    font-size: 1.1rem;
  }
  span{
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
`;

export const TitleModal = styled.div`
  .delete{
    color:#fff;
    svg{
      color: brown;
  }}

  .alert{
    color: var(--color-primary-h);
  }

  font-size: 24px;
  display:flex;
  align-items:center;
  margin-bottom: 18px;

  span{
    font-size: 20px;
    margin-left: 8px;
    text-decoration: line-through;
  }

  svg{
    font-size: 30px;
    margin-right: 8px;
  }
`;

interface IProps {
  go?: any;
  off?: any;
}

export const Red = styled.div<IProps>`
  color: ${(props) => (props.go ? '#D3D3D3' : '#CC0001')};
`;

export const WrapperName = styled.div`
  font-size: 18px;
  font-weight: bold;
  display:flex;
  align-items:center;
  margin-bottom: 10px;

  span{
    font-size: 15px;
    margin-left: 8px;
  }

  svg{
    font-size: 24px;
    margin-right: 8px;
  }
`;

export const WrapperRemove = styled.div`
  width: 100%;
  font-size: 24px;
  display: flex;
  justify-content: flex-end;

  cursor: pointer;
  svg{
    font-size: 24px;
    color: brown;
  }

  &:hover{
    svg{
      color: var(--color-secondary-h);
    }
  }


`;
