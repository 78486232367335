import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import getValidationError from '../../../../utils/getValidationsErros';
import { useHttp } from '../../../../hooks/http';
import Layout from '../../../../components/Layout';
import Flex, { Grid } from '../../../../components/Flex';
import {
  WrapperLabel, Select, TextArea, Button, InputHidden, DatePicker,
} from '../../../../components/Forms';
import { SupportIcon } from '../../../../styles/icons';
import {
  Container, Title, Content,
} from './styles';
import { ISupport } from '../../../../interfaces';

const SupportTechnicianProcess: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    httpGet, httpPut, loading,
  } = useHttp();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [support, setSupport] = useState({} as ISupport);

  const [isOptions] = useState([
    { value: 'true', label: 'Sim' },
    { value: 'false', label: 'Não' },
  ]);

  useEffect(() => {
    async function load(): Promise<void> {
      const { status, data } = await httpGet(`/supports/${id}`);

      if (status !== 200) {
        history.push(`/supports/answered/detail/${id}`);
        return;
      }
      setSupport(data);
    }

    load();
  }, [history, httpGet, id]);

  const handleSubmit = useCallback(async (data: any) => {
    const process = {
      ...data,
      started_at: data?.started_at,
      ended_at: data?.ended_at,
      sorted_out: data.sorted_out?.value || null,
    };
    setBtnLoading(true);

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        started_at: Yup.string().typeError('Horário de chegada é obrigatório.'),
        ended_at: Yup.string().typeError('Horário de saída é obrigatório'),
        sorted_out: Yup.string().typeError('Campo obrigatório'),
      });

      await schema.validate(process, { abortEarly: false });

      const response: any = await httpPut(`supports/${data.id}`, {
        ...process,
      });

      if (response.status === 200) {
        history.push('/support/technician');
      }

      if (response.status !== 200) {
        setBtnLoading(false);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setBtnLoading(false);
        const erros = getValidationError(err);
          formRef.current?.setErrors(erros);
      }
    }
  }, [history, httpPut]);

  const breadcrumbs = {
    icon: SupportIcon,
    links: [{ path: '', title: 'Processar atendimento técnico' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <Title className="first">
            Dados informados para atendimento
          </Title>
          <Grid container spacing={1}>
            <Flex title="Gerência" xs={12} sm={4} textAutoSize>
              {support?.managements?.initials}
              {support?.managements?.initials && ` - ${support?.managements?.name}`}
            </Flex>
            <Flex title="Solicitado por" xs={12} sm={4} textAutoSize>
              {`${support.user?.first_name}`}
            </Flex>
            <Flex title="Contato" xs={12} sm={4} textAutoSize>
              {`${support.user?.phone}`}
            </Flex>
            <Flex title="Problema" xs={12} sm={4} md={4} textAutoSize>
              {support.problem}
            </Flex>
            <Flex title="Descrição" xs={12} sm={12} md={4}>
              {support.description || 'não informado'}
            </Flex>
          </Grid>
          <Title>
            Procedimentos
          </Title>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <InputHidden name="id" defaultValue={id} />
              <WrapperLabel label="Horário de Chegada" xs={12} md={6}>
                <DatePicker
                  name="started_at"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm"
                />
              </WrapperLabel>
              <WrapperLabel label="Horário de Saída" xs={12} md={6}>
                <DatePicker
                  name="ended_at"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm"
                />
              </WrapperLabel>
              <WrapperLabel label="O problema foi resolvido?" xs={12} sm={12} md={12}>
                <Select
                  name="sorted_out"
                  options={isOptions}
                />
              </WrapperLabel>
              <WrapperLabel label="Observação" xs={12}>
                <TextArea name="report" />
              </WrapperLabel>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" isLoading={btnLoading}>Salvar</Button>
            </Grid>
          </UForm>
        </Content>
      </Container>
    </Layout>
  );
};

export default SupportTechnicianProcess;
