import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import typeFunction from 'utils/typeFunction';
import getValidationError from '../../../utils/getValidationsErros';
import {
  validateCpf, validateID, parseDate, formatDate,
} from '../../../utils';
import { useHttp } from '../../../hooks/http';
import {
  WrapperLabel, Input, Select, Button, InputMask, InputID, InputHidden,
} from '../../../components/Forms';
import { Container } from './styles';
import { IUser, IOption } from '../../../interfaces';

interface IFormProfile {
  profile: IUser;
  roles: IOption[];

}

const Profile: React.FC<IFormProfile> = ({ profile, roles }) => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const [blockingReason, setBlockingReason] = useState(false);

  const [statusOptions] = useState([
    { value: true, label: 'Ativo' },
    { value: false, label: 'Bloqueado' },
  ]);
  const [isIntelligenceOptions] = useState([
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ]);

  const {
    httpPost, httpPut, loading,
  } = useHttp();

  useEffect(() => {
    formRef.current?.setData({
      ...profile,
      bodycans: profile.bodycans?.password,
      blocking_reason: profile.blockings
        && profile.blockings.length && profile.blockings.reverse()[0].reason,
      birthday: formatDate(profile.birthday),
      status: statusOptions.find((status) => status.value === profile.status),
      is_intelligence: isIntelligenceOptions
        .find((sIntelligence) => sIntelligence.value === profile.is_intelligence),
      gender: { value: profile.gender, label: profile.gender },
      function: { value: profile.function, label: profile.function },
      roles: profile.roles && profile.roles.map((role) => ({ value: role.id, label: role.name })),
    });
  }, [isIntelligenceOptions, profile, statusOptions]);

  const handleSubmit = useCallback(async (data: any) => {
    // eslint-disable-next-line no-underscore-dangle
    const _user = {
      ...data,
      email: data.email.toLowerCase(),
      birthday: parseDate(data.birthday),
      gender: data.gender?.value,
      function: data.function?.value,
      war_name: data.war_name.toUpperCase(),
      document: data.document.replace(/\D/g, ''),
      document_secondary: data.document_secondary.replace(/\D/g, ''),
      status: data.status?.value,
      is_intelligence: data.is_intelligence?.value,
      roles: data.roles ? data.roles.map((role: any) => role.value) : [],
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        first_name: Yup.string().required('Nome é obrigatório'),
        last_name: Yup.string().required('Sobrenome é obrigatório'),
        phone: Yup.string().required('Celular é obrigatório'),
        email: Yup.string().email('Você deve informar um e-mail válido').required('E-mail é obrigatório'),
        document: Yup.string()
          .test('is-valid', 'Insira um CPF válido', (value) => validateCpf(value, true)),
        document_secondary: Yup.string()
          .test('is-valid', 'Insira um ID Funcional válido', (value) => validateID(value, false)),
        gender: Yup.string().ensure().required('Gênero é obrigatório'),
        birthday: Yup.date().typeError('Insira uma data válida'),
        status: Yup.boolean().typeError('Status é obrigatório'),
        is_intelligence: Yup.boolean().typeError('Capacitação inteligência é obrigatório'),
        blocking_reason: Yup.string().when('status', {
          is: (status) => status === false, then: Yup.string().ensure().required('Motivo é obrigatório'),
        }),
      });

      await schema.validate(_user, { abortEarly: false });

      if (_user.id) {
        await httpPut(`users/${_user.id}`, _user);
      } else {
        const { status, data } = await httpPost('users', _user);
        if (status === 200) {
          history.push(`/user/${data.id}`);
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, []);// eslint-disable-line

  const handleFieldStatus = useCallback((status: any) => {
    setBlockingReason(!status?.value);
  }, []);

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <InputHidden name="id" />
        <Grid container spacing={1}>
          <WrapperLabel label="Função" xs={12} sm={6} md={3}>
            <Select
              name="function"
              options={typeFunction}
            />
          </WrapperLabel>

          <WrapperLabel label="Nome" xs={12} sm={6} md={4}>
            <Input name="first_name" />
          </WrapperLabel>

          <WrapperLabel label="Sobrenome" xs={12} sm={6} md={5}>
            <Input name="last_name" />
          </WrapperLabel>

          <WrapperLabel label="Nome de Escala" xs={12} sm={6} md={4}>
            <Input name="war_name" style={{ textTransform: 'uppercase' }} />
          </WrapperLabel>

          <WrapperLabel label="Celular" xs={12} sm={6} md={4}>
            <InputMask mask="(99) 99999-9999" name="phone" />
          </WrapperLabel>

          <WrapperLabel label="CPF" xs={12} sm={6} md={4}>
            <InputMask name="document" mask="999.999.999-99" />
          </WrapperLabel>

          <WrapperLabel label="ID Funcional" xs={12} sm={6} md={4}>
            <InputID name="document_secondary" />
          </WrapperLabel>

          <WrapperLabel label="E-mail" xs={12} sm={6} md={8}>
            <Input name="email" type="text" />
          </WrapperLabel>

          <WrapperLabel label="Rg" xs={12} sm={6} md={4}>
            <Input name="rg" minLength={5} />
          </WrapperLabel>

          <WrapperLabel label="Nascimento" xs={12} sm={6} md={4}>
            <InputMask name="birthday" type="tel" mask="99/99/9999" placeholder="DD/MM/YYYY" />
          </WrapperLabel>

          <WrapperLabel label="Gênero" xs={12} sm={6} md={4}>
            <Select
              name="gender"
              options={[
                { value: 'Masculino', label: 'Masculino' },
                { value: 'Feminino', label: 'Feminino' },
              ]}
            />
          </WrapperLabel>

          <WrapperLabel label="Capacitação Intel." xs={12} sm={6} md={4}>
            <Select
              name="is_intelligence"
              options={isIntelligenceOptions}
            />
          </WrapperLabel>

          <WrapperLabel label="Bodycans" xs={12} sm={6} md={4}>
            <Input name="bodycans" type="text" maxLength={6} minLength={6} />
          </WrapperLabel>

          <WrapperLabel label="Status" xs={12} sm={6} md={4}>
            <Select
              name="status"
              options={statusOptions}
              onChange={handleFieldStatus}
            />
          </WrapperLabel>

          {
            blockingReason && (
              <WrapperLabel label="Motivo do bloqueio" xs={12}>
                <Input name="blocking_reason" />
              </WrapperLabel>
            )
          }

          <WrapperLabel label="Perfis" xs={12}>
            <Select
              name="roles"
              isMulti
              options={roles}
            />
          </WrapperLabel>

        </Grid>

        <Grid container justify="flex-end">
          <Grid item xs={12} md={4}>
            <Button type="submit" isLoading={loading}>Salvar</Button>
          </Grid>
        </Grid>

      </UForm>
    </Container>
  );
};

export default Profile;
