import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  position: relative;
  height: calc(100vh - 238px);
  display: flex;
  align-items: center;
  justify-content: center;

  > div{
    display: flex;
    flex-direction: column;
  }
`;

export const WrapperMap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  background: var(--color-bg);
`;

export const WrapperTarget = styled.div`
  padding: 4px 6px;
  z-index: 4;
  border: 2px solid rgba(255, 1, 0, 0.1);
  margin-left: -1px;

  border-radius: 50%;
  svg {
    color: red;
  }
`;

export const WrapperButtons = styled.div`
  width: 100%;
`;
