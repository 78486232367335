import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import * as Yup from 'yup';
import colors from 'utils/colors';
import vehiclesBrand from 'utils/vehiclesBrand';
import originOcorrence from 'utils/originOcorrence';
import uf from 'utils/uf';
import Simple from 'components/Headers/Simple';
import { BsPencil } from 'react-icons/bs';
import ICarInvolved from 'interfaces/carInvolved';
import { CarIcon } from '../../../../styles/icons';
import {
  Container, GridButtons, WrapperAddress, WrapperBtnCancel, WrapperBtnSubmit, Title,
} from './styles';
import {
  WrapperLabel, Select, Button, Input, InputMask,
} from '../../../../components/Forms';
import { useHttp } from '../../../../hooks/http';
import Layout from 'components/Layout';
import getValidationErrors from 'utils/getValidationsErros';

interface IOptions{
  value: string;
  label: string
}

const CarInvolvedStorage: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [involvedCar, setCarInvolved] = useState<ICarInvolved>();
  const history = useHistory();
  const ROId = localStorage.getItem('ROId');
  const { id } = useParams<{ id: string }>();
  const [involved, setInvolved] = useState<IOptions[]>();
  const { httpPut, httpGet } = useHttp();
  const { user } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    async function load(): Promise<any> {
      const { status, data } = await httpGet(`/involvedCars/${id}`);
      if (status === 200) {
        setCarInvolved(data);
      }
    }
    load();
  }, [httpGet, id, user]);

  useEffect(() => {
    async function loadInvolved(): Promise<any> {
      const { status, data } = await httpGet(`/involveds/list/${ROId}`);
      if (status === 200) {
        const involvedParsed = data
          .map((involved: any) => ({
            value: involved.id,
            label: involved.name,
          }));
        involvedParsed.unshift({ value: '', label: 'Não houve envolvido' });
        setInvolved(involvedParsed);
      }
    }

    loadInvolved();
  }, [ROId, httpGet, user]);

  useEffect(() => {
    formRef.current?.setData({
      bopm_id: ROId,
      involved_id: { value: involvedCar?.involved_id, label: involvedCar?.name },
      plate: involvedCar?.plate,
      chassi: involvedCar?.chassi,
      color: { value: involvedCar?.color, label: involvedCar?.color },
      brand: { value: involvedCar?.brand, label: involvedCar?.brand },
      model: involvedCar?.model,
      motivation: { value: involvedCar?.motivation, label: involvedCar?.motivation },
      conductor: involvedCar?.conductor,
      exercise: { value: involvedCar?.exercise, label: involvedCar?.exercise },
      crlv: involvedCar?.crlv,
      year: involvedCar?.year,
      cnh: involvedCar?.cnh,
      category: { value: involvedCar?.category, label: involvedCar?.category },
      uf: { value: involvedCar?.uf, label: involvedCar?.uf },
      infringement: involvedCar?.infringement,
    });
  }, [ROId, involvedCar]);

  const handleSubmit = useCallback(async (data: any) => {
    const _carInvolved = {
      ...data,
      bopm_id: ROId,
      involved_id: data.involved_id?.value,
      plate: data.plate,
      chassi: data.chassi,
      color: data.color?.value,
      brand: data.brand?.value,
      model: data.model,
      motivation: data.motivation?.value,
      conductor: data.conductor,
      exercise: data.exercise?.value,
      crlv: data.crlv,
      year: data.year,
      cnh: data.cnh,
      category: data.category?.value,
      uf: data.uf?.value,
      infringement: data.infringement,
    };

    try {
    formRef.current?.setErrors({});
    const schema = Yup.object().shape({

      plate: Yup.string().when('chassi', {
        is: (val) => val === '',
        then: Yup.string().required('Informe uma Placa válida'),
        otherwise: Yup.string().notRequired(),
      }),
      chassi: Yup.string().notRequired(),
      color: Yup.string().required('Cor é obrigatória'),
      brand: Yup.string().required('Marca é obrigatória'),
      model: Yup.string().required('Modelo é obrigatória'),
      motivation: Yup.string().required('Motivação é obrigatória'),

    });

    await schema.validate(_carInvolved, { abortEarly: false });

    const { status } = await httpPut(`involvedCars/${id}`, _carInvolved);

    if (status === 200) {
      addToast({ title: 'Salvo com sucesso!', type: 'success' });
      history.push('/RO/carInvolved/show');
    } else {
      addToast({ title: 'Não foi possível gravar!', type: 'error' });
    }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationErrors(err);
      formRef.current?.setErrors(erros);
      }
    }
  }, [addToast, ROId, history, httpPut, id]);

  const breadcrumbs = {
    icon: CarIcon,
    links: [
      { title: 'RO-SP / Veículos Envolvidos / Editar ', path: `/RO/${ROId}` },
    ],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Simple>
          <Title>
            <BsPencil /> Editar Veículos Envolvidos
          </Title>
        </Simple>
        <WrapperAddress>
          <Container>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>

                <WrapperLabel label="Motivação" xs={12} sm={6} md={4}>
                  <Select
                    name="motivation"
                    options={originOcorrence}
                  />
                </WrapperLabel>

                <WrapperLabel label="Placa" xs={12} sm={6} md={4}>
                  <InputMask
                    name="plate"
                    mask="aaa-9*99"
                    style={{ textTransform: 'uppercase' }}
                  />
                </WrapperLabel>

                <WrapperLabel label="Chassi (Mín 5 Caracter)" xs={12} sm={6} md={4}>
                  <Input name="chassi" placeholder="" />
                </WrapperLabel>

                <WrapperLabel label="Marca" xs={12} sm={6} md={4}>
                  <Select
                    name="brand"
                    options={vehiclesBrand}
                  />
                </WrapperLabel>

                <WrapperLabel label="Modelo" xs={12} sm={6} md={4}>
                  <Input name="model" placeholder="" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

                <WrapperLabel label="Cor" xs={12} sm={6} md={4}>
                  <Select
                    name="color"
                    options={colors}
                  />
                </WrapperLabel>

                <WrapperLabel label="Ano do Veículo" xs={12} sm={6} md={4}>
                  <InputMask
                    name="year"
                    mask="9999"
                    style={{ textTransform: 'uppercase' }}
                  />
                </WrapperLabel> 

                <WrapperLabel label="CRLV" xs={12} sm={6} md={4}>
                  <InputMask
                    name="crlv"
                    mask=""
                    style={{ textTransform: 'uppercase' }}
                  />
                </WrapperLabel>

                <WrapperLabel label="Condutor" xs={12} sm={6} md={4}>
                  <Input name="conductor" placeholder="" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

                <WrapperLabel label="CNH" xs={12} sm={6} md={4}>
                  <Input name="cnh" placeholder="" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

                <WrapperLabel label="Categoria" xs={12} sm={6} md={4}>
                  <Select
                    name="category"
                    options={[
                      { value: 'A', label: 'A' },
                      { value: 'AB', label: 'AB' },
                      { value: 'AC', label: 'AC' },
                      { value: 'AD', label: 'AD' },
                      { value: 'AE', label: 'AE' },
                      { value: 'B', label: 'B' },
                      { value: 'C', label: 'C' },
                      { value: 'D', label: 'D' },
                      { value: 'E', label: 'E' },
                    ]}
                  />
                </WrapperLabel>

                <WrapperLabel label="UF" xs={12} sm={6} md={4}>
                  <Select
                    name="uf"
                    options={uf}
                  />
                </WrapperLabel>

                <WrapperLabel label="Auto de Infração" xs={12} sm={6} md={6}>
                  <Input name="infringement" placeholder="" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

                <WrapperLabel label="Relacionamento ao envolvido" xs={12} sm={6} md={6}>
                  <Select
                    name="involved_id"
                    options={involved}
                  />
                </WrapperLabel>
              </Grid>
              <GridButtons>
                <WrapperBtnCancel>
                  <Button
                    type="button"
                    onClick={() => { history.push('/RO/carInvolved/show'); }}
                  >
                    Cancelar
                  </Button>
                </WrapperBtnCancel>
                <WrapperBtnSubmit>
                  <Button
                    type="submit"
                  >
                    Salvar
                  </Button>
                </WrapperBtnSubmit>
              </GridButtons>
            </UForm>
          </Container>
        </WrapperAddress>
      </Container>
    </Layout>
  );
};

export default CarInvolvedStorage;
