import React from 'react';
import { distanceDateTime } from 'utils';
import ModalFactory from 'react-modal-promise';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { ListIcon } from 'styles/icons';
import { useHistory } from 'react-router-dom';
import Flex, { Grid } from '../../../components/Flex';
import { Container, WrapperMenu, WrapperRef } from './styles';
import { IManagement } from '../../../interfaces';
import {
  Menu, MenuItem, DotButton, ItemIcon,
} from '../../../components/CustomMenu';

interface ISupport {
  id: string;
  problem: string;
  description: string;
  created_at: string;
  server_time: string;
  status: string;
  managements: IManagement
}

const Card: React.FC<{ support: ISupport }> = ({ support }) => {
  const history = useHistory();
  const [ref] = support.id.split('-');

  return (
    <Container>
      <Grid container spacing={1}>
        <WrapperMenu>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <>
                <DotButton {...bindTrigger(popupState)} />
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => { history.push(`/supports/${support.id}`); }}
                    style={{ width: 150 }}
                  >
                    <ItemIcon icon={<ListIcon />} title="Detalhe" />
                  </MenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        </WrapperMenu>
        <Grid item xs={12} sm={12} md={2}>
          <WrapperRef>
            #{ref.toUpperCase()}
          </WrapperRef>
        </Grid>
        <Flex title="Gerência" textAutoSize xs={6} sm={6} md={4}>
          {support.managements.initials} - {support.managements.name}
          {!support.managements.status && <b> (Inativo)</b>}
        </Flex>

        <Flex title="Problema" xs={6} sm={6} md={2}>
          {support.problem}
        </Flex>

        <Flex title="Status" xs={6} sm={6} md={2}>
          {support.status}
        </Flex>

        <Flex title="Solicitado há" xs={6} sm={6} md={2}>
          {distanceDateTime(support.created_at, support.server_time, false)}
        </Flex>
      </Grid>

      <ModalFactory />
    </Container>
  );
};

export default Card;
