import styled from 'styled-components';

export const Container = styled.div`
  form {
    padding: 8px;
  }
`;
export const WrapperButton = styled.button`
  border: none;
  background: transparent;
`;
