import React, {
    useRef, useCallback, useEffect, useState
} from 'react';
import Flex from 'components/Flex';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import Accordion from '../../../../../components/Accordion';
import getValidationError from '../../../../../utils/getValidationsErros';
import { useHttp } from '../../../../../hooks/http';
import { useToast } from '../../../../../hooks/toast';
import { InfoIcon } from 'styles/icons';
import { formatDate } from '../../../../../utils';
import { ModalDialog } from '../../../../../components/ModalDialog';
import ModalFactory from 'react-modal-promise';
import {
    Button,
    ButtonIcon,
    TextArea,
    WrapperLabel,
    Input,
    InputHidden,
    Select
} from '../../../../../components/Forms';
import {
    Container,
    Content,
    GridButtons,
    WrapperBtnInfo,
    WrapperBtnCancel,
    WrapperBtnSubmit,
    WrapperAccordion
} from './styles';

interface IPropsSection {
    section: any
}

const Section: React.FC<IPropsSection> = ({ section }) => {
    const formRef = useRef<FormHandles>(null);
    const { httpPost } = useHttp();
    const { addToast } = useToast();
    const history = useHistory();
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {
        formRef.current?.setData({
            ...section,
        });
    }, [section]);

    const handleSubmit = useCallback(async (payload: any) => {
        const _section = {
            ...payload,
            opm: payload.opm?.value,
        };

        setBtnLoading(true);

        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                agent_detran: Yup.number().typeError('Ag. do Detran é obrigatório, somente números'),
                number_trailers: Yup.number().typeError('Quantidade de Reboques é obrigatório, somente números'),
                criminal_occurrences: Yup.number().typeError('Criminais Diversas é obrigatório, somente números'),
                external_occurrences: Yup.number().typeError('Ocorrências Externas é obrigatório, somente números'),
            });

            await schema.validate(_section, { abortEarly: false });

            const response = await ModalDialog({
                title: 'Aviso!',
                content: 'Confirmar para Salvar',
                buttons: 'cancel_confirm',
            });

            if (response) {
                const { status } = await httpPost(`mrcols`, _section);
                if (status === 200) {
                    addToast({ title: 'Salvo com Sucesso!', type: 'success' });
                    setBtnLoading(false);
                    history.push('/mrcols');
                } else {
                    setBtnLoading(false);
                    addToast({ title: 'Não foi possível salvar!', type: 'error' });
                    //history.push('/dashboard');
                }
            }
            setBtnLoading(false);
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
            }
        }
    }, [addToast, history, httpPost]);

    let total_celebrity = null;
    let total_criminaloccurrences = null;
    let total_thirtyfour_ml = null;
    let total_arrests_means_evidence = null;
    let total_refusal = null;

    if (section?.sectiontwo?.length !== 0) {

        const totalCelebrity = section?.sectiontwo?.map((v: any) =>
            v?.celebrities_authorities);

        total_celebrity = totalCelebrity?.reduce(function (t: any, i: any) {
            return t + i;
        });

        const totalCriminalOccurrences = section?.sectiontwo?.map((v: any) =>
            v?.criminal_occurrences);

        total_criminaloccurrences = totalCriminalOccurrences?.reduce(function (t: any, i: any) {
            return t + i;
        });

        const totalThirtyfourMl = section?.sectiontwo?.map((v: any) =>
            v?.thirtyfour_ml);

        total_thirtyfour_ml = totalThirtyfourMl?.reduce(function (t: any, i: any) {
            return t + i;
        });

        const totalArrestsMeansEvidence = section?.sectiontwo?.map((v: any) =>
            v?.arrests_means_evidence);

        total_arrests_means_evidence = totalArrestsMeansEvidence?.reduce(function (t: any, i: any) {
            return t + i;
        });

        const totalRefusal = section?.sectiontwo?.map((v: any) =>
            v?.refusal);

        total_refusal = totalRefusal?.reduce(function (t: any, i: any) {
            return t + i;
        });

    }

    return (
        <Container>
            <UForm ref={formRef} onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <InputHidden name="id" />
                    <Content>
                        <Grid container spacing={1} justify="space-between">
                            <Flex title="Equipe" xs={12} sm={4} md={4}>
                                {section?.team || 'não informado'}
                            </Flex>
                            <Flex title="Data da Operação" xs={12} sm={4} md={4}>
                                {formatDate(section?.operation_date?.split('T')[0]) || 'não informado'}
                            </Flex>
                            <Flex title="Chefe da Equipe Civil" xs={12} sm={4} md={4}>
                                {section?.user?.name || 'não informado'}
                            </Flex>
                            <Flex title="Efetivo Militar" xs={12} sm={4} md={4}>
                                {section?.segovTeamMilitar || 'não informado'}
                            </Flex>
                            <Flex title="Efetivo Civil" xs={12} sm={4} md={4}>
                                {section?.segovTeamCivil || 'não informado'}
                            </Flex>
                            <Flex title="Viaturas Ols Utilizadas na Operação" xs={12} sm={4} md={4}>
                                {section?.cars || 'não informado'}
                            </Flex>
                            <Flex title="Celebridades / Autoridades" xs={12} sm={4} md={4}>
                                {total_celebrity}
                            </Flex>
                            <Flex title="Ocorrências Criminais Alcoolemia" xs={12} sm={4} md={4}>
                                {(total_arrests_means_evidence + total_thirtyfour_ml + total_refusal)}
                            </Flex>
                            <Flex title="Ocorrências Diversas" xs={12} sm={4} md={4}>
                                {total_criminaloccurrences}
                            </Flex>
                        </Grid>
                    </Content>
                    {section?.sectiontwo?.map((v: any, index: any) => (
                        <WrapperAccordion key={v.id}>
                            <Accordion title={`Operação #${index + 1}`}>
                                <Grid container spacing={1} justify="space-between">
                                    <Flex title="End. da Operação" xs={12}>
                                        {v.addressOperation || 'não informado'}
                                    </Flex>
                                    <Flex title="Outro End. Não Listado" xs={12}>
                                        {v.another_not_listed || 'não informado'}
                                    </Flex>
                                    <Flex title="Saída do Ponto de Encontro" xs={6} sm={4} md={4}>
                                        {v.departure_meeting_point || 'não informado'}
                                    </Flex>
                                    <Flex title="Montagem da Operação" xs={6} sm={4} md={4}>
                                        {v.operation_assembly || 'não informado'}
                                    </Flex>
                                    <Flex title="Primeira Abordagem" xs={6} sm={4} md={4}>
                                        {v.first_approach || 'não informado'}
                                    </Flex>
                                    <Flex title="Encerramento" xs={6} sm={4} md={4}>
                                        {v.closing || 'não informado'}
                                    </Flex>
                                    <Flex title="Abordagem" xs={6} sm={4} md={4}>
                                        {v.approach}
                                    </Flex>
                                    <Flex title="Recondutor" xs={6} sm={4} md={4}>
                                        {v.reconductor}
                                    </Flex>
                                    <Flex title="Recusa" xs={6} sm={4} md={4}>
                                        {v.refusal}
                                    </Flex>
                                    <Flex title="Celebridades / Autoridades" xs={6} sm={4} md={4}>
                                        {v.celebrities_authorities}
                                    </Flex>
                                    <Flex title="0,00 À 0,04mg/l" xs={6} sm={4} md={4}>
                                        {v.four_ml}
                                    </Flex>
                                    <Flex title="0,05 À 0,33mg/l" xs={6} sm={4} md={4}>
                                        {v.thirtythree_ml}
                                    </Flex>
                                    <Flex title="> ou = 0,34mg/l" xs={6} sm={4} md={4}>
                                        {v.thirtyfour_ml}
                                    </Flex>
                                    <Flex title="Quantidade de Testes Passivos Realizados" xs={6} sm={4} md={4}>
                                        {v.passive_tests_performed}
                                    </Flex>

                                    <Flex title="Cnh Recolhidas" xs={6} sm={4} md={4}>
                                        {v.cnh_collected}
                                    </Flex>
                                    <Flex title="Multados" xs={6} sm={4} md={4}>
                                        {v.fined}
                                    </Flex>
                                    <Flex title="Rebocados" xs={6} sm={4} md={4}>
                                        {v.towed}
                                    </Flex>
                                    <Flex title="Deliberações de Remoção" xs={6} sm={4} md={4}>
                                        {v.removal_resolutions}
                                    </Flex>
                                    <Flex title="Prisões por outros Meios de Prova Alcoolemia" xs={6} sm={4} md={4}>
                                        {v.arrests_means_evidence}
                                    </Flex>
                                    <Flex title="Art. 307 Do CTB" xs={6} sm={4} md={4}>
                                        {v.art307}
                                    </Flex>
                                    <Flex title="Ocorrências Diversas" xs={6} sm={4} md={4}>
                                        {v.criminal_occurrences}
                                    </Flex>
                                    <Flex title="Dirigir Com CNH Cassada" xs={6} sm={4} md={4}>
                                        {v.driving_canceled_license || 'não informado'}
                                    </Flex>
                                    <Flex title="Alterações de Material/Equipamento/Viatura" xs={6} sm={4} md={4}>
                                        {v.changes_material || 'não informado'}
                                    </Flex>
                                    <Flex title="Observações Deliberalções de Veículos" xs={6} sm={4} md={4}>
                                        {v.vehicle_resolutions || 'não informado'}
                                    </Flex>
                                    <Flex title="Observações Testes Administrativos" xs={6} sm={4} md={4}>
                                        {v.administrative_tests || 'não informado'}
                                    </Flex>
                                </Grid>
                            </Accordion>
                        </WrapperAccordion>
                    ))}
                    <WrapperLabel label="Efetivo PMERJ" xs={12}>
                        <TextArea
                            row={4}
                            name="pmerj_team"
                        />
                    </WrapperLabel>
                    <WrapperLabel label="Viaturas de Apoio" xs={12}>
                        <TextArea
                            row={4}
                            name="support_vehicles"
                        />
                    </WrapperLabel>
                    <WrapperLabel label="Opm" xs={12} md={6}>
                        <Select
                            name="opm"
                            options={[
                                { value: '2º BPM', label: '2º BPM' },
                                { value: '3º BPM', label: '3º BPM' },
                                { value: '4º BPM', label: '4º BPM' },
                                { value: '5º BPM', label: '5º BPM' },
                                { value: '6º BPM', label: '6º BPM' },
                                { value: '7º BPM', label: '7º BPM' },
                                { value: '8º BPM', label: '8º BPM' },
                                { value: '9º BPM', label: '9º BPM' },
                                { value: '10º BPM', label: '10º BPM' },
                                { value: '11º BPM', label: '11º BPM' },
                                { value: '12º BPM', label: '12º BPM' },
                                { value: '14º BPM', label: '14º BPM' },
                                { value: '15º BPM', label: '15º BPM' },
                                { value: '16º BPM', label: '16º BPM' },
                                { value: '17º BPM', label: '17º BPM' },
                                { value: '18º BPM', label: '18º BPM' },
                                { value: '19º BPM', label: '19º BPM' },
                                { value: '20º BPM', label: '20º BPM' },
                                { value: '21º BPM', label: '21º BPM' },
                                { value: '22º BPM', label: '22º BPM' },
                                { value: '23º BPM', label: '23º BPM' },
                                { value: '24º BPM', label: '24º BPM' },
                                { value: '25º BPM', label: '25º BPM' },
                                { value: '26º BPM', label: '26º BPM' },
                                { value: '27º BPM', label: '27º BPM' },
                                { value: '28º BPM', label: '28º BPM' },
                                { value: '29º BPM', label: '29º BPM' },
                                { value: '30º BPM', label: '30º BPM' },
                                { value: '31º BPM', label: '31º BPM' },
                                { value: '32º BPM', label: '32º BPM' },
                                { value: '33º BPM', label: '33º BPM' },
                                { value: '34º BPM', label: '34º BPM' },
                                { value: '35º BPM', label: '35º BPM' },
                                { value: '36º BPM', label: '36º BPM' },
                                { value: '37º BPM', label: '37º BPM' },
                                { value: '38º BPM', label: '38º BPM' },
                                { value: '39º BPM', label: '39º BPM' },
                                { value: '40º BPM', label: '40º BPM' },
                                { value: '41º BPM', label: '41º BPM' },
                            ]}
                        />
                    </WrapperLabel>
                    <WrapperLabel label="Motivos para Baixa Abordagem?" xs={12} md={6}>
                        <Input name="low_approach" maxLength={300} />
                    </WrapperLabel>
                    <WrapperLabel label="Autos de Infrações da Equipe" xs={12}>
                        <Input name="violation_notices" maxLength={300} />
                    </WrapperLabel>
                    <WrapperLabel label="Especifique os AI Utilizados" xs={12}>
                        <TextArea row={4} name="specify_used" />
                    </WrapperLabel>
                    <WrapperLabel label="Alterações Diversas" xs={12}>
                        <TextArea row={4} name="miscellaneous_changes" />
                    </WrapperLabel>
                </Grid>
                <GridButtons>
                    <WrapperBtnInfo>
                        <ButtonIcon
                            title="Instruções"
                        >
                            <InfoIcon />
                        </ButtonIcon>
                    </WrapperBtnInfo>
                    <WrapperBtnCancel>
                        <Button
                            type="button"
                            onClick={() => { history.push('/dashboard'); }}
                        >
                            Cancelar
                        </Button>
                    </WrapperBtnCancel>
                    <WrapperBtnSubmit>
                        <Button
                            type="submit"
                            isLoading={btnLoading}
                        >
                            Salvar
                        </Button>
                    </WrapperBtnSubmit>
                </GridButtons>
            </UForm>
            <ModalFactory />
        </Container>
    )
}

export default Section;
