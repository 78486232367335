import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  FaChevronLeft, FaChevronRight, FaFileAlt, FaUserShield, FaRegCheckCircle, FaAsterisk,
  FaClock, FaMapMarkerAlt, FaPencilAlt, FaRegClipboard, FaRegClock, FaShieldAlt,
} from 'react-icons/fa';
import {
  makeStyles, Theme, createStyles, withStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { useHistory, useParams } from 'react-router-dom';
import { Divider, FormControlLabel, Switch } from '@material-ui/core';
import { useToast } from 'hooks/toast';
import {
  TrashIcon,
  CarIcon,
  UserFriends,
  Handcuffs,
  PlusIcon,
  PencilIcon,
  CalendarIcon,
  PoliceCarFill,
  Medicines,
  Bullets,
  Pistol,
  BoxOpen,
  UserIcon,
  CheckIcon,
} from 'styles/icons';
import { MdPlayArrow } from 'react-icons/md';
import { AiFillAlert, AiOutlineAlert, AiOutlineFileText } from 'react-icons/ai';

import { GiPoliceOfficerHead } from 'react-icons/gi';
import { IOccurrence } from 'interfaces';
import { toInteger } from 'lodash';
import { BiCheck, BiExit } from 'react-icons/bi';
import IInvolved from 'interfaces/involved';
import ISeizures from 'interfaces/seizures';
import { GrMapLocation } from 'react-icons/gr';
import ReturnCopyPrint from 'components/Headers/ReturnCopyPrint';
import stringOptions from 'utils/stringOptions';
import Modal from '../../../components/ModalSimple';
import { Grid } from '../../../components/Flex';
import Layout from '../../../components/Layout';
import { Button as ButtonMat } from '../../../components/Forms';
import { useAuth } from '../../../hooks/auth';
import { useHttp } from '../../../hooks/http';
import { WrapperGrid, WrapperName, WrapperOccurrence } from '../styles';

import {
  Container,
  Button,
  WrapperAddress,
  Title,
  StepperContainer,
  WrapperModal,
  Red,
  WrapperModalDelete,
  TitleModal,
  Caption,
  Subchapter,
  Description,
} from './styles';

interface IStatus {
  involved: string,
  civilPolice: string,
  carInvolved: string,
  seizure: string,
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const CustomizedSteppers: React.FC = () => {
  const getSteps = (): any => ['', '', '', '', ''];
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const history = useHistory();
  const { addToast } = useToast();

  const [status, setStatus] = useState<IStatus>();
  const { httpGet, httpDelete } = useHttp();
  const labelConfRef = useRef<HTMLLabelElement>(null);
  const labelConfRefDelete = useRef<HTMLLabelElement>(null);
  const switchs = useRef<HTMLLabelElement>(null);
  const switchsDelete = useRef<HTMLLabelElement>(null);
  const [occurrence, setOccurrence] = useState({} as IOccurrence);
  const [involveds, setInvolveds] = useState<IInvolved[]>();
  const [seizures, setSeizures] = useState<ISeizures[]>();
  const [btnLoading, setBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [isloadding, setIsloadding] = useState(true);
  const [btnLoadingDelete, setBtnLoadingDelete] = useState(false);
  const [disableBtnDelete, setDisableBtnDelete] = useState(false);
  const [RI, setRI] = useState(false);
  const { user } = useAuth();
  const { step } = useParams<{ step: string }>();
  const [ref] = occurrence.id ? occurrence.id?.split('-') : '';
  const copyDocument = useRef<HTMLDivElement>(null);
  localStorage.setItem('ROId', `${occurrence?.id}`);

  useEffect(() => {
    async function loadStatus(): Promise<any> {
      const { data } = await httpGet(`/bopms/status/${occurrence.id}`);
      setStatus(data);
      setActiveStep(toInteger(step));
    }
    occurrence.id && loadStatus();
  }, [httpGet, occurrence, step]);

  const loadInvolved = useCallback(async (ROId) => {
    const { status, data } = await httpGet(`/involveds/list/${ROId}`);
    if (status === 200) {
      setInvolveds(data);
    }
  }, [httpGet]);

  const loadSeizures = useCallback(async (ROId) => {
    const { status, data } = await httpGet(`/seizures/list/${ROId}`);
    if (status === 200) {
      setSeizures(data);
    }
  }, [httpGet]);

  useEffect(() => {
    async function load(): Promise<any> {
      const { status, data } = await httpGet('/bopms/simpleDetail');

      if (status === 200) {
        setOccurrence(data);
        loadInvolved(data.id);
        loadSeizures(data.id);
        setIsloadding(false);
        setRI(data?.type_of_registration === 'RI');
      } else {
        setIsloadding(false);
        history.push('/RO/occurrence/storage');
      }
    }
    load();
  }, [history, httpGet, loadInvolved, loadSeizures, user]);

  const handleCloseRO = useCallback(async (ROId) => {
    setBtnLoading(false);
    const response: any = await httpGet(`/bopms/close/${ROId}`);

    // eslint-disable-next-line no-constant-condition
    if (response.status === 200 || 204) {
      addToast({ title: `Seu RO-SP: ${response.data}`, type: 'success' });
      history.push('/RO/list');
    } else {
      addToast({ title: 'Não foi possível excluir!', type: 'error' });
      setBtnLoading(false);
      setDisableBtn(false);
    switchs.current?.click();
    labelConfRef.current?.click();
    }
  }, [addToast, history, httpGet]);

  const handleDeleteRO = useCallback(async (ROId) => {
    setBtnLoading(false);

    const response: any = await httpDelete(`/bopms/${ROId}`);

    // eslint-disable-next-line no-constant-condition
    if (response.status === 200 || 204) {
      addToast({ title: 'Seu RO-SP foi deletado com sucesso ', type: 'success' });
      history.push('/dashboard');
    } else {
      addToast({ title: 'Não foi possível excluir!', type: 'error' });
      setBtnLoadingDelete(false);
      setDisableBtnDelete(false);
    switchsDelete.current?.click();
    labelConfRefDelete.current?.click();
    }
  }, [addToast, history, httpDelete]);

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundImage:
      'linear-gradient( 136deg, rgb(211, 209, 208) 0%, rgb(165, 163, 164) 50%, rgb(150, 147, 150) 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
      'linear-gradient( 136deg, rgb(134, 129, 126) 0%, rgb(117, 115, 116) 50%, rgb(102, 99, 102) 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
  })(StepConnector);

  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 45,
      height: 45,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    hover: {
      width: 75,
      height: 75,
    },
    active: {
      backgroundImage:
      'linear-gradient( 136deg, rgb(255, 255, 255) 0%, rgb(231, 225, 227) 50%, rgb(184, 175, 184) 100%)',
      boxShadow: '0 4px 10px 0 rgba(255, 253, 253, 0.25)',
      color: '#39393a',
      border: 'solid 2px rgb(150, 147, 150)',
    },
    completed: {
      backgroundImage:
    'linear-gradient( 136deg, rgb(134, 129, 126) 0%, rgb(70, 67, 68) 50%, rgb(63, 61, 63) 100%)',
    },
  });

  const ColorlibStepIcon = (props: StepIconProps): any => {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <FaFileAlt onClick={() => { history.push('/RO/step'); }} />,
      2: <UserFriends onClick={() => { history.push('/RO/step/1'); }} />,
      3: <FaUserShield onClick={() => { history.push('/RO/step/2'); }} />,
      4: <CarIcon onClick={() => { history.push('/RO/step/3'); }} />,
      5: <Handcuffs onClick={() => { history.push('/RO/step/4'); }} />,
      6: <FaFileAlt onClick={() => { history.push('/RO/step'); }} />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  };

  const getStepContent = (step: number): any => {
    switch (step) {
      case 0:
        return 'Resumo da Ocorrência';
      case 1:
        return 'Envolvidos';
      case 2:
        return 'Autoridade';
      case 3:
        return 'Veículos Envolvidos';
      case 4:
        return 'Apreensões';
      case 5:
        return 'Resumo';
      default:
        return 'Unknown step';
    }
  };
  const handleGoToDetail = useCallback(async () => {
  labelConfRef.current?.click();
  }, []);

  const handleChangeChecked = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableBtn(event.target.checked);
  }, []);

  const handleChangeCheckedDelete = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setDisableBtnDelete(event.target.checked);
    }, [],
  );

  const handleHover = useCallback(() => {
    setBtnLoading(false);
    setDisableBtn(false);
  switchs.current?.click();
  labelConfRef.current?.click();
  }, []);

  const handleHoverDelete = useCallback(() => {
    setBtnLoadingDelete(false);
    setDisableBtnDelete(false);
  switchsDelete.current?.click();
  labelConfRefDelete.current?.click();
  }, []);

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleToCopy = () => {
    const copy = copyDocument.current?.innerText;
    navigator.clipboard.writeText(`${copy}`).then(() => {
      addToast({ title: 'Texto copiado!', type: 'info' });
    });
  };

  const breadcrumbs = {
    icon: FaFileAlt,
    links: [{ path: '/RO/list', title: 'RO-SP RESUMO' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <ReturnCopyPrint
        handleReturn={() => history.push('/dashboard')}
        hiddenButtonCopy={activeStep !== 5}
        handleCopy={() => handleToCopy()}
        hiddenButtonPrint
        handlePrint={() => ''}
      />
      {isloadding ? <p>Loading...</p> : (
        <>
          {RI === false
        && (
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label: string) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        )}
          <WrapperAddress ref={copyDocument}>
            <Container>
              {activeStep === steps.length ? (
                <div>
                  <WrapperAddress>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12}>

                        <Caption>
                          OPERAÇÃO SEGURANÇA PRESENTE
                        </Caption>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Subchapter>
                          <FaShieldAlt /><span>BASE:</span>
                        </Subchapter>
                        <Divider />
                        <br />
                        <Description><MdPlayArrow />{occurrence.base}</Description>
                        <br />
                      </Grid>

                      <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                          <Subchapter>
                            <AiFillAlert /><span>OCORRÊNCIA:</span>
                          </Subchapter>
                          <Divider />
                          <br />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Description><AiOutlineAlert />
                            { occurrence.type_of_warrant
                            && stringOptions(occurrence.type_of_warrant)
                              .map((w: any) => (<>{w.label}<span> / </span></>))}
                          </Description>
                          <br />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Description><AiOutlineFileText />
                            <b>
                              {occurrence.type_of_registration} Nº:
                            </b>{`${occurrence.registration_number} - ${occurrence.dp}`}
                          </Description>
                          <br />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Description><span>INÍCIO:</span></Description>
                          <br />
                          <Description><CalendarIcon />
                            {occurrence.started_date_at} - <FaRegClock />{occurrence.started_time_at}
                          </Description>
                          <br />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Description><span>TÉRMINO:</span></Description>
                          <br />
                          <Description><CalendarIcon />
                            {occurrence.finish_date_at} - <FaClock />{occurrence.finish_time_at}
                          </Description>
                          <br />
                        </Grid>

                      </Grid>

                      <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                          <Subchapter>
                            <GrMapLocation /><span>LOCAL:</span>
                          </Subchapter>
                          <Divider />
                          <br />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Description><FaMapMarkerAlt />
                            {occurrence.street}, {occurrence.number} - {occurrence.neighborhood}
                          </Description>
                          <br />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Description><FaPencilAlt />
                            <b>AMBIENTE ESCOLAR: </b>
                            { occurrence.school_environment === true ? 'Sim' : 'Não' }
                          </Description>
                          <br />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Subchapter>
                          <PoliceCarFill /><span>SETOR:</span>
                        </Subchapter>
                        <Divider />
                        <br />
                        <Description><MdPlayArrow />
                          { occurrence.sector
                           && stringOptions(occurrence.sector)
                             .map((s: any) => (<>{s.label}<span> / </span></>))}
                        </Description>
                        <br />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Subchapter>
                          <GiPoliceOfficerHead /><span>GUARNIÇÃO:</span>
                        </Subchapter>
                        <Divider />
                        <br />
                        { occurrence.teamService && occurrence.teamService.map((t: any) => (
                          <>
                            <Description><CheckIcon />{t.team}</Description>
                          </>
                        ))}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Subchapter>
                          <UserFriends /><span>ENVOLVIDO(S):</span>
                        </Subchapter>
                        <Divider />
                        <br />
                        <Grid container>
                          { involveds ? involveds.map((involv: any) => (
                            <>
                              <Grid item xs={12} sm={6} md={6}>
                                <Description><UserIcon />
                                  <span>{involv?.name} - </span>
                                  {involv?.type_involvement}
                                </Description>
                                <Description>
                                  <b><FaAsterisk />Mãe:
                                  </b> {involv?.mather}
                                </Description>
                                <Description>
                                  <b><FaAsterisk />Nasc:</b> {involv?.birthday}
                                </Description>
                                {involv?.document && (
                                <Description>
                                  <b><FaAsterisk />CPF:</b> {involv?.document}
                                </Description>
                              )}
                                {involv?.document_secondary && (
                                <Description>
                                  <b><FaAsterisk />RG:</b> {involv?.document_secondary}
                                </Description>
                              )}
                                <Description>
                                  <b><FaAsterisk />Situação:</b> {involv?.situation}
                                </Description>
                                <br />
                              </Grid>
                            </>
                          )) : (<Description><MdPlayArrow />NÃO HOUVE</Description>)}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Subchapter>
                          <Handcuffs /><span>APREENSÕES:</span>
                        </Subchapter>
                        <Divider />
                        <br />
                        <Grid container>
                          { seizures ? seizures.map((seizure: any) => (
                            <>
                              {seizure?.seizure_type === 'Veículos' && (
                              <>

                                <Grid item xs={12} sm={6} md={6}>
                                  <Description><CarIcon />
                                    <span>{seizure?.seizure_type}</span>
                                  </Description>
                                  {seizure?.plate ? (
                                    <Description>
                                      <b><FaAsterisk />Placa:</b> {seizure?.plate}
                                    </Description>
                              ) : (
                                <Description>
                                  <b><FaAsterisk />Chassi:</b> {seizure?.serial}
                                </Description>
                              )}
                                  <Description>
                                    <b><FaAsterisk />Marca:</b> {seizure?.brand}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Modelo:</b> {seizure?.model}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Ano:</b> {seizure?.year}
                                  </Description>
                                  <br />
                                </Grid>
                              </>
                          )}
                              {seizure.seizure_type === 'Entorpecentes' && (
                              <>
                                <Grid item xs={12} sm={6} md={6}>
                                  <Description><Medicines />
                                    <span>{seizure?.seizure_type}</span>
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Entorpecente:
                                    </b> {seizure.type}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Quantidade:</b> {seizure.amount}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Medida:</b> {seizure.measure}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Destino:</b> {seizure.destiny}
                                  </Description>
                                  <br />
                                </Grid>
                              </>
                              )}
                              {seizure.seizure_type === 'Munições' && (
                              <>
                                <Grid item xs={12} sm={6} md={6}>
                                  <Description><Bullets />
                                    <span>{seizure?.seizure_type}</span>
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Quantidade:</b> {seizure.amount}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Calibre:</b> {seizure.caliber}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Marca:</b> {seizure?.brand}
                                  </Description>
                                  <br />
                                </Grid>
                              </>
                              )}
                              {seizure.seizure_type === 'Armas' && (
                              <>
                                <Grid item xs={12} sm={6} md={6}>
                                  <Description><Pistol />
                                    <span>{seizure?.seizure_type}</span>
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Marca:</b> {seizure.brand}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Modelo:</b> {seizure.model}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Calibre:</b> {seizure.caliber}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Tipo:</b> {seizure.type}
                                  </Description>
                                  <br />
                                </Grid>
                              </>
                              )}

                              {seizure.seizure_type === 'Materiais' && (
                              <>
                                <Grid item xs={12} sm={6} md={6}>
                                  <Description><BoxOpen />
                                    <span>{seizure?.seizure_type}</span>
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Quantidade:</b>
                                    {seizure.amount}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Marca:</b> {seizure.brand}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Tipo:</b> {seizure.type}
                                  </Description>
                                  <Description>
                                    <b><FaAsterisk />Modelo:</b> {seizure.model}
                                  </Description>
                                  <br />
                                </Grid>
                              </>
                              )}
                            </>
                          )) : (<Description><MdPlayArrow />NÃO HOUVE</Description>)}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Subchapter>
                          <FaRegClipboard /><span>HISTÓRICO:</span>
                        </Subchapter>
                        <Divider />
                        <br />
                        <Description><MdPlayArrow />{occurrence.history}</Description><br />
                      </Grid>
                    </Grid>
                  </WrapperAddress>
                  <WrapperOccurrence>
                    <Grid container spacing={2} item xs={12} sm={10} md={6}>
                      <Grid item xs={12} sm={3} md={3}>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          <FaChevronLeft /> Volta
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <Button onClick={() => { handleHoverDelete(); }}>
                          Excluir <TrashIcon />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <Button onClick={() => { history.push('/dashboard'); }}>
                          Sair <BiExit />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <Button onClick={() => { handleGoToDetail(); }} className={classes.button}>
                          Encerrar<BiCheck />
                        </Button>
                      </Grid>
                    </Grid>
                  </WrapperOccurrence>
                </div>
              ) : (
                <>
                  <Caption>
                    <Title className={classes.instructions}>
                      {RI === false ? `${getStepContent(activeStep)}` : 'Registro Interno'}
                    </Title>
                  </Caption>
                  <Grid container spacing={1}>
                    {`${getStepContent(activeStep)}` === 'Resumo da Ocorrência' && (
                    <>
                      <WrapperOccurrence>
                        <Grid item xs={12} sm={10} md={6}>
                          <WrapperGrid>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={12} md={12}>
                                <WrapperName>
                                  <FaFileAlt />&nbsp;{`${occurrence?.type_of_registration} N° ${occurrence.type_of_registration === 'RI'
                                    ? ref.toUpperCase() : occurrence?.registration_number} ${RI === false ? ` - ${occurrence?.dp}` : ''}`}
                                </WrapperName>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12}>
                                <Subchapter>
                                  <FaShieldAlt /><span>BASE:</span>
                                </Subchapter>
                                <Description><MdPlayArrow />{occurrence.base}</Description>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Subchapter>
                                  <AiFillAlert /><span>{RI === false ? 'TIPO DE MANDADO:' : 'TIPO:'}</span>
                                </Subchapter>
                                <Description><MdPlayArrow />{occurrence?.type_of_warrant}</Description>
                              </Grid>
                            </Grid>
                          </WrapperGrid>
                        </Grid>
                      </WrapperOccurrence>
                    </>
                    )}
                    <WrapperOccurrence>
                      {`${getStepContent(activeStep)}` === 'Envolvidos' && status?.involved !== '00' && (
                      <StepperContainer onClick={() => { history.push('/RO/involveds/show'); }}>
                        <span><PencilIcon /></span>
                        <UserFriends />
                        Editar
                        <b>{status?.involved}</b>
                      </StepperContainer>
                      )}
                      {`${getStepContent(activeStep)}` === 'Autoridade' && status?.civilPolice !== '00' && (
                      <StepperContainer onClick={() => { history.push('/RO/polices/show'); }}>
                        <span><PencilIcon /></span>
                        <FaUserShield />
                        Editar
                        <b>{status?.civilPolice}</b>
                      </StepperContainer>
                      )}
                      {`${getStepContent(activeStep)}` === 'Veículos Envolvidos' && status?.carInvolved !== '00' && (
                      <StepperContainer onClick={() => { history.push('/RO/carInvolved/show'); }}>
                        <span><PencilIcon /></span>
                        <CarIcon />
                        Editar
                        <b>{status?.carInvolved}</b>
                      </StepperContainer>
                      )}
                      {`${getStepContent(activeStep)}` === 'Apreensões' && status?.seizure !== '00' && (
                      <StepperContainer onClick={() => { history.push('/RO/seizures/show'); }}>
                        <span><PencilIcon /></span>
                        <Handcuffs />
                        Editar
                        <b>{status?.seizure}</b>
                      </StepperContainer>
                      )}
                      {`${getStepContent(activeStep)}` === 'Envolvidos' && (
                      <StepperContainer onClick={() => { history.push('/RO/involved/storage'); }}>
                        <span><PlusIcon /></span>
                        <UserFriends />
                        Adicionar
                      </StepperContainer>
                      )}
                      {`${getStepContent(activeStep)}` === 'Autoridade' && (
                      <StepperContainer onClick={() => { history.push('/RO/polices/storage'); }}>
                        <span><PlusIcon /></span>
                        <FaUserShield />
                        Adicionar
                      </StepperContainer>
                      )}
                      {`${getStepContent(activeStep)}` === 'Veículos Envolvidos' && (
                      <StepperContainer onClick={() => { history.push('/RO/carInvolved/storage'); }}>
                        <span><PlusIcon /></span>
                        <CarIcon />
                        Adicionar
                      </StepperContainer>
                      )}
                      {`${getStepContent(activeStep)}` === 'Apreensões' && (
                      <StepperContainer onClick={() => { history.push('/RO/seizures/storage/menu'); }}>
                        <span><PlusIcon /></span>
                        <Handcuffs />
                        Adicionar
                      </StepperContainer>
                      )}
                    </WrapperOccurrence>
                  </Grid>
                  <WrapperOccurrence>
                    <Grid container spacing={1} item xs={12} sm={10} md={6}>
                      { RI === false ? (
                        <>
                          <Grid item xs={6} sm={3} md={3}>
                            <Button
                              onClick={activeStep === 0 ? () => { history.push(`/RO/occurrence/${occurrence?.id}`); } : handleBack}
                              className={classes.button}
                            >
                              {activeStep !== 0 ? <FaChevronLeft /> : <PencilIcon /> }
                              {activeStep !== 0 ? 'Voltar' : 'Editar'}
                            </Button>
                          </Grid>
                          <Grid item xs={6} sm={3} md={3}>
                            <Button color="primary" onClick={handleNext} className={classes.button}>
                              Avançar <FaChevronRight />
                            </Button>
                          </Grid>
                        </>
                      )
                        : (
                          <>
                            <Grid item xs={6} sm={3} md={3}>
                              <Button
                                onClick={() => { history.push(`/RO/occurrence/${occurrence?.id}`); }}
                                className={classes.button}
                              >
                                <PencilIcon /> Editar
                              </Button>
                            </Grid>
                            <Grid item xs={6} sm={3} md={3}>
                              <Button
                                onClick={() => { handleGoToDetail(); }}
                                className={classes.button}
                              >
                                Encerrar<BiCheck />
                              </Button>
                            </Grid>
                          </>
                        )}
                      <Grid item xs={12} sm={3} md={3}>
                        <Button onClick={() => { handleHoverDelete(); }}>
                          Excluir <TrashIcon />
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <Button onClick={() => { history.push('/dashboard'); }}>
                          Sair <BiExit />
                        </Button>
                      </Grid>
                    </Grid>

                  </WrapperOccurrence>
                </>
              )}
            </Container>
          </WrapperAddress>

          <WrapperModal>
            <label ref={labelConfRef} htmlFor="modal-conf" />
            <Modal id="modal-conf" width={520} height="auto">
              <TitleModal>
                <p className="alert"><FaRegCheckCircle /> Deseja encerrar o {RI === true ? 'Registro' : 'RO-SP'}?</p>
              </TitleModal>
              <p>
                <b>
                  {`${occurrence?.type_of_registration} N°${occurrence.type_of_registration === 'RI'
                    ? ref : occurrence?.registration_number} ${RI === false ? ` - ${occurrence?.dp}` : ''}`}
                </b>
              </p>
              <p> Base: <b>{occurrence?.base}</b></p>
              <p> Tipo {RI === false && 'de Mandado'}: <b>{occurrence?.type_of_warrant}</b> </p>
              <FormControlLabel
                ref={switchs}
                label={<Red>Confirmo as informações acima.</Red>}
                control={(
                  <Switch
                    onChange={handleChangeChecked}
                    color="primary"
                    name="checked"
                    value="false"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
            )}
              />
              <span>
                <ButtonMat onClick={handleHover}>
                  Cancelar
                </ButtonMat>
                <ButtonMat
                  type="button"
                  onClick={() => { handleCloseRO(occurrence?.id); }}
                  isLoading={btnLoading}
                  disableForce={!disableBtn}
                >
                  Encerrar
                </ButtonMat>
              </span>

            </Modal>
          </WrapperModal>

          <WrapperModalDelete>
            <label ref={labelConfRefDelete} htmlFor="modal-conf-delete" />
            <Modal id="modal-conf-delete" width={520} height="auto">

              <TitleModal>
                <p className="delete"> <TrashIcon /> Deseja excluir o {RI === true ? 'Registro' : 'RO-SP'}?</p>
              </TitleModal>

              <p>
                <b>
                  {`${occurrence?.type_of_registration} N°${occurrence.type_of_registration === 'RI'
                    ? ref : occurrence?.registration_number} ${RI === false ? ` - ${occurrence?.dp}` : ''}`}
                </b>
              </p>
              <p> Base: <b>{occurrence?.base}</b></p>
              <p> Tipo {RI === false && 'de Mandado'}: <b>{occurrence?.type_of_warrant}</b> </p>
              <FormControlLabel
                ref={switchsDelete}
                label={<Red>Confirmo as informações acima.</Red>}
                control={(
                  <Switch
                    onChange={handleChangeCheckedDelete}
                    color="primary"
                    name="checked"
                    value="false"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
            )}
              />
              <span>
                <ButtonMat onClick={handleHoverDelete}>
                  Cancelar
                </ButtonMat>
                <ButtonMat
                  type="button"
                  onClick={() => { handleDeleteRO(occurrence?.id); }}
                  isLoading={btnLoadingDelete}
                  disableForce={!disableBtnDelete}
                >
                  Excluir
                </ButtonMat>
              </span>
            </Modal>
          </WrapperModalDelete>
        </>
      )}
    </Layout>
  );
};
export default CustomizedSteppers;
