import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  formatDateTime,
} from '../../../../utils';

/** components */
import Flex, { Grid } from '../../../../components/Flex';

import {
  Container,
} from './styles';

interface ICard {
  quizs: any;
}

const Card: React.FC<ICard> = ({ quizs }) => {
  const history = useHistory();

  return (
    <Grid item xs={12}>
      <Container onClick={() => history.push(`/ceperjs/detail/${quizs.id}`)}>
        <Grid container spacing={1}>
          <Flex title="Nome" xs={6} sm={6} md={3}>
            <b> {quizs.name} </b>
          </Flex>
          <Flex title="Cidade" xs={6} sm={6} md={3}>
            <b> {quizs?.city} </b>
          </Flex>
          <Flex title="Ação" xs={6} sm={6} md={3}>
            <b> {quizs?.action?.name} </b>
          </Flex>
          <Flex title="Data" xs={6} sm={6} md={3}>
            <b> {formatDateTime(quizs.created_at)} </b>
          </Flex>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Card;
