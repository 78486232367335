import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import AsyncSelect from 'react-select/async';
import customStyle from './customStyle';
import getValidationError from '../../utils/getValidationsErros';
import { formatPlate, formatDate, formatCpf } from '../../utils';

/** hooks */
import { useHttp } from '../../hooks/http';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

/** componentes */
import Layout from '../../components/Layout';
import Header from '../../components/Headers/Simple';
import Accordion from '../../components/Accordion';
import Flex, { Grid } from '../../components/Flex';
import Modal from '../../components/ModalSimple';
import {
  WrapperLabel, TextArea, InputMask, Select, Button, ButtonIcon, InputHidden,
} from '../../components/Forms';

import { ListIcon, InfoIcon } from '../../styles/icons';
import {
  Container, Content, WrapperAccordion, WrapperModal,
} from './styles';

/** interfaces */
import { IOption, IOrder } from '../../interfaces';

const breadcrumbs = {
  icon: ListIcon,
  links: [
    { path: '/closing-forms', title: 'Formulário de encerramentos' },
    { path: '', title: 'Formulário de encerramento' },
  ],
};

const Form: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { httpGet, httpPut, loading } = useHttp();
  const { addToast } = useToast();
  const { iCan } = useAuth();
  const RefLabelConfirm = useRef<HTMLLabelElement>(null);
  const RefLabelInfo = useRef<HTMLLabelElement>(null);
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [closingForm, setClosingForm] = useState<any>();
  const [registerTypes, setRegisterTypes] = useState([]);
  const [endSituations, setEndSituations] = useState([]);
  const [motives, setMotives] = useState([]);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [upaj, setUpaj] = useState<IOption | null>(null);
  const [situation, setSituation] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const [
        resRegisterTypes,
        resEndSituations,
        resClosingForm,
        resMotives,
      ] = await Promise.all([
        httpGet('/register-types/all', undefined, false),
        httpGet('/end-situations/all', undefined, false),
        httpGet(`/closing-forms/order/${id}`, undefined, false),
        httpGet('/motives/all', undefined, false),
      ]);

      if (resRegisterTypes.data) {
        const registerTypesParsed = resRegisterTypes.data
          .map((registerType: any) => ({ value: registerType.id, label: registerType.name }));
        setRegisterTypes(registerTypesParsed);
      }

      if (resEndSituations.data) {
        const endSituationsParsed = resEndSituations.data
          .map((endSituation: any) => ({ value: endSituation.id, label: endSituation.name }));

        setEndSituations(endSituationsParsed);
      }

      if (resMotives.data) {
        const motivesParsed = resMotives.data
          .map((motive: any) => ({ value: motive.id, label: motive.name }));
        setMotives(motivesParsed);
      }

      const { status, data } = await httpGet(`/orders/detail/${id}`);
      if (status === 200) {
        const [ref] = data.id.split('-');
        setOrder({
          ...data,
          ref: ref.toUpperCase(),
        });

        const endSituationsFilteredByType = resEndSituations.data
          .filter((item: any) => item.type === data.type);

        const resEndSituationsParsed = endSituationsFilteredByType
          .map((endSituation: any) => ({ value: endSituation.id, label: endSituation.name }));
        setEndSituations(resEndSituationsParsed);
      }

      if (resClosingForm.data) {
        if (!iCan('u-closing-form') && resClosingForm.data.status === 'Respondido') {
          history.push(`/closing-forms/detail/${resClosingForm.data.order_id}`);
        }

        const {
          upaj, registerType, endSituation, motive,
        } = resClosingForm.data;
        upaj && setUpaj({ value: upaj?.id, label: upaj?.initials });

        formRef.current?.setData({
          ...resClosingForm.data,
          register_type_id: { value: registerType?.id, label: registerType?.name },
          end_situation_id: { value: endSituation?.id, label: endSituation?.name },
          motive_id: { value: motive?.id, label: motive?.name },
        });
      } else {
        history.push('/dashboard');
      }
    })();
  }, [history, httpGet, iCan, id]);

  const handleSubmit = useCallback(async (payload: any) => {
    const data = {
      ...payload,
      upaj_id: upaj?.value,
      end_situation_id: payload.end_situation_id?.value,
      register_type_id: payload.register_type_id?.value,
      motive_id: payload.motive_id?.value,
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        upaj_id: Yup.string().when('end_situation_id', {
          is: (end_situation_id) => end_situation_id === true, then: Yup.string().ensure().required('UPAJ é obrigatório'),
        }),
        register_type_id: Yup.string().ensure().required('Tipo de registro é obrigatório'),
        register_number: Yup.string().required('Número do registro é obrigatório'),
        end_situation_id: Yup.string().ensure().required('Situação final da ocorrência é obrigatória'),
        description: Yup.string().required('Dinâmica é obrigatória'),
      });

      await schema.validate(data, { abortEarly: false });

      const { status } = await httpPut(`closing-forms/${data.id}`, data);
      if (status === 200) {
        addToast({ title: 'Salvo com sucesso' });
        history.push(`/closing-forms/detail/${id}`);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [addToast, history, httpPut, id, upaj]);

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 2) return;
    const users: any = [];
    const { status, data } = await httpGet(`/upajs/filter/${inputValue}`, undefined, false);

    if (status === 200) {
      data.forEach((item: any) => {
        users.push({
          value: item.id,
          label: `${item.initials} - ${item.institution}`,
        });
      });
    }

    callback(users);
  };

  const handleChangeUpaj = useCallback((event: any) => {
    setUpaj(event);
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      let data: any = formRef.current?.getData();

      data = {
        ...data,
        end_situation_id: data?.end_situation_id?.label,
        register_type_id: data?.register_type_id?.label,
        motive_id: data?.motive_id?.label,
      };
      setClosingForm(data);

      const schema = Yup.object().shape({
        register_type_id: Yup.string().ensure().required('Tipo de registro é obrigatório'),
        register_number: Yup.string().required('Número do registro é obrigatório'),
        end_situation_id: Yup.string().ensure().required('Situação final da ocorrência é obrigatória'),
        description: Yup.string().required('Dinâmica é obrigatória'),
      });

      await schema.validate(data, { abortEarly: false });

      RefLabelConfirm.current?.click();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, []);

  const handleSituations = useCallback((situation: any) => {
    const { value } = situation;
    if (value === 4 || value === 11) {
      setSituation(true);
    } else {
      setSituation(false);
    }
  }, []);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Header> Formulário de encerramento </Header>
        <Content>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <WrapperAccordion>
              <Accordion title={`Detalhes da consulta #${order.ref}`}>
                {order.type === 'vehicle' && (
                  <Grid container spacing={1}>
                    <Flex title="Placa" xs={12} sm={6} md={3}>
                      {formatPlate(order.vehicle?.plate)}
                    </Flex>
                    <Flex title="Chassi" xs={12} sm={6} md={3}>
                      {order.vehicle?.chassi}
                    </Flex>
                    <Flex title="Marca/Modelo" xs={12} sm={6} md={3}>
                      {order.vehicle?.brand}
                    </Flex>
                    <Flex title="Cor" xs={12} sm={6} md={3}>
                      {order.vehicle?.color}
                    </Flex>
                    <Flex title="Observação" xs={12}>
                      {order.note}
                    </Flex>
                  </Grid>
                )}
                {order.type === 'person' && (
                  <Grid container spacing={1}>
                    <Flex title="Nome" xs={12} sm={6} md={3}>
                      {order.person?.name}
                    </Flex>
                    <Flex title="CPF" xs={12} sm={6} md={3}>
                      {formatCpf(order.person?.document)}
                    </Flex>
                    <Flex title="RG" xs={12} sm={6} md={3}>
                      {order.person?.document_secondary}
                    </Flex>
                    <Flex title="Nascimento" xs={12} sm={6} md={3}>
                      {formatDate(order.person?.birthday)}
                    </Flex>
                    <Flex title="Observação" xs={12}>
                      {order.note}
                    </Flex>
                  </Grid>
                )}

              </Accordion>
            </WrapperAccordion>

            <Grid container spacing={1}>
              <InputHidden name="id" />
              <WrapperLabel label="Situação final da ocorrência" xs={12}>
                <Select name="end_situation_id" options={endSituations} onChange={handleSituations} />
              </WrapperLabel>
              <WrapperLabel label="UPAJ" xs={12} sm={12} md={4}>
                <div style={{ width: '100%' }}>
                  <AsyncSelect
                    placeholder="Ex.: 001 DP"
                    noOptionsMessage={() => 'Sem registos'}
                    cacheOptions
                    isClearable
                    name="upaj_id"
                    loadOptions={loadOptions}
                    onChange={handleChangeUpaj}
                    value={upaj}
                    styles={customStyle}
                    isDisabled={situation}
                  />
                </div>
              </WrapperLabel>
              <WrapperLabel label="Tipo de registro" xs={12} sm={6} md={4}>
                <Select name="register_type_id" options={registerTypes} />
              </WrapperLabel>
              <WrapperLabel label="Número do registro" xs={12} sm={6} md={4}>
                <InputMask mask="" name="register_number" />
              </WrapperLabel>
              {order.type === 'person' && (
                <WrapperLabel label="Motivo da prisão" xs={12}>
                  <Select name="motive_id" options={motives} isDisabled={situation} isClearable />
                </WrapperLabel>
              )}
              <WrapperLabel label="Dinâmica" xs={12}>
                <TextArea name="description" row={3} />
              </WrapperLabel>
            </Grid>
            <Grid container justify="space-between" alignItems="flex-end">
              <Grid item xs={1}>
                <ButtonIcon
                  title="Instruções"
                  onClick={() => RefLabelInfo.current?.click()}
                >
                  <InfoIcon />
                </ButtonIcon>
              </Grid>
              <Grid item xs={10} md={2}>

                <Button
                  disableForce={situation ? false : !upaj?.value}
                  isLoading={loading}
                  onClick={handleConfirm}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </UForm>
        </Content>

        <label ref={RefLabelConfirm} htmlFor="modalConfirme" style={{ opacity: 0 }}> open </label>
        <Modal id="modalConfirme">
          <WrapperModal>
            <h2>Confirma os dados informados?</h2>
            <Grid container spacing={1}>
              <Flex title="UPAJ" xs={12} sm={6}>
                {upaj?.label}
              </Flex>
              <Flex title="Tipo de registro" xs={12} sm={6}>
                {closingForm?.register_type_id}
              </Flex>
              <Flex title="Número do registro" xs={12} sm={6}>
                {closingForm?.register_number}
              </Flex>
              <Flex title="Situação final da ocorrência" xs={12} sm={6}>
                {closingForm?.end_situation_id}
              </Flex>
              {order.type === 'person' && (
                <Flex title="Motivo da prisão" xs={12} sm={6}>
                  {closingForm?.motive_id}
                </Flex>
              )}
              <Flex title="Dinâmica" xs={12} sm={12}>
                {closingForm?.description}
              </Flex>
            </Grid>
            <Button onClick={() => formRef.current?.submitForm()}>Confirmar</Button>
          </WrapperModal>
        </Modal>

        <label ref={RefLabelInfo} htmlFor="modalInfo" style={{ opacity: 0 }}> abrir </label>
        <Modal id="modalInfo" height={200}>
          <WrapperModal>
            <p>Você deve preencher todos os campos deste formulário. </p>
            <p>
              Antes de salvar o formulário revise os dados informados,
              pois você não poderá editá-los após clicar em confirmar.
            </p>
          </WrapperModal>
        </Modal>
      </Container>
    </Layout>
  );
};

export default Form;
