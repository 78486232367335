import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import { Form as UForm } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Layout from 'components/Layout';
import { Grid } from '@material-ui/core';
import { Button, Select, WrapperLabel } from 'components/Forms';
import { HomeGearFillIcon } from 'styles/icons';
import { useHttp } from 'hooks/http';
import { useAuth } from 'hooks/auth';
import { Content, Container } from './styles';
import getValidationError from '../../../utils/getValidationsErros';

interface IOption {
  value: number;
  label: string;
}

interface IOptionSelect {
  value: string;
  label: string;
}

const Ceperj: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const prefix = `@${process.env.REACT_APP_NAME}`;
  const { user, refreshUser, iIs } = useAuth();
  const [actions, setActions] = useState<IOption[]>({} as IOption[]);
  const [option, setOption] = useState<Boolean>(false);
  const { httpPost } = useHttp();
  const [options, setOptions] = useState<IOptionSelect[]>([]);
  const handleSubmit = useCallback(async (data: any) => {
    let additionSchema;
    if (!data.type?.value && !data.action?.value) {
      additionSchema = {
        type: Yup.string().required('Tipo é obrigatório'),
      };
    }

    let dataSchema;
    if (!data.action?.value) {
      dataSchema = {
        name: Yup.string().required('Ação é obrigatório'),
      };
    }

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        ...additionSchema,
        ...dataSchema,
      });

      await schema.validate(data, { abortEarly: false });

      const _action = {
        name: data.action?.label,
        id: data.action?.value,
        type: data.type?.value,
      };

      const userStore = localStorage.getItem(`${prefix}:user`);
      if (userStore) {
        const userParsed = JSON.parse(userStore);
        userParsed.action = {
          name: _action.name,
          id: _action.id,
          type: _action.type,
        };

        localStorage.setItem(`${prefix}:user`, JSON.stringify(userParsed));
        refreshUser();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [prefix, refreshUser]);

  const handleFieldStore = useCallback(async (type: any) => {
    if (!user) {
      return;
    }
    const { status, data } = await httpPost('actions/search', {
      type,
    });
    if (status === 200) {
      const actions = data
        .map((action: any) => ({
          value: action.id,
          label: `${action.name}`,
        }));
      setOption(false);
      setActions(actions);
      setOption(true);
    }
  }, [httpPost, user]);

  useEffect(() => {
    if (iIs('pesquisador')) {
      setOptions([
        { value: 'SERVIÇO', label: 'Serviços-Pacto RJ' },
        { value: 'OBRA', label: 'Obras-Pacto RJ' },
        { value: 'AGUA', label: 'Qualidade da Água' },
        { value: 'PARTICIPAÇÃO', label: 'Participação Comunitária-Pacto RJ' },
      ]);
    }
    if (iIs('analista-ceperj')) {
      setOptions([
        { value: 'SERVIÇO', label: 'Serviços-Pacto RJ' },
        { value: 'OBRA', label: 'Obras-Pacto RJ' },
        { value: 'AGUA', label: 'Qualidade da Água' },
        { value: 'PARTICIPAÇÃO', label: 'Participação Comunitária-Pacto RJ' },
        { value: 'OBRAANDAMENTO', label: 'Relatório G.A.P.O.' },
      ]);
    }
    if (iIs('admin')) {
      setOptions([
        { value: 'SERVIÇO', label: 'Serviços-Pacto RJ' },
        { value: 'OBRA', label: 'Obras-Pacto RJ' },
        { value: 'AGUA', label: 'Qualidade da Água' },
        { value: 'PARTICIPAÇÃO', label: 'Participação Comunitária-Pacto RJ' },
        { value: 'OBRAANDAMENTO', label: 'Relatório G.A.P.O.' },
      ]);
    }
  }, [iIs]);

  const breadcrumbs = {
    icon: HomeGearFillIcon,
    links: [{ path: '', title: 'Ceperj' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1} justify="center">
              <WrapperLabel label="Tipo" xs={12} sm={6} md={6}>
                <Select
                  name="type"
                  options={options}
                  onChange={handleFieldStore}
                />
              </WrapperLabel>
            </Grid>
            {option && (
              <Grid container spacing={1} justify="center">
                <WrapperLabel label="Ação" xs={12} sm={6} md={6}>
                  <Select
                    name="action"
                    options={actions}
                  />
                </WrapperLabel>
              </Grid>
            )}
            <Grid container justify="center">
              <Grid item xs={6}>
                <Button type="submit">Salvar</Button>
              </Grid>
            </Grid>
          </UForm>
        </Content>
      </Container>
    </Layout>
  );
};

export default Ceperj;
