import React from 'react';
import { Container, ButtonPrint, ButtonCopy, ButtonReturn } from './styles';
import { CopyIcon, ReplyIcon } from '../../../styles/icons';
import WrapperHeader from '../WrapperHeader';
import { FiPrinter } from 'react-icons/fi';

interface ReturnSalverPrintProps{
  hiddenButtonCopy?: boolean;
  hiddenButtonPrint?: boolean;
  handleCopy():void;
  handlePrint():void;
  handleReturn():void;
}

const ReturnCopyPrint: React.FC<ReturnSalverPrintProps> = ({
  children, hiddenButtonCopy, hiddenButtonPrint, handleCopy, handlePrint, handleReturn,
}) => (
  <WrapperHeader>
    <Container>
      <h1>
        {children}
      </h1>
      <ButtonReturn>
        <ReplyIcon onClick={handleReturn} />
      </ButtonReturn>
      {!hiddenButtonCopy && (
        <ButtonCopy>
          <CopyIcon onClick={handleCopy} />
        </ButtonCopy>
      )}
      {!hiddenButtonPrint && (
        <ButtonPrint>
          <FiPrinter onClick={handlePrint} />
        </ButtonPrint>
      )}
    </Container>
  </WrapperHeader>
);

export default ReturnCopyPrint;
