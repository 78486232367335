import React from 'react';
import { Container, ButtonReturn } from './styles';
import { ReplyIcon } from '../../../styles/icons';

interface ReturnSalverPlusProps{
    hiddenButton?: boolean;
    handleReturn():void;
  }

const HeaderReturn: React.FC<ReturnSalverPlusProps> = ({
  children, handleReturn,
}) => (

  <Container>
    <h1>
      {children}
    </h1>
    <ButtonReturn>
      <ReplyIcon onClick={handleReturn} />
    </ButtonReturn>
  </Container>
);

export default HeaderReturn;
