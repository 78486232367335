import { Grid } from '@material-ui/core';
import Flex from 'components/Flex';
import React from 'react';

import { Container } from './styles';

const CardUser: React.FC<{user: any}> = ({ user }) => (
  <Container>
    <Grid container spacing={1}>
      <Flex title="Nome" textAutoSize xs={12} md={4} sm={4}>
        {user.first_name} {user.last_name}
      </Flex>

      <Flex title="Cpf" xs={12} md={2} sm={2}>
        {user.document}
      </Flex>

      <Flex title="Telefone" xs={12} md={2} sm={2}>
        {user.phone}
      </Flex>

      <Flex title="Função" xs={12} md={2} sm={2}>
        {user.r_name}
      </Flex>
    </Grid>
  </Container>
);

export default CardUser;
