import React, { useCallback, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Form as UForm } from '@unform/web';
import Flex from 'components/Flex';
import { formatDateTime } from 'utils';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { InfoIcon } from '../../../styles/icons';
import {
  WrapperLabel,
  ButtonIcon,
  Button,
  DatePicker,
} from '../../../components/Forms';
import {
  GridButtons,
  WrapperBtnInfo,
  WrapperBtnCancel,
  WrapperBtnSubmit,
  Title,
} from './styles';

interface IProps {
  trailer_plate: string,
  driver: string,
  mechanicalof: string,
  note: string,
  started_at: string,
}

interface IResult {
  result: IProps
}

const Reboque: React.FC<IResult> = ({
  result,
}) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleSubmit = useCallback(async (payload: any) => {
    console.log(payload);
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        {result.trailer_plate && (
        <>
          <Flex title="Placa Reboque" xs={6} sm={6} md={4}>
            {result.trailer_plate}
          </Flex>
          <Flex title="Motorista" xs={6} sm={6} md={4}>
            {result.driver}
          </Flex>
        </>
        )}
        <Flex title="Oficina" xs={6} sm={6} md={4}>
          {result.mechanicalof}
        </Flex>
        <Flex title="Observações" xs={6} sm={6} md={4}>
          {result.note}
        </Flex>
        <Flex title="Data/Hora" xs={12} sm={6} md={6}>
          {formatDateTime(result.started_at)}
        </Flex>
      </Grid>
      <Title />
      <Grid container spacing={1}>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <WrapperLabel label="Data/Hora retirada" xs={12} sm={12} md={6}>
              <DatePicker
                name="ended_at"
                timeInputLabel="Hora:"
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeInput
              />
            </WrapperLabel>
          </Grid>
          <GridButtons>
            <WrapperBtnInfo>
              <ButtonIcon
                title="Instruções"
              >
                <InfoIcon />
              </ButtonIcon>
            </WrapperBtnInfo>
            <WrapperBtnCancel>
              <Button
                type="button"
                onClick={() => { history.push('/dashboard'); }}
              >
                Cancelar
              </Button>
            </WrapperBtnCancel>
            <WrapperBtnSubmit>
              <Button
                type="submit"
              >
                Salvar
              </Button>
            </WrapperBtnSubmit>
          </GridButtons>
        </UForm>
      </Grid>
    </>
  );
};

export default Reboque;
