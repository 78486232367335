import { Grid } from '@material-ui/core';
import Layout from 'components/Layout';
import { useHttp } from 'hooks/http';
import React from 'react';
import { useHistory } from 'react-router-dom';

/** component */
import {
  Injustice, PersonBoundingBox, UsersIcon,
} from '../../../styles/icons';

import { Button, Container } from './styles';

const breadcrumbs = {
  icon: UsersIcon,
  links: [
    { title: 'Opções', path: '' },
  ],
};

const SelectOption: React.FC = () => {
  const history = useHistory();
  const { loading } = useHttp();

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              type="button"
              onClick={() => history.push('/order/person')}
            >
              <Injustice size={32} />
              Mandados de Prisão
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              type="button"
              onClick={() => history.push('/order/detran')}
            >
              <PersonBoundingBox size={32} />
              Identificação
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default SelectOption;
