import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';

interface IMenu {
  resource: string;
}

const Menu: React.FC<IMenu> = ({ resource }) => (
  <Container>
    <ul>
      <Link to="/settings/default">
        <li className={resource === 'default' ? 'active' : ''}>
          Sistema
        </li>
      </Link>
      <Link to="/settings/upajs">
        <li className={resource === 'upajs' ? 'active' : ''}>
          UPAJs
        </li>
      </Link>
      <Link to="/settings/register-types">
        <li className={resource === 'register-types' ? 'active' : ''}>
          Tipo de registro
        </li>
      </Link>
      <Link to="/settings/end-situations">
        <li className={resource === 'end-situations' ? 'active' : ''}>
          Situação final da ocorrência
        </li>
      </Link>
      <Link to="/settings/motives">
        <li className={resource === 'motives' ? 'active' : ''}>
          Motivos da prisão
        </li>
      </Link>
      <Link to="/settings/ceperj">
        <li className={resource === 'ceperj' ? 'active' : ''}>
          Ceperj
        </li>
      </Link>
    </ul>
  </Container>
);

export default Menu;
