import React, { useEffect, useState } from 'react';
import { useHttp } from 'hooks/http';
import Flex from 'components/Flex';
import { Container, Text } from './styles';

type IProps = {
  id: string
}

const Situation: React.FC<IProps> = ({ id }) => {
  const { httpGet } = useHttp();
  const [loading, setLoading] = useState(true);
  const [situation, setSituation] = useState('');

  useEffect(() => {
    async function loadFleets(): Promise<void> {
      const { status, data } = await httpGet(`/fleetMechanicals/situation/${id}`);

      if (status === 200) {
        setSituation(data.situation);
        setLoading(false);
      }
      setLoading(false);
    }
    loadFleets();

  }, []); // eslint-disable-line

  return (
    <Container>
      {loading && <p>carregando...</p>}
      {situation ? (
        <Flex title="Situação" xs={6} sm={6} md={4}>
          {situation === 'Finalizado' ? (
            <Text>Aguardando</Text>
          ) : (<Text>{situation}</Text>)}
        </Flex>
      ) : ''}
    </Container>
  );
};
export default Situation;
