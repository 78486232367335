import React, {
  useState, useRef, useCallback, useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import ModalFactory from 'react-modal-promise';
import ReturnSalvePlus from 'components/Headers/ReturnSalvePlus';
import { Divider, FormControlLabel, Switch } from '@material-ui/core';
import { useToast } from 'hooks/toast';
import { FaUserShield } from 'react-icons/fa';
import ICivilPolice from 'interfaces/civilPolice';
import { useHttp } from '../../../hooks/http';
import { useAuth } from '../../../hooks/auth';
import Layout from '../../../components/Layout';
import { Button as ButtonMat } from '../../../components/Forms';
import Flex, { Grid } from '../../../components/Flex';
import Card, { CardHeader, CardContent } from '../../../components/Card';
import { PencilIcon, TrashIcon } from '../../../styles/icons';
import { formatDate } from '../../../utils';

import {
  Container,
  WrapperName,
  Button,
  WrapperModal,
  Red,
} from './styles';
import Modal from '../../../components/ModalSimple';
import { TitleModal } from '../styles';

const Police: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const labelConfRef = useRef<HTMLLabelElement>(null);
  const switchs = useRef<HTMLLabelElement>(null);
  const [polices, setPolices] = useState<ICivilPolice[]>();
  const [formData, setFormData] = useState<any>();
  const history = useHistory();
  const ROId = localStorage.getItem('ROId');
  const [btnLoading, setBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { httpGet, httpDelete } = useHttp();

  useEffect(() => {
    async function loadPolices(): Promise<any> {
      const { status, data } = await httpGet(`/civilPolices/list/${ROId}`);

      if (status === 200 && data.length > 0) {
        setPolices(data);
      } else { history.push('/RO/polices/storage'); }
    }
    loadPolices();
  }, [httpGet, user, history, ROId]);

  const handleDeletePolice = useCallback(async (policeId) => {
    setBtnLoading(false);
    const response: any = await httpDelete(`/civilPolices/${policeId}`);

    // eslint-disable-next-line no-constant-condition
    if (response.status === 200 || 204) {
      addToast({ title: 'Excluído com sucesso!', type: 'success' });
      history.go(0);
    } else {
      addToast({ title: 'Não foi possível excluir!', type: 'error' });
      setBtnLoading(false);
      setDisableBtn(false);
      switchs.current?.click();
      labelConfRef.current?.click();
      formRef.current?.reset();
    }
  }, [addToast, history, httpDelete]);

  const handleGoToDetail = useCallback(async (data: any) => {
    setFormData(data);
    formRef.current?.setData({
      ...data,
      document: data.document,
      birthday: formatDate(data.birthday),
      confirmed: [{ value: null, label: '' }],
    });

    labelConfRef.current?.click();
  }, []);

  const handleChangeChecked = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableBtn(event.target.checked);
  }, []);

  const handleHover = useCallback(() => {
    setBtnLoading(false);
    setDisableBtn(false);
    switchs.current?.click();
    labelConfRef.current?.click();
    formRef.current?.reset();
  }, []);

  const breadcrumbs = {
    icon: FaUserShield,
    links: [
      { title: 'RO-SP/ Autoridade / Listar', path: '/RO/step/2' },
    ],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <ReturnSalvePlus
          handleReturn={() => history.push('/RO/step/2')}
          handlePlus={() => history.push('/RO/polices/storage')}
        />

        <Grid container justify="flex-start" spacing={1}>
          {polices?.map((police) => (
            <Grid item key={police.id} xs={12} sm={4} md={4}>
              <Card>
                <CardHeader>
                  <WrapperName>
                    <FaUserShield />&nbsp;{police.name}
                  </WrapperName>
                </CardHeader>
                <Divider />
                <CardContent>
                  <Grid container spacing={1}>
                    <Flex title="Função" xs={5} sm={6} md={6}>
                      {police.office_dp}
                    </Flex>
                    <Flex title="Matrícula" xs={5} sm={6} md={6}>
                      {police.document ? police.document : 'Não registrado.'}
                    </Flex>
                    <Flex title="DP" xs={5} sm={6} md={6}>
                      {police.initials}
                    </Flex>
                    <Flex title="Localidade" xs={5} sm={6} md={6}>
                      {police.local}
                    </Flex>
                    <Flex title="" xs={12} sm={12} md={12} />

                    <Grid item xs={6} sm={6} md={6}>
                      <Button type="button" onClick={() => { history.push(`/RO/polices/${police.id}`); }}>
                        Editar &nbsp; <PencilIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <Button type="button" onClick={() => { handleGoToDetail(police); }}>
                        Excluir &nbsp; <TrashIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <WrapperModal>
          <label ref={labelConfRef} htmlFor="modal-conf" />
          <Modal id="modal-conf" width={520} height="auto">
            <TitleModal>
              <TrashIcon />Deseja excluir a Autoridade?
            </TitleModal>
            <p> Nome: <b>{formData?.name}</b></p>
            <p> Função: <b>{formData?.office_dp}</b> </p>
            <p> Matrícula: <b>{formData?.document}</b> </p>
            <p> Dp: <b>{formData?.initials}</b> </p>

            <FormControlLabel
              ref={switchs}
              label={<Red>Confirmo as informações acima.</Red>}
              control={(
                <Switch
                  onChange={handleChangeChecked}
                  color="primary"
                  name="checked"
                  value="false"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            )}
            />
            <span>
              <ButtonMat onClick={handleHover}>
                Cancelar
              </ButtonMat>
              <ButtonMat
                type="button"
                onClick={() => { handleDeletePolice(formData?.id); }}
                isLoading={btnLoading}
                disableForce={!disableBtn}
              >
                Excluir
              </ButtonMat>
            </span>
          </Modal>
        </WrapperModal>
        <ModalFactory />
      </Container>
    </Layout>
  );
};

export default Police;
