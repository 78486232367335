/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import { session } from '../../../../../utils';

/** hooks */
import { useToast } from '../../../../../hooks/toast';

/** components */
import Flex, { Grid } from '../../../../../components/Flex';
import Layout from '../../../../../components/Layout';
import { ListIcon, PersonIcon } from '../../../../../styles/icons';

import {
  Container,
  Content, Img, WrapperHeader,
} from './styles';
/** interface */

const breadcrumbs = {
  icon: ListIcon,
  links: [{ path: '', title: 'Detalhe da  Consulta' }],
};

const Detail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const [detran, setDetran] = useState<any>([]);

  useEffect(() => {
    async function loadAnswer(): Promise<void> {
      const user = session.get('detran') as any;
      setDetran(JSON.parse(user));
    }

    loadAnswer();

    }, [addToast, history, id]);  // eslint-disable-line

  const foto = `data:image/png;base64,${detran?.fotoCivil?.string}`;

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content isConduction={!!detran.result?.conduction}>
          <WrapperHeader>
            <span>
              <PersonIcon />
              {detran.NoCidadao}
            </span>
            <h2>
              Detran: {detran.RG}
            </h2>
          </WrapperHeader>

          <Grid container spacing={1}>
            {detran?.fotoCivil && (
              <Flex title="Foto" xs={6} md={6}>
                <Img src={foto} width={100} height={120} />
              </Flex>
            )}
          </Grid>
          <Grid container spacing={1}>
            <Flex title="CPF" xs={6} sm={6} md={3}>
              {detran.CPF || 'não informado'}
            </Flex>
            <Flex title="Nascimento" xs={12} sm={6} md={4}>
              {detran.DtNascimento || 'não informado'}
            </Flex>
            <Flex title="Sexo" xs={12} sm={6} md={4}>
              {detran.Sexo && detran.Sexo === 1 ? 'MASCULINO' : 'FEMININO' }
            </Flex>
            <Flex title="Mãe" xs={6} sm={6} md={4} textAutoSize>
              {detran.NoMaeCidadao || 'não informado'}
            </Flex>
            <Flex title="Pai" xs={6} sm={6} md={4} textAutoSize>
              {detran.NoPaiCidadao || 'não informado'}
            </Flex>
            <Divider />
            <Flex title="Endereço" xs={12} sm={6} md={4}>
              {detran.EdLogradouro || 'não informado'}
            </Flex>
            <Flex title="Observação" xs={12}>
              {detran.MsgRetorno || 'não informado'}
            </Flex>
          </Grid>
        </Content>
      </Container>
    </Layout>
  );
};

export default Detail;
