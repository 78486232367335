import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** components */
import Layout from '../../../components/Layout';
import Header from '../../../components/Headers/Simple';
import { Container, Content } from './styles';
import { PeopleIcon } from '../../../styles/icons';
import { Grid } from '../../../components/Flex';
import {
  WrapperLabel, Input, DatePicker, Select, TextArea, Button,
} from '../../../components/Forms';

const breadcrumbs = {
  icon: PeopleIcon,
  links: [
    { title: 'Serviços', path: '/services' },
    { title: 'Editar Serviço', path: '' }],
};

const Update: React.FC = () => {
  const { httpGet, httpPut } = useHttp();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<{id: string}>();
  const [sectores, setSectores] = useState([]);

  useEffect(() => {
    (async () => {
      const { status: serviceStatus, data: serviceData } = await httpGet(`/services/${id}`);
      const { status: sectorsStatus, data: sectorsData } = await httpGet(`/sectors?management_id=${serviceData?.management_id}`);

      if (serviceStatus === 200) {
        formRef.current?.setData(serviceData);
      }

      if (sectorsStatus === 200) {
        const sectorsParsed = sectorsData
          .map((sector:any) => ({ value: sector.id, label: sector.initials }));
        setSectores(sectorsParsed);

        formRef.current?.setData({
          ...serviceData,
          sector_id: sectorsParsed.find((sector: any) => sector.value === serviceData.sector_id),
        });
      }
    })();
  }, [httpGet, id]);

  const handleSubmit = useCallback(async (payload: any) => {
    const data = {
      ...payload,
      sector_id: payload?.sector_id?.value,
      body_cam: payload?.body_cam.toUpperCase(),
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        started_at: Yup.date().typeError('Início do serviço e obrigatório'),
        ended_at: Yup.date().typeError('Término do serviço é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      const { status } = await httpPut(`services/${id}`, data);
      if (status === 200) {
        addToast({ title: 'Editado com sucesso.' });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
      formRef.current?.setErrors(erros);
      }
    }
  }, [httpPut, id, addToast]);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Header>Editar Serviço</Header>
        <Content>

          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <WrapperLabel label="Agente" xs={12} sm={6} md={6}>
                <Input name="user" readOnly />
              </WrapperLabel>
              <WrapperLabel label="Setor" xs={12} sm={6} md={3}>
                <Select name="sector_id" options={sectores} isClearable />
              </WrapperLabel>
              <WrapperLabel label="bodyCam" xs={12} sm={6} md={3}>
                <Input name="body_cam" />
              </WrapperLabel>
              <WrapperLabel label="Início do serviço" xs={12} sm={6} md={3}>
                <DatePicker
                  name="started_at"
                  timeInputLabel="Hora:"
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeInput
                />
              </WrapperLabel>
              <WrapperLabel label="Término do serviço" xs={12} sm={6} md={3}>
                <DatePicker
                  name="ended_at"
                  timeInputLabel="Hora:"
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeInput
                />
              </WrapperLabel>
              <WrapperLabel label="Presença confirmada em:" xs={12} sm={6} md={3}>
                <DatePicker
                  name="presence_started_at"
                  timeInputLabel="Hora:"
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeInput
                  isClearable
                />
              </WrapperLabel>
              <WrapperLabel label="Serviço encerrado em:" xs={12} sm={6} md={3}>
                <DatePicker
                  name="presence_ended_at"
                  timeInputLabel="Hora:"
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeInput
                  isClearable
                />
              </WrapperLabel>
              <WrapperLabel label="Alterações" xs={12}>
                <TextArea name="description" row={3} />
              </WrapperLabel>
            </Grid>
            <Grid container justify="flex-end">
              <Grid item xs={12} md={4}>
                <Button type="submit">Salvar</Button>
              </Grid>
            </Grid>
          </UForm>
        </Content>
      </Container>
    </Layout>
  );
};

export default Update;
