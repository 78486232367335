import styled from 'styled-components';

export const Whapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #000000;
`;

export const Content = styled.div`
  margin: 0 auto;
`;

export const Display = styled.div`
  display: flex;
  flex: 1;
  color: white;
`;

export const ContentWapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 2px;

   h3 {
    font-weight: bold;
    color: white;
   }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  padding: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
`;

export const Description = styled.div`
 text-align: justify;
  text-justify: inter-word;
  padding: 12px;
  font-size: 1rem;
  font-weight: auto;
  color: white;
`;
