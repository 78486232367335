import React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

/** hooks */

/** Componentes */
import Layout from '../../components/Layout';
import { ChartBarIcon, CsvIcon } from '../../styles/icons';
import { Container, Button } from './styles';

const breadcrumbs = {
  icon: ChartBarIcon,
  links: [
    { title: 'Estatística', path: '' },
  ],
};
const Statistic: React.FC = () => {
  const history = useHistory();

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            type="button"
            onClick={() => history.push('/statistic/extraction')}
          >
            <CsvIcon size={32} />
            Exportar Consultas
          </Button>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Statistic;
