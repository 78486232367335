import React, { useState, useEffect, useCallback } from 'react';
/** hooks */
import { useHttp } from '../../../../hooks/http';
import { Container } from './styles';

/** componentes */
import Pagination from '../../../../components/Pagination';
import Header from '../components/Header';
import Card, { Flex, Grid } from '../components/Card';
import { IPaginate } from '../../../../interfaces';

const RegisterTypes: React.FC = () => {
  const { httpGet } = useHttp();
  const [registerTypes, setRegisterTypes] = useState([]);
  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 20, lastPage: 1,
  });
  const [filter, setFilter] = useState<string|null>(null);

  const getRegisterType = useCallback(async (filter: string | null, paginate: IPaginate) => {
    const { status, data } = await httpGet('register-types', {
      params: {
        filter,
        ...paginate,
      },
    });

    if (status === 200) {
      setRegisterTypes(data.data);
      setPaginate(data.pagination);
    }
  }, [httpGet]);

  useEffect(() => {
    getRegisterType(null, { page: 1, perPage: 20 } as IPaginate);
  }, [getRegisterType]);

  const handledPagination = useCallback((payload: IPaginate) => {
    setPaginate(payload);
    getRegisterType(filter, payload);
  }, [filter, getRegisterType]);

  const handledFilter = useCallback((filter: string) => {
    setFilter(filter);
    getRegisterType(filter, { page: 1, perPage: 20 } as IPaginate);
  }, [getRegisterType]);

  return (
    <Container>
      <Header
        handledSearch={handledFilter}
        uriAdd="/settings/register-types/new"
      />
      {registerTypes && registerTypes.map((registerType: any) => (
        <Card key={registerType.id} UriUpdate={`/settings/register-types/${registerType.id}`}>
          <Grid container spacing={1}>
            <Flex textAutoSize title="Nome" xl={12}>
              {registerType.name}
            </Flex>
          </Grid>
        </Card>
      )) }
      <Pagination handlePaginate={handledPagination} paginate={paginate} />
    </Container>
  );
};

export default RegisterTypes;
