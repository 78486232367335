import styled, { css } from 'styled-components';

export const Container = styled.div`

`;

export const WrapperDotButton = styled.div`
  button{
    border: none;
    background: transparent;
  }
`;

interface IWrapperMenuItem{
  confirm?: boolean
}
export const WrapperMenuItem = styled.div<IWrapperMenuItem>`
  color: var(--color-secondary);

  &:hover{
    color: var(--color-secondary-h);
  }
  ${(props) => props.confirm && css`
    color: var(--color-red);
    &:hover{
      color: var(--color-red-h);
    }
  `}

  svg{
    margin-right: 8px;
  }


`;
