import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { formatDateTime, formatPlate } from 'utils';
import Flex from 'components/Flex';
import { useHttp } from '../../../hooks/http';
import { IFleet } from '../../../interfaces';
import { Container, Title } from './styles';
import Problem from './problem';
import Reboque from './reboque';

interface IForm {
  fleet: IFleet;
}

const Main: React.FC<IForm> = ({ fleet }) => {
  const [result, setResult] = useState<any>([]);
  const { httpGet } = useHttp();

  useEffect(() => {
    async function load(): Promise<void> {
      if (fleet.id === undefined) return;
      const { status, data } = await httpGet(`fleetMechanicals/condition/${fleet?.id}`);
      if (status === 200) {
        setResult(data);
      }
    }

    load();
  }, [fleet.id]); // eslint-disable-line

  console.log('Result ', result);

  return (
    <Container>
      <Grid container spacing={1}>
        <Flex title="Placa" xs={6} sm={6} md={4}>
          {formatPlate(fleet?.plate)}
        </Flex>
        <Flex title="Placa especial" xs={6} sm={6} md={4}>
          {fleet?.special_plate ? formatPlate(fleet?.special_plate) : 'não informado'}
        </Flex>
        <Flex title="Marca/Modelo" xs={6} sm={6} md={4}>
          {fleet?.make?.name} / {fleet?.model?.name}
        </Flex>
        <Flex title="Combustível" xs={6} sm={6} md={4} textAutoSize>
          {fleet?.fuel}
        </Flex>
        <Flex title="Cor" xs={6} sm={6} md={4}>
          {fleet?.color}
        </Flex>
        <Flex title="Status" xs={6} sm={6} md={4}>
          {fleet?.status_vehicle
            ? 'Ativa'
            : 'Bloqueada'}
        </Flex>
      </Grid>
      <Title />
      {result.situation && (
      <Grid container spacing={1}>
        <Flex title="Problema" xs={6} sm={6} md={4} textAutoSize>
          {result.problem}
        </Flex>
        <Flex title="Ação" xs={6} sm={6} md={4} textAutoSize>
          {result.order}
        </Flex>
        <Flex title="Data/Hora Solicitação" xs={6} sm={6} md={4}>
          {formatDateTime(result.created_at)}
        </Flex>
      </Grid>
      ) }
      <Title />
      {result.situation === 'Retirar' ? (
        <Reboque result={result} />
      ) : (
        <Problem id={fleet?.id} />
      )}
    </Container>
  );
};

export default Main;
