import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatCpf, formatDateTime } from '../../../utils';
/** components */
import Flex, { Grid } from '../../../components/Flex';

import { Container } from './styles';

interface ICard {
  closingForm: any;
}

const Card: React.FC<ICard> = ({ closingForm }) => {
  const history = useHistory();
  const [ref] = closingForm.order_id.split('-');
  return (
    <Grid item xs={12}>
      <Container onClick={() => history.push(`/closing-forms/order/${closingForm.order_id}`)}>
        <Grid container spacing={1}>

          <Flex title="Protocolo" xs={6} sm={6} md={3}>
            {ref.toUpperCase()}
          </Flex>
          <Flex title="UPAJ" xs={6} sm={6} md={3}>
            {closingForm.upaj}
          </Flex>
          <Flex title="Registro" xs={6} sm={6} md={3} textAutoSize>
            {closingForm.register} - {closingForm.register_number}
          </Flex>
          <Flex title="Situação final da ocorrência" xs={12} sm={6} md={3} textAutoSize>
            {closingForm.situation || 'Aguardando'}
          </Flex>

          <Flex title="Data" xs={6} sm={6} md={3} textAutoSize>
            { formatDateTime(closingForm.created_at)}
          </Flex>
          <Flex title="Gerência" xs={6} sm={6} md={3} textAutoSize>
            {closingForm.management}
            {closingForm.sector && ` - ${closingForm.sector}`}
          </Flex>
          <Flex title="Agente" xs={12} sm={6} md={4} textAutoSize>
            {formatCpf(closingForm.u_document)}, {`${closingForm.u_first_name} ${closingForm.u_last_name}` }
          </Flex>
          <Flex title="Telefone" xs={6} sm={6} md={2}>
            { closingForm?.u_phone }
          </Flex>

          <Flex title="Dinâmica" xs={12}>
            {closingForm.description}
          </Flex>

        </Grid>
      </Container>
    </Grid>
  );
};

export default Card;
