import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
`;

export const WrapperLink = styled.span`
  display: flex;
  align-items: center;

  a {
    margin-left: 8px;
    color: var(--color-primary);
    text-decoration: none;

    &:hover{
      color: var(--color-primary-h);
      text-decoration: underline;
    }
  }
  svg {
    margin-right: 4px;
  }
`;

export const WrapperProcess = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background: rgba(0,0,0,0.6);
  z-index: 2;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #ebebeb;
  font-size: 1.2em;
  font-weight: 700;

  &:hover{
    color: #fff;
  }
`;

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-top: 8px;

  font-weight: 700;

  svg {
    flex-shrink: 0;
    font-size: 24px;
    margin-right: 8px;
  }
`;
