import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import Flex from 'components/Flex';
import { format } from 'date-fns';
import { IPaginate } from 'interfaces';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router';
import AsyncSelect from 'react-select/async';
import Card, { CardContent, CardHeader } from '../../../components/Card';
import Drawer from '../../../components/Drawer';
import {
  Button, ButtonIcon, DatePicker, Select, WrapperLabel,
} from '../../../components/Forms';
import HeaderFilterDrawerSimple from '../../../components/Headers/FilterDrawerSimple';
import Layout from '../../../components/Layout';
import Pagination from '../../../components/Pagination';
import { useHttp } from '../../../hooks/http';
import { ArrowBarRightIcon, ListIcon, TrashIcon } from '../../../styles/icons';
import { formatDate } from '../../../utils';
import customStyle from './customStyle';
import { Container, DrawerContent, WrapperName } from './styles';

interface IFilter {
    id: string;
    operation_date: Date;
    team: string;
    user: string;
}

interface IOptions {
    label: string;
    value: string;
}
const LeiSeca: React.FC = () => {
  const { httpGet, loading } = useHttp();
  const formRef = useRef<FormHandles>(null);
  const RefLabelToggle = useRef<HTMLLabelElement>(null);
  const [rcols, setRcols] = useState([]);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const history = useHistory();
  const [paginate, setPaginate] = React.useState<IPaginate>({ page: 1, lastPage: 1, perPage: 10 });
  const [user, setUser] = useState<IOptions | null>(null);

  const breadcrumbs = {
    icon: ListIcon,
    links: [
      { title: 'LeiSeca - Educação', path: '' },
    ],
  };

  const getLeiSeca = useCallback(async (filter: IFilter, paginate: IPaginate) => {
    const { status, data } = await httpGet('reducols/leisecalist', {
      params: { ...filter, ...paginate },
    });

    if (status === 200) {
      setRcols(data.data);
      setPaginate(data.pagination);
    }
  }, [httpGet]);

  useEffect(() => {
    getLeiSeca(
            { id: '' } as IFilter,
            { page: 1, lastPage: 1, perPage: 10 },
    );
  }, [getLeiSeca, httpGet]);

  const handleSubmit = useCallback(async (payload: any) => {
    const filter = {
      ...payload,
      id: null,
      page: 1,
      perPage: 10,
      type_action: payload?.type_action?.value ? payload?.type_action?.value : null,
      user_id: user ? user.value : null,
      team: payload?.team?.value ? payload?.team?.value : null,
      operation_date: payload.operation_date ? format(payload.operation_date, 'yyyy-MM-dd') : null,
    };

    setFilter(filter);
    const { status, data } = await httpGet('reducols/leisecalist', {
      params: { ...filter },
    });

    if (status === 200) {
      setRcols(data.data);
      setPaginate(data.pagination);

      if (data.data.length > 0) {
                RefLabelToggle?.current?.click();
      }
    }
  }, [httpGet, user]);

  const handlePaginate = useCallback((payload: IPaginate) => {
    setPaginate(payload);
    getLeiSeca(filter, payload);
  }, [filter, getLeiSeca]);

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 3) return;
    const users: any = [];
    const { status, data } = await httpGet(`/reducols/users/${inputValue}`);

    if (status === 200) {
      data.forEach((item: any) => {
        users.push({
          value: item.id,
          label: item.name,
        });
      });
    }

    callback(users);
  };

  const handleChangeEducationAgents = useCallback((event: any) => {
    setUser(event);
  }, []);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderFilterDrawerSimple />
        <Drawer>
          <DrawerContent>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <WrapperLabel label="Data" xs={6}>
                  <DatePicker
                    name="operation_date"
                    isClearable
                  />
                </WrapperLabel>
                <WrapperLabel label="Equipe" xs={6}>
                  <Select
                    name="team"
                    options={[
                      { value: 'A', label: 'A' },
                      { value: 'B', label: 'B' },
                      { value: 'C', label: 'C' },
                      { value: 'D', label: 'D' },
                      { value: 'E', label: 'E' },
                      { value: 'F', label: 'F' },
                      { value: 'G', label: 'G' },
                      { value: 'J', label: 'J' },
                      { value: 'EXTRA I', label: 'EXTRA I' },
                      { value: 'EXTRA II', label: 'EXTRA II' },
                      { value: 'EXTRA III', label: 'EXTRA III' },
                    ]}
                  />
                </WrapperLabel>
                <WrapperLabel label="Agentes Educação / Chefe" xs={12}>
                  <div style={{ width: '100%' }}>
                    <AsyncSelect
                      placeholder="Nome"
                      noOptionsMessage={() => 'Sem registos'}
                      cacheOptions
                      isClearable
                      loadOptions={loadOptions}
                      onChange={handleChangeEducationAgents}
                      styles={customStyle}
                      value={user}
                    />
                  </div>
                </WrapperLabel>
                <WrapperLabel label="Tipo de Ação" xs={12}>
                  <Select
                    name="type_action"
                    options={[
                      { value: 'PALESTRA EMPRESA', label: 'PALESTRA EMPRESA' },
                      { value: 'PALESTRA UNIVERSIDADE', label: 'PALESTRA UNIVERSIDADE' },
                      { value: 'PALESTRA ESCOLA', label: 'PALESTRA ESCOLA' },
                      { value: 'ESCOLA NOTA 10', label: 'ESCOLA NOTA 10' },
                      { value: 'AÇÃO - FUTEBOL', label: 'AÇÃO - FUTEBOL' },
                      { value: 'AÇÃO - ESPORTES', label: 'AÇÃO - ESPORTES' },
                      { value: 'AÇÃO - BAR', label: 'AÇÃO - BAR' },
                      { value: 'AÇÃO - OUTRAS', label: 'AÇÃO - OUTRAS' },
                    ]}
                  />
                </WrapperLabel>
              </Grid>

              <Grid container spacing={1} justify="space-between" alignItems="baseline">
                <Grid item xs={10} md={4}>
                  <span style={{ display: 'flex', gap: 8, marginTop: 8 }}>
                    <ButtonIcon title="Fechar">
                      <label ref={RefLabelToggle} htmlFor="pure-toggle-right" data-toggle-label="right">
                        <ArrowBarRightIcon />
                      </label>
                    </ButtonIcon>
                    <ButtonIcon
                      title="Limpar"
                      onClick={() => {
                          formRef.current?.reset();
                      }}
                    >
                      <TrashIcon />
                    </ButtonIcon>
                  </span>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Button type="submit" isLoading={loading}> Filtrar </Button>
                </Grid>
              </Grid>
            </UForm>
          </DrawerContent>
        </Drawer>

        <Grid container spacing={1}>
          <Grid container justify="flex-start" spacing={1}>
            {rcols?.map((rcol: any) => (
              <Grid item key={rcol.id} xs={12} sm={6} md={6} onClick={() => history.push(`/leiseca/detail-educacao/${rcol.id}`)}>
                <Card>
                  <CardHeader>
                    <WrapperName>
                      {rcol.team}
                    </WrapperName>
                    {formatDate(String(rcol.operation_date).split('T')[0])}
                  </CardHeader>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Flex title="Chefe de Equipe" xs={12} sm={6} md={4}>
                        {rcol.user.name}
                      </Flex>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default LeiSeca;
