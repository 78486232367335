import styled, { css } from 'styled-components';

import Tooltip from '../../Tooltip';

interface ContainerProps{
  isFocused?: boolean;
  isFilled?: boolean;
  isErrored?: boolean;
}
export const Container = styled.div<ContainerProps>`
  background: var(--color-white);
  border-radius: 4px;
  /* padding: 8px; */
  width: 100%;
  /* border: 2 solid var(--color-red); */
  color: var(--color-gray);
  position: relative;
  display: flex;
  align-items: flex-start;
  /* flex-shrink: 1; */

  & + div{
      margin-top: 12px;
    }

  textarea{
    flex: 1;
    background: var(--color-white);;

    padding: 8px;
    border-radius:4px;
    border-color: var(--color-border);
    color: var(--color-black);
    min-height: 40px;

    /* z-index: 2; */

    ${(props) => props.isErrored && css`
      border-color: var(--color-red);
    `}

    ${(props) => props.isFocused && css`
      border-color: var(--color-grey);
    `}

    &::placeholder{
      color: #666360;
    }
  }

  span{
      font-size: 14px;
      color: var(--color-red);
      text-align: left;
      /* display: none; */
    }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  top: 8px;
  right: 8px;
  background: #FFF;

  position: absolute;
  display:none;
  visibility: hidden;
  /* z-index: 3; */

  ${(props) => props.title && css`
    display:block;
    visibility: visible;
  `}

  svg{
    margin: 0;
  }

  span {
    background: var(--color-red);
    color: #FFF;

    left: 90%;
    transform: translateX(-95%);

    &::before{
      border-color: var(--color-red) transparent;
      left: 90%;
    }
  }
`;
