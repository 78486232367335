import { isAfter } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import ModalFactory from 'react-modal-promise';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Accordion from '../../../../components/Accordion';
import Flex, { Grid } from '../../../../components/Flex';
import Layout from '../../../../components/Layout';
import { ModalOption } from '../../../../components/ModalOption';
import { useHttp } from '../../../../hooks/http';
import { useToast } from '../../../../hooks/toast';
import { ListIcon, TrashIcon } from '../../../../styles/icons';
import {
  Container, Title, WrapperAccordion, WrapperHeader,
} from './styles';

const Detail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { httpGet, httpDelete, loading } = useHttp();
  const { addToast } = useToast();
  const [reducols, setReducols] = useState<any>({} as any);

  useEffect(() => {
    async function loadAnswer(): Promise<void> {
      const { status, data } = await httpGet(`/reducols/${id}`);
      if (status === 200) {
        setReducols(data);
      }
    }
    loadAnswer();
    }, [addToast, history, id]);  // eslint-disable-line

  const breadcrumbs = {
    icon: ListIcon,
    links: [
      { path: '/reducols', title: 'Relatórios' },
      { path: '', title: 'Detalhe do Relatório' },
    ],
  };

  const handleDateBefore = useCallback((date) => {
    if (isAfter(new Date(date), new Date())) {
      return true;
    }

    return false;
  }, []);

  const handleDelete = useCallback(async (id) => {
    const response = await ModalOption({
      title: 'ATENÇÃO',
      content: 'Deseja excluir o relatório ?',
      buttons: 'sim_nao',
    });

    if (response) {
      const { status } = await httpDelete(`reducols/${id}`);
      if (status === 200) {
        addToast({ title: 'Excluído com sucesso', type: 'info' });
        history.push('/reducols');
      }
    }
  }, [history, addToast, httpDelete]);

  const dataOp = String(reducols?.operation_date).split('T')[0];
  const dataNew = dataOp.replaceAll('-', '/');
  const dataOpNew = dataNew.split('/');

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <WrapperHeader>
          <span>Equipe: {reducols?.team} </span>
          <b>Data da Ação: {`${dataOpNew[2]}/${dataOpNew[1]}/${dataOpNew[0]}`}</b>
          {handleDateBefore(reducols.created_at) && (
            <span>
              <TrashIcon
                color="red"
                onClick={() => { handleDelete(reducols?.id); }}
                cursor="pointer"
              />
            </span>
          )}
        </WrapperHeader>
        <Title>Dados da Ação:</Title>
        <Grid container spacing={1} justify="space-between">
          <Flex title="Chefe da Equipe Educação" xs={6} sm={4} md={4}>
            {reducols?.user?.name || 'não informado'}
          </Flex>
          <Flex title="Agentes PCDs" xs={6} sm={4} md={4}>
            {reducols?.educationPcd_id || 'não informado'}
          </Flex>
          <Flex title="Agentes de Educação" xs={4} sm={4} md={4}>
            {reducols?.educationAgents_id || 'não informado'}
          </Flex>
          <Flex title="Viaturas Utilizadas" xs={4} sm={4} md={4}>
            {reducols?.cars_id || 'não informado'}
          </Flex>
        </Grid>
        <Title>Quantitativo:</Title>
        <Grid container spacing={1} justify="space-between">
          <Flex title="Etilômetros(s) Utilizados(s)" xs={4} sm={4} md={4}>
            {reducols?.breathalyzers || 'não informado'}
          </Flex>
          <Flex title="Contato" xs={6} sm={4} md={4}>
            {reducols?.contact_received || 'não informado'}
          </Flex>
        </Grid>
        <Title>Ação:</Title>
        {reducols?.sectiontwo?.map((v: any, index: any) => (
          <WrapperAccordion key={v.id}>
            <Accordion title={`#${index + 1}`}>
              <Grid container spacing={1} justify="space-between">
                <Flex title="Local da Ação" xs={12}>
                  {v.place_action || 'não informado'}
                </Flex>
                <Flex title="Tipo de Ação" xs={12} sm={4} md={4}>
                  {v.type_action || 'não informado'}
                </Flex>
                <Flex title="Tipo de Público" xs={6} sm={4} md={4}>
                  {v.type_audience || 'não informado'}
                </Flex>
                <Flex title="Nome da Instituição" xs={6} sm={4} md={4}>
                  {v.institution_name || 'não informado'}
                </Flex>
                <Flex title="Horário de Início da Ação" xs={6} sm={4} md={4}>
                  {v.start_time || 'não informado'}
                </Flex>
                <Flex title="Horário de Encerramento da Ação" xs={6} sm={4} md={4}>
                  {v.final_hour || 'não informado'}
                </Flex>
                <Flex title="Abordagens Realizadas" xs={6} sm={4} md={4}>
                  {v.approach || 'não informado'}
                </Flex>
                <Flex title="Testes Realizados" xs={6} sm={4} md={4}>
                  {v.tests || 'não informado'}
                </Flex>
                <Flex title="Biqueiras Utilizadas" xs={6} sm={4} md={4}>
                  {v.used_caps || 'não informado'}
                </Flex>
                <Flex title="Biqueiras Disponíveis (restantes)" xs={6} sm={4} md={4}>
                  {v.available_caps || 'não informado'}
                </Flex>
                <Flex title="Folders Distribuídos" xs={6} sm={4} md={4}>
                  {v.distributed_folders || 'não informado'}
                </Flex>
                <Flex title="Cricris Utilizados" xs={6} sm={4} md={4}>
                  {v.cricris || 'não informado'}
                </Flex>
                <Flex title="Vetarolas Utilizadas" xs={6} sm={4} md={4}>
                  {v.vetarolas || 'não informado'}
                </Flex>
                <Flex title="Adesivos Utilizados (Colabore)" xs={6} sm={4} md={4}>
                  {v.used_adhesives || 'não informado'}
                </Flex>
                <Flex title="Sequencial de Certificados Emitidos" xs={6} sm={4} md={4}>
                  {v.sequence_certificates || 'não informado'}
                </Flex>
                <Flex title="Gibis Distribuídos" xs={6} sm={4} md={4}>
                  {v.gibis || 'não informado'}
                </Flex>
                <Flex title="Certificados Distribuídos" xs={6} sm={4} md={4}>
                  {v.distributed_certificates || 'não informado'}
                </Flex>
              </Grid>
            </Accordion>
          </WrapperAccordion>
        ))}

        <Title>Alterações:</Title>
        <Grid container spacing={1}>
          <Flex title="Alterações de Efetivo" xs={12} sm={4} md={4}>
            {reducols?.changes_staff || 'não informado'}
          </Flex>
          <Flex title="Alterações com Viatura ou Materiais" xs={12}>
            {reducols.changes_general || 'não informado'}
          </Flex>
          <Flex title="Ocorrência / Observação" xs={12}>
            {reducols.occurrence_observation || 'não informado'}
          </Flex>
        </Grid>
      </Container>
      <ModalFactory />
    </Layout>
  );
};

export default Detail;
