import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;

  padding: 8px 16px;

  form{
    width: 100%;
  }
`;

export const WrapperCore = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;

  width: calc(33.33% - 16px);
  margin: 8px;
  padding: 8px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  overflow: hidden;
  transition: transform 0.2s;

  &:hover{
    transform: translateY(-2px);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 767.98px) {
    width: 100%;
  }

`;

export const WrapperPaginate = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  margin-top: 8px;
`;

export const WrapperContent = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  p{
    display: flex;
    align-items: center;
    padding-bottom: 4px;
  }

  svg{
    margin-right: 8px;
  }

`;

export const WrapperRoles = styled.div`
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: start;

  span{
    padding: 3px 6px;
    background: var(--color-gray);
    border-radius: 4px;
    color: #fff;
    font-size: 12px;

    & + span{
      margin-left: 8px;
    }
  }
`;
