import React, {
  useEffect, useCallback, useState,
} from 'react';

import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

/** hooks */
import { useHttp } from '../../hooks/http';

/** componentes */
import Layout from '../../components/Layout';
import HeaderSavePlus from '../../components/Headers/SavePlus';
import Tabs, { TabsItem } from '../../components/Tabs';
import FormProfile from './Profile';
import FormAllocation from './Allocation';
import Blocking from './Blocking';

import { Container } from './styles';

/** interface */
import { IUser } from '../../interfaces';
import { UsersIcon } from '../../styles/icons';

const Form: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { httpGet, loading } = useHttp();
  const [user, setUser] = useState({} as IUser);
  const [roles, setRoles] = useState([]);
  const [managements, setManagements] = useState([]);

  useEffect(() => {
    async function loadRules(): Promise<void> {
      const [responseRoles, responseManagements] = await Promise.all([
        httpGet('/roles', undefined, false),
        httpGet('/managements/all', undefined, false),
      ]);

      const roles = responseRoles.data;
      if (roles) {
        setRoles(roles
          .map((role: any) => ({ value: role.id, label: role.name })));
      }

      const managements = responseManagements.data;
      if (managements) {
        setManagements(managements
          .map((management: any) => ({ value: management.id, label: management.name })));
      }
    }
    loadRules();
  }, []);// eslint-disable-line

  useEffect(() => {
    async function loadUser(): Promise<void> {
      const { data } = await httpGet(`/users/${id}`);
      setUser(data);
    }

    id && loadUser();
  }, [id]);// eslint-disable-line

  const handlePlus = useCallback(() => {
    history.push('/user');
    setUser({} as IUser);
  }, [history]);

  const breadcrumbs = {
    icon: UsersIcon,
    links: [
      { path: '/users', title: 'Usuários' },
      { path: '', title: 'Usuário' },
    ],
  };
  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderSavePlus
          hiddenButton={!id}
          handlePlus={handlePlus}
        >
          {id ? `Editar ${user.first_name}` : 'Criar usuário'}
        </HeaderSavePlus>

        <Tabs>
          <TabsItem title="Perfil" show>
            {id && <FormProfile profile={user} roles={roles} />}
            {!id && <FormProfile profile={{} as IUser} roles={roles} />}
          </TabsItem>
          <TabsItem title="Lotação" enable={!!id}>
            <FormAllocation profile={user} managements={managements} />
          </TabsItem>
          {user?.blockings?.length > 0 && (
            <TabsItem title="Bloqueio">
              <Blocking id={user.id} />
            </TabsItem>
          )}
        </Tabs>

      </Container>
    </Layout>
  );
};

export default Form;
