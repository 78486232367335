import React from 'react';

import { Container } from './HeaderStyles';

const CardHeader: React.FC = ({ children }) => (
  <Container>
    {children}
  </Container>
);

export default CardHeader;
