import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 16px;

`;

export const Title = styled.div`
  border-top: 1px solid var(--color-border);
  margin: 0 -16px;
  margin-top: 8px;
  padding: 8px;
`;

interface IContent{
  isConduction?: boolean;
}
export const Content = styled.div<IContent>`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;

  ${(props) => props.isConduction && css`border: 2px solid var(--color-red);`}
  p{
    margin: 4px 0;
  }

  footer{
    color: var(--color-secondary);
    margin: 8px 0;
  }
`;

export const WrapperSeparator = styled.span`
  + span{
    ::before{
      content: ', ';
    }
  }
`;

export const WrapperConduction = styled.div`
 width: 100%;
 text-align: center;
 padding: 4px;
 border-radius: 10px;
 background: var(--color-red);
 color: var(--color-white);
 font-weight: 700;

`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  h2{
    font-size: 1em;
    font-weight: 700;
  }
  span{
    display: flex;
    align-items: center;
    font-weight: 700;
  }
  svg{
    font-size: 24px;
    margin-right: 8px;
  }
`;

export const WrapperLink = styled.span`
  display: flex;
  align-items: center;

  a {
    margin-left: 8px;
    color: var(--color-primary);
    text-decoration: none;

    &:hover{
      color: var(--color-primary-h);
      text-decoration: underline;
    }
  }
  svg {
    margin-right: 4px;
  }
`;
