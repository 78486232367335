import styled, { css } from 'styled-components';
import { lighten } from 'polished';

interface IContainer {
  isConduction?: boolean;
  isResult?: number;
  isStatus?: string;
}

export const Container = styled.div<IContainer>`
  box-shadow: var(--shadow);
  border-radius: 4px;

  padding: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;

  ${(props) => props.isStatus === 'Aguardando'
    && css`
      border-left: 4px solid var(--color-yellow);
      border-radius: 0 4px 4px 0;
      background: ${lighten(0.2, '#FFBB34')};
      animation: pulse 0.7s infinite;
      animation-direction: alternate;
      -webkit-animation-name: pulse;
      animation-name: pulse;
    `}

    ${(props) => props.isStatus === 'Processando'
    && css`
      border-left: 4px solid var(--color-yellow);
      border-radius: 0 4px 4px 0;
      background: ${lighten(0.1, '#FFBB34')};
      animation: pulse 0.7s infinite;
      animation-direction: alternate;
      -webkit-animation-name: pulse;
      animation-name: pulse;
    `}

  ${(props) => props.isResult
    && props.isResult !== 2
    && css`
      border-left: 4px solid var(--color-yellow);
      border-radius: 0 4px 4px 0;
    `}

  ${(props) => props.isResult === 2
    && css`
      border-left: 4px solid var(--color-blue);
      border-radius: 0 4px 4px 0;
    `}

  ${(props) => props.isConduction
    && css`
      border-left: 4px solid var(--color-red);
      border-radius: 0 4px 4px 0;
      h3 {
        color: #666 !important;
      }
      background: #ffe4e1;
    `}


  transition: transform 0.2s;
  &:hover {
    transform: translateY(-4px);
  }
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  svg {
    font-size: 24px;
    margin-right: 8px;
  }

  h2 {
    font-size: 1rem;
    font-weight: 700;
  }
`;
