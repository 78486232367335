import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { formatCpf, formatDate } from '../../../utils';

/** components */
import { Container } from './styles';
import Flex from '../../../components/Flex';

import { IPerson } from '../../../interfaces';

interface ICardPerson{
  person: IPerson;
}
const Card: React.FC<ICardPerson> = ({ person }) => {
  const history = useHistory();

  const handleGoToDetail = useCallback(() => {
    history.push(`/person/${person.id}`);
  }, [history, person.id]);

  return (

    <Container onClick={handleGoToDetail}>

      <Grid container spacing={1}>
        <Flex title="Nome" xs={12} sm={5} md={3} textAutoSize>
          {person.name}
        </Flex>
        <Flex title="CPF" xs={4} sm={3} md={2} textAutoSize>
          {person.document ? formatCpf(person.document) : 'não informado'}
        </Flex>
        <Flex title="RG" xs={4} sm={2} md={1} textAutoSize>
          {person.document_secondary || 'não informado'}
        </Flex>
        <Flex title="Nascimento" xs={4} sm={2} md={1} textAutoSize>
          {person.birthday && formatDate(person.birthday)}
        </Flex>
        <Flex title="Mãe" xs={6} sm={5} md={2} textAutoSize>
          {person.mather || 'não informado'}
        </Flex>
        <Flex title="Pai" xs={6} sm={6} md={2} textAutoSize>
          {person.father || 'não informado'}
        </Flex>

      </Grid>

    </Container>

  );
};

export default Card;
