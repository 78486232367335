import React from 'react';
import CountUp from 'react-countup';

import { Container } from './styles';

interface IWalletBox {
    title: string;
    amount: number;
    color: string;
}

const WalletBoxPercent: React.FC<IWalletBox> = ({
    title,
    amount,
    color
}) => {
    return (
        <Container color={color}>
            <span>{title}</span>
            <h1>
                <CountUp
                    end={amount}
                    decimal=","
                    decimals={2}
                /> %
            </h1>
        </Container>
    );
}

export default WalletBoxPercent;