import Pagination from 'components/Pagination';
import { useHttp } from 'hooks/http';
import { IPaginate } from 'interfaces';
import React, { useEffect, useState } from 'react';
import Card from './Card';

import { Container } from './styles';

interface IProps {
  id: string;
}

const Blocking: React.FC<IProps> = ({ id }) => {
  const [blockings, setBlockings] = React.useState([]);

  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const { httpGet } = useHttp();

  useEffect(() => {
    async function loadBlockings(): Promise<void> {
      const { status, data } = await httpGet('blockings', {
        params: {
          user: id,
          ...paginate,
        },
      });

      if (status === 200) {
        setBlockings(data.data);
        setPaginate(data.pagination);
      }
    }

    loadBlockings();

  }, [paginate.page]); // eslint-disable-line

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  return (
    <Container>

      {blockings && blockings.map((blocking: any) => (
        <Card key={blocking.id} blocking={blocking} />
      ))}

      <Pagination paginate={paginate} handlePaginate={handlePaginate} />
    </Container>
  );
};

export default Blocking;
