/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PoliceCarFill } from '../../../styles/icons';
import { Container, WrapperHeader } from './styles';
import { formatDateTime } from '../../../utils';
import Flex, { Grid } from '../../../components/Flex';

interface ICard {
    bdtd: {
        active: boolean;
        marca: string;
        modelo: string;
        plate: string;
        started_at: Date;
        ended_at: Date;
    };
    supervisor: boolean;
}

const Card: React.FC<ICard> = ({ bdtd, supervisor }) => {
  const history = useHistory();

  const handleGoToDetail = useCallback((bdtd: any) => {
    if (supervisor) {
      history.push(`/sbdtd/${bdtd.id}`);
    } else if (bdtd?.active) {
      history.push('/bdtd');
    } else {
      history.push(`/bdtd/${bdtd.id}`);
    }
  }, [history, supervisor]);

  return (
    <Grid item xs={12} sm={6} md={6}>
      <Container
        onClick={() => handleGoToDetail(bdtd)}
        isResult={bdtd?.active}
      >
        <WrapperHeader>
          <span>
            <PoliceCarFill />{`${bdtd?.marca}/${bdtd?.modelo}-${bdtd?.plate}`}
          </span>
          <h2>
            {bdtd.active ? 'Aberto' : 'Fechado'}
          </h2>
        </WrapperHeader>

        <Grid container spacing={1}>
          <Flex title="Iniciado em" xs={6} md={6}>
            {formatDateTime(bdtd.started_at)}
          </Flex>
          <Flex title="Fechado em" xs={6} md={6}>
            {bdtd.ended_at ? formatDateTime(bdtd.ended_at) : 'Aguardando...'}
          </Flex>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Card;
