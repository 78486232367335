import Layout from 'components/Layout';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { ViewListIcon } from 'styles/icons';
import { useHistory } from 'react-router-dom';
/** componentes */
import HeaderSavePlus from '../../../../components/Headers/SavePlus';
import Tabs, { TabsItem } from '../../../../components/Tabs';
import { useHttp } from '../../../../hooks/http';
import Section from './Section';
import SectionTwo from './SectionTwo';
import { Container } from './styles';

interface IRcols {
    id: string;
}

const Edit: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { httpGet } = useHttp();
    const [rcols, setRcols] = useState({} as IRcols);
    const history = useHistory();

    useEffect(() => {
        setRcols({} as IRcols);
        async function loadRcols(): Promise<void> {
            const { status, data } = await httpGet(`/rcols/${id}`);
            if (status === 200) {
                setRcols(data);
            } else {
                history.push('/rcols');
            }
        }

        id && loadRcols();
    }, [id]);// eslint-disable-line

    const breadcrumbs = {
        icon: ViewListIcon,
        links: [{ path: '', title: 'Editar LeiSeca Rcols' }],
    };

    return (
        <Layout breadcrumbs={breadcrumbs}>
            <Container>
                <HeaderSavePlus
                    hiddenButton
                    handlePlus={() => { }}
                >
                    {'RCOLS - RELATÓRIO OPERACIONAL CIVIL'}
                </HeaderSavePlus>
                <Tabs>
                    <TabsItem title="Seção I" show>
                        <Section section={rcols} />
                    </TabsItem>
                    <TabsItem title="Seção II">
                        <SectionTwo section={rcols} />
                    </TabsItem>
                </Tabs>
            </Container>
        </Layout>
    );
}

export default Edit;