/* eslint-disable no-param-reassign */
import React, {
  useEffect, useRef, useState, useCallback, InputHTMLAttributes,
} from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error, WrapperCheck } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: {
    // id: string;
    value: string | number;
    label: string;
  }[];
}

const InputCheckbox: React.FC<Props> = ({ name, options, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const {
    fieldName, registerField, defaultValue = [], error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => refs
        .filter((ref) => ref.checked).map((ref) => ref.value),
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach((ref: any) => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach((ref: any) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  const handleFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);
  return (
    <Container
      isErrored={!!error}
      isFocused={isFocused}
      onFocus={handleFocused}
      onBlur={handleInputBlur}
    >
      <WrapperCheck>
        {options.map((option, index) => (
          <label htmlFor={option.value.toString()} key={option.value.toString()}>
            <input
              defaultChecked={defaultValue
                .find((dv: string | number) => dv === option.value.toString())}
              ref={(ref) => {
                inputRefs.current[index] = ref as HTMLInputElement;
              }}
              value={option.value}
              type="checkbox"
              id={option.value.toString()}
              {...rest}
            />
            <span>
              {option.label}
            </span>
          </label>
        ))}
      </WrapperCheck>

      <Error title={error || ''}>
        <FiAlertCircle color="#c53030" size={20} />
      </Error>
    </Container>
  );
};

export default InputCheckbox;
