import Flex, { Grid } from 'components/Flex';
import {
  Button,
  ButtonIcon, Input, InputMask, TextArea, WrapperLabel,
} from 'components/Forms';
import Layout from 'components/Layout';
import { useHttp } from 'hooks/http';
import { useToast } from 'hooks/toast';
import { IOrder } from 'interfaces';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useHistory, useParams } from 'react-router';
import { InfoIcon, WarningIcon } from 'styles/icons';
import { formatPlate, validateCpf } from 'utils';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { Box } from '@material-ui/core';
import getValidationError from 'utils/getValidationsErros';
import getCurrentPosition from 'utils/getCurrentPosition';
import * as Yup from 'yup';
import Modal from '../../../../components/ModalSimple';
import {
  Container, Content, GridButtons, WrapperBtnCancel, WrapperBtnInfo, WrapperBtnSubmit,
} from './styles';

const Alert: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { httpGet, loading } = useHttp();
  const { addToast } = useToast();
  const [order, setOrder] = useState<IOrder>({} as IOrder);

  useEffect(() => {
    async function loadAnswer(): Promise<void> {
      const { status, data } = await httpGet(`orders/detail/${id}`);
      if (status === 200) {
        const [ref] = data.id.split('-');
        setOrder({
          ...data,
          ref: ref.toUpperCase(),
        });
      } else {
        addToast({ title: 'Erro ao tentar acessar a consulta.', type: 'error' });
        history.push('/orders/me');
      }
    }

    loadAnswer();

  }, [addToast, history, id]);  // eslint-disable-line

  const handleSubmit = useCallback(async (data: any) => {
    const alertSubmit = {
      ...data,
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        phone: Yup.string().required('Celular é obrigatório'),
        document: Yup.string()
          .test('is-valid', 'Insira um CPF válido', (value) => validateCpf(value, true)),
        description: Yup.string().required('Insira breve relato'),
      });
      await schema.validate(alertSubmit, { abortEarly: false });

      setBtnLoading(true);
      const position = await getCurrentPosition();
      const _alert = {
        ...alertSubmit,
        current_lat: position.lat,
        current_lng: position.lng,
      };
      console.log('_alert ', _alert);
      setBtnLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }

      setBtnLoading(false);
    }
  }, []);

  const breadcrumbs = {
    icon: WarningIcon,
    links: [{ path: '', title: 'Alerta Roubo-Furto' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <Box border={1} p={2} borderRadius={4} mb={4}>
            <Grid container spacing={1}>
              <Flex title="Placa" xs={6} sm={4} md={4}>
                {order.plate ? formatPlate(order.plate) : 'não informado'}
              </Flex>
              <Flex title="Marca/Modelo" xs={6} sm={4} md={4}>
                {order.brand || 'não informado'}
              </Flex>
              <Flex title="Cor" xs={6} sm={4} md={4}>
                {order.color || 'não informada'}
              </Flex>
            </Grid>
          </Box>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <WrapperLabel label="CPF" xs={12}>
              <InputMask
                name="document"
                type="tel"
                mask="999.999.999-99"
              />
            </WrapperLabel>
            <WrapperLabel label="Nome Completo" xs={12}>
              <Input name="name" style={{ textTransform: 'uppercase' }} />
            </WrapperLabel>
            <WrapperLabel label="Celular" xs={12}>
              <InputMask mask="(99) 99999-9999" name="phone" />
            </WrapperLabel>
            <WrapperLabel label="Logradouro" xs={12}>
              <Input name="street" />
            </WrapperLabel>
            <WrapperLabel label="Nº" xs={12}>
              <Input name="number" />
            </WrapperLabel>
            <WrapperLabel label="Bairro" xs={12}>
              <Input name="distric" />
            </WrapperLabel>
            <WrapperLabel label="Relato da abordagem" xs={12}>
              <TextArea row={2} name="description" />
            </WrapperLabel>
            <GridButtons>
              <WrapperBtnInfo>
                <ButtonIcon
                  title="Instruções"
                  onClick={() => labelRef.current?.click()}
                >
                  <InfoIcon />
                </ButtonIcon>

                <label ref={labelRef} htmlFor="modal-instruction" />
                <Modal id="modal-instruction" width={520} height="auto">
                  <h1>Instruções</h1><br />
                  <p>
                    Para solicitar uma consulta de pessoa você precisa no mínimo
                    informar os seguintes dados:
                  </p><br />
                  <p><b>CPF</b> ou</p>
                  <p><b>Nome e Nascimento</b> ou</p>
                  <p><b>Nome e Mãe</b>. </p>
                  <br />
                </Modal>

              </WrapperBtnInfo>
              <WrapperBtnCancel>
                <Button
                  type="button"
                  onClick={() => { history.push('/dashboard'); }}
                >
                  Cancelar
                </Button>
              </WrapperBtnCancel>
              <WrapperBtnSubmit>
                <Button type="submit" isLoading={btnLoading}>Salvar</Button>
              </WrapperBtnSubmit>
            </GridButtons>
          </UForm>
        </Content>
      </Container>
    </Layout>
  );
};
export default Alert;
