const guns = [
  { value: 'Armas antiaéreas', label: 'Armas antiaéreas' },
  { value: 'Armas de artes marciais', label: 'Armas de artes marciais' },
  { value: 'Armas de artilharia', label: 'Armas de artilharia' },
  { value: 'Canhões', label: 'Canhões' },
  { value: 'Carabinas', label: 'Carabinas' },
  { value: 'Espadas', label: 'Espadas' },
  { value: 'Espingardas', label: 'Espingardas' },
  { value: 'Facas e facões', label: 'Facas e facões' },
  { value: 'Foguetes', label: 'Foguetes' },
  { value: 'Fuzil', label: 'Fuzil' },
  { value: 'Granadas', label: 'Granadas' },
  { value: 'Lançadores de granadas', label: 'Lançadores de granadas' },
  { value: 'Lança-chamas', label: 'Lança-chamas' },
  { value: 'Metralhadoras', label: 'Metralhadoras' },
  { value: 'Mísseis', label: 'Mísseis' },
  { value: 'Pistolas', label: 'Pistolas' },
  { value: 'Submetralhadoras', label: 'Submetralhadoras' },
  { value: 'Outros', label: 'Outros' },
];

export default guns;
