import React, {
  useRef, useCallback, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import ModalFactory from 'react-modal-promise';

import getValidationError from '../../../utils/getValidationsErros';
import getCurrentPosition from '../../../utils/getCurrentPosition';

/** hooks */
import { useAuth } from '../../../hooks/auth';
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** components */
import { ModalDialog } from '../../../components/ModalDialog';
import Modal from '../../../components/ModalSimple';
import {
  WrapperLabel, Input, InputMask, TextArea, Button, ButtonIcon, Select,
} from '../../../components/Forms';
import { InfoIcon } from '../../../styles/icons';
import {
  Container, GridButtons, WrapperBtnInfo, WrapperBtnCancel, WrapperBtnSubmit,
} from './styles';

  interface IForm {
    coord: {
      lat: number,
      lng: number
    }
  }

  interface IEnd {
    logradouro?: string;
    complemento?: string;
    bairro?: string;
  }

const Proximity: React.FC<IForm> = ({ coord }) => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const { user } = useAuth();
  const {
    httpGet, httpPost, unLoading,
  } = useHttp();
  const [assistances, setAssistances] = useState([]);
  const [store, setStore] = useState(false);
  const [address, setAddress] = useState<IEnd | undefined>({} as IEnd);
  const [btnLoading, setBtnLoading] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    unLoading();
    // setBtnLoading(loading);
  }, [unLoading]);

  useEffect(() => {
    const load = async function load():Promise<any> {
      const { status, data } = await httpGet('/assistances');
      if (status === 200) {
        const assistanceParsed = data
          .map((assistance: any) => ({
            value: assistance.id,
            label: `${assistance.description}`,
          }));
        setAssistances(assistanceParsed);
      }
    };

    load();
  }, [httpGet]);

  const handleFieldStore = useCallback((assistance: any) => {
    const { value } = assistance;
    if (value === 2) {
      setStore(true);
    } else {
      setStore(false);
    }
  }, []);

  const handleBlur = useCallback(async (cep: string) => {
    if (cep.length < 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`, { mode: 'cors' })
      .then((res) => res.json())
      .then((data) => {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('erro')) {
          return;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty('erro')) {
          return;
        }
        setAddress(data);
      }).catch();
  }, []);

  const handleSubmit = useCallback(async (data: any) => {
    const proximity = {
      ...data,
      ...coord,
      management_id: user?.allocation?.management_id,
      assistance_id: data.assistance_id?.value,
      phone: data.phone.replace(/\D/g, ''),
      type: 'proximity',
    };

    const schema = Yup.object().shape({
      assistance_id: Yup.string().required('Insira a Assistência'),
      name: Yup.string().required('Insira um Nome'),
      description: Yup.string().required('Insira uma descrição'),
    });

    try {
        formRef.current?.setErrors({});
        await schema.validate(proximity, { abortEarly: false });

        if (!data.email && !data.phone) {
          const response = await ModalDialog({
            title: 'Seu registro não possui contato.',
            content: 'Sua ocorrência não poderá ser avaliada. Deseja prosseguir?',
            buttons: 'cancel_confirm',
          });

          if (response) {
            setBtnLoading(true);
            const position = await getCurrentPosition();
            const { status } = await httpPost('/proximities', {
              ...proximity,
              current_lat: position.lat,
              current_lng: position.lng,
            });

            if (status === 200) {
              history.push('/dashboard');
              addToast({ title: 'Salvo com sucesso!', type: 'success' });
            }

            if (status !== 200) {
              setBtnLoading(false);
            }
          }
        } else {
          setBtnLoading(true);
          const position = await getCurrentPosition();
          const { status } = await httpPost('/proximities', {
            ...proximity,
            current_lat: position.lat,
            current_lng: position.lng,
          });

          if (status === 200) {
            history.push('/dashboard');
            addToast({ title: 'Salvo com sucesso!', type: 'success' });
          }

          if (status !== 200) {
            setBtnLoading(false);
          }
        }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
          formRef.current?.setErrors(erros);
      }

      setBtnLoading(false);
    }

    }, [coord, history]); // eslint-disable-line

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Assistência" xs={12}>
            <Select name="assistance_id" options={assistances} onChange={handleFieldStore} />
          </WrapperLabel>
          {
            store ? (
              <WrapperLabel label="Establecimento" xs={12}>
                <Input name="store" />
              </WrapperLabel>
            ) : ''
          }
          <WrapperLabel label="Nome Completo" xs={12} sm={6}>
            <Input name="name" />
          </WrapperLabel>
          <WrapperLabel label="CPF" xs={12} sm={6}>
            <InputMask name="document" mask="999.999.999-99" />
          </WrapperLabel>
          <WrapperLabel label="Telefone de Contato" xs={12} sm={6}>
            <InputMask name="phone" mask="(99)999999999" autoComplete="off" />
          </WrapperLabel>
          <WrapperLabel label="E-mail" xs={12} sm={6}>
            <Input name="email" />
          </WrapperLabel>
          <WrapperLabel label="CEP" xs={12} sm={3}>
            <Input name="cep" onBlur={(e) => handleBlur(e.target.value)} />
          </WrapperLabel>
          <WrapperLabel label="Logradouro" xs={10} sm={7}>
            <Input name="street" defaultValue={address?.logradouro || undefined} />
          </WrapperLabel>
          <WrapperLabel label="Nº" xs={2} sm={2}>
            <Input name="number" />
          </WrapperLabel>
          <WrapperLabel label="Bairro" xs={12} sm={6}>
            <Input name="distric" defaultValue={address?.bairro || undefined} />
          </WrapperLabel>
          <WrapperLabel label="Complemento" xs={12} sm={6}>
            <Input name="complement" defaultValue={address?.complemento || undefined} />
          </WrapperLabel>
          <WrapperLabel label="Relato da abordagem" xs={12}>
            <TextArea row={2} name="description" />
          </WrapperLabel>
        </Grid>

        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
              onClick={() => labelRef.current?.click()}
            >
              <InfoIcon />
            </ButtonIcon>

            <label ref={labelRef} htmlFor="modal-instruction" />
            <Modal id="modal-instruction" width={520} height="auto">
              <h1>Instruções</h1><br />
              <p>
                Para solicitar uma consulta de pessoa você precisa no mínimo
                informar os seguintes dados:
              </p><br />
              <p>apenas o <b>Nome</b> ou</p>
              <p><b>Nome e Email</b> ou</p>
              <p><b>Nome e Telefone</b> ou</p>
              <br />
            </Modal>

          </WrapperBtnInfo>
          <WrapperBtnCancel>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </WrapperBtnCancel>
          <WrapperBtnSubmit>
            <Button
              type="submit"
              isLoading={btnLoading}
            >
              Salvar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>
      </UForm>
      <ModalFactory />
    </Container>
  );
};

export default Proximity;
