const brandAmmunition = [
  { value: 'Sem Marca', label: 'Sem Marca' },
  { value: 'Aguila Ammunition', label: 'Aguila Ammunition' },
  { value: 'CBC', label: 'CBC' },
  { value: 'CCI', label: 'CCI' },
  { value: 'Chronus Importacao e Exportacao LTDA', label: 'Chronus Importacao e Exportacao LTDA' },
  { value: 'Eley', label: 'Eley' },
  { value: 'Federal', label: 'Federal' },
  { value: 'Fiocchi', label: 'Fiocchi' },
  { value: 'Hornady', label: 'Hornady' },
  { value: 'Remington', label: 'Remington' },
  { value: 'Sellier & Bellot', label: 'Sellier & Bellot' },
  { value: 'SPEER', label: 'SPEER' },
  { value: 'Outros', label: 'Outros' },
];

export default brandAmmunition;
