import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Title = styled.div`
  border-top: 1px solid var(--color-border);
  margin: 0 -16px;
  margin-top: 8px;
  padding: 8px;
`;

interface IContent {
  isConduction?: boolean;
  isAlert?: boolean;
}
export const Content = styled.div<IContent>`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;

  ${(props) => props.isConduction && css`border: 2px solid var(--color-red);`}

  p{
    margin: 4px 0;
  }

  footer{
    color: var(--color-secondary);
    margin: 8px 0;
    text-align: justify;
  }
`;

export const WrapperConduction = styled.div`
 width: 100%;
 text-align: center;
 padding: 4px;
 border-radius: 10px;
 background: var(--color-red);
 color: var(--color-white);
 font-weight: 700;

`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  h2{
    font-size: 1em;
    font-weight: 700;
    text-align: right;
  }
  span{
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  svg{
    font-size: 24px;
    margin-right: 8px;
  }
`;

export const WrapperSeparator = styled.span`
  + span{
    ::before{
      content: ', ';
    }
  }
`;

export const WrapperLink = styled.div`
  width: 100%;
  margin: 16px 0 0 0;
  border-radius: 4px;
  background: var(--color-button);
  display: flex;
  justify-content: center;
  align-items: center;

  a{
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    svg{
      font-size: 20px;
      margin-right: 16px;
    }
  }

  &:hover{
    background: var(--color-button-h);
  }
`;

export const Card = styled.div`
`;

