import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid var(--color-border);
  padding: 8px 16px;
  position: relative;

  + div{
    margin-top: 8px;
  }
`;
