import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import { ViewListIcon } from 'styles/icons';
/** hooks */
import Grid from '@material-ui/core/Grid';
import { useAuth } from 'hooks/auth';
import { useHttp } from '../../../hooks/http';
import HeaderFilterPlus from '../../../components/Headers/FilterPlus';
import Pagination from '../../../components/Pagination';
import Card from './Card';
import { Container } from './styles';
/** interfaces */
import { IPaginate } from '../../../interfaces';

const List: React.FC = () => {
  const [rcols, setRcols] = useState([]);
  const [filter, setFilter] = useState('');
  const { user } = useAuth();

  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const { httpGet, loading } = useHttp();

  useEffect(() => {
    async function loadSocials(): Promise<void> {
      const { status, data } = await httpGet('/rcols', {
        params: {
          user,
          filter,
          ...paginate,
        },
      });

      if (status === 200) {
        setRcols(data.data);
        setPaginate(data.pagination);
      }
    }

    loadSocials();

    }, [filter, user, paginate.page]); // eslint-disable-line

  const handleSearch = (value: string): void => {
    setPaginate({ ...paginate, page: 1 });
    setFilter(value);
  };

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  const breadcrumbs = {
    icon: ViewListIcon,
    links: [{ path: '', title: 'LeiSeca Rcols' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderFilterPlus handleSearch={handleSearch} />
        <Grid container spacing={1}>
          {rcols && rcols.map((rcol: any) => (
            <Card
              key={rcol.id}
              rcol={rcol}
            />
          ))}
        </Grid>
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default List;
