import React from 'react';

import { Container } from './ContentStyles';

const CardFooter: React.FC = ({ children }) => (
  <Container>
    {children}
  </Container>
);

export default CardFooter;
