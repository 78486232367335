import React, {
  useState, useRef, useCallback, useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import ModalFactory from 'react-modal-promise';
import ReturnSalvePlus from 'components/Headers/ReturnSalvePlus';
import { Divider, FormControlLabel, Switch } from '@material-ui/core';
import { useToast } from 'hooks/toast';
import ISeizures from 'interfaces/seizures';
import { useHttp } from '../../../hooks/http';
import { useAuth } from '../../../hooks/auth';
import Layout from '../../../components/Layout';
import { Button as ButtonMat } from '../../../components/Forms';
import Flex, { Grid } from '../../../components/Flex';
import Card, { CardHeader, CardContent } from '../../../components/Card';
import {
  UsersIcon, PencilIcon, TrashIcon, Pistol, Medicines, BoxOpen, Bullets, CarIcon,
} from '../../../styles/icons';
import {
  Container, WrapperName, Button, WrapperModal, Red, TopCard,
} from './styles';
import Modal from '../../../components/ModalSimple';
import { TitleModal } from '../styles';

const Seizure: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const labelConfRef = useRef<HTMLLabelElement>(null);
  const switchs = useRef<HTMLLabelElement>(null);
  const [seizures, setSeizure] = useState<ISeizures[]>();
  const [formData, setFormData] = useState<any>();
  const history = useHistory();
  const ROId = localStorage.getItem('ROId');
  const [btnLoading, setBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { httpGet, httpDelete } = useHttp();
  const location = useLocation();

  useEffect(() => {
    async function loadSeizure(): Promise<any> {
      const { status, data } = await httpGet(`/seizures/list/${ROId}`);

      if (status === 200 && data.length > 0) {
        setSeizure(data);
      } else { history.push('/RO/seizures/storage/menu'); }
    }
    loadSeizure();
  }, [httpGet, user, history, ROId, location.key]);

  const handleDeleteSeizure = useCallback(async (seizureId) => {
    const response: any = await httpDelete(`/seizures/${seizureId}`);

    // eslint-disable-next-line no-constant-condition
    if (response.status === 200 || 204) {
      addToast({ title: 'Excluído com sucesso!', type: 'success' });
      history.go(0);
    } else {
      addToast({ title: 'Não foi possível excluir!', type: 'error' });
      setBtnLoading(false);
      setDisableBtn(false);
      switchs.current?.click();
      labelConfRef.current?.click();
      formRef.current?.reset();
    }
  }, [addToast, history, httpDelete]);

  const handleGoToDetail = useCallback(async (data: any) => {
    setFormData(data);
    formRef.current?.setData({
      ...data,
      confirmed: [{ value: null, label: '' }],
    });

    labelConfRef.current?.click();
  }, []);

  const handleChangeChecked = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableBtn(event.target.checked);
  }, []);

  const handleHover = useCallback(() => {
    setBtnLoading(false);
    setDisableBtn(false);
    switchs.current?.click();
    labelConfRef.current?.click();
    formRef.current?.reset();
  }, []);

  const breadcrumbs = {
    icon: UsersIcon,
    links: [
      { title: 'RO-SP / Apreenções / Listar', path: '/RO/step/4' },
    ],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <ReturnSalvePlus
          handleReturn={() => history.push('/RO/step/4')}
          handlePlus={() => history.push('/RO/seizures/storage/menu')}
        />

        <Grid container justify="flex-start" spacing={1}>
          {seizures?.map((seizure) => (
            <Grid item key={seizure.id} xs={12} sm={4} md={4}>
              <TopCard>
                <p className={seizure.seizure_type} />
              </TopCard>
              <Card>
                <CardHeader>
                  <WrapperName>
                    {seizure.seizure_type === 'Veículos' && (<><CarIcon /></>)}
                    {seizure.seizure_type === 'Entorpecentes' && (<><Medicines /></>)}
                    {seizure.seizure_type === 'Munições' && (<><Bullets /></>)}
                    {seizure.seizure_type === 'Armas' && (<><Pistol /></>)}
                    {seizure.seizure_type === 'Materiais' && (<><BoxOpen /></>)}
                    &nbsp;<p>{seizure.seizure_type}</p>
                  </WrapperName>
                </CardHeader>
                <br></br>
                <Divider/>
                <CardContent>
                  <Grid container spacing={1}>
                    {seizure.seizure_type === 'Veículos' && (
                    <>
                      {seizure.plate ? (
                        <>
                          <Flex title="Placa" xs={5} sm={6} md={6}>
                            {seizure.plate}
                          </Flex>
                        </>
                      ) : (
                        <>
                          <Flex title="Chassi" xs={5} sm={6} md={6}>
                            {seizure.serial}
                          </Flex>
                        </>
                      )}
                      <Flex title="Marca" xs={5} sm={6} md={6}>
                        {seizure.brand}
                      </Flex>
                      <Flex title="Modelo" xs={5} sm={6} md={6}>
                        {seizure.model}
                      </Flex>
                      <Flex title="Ano" xs={5} sm={6} md={6}>
                        {seizure.year}
                      </Flex>
                    </>
                    )}

                    {seizure.seizure_type === 'Entorpecentes' && (
                    <>
                      <Flex title="Entorpecente" xs={5} sm={6} md={6}>
                        {seizure.type}
                      </Flex>
                      <Flex title="Quantidade" xs={5} sm={6} md={6}>
                        {seizure.amount}
                      </Flex>
                      <Flex title="Medida" xs={5} sm={6} md={6}>
                        {seizure.measure}
                      </Flex>
                      <Flex title="Destino" xs={5} sm={6} md={6}>
                        {seizure.destiny}
                      </Flex>
                    </>
                    )}

                    {seizure.seizure_type === 'Munições' && (
                    <>
                      <Flex title="Quantidade" xs={5} sm={6} md={6}>
                        {seizure.amount}
                      </Flex>
                      <Flex title="Calibre" xs={5} sm={6} md={6}>
                        {seizure.caliber}
                      </Flex>
                      <Flex title="....." xs={5} sm={6} md={6}>
                        .
                      </Flex>
                      <Flex title="....." xs={5} sm={6} md={6}>
                        .
                      </Flex>
                    </>
                    )}

                    {seizure.seizure_type === 'Armas' && (
                    <>
                      <Flex title="Marca" xs={5} sm={6} md={6}>
                        {seizure.brand}
                      </Flex>
                      <Flex title="Modelo" xs={5} sm={6} md={6}>
                        {seizure.model}
                      </Flex>
                      <Flex title="Calibre" xs={5} sm={6} md={6}>
                        {seizure.caliber}
                      </Flex>
                      <Flex title="Tipo" xs={5} sm={6} md={6}>
                        {seizure.type}
                      </Flex>
                    </>
                    )}

                    {seizure.seizure_type === 'Materiais' && (
                    <>
                      <Flex title="Quantidade" xs={5} sm={6} md={6}>
                        {seizure.amount}
                      </Flex>
                      <Flex title="Tipo" xs={5} sm={6} md={6}>
                        {seizure.type}
                      </Flex>
                      <Flex title="Marca" xs={5} sm={6} md={6}>
                        {seizure.brand}
                      </Flex>
                      <Flex title="Modelo" xs={5} sm={6} md={6}>
                        {seizure.model}
                      </Flex>
                    </>
                    )}

                    <Grid item xs={6} sm={6} md={6}>
                      <Button type="button" onClick={() => { history.push(`/RO/seizures/${seizure.id}`); }}>
                        Editar &nbsp; <PencilIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <Button type="button" onClick={() => { handleGoToDetail(seizure); }}>
                        Excluir &nbsp; <TrashIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <WrapperModal>
          <label ref={labelConfRef} htmlFor="modal-conf" />
          <Modal id="modal-conf" width={520} height="auto">
            <TitleModal>
              <TrashIcon /> Deseja excluir a apreenção?
            </TitleModal>
            <p className={formData?.seizure_type}><b>{formData?.seizure_type.toUpperCase()} </b></p>
            {formData?.seizure_type === 'Veículos' && (
            <>
              <p> Placa: <b>{formData?.plate}</b></p>
              <p> Marca: <b>{formData?.brand}</b> </p>
              <p> Modelo: <b>{formData?.model}</b> </p>
              <p> Ano: <b>{formData?.year}</b> </p>
            </>
          )}
            {formData?.seizure_type === 'Entorpecentes' && (
            <>
              <p> Entorpecente: <b>{formData?.type}</b></p>
              <p> Quantidade: <b>{formData?.amount}</b> </p>
              <p> Medida: <b>{formData?.measure}</b> </p>
              <p> Destino: <b>{formData?.destiny}</b> </p>
            </>
          )}
            {formData?.seizure_type === 'Munições' && (
            <>
              <p> Quantidade: <b>{formData?.amount}</b></p>
              <p> Tipo: <b>{formData?.caliber}</b> </p>
            </>
          )}
            {formData?.seizure_type === 'Armas' && (
            <>
              <p> Marca: <b>{formData?.brand}</b> </p>
              <p> Modelo: <b>{formData?.model}</b> </p>
              <p> Calibre: <b>{formData?.caliber}</b></p>
              <p> Tipo: <b>{formData?.type}</b> </p>
            </>
          )}
            {formData?.seizure_type === 'Materiais' && (
            <>
              <p> Quantidade: <b>{formData?.amount}</b></p>
              <p> Tipo: <b>{formData?.type}</b> </p>
              <p> Marca: <b>{formData?.brand}</b> </p>
              <p> Modelo: <b>{formData?.model}</b> </p>
            </>
          )}

            <FormControlLabel
              ref={switchs}
              label={<Red>Confirmo as informações acima.</Red>}
              control={(
                <Switch
                  onChange={handleChangeChecked}
                  color="primary"
                  name="checked"
                  value="false"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            )}
            />
            <span>
              <ButtonMat onClick={handleHover}>
                Cancelar
              </ButtonMat>
              <ButtonMat
                type="button"
                onClick={() => { handleDeleteSeizure(formData?.id); }}
                isLoading={btnLoading}
                disableForce={!disableBtn}
              >
                Excluir
              </ButtonMat>
            </span>

          </Modal>
        </WrapperModal>
        <ModalFactory />
      </Container>
    </Layout>
  );
};

export default Seizure;
