import React, { useState, useCallback, useEffect } from 'react';
import Layout from 'components/Layout';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import HeaderSavePlus from '../../components/Headers/SavePlus';
import Tabs, { TabsItem } from '../../components/Tabs';
import { Container } from './styles';
import { ClipboardCopyIcon } from '../../styles/icons';
import Profile from './Profile';
import { useHttp } from 'hooks/http';
import { IMake } from 'interfaces';

const breadcrumbs = {
    icon: ClipboardCopyIcon,
    links: [
        { path: '/makes', title: 'Marcas' },
        { path: '', title: 'Marca' },
    ],
};

const Form: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { httpGet, loading } = useHttp();
    const [makes, setMakes] = useState({} as IMake);
    const history = useHistory();

    useEffect(() => {
        async function loadMakes(): Promise<void> {
            const { data } = await httpGet(`/marcas/${id}`);
            setMakes(data);
        }

        id && loadMakes();
    }, [id]);// eslint-disable-line

    const handlePlus = useCallback(() => {
        history.push('/makes');
        setMakes({} as IMake);
    }, [history]);

    return (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
            <Container>
                <HeaderSavePlus
                    hiddenButton={!id}
                    handlePlus={handlePlus}
                >
                    {id ? `Editar ${makes.name}` : 'Nova Marca'}
                </HeaderSavePlus>

                <Tabs>
                    <TabsItem title="Dados" show>
                        {id && <Profile make={makes} />}
                        {!id && <Profile make={{} as IMake} />}
                    </TabsItem>
                </Tabs>

            </Container>
        </Layout>
    );
}

export default Form;