import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Content = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  p{
    padding: 4px 0;
  }
  ul,li{
    text-decoration: none;
    list-style: none;
  }
`;

export const WrapperInputFile = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;

  span{
    position: absolute;
    width: 100%;
    padding: 12px;
    text-align: center;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    height: 46px;
  }

  .csv-input {
      padding: 10px;
      width: 100%;
      cursor: pointer;
    }
`;

export const WrapperModal = styled.div`
  padding: 16px;
  p{
    margin-bottom: 16px;
  }
  table{
    width: 100%;
  }
  table, th, td, tr{
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
  }
`;
