import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import Grid from '@material-ui/core/Grid';

/** componentes */
import {
  WrapperLabel, Select, InputHidden, TextArea, ButtonIcon,
} from '../../../components/Forms';

import {
  documentation, staystreet, street, streetmotive, forwarding,
} from '../social';
/** hooks */
import { useHttp } from '../../../hooks/http';
import { WrapperBtnInfo } from './styles';
import { InfoIcon } from '../../../styles/icons';
import { IComplement } from '../../../interfaces';

  interface IFormComplement {
    complement?: IComplement;
  }

const DetailComplements: React.FC<IFormComplement> = ({ complement }) => {
  const formRef = useRef<FormHandles>(null);

  const [sOptions] = useState([
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ]);

  const {
    httpGet,
  } = useHttp();

  const [documentations] = useState(documentation);
  const [benefits, setBenefits] = useState([]);
  const [staystreets] = useState(staystreet);
  const [streettime] = useState(street);
  const [streetmotives] = useState(streetmotive);
  const [forwardings] = useState(forwarding);

  useEffect(() => {
    const documentationsIds = complement?.documentationsIds
      ? complement.documentationsIds.split(/\s*,\s*/)
      : [];

    const documentationsFiltered = documentations.filter((documentations:any) => {
      if (documentationsIds.some((id) => parseInt(id, 10) === documentations.value)) {
        return true;
      }
      return false;
    });

    const benefitsIds = complement?.benefitsIds
      ? complement.benefitsIds.split(/\s*,\s*/)
      : [];

    const benefitsFiltered = benefits.filter((benefits:any) => {
      if (benefitsIds.some((id) => parseInt(id, 10) === benefits.value)) {
        return true;
      }
      return false;
    });

    const stayStreetIds = complement?.stayStreetIds
      ? complement.stayStreetIds.split(/\s*,\s*/)
      : [];

    const stayStreetFiltered = staystreets.filter((staystreets:any) => {
      if (stayStreetIds.some((id) => parseInt(id, 10) === staystreets.value)) {
        return true;
      }
      return false;
    });

    const streetTimeId = complement?.streetTimeId
      ? complement.streetTimeId.split(/\s*,\s*/)
      : [];

    const streetTimeIdFiltered = streettime.filter((streettime:any) => {
      if (streetTimeId.some((id) => parseInt(id, 10) === streettime.value)) {
        return true;
      }
      return false;
    });

    const streetMotiveIds = complement?.streetMotiveIds
      ? complement.streetMotiveIds.split(/\s*,\s*/)
      : [];

    const streetMotiveIdsFiltered = streetmotives.filter((streetmotives:any) => {
      if (streetMotiveIds.some((id) => parseInt(id, 10) === streetmotives.value)) {
        return true;
      }
      return false;
    });

    const forwardingIds = complement?.forwardingIds
      ? complement.forwardingIds.split(/\s*,\s*/)
      : [];

    const forwardingIdsFiltered = forwardings.filter((forwardings:any) => {
      if (forwardingIds.some((id) => parseInt(id, 10) === forwardings.value)) {
        return true;
      }
      return false;
    });

    formRef.current?.setData({
      ...complement,
      benefitsIds: benefitsFiltered,
      stayStreetIds: stayStreetFiltered,
      streetTimeId: streetTimeIdFiltered,
      streetMotiveIds: streetMotiveIdsFiltered,
      documentationsIds: documentationsFiltered,
      welcomed: sOptions.find((welcomed) => welcomed.value === complement?.welcomed),
      requested: sOptions.find((requested) => requested.value === complement?.requested),
      received: sOptions.find((received) => received.value === complement?.received),
      followUpCreas: sOptions
        .find((followUpCreas) => followUpCreas.value === complement?.followUpCreas),
      followUpPop: sOptions.find((followUpPop) => followUpPop.value === complement?.followUpPop),
      forwardingIds: forwardingIdsFiltered,
    });
  }, [complement, benefits, staystreets, streettime, streetmotives,
    documentations, forwardings, sOptions]);

  useEffect(() => {
    const load = async function load():Promise<any> {
      const [respBenefits] = await Promise.all([
        httpGet('/benefits', undefined, false),
      ]);

      const benfs = respBenefits.data;
      if (benfs) {
        const benefitParsed = benfs
          .map((benefits: any) => ({
            value: benefits.id,
            label: `${benefits.description}`,
          }));
        setBenefits(benefitParsed);
      }
    };
    load();
  }, [httpGet]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSubmit = useCallback(() => {

    }, []);// eslint-disable-line

  return (
    <UForm ref={formRef} onSubmit={handleSubmit}>
      <InputHidden name="id" />
      <Grid container spacing={1}>
        <WrapperLabel label="Documentação" xs={12}>
          <Select
            name="documentationsIds"
            options={documentations}
            isMulti
            isDisabled
          />
        </WrapperLabel>
        <WrapperLabel label="Benefícios" xs={12}>
          <Select
            name="benefitsIds"
            options={benefits}
            isMulti
            isDisabled
          />
        </WrapperLabel>
        <WrapperLabel label="Permanência na rua/modalidade" xs={12} sm={6} md={6}>
          <Select
            name="stayStreetIds"
            options={staystreets}
            isDisabled
          />
        </WrapperLabel>
        <WrapperLabel label="Tempo de permanência na rua" xs={12} sm={6} md={6}>
          <Select
            name="streetTimeId"
            options={streettime}
            isDisabled
          />
        </WrapperLabel>
        <WrapperLabel label="Motivo de permanência na rua" xs={12}>
          <Select
            name="streetMotiveIds"
            options={streetmotives}
            isMulti
            isDisabled
          />
        </WrapperLabel>

        <WrapperLabel label="Já esteve acolhido?" xs={12} sm={4} md={4}>
          <Select
            name="welcomed"
            options={sOptions}
            isDisabled
          />
        </WrapperLabel>
        <WrapperLabel label="Solicitou colhimento?" xs={12} sm={4} md={4}>
          <Select
            name="requested"
            options={sOptions}
            isDisabled
          />
        </WrapperLabel>
        <WrapperLabel label="Recebeu acolhimento?" xs={12} sm={4} md={4}>
          <Select
            name="received"
            options={sOptions}
            isDisabled
          />
        </WrapperLabel>

        <WrapperLabel label="Faz acompanhamento CREAS?" xs={12}>
          <Select
            name="followUpCreas"
            options={sOptions}
            isDisabled
          />
        </WrapperLabel>
        <WrapperLabel label="Faz acompanhamento centro POP?" xs={12}>
          <Select
            name="followUpPop"
            options={sOptions}
            isDisabled
          />
        </WrapperLabel>

        <WrapperLabel label="Encaminhamento" xs={12}>
          <Select
            name="forwardingIds"
            options={forwardings}
            isMulti
            isDisabled
          />
        </WrapperLabel>

        <WrapperLabel label="Observações" xs={12}>
          <TextArea
            disabled
            name="note"
          />
        </WrapperLabel>
      </Grid>

      <Grid container justify="flex-start">
        <WrapperBtnInfo>
          <ButtonIcon
            title="Somente para consulta!"
          >
            <InfoIcon />
          </ButtonIcon>
        </WrapperBtnInfo>
      </Grid>

    </UForm>
  );
};

export default DetailComplements;
