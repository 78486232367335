import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

interface ReactSelectProps extends SelectProps<OptionTypeBase>{
  name: string;
  message?: string;
  transform?: 'uppercase' | 'default' ;
  isDisabled?: boolean,
  placeholder?: string;
}

const SelectDash: React.FC<ReactSelectProps> = ({
  name,
  options,
  transform = 'default',
  message = 'Sem registros',
  isDisabled = false,
  placeholder = 'Selecione',
  ...rest
}) => {
  const selectRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const {
    fieldName, defaultValue, registerField, error,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => ref.state.value,
      setValue: (ref, value) => {
        ref.select.setValue(value || null);
      },
      clearValue: (ref) => {
        ref.select.clearValue();
      },
    });
  }, [fieldName, registerField]);

  const handleFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const style = {
    control: (base: any) => ({
      ...base,
      color: '#fff123',
      border: 0,
      fontSize: 20,
      background: ' #458412',
      colorText: '#fff000',
      selected: {
        color: '#aa1919',
      },
    }),

    option: (provided: any) => ({
      ...provided,
      // background: ' #2c2f38',
      color: '#2c2f38',
    }),
  };

  return (

    <Container
      isErrored={!!error}
      isFocused={isFocused}
      transform={transform}
      disable={isDisabled}
    >

      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        styles={style}
        onFocus={handleFocused}
        onBlur={handleInputBlur}
        classNamePrefix="react-select"
        options={options}
        placeholder={placeholder}
        noOptionsMessage={() => message}
        isDisabled={isDisabled}
        {...rest}
      />

      <Error title={error || ''}>
        <FiAlertCircle color="#c53030" size={20} />
      </Error>
    </Container>

  );
};

export default SelectDash;
