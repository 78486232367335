import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;

`;

export const Content = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  .first{
    margin-top: -16px;
    border-top: 0;
  }

  .hidden{
    display: none;
  }
`;

export const Title = styled.div`
  padding: 12px;
  margin: 16px -16px;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
`;

export const WrapperConduction = styled.div`
  width: 100%;
  margin-top: 8px;
  label > span {
    color: red !important;
  }
`;

export const WrapperForm = styled.div`
  padding: 16px;
  background: #fff;
  border-radius: 4px;

  header{
    padding: 8px 0;
    border-bottom: 1px solid var(--color-border);
    margin-bottom: 16px;
  }

  .hidden{
    display: none;
  }
`;
