import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 8px;

  h1{
    font-size: 16px;
  }

  + div{
    margin-top: 8px;
  }
`;

export const WrapperRemove = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 4px;

  cursor: pointer;
  svg{
    color: var(--color-secondary);
  }

  &:hover{
    svg{
      color: var(--color-secondary-h);
    }
  }
`;

export const WrapperEnded = styled.div`
  button {
    border: none;
    background: transparent;
  }
`;

export const WrapperForm = styled.div`
 padding: 24px;
 background: #fff;

 border-radius: 4px;
 form{
   width: 100%;
 }
`;
