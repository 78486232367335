import styled, { css } from 'styled-components';

interface Props{
  size: 'large' | 'medium' | 'small';
  color: 'primary' | 'secondary' | 'transparent';
  isLoading?: boolean;
  disableForce?: boolean;
}
export const Container = styled.button<Props>`

      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: 0;
      color: var(--color-white);

      display: flex;
      justify-content: center;
      align-items:center;

      ${(props) => props.isLoading && css`cursor: progress;`}

      background: var(--color-button);
      ${(props) => props.color === 'secondary' && css`background: var(--color-button-secondary);`}
      ${(props) => props.color === 'transparent' && css`background: transparent; color: var(--color-primary);`}

      ${(props) => props.size === 'large' && css`height: 56px;`}


      transition: background-color 0.2s;

      &:hover{
        background-color: var(--color-button-h);
        ${(props) => props.color === 'secondary' && css`background-color: var(--color-button-secondary-h);`}
        ${(props) => props.color === 'transparent' && css`background-color: transparent; color: var(--color-primary-h);`}
      }

      ${(props) => props.disableForce && css`
        cursor: not-allowed;
        background: #999;
        &:hover{background: #999;}
      `}

`;
