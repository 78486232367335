import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 8px 12px;

  form{
    width: 100%;
  }
`;

export const WrapperName = styled.div`
  font-size: 18px;
  display:flex;
  align-items:center;

  span{
    font-size: 18px;
    margin-left: 8px;
    text-decoration: line-through;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;

  width: calc(33.33% - 12px);
  margin: 8px;
  padding: 8px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  overflow: hidden;
  transition: transform 0.2s;

  &:hover{
    transform: translateY(-2px);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export const WrapperRoles = styled.div`
  width:100%;
  display: flex;
  justify-content: start;

  min-height: 22px;

  span {
    color: #fff;
    padding: 3px 6px;
    background: var(--color-bg-gray);
    border-radius: 3px;
  }

`;

export const WrapperPaginate = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;

export const WrapperAddress = styled.div`
  background: #fff;
  padding: 12px;
  border-radius: 4px;
  align-items: center;

  h3{
    color: var(--color-primary);
    margin-bottom: 8px;

  }
`;

export const Caption = styled.div`
  width:100%;
  text-align: center;
  font-size: 12px;
  align-items:center;
  font-weight: bold;
  margin-top:5px;

  p{
   padding-top: 10px;
   font-size: 16px;
   text-transform: uppercase; 
   font-weight: 600; 
  }

  svg{
    font-size: 30px;
    margin-right: 8px;
  }
`;

export const Subchapter = styled.div`
  width:100%;
  text-align: center;
  border-top: 2px solid var(--color-border);
  border-bottom: 2px solid var(--color-border);
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  align-items:center;
  margin-top: 20px;
  margin-bottom: 10px;

  span{
    font-weight: bolder;
  }

  svg{
    font-size: 30px;
    margin-right: 18px;
  }
`;

export const Description = styled.div`
  font-size: 12px;
  margin-top: 5px;
  margin-left: 20px;
  font-weight: lighter;
  font-weight: 100;

  list{
  }

  span{
    font-weight: bolder;
    font-weight: 500;
  }

  a{
   text-transform: uppercase; 
   font-weight: 600; 
  }

  b{
    margin-left: 10px;
    font-weight: 400;

    svg{
      font-size: 10px;
      margin-right: 8px;
    }
  }

  svg{
    font-size: 20px;
    margin-right: 8px;
  }
`;

export const Img = styled.img`
  align-items:center;
  width: 104px;
  height: 96px;
`;
