import React from 'react';
import { camelCase } from 'change-case';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { Container, Content } from './styles';

interface LabelWrapperProps extends GridProps{
  label: string;
  children: React.ReactNode;
}
const LabelWrapper: React.FC<LabelWrapperProps> = ({
  label, children, ...rest
}) => (
  <Grid item {...rest}>
    <Container>
      <label htmlFor={camelCase(label)}>{label}</label>
      <Content>
        {children}
      </Content>
    </Container>
  </Grid>
);
export default LabelWrapper;
