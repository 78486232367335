import React, { useRef, useEffect, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import getValidationError from '../../../utils/getValidationsErros';
import {
    WrapperLabel,
    Input,
    InputHidden,
    ButtonIcon,
    Button,
    Select
} from '../../../components/Forms';
import {
    Container,
    GridButtons,
    WrapperBtnInfo,
    WrapperBtnCancel,
    WrapperBtnSubmit,
} from './styles';
import { InfoIcon } from '../../../styles/icons';
import { useToast } from '../../../hooks/toast';
import { useHttp } from '../../../hooks/http';
import { IModel, IMake, IPaginate } from 'interfaces';

interface IForm {
    model: IModel;
}

const ProfileModel: React.FC<IForm> = ({ model }) => {
    const [makes, setMakes] = useState<IMake[]>([]);
    const formRef = useRef<FormHandles>(null);
    const history = useHistory();
    const { addToast } = useToast();
    const [paginate, setPaginate] = useState<IPaginate>({
        page: 1, perPage: 10, lastPage: 1, total: 0,
    });
    const {
        httpPost, httpGet, httpPut, loading, unLoading,
    } = useHttp();

    useEffect(() => {
        unLoading();
        if (model) {
            formRef.current?.setData({
                ...model,
                make: { value: model?.make?.id, label: model?.make?.name }
            });
        }
    }, [model]); // eslint-disable-line

    useEffect(() => {
        async function loadMake(): Promise<void> {
            const { status, data } = await httpGet('marcas', {
                params: {
                    ...paginate,
                },
            });

            if (status === 200) {
                const makeParsed = data.data
                    .map((assistance: any) => ({
                        value: assistance.id,
                        label: `${assistance.name}`,
                    }));
                setMakes(makeParsed);
                setPaginate(data.pagination);
            }
        }
        loadMake();
    }, [paginate.page]); // eslint-disable-line

    const handleSubmit = useCallback(async (payload: any) => {

        const _make = {
            ...payload,
            make_id: payload.make.value
        };

        formRef.current?.setErrors({});

        try {
            const schema = Yup.object().shape({
                name: Yup.string().required('Nome é obrigatório'),
            });

            await schema.validate(_make, { abortEarly: false });

            if (model.id) {
                const { status } = await httpPut(`modelos/${_make.id}`, _make);
                if (status === 200) {
                    addToast({ title: 'Alterado com sucesso!', type: 'warning' });
                    history.push('/makes');
                }
            } else {
                const { status } = await httpPost('modelos', _make);
                if (status === 200) {
                    addToast({ title: 'Salvo com sucesso!', type: 'success' });
                    history.push('/makes');
                }
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
            }
        }
    }, [model, httpPost, httpPut, history, addToast]);

    return (
        <Container>
            <UForm ref={formRef} onSubmit={handleSubmit}>
                <InputHidden name="id" />
                <Grid container spacing={1}>
                    <WrapperLabel label="Marca" xs={12} >
                        <Select
                            name="make"
                            options={makes}
                        />
                    </WrapperLabel>
                    <WrapperLabel label="Nome" xs={12} >
                        <Input
                            name="name"
                            maxLength={30}
                            style={{ textTransform: 'uppercase' }}
                        />
                    </WrapperLabel>
                </Grid>
                <GridButtons>
                    <WrapperBtnInfo>
                        <ButtonIcon
                            title="Instruções"
                        >
                            <InfoIcon />
                        </ButtonIcon>
                    </WrapperBtnInfo>
                    <WrapperBtnCancel>
                        <Button
                            type="button"
                            onClick={() => { history.push('/dashboard'); }}
                        >
                            Cancelar
                        </Button>
                    </WrapperBtnCancel>
                    <WrapperBtnSubmit>
                        <Button
                            type="submit"
                            isLoading={loading}
                        >
                            Salvar
                        </Button>
                    </WrapperBtnSubmit>
                </GridButtons>
            </UForm>
        </Container>
    );
}

export default ProfileModel;