import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Content = styled.div`
  padding: 16px;
  border-radius: 4px;
  background: #fff;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;

  form{
    width: 100%;
    display: flex;
  }
`;

export const WrapperSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: calc( 100% - 48px);
  margin-right: 8px;
  z-index: 99;

  label{
    margin-bottom: 2px;
    color: var(--color-secondary)
  }
`;
