import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { DotVertIcon } from '../../styles/icons';

import {
  WrapperMenuItem, WrapperDotButton,
} from './styles';

interface IButton extends React.HTMLProps<HTMLButtonElement>{
  type?: 'button' | 'submit' | 'reset';
}

export const DotButton: React.FC<IButton> = ({ ...rest }) => (
  <WrapperDotButton>
    <button type="button" {...rest}>
      <DotVertIcon />
    </button>
  </WrapperDotButton>
);

interface IMenuItemIcon {
  icon: React.ReactNode;
  title: string;
  confirm?: boolean;
}

export const ItemIcon: React.FC<IMenuItemIcon> = ({ icon, title, confirm }) => (
  <WrapperMenuItem confirm={confirm}>
    { icon } {confirm ? 'Confirma?' : title}
  </WrapperMenuItem>
);

export { Menu, MenuItem };
