const prefix = `@${process.env.REACT_APP_NAME}`;

export default {

  set(key: string, value: string): string {
    window.localStorage[`${prefix}:${key}`] = value;
    return window.localStorage[key];
  },
  get(key: string, defaultValue?: string): string | null {
    const defaultValueReturn = defaultValue || null;
    return window.localStorage[`${prefix}:${key}`] || defaultValueReturn;
  },
  setObject(key: string, value: any): object {
    window.localStorage[`${prefix}:${key}`] = JSON.stringify(value);
    return this.getObject(`${prefix}:${key}`);
  },
  getObject(key: string): object {
    return JSON.parse(window.localStorage[`${prefix}:${key}`] || null);
  },

  setObjectVictim(key: string, value: any): object {
    window.localStorage[`${prefix}:${key}`] = JSON.stringify(value);
    return this.getObject(`${prefix}:${key}`);
  },
  getObjectVictim(key: string): object {
    return JSON.parse(window.localStorage[`${prefix}:${key}`] || null);
  },

  setObjectDetran(key: string, value: any): object {
    window.localStorage[`${prefix}:${key}`] = JSON.stringify(value);
    return this.getObject(`${prefix}:${key}`);
  },

  getObjectDetran(key: string): object {
    return JSON.parse(window.localStorage[`${prefix}:${key}`] || null);
  },

  remove(key: string): void {
    window.localStorage.removeItem(`${prefix}:${key}`);
  },

  /*
    set (key, value) {
      window.sessionStorage[key] = value
      return window.sessionStorage[key]
    },
    get (key, defaultValue) {
      return window.sessionStorage[key] || defaultValue
    },
    setObject (key, value) {
      window.sessionStorage[key] = JSON.stringify(value)
      return this.getObject(key)
    },
    getObject (key) {
      return JSON.parse(window.sessionStorage[key] || null)
    },
    remove (key) {
      window.sessionStorage.removeItem(key)
    }
  */
};
