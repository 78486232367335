import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 4px 0 0 4px;
  background: #fff;
  border-right: 1px solid #ccc;

  ul{
    list-style: none;
    width: 100%;

    a{
      text-decoration: none;
      color: var(--color-secondary);
    }

    li{
      width: 100%;
      padding: 16px 16px;

      background: #fff;
      border-bottom: 1px solid #ccc;

      cursor: pointer;
      &:hover{
        color: #000;
      }
    }

    li.active{
      border-left: 3px solid #ccc;
      border-right: 2px solid #fff;

      width: calc( 100% + 1px);
      @media (max-width: 767.98px) {
        width:  100% ;
      }
    }

  }

`;
