import React, {
  useRef, useCallback, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import colors from '../../../utils/colors';
import getValidationError from '../../../utils/getValidationsErros';
import getCurrentPosition from '../../../utils/getCurrentPosition';

/** hooks */
import { useAuth } from '../../../hooks/auth';
import { useHttp } from '../../../hooks/http';

/** components */
import Modal from '../../../components/ModalSimple';

import {
  WrapperLabel, Input, InputMask, Select, TextArea, Button, ButtonIcon, InputHidden,
} from '../../../components/Forms';
import { InfoIcon, WarningIcon } from '../../../styles/icons';
import {
  Container, GridButtons, WrapperBtnInfo,
  WrapperBtnCancel, WrapperBtnSubmit, WrapperModal, WrapperHidden, Red,
} from './styles';

interface IForm {
  coord: {
    lat: number,
    lng: number
  }
}

const Vehicle: React.FC<IForm> = ({ coord }) => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const labelConfRef = useRef<HTMLLabelElement>(null);
  const { user } = useAuth();
  const {
    httpGet, httpPost, unLoading, loading,
  } = useHttp();
  const [btnLoading, setBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [disableBtnChecked, setDisableBtnChecked] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState<any>();

  useEffect(() => {
    unLoading();
  }, [unLoading, loading]);

  const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const name = e?.currentTarget.name;
    let { value } = e?.currentTarget;

    setFormData(null);
    setShowForm(false);

    if (name === 'plate') {
      value = value.replace(/[\W,_]/g, '');
      value.length > 6
        ? setDisableBtn(false)
        : setDisableBtn(true);
    }

    if (name === 'chassi') {
      value = value.replace(/[\W,_]/g, '');
      value.length > 4
        ? setDisableBtn(false)
        : setDisableBtn(true);
    }
  }, []);

  const handleGetVehicle = useCallback(async () => {
    const plate = formRef.current?.getFieldValue('plate');
    const chassi = formRef.current?.getFieldValue('chassi');

    const params = plate ? { plate } : { chassi };

    setBtnLoading(true);
    const response: any = await httpGet('/orders/autocomplete/vehicle', { params });

    if (response.status === 200) {
      const { data } = response;
      setShowForm(false);
      setFormData(data);
      formRef.current?.setData({
        ...data,
        proprietario: data.proprietario.nomeProprietario,
        color: { value: data.color, label: data.color },
        confirmed: [{ value: null, label: '' }],
      });

      labelConfRef.current?.click();
    }

    if (response.response?.status === 400) {
      setShowForm(true);
      formRef.current?.reset();
    }

    setBtnLoading(false);
  }, [httpGet]);

  const handleSubmit = useCallback(async (data: any) => {
    const vehicle = {
      ...data,
      ...coord,
      management_id: user?.allocation?.management_id,
      plate: data.plate.replace(/[_\W]/g, '').toUpperCase(),
      chassi: data.chassi.replace(/[_\W]/g, '').toUpperCase(),
      engine_number: data.engine_number.replace(/\W/g, '').toUpperCase(),
      brand: data.brand.toUpperCase(),
      color: data.color?.value || null,
      type: 'vehicle',
    };

    let additionSchema;
    if (!vehicle.plate && !vehicle.chassi) {
      additionSchema = {
        plate: Yup.string().required('Informe a Placa ou Chassi'),
        chassi: Yup.string().required('Informe o Chassi ou Placa'),
      };
    }

    if (vehicle.plate) {
      additionSchema = {
        plate: Yup.string().min(7, 'Informe uma Placa válida'),
      };
    }

    if (vehicle.chassi) {
      additionSchema = {
        chassi: Yup.string().min(5, 'Informe um Chassi válido'),
      };
    }

    if (showForm) {
      additionSchema = {
        ...additionSchema,
        plate: Yup.string().required('Informe a Placa'),
        chassi: Yup.string().required('Informe o Chassi'),
        brand: Yup.string().required('Marca/Modelo é obrigatório'),
      };
    }
    const schema = Yup.object().shape({
      color: Yup.string().ensure().required('Cor é obrigatório'),
      description: Yup.string(),
      ...additionSchema,
    });

    try {
      formRef.current?.setErrors({});
      await schema.validate(vehicle, { abortEarly: false });

      setBtnLoading(true);
      const position = await getCurrentPosition();

      const response: any = await httpPost('orders', {
        ...vehicle,
        current_lat: position.lat,
        current_lng: position.lng,
      });

      if (response.status === 200) {
        history.push('/orders/latest');
      }

      if (response.response?.status === 302) {
        const { data } = response.response;
        history.push(`/order/detail/${data.id}`);
      }

      if (response.status !== 200) {
        setBtnLoading(false);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }

      setBtnLoading(false);
    }
  }, [coord, history, httpPost, user, showForm]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableBtnChecked(event.target.checked);
  };

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Placa" xs={12} sm={6}>
            <InputMask
              name="plate"
              mask="aaa-9*99"
              onKeyUp={handleKeyUp}
              style={{ textTransform: 'uppercase' }}
            />
          </WrapperLabel>
          <WrapperLabel label="Chassi" xs={12} sm={6}>
            <InputMask
              name="chassi"
              mask=""
              onKeyUp={handleKeyUp}
              style={{ textTransform: 'uppercase' }}
            />
          </WrapperLabel>

          <WrapperHidden show={showForm}>
            <Grid container spacing={1}>
              <WrapperLabel label="Motor" xs={12} sm={4}>
                <Input
                  name="engine_number"
                  style={{ textTransform: 'uppercase' }}
                  disabled={!!formData?.engine_number}
                />
              </WrapperLabel>
              <WrapperLabel label="Marca/Modelo" xs={12} sm={4}>
                <Input
                  name="brand"
                  style={{ textTransform: 'uppercase' }}
                  disabled={!!formData?.brand}
                />
              </WrapperLabel>
              <WrapperLabel label="Cor" xs={12} sm={4}>
                <Select
                  name="color"
                  transform="uppercase"
                  options={colors}
                  isDisabled={!!formData?.color}
                />
              </WrapperLabel>
              <InputHidden
                name="alerts"
                disabled={!!formData?.alerts}
              />
            </Grid>
          </WrapperHidden>

          <WrapperLabel label="Observação" xs={12}>
            <TextArea row={2} name="description" />
          </WrapperLabel>

        </Grid>

        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
              onClick={() => labelRef.current?.click()}
            >
              <InfoIcon />
            </ButtonIcon>
          </WrapperBtnInfo>
          <WrapperBtnCancel>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </WrapperBtnCancel>
          <WrapperBtnSubmit>
            <Button
              type="button"
              onClick={() => {
                if (showForm) {
                  formRef.current?.submitForm();
                } else {
                  handleGetVehicle();
                }
              }}
              disableForce={disableBtn}
              isLoading={btnLoading}
            >
              Consultar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>

      </UForm>
      <label ref={labelRef} htmlFor="modal-instruction" />
      <Modal id="modal-instruction" width={520} height="auto">
        <h1>Instruções</h1><br />
        <p>
          Para solicitar uma consulta de veículo você precisa no mínimo
          informar os seguintes dados:
        </p><br />
        <p>
          <b>Placa, Modelo/Marca e Cor</b> ou <b>Chassi, Marca/Modelo e Cor</b>.
        </p><br />
        <p>
          Observação: Caso não seja possível identificar a Marca e o
          Modelo do veículo consultado, preencha NÃO IDENTIFICADO no campo Marca/Modelo.
        </p>
      </Modal>

      <WrapperModal>
        <label ref={labelConfRef} htmlFor="modal-conf" />
        <Modal id="modal-conf" width={520} height="auto">
          <h2>
            <WarningIcon /> Confirma os dados do veículo e posse?
          </h2>
          <p>Placa: <b>{formData?.plate}</b></p>
          <p>Chassi: <b>{formData?.chassi}</b></p>
          <p>Marca/Modelo: <b>{formData?.brand}</b></p>
          <p>Cor: <b>{formData?.color}</b></p>
          <p>Proprietário: <b>{formData?.proprietario?.nomeProprietario}</b></p>
          <FormControlLabel
            label={<Red>Confirmo as informações acima.</Red>}
            control={(
              <Switch
                onChange={handleChangeChecked}
                color="primary"
                name="checked"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            )}
          />
          <span>
            <Button onClick={() => labelConfRef.current?.click()}>
              Cancelar
            </Button>
            <Button
              onClick={() => formRef.current?.submitForm()}
              isLoading={btnLoading}
              disableForce={!disableBtnChecked}
            >
              Confirmar
            </Button>
          </span>

        </Modal>
      </WrapperModal>
    </Container>
  );
};

export default Vehicle;
