import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 9998;

  display: none;



  justify-content: center;
  align-items: center;
  background: rgba(229,229,229,0.9);

  cursor: progress;

  h1{
    font-size: 24px;
    color: var(--color-secondary);
  }
`;
