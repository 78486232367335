import React from 'react';
import { Link } from 'react-router-dom';
import { MdExitToApp } from 'react-icons/md';
import { Container } from './styles';

const Home: React.FC = () => (
  <Container>
    <Link to="/signin"><MdExitToApp />Entrar</Link>
  </Container>
);

export default Home;
