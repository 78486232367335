import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 64px;
  padding: 0 16px;
  margin: 4px 0;
  display:flex;
  justify-content: end;
  align-items: center;
  background: #fff;

  flex-shrink:0;
  border-radius: 4px;
  margin-bottom: 8px;


  @media (max-width: 767.98px) {
      form{
        width: 100%;
      }
    }
`;

export const ButtonPlus = styled.button`
  border: 0;
  width: 32px;
  height: 32px;
  padding: 4px;
  background: var(--color-button);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;

  box-shadow: var(--shadow);
  svg{
    color: var(--color-white);
  }

  &:hover{
    background-color: var(--color-button-h);

    svg {

    }
  }

  @media (max-width: 767.98px) {
    position: fixed;
    width: 40px;
    height: 40px;

    z-index: 2;
   // bottom: 32px;
    right:26px;
  }

`;

export const ButtonReturn = styled.button`
  border: 0;
  width: 32px;
  height: 32px;
  padding: 4px;
  background: var(--color-button);
  border-radius: 50%;
  position: fixed;
  left:300px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;

  box-shadow: var(--shadow);
  svg{
    color: var(--color-white);
  }

  &:hover{
    background-color: var(--color-button-h);

    svg {

    }
  }

  @media (max-width: 767.98px) {
    position: fixed;
    width: 40px;
    height: 40px;

    z-index: 2;
   // bottom: 32px;
    left:26px;
  }

`;
