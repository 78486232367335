import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { formatPlate } from '../../../utils';

/** components */
import { Container } from './styles';
import Flex from '../../../components/Flex';

import { IVehicle } from '../../../interfaces';

interface ICardPerson{
  vehicle: IVehicle;
}
const Card: React.FC<ICardPerson> = ({ vehicle }) => {
  const history = useHistory();

  const handleGoToDetail = useCallback(() => {
    history.push(`/vehicle/${vehicle.id}`);
  }, [history, vehicle.id]);

  return (

    <Container onClick={handleGoToDetail}>

      <Grid container spacing={1}>
        <Flex title="Placa" xs={12} sm={3} md={3}>
          {vehicle.plate ? formatPlate(vehicle.plate) : 'não informado'}
        </Flex>
        <Flex title="Chassi" xs={4} sm={3} md={3}>
          {vehicle.chassi}
        </Flex>
        <Flex title="Motor" xs={4} sm={3} md={3}>
          {vehicle.engine_number}
        </Flex>
        <Flex title="Marca/Modelo" xs={4} sm={4} md={4}>
          {vehicle.brand || 'não informado'}
        </Flex>
        <Flex title="Cor" xs={4} sm={2} md={2}>
          {vehicle.color || 'não informado'}
        </Flex>

      </Grid>

    </Container>

  );
};

export default Card;
