export default function validateID(value: string | null | undefined, required?: boolean): boolean {
  let soma = 0;

  if (!required && !value) {
    return true;
  }

  if (!value) {
    return false;
  }

  let id = value.replace(/\D/g, '');

  if (id.length < 7 || id.length > 8) {
    return false;
  }

  id = id.padStart(9, '0');

  let i;
  for (i = 1; i < 9; i += 1) {
    soma += parseInt(id.slice(i - 1, i), 10) * (10 - i);
  }

  const resto = soma % 11;
  let digito_verificador = 11 - resto;

  if (resto === 0 || resto === 1) {
    digito_verificador = 0;
  }

  if (digito_verificador !== parseInt(id.slice(8), 10)) {
    return false;
  }

  return true;
}
