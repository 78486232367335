import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;

  padding: 8px 16px;

  form{
    width: 100%;
  }
`;

export const Content = styled.div`
  background: #fff;
  padding: 32px 16px;
  border-radius: 4px;
  margin-bottom: 20px;;
`;
