import React from 'react';

/** components */
import Layout from '../../../components/Layout';
import { HomeGearFillIcon } from '../../../styles/icons';

import { Container } from './styles';

const CeperjDataStudio: React.FC = () => {
  const breadcrumbs = {
    icon: HomeGearFillIcon,
    links: [{ path: '', title: 'Ceperj Dashboard' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>

        <iframe className="teste" title="Graficos de pesquisas realizadas" src="https://datastudio.google.com/embed/reporting/2009924f-57df-4784-8760-0fd9d3f87653/page/D2r7B" width="100%" height="100%" />

      </Container>

    </Layout>

  );
};

export default CeperjDataStudio;
