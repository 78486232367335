const typeFunction = [
	{ value: "", label: "SEM FUNÇÃO" },
	{ value: "CEL", label: "CEL" },
	{ value: "TEN CEL", label: "TEN CEL" },
	{ value: "MAJ", label: "MAJ" },
	{ value: "CAP", label: "CAP" },
	{ value: "1º TEN", label: "1º TEN" },
	{ value: "2º TEN", label: "2º TEN" },
	{ value: "ASP", label: "ASP" },
	{ value: "SUB TEN", label: "SUB TEN" },
	{ value: "1º SGT", label: "1º SGT" },
	{ value: "2º SGT", label: "2º SGT" },
	{ value: "3º SGT", label: "3º SGT" },
	{ value: "CB", label: "CB" },
	{ value: "SD", label: "SD" },
	{ value: "EXT APOIO", label: "EXT APOIO" },
	{ value: "EXT ATENDIMENTO", label: "EXT ATENDIMENTO" },
	{ value: "MOTORISTA", label: "MOTORISTA" },
	{ value: "FC CÍVIL", label: "FC CÍVIL" },
	{ value: "PSICÓLOGA", label: "PSICÓLOGA" },
	{ value: "POLICIAL PENAL", label: "POLICIAL PENAL" },
];

export default typeFunction;
