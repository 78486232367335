import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';

import TextAutoSize from '../TextAutoSize';
import { Container, Whapper } from './styles';

interface IFlex extends GridProps{
  title: string;
  textAutoSize?: boolean;
}
const Flex: React.FC<IFlex> = ({
  title,
  children,
  textAutoSize = false,
  ...rest
}) => (
  <Grid item {...rest}>
    <Container>
      <h3>{title}</h3>
      <Whapper>
        {textAutoSize
          ? <TextAutoSize>{children}</TextAutoSize>
          : children}
      </Whapper>
    </Container>
  </Grid>
);

export default Flex;

export { Grid };
