import styled, { css } from 'styled-components';

interface IContainer {
  isResult?: boolean;
}

export const Container = styled.div<IContainer>`
  box-shadow: var(--shadow);
  border-radius:4px;

  padding: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  ${(props) => props.isResult && css`
    background: #EEE8AA;`}

  transition: transform 0.2s;
  &:hover{
     transform: translateY(-4px);
  }
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span{
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  svg{
    font-size: 24px;
    margin-right: 8px;
  }

  h2{
    font-size: 1rem;
    font-weight: 700;
  }
`;
