import React from 'react';
import { formatDateTime } from '../../../utils';

/** componentes */
import Flex, { Grid } from '../../../components/Flex';

import { Container } from './styles';

import { IService } from '../../../interfaces';

const Missing: React.FC<{service: IService} | null> = ({ service }) => (
  <Container>

    <h1>Informações do Serviço</h1>

    <Grid container spacing={1}>
      <Flex title="Gerência - Setor" textAutoSize xs={6}>
        {service.management?.initials} - {service.sector}
      </Flex>
      <Flex title="BodyCam" textAutoSize xs={6}>
        {service.body_cam}
      </Flex>
      <Flex title="Serviço criado por" textAutoSize xs={12}>
        {service.userAuth}
      </Flex>

      <Flex title="Início" textAutoSize xs={6}>
        {formatDateTime(service.started_at)}
      </Flex>
      <Flex title="Término" textAutoSize xs={6}>
        {formatDateTime(service.ended_at)}
      </Flex>

      <Flex title="Agente" textAutoSize xs={12}>
        {service.user}
      </Flex>

      {!service.missing_id && (
        <>
          <Flex title="Presença confirmada em" textAutoSize xs={6}>
            {service.presence_started_at && formatDateTime(service.presence_started_at)}
          </Flex>
          <Flex title="Presença confirmada por" textAutoSize xs={12} md={6}>
            {service.userPresenceStarted}
          </Flex>

          <Flex title="Serviço encerrado em" textAutoSize xs={6}>
            {service.presence_ended_at && formatDateTime(service.presence_ended_at)}
          </Flex>
          <Flex title="Serviço encerrado por" textAutoSize xs={12} md={6}>
            {service.userPresenceEnded }
          </Flex>
        </>
      )}

      {service.missing_id && (
        <>
          <Flex title="Falta informada em" textAutoSize xs={12} md={6}>
            {formatDateTime(service.missing_at)}
          </Flex>
          <Flex title="Falta informada por" textAutoSize xs={12} md={6}>
            {service.userMissing}
          </Flex>
        </>
      )}

      <Flex title="Alterações" xs={12}>
        <pre>
          {service.description || 'Não informada'}
        </pre>
      </Flex>
    </Grid>

  </Container>
);

export default Missing;
