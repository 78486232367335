import React from 'react';
import { Container, ButtonReturn } from './styles';
import { EqualizerIcon, ReplyIcon } from '../../../styles/icons';

interface ReturnSalverPlusProps{
    hiddenButton?: boolean;
    handleDrawer():void;
    handleReturn():void;
  }

const FilterDrawerReturn: React.FC<ReturnSalverPlusProps> = ({
  children, hiddenButton, handleReturn,
}) => (

  <Container>
    <h1>
      {children}
    </h1>
    <ButtonReturn>
      <ReplyIcon onClick={handleReturn} />
    </ButtonReturn>
    {!hiddenButton && (
      <label className="pure-toggle-label-custom" htmlFor="pure-toggle-right" data-toggle-label="right">
        <EqualizerIcon />
      </label>
    )}
  </Container>
);

export default FilterDrawerReturn;
