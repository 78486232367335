import React, { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import MAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    borderRadius: 0,
  },
  accordion: {
    color: '#666',
    backgroundColor: 'transparent',
    boxShadow: 'none',

  },
  accordionSummary: {
    color: '#333',
    padding: 0,
  },
}));

export interface IAccordion{
  title: string | React.ReactNode;
  content?: string | React.ReactNode;
  children?: React.ReactNode;
  expanded?: boolean;
}

const Accordion: React.FC<IAccordion> = ({
  title,
  content,
  children,
  expanded = false,
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(expanded);
  return (
    <>
      <MAccordion
        expanded={show}
        onClick={() => setShow(!show)}
        square
        className={classes.accordion}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon className={classes.accordionSummary} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          { typeof title === 'string' ? <Typography className={classes.heading}>{title}</Typography> : title}

        </AccordionSummary>
        <AccordionDetails>
          {content}
          {children && children}
        </AccordionDetails>
      </MAccordion>
    </>
  );
};

export default Accordion;
