import React, {
  useRef, useCallback, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from 'hooks/toast';
import HeaderSimle from '../../../../components/Headers/Simple';
import getValidationError from '../../../../utils/getValidationsErros';
import { session } from '../../../../utils';

/** hooks */
import { useAuth } from '../../../../hooks/auth';
import { useHttp } from '../../../../hooks/http';

/** components */
import Modal from '../../../../components/ModalSimple';
import {
  WrapperLabel, Input, InputMask, TextArea, Button, ButtonIcon,
} from '../../../../components/Forms';
import { InfoIcon } from '../../../../styles/icons';
import {
  Container, GridButtons, WrapperBtnInfo, WrapperBtnCancel, WrapperBtnSubmit,
} from './styles';

interface IForm {
  coord: {
    lat: number,
    lng: number
  }
}

const Detran: React.FC<IForm> = ({ coord }) => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { httpGet, unLoading, loading } = useHttp();
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    unLoading();
  }, [unLoading, loading]);

  const handleSubmit = useCallback(async (data: any) => {
    const person = {
      ...data,
      ...coord,
      management_id: user?.allocation?.management_id,
    };

    let extendSchema;

    if (!person.document_secondary && !person.name) {
      if (!person.mather && !person.birthday) {
        extendSchema = {
          document_secondary: Yup.string().required('insira Rg ou Nome, Nascimento e Mãe.'),
        };
      }
    }

    let additionSchema;

    if (person.name || person.mather || person.birthday) {
      additionSchema = {
        name: Yup.string().required('insira Nome, Nascimento e Mãe.'),
        mather: Yup.string().required('insira a Mãe, Nome e Nascimento.'),
        birthday: Yup.string().required('insira o Nascimento, Nome e Mãe'),
      };
    }

    const schema = Yup.object().shape({
      mather: Yup.string(),
      ...additionSchema,
      ...extendSchema,
    });

    try {
      formRef.current?.setErrors({});
      await schema.validate(person, { abortEarly: false });

      setBtnLoading(true);
      const response: any = await httpGet('/orders/autocomplete/detran', { params: { ...person } });

      if (response.status === 200) {
        const { data } = response;

        console.log("data ", data);

        if (data.MsgRetorno === 'Cidadão não encontrado'
          || data.MsgRetorno === 'Não existe solicitação de Processamento em aberto para este ID.') {
          addToast({ type: 'error', title: data.MsgRetorno });
        } else if (data?.RG !== 0) {
          session.remove('detran');
          session.setObjectDetran('detran', data);
          history.push(`/order/detran/detail/${data.RG}`);
        } else {
          addToast({ title: data.MsgRetorno });
        }
      }

      setBtnLoading(false);
      if (response.status !== 200) {
        setBtnLoading(false);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }

      setBtnLoading(false);
    }

  }, [coord, history]); // eslint-disable-line

  return (
    <>
      <HeaderSimle>
        IDENTIFICAÇÃO CIVIL
      </HeaderSimle>
      <Container>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <WrapperLabel label="RG" xs={12} sm={6}>
              <Input name="document_secondary" type="tel" />
            </WrapperLabel>
            <WrapperLabel label="Nascimento" xs={12} sm={6}>
              <InputMask name="birthday" mask="99/99/9999" placeholder="DD/MM/YYYY" type="tel" />
            </WrapperLabel>
            <WrapperLabel label="Nome" xs={12}>
              <Input name="name" />
            </WrapperLabel>
            <WrapperLabel label="Mãe" xs={12}>
              <Input name="mather" />
            </WrapperLabel>
            <WrapperLabel label="Observação" xs={12}>
              <TextArea row={2} name="description" />
            </WrapperLabel>
          </Grid>

          <GridButtons>
            <WrapperBtnInfo>
              <ButtonIcon
                title="Instruções"
                onClick={() => labelRef.current?.click()}
              >
                <InfoIcon />
              </ButtonIcon>

              <label ref={labelRef} htmlFor="modal-instruction" />
              <Modal id="modal-instruction" width={520} height="auto">
                <h1>Instruções</h1><br />
                <p>
                  Para solicitar uma consulta de pessoa você precisa no mínimo
                  informar os seguintes dados:
                </p><br />
                <p><b>Nome e RG</b> ou</p>
                <p><b>Nome e Nascimento</b> ou</p>
                <p><b>Nome e Mãe</b> ou</p>
                <p><b>Nome e Pai</b>.</p>
                <br />
              </Modal>

            </WrapperBtnInfo>
            <WrapperBtnCancel>
              <Button
                type="button"
                onClick={() => { history.push('/dashboard'); }}
              >
                Cancelar
              </Button>
            </WrapperBtnCancel>
            <WrapperBtnSubmit>
              <Button
                type="submit"
                isLoading={btnLoading}
              >
                Salvar
              </Button>
            </WrapperBtnSubmit>
          </GridButtons>
        </UForm>
      </Container>
    </>
  );
};

export default Detran;
