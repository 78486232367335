import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error } from './styles';

interface InputProp extends InputProps{
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
}

const InputWrapped: React.FC<InputProp> = ({ name, icon: Icon, ...rest }) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);

  const {
    fieldName, defaultValue, error, registerField,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      // setValue(ref: any, value: string) {
      //   ref.setInputValue(value);
      // },
      // clearValue(ref: any) {
      //   ref.setInputValue('');
      // },
    });
  }, [fieldName, registerField]);

  const handleFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Container isErrored={!!error} isFocused={isFocused}>
      {Icon && <Icon size={20} />}
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        onFocus={handleFocused}
        onBlur={handleInputBlur}
        type="tel"
        {...rest}
      />
      {error && (
      <Error title={error}>
        <FiAlertCircle color="#c53030" size={20} />
      </Error>
      )}
    </Container>
  );
};

export default InputWrapped;
