import React, { useState, useRef, useCallback } from 'react';
import { format } from 'date-fns';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import Modal from '@material-ui/core/Modal';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import getValidationError from '../../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../../hooks/http';

import Flex, { Grid } from '../../../../components/Flex';
import { WrapperLabel, DatePicker, Button } from '../../../../components/Forms';
import { IManagement } from '../../../../interfaces';
import { TrashIcon } from '../../../../styles/icons';
import {
  Container, WrapperRemove, WrapperEnded, WrapperForm,
} from './styles';

const useStyles = makeStyles(() => createStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface IForm {
  id: number;
  minDate: Date;
  loadAllocations(data: any):any;
}

const Form: React.FC<IForm> = ({ id, minDate, loadAllocations }) => {
  const formRef = useRef<FormHandles>(null);
  const { httpPut, loading } = useHttp();

  const handleSubmit = useCallback(async (formData: any) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        ended_at: Yup.date().nullable().required('Data de encerramento é obrigatória'),
      });

      await schema.validate(formData, { abortEarly: false });
      const { data, status } = await httpPut(`allocations/terminate/${id}`, formData);

      if (status === 200) {
        loadAllocations(data.allocations);
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const erros = getValidationError(error);
        formRef.current?.setErrors(erros);
      }
    }
  }, [id, loadAllocations]);// eslint-disable-line

  return (
    <WrapperForm>
      <UForm
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <WrapperLabel label="Encerrar em:" xs={12}>
          <DatePicker
            name="ended_at"
            minDate={new Date(minDate)}
            popperPlacement="top-end"
          />
        </WrapperLabel>
        <Button type="submit" isLoading={loading}>Salvar</Button>
      </UForm>
    </WrapperForm>
  );
};

interface IAllocation{
  allocation: {
    id: number;
    index: number;
    started_at: Date;
    ended_at: Date;
    management: IManagement;
  };

  handleRemove(id: number): void;
  loadAllocations(data: any): any;
}

const Card: React.FC<IAllocation> = ({ allocation, handleRemove, loadAllocations }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <Container>
      <Grid container spacing={1}>
        <Flex title="Gerência" xs={11}>
          {allocation.management.initials} - {allocation.management.name}
        </Flex>
        { allocation.index === 0
          && (
            <Grid item xs={1}>
              <WrapperRemove onClick={() => handleRemove(allocation.id)}>
                <TrashIcon />
              </WrapperRemove>
            </Grid>
          )}

        <Flex title="Início" xs={6}>
          {format(new Date(allocation.started_at), 'dd/MM/yyyy')}
        </Flex>
        {allocation.ended_at
        && (
        <Flex title="Término" xs={6}>
          {format(new Date(allocation.ended_at), 'dd/MM/yyyy')}
        </Flex>
        )}
        {!allocation.ended_at
        && (
        <Flex title="Término" xs={6}>
          <WrapperEnded>
            <button
              type="button"
              onClick={() => setOpen(!open)}
            >
              Encerrar ?
            </button>

            <Modal
              className={classes.modal}
              open={open}
              onClose={() => setOpen(!open)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <>
                <Form
                  id={allocation.id}
                  minDate={allocation.started_at}
                  loadAllocations={loadAllocations}
                />
              </>
            </Modal>
          </WrapperEnded>
        </Flex>
        )}

      </Grid>

    </Container>
  );
};

export default Card;
