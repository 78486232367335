import React, { useRef, useCallback, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHttp } from '../../../hooks/http';
import Layout from '../../../components/Layout';
import Drawer from '../../../components/Drawer';
import HeaderFilterDrawerSimple from '../../../components/Headers/FilterDrawerSimple';
import { ListIcon } from '../../../styles/icons';
import { Container, DrawerContent, WrapperName } from './styles';
import Pagination from '../../../components/Pagination';
import { IPaginate } from 'interfaces';
import { WrapperLabel, DatePicker, Button, ButtonIcon } from '../../../components/Forms';
import { formatDate } from '../../../utils';
import { ArrowBarRightIcon, TrashIcon } from '../../../styles/icons';
import Card, { CardHeader, CardContent } from '../../../components/Card';
import Flex from 'components/Flex';
import { useHistory } from 'react-router';
import { format } from 'date-fns';
import * as Yup from 'yup';
import getValidationError from '../../../utils/getValidationsErros';

interface IFilter {
    id: string;
    operation_date: Date;
}
const LeiSeca: React.FC = () => {
    const { httpGet, loading } = useHttp();
    const formRef = useRef<FormHandles>(null);
    const RefLabelToggle = useRef<HTMLLabelElement>(null);
    const [rcols, setRcols] = useState([]);
    const [filter, setFilter] = useState<IFilter>({} as IFilter);
    const history = useHistory();
    const [paginate, setPaginate] = React.useState<IPaginate>({ page: 1, lastPage: 1, perPage: 10 });

    const breadcrumbs = {
        icon: ListIcon,
        links: [
            { title: `LeiSeca`, path: '' },
        ],
    };

    const getLeiSeca = useCallback(async (filter: IFilter, paginate: IPaginate) => {
        const { status, data } = await httpGet('rcols/leisecalist', {
            params: { ...filter, ...paginate },
        });

        if (status === 200) {
            setRcols(data.data);
            setPaginate(data.pagination);
        }
    }, [httpGet]);

    useEffect(() => {
        getLeiSeca(
            { id: '' } as IFilter,
            { page: 1, lastPage: 1, perPage: 10 },
        );
    }, [getLeiSeca, httpGet]);

    const handleSubmit = useCallback(async (payload: any) => {

        try {
            const filter = {
                ...payload,
                id: null,
                page: 1,
                perPage: 10,
                operation_date: payload.operation_date ? format(payload.operation_date, 'yyyy-MM-dd') : null
            };
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                operation_date: Yup.string().ensure()
            });

            await schema.validate(filter, { abortEarly: false });
            setFilter(filter);
            const { status, data } = await httpGet('rcols/leisecalist', {
                params: { ...filter },
            });

            if (status === 200) {
                setRcols(data.data);
                setPaginate(data.pagination);

                if (data.data.length > 0) {
                    RefLabelToggle?.current?.click();
                }
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
            }
        }
    }, [httpGet]);

    const handlePaginate = useCallback((payload: IPaginate) => {
        setPaginate(payload);
        getLeiSeca(filter, payload);
    }, [filter, getLeiSeca]);

    return (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
            <Container>
                <HeaderFilterDrawerSimple />
                <Drawer>
                    <DrawerContent>
                        <UForm ref={formRef} onSubmit={handleSubmit}>
                            <Grid container spacing={1}>
                                <WrapperLabel label="Data" xs={12}>
                                    <DatePicker
                                        name="operation_date"
                                        isClearable
                                    />
                                </WrapperLabel>
                            </Grid>

                            <Grid container spacing={1} justify="space-between" alignItems="baseline">
                                <Grid item xs={10} md={4}>
                                    <span style={{ display: 'flex', gap: 8, marginTop: 8 }}>
                                        <ButtonIcon title="Fechar">
                                            <label ref={RefLabelToggle} htmlFor="pure-toggle-right" data-toggle-label="right">
                                                <ArrowBarRightIcon />
                                            </label>
                                        </ButtonIcon>
                                        <ButtonIcon
                                            title="Limpar"
                                            onClick={() => {
                                                formRef.current?.reset();
                                            }}
                                        >
                                            <TrashIcon />
                                        </ButtonIcon>
                                    </span>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Button type="submit" isLoading={loading}> Filtrar </Button>
                                </Grid>
                            </Grid>
                        </UForm>
                    </DrawerContent>
                </Drawer>

                <Grid container spacing={1}>
                    <Grid container justify="flex-start" spacing={1}>
                        {rcols?.map((rcol: any) => (
                            <Grid item key={rcol.id} xs={12} sm={6} md={6} onClick={() => history.push(`/leiseca/detail/${rcol.id}`)}>
                                <Card>
                                    <CardHeader>
                                        <WrapperName>
                                            {rcol.team}
                                        </WrapperName>
                                        {formatDate(String(rcol.operation_date).split('T')[0])}
                                    </CardHeader>
                                    <CardContent>
                                        <Grid container spacing={1}>
                                            <Flex title="Chefe Civil" xs={12} sm={6} md={4}>
                                                {rcol.user.name}
                                            </Flex>
                                            <Flex title="Chefe Militar" xs={12} sm={6} md={4}>
                                                {rcol.military.name}
                                            </Flex>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Pagination paginate={paginate} handlePaginate={handlePaginate} />
            </Container>
        </Layout>
    );
};

export default LeiSeca;