import styled from 'styled-components';

export const Container = styled.div`
  
`;
export const WrapperTitle = styled.span`
  display: flex;
  align-items: center;

  h3, h2{
      font-size: 18px;
  }
  > svg{
      margin-right: 8px;
  }

  @media (max-width: 576.98px) {
    h2 {
      display: none;
    }
    > svg{
      margin-right: 0;
    }
  }
`;
