import React from 'react';
import { useHistory } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

/** hooks */
import { useAuth } from '../../../hooks/auth';

/** components */
import Flex, { Grid } from '../../../components/Flex';
import {
    Menu, MenuItem, DotButton, ItemIcon,
} from '../../../components/CustomMenu';

import { Container, WrapperMenu } from './styles';
import { PencilIcon } from '../../../styles/icons';
import { IGas } from '../../../interfaces';

const Card: React.FC<{ gas: IGas }> = ({ gas }) => {
    const history = useHistory();
    const { iCan } = useAuth();

    return (
        <Container>
            <Grid container spacing={1}>
                {iCan('manager') && (
                    <WrapperMenu>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <>
                                    <DotButton {...bindTrigger(popupState)} />
                                    <Menu {...bindMenu(popupState)}>
                                        <MenuItem
                                            onClick={() => { history.push(`/gas/${gas.id}`); }}
                                            style={{ width: 150 }}
                                        >
                                            <ItemIcon icon={<PencilIcon />} title="Editar" />
                                        </MenuItem>
                                    </Menu>
                                </>
                            )}
                        </PopupState>
                    </WrapperMenu>
                )}

                <Flex title="Cod" xs={2} md={1}>
                    {gas.id}
                </Flex>

                <Flex title="Nome" textAutoSize xs={10} md={4}>
                    {gas.fantasy} - {gas.corporate}
                </Flex>

                <Flex title="Telefone" xs={12} md={2}>
                    {gas?.telephone}
                </Flex>

                <Flex title="Cidade" xs={12} md={3}>
                    {gas.city} - {gas.state}
                </Flex>

                <Flex title="Status" xs={12} md={2}>
                    {gas.status ? 'ATIVO' : 'INATIVO'}
                </Flex>

            </Grid>
        </Container>
    );
};

export default Card;
