import React, { useEffect } from 'react';

import {
  FiAlertCircle, FiCheckCircle, FiInfo, FiX,
} from 'react-icons/fi';

import { ToastMessage, useToast } from '../../../hooks/toast';
import { Container, Link } from './styles';

interface ToastProps{
  message: ToastMessage;
  style: object;
}

const icons = {
  info: <FiInfo size={24} />,
  success: <FiCheckCircle size={24} />,
  error: <FiAlertCircle size={24} />,
  warning: <FiAlertCircle size={24} />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    if (message.time === 0) return;
    const time = message.time || 5;
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, time * 1000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [message.time, message.id, removeToast]);

  return (
    <Container
      type={message.type}
      style={style}
      description={Number(!!message.description)}
    >
      {icons[message.type || 'info']}
      <div>
        <strong>{message.title}</strong>
        { message.description && <p>{message.description}</p>}
        { message.link && <Link href={message.link.url}>{message.link.title}</Link>}
      </div>
      <button type="button" onClick={() => removeToast(message.id)}>
        <FiX size={18} />
      </button>
    </Container>
  );
};

export default Toast;
