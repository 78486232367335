import React, {
  useEffect, useState,
} from 'react';
import { useParams } from 'react-router';
import _ from 'lodash';

/** hooks */
import { useHttp } from '../../hooks/http';

/**  componentes */
import Layout from '../../components/Layout';
import Profile from './Profile';
import History from './History';
import Tabs, { TabsItem } from '../../components/Tabs';
import { PersonIcon } from '../../styles/icons';

import { Container, WrapperTitle } from './styles';

/** interfaces */
import { IPerson } from '../../interfaces';

const Form: React.FC = () => {
  const { id } = useParams<{id: string}>();
  const [person, setPerson] = useState({} as IPerson);
  const [orders, setOrders] = useState<any[]>([]);

  const { httpGet } = useHttp();
  useEffect(() => {
    setPerson({} as IPerson);

    async function loadPerson(): Promise<void> {
      const { status, data } = await httpGet(`people/${id}`);
      if (status === 200) {
        setPerson(data);

        const orderSorted = await data.orders?.length > 0
          ? _.orderBy(data.orders, ['created_at'], ['desc'])
          : [];

        setOrders(orderSorted);
      }
    }

    id && loadPerson();

  }, [id]); // eslint-disable-line

  const breadcrumbs = {
    icon: PersonIcon,
    links: [
      { path: '/people', title: 'Pessoas' },
      { path: '', title: 'Pessoa' },
    ],
  };
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>

        <Tabs>
          <TabsItem title="Perfil" show>
            <Profile person={person} />
          </TabsItem>

          <TabsItem title="Histórico de Abordagens" enable={!!person.id}>
            {orders.length > 0 && (
              <WrapperTitle>
                <h2>Total de abordagens: <b>{orders.length}</b></h2>
              </WrapperTitle>
            )}
            {orders && orders
              .map((order) => <History key={order.id} order={order} />)}
          </TabsItem>

        </Tabs>

      </Container>
    </Layout>
  );
};

export default Form;
