import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { Button, Select, WrapperLabel } from 'components/Forms';
import { useAuth } from 'hooks/auth';
import {
  formatDateTime, formatPlate,
} from '../../../utils';
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';
import Layout from '../../../components/Layout';
import Flex, { Grid } from '../../../components/Flex';
import {
  ListIcon,
  SupportIcon,
} from '../../../styles/icons';
import {
  Container, Content, WrapperHeader, Title,
  GridButtons,
  WrapperBtnCancel,
  WrapperBtnSubmit,
} from './styles';

const Detail: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<{id: string}>();
  const { httpGet, httpPost, loading } = useHttp();
  const { addToast } = useToast();
  const { iCan } = useAuth();
  const [order, setOrder] = useState<any>({}as any);

  useEffect(() => {
    async function loadAnswer():Promise<void> {
      const { status, data } = await httpGet(`fleetMechanicals/${id}`);
      if (status === 200) {
        const [ref] = data.id.split('-');
        setOrder({
          ...data,
          ref: ref.toUpperCase(),
        });
      } else {
        addToast({ title: 'Erro ao tentar acessar o atendimento.', type: 'error' });
        history.push('/companys');
      }
    }
    loadAnswer();

  }, [addToast, history, id]);  // eslint-disable-line

  const handleSubmit = useCallback(async (data: any) => {
    const answer = {
      id,
      option: data?.option?.value,
      option2: data?.option2?.value,
    };

    console.log('aws ', answer);

    const result: any = await httpPost('/fleetMechanicals/process3', answer);
    const { status } = result;

    if (status === 200) {
      addToast({ title: 'Finalizado com sucesso.' });
      history.push('/maintenance/list');
    }
  }, [addToast, history, httpPost, id]);

  let breadcrumbs;

  breadcrumbs = {
    icon: ListIcon,
    links: [
      { path: '/companys/process', title: 'Suporte Manutenção' },
      { path: '', title: 'Detalhe da Manutenção' },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  iCan('adm') && (
    breadcrumbs = {
      icon: ListIcon,
      links: [
        { path: '/maintenance/list', title: 'Suporte Manutenção' },
        { path: '', title: 'Detalhe da Manutenção' },
      ],
    }
  );

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <WrapperHeader>
            <span>
              <SupportIcon />
              #{ order.ref}
            </span>
            <h2 style={{ fontSize: '1em', fontWeight: 'bold' }}>{order.situation}</h2>
          </WrapperHeader>

          <Title>Dados informados:</Title>
          <Grid container spacing={1}>
            <Flex title="Placa" xs={12} sm={6} md={6}>
              {order.fleet?.plate ? formatPlate(order.fleet.plate) : 'não informado'}
            </Flex>
            <Flex title="Gerência" xs={12} sm={6} md={6}>
              {order.managements?.name}
            </Flex>
            <Flex title="Problema" xs={12} sm={6} md={6}>
              {order.problem}
            </Flex>
            <Flex title="Solicitação" xs={12} sm={6} md={6}>
              {order.order}
            </Flex>
            {order.order === 'REBOQUE' && (
              <>
                <Flex title="Motorista do reboque" xs={12} sm={6} md={6}>
                  {order.driver}
                </Flex>
                <Flex title="Placa do reboque" xs={12} sm={6} md={6}>
                  {order.trailer_plate}
                </Flex>
              </>
            )}
            <Flex title="Observação" xs={12} sm={12} md={12}>
              {order.description || 'não informado'}
            </Flex>
            <Flex title="Solicitado por" xs={12} sm={6} md={6}>
              {order.user?.name}
            </Flex>
            <Flex title="Data/Hora" xs={12} sm={6} md={6}>
              {formatDateTime(order.created_at)}
            </Flex>
          </Grid>
          {order.situation !== 'Solicitação' && (
            <>
              <Title>Atendimento:</Title>
              <Grid container spacing={1}>
                <Flex title="Oficina" xs={12} sm={6} md={6}>
                  {order.mechanicalof}
                </Flex>
                <Flex title="Observação" xs={12} sm={6} md={6}>
                  {order.note && order.note}
                </Flex>
                <Flex title="Respondido por" xs={12} sm={4} md={4}>
                  {order.operator?.name}
                </Flex>
                <Flex title="Data/Hora" xs={6} sm={4} md={4}>
                  {formatDateTime(order.started_at)}
                </Flex>
                {order.ended_at ? (
                  <Flex title="Data/Hora Saída" xs={6} sm={4} md={4}>
                    {formatDateTime(order.ended_at)}
                  </Flex>
                ) : null}
              </Grid>
              {order.situation !== 'Finalizado' && (
                iCan('adm') && (
                <>
                  <Title>
                    Manutenção:
                  </Title>
                  <UForm ref={formRef} onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                      <>
                        <WrapperLabel label="Finalizar" xs={12} sm={12} md={12}>
                          <Select
                            name="option"
                            options={[{ label: 'SIM', value: true }]}
                          />
                        </WrapperLabel>
                      </>
                    </Grid>
                    <GridButtons>
                      <WrapperBtnCancel>
                        <Button
                          type="button"
                          onClick={() => { history.push('/companys'); }}
                        >
                          Cancelar
                        </Button>
                      </WrapperBtnCancel>
                      <WrapperBtnSubmit>
                        <Button
                          type="submit"
                          isLoading={loading}
                        >
                          Salvar
                        </Button>
                      </WrapperBtnSubmit>
                    </GridButtons>
                  </UForm>
                </>
                )
              )}
              {order.situation === 'Finalizado' && (
                iCan('admim') && (
                <>
                  <Title>
                    Manutenção:
                  </Title>
                  <UForm ref={formRef} onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                      <>
                        <WrapperLabel label="Encerrar" xs={12} sm={12} md={12}>
                          <Select
                            name="option2"
                            options={[{ label: 'SIM', value: true }]}
                          />
                        </WrapperLabel>
                      </>
                    </Grid>
                    <GridButtons>
                      <WrapperBtnCancel>
                        <Button
                          type="button"
                          onClick={() => { history.push('/companys'); }}
                        >
                          Cancelar
                        </Button>
                      </WrapperBtnCancel>
                      <WrapperBtnSubmit>
                        <Button
                          type="submit"
                          isLoading={loading}
                        >
                          Salvar
                        </Button>
                      </WrapperBtnSubmit>
                    </GridButtons>
                  </UForm>
                </>
                )
              )}
            </>
          )}
        </Content>
      </Container>
    </Layout>
  );
};

export default Detail;
