/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Divider } from '@material-ui/core';
import {
  formatCpf, formatDate,
  session,
} from '../../../utils';

/** hooks */
import { useToast } from '../../../hooks/toast';

/** components */
import Flex, { Grid } from '../../../components/Flex';
import Layout from '../../../components/Layout';
import { ListIcon, PersonIcon } from '../../../styles/icons';

import {
  Container,
  Content, Img, WrapperHeader,
} from './styles';
/** interface */

const breadcrumbs = {
  icon: ListIcon,
  links: [{ path: '', title: 'Detalhe da  Consulta' }],
};

const Detail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const [order, setOrder] = useState<any>([]);

  useEffect(() => {
    async function loadAnswer(): Promise<void> {
      const user = session.get('victim') as any;
      setOrder(JSON.parse(user));
    }

    loadAnswer();

    }, [addToast, history, id]);  // eslint-disable-line

  let resultName;
  switch (order.result?.id) {
    case 6:
      resultName = 'Nada constatado';
      break;
    case 2:
      resultName = 'Nada constatado';
      break;
    case 11:
      resultName = 'Nada constatado';
      break;
    default:
      resultName = order.identifier;
      break;
  }

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content isConduction={!!order.result?.conduction}>
          <WrapperHeader>
            <span>
              <PersonIcon />
              {order.name}
            </span>
            <h2>
              Sinalid: {resultName}
            </h2>
          </WrapperHeader>

          <Grid container spacing={1}>
            {order?.image && (
            <Flex title="Foto" xs={6} md={6}>
              <Img src={order?.image} width={100} height={100} />
            </Flex>
                        )}
          </Grid>
          <Grid container spacing={1}>
            <Flex title="CPF" xs={6} sm={6} md={3}>
              {order.document ? formatCpf(order.document) : 'não informado'}
            </Flex>
            <Flex title="RG" xs={6} sm={6} md={3}>
              {order.document_secondary || 'não informado'}
            </Flex>
            <Flex title="Nascimento" xs={12} sm={6} md={4}>
              {order.birthday ? formatDate(order.birthday) : 'não informado'}
            </Flex>
            <Flex title="Sexo" xs={12} sm={6} md={4}>
              {order.gender || 'não informado'}
            </Flex>
            <Flex title="Mãe" xs={6} sm={6} md={4} textAutoSize>
              {order.mather || 'não informado'}
            </Flex>
            <Flex title="Pai" xs={6} sm={6} md={4} textAutoSize>
              {order.father || 'não informado'}
            </Flex>
            <Divider />
            <Flex title="Bairro" xs={12} sm={6} md={4}>
              {order.district || 'não informado'}
            </Flex>
            <Flex title="Cidade" xs={6} sm={6} md={4} textAutoSize>
              {order.contry || 'não informado'}
            </Flex>
            <Flex title="Uf" xs={6} sm={6} md={4} textAutoSize>
              {order.uf || 'não informado'}
            </Flex>
            <Divider />
            <Flex title="R.O" xs={6} sm={6} md={4} textAutoSize>
              {order.ro || 'não informado'}
            </Flex>
            <Flex title="Observação" xs={12}>
              {order.description || 'não informado'}
            </Flex>
          </Grid>
        </Content>
      </Container>
    </Layout>
  );
};

export default Detail;
