import styled, { css } from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
`;

export const RedStyle = styled.div`
  padding: 16px;
  font-weight: bold;
  border-radius: 4px;
  margin: 10px auto;
  background-color: #CC0001;
  color: white;
  text-align: justify;
`;

export const Card = styled.div`
`;

interface Props {
  show?: boolean;
}

export const WrapperHidden = styled.div<Props>`
  width: 100%;
  padding: 0 4px;
  ${(props) => !props.show && css`
    position: absolute;
    right: 9999px;
  `}
`;

export const WrapperModal = styled.div`
  color: #212529;
  .modal__inner{
    background: #FFCD39;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    svg {
      flex-shrink: 0;
      font-size: 1.5em;
    }
  }
  p{
    margin-bottom: 8px;
  }
  b{
    font-size: 1.1rem;
  }
  span{
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
`;

export const Death = styled.div`
  font-weight: bold;
  color: #CC0001;
`;

interface IProps {
  go?: any;
  off?: any;
}

export const Red = styled.div<IProps>`
  color: ${(props) => (props.go ? '#D3D3D3' : '#CC0001')};
`;