export const type = [
  {
    value: 'AUTOMÓVEL',
    label: 'AUTOMÓVEL',
  }, {
    value: 'MOTOCICLETA',
    label: 'MOTOCICLETA',
  }, {
    value: 'BICICLETA',
    label: 'BICICLETA',
  },
];

export const fuels = [
  {
    value: 'FLEX',
    label: 'FLEX',
  }, {
    value: 'ALCOOL',
    label: 'ALCOOL',
  }, {
    value: 'GASOLINA',
    label: 'GASOLINA',
  }, {
    value: 'DIESEL',
    label: 'DIESEL',
  }, {
    value: 'GNV',
    label: 'GNV',
  },
];

export const color = [
  {
    value: 'BRANCO',
    label: 'BRANCO',
  }, {
    value: 'CINZA',
    label: 'CINZA',
  }, {
    value: 'PRETO',
    label: 'PRETO',
  }, {
    value: 'PRATA',
    label: 'PRATA',
  },
];
