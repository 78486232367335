import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;

  form{
    width: 100%;
  }
`;

export const WrapperForm = styled.div`
  > div{
    height: 40px;
  }
  margin-right: 4px;
`;

export const WrapperSearch = styled.div`
  display: flex;
  width: 400px;
  max-width: calc(100% -48px);

  button{
    margin-left: 8px;
    flex-shrink: 0;
  }
`;
export const NoResult = styled.div`
  padding: 8px 16px;
  width: 100%;
  text-align: center;
`;
