import React, {
  useRef, useCallback, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';

import getValidationError from '../../../../utils/getValidationsErros';
import { validateCpf, parseDate } from '../../../../utils';
import getCurrentPosition from '../../../../utils/getCurrentPosition';

/** hooks */
import { useAuth } from '../../../../hooks/auth';
import { useHttp } from '../../../../hooks/http';

/** components */
import Modal from '../../../../components/ModalSimple';
import {
  WrapperLabel, Input, InputMask, TextArea, Button, ButtonIcon,
} from '../../../../components/Forms';
import { InfoIcon } from '../../../../styles/icons';
import {
  Container, GridButtons, WrapperBtnInfo, WrapperBtnCancel, WrapperBtnSubmit,
} from './styles';

interface IForm {
  coord: {
    lat: number,
    lng: number
  }
}

const Nuint: React.FC<IForm> = ({ coord }) => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const { user } = useAuth();
  const { httpPost, unLoading, loading } = useHttp();
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    unLoading();
    // setBtnLoading(loading);
  }, [unLoading, loading]);

  const handleSubmit = useCallback(async (data: any) => {
    const person = {
      ...data,
      ...coord,
      birthday: parseDate(data.birthday),
      management_id: user?.allocation?.management_id,
      document: data.document.replace(/\D/g, ''),
      type: 'person',
    };

    let additionSchema;

    if (!person.document && person.name) {
      if (!person.document_secondary && !person.mather && !person.father && !person.birthday) {
        additionSchema = {
          mather: Yup.string().required('insira a Mãe, Pai ou Nascimento'),
          father: Yup.string().required('insira o Pai, Mãe ou NAscimento'),
          birthday: Yup.date().typeError('insira o Nascimento, Mãe ou Pai'),
        };
      }
    }

    let documentSchema;
    if (person.document) {
      documentSchema = {
        document: Yup.string()
          .test('is-valid', 'insira um CPF válido', (value) => validateCpf(value, true)),
      };
    }

    let birthdaySchema;
    if (person.birthday) {
      birthdaySchema = {
        birthday: Yup.date().typeError('insira uma data Válida'),
      };
    }

    const schema = Yup.object().shape({
      birthday: Yup.date().nullable().typeError('insira uma data válida'),
      name: Yup.string().required('o Nome é obrigatório'),
      mather: Yup.string(),
      father: Yup.string(),
      ...additionSchema,
      ...documentSchema,
      ...birthdaySchema,
    });

    try {
      formRef.current?.setErrors({});
      await schema.validate(person, { abortEarly: false });

      setBtnLoading(true);
      const position = await getCurrentPosition();

      const response: any = await httpPost('orders', {
        ...person,
        current_lat: position.lat,
        current_lng: position.lng,
      });

      if (response.response?.status === 302) {
        const { data } = response.response;
        history.push(`/order/detail/${data.id}`);
      }

      if (response.status === 200) {
        history.push('/orders/latest');
      }

      if (response.status !== 200) {
        setBtnLoading(false);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }

      setBtnLoading(false);
    }

  }, [coord, history]); // eslint-disable-line

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="CPF" xs={12} sm={6}>
            <InputMask name="document" type="tel" mask="999.999.999-99" />
          </WrapperLabel>
          <WrapperLabel label="RG" xs={12} sm={6}>
            <Input name="document_secondary" type="tel" />
          </WrapperLabel>
          <WrapperLabel label="Nome" xs={12} sm={6}>
            <Input name="name" />
          </WrapperLabel>
          <WrapperLabel label="Nascimento" xs={12} sm={6}>
            <InputMask name="birthday" mask="99/99/9999" placeholder="DD/MM/YYYY" type="tel" />
          </WrapperLabel>
          <WrapperLabel label="Mãe" xs={12} sm={6}>
            <Input name="mather" />
          </WrapperLabel>
          <WrapperLabel label="Pai" xs={12} sm={6}>
            <Input name="father" />
          </WrapperLabel>
          <WrapperLabel label="Observação" xs={12}>
            <TextArea row={2} name="description" />
          </WrapperLabel>
        </Grid>

        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
              onClick={() => labelRef.current?.click()}
            >
              <InfoIcon />
            </ButtonIcon>

            <label ref={labelRef} htmlFor="modal-instruction" />
            <Modal id="modal-instruction" width={520} height="auto">
              <h1>Instruções</h1><br />
              <p>
                Para solicitar uma consulta de pessoa você precisa no mínimo
                informar os seguintes dados:
              </p><br />
              <p><b>Nome e CPF</b> ou</p>
              <p><b>Nome e RG</b> ou</p>
              <p><b>Nome e Nascimento</b> ou</p>
              <p><b>Nome e Mãe</b> ou</p>
              <p><b>Nome e Pai</b>.</p>
              <br />
            </Modal>

          </WrapperBtnInfo>
          <WrapperBtnCancel>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </WrapperBtnCancel>
          <WrapperBtnSubmit>
            <Button
              type="submit"
              isLoading={btnLoading}
            >
              Salvar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>
      </UForm>
    </Container>
  );
};

export default Nuint;
