import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 16px;

  border-radius: 0 4px 4px 0;
  overflow-y: auto;
`;
