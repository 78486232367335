import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

/** hooks */
import Grid from '@material-ui/core/Grid';
import { useHttp } from '../../../hooks/http';
import { useAuth } from '../../../hooks/auth';
/** components */
import Layout from '../../../components/Layout';
import HeaderFilterPlus from '../../../components/Headers/FilterPlus';
import Pagination from '../../../components/Pagination';
import Card from '../Card';

import { HandHoldingHeartIcon } from '../../../styles/icons';
import { Container } from './styles';

/** interfaces */
import { IPaginate, ISocial } from '../../../interfaces';

const Socials: React.FC = () => {
  const { type } = useParams<{type: string}>();
  const [socials, setSocials] = useState([]);
  const [filter, setFilter] = useState('');
  const { user } = useAuth();

  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const { httpGet, loading } = useHttp();

  const breadcrumbs = {
    icon: HandHoldingHeartIcon,
    links: [
      { title: `${paginate.total} Sociais`, path: '' },
    ],
  };

  useEffect(() => {
    async function loadSocials(): Promise<void> {
      const { status, data } = await httpGet(`/socials/${type}`, {
        params: {
          user,
          filter,
          ...paginate,
        },
      });

      if (status === 200) {
        setSocials(data.data);
        setPaginate(data.pagination);
      }
    }

    loadSocials();

  }, [filter, user,paginate.page, type]); // eslint-disable-line

  const handleSearch = (value: string): void => {
    setPaginate({ ...paginate, page: 1 });
    setFilter(value);
  };

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderFilterPlus handleSearch={handleSearch} />
        <Grid container spacing={1}>
          {socials && socials.map((social: ISocial) => (
            <Card
              key={social.id}
              social={social}
              type={type}
            />
          ))}
        </Grid>
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default Socials;
