const typeWarrant = [
	{ value: "ABANDONO MORAL", label: "ABANDONO MORAL" },
	{ value: "ACIDENTE DE TRÂNSITO", label: "ACIDENTE DE TRÂNSITO" },
	{
		value: "ACIDENTE DE TRÂNSITO ENVOLVENDO POLICIAL",
		label: "ACIDENTE DE TRÂNSITO ENVOLVENDO POLICIAL",
	},
	{
		value: "ADULTERAÇÃO DO SINAL IDENTIFICADOR DE VEÍCULO",
		label: "ADULTERAÇÃO DO SINAL IDENTIFICADOR DE VEÍCULO",
	},
	{ value: "AGRESSÃO", label: "AGRESSÃO" },
	{ value: "AMEAÇA", label: "AMEAÇA" },
	{ value: "APREENSÃO DE CELULAR", label: "APREENSÃO DE CELULAR" },
	{ value: "APREENSÃO DE DROGAS", label: "APREENSÃO DE DROGAS" },
	{ value: "APREENSÃO DE MATERIAL", label: "APREENSÃO DE MATERIAL" },
	{ value: "APREENSÃO DE VEÍCULOS", label: "APREENSÃO DE VEÍCULOS" },
	{ value: "APROPRIAÇÃO INDÉBITA", label: "APROPRIAÇÃO INDÉBITA" },
	{ value: "ARMA BRANCA", label: "ARMA BRANCA" },
	{ value: "ARMA DE FOGO", label: "ARMA DE FOGO" },
	{ value: "ATO OBSCENO", label: "ATO OBSCENO	" },
	{ value: "AUXÍLIO A TRANSEUNTE", label: "AUXÍLIO A TRANSEUNTE" },
	{
		value: "AVERIGUAÇÃO EM SEDE POLICIAL",
		label: "AVERIGUAÇÃO EM SEDE POLICIAL",
	},
	{ value: "BUSCA E APREENSÃO DE MENOR", label: "BUSCA E APREENSÃO DE MENOR" },
	{ value: "CAPTURA DE EVADIDO", label: "CAPTURA DE EVADIDO" },
	{ value: "CONTRAVENÇÃO", label: "CONTRAVENÇÃO" },
	{ value: "CORRUPÇÃO ATIVA", label: "CORRUPÇÃO ATIVA" },
	{ value: "CRIME AMBIENTAL", label: "CRIME AMBIENTAL" },
	{ value: "DANO", label: "DANO" },
	{ value: "DESACATO", label: "DESACATO" },
	{ value: "DESOBEDIÊNCIA", label: "DESOBEDIÊNCIA" },
	{ value: "DIREÇÃO PERIGOSA", label: "DIREÇÃO PERIGOSA" },
	{ value: "DIRIGIR SEM CNH", label: "DIRIGIR SEM CNH" },
	{ value: "ENCONTRO DE CADÁVER", label: "ENCONTRO DE CADÁVER" },
	{ value: "ESTELIONATO", label: "ESTELIONATO" },
	{ value: "ESTUPRO", label: "ESTUPRO" },
	{
		value: "EXERCÍCIO ILEGAL DA PROFISSÃO",
		label: "EXERCÍCIO ILEGAL DA PROFISSÃO",
	},
	{ value: "EXTORSÃO", label: "EXTORSÃO" },
	{ value: "FALSA IDENTIDADE", label: "FALSA IDENTIDADE" },
	{ value: "FATO ATÍPICO", label: "FATO ATÍPICO" },
	{ value: "FRAUDE", label: "FRAUDE" },
	{ value: "FURTO", label: "FURTO" },
	{
		value: "FURTO A ESTABELECIMENTO COMERCIAL",
		label: "FURTO A ESTABELECIMENTO COMERCIAL",
	},
	{ value: "FURTO A TRANSEUNTE", label: "FURTO A TRANSEUNTE" },
	{ value: "FURTO DE BICICLETA", label: "FURTO DE BICICLETA" },
	{ value: "FURTO DE CELULAR", label: "FURTO DE CELULAR" },
	{ value: "FURTO DE VEÍCULOS", label: "FURTO DE VEÍCULOS" },
	{
		value: "HOMICÍDIO CULPOSO DE TRÂNSITO",
		label: "HOMICÍDIO CULPOSO DE TRÂNSITO",
	},
	{ value: "HOMICÍDIO DOLOSO", label: "HOMICÍDIO DOLOSO" },
	{ value: "HOMICÍDIO FEMINICÍDIO", label: "HOMICÍDIO FEMINICÍDIO" },
	{ value: "IMPORTUNAÇÃO SEXUAL", label: "IMPORTUNAÇÃO SEXUAL" },
	{ value: "INJÚRIA", label: "INJÚRIA" },
	{
		value: "LATROCÍNIO (ROUBO SEGUIDO DE MORTE)",
		label: "LATROCÍNIO (ROUBO SEGUIDO DE MORTE)",
	},
	{
		value: "LESÃO CORPORAL CULPOSA DE TRÂNSITO",
		label: "LESÃO CORPORAL CULPOSA DE TRÂNSITO",
	},
	{ value: "LESÃO CORPORAL DOLOSA", label: "LESÃO CORPORAL DOLOSA" },
	{ value: "MANDADO DE PRISÃO", label: "MANDADO DE PRISÃO" },
	{ value: "MARIA DA PENHA", label: "MARIA DA PENHA" },
	{ value: "MAUS TRATOS", label: "MAUS TRATOS" },
	{ value: "MAUS TRATOS A IDOSO", label: "MAUS TRATOS A IDOSO" },
	{ value: "MAUS TRATOS AOS ANIMAIS", label: "MAUS TRATOS AOS ANIMAIS" },
	{
		value: "MORTE POR INTERVENÇÃO DE AGENTE DO ESTADO",
		label: "MORTE POR INTERVENÇÃO DE AGENTE DO ESTADO",
	},
	{ value: "PESSOAS DESAPARECIDAS", label: "PESSOAS DESAPARECIDAS" },
	{ value: "POSSE E USO", label: "POSSE E USO" },
	{ value: "RECEPTAÇÃO", label: "RECEPTAÇÃO" },
	{ value: "RECUPERAÇÃO DE VEÍCULO", label: "RECUPERAÇÃO DE VEÍCULO" },
	{ value: "RESISTÊNCIA", label: "RESISTÊNCIA" },
	{ value: "ROUBO A BANCO", label: "ROUBO A BANCO" },
	{
		value: "ROUBO A ESTABELECIMENTO COMERCIAL",
		label: "ROUBO A ESTABELECIMENTO COMERCIAL",
	},
	{ value: "ROUBO A RESIDÊNCIA", label: "ROUBO A RESIDÊNCIA" },
	{ value: "ROUBO A TRANSEUNTE", label: "ROUBO A TRANSEUNTE" },
	{
		value: "ROUBO APÓS SAQUE EM INSTITUIÇÃO FINANCEIRA",
		label: "ROUBO APÓS SAQUE EM INSTITUIÇÃO FINANCEIRA",
	},
	{
		value: "ROUBO COM CONDUÇÃO DA VÍTIMA PARA SAQUE EM INSTITUIÇÃO FINANCEIRA",
		label: "ROUBO COM CONDUÇÃO DA VÍTIMA PARA SAQUE EM INSTITUIÇÃO FINANCEIRA",
	},
	{ value: "ROUBO DE APARELHO CELULAR", label: "ROUBO DE APARELHO CELULAR" },
	{ value: "ROUBO DE BICICLETA", label: "ROUBO DE BICICLETA" },
	{ value: "ROUBO DE CAIXA ELETRÔNICO", label: "ROUBO DE CAIXA ELETRÔNICO" },
	{ value: "ROUBO DE CARGA", label: "ROUBO DE CARGA" },
	{ value: "ROUBO DE VEÍCULO", label: "ROUBO DE VEÍCULO" },
	{ value: "ROUBO EM COLETIVO", label: "ROUBO EM COLETIVO" },
	{ value: "SEQUESTRO RELÂMPAGO", label: "SEQUESTRO RELÂMPAGO" },
	{ value: "SIMULACRO", label: "SIMULACRO" },
	{ value: "SUICÍDIO", label: "SUICÍDIO" },
	{ value: "TENTATIVA DE HOMICÍDIO", label: "TENTATIVA DE HOMICÍDIO" },
	{ value: "TRÁFICO DE DROGAS", label: "TRÁFICO DE DROGAS" },
	{
		value: "TRANSPORTE DE PRODUTOS PERIGOSOS",
		label: "TRANSPORTE DE PRODUTOS PERIGOSOS",
	},
	{ value: "TROCA DE TIROS", label: "TROCA DE TIROS" },
	{ value: "VEÍCULO ADULTERADO", label: "VEÍCULO ADULTERADO" },
	{ value: "VIAS DE FATO", label: "VIAS DE FATO" },
	{ value: "BUSCA E APREENSÃO", label: "BUSCA E APREENSÃO" },
	{
		value: "BUSCA E APREENSÃO MENOR INFRATOR",
		label: "BUSCA E APREENSÃO MENOR INFRATOR",
	},
	{ value: "ESTUPRO", label: "ESTUPRO" },
	{ value: "FURTO", label: "FURTO" },
	{ value: "HOMICÍDIO", label: "HOMICÍDIO" },
	{ value: "MARIA DA PENHA", label: "MARIA DA PENHA" },
	{ value: "TRÁFICO", label: "TRÁFICO" },
	{ value: "P.A. (PENSÃO ALIMENTÍCIA)", label: "P.A. (PENSÃO ALIMENTÍCIA)" },
	{ value: "PREVENTIVO", label: "PREVENTIVO" },
	{ value: "ROUBO", label: "ROUBO" },
];

export default typeWarrant;
