import React from 'react';
import CountUp from 'react-countup';

import { Container } from './styles';

interface IDashBox {
    title: string;
    amount: number;
    color: string;
}

const DashBox: React.FC<IDashBox> = ({
  title,
  amount,
  color,
}) => (
  <Container color={color}>
    <span>{title}</span>
    <h1>
      <CountUp
        end={amount}
      />
    </h1>
  </Container>
);

export default DashBox;
