import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import getValidationError from '../../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../../hooks/http';
import { useToast } from '../../../../hooks/toast';

/**  componentes */
import Layout from '../../../../components/Layout';
import { Grid } from '../../../../components/Flex';
import {
  WrapperLabel, Input, Select, Button,
} from '../../../../components/Forms';
import { GearIcon } from '../../../../styles/icons';
import { Container, Content } from './styles';

const Roles: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<{id: any}>();
  const { httpGet, httpPut, httpPost } = useHttp();
  const { addToast } = useToast();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    (async ():Promise<void> => {
      const { status, data } = await httpGet('/permissions');
      if (status === 200) {
        const permissionsParsed = data
          .map((permission: any) => ({ value: permission.id, label: permission.name }));
        setPermissions(permissionsParsed);
      }
    })();
  }, [httpGet]);

  useEffect(() => {
    async function load():Promise<void> {
      const { status, data } = await httpGet(`/roles/${id}`);
      if (status === 200) {
        const permissionsParsed = data?.permissions
          .map((permission: any) => ({ value: permission.id, label: permission.name }));

        formRef.current?.setData({ ...data, permissions: permissionsParsed });
      }
    }
    if (id) {
      load();
    }
  }, [httpGet, id]);

  const handleSubmit = useCallback(async (payload: any) => {
    const data = {
      ...payload,
      permissions: payload.permissions
        ? payload.permissions.map((permission: any) => permission.value)
        : [],
    };
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        slug: Yup.string().required('Slug é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      if (id) {
        const { status } = await httpPut(`roles/${id}`, data);
        if (status === 200) {
          addToast({ title: 'Editado com sucesso.' });
        }
      } else {
        const { status } = await httpPost('roles', data);
        if (status === 200) {
          addToast({ title: 'Salvo com sucesso.' });
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
      formRef.current?.setErrors(erros);
      }
    }
  }, [id, httpPut, addToast, httpPost]);

  const breadcrumbs = {
    icon: GearIcon,
    links: [
      { path: '/acl', title: 'Acl' },
      { path: '', title: 'Role' },
    ],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <WrapperLabel label="Nome" xs={12} md={6}>
                <Input name="name" />
              </WrapperLabel>
              <WrapperLabel label="Slug" xs={12} md={6}>
                <Input name="slug" />
              </WrapperLabel>
              <WrapperLabel label="Permissões" xs={12}>
                <Select
                  isMulti
                  name="permissions"
                  options={permissions}
                />
              </WrapperLabel>
            </Grid>
            <Grid container justify="flex-end">
              <Grid item xs={12} md={2}>
                <Button type="submit">Salvar</Button>
              </Grid>
            </Grid>
          </UForm>
        </Content>
      </Container>
    </Layout>
  );
};

export default Roles;
