import React, {
  useEffect, useCallback, useState,
} from 'react';

import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

/** hooks */
import { useHttp } from '../../hooks/http';

/** componentes */
import HeaderSavePlus from '../../components/Headers/SavePlus';
import Tabs, { TabsItem } from '../../components/Tabs';
import FormData from './Data';
import FormComplement from './Complement';

import { Container } from './styles';

/** interface */
import { ISocial } from '../../interfaces';

interface IForm {
  active?: boolean,
  coord?: {
    lat: number,
    lng: number
  }
}

const Form: React.FC<IForm> = ({ coord, active }) => {
  const [social, setSocial] = useState({} as ISocial);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { httpGet } = useHttp();

  useEffect(() => {
    setSocial({} as ISocial);
    async function loadUser(): Promise<void> {
      const { status, data } = await httpGet(`/socials/${id}`);
      if (status === 200) {
        setSocial(data);
      } else {
        history.push('/social');
      }
    }

    id && loadUser();
  }, [id]);// eslint-disable-line

  const handlePlus = useCallback(() => {
    history.push('/social');
  }, [history]);

  return (
    <Container>
      <HeaderSavePlus
        hiddenButton
        handlePlus={handlePlus}
      >
        {id ? `Editar ${social.name || 'não informado'}` : 'Novo Cadastro'}
      </HeaderSavePlus>
      <Tabs>
        <TabsItem title="Dados" show>
          {id && <FormData social={social} />}
          {!id && <FormData social={{} as ISocial} coord={coord} />}
        </TabsItem>
        <TabsItem title="Complemento" enable={!!id}>
          <FormComplement complement={social.complements} />
        </TabsItem>
      </Tabs>

    </Container>
  );
};

export default Form;
