import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import { Form as UForm } from '@unform/web';
import { FormHandles } from '@unform/core';

import { useToast } from 'hooks/toast';
import * as Yup from 'yup';
import Layout from 'components/Layout';
import { Grid } from '@material-ui/core';
import { Button, Select, WrapperLabel } from 'components/Forms';
import { HomeGearFillIcon } from 'styles/icons';
import { useHttp } from 'hooks/http';
import { useAuth } from 'hooks/auth';
import { Content, Container } from './styles';
import getValidationError from '../../../utils/getValidationsErros';
import Pagination from '../../../components/Pagination';
import { IPaginate } from '../../../interfaces';
import Card from './Card';

interface IOption {
  id: string;
  name: string;
  city: string;
}

interface IOptionSelect {
  value: string;
  label: string;
}

const List: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [participation, setParticipation] = useState<IOption[]>();
  const { httpGet } = useHttp();
  const { user, iIs } = useAuth();
  const { addToast } = useToast();
  const [options, setOptions] = useState<IOptionSelect[]>([]);
  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const handleSubmit = useCallback(async (data: any) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        type: Yup.string().ensure().required('Tipo é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });
      const _action = {
        type: data.type?.value,
      };

      switch (_action.type) {
        case 'PARTICIPAÇÃO': {
          const { status, data } = await httpGet('/participation/list-user', {
            params: {
              user,
              ...paginate,
            },
          });
          if (status === 200) {
            setParticipation(data.data);
            setPaginate(data.pagination);
          }
          break;
        }
        case 'OBRAANDAMENTO': {
          const { status, data } = await httpGet('/constructioninprogress/list-user', {
            params: {
              user,
              ...paginate,
            },
          });
          if (status === 200) {
            setParticipation(data.data);
            setPaginate(data.pagination);
          }
          break;
        }

        case 'AGUA': {
          const { status, data } = await httpGet('/waterqualities/list-user', {

            params: {
              user,
              ...paginate,
            },
          });
          if (status === 200) {
            setParticipation(data.data);
            setPaginate(data.pagination);
          }
          break;
        }
        case 'OBRA':
        case 'SERVIÇO': {
          const { status, data } = await httpGet('/quizzes/type-user', {
            params: {
              user,
              _action,
              ...paginate,
            },
          });
          if (status === 200) {
            setParticipation(data.data);
            setPaginate(data.pagination);
          }
          break;
        }
        default: {
          addToast({ title: 'Não foi possível gravar a opção!', type: 'error' });
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [user, paginate, httpGet, addToast]);

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  useEffect(() => {
    if (iIs('pesquisador')) {
      setOptions([
        { value: 'SERVIÇO', label: 'Serviços-Pacto RJ' },
        { value: 'OBRA', label: 'Obras-Pacto RJ' },
        { value: 'AGUA', label: 'Qualidade da Água' },
        { value: 'PARTICIPAÇÃO', label: 'Participação Comunitária-Pacto RJ' },
      ]);
    }
    if (iIs('analista-ceperj')) {
      setOptions([
        { value: 'OBRAANDAMENTO', label: 'Relatório G.A.P.O.' },
      ]);
    }
    if (iIs('admin')) {
      setOptions([
        { value: 'SERVIÇO', label: 'Serviços-Pacto RJ' },
        { value: 'OBRA', label: 'Obras-Pacto RJ' },
        { value: 'AGUA', label: 'Qualidade da Água' },
        { value: 'PARTICIPAÇÃO', label: 'Participação Comunitária-Pacto RJ' },
        { value: 'OBRAANDAMENTO', label: 'Relatório G.A.P.O.' },
      ]);
    }
  }, [iIs]);

  const breadcrumbs = {
    icon: HomeGearFillIcon,
    links: [{ path: '', title: 'Ceperj Listagem' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1} justify="center">
              <WrapperLabel label="Tipo" xs={12} sm={6} md={6}>
                <Select
                  name="type"
                  options={options}
                />
              </WrapperLabel>
            </Grid>
            <Grid container justify="center">
              <Grid item xs={6}>
                <Button type="submit">Buscar</Button>
              </Grid>
            </Grid>
          </UForm>
        </Content>
        <Grid container spacing={1}>
          {participation && participation.map((participation: IOption) => (
            <Card
              key={participation.id}
              quizs={participation}
            />
          ))}
        </Grid>
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default List;
