import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
/** hooks */
import * as Yup from 'yup';
import { Content } from 'components/Forms/WrapperLabel/styles';
import { useAuth } from 'hooks/auth';

import { useToast } from 'hooks/toast';
import Simple from 'components/Headers/Simple';
import getCurrentPosition from 'utils/getCurrentPosition';
import { InfoIcon, TrashIcon } from '../../../styles/icons';
import { useHttp } from '../../../hooks/http';
import {
  WrapperLabel, Select, Button, InputHidden, ButtonIcon, Input,
} from '../../../components/Forms';
import getValidationError from '../../../utils/getValidationsErros';

import {
  Container,
  GridButtons,
  WrapperBtnInfo,
  WrapperBtnCancel,
  WrapperBtnSubmit,
  Text,
  WrapperRemove,
} from './styles';

interface IFormData {
  coord?: {
    lat: number,
    lng: number
  }
}

interface FileProps {
  file: File;
}

const FormInProgress: React.FC<IFormData> = ({ coord }) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [municipio, setMunicipio] = useState([]);

  const [cardFile, setCardFile] = useState<any[]>([]);

  const [disabledKindMachineEquipament, setDisabledKindMachineEquipament] = useState(false);
  const [enableddStatusOthers, setEnabledStatusOthers] = useState(false);

  const { loading, httpGet, httpPost } = useHttp();
  const { user, refreshUser } = useAuth();
  const { addToast } = useToast();
  const prefix = `@${process.env.REACT_APP_NAME}`;

  const handleDelete = useCallback(async () => {
    try {
      const userStore = localStorage.getItem(`${prefix}:user`);
      if (userStore) {
        const userParsed = JSON.parse(userStore);
        delete (userParsed.action);
        localStorage.setItem(`${prefix}:user`, JSON.stringify(userParsed));
        refreshUser();
        history.push('/dashboard');
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [prefix, refreshUser, history]);

  useEffect(() => {
    async function loadDitricts(): Promise<any> {
      const { status, data } = await httpGet('https://servicodados.ibge.gov.br/api/v1/localidades/estados/33/municipios');
      if (status === 200) {
        const municipioParsed = data
          .map((municipio: any) => ({
            value: municipio.nome,
            label: municipio.nome,
          }));
        setMunicipio(municipioParsed);
      }
    }

    loadDitricts();
  }, [httpGet]);

  useEffect(() => {
    formRef.current?.setData({});
  }, []);

  const handleSubmit = useCallback(async (payload: any) => {
    const _ceperj = {
      ...payload,
      ...coord,
      city: payload.city?.value,
      machine_equipment: payload.machine_equipment?.value,
      kind_machine_equipament: payload.kind_machine_equipament
        ? payload.kind_machine_equipament.map((kindMachineLabels: any) => kindMachineLabels.label).join(',')
        : null,
      status_observation: payload.status_observation?.value,
      management_id: user?.allocation?.management_id,
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().ensure().required('O campo empregado da empresa responsável pela equipe da obra é obrigatório.'),
        people_working: Yup.number().typeError('Idade é obrigatório, somente números'),
        city: Yup.string().required('Município da obra'),
        machine_equipment: Yup.string().ensure().required('O campo há emprego de máquinas ou equipamentos no local da obra é obrigatório'),
        status_observation: Yup.string().ensure().required('Observações sobre o andamento da obra... é obrigatório'),
        kind_machine_equipament: Yup.string().nullable().when('machine_equipment', {
          is: (machine_equipment) => machine_equipment === 'Sim', then: Yup.string().required('Que tipos de máquinas ou equipamentos'),
        }),

      });
      await schema.validate(_ceperj, { abortEarly: false });

      const position = await getCurrentPosition();

      const { status, data } = await httpPost('constructioninprogress', {
        ..._ceperj,
        current_lat: position.lat,
        current_lng: position.lng,
      });

      if (status === 200) {
        if (cardFile.length !== 0) {
          const formData = new FormData();

          Object.values(cardFile).forEach((file) => {
            formData.append('file', file, data.id);
          });

          const response = await httpPost('/files', formData, 'multipart');
          if (response.status !== 204) {
            addToast({ title: 'Não foi possivel gravar imagens!', type: 'error' });
          }
        }

        addToast({ title: 'Salvo com sucesso!', type: 'success' });
        const userStore = localStorage.getItem(`${prefix}:user`);

        if (userStore) {
          const userParsed = JSON.parse(userStore);
          delete (userParsed.action);
          localStorage.setItem(`${prefix}:user`, JSON.stringify(userParsed));
          refreshUser();
        }

        history.push('/dashboard');
      } else {
        addToast({ title: 'Não foi possível gravar!', type: 'error' });
        history.push('/dashboard');
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [coord, user, addToast, history, httpPost, refreshUser, prefix, cardFile]);

  const handleKindMachineEquipament = useCallback((machine_equipment: any) => {
    setDisabledKindMachineEquipament(machine_equipment?.value === 'Não');
  }, []);

  const handleStatusOthers = useCallback((status_observation: any) => {
    setEnabledStatusOthers(status_observation?.value === 'Outros');
  }, []);

  const handleUploadFile = (e: any) => setCardFile(e.target.files);

  return (
    <>
      <Content>
        <Simple>
          <Text>{user?.action?.name}</Text>
          <WrapperRemove onClick={handleDelete}>
            <ButtonIcon
              title="Excluir"
            >
              <TrashIcon color="white" />
            </ButtonIcon>
          </WrapperRemove>
        </Simple>
      </Content>
      <Container>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <InputHidden name="id" defaultValue={user?.action?.id} />
            <WrapperLabel label="Empregado da empresa responsável pela equipe da obra" xs={12} sm={6}>
              <Input name="name" />
            </WrapperLabel>
            <WrapperLabel label="Quantidade de pessoas trabalhando no momento da visita" xs={12} sm={6} md={6}>
              <Input
                name="people_working"
                type="text"
                placeholder="Somente números"
                maxLength={4}
              />
            </WrapperLabel>
            <WrapperLabel label="Município da obra" xs={12} md={6} sm={6}>
              <Select
                name="city"
                options={municipio}
              />
            </WrapperLabel>
            <WrapperLabel label="Há emprego de máquinas ou equipamentos no local da obra" xs={12} sm={6} md={6}>
              <Select
                name="machine_equipment"
                onChange={handleKindMachineEquipament}
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Não', label: 'Não' },
                ]}
              />
            </WrapperLabel>
            <WrapperLabel label="Que tipos de máquinas ou equipamentos" xs={12} md={6} sm={6}>
              <Select
                name="kind_machine_equipament"
                isDisabled={disabledKindMachineEquipament}
                options={[
                  { value: 'Escavadeira', label: 'Escavadeira' },
                  { value: 'Retroescavadeira', label: 'Retroescavadeira' },
                  { value: 'Trator', label: 'Trator' },
                  { value: 'Rolo Compactador', label: 'Rolo Compactador' },
                  { value: 'Motoniveladora', label: 'Motoniveladora' },
                  { value: 'Betoneira', label: 'Betoneira' },
                  { value: 'Bate-estacas', label: 'Bate-estacas' },
                  { value: 'Caminhão', label: 'Caminhão' },
                  { value: 'Guindaste', label: 'Guindaste' },
                  { value: 'Containers', label: 'Containers' },
                  { value: 'Empilhadeiras', label: 'Empilhadeiras' },

                ]}
                isMulti
              />
            </WrapperLabel>

            {
              !disabledKindMachineEquipament && (

                <WrapperLabel label="Outros" xs={12} md={6} sm={6}>
                  <Input name="kind_machine_equipament_others" />
                </WrapperLabel>
              )
            }

            <WrapperLabel label="Observações sobre o andamento da obra" xs={12} md={6} sm={6}>
              <Select
                name="status_observation"
                onChange={handleStatusOthers}
                options={[
                  { value: 'Não iniciada', label: 'Não iniciada' },
                  { value: 'Canteiro de obras em montagem', label: 'Canteiro de obras em montagem' },
                  { value: 'Início da sondagem do terreno', label: 'Início da sondagem do terreno' },
                  { value: 'Em andamento a conferência, levantamento e marcação topográfica das estacas, blocos e pilares', label: 'Em andamento a conferência, levantamento e marcação topográfica das estacas, blocos e pilares' },
                  { value: 'Em andamento a terraplanagem ou aterramento do terreno', label: 'Em andamento a terraplanagem ou aterramento do terreno' },
                  { value: 'Em andamento a pavimentação (asfalto)', label: 'Em andamento a pavimentação (asfalto)' },
                  { value: 'Em andamento a execução de serviços de ferragens e estruturas (vigas, colunas, blocos, pilares etc)', label: 'Em andamento a execução de serviços de ferragens e estruturas (vigas, colunas, blocos, pilares etc)' },
                  { value: 'Em andamento instalações hidrossanitárias ou elétricas', label: 'Em andamento instalações hidrossanitárias ou elétricas' },
                  { value: 'Em andamento serviços de revestimentos, cerâmicas, pisos ou pinturas', label: 'Em andamento serviços de revestimentos, cerâmicas, pisos ou pinturas.' },
                  { value: 'Finalizada a sondagem do terreno', label: 'Finalizada a sondagem do terreno' },
                  { value: 'Finalizada a conferência, levantamento e marcação topográfica das estacas, blocos e pilares', label: 'Finalizada a conferência, levantamento e marcação topográfica das estacas, blocos e pilares.' },
                  { value: 'Finalizada a terraplanagem ou aterramento do terreno', label: 'Finalizada a terraplanagem ou aterramento do terreno' },
                  { value: 'Finalizada a pavimentação (asfalto)', label: 'Finalizada a pavimentação (asfalto)' },
                  { value: 'Finalizada a execução de serviços de ferragens e estruturas (vigas, colunas, blocos, pilares etc)', label: 'Finalizada a execução de serviços de ferragens e estruturas (vigas, colunas, blocos, pilares etc)' },
                  { value: 'Finalizadas as instalações hidrossanitárias ou elétricas', label: 'Finalizadas as instalações hidrossanitárias ou elétricas' },
                  { value: 'Finalizados os serviços de revestimentos, cerâmicas, pisos ou pinturas', label: 'Finalizados os serviços de revestimentos, cerâmicas, pisos ou pinturas' },
                  { value: 'Obra finalizada', label: 'Obra finalizada' },
                  { value: 'Outros', label: 'Outros' },
                ]}
              />
            </WrapperLabel>
            {
              enableddStatusOthers && (

                <WrapperLabel label="Outros" xs={12} sm={12}>
                  <Input name="status_observation_others" />
                </WrapperLabel>
              )
            }

            <WrapperLabel label="Imagens" xs={12} sm={12}>
              <input type="file" onChange={handleUploadFile} multiple />
            </WrapperLabel>

            <WrapperLabel label="Observações" xs={12} sm={12}>
              <Input name="note" />
            </WrapperLabel>

          </Grid>

          <GridButtons>
            <WrapperBtnInfo>
              <ButtonIcon
                title="Instruções"
              >
                <InfoIcon />
              </ButtonIcon>
            </WrapperBtnInfo>
            <WrapperBtnCancel>
              <Button
                type="button"
                onClick={() => { history.push('/dashboard'); }}
              >
                Cancelar
              </Button>
            </WrapperBtnCancel>
            <WrapperBtnSubmit>
              <Button
                type="submit"
                isLoading={loading}
              >
                Salvar
              </Button>
            </WrapperBtnSubmit>
          </GridButtons>
        </UForm>
      </Container>
    </>
  );
};

export default FormInProgress;
