import React from 'react';
import { useHistory } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Flex, { Grid } from '../../../../components/Flex';
import {
    Menu, MenuItem, DotButton, ItemIcon,
} from '../../../../components/CustomMenu';
import { Container, WrapperMenu } from './styles';
import { InfoIcon } from '../../../../styles/icons';
import { formatDate } from '../../../../utils';

interface ICard {
    rcol: any;
}

const Card: React.FC<ICard> = ({ rcol }) => {
    const history = useHistory();



    return (
        <Grid item xs={12}>
            <Container>
                <Grid container spacing={1}>
                    <WrapperMenu>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <>
                                    <DotButton {...bindTrigger(popupState)} />
                                    <Menu {...bindMenu(popupState)}>
                                        <MenuItem
                                            onClick={() => { history.push(`/rcols/detail/${rcol.id}`); }}
                                            style={{ width: 150 }}
                                        >

                                            <ItemIcon icon={<InfoIcon />} title="Mostrar" />

                                        </MenuItem>


                                    </Menu>
                                </>
                            )}
                        </PopupState>
                    </WrapperMenu>
                    <Flex title="Equipe" xs={12} md={6} sm={6}>
                        <b>{rcol?.team}</b>
                    </Flex>
                    <Flex title="Data da Operação" xs={12} sm={6} md={6}>
                        <b>{formatDate(String(rcol?.operation_date).split('T')[0])}</b>
                    </Flex>
                </Grid>
            </Container>
        </Grid>
    );
};

export default Card;
