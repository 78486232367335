import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { formatDate } from '../../../utils';

import states from '../../../utils/states';
import Layout from '../../../components/Layout';
import HeaderSavePlus from '../../../components/Headers/SavePlus';
import Tabs, { TabsItem } from '../../../components/Tabs';
import DetailComplements from './detail';
/** hooks */
import { useHttp } from '../../../hooks/http';

/** componentes */
import {
  WrapperLabel, Input, Select, InputMask, InputHidden, ButtonIcon,
} from '../../../components/Forms';
import { Container, WrapperBtnInfo } from './styles';
import { HandHoldingHeartIcon, InfoIcon } from '../../../styles/icons';
import { ISocial } from '../../../interfaces';

const Detail: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [social, setSocial] = useState({} as ISocial);
  const { id } = useParams<{id: string}>();

  const {
    httpGet,
  } = useHttp();

  useEffect(() => {
    setSocial({} as ISocial);
    async function loadUser(): Promise<void> {
      const { status, data } = await httpGet(`/socials/${id}`);
      if (status === 200) {
        setSocial(data);
      } else {
        history.push('/social');
      }
    }

    id && loadUser();
  }, [id]);// eslint-disable-line

  useEffect(() => {
      formRef.current?.setData({
        ...social,
        birthday: formatDate(social.birthday),
        gender: { value: social.gender, label: social.gender },
        gender_identity: { value: social.gender_identity, label: social.gender_identity },
        nationality: { value: social.nationality, label: social.nationality },
        naturalness: { value: social.naturalness, label: social.naturalness },
        ethnicity: { value: social.ethnicity, label: social.ethnicity },
        marital: { value: social.marital, label: social.marital },
        schooling: { value: social.schooling, label: social.schooling },
        is_participatory: social.is_participatory ? { value: true, label: 'Sim' } : { value: false, label: 'Não' },
      });
  }, [social]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSubmit = useCallback(() => {
    }, []);// eslint-disable-line

  const breadcrumbs = {
    icon: HandHoldingHeartIcon,
    links: [
      { path: '', title: 'Social' },
    ],
  };
  const handlePlus = useCallback(() => {
    history.push('/social');
  }, [history]);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <HeaderSavePlus
          handlePlus={handlePlus}
        >
          Consulta
        </HeaderSavePlus>
        <Tabs>
          <TabsItem title="Dados" show>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <InputHidden name="id" />
                <WrapperLabel label="Participativo?" xs={12} md={12}>
                  <Select
                    name="is_participatory"
                    options={[
                      { value: true, label: 'Sim' },
                      { value: false, label: 'Não' },
                    ]}
                    isDisabled
                  />
                </WrapperLabel>
                <WrapperLabel label="Nome" xs={12} sm={6}>
                  <Input name="name" disabled />
                </WrapperLabel>

                <WrapperLabel label="Nome Social" xs={12} sm={6}>
                  <Input name="social_name" disabled />
                </WrapperLabel>

                <WrapperLabel label="Mãe" xs={12} sm={6}>
                  <Input name="mather" disabled />
                </WrapperLabel>

                <WrapperLabel label="Pai" xs={12} sm={6}>
                  <Input name="father" disabled />
                </WrapperLabel>

                <WrapperLabel label="Nascimento" xs={12} sm={6} md={5}>
                  <InputMask name="birthday" type="tel" mask="99/99/9999" placeholder="DD/MM/YYYY" disabled />
                </WrapperLabel>

                <WrapperLabel label="Idade" xs={12} sm={6} md={1}>
                  <Input name="age" maxLength={2} disabled />
                </WrapperLabel>

                <WrapperLabel label="Gênero" xs={12} sm={4} md={6}>
                  <Select
                    isDisabled
                    name="gender"
                    options={[
                      { value: 'Masculino', label: 'Masculino' },
                      { value: 'Feminino', label: 'Feminino' },
                    ]}
                  />
                </WrapperLabel>

                <WrapperLabel label="Identidade de gênero" xs={12} sm={4} md={4}>
                  <Select
                    isDisabled
                    name="gender_identity"
                    options={[
                      { value: 'Cisgênero', label: 'Cisgênero' },
                      { value: 'Transgênero', label: 'Transgênero' },
                      { value: 'Homossexual', label: 'Homossexual' },
                    ]}
                  />
                </WrapperLabel>

                <WrapperLabel label="Nacionalidade" xs={12} sm={4}>
                  <Select
                    isDisabled
                    name="nationality"
                    options={[
                      { value: 'Brasileira', label: 'Brasileira' },
                      { value: 'Estrangeira', label: 'Estrangeira' },
                    ]}
                  />
                </WrapperLabel>

                <WrapperLabel label="Naturalidade" xs={12} md={4} sm={6}>
                  <Select
                    isDisabled
                    name="naturalness"
                    options={states}
                  />
                </WrapperLabel>

                <WrapperLabel label="Etnia" xs={12} sm={6}>
                  <Select
                    isDisabled
                    name="ethnicity"
                    options={[
                      { value: 'Branca', label: 'Branca' },
                      { value: 'Negra', label: 'Negra' },
                      { value: 'Parda', label: 'Parda' },
                      { value: 'Amarela', label: 'Amarela' },
                      { value: 'Indígena', label: 'Indígena' },
                      { value: 'Outros', label: 'Outros' },
                    ]}
                  />
                </WrapperLabel>

                <WrapperLabel label="Estado Civil" xs={12} sm={6} md={6}>
                  <Select
                    isDisabled
                    name="marital"
                    options={[
                      { value: 'Casado', label: 'Casado' },
                      { value: 'Solteiro', label: 'Solteiro' },
                      { value: 'Viúvo', label: 'Viúvo' },
                      { value: 'Separado', label: 'Separado' },
                      { value: 'Divorciado', label: 'Divorciado' },
                    ]}
                  />
                </WrapperLabel>

                <WrapperLabel label="Local Nascimento" xs={12} sm={6} md={6}>
                  <Input name="place_birth" disabled />
                </WrapperLabel>

                <WrapperLabel label="Telefone" xs={12} sm={6} md={6}>
                  <InputMask mask="(99) 99999-9999" name="phone" disabled />
                </WrapperLabel>

                <WrapperLabel label="Escolaridade" xs={12} sm={6}>
                  <Select
                    isDisabled
                    name="schooling"
                    options={[
                      { value: 'Analfabeto', label: 'Analfabeto' },
                      { value: 'fundamental_inc', label: 'Fundamental incompleto' },
                      { value: 'fundamental_comp', label: 'Fundamental completo' },
                      { value: 'medio_inc', label: 'Médio incompleto' },
                      { value: 'medio_comp', label: 'Médio completo' },
                      { value: 'superior_inc', label: 'Superior incompleto' },
                      { value: 'superior_comp', label: 'Superior completo' },
                      { value: 'nao_info', label: 'Não Informado' },
                    ]}
                  />
                </WrapperLabel>

                <WrapperLabel label="Profissão" xs={12} sm={6}>
                  <Input name="profession" disabled />
                </WrapperLabel>

                <WrapperLabel label="Endereço de referência" xs={12} sm={12}>
                  <Input name="reference_address" disabled />
                </WrapperLabel>
              </Grid>

              <Grid container justify="flex-start">
                <WrapperBtnInfo>
                  <ButtonIcon
                    title="Somente para consulta!"
                  >
                    <InfoIcon />
                  </ButtonIcon>
                </WrapperBtnInfo>
              </Grid>

            </UForm>
          </TabsItem>
          <TabsItem title="Complemento">
            <DetailComplements complement={social?.complements} />
          </TabsItem>
        </Tabs>
      </Container>
    </Layout>
  );
};

export default Detail;
