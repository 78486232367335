import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiLock } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import getValidationError from '../../utils/getValidationsErros';

import api, { isNetworkError } from '../../services/api';
import { useToast } from '../../hooks/toast';

import InputWrapped from '../../components/Forms/InputWrapped';
import Button from '../../components/Forms/Button';

import logoImg from '../../assets/auth-logo.svg';
import {
  Container, Content, Background, AnimationContainer,
} from './styles';

interface ParamProps{
  token: string;
}
const Reset: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { addToast } = useToast();

  const { token } = useParams<ParamProps>();

  interface ForGotFormData{
    password: string;
    password_confirmation: string;
  }

  const handleSubmit = useCallback(async (data: ForGotFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        password: Yup
          .string()
          .required('A Senha é obrigatória')
          .matches(
            /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Precisa: no mínimo 6 caracteres; conter letras maiúsculas, minúsculas, números e caracteres especiais.',
          ),
        password_confirmation: Yup
          .string()
          .required()
          .oneOf([Yup.ref('password'), ''], 'As senhas não conferem'),
      });

      await schema.validate(data, { abortEarly: false });

      if (!token) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'O link para criar uma nova senha não é válido.',
        });
        return;
      }

      setIsLoading(true);
      await api.post('auth/reset', {
        token,
        password: data.password,
        password_confirmation: data.password_confirmation,
      }).finally(() => setIsLoading(false));

      history.push('/signin');

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Sua senha foi criada com sucesso. Agora você já pode acessar o sistema com sua nova senha.',
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);

        return;
      }

      if (isNetworkError(err)) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Você está sem acesso à internet ou o servidor está off-line.',
        });
        return;
      }

      if (err.response && err.response.status === 429) {
        addToast({
          type: 'error',
          title: 'Opsss!',
          description: 'Você excedeu o limite de requisições para o servidor.',
        });
        return;
      }

      if (err.response && err.response.status === 400) {
        const { message } = Array.isArray(err.response.data)
          ? err.response.data[0]
          : err.response.data;
        addToast({
          type: 'error',
          title: message,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Error',
        description: 'Não foi possível criar uma nova senha',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, addToast, token]);

  return (
    <Container>
      <Background />
      <Content>

        <AnimationContainer>
          <img src={logoImg} alt="DSCi" />

          <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
            <h1>Recuperar acesso</h1>

            <InputWrapped
              name="password"
              mask=""
              icon={FiLock}
              type="password"
              placeholder="Digite uma nova senha"
            />

            <InputWrapped
              name="password_confirmation"
              mask=""
              icon={FiLock}
              type="password"
              placeholder="Confirme a nova senha"
            />

            <Button
              type="submit"
              color="secondary"
              isLoading={isLoading}
              size="large"
            >Entrar
            </Button>
            <Link to="/forgot">Esqueci minha senha</Link>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Reset;
