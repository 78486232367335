import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  width:100%;
`;

export const Contante = styled.div`
  padding: 24px 16px;
  width:100%;
  background: #fff;
  border-radius: 4px;
`;

export const WrapperSelect = styled.div`
  display: flex;
  flex-direction: column;

  label{
    margin-bottom: 2px;
    color: var(--color-secondary)
  }


`;

export const WrapperDate = styled.div`
  display: flex;
  flex-direction: column;

  label{
    margin-bottom: 2px;
    color: var(--color-secondary)
  }

  input{
    width: 100%;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    padding: 9px 16px;
    height: 38px;
  }
`;
