import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  :root {
    --color-primary: #333;
    --color-primary-h: #000;
    --color-secondary: #666;
    --color-secondary-h: #0c0c0c;

    --color-gold: #8D7842;
    --color-gold-h: #82651c;

    --color-red: #c53030;
    --color-red-h: #CC0001;
    --color-yellow: #FFBB34;
    --color-yellow-h: #FF8700;
    --color-green: #01C951;
    --color-green-h: #007E33;
    --color-blue: #4385F5;
    --color-blue-h: #0D47A1;

    --color-gray: #95A5A6;
    --color-gray-h: #7F8C8D;

    --color-black: #333;
    --color-white: #FFF;

    --color-border:#CCC;
    --color-border-focus: #999;

    --color-bg: #E5E5E5;
    --color-bg-red: rgba(197, 48, 48, 1);
    --color-bg-gray: #666;
    --color-bg-disable: #ebebeb;

    --color-button: #404040;
    --color-button-h: #333;
    --color-button-secondary: #404040;
    --color-button-secondary-h: #0c0c0c;

    --shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);

  }

  .react-select_control{
    border: 0;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body{
    background: var(--color-bg);
    color: var(--color-black);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button, pre{
    font: 16px 'Roboto', sans-serif;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 400;
  }

  button {
    cursor: pointer;
  }

`;
