import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { Content } from 'components/Forms/WrapperLabel/styles';
import ModalFactory from 'react-modal-promise';
import getValidationError from '../../utils/getValidationsErros';
/** hooks */
import { useHttp } from '../../hooks/http';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import Simple from '../../components/Headers/Simple';
/** componentes */
/** components */
import { ModalDialog } from '../../components/ModalDialog';
import Layout from '../../components/Layout';
import {
  WrapperLabel, Input, DatePicker, Button, InputHidden, Select, ButtonIcon,
} from '../../components/Forms';

import { MapIcon, TrashIcon } from '../../styles/icons';
import { Container, Content2, WrapperRemove } from './styles';

const PollForm: React.FC = () => {
  const { addToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const { user, iIs } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [managements, setManagements] = useState<any>();
  const {
    httpGet, httpPost, httpPut, httpDelete,
  } = useHttp();
  const [disabled, setDisabled] = useState(false);

  const breadcrumbs = {
    icon: MapIcon,
    links: [
      { title: 'Apurações', path: '/polls' },
      { title: 'Apuração', path: '' }],
  };

  useEffect(() => {
    async function loadManagements(): Promise<void> {
      if (!user) {
        return;
      }

      const { status, data } = await httpGet('managements/all');
      if (status === 200) {
        const stations = data
          .map((station: any) => ({
            value: station.id,
            label: `${station.initials} - ${station.name}`,
            is_core: station.is_core,
          }));
        setManagements(stations);
      }
    }

    loadManagements();

  }, [user]);// eslint-disable-line

  useEffect(() => {
    async function loadPoll(): Promise<void> {
      const { status, data } = await httpGet(`/polls/${id}`);

      if (status === 200) {
        formRef.current?.setData({
          ...data,
          date_poll: data.date_poll.split('T')[0],
          management_id: managements?.filter((m: any) => m.value === data.management_id),
        });
        setDisabled(true);
      } else {
        history.push('/polls');
      }
    }

    id && loadPoll();
  }, [id, managements]);// eslint-disable-line

  const handleSubmit = useCallback(async (payload: any) => {
    const { value } = payload.management_id;
    const _poll = {
      ...payload,
      management_id: value,
      date_poll: payload?.date_poll,
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        date_poll: Yup.string().required('Data é obrigatório'),
        available_vacancies: Yup.number().typeError('Vagas é obrigatorio, somente números'),
        vacancies_filled: Yup.number().typeError('Vagas preenchidas é obrigatorio, somente números'),
        absences: Yup.number().typeError('Faltas é obrigatorio, somente números'),
      });

      await schema.validate(_poll, { abortEarly: false });

      setIsLoading(true);

      if (_poll.id) {
        const { status } = await httpPut(`polls/${_poll.id}`, _poll);
        if (status === 200) {
          addToast({ title: 'Alterado com sucesso', type: 'success' });
          history.push('/polls');
        }
      } else {
        const { status, data } = await httpPost('/polls', _poll);
        if (status === 200) {
          addToast({ title: data.message, type: 'success' });
          history.push('/polls');
          return;
        }
        setIsLoading(false);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }

  }, []);// eslint-disable-line

  const handleDelete = useCallback(async (id) => {
    const response = await ModalDialog({
      title: 'Atenção!',
      content: 'Você tem certeza que deseja excluir esse lançamento?',
      buttons: 'cancel_confirm',
    });
    if (response) {
      const { status } = await httpDelete(`polls/${id}`);
      if (status === 200) {
        addToast({ title: 'Deletado com sucesso', type: 'success' });
        history.push('/polls');
      }
    }
  }, [httpDelete, history, addToast]);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content>
          <Simple>
            {!id && 'Nova Data'}
            {id && 'Editar'}
            {iIs('admin') && (
              <WrapperRemove onClick={() => { handleDelete(id); }}>
                <ButtonIcon
                  title="Excluir"
                >
                  <TrashIcon color="white" />
                </ButtonIcon>
              </WrapperRemove>
            )}
          </Simple>
        </Content>
        <Content2>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1} alignItems="flex-end">
              <InputHidden name="id" />
              <WrapperLabel label="Gerência" xs={12} md={6}>
                <Select name="management_id" options={managements} isDisabled={disabled} />
              </WrapperLabel>
              <WrapperLabel label="Data" xs={12} md={6}>
                <DatePicker
                  name="date_poll"
                  maxDate={new Date()}
                  disabled={disabled}
                />
              </WrapperLabel>
              <WrapperLabel label="Vagas Disponíveis" xs={12} md={6}>
                <Input name="available_vacancies" />
              </WrapperLabel>
              <WrapperLabel label="Vagas Preenchidas" xs={12} md={6}>
                <Input name="vacancies_filled" />
              </WrapperLabel>
              <WrapperLabel label="Faltas" xs={12} md={6}>
                <Input name="absences" />
              </WrapperLabel>
              <Grid container justify="flex-end">
                <Grid item xs={12} md={4}>
                  <Button type="submit" isLoading={isLoading}>Salvar</Button>
                </Grid>
              </Grid>
            </Grid>
          </UForm>
          <ModalFactory />
        </Content2>
      </Container>
    </Layout>
  );
};

export default PollForm;
