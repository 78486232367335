import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useHttp } from '../../../hooks/http';
import Layout from '../../../components/Layout';
import { SupportIcon } from '../../../styles/icons';
import { Container } from './styles';

const Observe: React.FC = () => {
 const [page, setPage] = useState(1)
 const { httpGet } = useHttp();

 const fetchSupports = useCallback(async (page: any) => {
  const response = await httpGet(`https://172.16.1.7/api/analytics/202307?page=${page}`);
  return response.data;
}, [httpGet]);

 const { 
  data, 
  isLoading, 
  isError,
  isFetching 
 } = useQuery(['observe', page], () => fetchSupports(page), {
  keepPreviousData : true 
});

const breadcrumbs = {
  icon: SupportIcon,
  links: [{ path: '', title: 'Suporte observer' }],
};

return (
 <Layout breadcrumbs={breadcrumbs}>
   <Container>
     {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <div>Error: {isError}</div>
      ) : (
        <div>
          {data.data.map((project: any) => (
            <p key={project.numero_de_serie}>
             {project.numero_de_serie} - 
             {project.to_date} - 
             {project.local} - 
             {project.quantidade}
            </p>
          ))}
        </div>
      )}

   
      <span>Current Page: {page}</span>
      <button
        onClick={() => setPage(old => Math.max(old - 1, 0))}
        disabled={page === 1}
      >
        Previous Page
      </button>{' '}
      <button
        onClick={() => {
            setPage(old => old + 1)
        }}
        // Disable the Next Page button until we know a next page is available
      >
        Next Page
      </button>
      {isFetching ? <span> Loading...</span> : null}{' '}
   </Container>
 </Layout>
);
};

export default Observe;