import React from 'react';
import { Container } from './styles';
import { EqualizerIcon } from '../../../styles/icons';

const FilterDrawer: React.FC = () => (
  <Container>
    <label className="pure-toggle-label-custom" htmlFor="pure-toggle-right" data-toggle-label="right">
      <EqualizerIcon />
    </label>
  </Container>
);

export default FilterDrawer;
