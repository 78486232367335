import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import AsyncSelect from 'react-select/async';
import { format, isAfter } from 'date-fns';
import getValidationError from '../../../utils/getValidationsErros';
import customStyle from './customStyle';

/** hooks */
import { useHttp } from '../../../hooks/http';
import { useAuth } from '../../../hooks/auth';

/** Componentes */
import Layout from '../../../components/Layout';
import Drawer from '../../../components/Drawer';
import HeaderFilterDrawer from '../../../components/Headers/FilterDrawer';
import Pagination from '../../../components/Pagination';
import Card from './Card';
import {
  WrapperLabel, Input, DatePicker, Select, Button, ButtonIcon,
} from '../../../components/Forms';

import {
  ArrowBarRightIcon,
  ListIcon, TrashIcon,
} from '../../../styles/icons';
import { Container, DrawerContent } from './styles';

/** interfaces */
import { IOrder, IPaginate } from '../../../interfaces';

interface IOptions {
  label: string;
  value: string;
}

interface IFilter {
  id: string;
  startDate: Date;
  endDate: Date;
  type: 'person' | 'vehicle';
  results: number[];
  management_id: number;
  user_id: string;
  operator_id: string;
  plate: string;
  chassi: string;
  name: string;
  document: string;
  document_secondary: string;
  note: string;
}

const breadcrumbs = {
  icon: ListIcon,
  links: [
    { title: 'Consultas reoldPaginatespondidas', path: '' },
  ],
};
const Processed: React.FC = () => {
  const { httpGet, loading } = useHttp();
  const formRef = useRef<FormHandles>(null);
  const { iCan } = useAuth();
  const RefLabelToggle = useRef<HTMLLabelElement>(null);
  const [orders, setOrders] = useState([]);
  const [type, setType] = useState('person');
  const [results, setResults] = useState([]);
  const [managements, setManagements] = useState([]);
  const [resultsFiltered, setResultsFiltered] = useState<any>([]);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [paginate, setPaginate] = React.useState<IPaginate>({ page: 1, lastPage: 1, perPage: 10 });
  const [user, setUser] = useState<IOptions | null>(null);
  const [operator, setOperator] = useState<IOptions | null>(null);

  const getOrders = useCallback(async (filter: IFilter, paginate: IPaginate) => {
    const { status, data } = await httpGet('orders/answered', {
      params: { ...filter, ...paginate },
    });

    if (status === 200) {
      setOrders(data.data);
      setPaginate(data.pagination);
    }
  }, [httpGet]);

  useEffect(() => {
    (async () => {
      const { status, data } = await httpGet('/managements/all', undefined, false);
      if (status === 200) {
        const managementParsed = data
          .map((management: any) => ({
            value: management.id,
            label: management.initials,
          }));
        setManagements(managementParsed);
      }
    })();
    (async ():Promise<void> => {
      const { status, data } = await httpGet('results', undefined, false);
      if (status === 200) {
        setResults(data);
        const resultsFilteredByType = data
          .filter((result: any) => (result.type === 'person' || result.type === null));

        const resultsParsed = resultsFilteredByType.map((item: any) => ({
          value: item.id,
          label: item.name,
        }));

        setResultsFiltered(resultsParsed);
      }
    })();

    getOrders(
      { id: '' } as IFilter,
      { page: 1, lastPage: 1, perPage: 10 },
    );
  }, [getOrders, httpGet]);

  const handleType = useCallback((payload: any) => {
    if (!payload) {
      setType('');
      return;
    }
    const resultsFilteredByType = results
      .filter((result: any) => (result.type === payload.value || result.type === null));

    const resultsParsed = resultsFilteredByType.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));

    setType(payload.value);
    setResultsFiltered(resultsParsed);
  }, [results]);

  const handleSearch = useCallback((value: string) => {
    setPaginate((oldPaginate: any) => ({ ...oldPaginate, page: 1 }));
    setFilter((oldState: any) => ({ ...oldState, id: value }));

    getOrders(
      { id: value } as IFilter,
      { page: 1, lastPage: 1, perPage: 10 },
    );
  }, [getOrders]);

  const handlePaginate = useCallback((payload: IPaginate) => {
    setPaginate(payload);
    getOrders(filter, payload);
  }, [filter, getOrders]);

  const validateDate = useCallback((startDate: any, endDate: any) => {
    if (!startDate && !endDate) {
      return true;
    }

    if (!startDate || !endDate) {
      return false;
    }

    if (isAfter(new Date(startDate), new Date(endDate))) {
      return false;
    }

    return true;
  }, []);

  const handleSubmit = useCallback(async (payload: any) => {
    try {
      const filter = {
        ...payload,
        id: null,
        page: 1,
        perPage: 10,
        startDate: payload.startDate ? format(payload.startDate, 'yyyy-MM-dd') : null,
        endDate: payload.endDate ? format(payload.endDate, 'yyyy-MM-dd') : null,
        type: payload.type ? payload.type.value : null,
        management_id: payload.management_id ? payload.management_id.value : null,
        user_id: user ? user.value : null,
        operator_id: operator ? operator.value : null,
        results: payload.results ? payload.results
          .map((results: any) => results.value) : null,
      };

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        startDate: Yup.string().ensure()
          .test(
            'is-valid',
            'a Data Início não pode ser maior que a data de Término.',
            () => validateDate(filter.startDate, filter.endDate),
          ),
        endDate: Yup.string().ensure()
          .test(
            'is-valid',
            'a Data Término não pode ser menor que a data de Início.',
            () => validateDate(filter.startDate, filter.endDate),
          ),

      });

      await schema.validate(filter, { abortEarly: false });
      setFilter(filter);

      const { status, data } = await httpGet('orders/answered', { params: filter });

      if (status === 200) {
        setOrders(data.data);
        setPaginate(data.pagination);

        if (data.data.length > 0) {
          RefLabelToggle?.current?.click();
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [httpGet, operator, user, validateDate]);

  const loadOptions = async (inputValue: any, callback: any):Promise<any> => {
    if (inputValue.length < 3) return;
    const users: any = [];
    const { status, data } = await httpGet(`/users/filter/${inputValue}`, undefined, false);

    if (status === 200) {
      data.forEach((item:any) => {
        users.push({
          value: item.id,
          label: `${item.document} - ${item.name}`,
        });
      });
    }

    callback(users);
  };

  const handleChangeUser = useCallback((event: any) => {
    setUser(event);
  }, []);

  const handleChangeOperator = useCallback((event: any) => {
    setOperator(event);
  }, []);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderFilterDrawer
          handleSearch={handleSearch}
          placeholder="#protocolo"
        />

        <Drawer>
          <DrawerContent>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>

                <WrapperLabel label="Data Início" xs={12} md={6}>
                  <DatePicker
                    name="startDate"
                    isClearable
                    maxDate={filter?.endDate || new Date()}
                  />
                </WrapperLabel>
                <WrapperLabel label="Data Término" xs={12} md={6}>
                  <DatePicker
                    name="endDate"
                    isClearable
                    maxDate={filter?.endDate || new Date()}
                  />
                </WrapperLabel>

                <WrapperLabel label="Gerência" xs={12} md={6}>
                  <Select
                    name="management_id"
                    isClearable
                    options={managements}
                  />
                </WrapperLabel>

                <WrapperLabel label="Tipo" xs={12} md={6}>
                  <Select
                    name="type"
                    onChange={handleType}
                    defaultValue={[{ value: 'person', label: 'Pessoa' }]}
                    options={[
                      { value: 'person', label: 'Pessoa' },
                      { value: 'vehicle', label: 'Veículo' },
                    ]}
                  />
                </WrapperLabel>

                <WrapperLabel label="Resultados" xs={12}>
                  <Select name="results" options={resultsFiltered} isMulti />
                </WrapperLabel>

                <WrapperLabel label="Solicitado por:" xs={12}>
                  <div style={{ width: '100%' }}>
                    <AsyncSelect
                      placeholder="CPF ou Nome"
                      noOptionsMessage={() => 'Sem registos'}
                      cacheOptions
                      isClearable
                      name="user_id"
                      loadOptions={loadOptions}
                      onChange={handleChangeUser}
                      value={user}
                      styles={customStyle}
                    />
                  </div>
                </WrapperLabel>

                {iCan('r-all-orders') && (
                  <WrapperLabel label="Respondido por:" xs={12}>
                    <div style={{ width: '100%' }}>
                      <AsyncSelect
                        placeholder="CPF ou Nome"
                        noOptionsMessage={() => 'Sem registos'}
                        cacheOptions
                        isClearable
                        name="user_id"
                        loadOptions={loadOptions}
                        onChange={handleChangeOperator}
                        value={operator}
                        styles={customStyle}
                      />
                    </div>
                  </WrapperLabel>
                )}

                { type === 'person' && (
                  <>
                    <WrapperLabel label="Nome" xs={12}>
                      <Input name="name" />
                    </WrapperLabel>
                    <WrapperLabel label="CPF" xs={12} md={6}>
                      <Input name="document" />
                    </WrapperLabel>
                    <WrapperLabel label="RG" xs={12} md={6}>
                      <Input name="document_secondary" />
                    </WrapperLabel>
                  </>
                )}
                { type === 'vehicle' && (
                  <>
                    <WrapperLabel label="Placa" xs={12} md={6}>
                      <Input name="plate" />
                    </WrapperLabel>
                    <WrapperLabel label="Chassi" xs={12} md={6}>
                      <Input name="chassi" />
                    </WrapperLabel>
                  </>
                )}
                <WrapperLabel label="Observação" xs={12}>
                  <Input name="note" />
                </WrapperLabel>
              </Grid>
              <Grid container spacing={1} justify="space-between" alignItems="baseline">

                <Grid item xs={10} md={4}>
                  <span style={{ display: 'flex', gap: 8, marginTop: 8 }}>
                    <ButtonIcon title="Fechar">
                      <label ref={RefLabelToggle} htmlFor="pure-toggle-right" data-toggle-label="right">
                        <ArrowBarRightIcon />
                      </label>
                    </ButtonIcon>
                    <ButtonIcon
                      title="Limpar"
                      onClick={() => {
                        setUser(null);
                        setOperator(null);
                      formRef.current?.reset();
                      }}
                    >
                      <TrashIcon />
                    </ButtonIcon>
                  </span>

                </Grid>

                <Grid item xs={12} md={4}>
                  <Button type="submit" isLoading={loading}> Filtrar </Button>
                </Grid>
              </Grid>
            </UForm>
          </DrawerContent>
        </Drawer>

        <Grid container spacing={1}>
          {orders && orders.map((order: IOrder) => (
            <Card
              key={order.id}
              order={order}
            />
          ))}
        </Grid>
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default Processed;
