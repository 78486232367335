import styled from 'styled-components';

export const Container = styled.span`
  border-radius: 4px;
  padding: 8px;
  width: 100%;

input{
  display: none;
  width: 60px;
}

  display: flex;
  align-items: center;
  justify-content: center;
  & + div{
      margin-top: 12px;
    }
`;
