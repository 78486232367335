import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 8px 16px;

  + div:nth-child(2n) {
    background-color: #F2F2F2;
  }

  + div:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  + div:nth-last-child(-n + 1) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
