import Modal from 'components/ModalSimple';
import React, { useRef } from 'react';
import CountUp from 'react-countup';

import { Container, Content } from './styles';

interface IWalletBox {
    title: string;
    amount: number;
    color: string;
    dataction: any;
    datarec: any;
    nota: number;
    age: any;
}

const WalletBoxAction: React.FC<IWalletBox> = ({
    title,
    amount,
    color,
    dataction,
    datarec,
    nota,
    age,
}) => {
    const labelRef = useRef<HTMLLabelElement>(null);

    const futebol = dataction['AÇÃO - FUTEBOL'];
    const esporte = dataction['AÇÃO - ESPORTES'];
    const bar = dataction['AÇÃO - BARES e COMÉRCIO'];
    const outras = dataction['AÇÃO - OUTRAS'];
    const empresa = datarec['PALESTRA EMPRESA'];
    const escola = datarec['PALESTRA ESCOLA'];
    const universidade = datarec['PALESTRA UNIVERSIDADE'];

    const misto = age['MISTO (no caso de ação)'];

    return (

        <Container color={color}>
            <Content
                title="Instruções"
                onClick={() => labelRef.current?.click()}
            >
                <span>{title}</span>
                <h1>
                    <CountUp
                        end={amount}
                    />
                </h1>
                <label ref={labelRef} htmlFor="modal-instruction" />
                <Modal id="modal-instruction" width={420} height="auto">
                    <span>
                        <b>AÇÃO - FUTEBOL: {futebol}</b>
                        <b>AÇÃO - ESPORTES: {esporte}</b>
                        <b>AÇÃO - BARES e COMÉRCIO: {bar}</b>
                        <b>AÇÃO - OUTRAS: {outras}</b>
                        <b>PALESTRA ADULTO: {empresa}</b>
                        <b>PALESTRA INFANTIL:  {escola}</b>
                        <b>PALESTRA ADOLESCENTE: {universidade}</b>
                        <b>ESCOLA NOTA 10: {nota}</b>
                        <b>MISTO (no caso de ação): {misto}</b>
                    </span>
                </Modal>
            </Content>
        </Container >

    );
}

export default WalletBoxAction;