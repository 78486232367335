export default function validateCellPhone(value: string | null | undefined, required?: boolean): boolean {
  if (!required && !value) {
    return true;
  }

  if (!value) {
    return false;
  }

  const number = value.replace(/\D/g, '');

  if (number.length < 11) {
    return false;
  }

  return true;
}
