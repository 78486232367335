import React, {
  useRef, useEffect, useCallback,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';

import Grid from '@material-ui/core/Grid';
import getValidationError from '../../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../../hooks/http';

/** componentes */
import {
  WrapperLabel, Input, Button,
} from '../../../../components/Forms';
import { Container } from './styles';

import { IRole } from '../../../../interfaces';

interface IFormRole{
  permission: IRole;
  children ?: React.ReactNode;
  handleClose(): void;
  forceLoad():void;
}

const Acl: React.FC<IFormRole> = ({
  permission, forceLoad, handleClose,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { httpPost, httpPut } = useHttp();

  useEffect(() => {
    formRef.current?.setData(permission);
  }, [permission]);

  const handleSubmit = useCallback(async (data: IRole) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        slug: Yup.string().required('Slug é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });
      let response;
      if (permission.id) {
        response = await httpPut(`permissions/${permission.id}`, data);
      } else {
        response = await httpPost('permissions', data);
      }
      if (response.status === 200) {
        forceLoad();
        handleClose();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [forceLoad, handleClose, httpPost, httpPut, permission.id]);

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Nome" xs={12}>
            <Input name="name" />
          </WrapperLabel>

          <WrapperLabel label="Slug" xs={12}>
            <Input name="slug" />
          </WrapperLabel>
        </Grid>

        <Grid container justify="flex-end">
          <Grid item xs={12} md={6}>
            <Button type="submit">Salvar</Button>
          </Grid>
        </Grid>
      </UForm>
    </Container>
  );
};

export default Acl;
