import styled from 'styled-components';

export const Container = styled.div`

background: #fff;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 20px;;
`;

export const Text = styled.h5`
  display: flex;
`;

export const WrapperRemove = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 4px;

  cursor: pointer;
  svg{
    color: var(--color-secondary);
  }

  &:hover{
    svg{
      color: var(--color-secondary-h);
    }
  }
`;

export const GridButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 40px 1fr 1fr 1fr;
  grid-template-rows: 56px;
  grid-template-areas:
    'btn-inf space btn-cancel btn-submit';


  @media (max-width: 767.98px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 56px 56px;
    grid-template-areas:
      'btn-cancel btn-submit'
      'btn-inf space';
  }
`;

export const WrapperButton = styled.span`
  display: flex;
  width: 100%;
  > div{
    width: calc(100% - 48px);
    input{
      width: calc(100% - 46px);
    }
  }

  button{
    width: 40px;
  }
`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;

export const WrapperBtnCancel = styled.div`
  grid-area: btn-cancel;
`;

export const WrapperBtnSubmit = styled.div`
  grid-area: btn-submit;
`;

export const WrapperAddress = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 4px;

  h3{
    color: var(--color-primary);
    margin-bottom: 8px;
  }

  @media (max-width: 767.98px) {
    span {
      display: none;
    };
  }
`;
