/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { format } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useParams } from 'react-router';
import {
  Container, Title, Content, Header, Body,
} from './styles';

type LessonProps = {
    title: string;
    slug: string;
    availableAt: Date;
    type: 'live' | 'class';
}

const Lesson: React.FC<LessonProps> = (props) => {
  const { slug } = useParams<{ slug: string }>();

  const utcDate = zonedTimeToUtc(props.availableAt, 'UTC');
  const znDate = utcToZonedTime(utcDate, 'America/Sao_Paulo');
  const availableDateFormatted = format(znDate, "EEEE' • 'd' de 'MMMM' • 'k'h'mm");

  const isActiveLesson = slug === props.slug;

  return (
    <Container to={`/classrooms/${props.slug}`}>
      <Title>
        {availableDateFormatted}
      </Title>
      <Content>
        <Header>
          <>
            <span>
              { isActiveLesson ? 'Em execução' : 'Iniciar'}
            </span>
            <span>
              AULA
            </span>
          </>
        </Header>
        <Body>
          {props.title}
        </Body>
      </Content>
    </Container>
  );
};

export default Lesson;
