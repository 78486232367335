/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { CarIcon, PersonIcon } from '../../../../styles/icons';
import { Container, WrapperHeader } from './styles';
import { formatDateTime, formatCpf, formatPlate } from '../../../../utils';

/** hooks */
import { useToast } from '../../../../hooks/toast';

/** components */
import Flex, { Grid } from '../../../../components/Flex';

/** interface */
import { IOrder } from '../../../../interfaces';

interface ICard {
  order: IOrder;
}
const Card: React.FC<ICard> = ({ order }) => {
  const history = useHistory();
  const { addToast } = useToast();
  const [ref] = order.id.split('-');

  const handleGoToDetail = useCallback(
    (order: IOrder) => {
      if (order.status !== 'Respondida') {
        addToast({ title: 'Aguarde a consulta ser respondida' });
        return;
      }
      history.push(`/order/detail/${order.id}`);
    },
    [addToast, history],
  );

  return (
    <Grid item xs={12} sm={6} md={6}>
      <Container
        onClick={() => handleGoToDetail(order)}
        isConduction={!!order.result?.conduction}
        isResult={order.result_id}
        isStatus={order.status}
      >
        <WrapperHeader>
          <span>
            {order.type === 'vehicle' ? <CarIcon /> : <PersonIcon />}#
            {ref.toUpperCase()}
          </span>

          <h2> {!order.result ? order.status : order.result?.name}</h2>
        </WrapperHeader>
        <Grid container spacing={1}>
          {order.type === 'vehicle' && (
            <Flex title={order.plate ? 'Placa' : 'Chassi'} xs={6} md={6}>
              {order.plate ? formatPlate(order.plate) : order.chassi}
            </Flex>
          )}

          {order.type === 'person' && (
            <Flex title={order.document ? 'CPF' : 'Nome'} xs={6} md={6}>
              {order.document ? formatCpf(order.document) : order.name}
            </Flex>
          )}

          <Flex title="Solicitado em" xs={6} md={6}>
            {formatDateTime(order.created_at)}
          </Flex>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Card;
