const typeNarcotics = [
  { value: 'Anfetaminas', label: 'Anfetaminas' },
  { value: 'Barbitúricos', label: 'Barbitúricos' },
  { value: 'Cocaína', label: 'Cocaína' },
  { value: 'Cogumelos Alucinógenos', label: 'Cogumelos Alucinógenos' },
  { value: 'Cola de Sapateiro', label: 'Cola de Sapateiro' },
  { value: 'Crack', label: 'Crack' },
  { value: 'DMT Dimetiltriptamina', label: 'DMT Dimetiltriptamina' },
  { value: 'Ecstasy', label: 'Ecstasy' },
  { value: 'Haxixe', label: 'Haxixe' },
  { value: 'Heroína', label: 'Heroína' },
  { value: 'Lança Perfume', label: 'Lança Perfume' },
  { value: 'LSD', label: 'LSD' },
  { value: 'Maconha', label: 'Maconha' },
  { value: 'Merla', label: 'Merla' },
  { value: 'Metanfetamina', label: 'Metanfetamina' },
  { value: 'Ópio', label: 'Ópio' },
  { value: 'Oxi', label: 'Oxi' },
  { value: 'Psilocibina', label: 'Psilocibina' },
  { value: 'Skunk', label: 'Skunk' },
  { value: 'Outros', label: 'Outros' },
];

export default typeNarcotics;
