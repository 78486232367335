import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import {
  formatDateTime, formatCpf,
} from '../../../../utils';

/** hooks */
import { useHttp } from '../../../../hooks/http';
import { useToast } from '../../../../hooks/toast';

/** components */
import Layout from '../../../../components/Layout';
import Flex, { Grid } from '../../../../components/Flex';
import {
  ListIcon, HandsHelpingIcon,
} from '../../../../styles/icons';
import {
  Container, WrapperHeader, Title,
} from './styles';

/** interface */
import { IProximity } from '../../../../interfaces';

const breadcrumbs = {
  icon: ListIcon,
  links: [
    { path: '', title: 'Detalhe da Proximidade' },
  ],
};

const Detail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{id: string}>();
  const { httpGet, loading } = useHttp();
  const { addToast } = useToast();
  const [proximity, setProximity] = useState<IProximity>({}as IProximity);

  useEffect(() => {
    async function loadAnswer():Promise<void> {
      const { status, data } = await httpGet(`/proximities/${id}`);
      if (status === 200) {
        const [ref] = data.id.split('-');
        setProximity({
          ...data,
          ref: ref.toUpperCase(),
        });
      } else {
        addToast({ title: 'Erro ao tentar acessar a consulta.', type: 'error' });
        history.push('/orders/proximity/list');
      }
    }
    loadAnswer();

  }, [addToast, history, id]);  // eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <WrapperHeader>
          <span>
            <HandsHelpingIcon />
            { proximity.assistances?.code} - {proximity.assistances?.description }
          </span>
        </WrapperHeader>

        <Title>Dados da Proximidade:</Title>

        <Grid container spacing={1} justify="space-between">
          <Flex title="Nome" xs={12} sm={4} md={4}>
            <b> {proximity?.name} </b>
          </Flex>
          <Flex title="CPF" xs={12} sm={4} md={4}>
            <b> {proximity.document ? formatCpf(proximity.document) : 'não informado'} </b>
          </Flex>
        </Grid>
        {proximity?.store && (
          <Grid item xs={12} sm={8} md={6}>
            <Flex title="Establecimento" xs={12} sm={8} md={6}>
              <b> {proximity?.store} </b>
            </Flex>
          </Grid>
        )}
        <Grid container spacing={1} justify="space-between">
          <Flex title="Telefone de Contato" xs={6} sm={4} md={4}>
            <b> {proximity.phone || 'não informado'} </b>
          </Flex>
          <Flex title="E-mail" xs={6} sm={4} md={4}>
            <b> {proximity.email || 'não informado'} </b>
          </Flex>
          <Flex title="CEP" xs={12} sm={4} md={4}>
            <b> {proximity.cep || 'não informado'} </b>
          </Flex>
          <Flex title="Logradouro" xs={6} sm={4} md={4}>
            <b> {proximity.street || 'não informado'} </b>
          </Flex>
          <Flex title="Nº" xs={6} sm={4} md={4}>
            <b> {proximity.number || 'não informado'} </b>
          </Flex>
          <Flex title="Bairro" xs={12} sm={4} md={4}>
            <b> {proximity.distric || 'não informado'} </b>
          </Flex>
          <Flex title="Complemento" xs={12} sm={4} md={4}>
            <b> {proximity.complement || 'não informado'} </b>
          </Flex>

          <Grid container spacing={1} justify="space-between">
            <Grid item xs={12} sm="auto">
              <Flex title="Relato da abordagem">
                <b>{proximity.description}</b>
              </Flex>
            </Grid>
          </Grid>

          <Grid container spacing={1} justify="space-between">
            <Flex title="Data" xs={6} sm={4} md={4}>
              <b>{formatDateTime(proximity.created_at)}</b>
            </Flex>
            <Flex title="Gerência" xs={6} sm={4} md={4}>
              <b>{proximity.managements?.name}</b>
            </Flex>
            <Flex title="Setor" xs={6} sm={4} md={4}>
              <b>{proximity.sectors?.name}</b>
            </Flex>
          </Grid>

        </Grid>
      </Container>
    </Layout>
  );
};

export default Detail;
