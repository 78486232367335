import React, { useState, FormEvent } from 'react';
import {
  Container, WrapperInput,
} from './styles';
import { SearchIcon, EqualizerIcon } from '../../../styles/icons';

interface IFilterDrawer {
  handleSearch(filter: string): void;
  border?: boolean;
  placeholder?: string;
}

const FilterDrawer: React.FC<IFilterDrawer> = ({
  children, border = false, placeholder = '', handleSearch,
}) => {
  const [filter, setFilter] = useState('');

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    handleSearch(filter);
  }

  return (
    <>
      <Container border={border}>
        <form onSubmit={handleSubmit}>
          <WrapperInput>
            <input
              type="search"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder={placeholder}
            />
            <button type="submit">
              <SearchIcon />
            </button>
          </WrapperInput>
        </form>

        {children}
        <label className="pure-toggle-label-custom" htmlFor="pure-toggle-right" data-toggle-label="right">
          <EqualizerIcon />
        </label>

      </Container>

    </>
  );
};

export default FilterDrawer;
