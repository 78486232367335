import styled from 'styled-components';

interface IContaner{
  width: number;
}

export const Container = styled.div<IContaner>`
  background: var(--color-white);
  margin: auto auto;
  border: 1px solid var(--color-border);
  border-radius: 4px;

  max-height: calc(100vh - 32px);
  overflow-x: hidden;
  overflow-y: auto;

  padding: 16px;

  width: ${(props) => props.width}px;

  position: relative;

  > button{
    border: none;
    background: transparent;
    position: absolute;
    z-index:1601;

    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  > svg {
    color: var(--color-secondary);
    &:hover{
      color: var(--color-secondary-h);
    }
  }

  @media (max-width: 540px) {
    width: calc(100vw - 32px);
  }
`;

export const HeaderModal = styled.header`
    width: 100%;
    padding: 8px 0 16px;
    border-bottom: 1px solid var(--color-border);
    margin-bottom:16px;
    font-size: 18px;

    @media (max-width: 540px) {
      margin-right:16px;
    }
`;
