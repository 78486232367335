export const documentation = [
  {
    value: 1,
    label: 'RG',
  }, {
    value: 2,
    label: 'Cert.Nasc',
  }, {
    value: 3,
    label: 'RED',
  }, {
    value: 4,
    label: 'CPTS',
  }, {
    value: 5,
    label: 'CNH',
  }, {
    value: 6,
    label: 'Sem Documentos',
  }, {
    value: 7,
    label: 'Nunca foi registrado',
  }, {
    value: 8,
    label: 'Título de eleitor',
  }, {
    value: 9,
    label: 'Certidão de casamento',
  },
];

export const staystreet = [
  {
    value: 1,
    label: 'Permanece na rua o tempo todo',
  }, {
    value: 2,
    label: 'Permanece na rua apenas durante o dia ou noite',
  }, {
    value: 3,
    label: 'Frequenta cenas de uso',
  }, {
    value: 4,
    label: 'Permanece na rua durante a semana e retorna para casa nos fins de semana',
  }, {
    value: 5,
    label: 'Não soube ou não quis informar',
  },
];

export const street = [
  {
    value: 1,
    label: 'Menos de 1 semana',
  }, {
    value: 2,
    label: 'De 1 a 3 meses',
  }, {
    value: 3,
    label: 'De 3 a 6 meses',
  }, {
    value: 4,
    label: 'De 6 meses a 1 ano',
  }, {
    value: 5,
    label: 'De 1 a 2 anos',
  }, {
    value: 6,
    label: 'De 3 a 5 anos',
  }, {
    value: 7,
    label: 'Mais de 5 anos',
  }, {
    value: 8,
    label: 'Não soube ou não quis informar',
  },
];

export const streetmotive = [
  {
    value: 1,
    label: 'Conflito familiar',
  }, {
    value: 2,
    label: 'Abandono Familiar',
  }, {
    value: 3,
    label: 'Desemprego',
  }, {
    value: 4,
    label: 'Preferência/vontade própria',
  }, {
    value: 5,
    label: 'Violência ou conflito na comunidade',
  }, {
    value: 6,
    label: 'Transtorno psiquiátrico',
  }, {
    value: 7,
    label: 'Trabalho infantil',
  }, {
    value: 8,
    label: 'Uso de álcool e outras drogas',
  }, {
    value: 9,
    label: 'Falecimento dos pais, pessoa querida ou provedor',
  }, {
    value: 10,
    label: 'Não soube ou não quis informar',
  },
];

export const forwarding = [
  {
    value: 1,
    label: 'Comunidade terapêutica',
  }, {
    value: 2,
    label: 'Conselho tutelar',
  }, {
    value: 3,
    label: 'CRAF',
  }, {
    value: 4,
    label: 'Abrigo',
  }, {
    value: 5,
    label: 'Fundação Leão XIII',
  }, {
    value: 6,
    label: 'Defensoria pública',
  }, {
    value: 7,
    label: 'Delegacia de polícia',
  }, {
    value: 8,
    label: 'Saúde (Hospital, Upa ou similares)',
  },
  {
    value: 9,
    label: 'De volta a terra natal',
  },
];
