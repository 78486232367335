import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Content = styled.div`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  p{
    margin: 4px 0;
  }

  footer{
    color: var(--color-secondary);
    margin: 8px 0;
    text-align: justify;
  }
`;

export const Title = styled.div`
  border-top: 1px solid var(--color-border);
  margin: 0 -16px;
  margin-top: 8px;
  padding: 8px;
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  h2{
    font-size: 1em;
    font-weight: 700;
    text-align: right;
  }
  span{
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  svg{
    font-size: 24px;
    margin-right: 8px;
  }
`;

export const GridButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 40px 1fr 1fr 1fr;
  grid-template-rows: 56px;
  grid-template-areas:
    'btn-inf space btn-cancel btn-submit';


  @media (max-width: 767.98px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 56px 56px;
    grid-template-areas:
      'btn-cancel btn-submit'
      'btn-inf space';
  }
`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;

export const WrapperBtnCancel = styled.div`
  grid-area: btn-cancel;
`;

export const WrapperBtnSubmit = styled.div`
  grid-area: btn-submit;
`;

export const WrapperLink = styled.div`
  width: 100%;
  margin: 16px 0 0 0;
  border-radius: 4px;
  background: var(--color-button);
  display: flex;
  justify-content: center;
  align-items: center;

  a{
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    svg{
      font-size: 20px;
      margin-right: 16px;
    }
  }

  &:hover{
    background: var(--color-button-h);
  }
`;
