import React from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Simple from 'components/Headers/Simple';
import Layout from '../../../../components/Layout';
import {
  CarIcon,
  Reply,
  Pistol,
  Bullets,
  BoxOpen,
  Medicines,
  Handcuffs,
} from '../../../../styles/icons';

import {
  Container, Button, WrapperAddress, Title,
} from './styles';

const Favorites: React.FC = () => {
  const history = useHistory();

  const breadcrumbs = {
    icon: Handcuffs,
    links: [{ path: '/RO/seizures/show', title: 'RO-SP / Apreenções / Menu' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Simple>
          <Title><Handcuffs /> Tipo de Apreenção </Title>
        </Simple>
        <WrapperAddress>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Button type="button" onClick={() => history.push('/RO/seizures/storage/guns')}>
                  <Pistol size={32} /> Armas
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Button type="button" onClick={() => history.push('/RO/seizures/storage/narcotics')}>
                  <Medicines size={32} /> Entorpecentes
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Button type="button" onClick={() => history.push('/RO/seizures/storage/material')}>
                  <BoxOpen size={32} /> Material
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} className="displayNone">
                <Button type="button" onClick={() => history.push('/RO/seizures/storage/ammunitions')}>
                  <Bullets size={32} /> Munição
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} className="displayNone">
                <Button
                  type="button"
                  onClick={() => history.push('/RO/seizures/storage/cars')}
                >
                  <CarIcon size={32} /> Veículo
                </Button>
              </Grid>
              <Grid container justify="flex-end" item xs={12} sm={6} md={6}>
                <Button id="volta" type="button" onClick={() => history.push('/RO/step/4')}>
                  <Reply size={32} /> Volta
                </Button>
              </Grid>
            </Grid>
          </Container>
        </WrapperAddress>
      </Container>
    </Layout>
  );
};

export default Favorites;
