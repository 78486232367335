import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;

  padding: 8px 16px;

  form{
    width: 100%;
  }
`;

export const WrapperName = styled.div`
  font-size: 18px;
  display:flex;
  align-items:center;
  
  p{
    font-weight: 800;
    margin-left: 8px;
  }
  
  span{
    font-size: 18px;
    margin-left: 8px;
    text-decoration: line-through;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;

  width: calc(33.33% - 16px);
  margin: 8px;
  padding: 8px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  overflow: hidden;
  transition: transform 0.2s;

  &:hover{
    transform: translateY(-2px);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 767.98px) {
    width: 100%;
  }

`;

export const WrapperRoles = styled.div`
  width:100%;
  display: flex;
  justify-content: start;

  min-height: 22px;

  span {
    color: #fff;
    padding: 3px 6px;
    background: var(--color-bg-gray);
    border-radius: 3px;
  }

`;

export const WrapperPaginate = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;

export const GridButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 40px 1fr 1fr 1fr;
  grid-template-rows: 56px;
  grid-template-areas:
    'btn-inf space btn-cancel btn-submit';


  @media (max-width: 767.98px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 56px 56px;
    grid-template-areas:
      'btn-cancel btn-submit'
      'btn-inf space';
  }
`;

export const WrapperButton = styled.span`
  display: flex;
  width: 100%;
  > div{
    width: calc(100% - 48px);
    input{
      width: calc(100% - 46px);
    }
  }

  button{
    width: 40px;
  }

`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;

export const WrapperBtnCancel = styled.div`
  grid-area: btn-cancel;
`;

export const WrapperBtnSubmit = styled.div`
  grid-area: btn-submit;
`;
