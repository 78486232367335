import React, { useState, useEffect, useCallback } from 'react';
/** hooks */
import { useHttp } from '../../../../hooks/http';
import { Container } from './styles';

/** componentes */
import Pagination from '../../../../components/Pagination';
import Header from '../components/Header';
import Card, { Flex, Grid } from '../components/Card';
import { IPaginate } from '../../../../interfaces';

const EndSituation: React.FC = () => {
  const { httpGet } = useHttp();
  const [endSituations, setEndSituations] = useState([]);
  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 20, lastPage: 1,
  });
  const [filter, setFilter] = useState<string|null>(null);

  const getUpajs = useCallback(async (filter: string | null, paginate: IPaginate) => {
    const { status, data } = await httpGet('end-situations', {
      params: {
        filter,
        ...paginate,
      },
    });

    if (status === 200) {
      setEndSituations(data.data);
      setPaginate(data.pagination);
    }
  }, [httpGet]);

  useEffect(() => {
    getUpajs(null, { page: 1, perPage: 20 } as IPaginate);
  }, [getUpajs]);

  const handledPagination = useCallback((payload: IPaginate) => {
    setPaginate(payload);
    getUpajs(filter, payload);
  }, [filter, getUpajs]);

  const handledFilter = useCallback((filter: string) => {
    setFilter(filter);
    getUpajs(filter, { page: 1, perPage: 20 } as IPaginate);
  }, [getUpajs]);

  return (
    <Container>
      <Header
        handledSearch={handledFilter}
        uriAdd="/settings/end-situations/new"
      />
      {endSituations && endSituations.map((endSituation: any) => (
        <Card key={endSituation.id} UriUpdate={`/settings/end-situations/${endSituation.id}`}>
          <Grid container spacing={1}>
            <Flex textAutoSize title="Nome" xs={6}>
              {endSituation.name}
            </Flex>
            <Flex textAutoSize title="Tipo" xs={6}>
              {endSituation.type === 'vehicle' ? 'Veículo' : 'Pessoa'}
            </Flex>
          </Grid>
        </Card>
      )) }
      <Pagination handlePaginate={handledPagination} paginate={paginate} />
    </Container>
  );
};

export default EndSituation;
