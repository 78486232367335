import React, { ButtonHTMLAttributes } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'large' | 'medium' | 'small';
  color?: 'primary' | 'secondary';
  isLoading?: boolean;
  disableForce?: boolean;
}

const ButtonDash: React.FC<ButtonProps> = ({
  children,
  size = 'large',
  color = 'primary',
  isLoading = false,
  disableForce = false,
  ...rest
}) => (
  <Container
    type="button"
    disabled={disableForce || isLoading}
    color={color}
    size={size}
    isLoading={isLoading}
    disableForce={disableForce}
    {...rest}
  >
    {isLoading
      ? (
        <PulseLoader
          size={18}
          color="#fff"
          loading={isLoading}
        />
      )
      : children}

  </Container>
);

export default ButtonDash;
