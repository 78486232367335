import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 8px 8px 8px 20px;
`;

export const WrapperUser = styled.div`
  display: flex;
  align-items: center;

  span{
    margin-left: 10px;
  }
`;

export const Initials = styled.div`
  background: #2D383C;
  border-radius: 4px;
  width: 35px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
`;
