import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 65px;
  width: 100%;
  padding: 8px 12px;

  border-bottom: 1px solid var(--color-border);
`;

export const WrapperBreadcrumb = styled.div`
  width: 100%;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767.98px) {
    padding-right: 0;
  }
`;

export const WrapperUpdate = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  cursor: pointer;
  padding: 0 16px;
  margin-right: 16px;
  height: 35px;
  border-radius: 4px;
  color: var(--color-secondary);
  background: var(--color-yellow);

  > svg{
    flex-shrink: 0;
    margin-right: 8px;
  }

  @media (max-width: 767.98px) {
    font-size: 0.75em;
  }
`;

export const WrapperProfile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 3;

  button{
    border: none;
    background: transparent;
    color: #fff;
  }
`;

export const WrapperMenu = styled.div`
  position: absolute;
  top: 32px;
  right: 16px;
`;

export const WrapperMenuItem = styled.span`
  display: flex;
  align-items: center;
  svg {
    color: var(--color-secondary);
    margin-right: 8px;
  }
`;

export const Breadcrumb = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 8px;

  @media (max-width: 767.98px) {
    padding-left: 0;
  }

  svg{
    margin-right: 8px;
  }

  ul{
    width: 100%;
    display: flex;
  }

  li  {
    list-style: none;

    + li{
      margin-left: 8px;
      &::before{
        content: '/';
        padding-right: 8px;
      }
    }

    @media (max-width: 575.98px) {
      + li{
      margin-left: 0;
      &::before{
        content: '';
        padding-right: 0;
      }
      }
    }

    a{
      text-decoration: none;
      color: var(--color-primary);
    }
  }

  @media (max-width: 575.98px) {
    svg {
      display: none;
    }
    ${css`.breadcrumb-link{ display: none;}`}
  }
`;

export const ContactNuint = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 8px;

  @media (max-width: 767.98px) {
    padding-left: 0;
  }
  svg {
      flex-shrink: 0;
      margin-right: 8px;
    }

  @media (max-width: 575.98px) {
    svg {
      flex-shrink: 0;
      margin-right: 8px;
    }
    h4 {
      display: block;
      font-size: 0.75em;
    }
    ${css`.breadcrumb-link{ display: none;}`}
  }
`;
