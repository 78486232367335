import { useHttp } from 'hooks/http';
import React, { useEffect, useState } from 'react';
import Lesson from './Lesson';

import { Container, Head, Content } from './styles';

const SideVideo: React.FC = () => {
  const { httpGet } = useHttp();
  const [lesson, setLesson] = useState([]);

  useEffect(() => {
    async function load(): Promise<any> {
      const { status, data } = await httpGet('/classrooms');

      if (status === 200) {
        setLesson(data);
      }
    }
    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpGet]);

  return (
    <Container>
      <Head>
        Cronograma de aulas
      </Head>
      {lesson && lesson.map((item: any) => (
        <Content key={item.id}>
          <Lesson
            title={item?.title}
            slug={item?.slug}
            type="class"
            availableAt={item?.created_at}
          />
        </Content>
      ))}
    </Container>
  );
};

export default SideVideo;
