import 'leaflet/dist/leaflet.css';
import React, { useCallback, useEffect, useState } from 'react';
import { TileLayer, Map } from 'react-leaflet';
import { LeafletEvent } from 'leaflet';

import { FiPlus } from 'react-icons/fi';
import { Container, WrapperTarget } from './styles';

interface ICoords {
  lat: number;
  lng: number;
}

interface ILeafletMap{
  handleCoord(value: ICoords): void;
  currentCoords: ICoords;
}

const LeafletMap: React.FC<ILeafletMap> = ({ handleCoord, currentCoords }) => {
  const [initPosition] = useState<any>([
    currentCoords.lat,
    currentCoords.lng,
  ]);

  useEffect(() => {
    handleCoord(currentCoords);
  }, [currentCoords, handleCoord]);

  const handleMapDragend = useCallback((event: LeafletEvent) => {
    const position = event.target.getCenter();
    handleCoord({
      lat: position.lat,
      lng: position.lng,
    });
  }, [handleCoord]);
  return (
    <Container>
      <WrapperTarget>
        <FiPlus />
      </WrapperTarget>
      <Map
        center={initPosition}
        onDragend={handleMapDragend}
        zoom={16}
        style={{ width: '100%', height: '100%' }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </Map>
    </Container>
  );
};

export default LeafletMap;
