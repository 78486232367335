import React from 'react';
import { MenuItem as MenuItemPro } from 'react-pro-sidebar';
import { useAuth } from '../../../hooks/auth';

interface MenuItemProps {
  can?: string;
  is?: string;
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  active?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  firstchild?: boolean;
  popperarrow?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ can = '', is = '', ...rest }) => {
  const { iCan, iIs } = useAuth();
  return (
    <>
      { (iCan(can) && iIs(is)) && <MenuItemPro {...rest} />}
    </>
  );
};

export default MenuItem;
