const brandGuns = [
  { value: 'Benelli', label: 'Benelli' },
  { value: 'Beretta', label: 'Beretta' },
  { value: 'Bersa', label: 'Bersa' },
  { value: 'Boito', label: 'Boito' },
  { value: 'Browning', label: 'Browning' },
  { value: 'CBC', label: 'CBC' },
  { value: 'Colt', label: 'Colt' },
  { value: 'CZ (Ceske zbrojovka)', label: 'CZ (Ceske zbrojovka)' },
  { value: 'FAMAE', label: 'FAMAE' },
  { value: 'FN(Fabrigue Nationale de Herstal)', label: 'FN(Fabrigue Nationale de Herstal)' },
  { value: 'Glock Power', label: 'Glock Power' },
  { value: 'Hatsan', label: 'Hatsan' },
  { value: 'HK (Heckler & Kock)', label: 'HK (Heckler & Kock)' },
  { value: 'IMBEL', label: 'IMBEL' },
  { value: 'IWI(Israel Weapon Industries)', label: 'IWI(Israel Weapon Industries)' },
  { value: 'Kimber', label: 'Kimber' },
  { value: 'Magnus', label: 'Magnus' },
  { value: 'Mossberg', label: 'Mossberg' },
  { value: 'Remington', label: 'Remington' },
  { value: 'Ruger', label: 'Ruger' },
  { value: 'Savage', label: 'Savage' },
  { value: 'Sig Sauer', label: 'Sig Sauer' },
  { value: 'Smith & Wesson', label: 'Smith & Wesson' },
  { value: 'Springfield', label: 'Springfield' },
  { value: 'Tanfoglio', label: 'Tanfoglio' },
  { value: 'Taurus', label: 'Taurus' },
  { value: 'Thompson', label: 'Thompson' },
  { value: 'Walther', label: 'Walther' },
  { value: 'Winchester', label: 'Winchester' },
  { value: 'Outros', label: 'Outros' },
];

export default brandGuns;
