import React, { useCallback, useRef } from 'react';

import { Link } from 'react-router-dom';

import { Container, WrapperInput, ButtonPlus } from './styles';
import { SearchIcon, PlusIcon } from '../../../../../styles/icons';

interface IHeader{
  handledSearch(filter: string): void;
  uriAdd: string;
}

const Header: React.FC<IHeader> = ({ handledSearch, uriAdd }) => {
  const refInput = useRef<HTMLInputElement>(null);

  const handledSubmit = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    handledSearch(refInput.current?.value || '');
  }, [handledSearch]);

  return (
    <Container>
      <form onSubmit={handledSubmit}>
        <WrapperInput>
          <input ref={refInput} type="search" name="filter" />
          <button type="submit">
            <SearchIcon />
          </button>
        </WrapperInput>
      </form>
      <Link to={uriAdd}>
        <ButtonPlus>
          <PlusIcon />
        </ButtonPlus>
      </Link>
    </Container>
  );
};

export default Header;
