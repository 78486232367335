import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt-BR'; // eslint-disable-line
import Select from 'react-select';
/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** Componentes */
import Layout from '../../../components/Layout';
import { ButtonIcon } from '../../../components/Forms';
import { CsvIcon, DownloadIcon } from '../../../styles/icons';
import {
  Container, Contante, WrapperSelect, WrapperDate,
} from './styles';

import { IOption } from '../../../interfaces';

registerLocale('pt-BR', pt);

const breadcrumbs = {
  icon: CsvIcon,
  links: [
    { title: 'Estatística', path: '/statistic' },
    { title: 'Exportar Consultas', path: '' },
  ],
};
const Statistic: React.FC = () => {
  const {
    httpGet, httpPost, loading,
  } = useHttp();
  const { addToast } = useToast();
  const [date, setDate] = useState(new Date());
  const [managements, setManagements] = useState<IOption[]>([]);
  const [management_id, setManagement_id] = useState<number|null>(null);

  useEffect(() => {
    async function load():Promise<void> {
      const { status, data } = await httpGet('/extraction/managements');
      if (status === 200) {
        const managementParse = data.map((management:any) => ({
          value: management.id,
          label: `${management.initials} - ${management.name}`,
        }));

        setManagements(managementParse);
      }
    }

    load();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: any):void => {
    setManagement_id(event?.value as number);
  };

  const handleDownload = useCallback(async () => {
    if (!management_id) {
      addToast({ title: 'Você precisa escolher uma gerência', type: 'error' });
      return;
    }
    const { status, data } = await httpPost('/extraction/orders', { date, management_id });
    if (status === 200) {
      const a = document.createElement('a');
      document.body.appendChild(a);

      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const managementFiltered = managements
        .filter((management:any) => management.value === management_id)[0];

      a.href = url;
      a.download = `Consultas ${String(managementFiltered.label)}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, managements, management_id]);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Contante>
          <Grid container spacing={1} alignItems="flex-end">

            <Grid item xs={12} sm={6} md={4}>
              <WrapperSelect>
                <label htmlFor="date">Gerência</label>
                <Select name="management" isClearable options={managements} onChange={handleChange} />
              </WrapperSelect>
            </Grid>
            <Grid item xs={10} sm={4} md={3}>
              <WrapperDate>
                <label htmlFor="date">Mês/Ano</label>
                <DatePicker
                  id="date"
                  selected={date}
                  onChange={(date: any) => setDate(date)}
                  dateFormat="MM/yyyy"
                  locale="pt-BR"
                  showMonthYearPicker
                  maxDate={new Date()}
                  showFullMonthYearPicker
                />
              </WrapperDate>
            </Grid>
            <Grid item xs={1}>
              <ButtonIcon
                title="baixar"
                isLoading={loading}
                onClick={handleDownload}
              >
                <DownloadIcon />
              </ButtonIcon>
            </Grid>
          </Grid>
        </Contante>
      </Container>
    </Layout>
  );
};

export default Statistic;
