import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { InfoIcon, GrUserPoliceIcon } from 'styles/icons';
import * as Yup from 'yup';
import Layout from 'components/Layout';
import Flex from 'components/Flex';
import getValidationError from '../../utils/getValidationsErros';
import {
  Button, ButtonIcon, Input, WrapperLabel,
} from '../../components/Forms';
import Modal from '../../components/ModalSimple';
import { useHttp } from '../../hooks/http';
import {
  Container,
  Content,
  GridButtons,
  Img,
  WrapperBtnCancel,
  WrapperBtnInfo,
  WrapperBtnSubmit,
} from './styles';

interface IPmerj {
  rg: string,
  gh: string,
  name: string,
  document: string,
  opm: string
}

const Pmerj: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const { httpGet } = useHttp();
  const [pmerj, setPmerj] = useState<IPmerj>();

  const handleSubmit = useCallback(async (data: any) => {
    const _user = {
      ...data,
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        rg: Yup.number().required('Rg pmerj é obrigatório'),
      });

      await schema.validate(_user, { abortEarly: false });
      setBtnLoading(true);
      const { status, data } = await httpGet(`users/pmerj/${_user.rg}`);
      if (status === 200) {
        setPmerj(data);
      }
      setBtnLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, []);// eslint-disable-line

  const breadcrumbs = {
    icon: GrUserPoliceIcon,
    links: [{ path: '', title: 'Pmerj' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content>
          {!pmerj ? (
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <WrapperLabel label="Rg Pmerj" xs={12}>
                  <Input name="rg" maxLength={6} minLength={5} />
                </WrapperLabel>
              </Grid>
              <GridButtons>
                <WrapperBtnInfo>
                  <ButtonIcon
                    title="Instruções"
                    onClick={() => labelRef.current?.click()}
                  >
                    <InfoIcon />
                  </ButtonIcon>

                  <label ref={labelRef} htmlFor="modal-instruction" />
                  <Modal id="modal-instruction" width={520} height="auto">
                    <h1>Instruções</h1><br />
                    <p>
                      Para solicitar uma consulta de pessoa você precisa no mínimo
                      informar os seguintes dados:
                    </p><br />
                    <p><b>Rg pmerj</b></p>
                    <br />
                  </Modal>

                </WrapperBtnInfo>
                <WrapperBtnCancel>
                  <Button
                    type="button"
                    onClick={() => { history.push('/dashboard'); }}
                  >
                    Cancelar
                  </Button>
                </WrapperBtnCancel>
                <WrapperBtnSubmit>
                  <Button
                    type="submit"
                    onClick={() => {
                    formRef.current?.submitForm();
                    }}
                    isLoading={btnLoading}
                  >
                    Consultar
                  </Button>
                </WrapperBtnSubmit>
              </GridButtons>
            </UForm>
          ) : (
            <>

              <Grid container spacing={1}>
                <Flex title="Foto" xs={12}>
                  <Img src={`http://172.16.0.1/fotos/${pmerj?.rg}.bmp`} width={180} height={180} />
                </Flex>
                <Flex title="Nome" xs={12} sm={6} md={4}>
                  <span>
                    {pmerj?.name}
                  </span>
                </Flex>
                <Flex title="Posto/Graduação" xs={6} sm={6} md={4}>
                  {pmerj?.gh || 'não informado'}
                </Flex>
                <Flex title="Rg" xs={6} sm={6} md={3}>
                  {pmerj?.rg || 'não informado'}
                </Flex>
                <Flex title="Cpf" xs={6} sm={6} md={3}>
                  {pmerj?.document || 'não informado'}
                </Flex>
                <Flex title="Opm" xs={6} sm={6} md={4}>
                  {pmerj?.opm || 'não informado'}
                </Flex>
                <Grid container justify="flex-end">
                  <Grid item xs={12} md={4}>
                    <Button type="button" onClick={() => setPmerj(undefined)}>Nova Busca</Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

        </Content>
      </Container>
    </Layout>
  );
};

export default Pmerj;
