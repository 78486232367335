import styled from 'styled-components';

export const Container = styled.div`
  margin: 16px;
  padding: 16px;
  background: #fff;
  border-radius: 4px;

  h2{
    font-size: 1.1em;
    padding-bottom: 8px;
    margin: 8px 0;

    border-bottom: 1px solid #ccc;

  }
`;

export const WrapperLink = styled.div`
  width: 100%;
  margin: 16px 0 0 0;
  border-radius: 4px;
  background: var(--color-button);
  display: flex;
  justify-content: center;
  align-items: center;

  a{
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    svg{
      font-size: 20px;
      margin-right: 16px;
    }
  }

  &:hover{
    background: var(--color-button-h);
  }
`;
