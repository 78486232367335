import api from '../../../../services/api';
import { session, formatDate } from '../../../../utils';

import { IOrder } from '../../../../interfaces';

export default async function searchMyBase(order: IOrder): Promise<any | null> {
  const token = session.get('token');
  api.defaults.headers.authorization = `Bearer ${token}`;

  if (order.type === 'vehicle') {
    const { status, data } = await api.get('/vehicles/search', {
      params: {
        plate: order.plate,
        chassi: order.chassi,
        engine_number: order.engine_number,
      },
    });
    if (status === 200) {
      return {
        'vehicle.plate': data.plate,
        'vehicle.chassi': data.chassi,
        'vehicle.engine_number': data.engine_number,
        'vehicle.brand': data.brand,
        'vehicle.color': data.color ? { value: data.color, label: data.color } : null,
      };
    }
    return {
      'vehicle.plate': order.plate,
      'vehicle.chassi': order.chassi,
      'vehicle.engine_number': order.engine_number,
      'vehicle.brand': order.brand,
      'vehicle.color': order.color ? { value: order.color, label: order.color } : null,
    };
  }

  if (order.type === 'person') {
    if (order.document || order.document_secondary) {
      const { status, data } = await api.get('/person/search', {
        params: {
          document: order.document,
          document_secondary: order.document_secondary,
        },
      });

      if (status === 200) {
        return {
          'person.name': data.name,
          'person.document': data.document,
          'person.birthday': data.birthday ? formatDate(data.birthday) : null,
          'person.document_secondary': data.document_secondary,
          'person.mather': data.mather,
          'person.father': data.father,
          'person.gender': data.gender ? { value: data.gender, label: data.gender } : null,
        };
      }
    }

    return {
      'person.name': order.name,
      'person.document': order.document,
      'person.birthday': order.birthday ? formatDate(order.birthday) : null,
      'person.document_secondary': order.document_secondary,
      'person.mather': order.mather,
      'person.father': order.father,
    };
  }

  return null;
}
