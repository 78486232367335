import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

/** hooks */
import { useHttp } from '../../hooks/http';

/** Componentes */
import Layout from '../../components/Layout';
import HeaderFilterPlus from '../../components/Headers/FilterPlus';
import Flex, { Grid } from '../../components/Flex';
import Card, { CardHeader, CardContent } from '../../components/Card';
import {
  Menu, MenuItem, DotButton, ItemIcon,
} from '../../components/CustomMenu';

import {
  UsersIcon, PencilIcon, BlockIcon,
} from '../../styles/icons';

/** interfaces */
import { IUser, IResponse } from '../../interfaces';
import { formatCpf, formatDate } from '../../utils';

import {
  Container, WrapperPaginate, WrapperRoles, WrapperName,
} from './styles';

const breadcrumbs = {
  icon: UsersIcon,
  links: [
    { title: 'Usuários', path: '' },
  ],
};

interface UserData extends IResponse {
  data: IUser[];
}

const User: React.FC = () => {
  const [users, setUsers] = useState<UserData>({} as UserData);
  const [filter, setFilter] = useState('');
  const history = useHistory();
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(6);
  const [lastPage, setLastPage] = React.useState(1);

  const { httpGet, loading } = useHttp();

  useEffect(() => {
    async function loadUsers(): Promise<void> {
      const response = await httpGet('users', {
        params: {
          page,
          perPage,
          filter,
        },
      });

      if (response.data) {
        setUsers(response.data);
        setPerPage(response.data.pagination.perPage);
        setLastPage(response.data.pagination.lastPage);
      }
    }

    loadUsers();

  }, [filter, page, perPage]); // eslint-disable-line

  const handleSearch = (value: string): void => {
    setPage(1);
    setFilter(value);
  };

  const handlePaginate = (event: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  const handleGoToEdit = (id: string): void => {
    history.push(`/user/${id}`);
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>

        <HeaderFilterPlus
          handleSearch={handleSearch}
          handlePlus={() => history.push('/user')}
        />

        <Grid container justify="flex-start" spacing={1}>
          {users.data?.map((user) => (
            <Grid item key={user.id} xs={12} sm={6} md={6}>
              <Card>
                <CardHeader>
                  <WrapperName>
                    {user.status
                      ? `${user.function} ${user.name}`
                      : <><BlockIcon /> <span> {`${user.function} ${user.name}`} </span></>}
                  </WrapperName>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <>
                        <DotButton {...bindTrigger(popupState)} />
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            onClick={() => { handleGoToEdit(user.id); }}
                            style={{ width: 150 }}
                          >
                            <ItemIcon icon={<PencilIcon />} title="Editar" />
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </PopupState>
                </CardHeader>
                <CardContent>
                  <Grid container spacing={1}>
                    <Flex title="CPF" xs={6} sm={6} md={4}>
                      {formatCpf(user.document)}
                    </Flex>
                    <Flex title="ID Funcional" xs={6} sm={6} md={4}>
                      {user.document_secondary}
                    </Flex>
                    <Flex title="Celular" xs={6} sm={6} md={4}>
                      {user.phone}
                    </Flex>
                    <Flex title="Capacitação Intel." xs={6} sm={6} md={4}>
                      {user.is_intelligence
                        ? 'Sim'
                        : 'Não'}
                    </Flex>
                    <Flex title="Lotação" xs={6} sm={6} md={4}>
                      {user.allocations
                        .find((allocation) => allocation.enabled)?.management.initials}
                    </Flex>
                    <Flex title="Rg" xs={6} sm={6} md={4}>
                      {user.rg}
                    </Flex>
                    <Flex title="E-mail" xs={6} sm={6} md={6} textAutoSize>
                      {user.email}
                    </Flex>
                    <Flex title="Bodycan" xs={6} sm={6} md={6}>
                      {user.bodycans?.password ? user.bodycans?.password : 'Não cadastrado'}
                    </Flex>
                    <Flex title="Nascimento" xs={6} sm={6} md={4}>
                      {formatDate(user.birthday)}
                    </Flex>

                    <Flex title="Função" xs={12}>
                      <WrapperRoles>
                        {user.roles.map((role) => (
                          <span key={`${user.id}-${role.name}`}>
                            {role.name}
                          </span>
                        ))}
                      </WrapperRoles>
                    </Flex>
                  </Grid>
                </CardContent>

              </Card>
            </Grid>
          ))}
        </Grid>
        <WrapperPaginate>
          <Pagination count={lastPage} page={page} onChange={handlePaginate} />
        </WrapperPaginate>
      </Container>
    </Layout>
  );
};

export default User;
