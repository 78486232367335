import React, { useState } from 'react';
import { useHttp } from '../../hooks/http';

/** components */
import Layout from '../../components/Layout';
import { UserSearchFill } from '../../styles/icons';
import Address from '../Orders/Address';

import Form from './Form';
import { Container, WrapperAddress } from './styles';

interface ICoord {
    lat: number;
    lng: number;
}

const Sinalid: React.FC = () => {
  const { loading } = useHttp();
  const [coord, setCoords] = useState<ICoord>({} as ICoord);

  const breadcrumbs = {
    icon: UserSearchFill,
    links: [{ path: '', title: 'Sinalid' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        {!coord.lat && (
        <WrapperAddress>
          <h3><span>Selecione o </span>local</h3>
          <Address
            handleCoord={(coord: ICoord) => {
              setCoords(coord);
            }}
          />
        </WrapperAddress>
        )}
        {(coord.lat) && (
        <Form coord={coord} />
        )}
      </Container>
    </Layout>
  );
};

export default Sinalid;
