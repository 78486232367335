import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  grid-template-areas:
    'form list';

  @media (max-width: 960.98px) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas:
    'form'
    'list';
  }
`;
export const WrapperForm = styled.div`
  grid-area: form;

`;

export const WrapperAllocation = styled.div`
  grid-area: list;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0 0 0 16px;

  overflow-y: 500px;
  max-height: 500px;
  > span {
    border-left: 1px solid var(--color-border);
    padding-left: 16px;

    > h1{
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 2px;
    }

    > h2{
      font-size: 16px;

      border: 1px solid var(--color-border);
      border-radius: 4px;
      padding: 9px 16px;
    }
    @media (max-width: 960.98px) {
      border-left: 0;
      padding-left: 0;
    }
  }



  @media (max-width: 960.98px) {
    padding: 8px 0;
  }
`;
