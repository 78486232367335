import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { formatDateTime } from '../../../utils';

/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';
import { documentation } from '../FormPacto/pacto';
/** components */
import Layout from '../../../components/Layout';
import Flex, { Grid } from '../../../components/Flex';
import {
  ListIcon,
} from '../../../styles/icons';
import {
  Container, WrapperHeader, Title,
} from './styles';

/** interface */
import { IQuiz, IParticipation, IWater } from '../../../interfaces';

const breadcrumbs = {
  icon: ListIcon,
  links: [
    { path: '', title: 'Detalhe da Pesquisa' },
  ],
};

const Detail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { httpGet, loading } = useHttp();
  const { addToast } = useToast();
  const [list, setList] = useState<string[]>();
  const [quiz, setQuiz] = useState<IQuiz>({} as IQuiz);
  const [water, setWater] = useState<IWater>({} as IWater);
  const [participation, setParticipation] = useState<IParticipation>({} as IParticipation);
  const [inProgress, setInProgress] = useState<any>({} as any);

  useEffect(() => {
    async function loadAnswer(): Promise<void> {
      const { status, data } = await httpGet(`/constructioninprogress/${id}`);
      if (status === 200) {
        setInProgress(data);
      } else {
        const { status, data } = await httpGet(`/quizzes/${id}`);
        if (status === 200) {
          setQuiz(data);
        } else {
          const { status, data } = await httpGet(`/waterqualities/${id}`);
          if (status === 200) {
            setWater(data);
          } else {
            const { status, data } = await httpGet(`/participation/${id}`);
            if (status === 200) {
              const filterd = data.documentations_ids.split(',');
              const dd = Array<string>();
              filterd.map((x: number) => dd.push(`${documentation[x].label}, `));
              setList(dd);

              setParticipation(data);
            } else {
              addToast({ title: 'Erro ao listar.' });
              history.push('/ceperjs/list');
            }
          }
        }
      }
    }
    loadAnswer();

  }, [addToast, history, id]);  // eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        {quiz?.id && (
          <>
            <WrapperHeader>
              {quiz?.action?.name}
            </WrapperHeader>

            <Title>Dados da(o) {quiz?.action?.type}:</Title>

            <Grid container spacing={1} justify="space-between">
              <Flex title="Nome" xs={12} sm={4} md={4}>
                <b> {quiz?.name} </b>
              </Flex>
              <Flex title="Gênero" xs={6} sm={4} md={4}>
                <b> {quiz?.gender || 'não informado'} </b>
              </Flex>
              <Flex title="Idade" xs={6} sm={4} md={4}>
                <b> {quiz?.age || 'não informado'} </b>
              </Flex>
              <Flex title="Município" xs={12} sm={4} md={4}>
                <b> {quiz?.city || 'não informado'} </b>
              </Flex>
              <Flex title="Reside neste Município?" xs={12} sm={4} md={4}>
                <b> {quiz?.lived_city || 'não informado'} </b>
              </Flex>
              <Flex title="Quanto anos você reside neste município?" xs={12} sm={4} md={4}>
                <b> {quiz?.long_lived_city || 'não informado'} </b>
              </Flex>
              <Flex title="Tem conhecimento da implantação?" xs={6} sm={4} md={4}>
                <b> {quiz.implantation || 'não informado'} </b>
              </Flex>
              <Flex title="Como tomou conhecimento?" xs={6} sm={4} md={4}>
                <b> {quiz.knowledge || 'não informado'} </b>
              </Flex>
              <Flex title={`Qual a sua opinião sobre a necessidade da(o) da(o) ${quiz?.action?.type}?`} xs={12} sm={4} md={4}>
                <b> {quiz.need || 'não informado'} </b>
              </Flex>
              {quiz?.action?.type === 'OBRA' ? (
                <Flex title="Qual a sua opinião sobre a qualidade da obra." xs={12} sm={4} md={4}>
                  <b> {quiz.quality_work || 'não informado'} </b>
                </Flex>
              ) : ''}
              <Flex title={`A realizacao da(o) ${quiz?.action?.type} trouxe alguma melhoria para a regiao?`} xs={6} sm={4} md={4}>
                <b> {quiz.improvement_region || 'não informado'} </b>
              </Flex>
              <Flex title={`A realizacao da(o) ${quiz?.action?.type} trouxe alguma melhoria para sua vida?`} xs={12} sm={4} md={4}>
                <b> {quiz.improvement_life || 'não informado'} </b>
              </Flex>
              <Flex title={`Como voce percebe os efeitos da(o) ${quiz?.action?.type} eo impacto social na regiao?`} xs={12} sm={4} md={4}>
                <b> {quiz.impact_region || 'não informado'} </b>
              </Flex>
              <Flex title={`Como você percebe os efeitos e o impacto social da(o) ${quiz?.action?.type} na sua vida?`} xs={12} sm={4} md={4}>
                <b> {quiz.impact_life || 'não informado'} </b>
              </Flex>
            </Grid>
            <Grid container spacing={1} justify="space-between">
              <Flex title="Data" xs={6} sm={4} md={4}>
                <b>{formatDateTime(quiz.created_at)}</b>
              </Flex>
              <Flex title="Gerência" xs={6} sm={4} md={4}>
                <b>{quiz.managements?.name}</b>
              </Flex>
            </Grid>
          </>
        )}
        {participation?.id && (
          <>
            <WrapperHeader>
              {participation?.action?.name}
            </WrapperHeader>

            <Title>Dados da(o) {participation?.action?.type}:</Title>

            <Grid container spacing={1} justify="space-between">
              <Flex title="Nome" xs={12} sm={4} md={4}>
                <b> {participation?.name} </b>
              </Flex>
              <Flex title="Gênero" xs={6} sm={4} md={4}>
                <b> {participation?.gender || 'não informado'} </b>
              </Flex>
              <Flex title="Idade" xs={6} sm={4} md={4}>
                <b> {participation?.age || 'não informado'} </b>
              </Flex>
              <Flex title="Município" xs={12} sm={4} md={4}>
                <b> {participation?.city || 'não informado'} </b>
              </Flex>
              <Flex title="Reside neste Município?" xs={12} sm={4} md={4}>
                <b> {participation?.lived_city || 'não informado'} </b>
              </Flex>
              <Flex title="Quanto anos você reside neste município?" xs={12} sm={4} md={4}>
                <b> {participation?.long_lived_city || 'não informado'} </b>
              </Flex>
              <Flex title="Com relação a melhorias para a qualidade de vida, indicque 3 ações que considere essenciais?" xs={12} sm={12} md={12}>
                <b>{list && list}</b>
              </Flex>
              <Flex title="Você se sente bem em morar no local?" xs={6} sm={4} md={4}>
                <b> {participation.naturalness || 'não informado'} </b>
              </Flex>
              <Flex title="Educação" xs={12} sm={4} md={4}>
                <b> {participation.education || 'não informado'} </b>
              </Flex>
              <Flex title="Saúde" xs={12} sm={4} md={4}>
                <b> {participation.health || 'não informado'} </b>
              </Flex>
              <Flex title="Cultura" xs={6} sm={4} md={4}>
                <b> {participation.culture || 'não informado'} </b>
              </Flex>
              <Flex title="Lazer" xs={12} sm={4} md={4}>
                <b> {participation.leisure || 'não informado'} </b>
              </Flex>
              <Flex title="Saneamento" xs={12} sm={4} md={4}>
                <b> {participation.sanitation || 'não informado'} </b>
              </Flex>
              <Flex title="Água encanada" xs={12} sm={4} md={4}>
                <b> {participation.piped_water || 'não informado'} </b>
              </Flex>
              <Flex title="Habitação" xs={6} sm={4} md={4}>
                <b> {participation.housing || 'não informado'} </b>
              </Flex>
              <Flex title="Segurança pública" xs={12} sm={4} md={4}>
                <b> {participation.public_security || 'não informado'} </b>
              </Flex>
              <Flex title="Transporte público" xs={12} sm={4} md={4}>
                <b> {participation.public_transportation || 'não informado'} </b>
              </Flex>
            </Grid>
            <Grid container spacing={1} justify="space-between">
              <Flex title="Data" xs={6} sm={4} md={4}>
                <b>{formatDateTime(participation.created_at)}</b>
              </Flex>
              <Flex title="Gerência" xs={6} sm={4} md={4}>
                <b>{participation.managements?.name}</b>
              </Flex>
            </Grid>
          </>
        )}
        {inProgress?.id && (
          <>
            <WrapperHeader>
              {inProgress?.action?.name}
            </WrapperHeader>

            <Title>Dados da(o) {inProgress?.action?.name}:</Title>

            <Grid container spacing={1} justify="space-between">
              <Flex title="Empregado da empresa responsável pela equipe da obra?" xs={12} sm={4} md={4}>
                <b> {inProgress?.name} </b>
              </Flex>
              <Flex title="Quantidade de pessoas trabalhando no momento da visita?" xs={6} sm={4} md={4}>
                <b> {inProgress?.people_working || 'não informado'} </b>
              </Flex>
              <Flex title="Município da obra?" xs={12} sm={4} md={4}>
                <b> {inProgress?.city || 'não informado'} </b>
              </Flex>
              <Flex title="Há emprego de máquinas ou equipamentos no local da obra?" xs={6} sm={4} md={4}>
                <b> {inProgress?.machine_equipment || 'não informado'} </b>
              </Flex>
              <Flex title="Que tipos de máquinas ou equipamentos?" xs={12} sm={4} md={4}>
                <b> {inProgress?.kind_machine_equipament || 'não informado'} </b>
              </Flex>
              <Flex title="Outros" xs={6} sm={4} md={4}>
                <b> {inProgress?.kind_machine_equipament_others || 'não informado'} </b>
              </Flex>
              <Flex title="Observações sobre o andamento da obra" xs={12} sm={4} md={4}>
                <b> {inProgress?.status_observation || 'não informado'} </b>
              </Flex>
              <Flex title="Outros" xs={6} sm={4} md={4}>
                <b> {inProgress?.status_observation_others || 'não informado'} </b>
              </Flex>
              <Flex title="Observações" xs={6} sm={4} md={4}>
                <b> {inProgress?.note || 'não informado'} </b>
              </Flex>

              <Flex title="Imagens" xs={12} sm={6} md={6}>
                {inProgress?.ceperjs
                  && (
                  <ul>
                    {inProgress?.ceperjs.map((i: any) => (
                      <li style={{ listStyle: 'none' }} key={i.id}>
                        <a
                          href={`${i.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >{i.name}
                        </a>
                      </li>
                    ))}
                  </ul>
)}
              </Flex>

            </Grid>
            <Grid container spacing={1} justify="space-between">
              <Flex title="Data" xs={6} sm={4} md={4}>
                <b>{formatDateTime(inProgress.created_at)}</b>
              </Flex>
              <Flex title="Gerência" xs={6} sm={4} md={4}>
                <b>{inProgress.managements?.name}</b>
              </Flex>
            </Grid>
          </>

        )}

        {water?.id && (
          <>
            <WrapperHeader>
              {water?.action?.name}
            </WrapperHeader>

            <Title>Dados da(o) {water?.action?.type}:</Title>

            <Grid container spacing={1} justify="space-between">
              <Flex title="Nome" xs={12} sm={4} md={4}>
                <b> {water?.name} </b>
              </Flex>
              <Flex title="Gênero" xs={6} sm={4} md={4}>
                <b> {water?.gender || 'não informado'} </b>
              </Flex>
              <Flex title="Idade" xs={6} sm={4} md={4}>
                <b> {water?.age || 'não informado'} </b>
              </Flex>
              <Flex title="Município" xs={12} sm={4} md={4}>
                <b> {water?.city || 'não informado'} </b>
              </Flex>
              <Flex title="Reside neste Município?" xs={12} sm={4} md={4}>
                <b> {water?.lived_city || 'não informado'} </b>
              </Flex>
              <Flex title="Quanto anos você reside neste município?" xs={12} sm={4} md={4}>
                <b> {water?.long_lived_city || 'não informado'} </b>
              </Flex>
              <Flex
                title="Sua residência recebe água encanada fornecida por concessionária de serviços públicos(distribuida
                            por meio de tubulações e encanamentos)?"
                xs={6}
                sm={4}
                md={4}
              >
                <b> {water.receive_running || 'não informado'} </b>
              </Flex>
              <Flex
                title="Sua residência é atendida por serviço de rede
                        de coleta de esgoto prestado por concessionária de serviços públicos?"
                xs={12}
                sm={4}
                md={4}
              >
                <b> {water.attended_collection || 'não informado'} </b>
              </Flex>
              <Flex title="Em relação à água recebida em sua casa, você pode nos informar se:" xs={12} sm={4} md={4}>
                <b> {water.received_water || 'não informado'} </b>
              </Flex>
              <Flex title="Nos últimos seis meses faltou água na sua casa?" xs={6} sm={4} md={4}>
                <b> {water.lack_water || 'não informado'} </b>
              </Flex>
              <Flex title="Em relação às comunicações sobre interrupções dos serviços de abastecimento de água:" xs={12} sm={4} md={4}>
                <b> {water.water_interruptions || 'não informado'} </b>
              </Flex>
              <Flex title="Como é a pressão da água que chega na sua residência?" xs={12} sm={4} md={4}>
                <b> {water.water_pressure || 'não informado'} </b>
              </Flex>
              <Flex title="Já houve casos de retorno de esgoto na rua residência?" xs={12} sm={4} md={4}>
                <b> {water.sewage_return || 'não informado'} </b>
              </Flex>
              <Flex title="Já sentiu mau cheiro proveniente da rede de esgoto na rua onde sua residência está localizada?" xs={6} sm={4} md={4}>
                <b> {water.bad_smell || 'não informado'} </b>
              </Flex>
              <Flex title="Nos últimos seis meses percebeu vazamento na rede de esgoto próxima de sua residência?" xs={12} sm={4} md={4}>
                <b> {water.leak_runs_out || 'não informado'} </b>
              </Flex>
            </Grid>
            <Grid container spacing={1} justify="space-between">
              <Flex title="Data" xs={6} sm={4} md={4}>
                <b>{formatDateTime(water.created_at)}</b>
              </Flex>
              <Flex title="Gerência" xs={6} sm={4} md={4}>
                <b>{water.managements?.name}</b>
              </Flex>
            </Grid>
          </>
        )}

      </Container>
    </Layout>
  );
};

export default Detail;
