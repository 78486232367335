import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import ModalFactory from 'react-modal-promise';

import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../hooks/http';

/** componentes */
import { ModalDialog } from '../../../components/ModalDialog';
import {
  WrapperLabel, DatePicker, Select, Button, InputHidden,
} from '../../../components/Forms';
import Card from './Card';

import { Container, WrapperForm, WrapperAllocation } from './styles';

import { IUser, IOption } from '../../../interfaces';

interface IFormProfile {
  profile: IUser;
  managements: IOption[];
}

const FormProfile: React.FC<IFormProfile> = ({ profile, managements }) => {
  const formRef = useRef<FormHandles>(null);
  const [allocations, setAllocations] = useState<any>([]);
  const {
    httpPost, httpDelete, loading,
  } = useHttp();

  const handleLocation = useCallback((allocations: any) => {
    const _allocations = allocations?.length
      ? _.orderBy(allocations, ['id'], ['desc'])
      : null;
    setAllocations(_allocations);
  }, []);

  useEffect(() => {
    formRef.current?.reset();

    const { allocations } = profile;
    handleLocation(allocations);

    formRef.current?.setData(profile);
  }, [handleLocation, profile]);

  const handleSubmit = useCallback(async (data: any) => {
    const _allocation = {
      ...data,
      management_id: data.management_id?.value,
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        management_id: Yup.string().ensure().required('a Gerência é obrigatório'),
        started_at: Yup.date().nullable().required('Data de Início é obrigatória'),
      });

      await schema.validate(_allocation, { abortEarly: false });

      const { status, data } = await httpPost('allocations', _allocation);
      if (status === 200) {
        handleLocation(data.allocations);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, []);// eslint-disable-line

  const handleRemoveAllocation = useCallback(async (id: number) => {
    const response = await ModalDialog({
      title: 'Confirma a remoção?',
      content: 'está ação não poderá ser desfeita.',
      buttons: 'cancel_confirm',
    });

    if (response) {
      const { status, data } = await httpDelete(`allocations/${id}`);
      if (status === 200) {
        handleLocation(data.allocations);
      }
    }

  }, [handleLocation]);// eslint-disable-line

  return (
    <Container>
      <WrapperForm>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <InputHidden name="id" />

          <Grid container spacing={1} justify="flex-end">

            <WrapperLabel label="Gerência" xs={12} sm={6} md={6}>
              <Select
                name="management_id"
                options={managements}
              />
            </WrapperLabel>

            <WrapperLabel label="Início" xs={12} sm={6} md={6}>
              <DatePicker
                name="started_at"
                minDate={new Date()}
              />
            </WrapperLabel>

            <Grid item xs={12} md={6}>
              <Button type="submit" isLoading={loading}>Salvar</Button>
            </Grid>

          </Grid>

        </UForm>
      </WrapperForm>
      <WrapperAllocation>
        <span>
          <h1>Histórico das Lotações:</h1>
          { !allocations && <h2>Não há lotações</h2>}
          { allocations
          && allocations
            .map((allocation:any, index:number) => (
              <Card
                loadAllocations={handleLocation}
                handleRemove={handleRemoveAllocation}
                key={allocation.id}
                allocation={{ ...allocation, index }}
              />
            ))}
        </span>
      </WrapperAllocation>
      <ModalFactory />
    </Container>
  );
};

export default FormProfile;
