import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** componentes */
import {
  WrapperLabel, Select, Button, InputHidden, TextArea, ButtonIcon,
} from '../../../components/Forms';
import {
  Container, GridButtons, WrapperBtnInfo, WrapperBtnSubmit,
} from './styles';
import { InfoIcon } from '../../../styles/icons';
import {
  documentation, staystreet, street, streetmotive, forwarding,
} from '../social';

import { IComplement } from '../../../interfaces';

  interface IFormComplement {
    complement: IComplement;
  }

const Complement: React.FC<IFormComplement> = ({ complement }) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const [sOptions] = useState([
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ]);

  const [documentations, setDocumentations] = useState(documentation);
  const [benefits, setBenefits] = useState([]);
  const [staystreets, setStayStreets] = useState(staystreet);
  const [streettime, setStreetTime] = useState(street);
  const [streetmotives, setStreetMotives] = useState(streetmotive);
  const [forwardings, setForwardings] = useState(forwarding);

  const {
    httpPut, loading, httpGet,
  } = useHttp();

  useEffect(() => {
    const documentationsIds = complement?.documentationsIds
      ? complement.documentationsIds.split(/\s*,\s*/)
      : [];

    const documentationsFiltered = documentations.filter((documentations:any) => {
      if (documentationsIds.some((id) => parseInt(id, 10) === documentations.value)) {
        return true;
      }
      return false;
    });

    const benefitsIds = complement?.benefitsIds
      ? complement.benefitsIds.split(/\s*,\s*/)
      : [];

    const benefitsFiltered = benefits.filter((benefits:any) => {
      if (benefitsIds.some((id) => parseInt(id, 10) === benefits.value)) {
        return true;
      }
      return false;
    });

    const stayStreetIds = complement?.stayStreetIds
      ? complement.stayStreetIds.split(/\s*,\s*/)
      : [];

    const stayStreetFiltered = staystreets.filter((staystreets:any) => {
      if (stayStreetIds.some((id) => parseInt(id, 10) === staystreets.value)) {
        return true;
      }
      return false;
    });

    const streetTimeId = complement?.streetTimeId
      ? complement.streetTimeId.split(/\s*,\s*/)
      : [];

    const streetTimeIdFiltered = streettime.filter((streettime:any) => {
      if (streetTimeId.some((id) => parseInt(id, 10) === streettime.value)) {
        return true;
      }
      return false;
    });

    const streetMotiveIds = complement?.streetMotiveIds
      ? complement.streetMotiveIds.split(/\s*,\s*/)
      : [];

    const streetMotiveIdsFiltered = streetmotives.filter((streetmotives:any) => {
      if (streetMotiveIds.some((id) => parseInt(id, 10) === streetmotives.value)) {
        return true;
      }
      return false;
    });

    const forwardingIds = complement?.forwardingIds
      ? complement.forwardingIds.split(/\s*,\s*/)
      : [];

    const forwardingIdsFiltered = forwardings.filter((forwardings:any) => {
      if (forwardingIds.some((id) => parseInt(id, 10) === forwardings.value)) {
        return true;
      }
      return false;
    });

    formRef.current?.setData({
      ...complement,
      benefitsIds: benefitsFiltered,
      stayStreetIds: stayStreetFiltered,
      streetTimeId: streetTimeIdFiltered,
      streetMotiveIds: streetMotiveIdsFiltered,
      documentationsIds: documentationsFiltered,
      welcomed: sOptions.find((welcomed) => welcomed.value === complement?.welcomed),
      requested: sOptions.find((requested) => requested.value === complement?.requested),
      received: sOptions.find((received) => received.value === complement?.received),
      followUpCreas: sOptions
        .find((followUpCreas) => followUpCreas.value === complement?.followUpCreas),
      followUpPop: sOptions.find((followUpPop) => followUpPop.value === complement?.followUpPop),
      forwardingIds: forwardingIdsFiltered,
    });
  }, [complement, benefits, staystreets, streettime, streetmotives,
    documentations, forwardings, sOptions]);

  useEffect(() => {
    const load = async function load():Promise<any> {
      const [respBenefits, respComplements] = await Promise.all([
        httpGet('/benefits', undefined, false),
        httpGet('/complements', undefined, false),
      ]);

      const benfs = respBenefits.data;
      if (benfs) {
        const benefitParsed = benfs
          .map((benefits: any) => ({
            value: benefits.id,
            label: `${benefits.description}`,
          }));
        setBenefits(benefitParsed);
      }

      const compls = respComplements.data;
      if (compls.documentationsIds) {
        const _complsDocumentation = compls.documentationsIds
          .map((core: any) => ({ value: core.documentationsIds }));
        setDocumentations(_complsDocumentation);
      }
      if (compls.stayStreetIds) {
        const _complsStreet = compls.stayStreetIds
          .map((core: any) => ({ value: core.stayStreetIds }));
        setStayStreets(_complsStreet);
      }
      if (compls.streetTimeId) {
        const _complsTime = compls.streetTimeId
          .map((core: any) => ({ value: core.streetTimeId }));
        setStreetTime(_complsTime);
      }
      if (compls.streetMotiveIds) {
        const _complsMotive = compls.streetMotiveIds
          .map((core: any) => ({ value: core.streetMotiveIds }));
        setStreetMotives(_complsMotive);
      }

      if (compls.forwardingIds) {
        const _forwarding = compls.forwardingIds
          .map((core: any) => ({ value: core.forwardingIds }));
        setForwardings(_forwarding);
      }
    };

    load();
  }, [httpGet]);

  const handleSubmit = useCallback(async (data: any) => {
    const _complement = {
      ...data,
      documentationsIds: data.documentationsIds
        ? data.documentationsIds.map((documentationsIds:any) => documentationsIds.value).join(',')
        : null,
      benefitsIds: data.benefitsIds
        ? data.benefitsIds.map((benefitsIds:any) => benefitsIds.value).join(',')
        : null,
      stayStreetIds: data.stayStreetIds?.value,
      streetTimeId: data.streetTimeId?.value,
      streetMotiveIds: data.streetMotiveIds
        ? data.streetMotiveIds.map((streetMotiveIds:any) => streetMotiveIds.value).join(',')
        : null,
      welcomed: data.welcomed?.value,
      requested: data.requested?.value,
      received: data.received?.value,
      followUpCreas: data.followUpCreas?.value,
      followUpPop: data.followUpPop?.value,
      forwardingIds: data.forwardingIds
        ? data.forwardingIds.map((forwardingIds:any) => forwardingIds.value).join(',')
        : null,
    };
    try {
      if (complement.id) {
        const { status } = await httpPut(`complements/${_complement.id}`, _complement);
        if (status === 200) {
          addToast({ title: 'Salvo com sucesso!', type: 'success' });
          history.push('/dashboard');
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
          formRef.current?.setErrors(erros);
      }
    }
    }, [complement]);// eslint-disable-line

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <InputHidden name="id" />
        <Grid container spacing={1}>
          <WrapperLabel label="Documentação" xs={12}>
            <Select
              name="documentationsIds"
              options={documentations}
              isMulti
            />
          </WrapperLabel>
          <WrapperLabel label="Benefícios" xs={12}>
            <Select
              name="benefitsIds"
              options={benefits}
              isMulti
            />
          </WrapperLabel>
          <WrapperLabel label="Permanência na rua/modalidade" xs={12} sm={6} md={6}>
            <Select
              name="stayStreetIds"
              options={staystreets}
            />
          </WrapperLabel>
          <WrapperLabel label="Tempo de permanência na rua" xs={12} sm={6} md={6}>
            <Select
              name="streetTimeId"
              options={streettime}
            />
          </WrapperLabel>
          <WrapperLabel label="Motivo de permanência na rua" xs={12}>
            <Select
              name="streetMotiveIds"
              options={streetmotives}
              isMulti
            />
          </WrapperLabel>

          <WrapperLabel label="Já esteve acolhido?" xs={12} sm={4} md={4}>
            <Select
              name="welcomed"
              options={sOptions}
            />
          </WrapperLabel>
          <WrapperLabel label="Solicitou colhimento?" xs={12} sm={4} md={4}>
            <Select
              name="requested"
              options={sOptions}
            />
          </WrapperLabel>
          <WrapperLabel label="Recebeu acolhimento?" xs={12} sm={4} md={4}>
            <Select
              name="received"
              options={sOptions}
            />
          </WrapperLabel>

          <WrapperLabel label="Faz acompanhamento CREAS?" xs={12}>
            <Select
              name="followUpCreas"
              options={sOptions}
            />
          </WrapperLabel>
          <WrapperLabel label="Faz acompanhamento centro POP?" xs={12}>
            <Select
              name="followUpPop"
              options={sOptions}
            />
          </WrapperLabel>

          <WrapperLabel label="Encaminhamento" xs={12}>
            <Select
              name="forwardingIds"
              options={forwardings}
              isMulti
            />
          </WrapperLabel>

          <WrapperLabel label="Observações" xs={12}>
            <TextArea
              name="note"
            />
          </WrapperLabel>
        </Grid>

        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
            >
              <InfoIcon />
            </ButtonIcon>
          </WrapperBtnInfo>
          <WrapperBtnSubmit>
            <Button
              type="submit"
              isLoading={loading}
            >
              Salvar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>

      </UForm>
    </Container>
  );
};

export default Complement;
