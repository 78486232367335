/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Box, Grid,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import { Form as UForm } from '@unform/web';
import {
  WrapperLabel, DatePicker, ButtonIcon, Button, Select,
} from 'components/Forms';
import { FormHandles } from '@unform/core';
import Drawer from 'components/Drawer';
import { format, isAfter } from 'date-fns';
import * as Yup from 'yup';
import getValidationError from '../../../utils/getValidationsErros';
import HeaderFilterDrawer from '../../../components/Headers/FilterDrawer';
import Pagination from '../../../components/Pagination';
import { useHttp } from '../../../hooks/http';
import Layout from '../../../components/Layout';
import { ArrowBarRightIcon, SupportIcon, TrashIcon } from '../../../styles/icons';
import { IPaginate } from '../../../interfaces';
import { Container, DrawerContent } from './styles';
import Card from './Card';

interface IFilter {
  id: string;
  started_at: Date;
  ended_at: Date;
  management_id: number;
}

const List: React.FC = () => {
  const { httpGet, loading } = useHttp();
  const [supports, setSupports] = useState([]);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [managements, setManagements] = useState([]);
  const formRef = useRef<FormHandles>(null);
  const RefLabelToggle = useRef<HTMLLabelElement>(null);

  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const validateDate = useCallback((startDate: any, endDate: any) => {
    if (!startDate && !endDate) {
      return true;
    }

    if (!startDate || !endDate) {
      return false;
    }

    if (isAfter(new Date(startDate), new Date(endDate))) {
      return false;
    }

    return true;
  }, []);

  const fetchSupports = useCallback(async () => {
    const response = await httpGet('fleetMechanicals', {
      params: {
        filter,
        ...paginate,
      },
    });

    return response.data;
  }, [paginate?.page, filter]);

  useEffect(() => {
    (async () => {
      const { status, data } = await httpGet('/managements/all', undefined, false);
      if (status === 200) {
        const managementParsed = data
          .map((management: any) => ({
            value: management.id,
            label: management.initials,
          }));
        setManagements(managementParsed);
      }
    })();
  }, []);

  const { data, status } = useQuery(['supper', paginate, filter], fetchSupports, {
    refetchOnWindowFocus: false, refetchInterval: 10000, retry: false,
  });

  useEffect(() => {
    if (status === 'success') {
      setSupports(data?.data);
      console.log(data.data);
      setPaginate(data?.pagination);
    }
  }, [data, paginate?.page, filter]);

  const handleSearch = (value: string): void => {
    setPaginate({ ...paginate, page: 1 });
    setFilter({ id: value } as IFilter);
  };

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  const handleSubmit = useCallback(async (payload: any) => {
    try {
      const filter = {
        ...payload,
        id: null,
        page: 1,
        perPage: 10,
        started_at: payload.startDate ? format(payload.startDate, 'yyyy-MM-dd') : null,
        ended_at: payload.endDate ? format(payload.endDate, 'yyyy-MM-dd') : null,
        management_id: payload.management_id ? payload.management_id.value : null,
        status: payload.status ? payload.status.value : null,
      };

      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        startDate: Yup.string().ensure()
          .test(
            'is-valid',
            'a Data Início não pode ser maior que a data de Término.',
            () => validateDate(filter.startDate, filter.endDate),
          ),
        endDate: Yup.string().ensure()
          .test(
            'is-valid',
            'a Data Término não pode ser menor que a data de Início.',
            () => validateDate(filter.startDate, filter.endDate),
          ),

      });
      await schema.validate(filter, { abortEarly: false });
      setFilter(filter);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, []);

  const breadcrumbs = {
    icon: SupportIcon,
    links: [{ path: '', title: 'Suporte Manutencao' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <HeaderFilterDrawer handleSearch={handleSearch} />
        <Drawer>
          <DrawerContent>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <WrapperLabel label="Data Início" xs={12} md={6}>
                  <DatePicker
                    name="startDate"
                    isClearable
                  />
                </WrapperLabel>
                <WrapperLabel label="Data Término" xs={12} md={6}>
                  <DatePicker
                    name="endDate"
                    isClearable
                  />
                </WrapperLabel>

                <WrapperLabel label="Gerência" xs={12} md={6}>
                  <Select
                    name="management_id"
                    isClearable
                    options={managements}
                  />
                </WrapperLabel>
                <WrapperLabel label="Status" xs={12} md={6}>
                  <Select
                    name="status"
                    isClearable
                    options={[
                      { value: 'Aberto', label: 'Aberto' },
                      { value: 'Em andamento', label: 'Em andamento' },
                      { value: 'Encerrado', label: 'Encerrado' },
                    ]}
                  />
                </WrapperLabel>

              </Grid>
              <Grid container spacing={1} justify="space-between" alignItems="baseline">
                <Grid item xs={10} md={4}>
                  <span style={{ display: 'flex', gap: 8, marginTop: 8 }}>
                    <ButtonIcon title="Fechar">
                      <label ref={RefLabelToggle} htmlFor="pure-toggle-right" data-toggle-label="right">
                        <ArrowBarRightIcon />
                      </label>
                    </ButtonIcon>
                    <ButtonIcon
                      title="Limpar"
                      onClick={() => {
                         formRef.current?.reset();
                      }}
                    >
                      <TrashIcon />
                    </ButtonIcon>
                  </span>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Button type="submit" isLoading={loading}> Filtrar </Button>
                </Grid>
              </Grid>
            </UForm>
          </DrawerContent>
        </Drawer>

        <Grid container spacing={3} justify="space-between">
          <Box p={2}><b>Solicitações</b></Box>
          <Box p={2}><b>{supports?.length}</b></Box>
        </Grid>

        {supports?.map((support: any) => (
          <Card support={support} key={support.id} />
        ))}

        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default List;
