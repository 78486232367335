import React from 'react';

/** components */
import CustomTable, { Button, GridColDef, ValueGetterParams } from '../../../../components/CustomTable';

interface IPermission {
  id: number;
  slug: string;
  name: string;
}

interface IGrid {
  permissions: IPermission[];
  handleEdit(id: number): void;
}

const Grid: React.FC<IGrid> = ({ permissions, handleEdit }) => {
  const columns: GridColDef[] = [
    {
      field: 'action',
      headerName: 'Ação',
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      width: 70,
      renderCell: (params: ValueGetterParams) => (
        <Button icon="edit" onClick={() => handleEdit(params.row?.id as number)} />
      ),
    },
    { field: 'name', headerName: 'Nome', width: 300 },
    { field: 'slug', headerName: 'Sigla', width: 300 },
  ];
  return <CustomTable squash="210px" columns={columns} rows={permissions} />;
};

export default Grid;
