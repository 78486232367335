import React from 'react';
import MModal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

import { CloseIcon } from '../../styles/icons';
import { Container, HeaderModal } from './styles';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const Header: React.FC = ({ children }) => (
  <HeaderModal>
    {children}
  </HeaderModal>
);

interface IModal{
  isOpen: boolean;
  handleClose():void;
  children: string | React.ReactNode | React.FC;
  width?: number;
}

const Modal: React.FC<IModal> = ({
  isOpen, children, width = 300, handleClose,
}) => {
  const classes = useStyles();
  return (
    <div>

      <MModal
        open={isOpen}
        onClose={handleClose}
        className={classes.modal}
      >
        <Container width={width}>
          <button
            type="button"
            onClick={handleClose}
          >
            <CloseIcon size={24} />
          </button>
          { children }
        </Container>
      </MModal>
    </div>

  );
};
export default Modal;
