const originOcorrence = [
  { value: 'DENÚNCIA', label: 'DENÚNCIA' },
  { value: 'FLAGRANTE', label: 'FLAGRANTE' },
  { value: 'CONSULTA(NUINTE)', label: 'CONSULTA(NUINTE)' },
  { value: 'SALA DE OPERAÇÕES', label: 'SALA DE OPERAÇÕES' },
  { value: 'CENTRO DE OPERAÇÕES', label: 'CENTRO DE OPERAÇÕES' },
  { value: 'SOLICITAÇÃO AO POLICIAL', label: 'SOLICITAÇÃO AO POLICIAL' },
  { value: 'DEPAROU-SE COM A OCORRÊNCIA', label: 'DEPAROU-SE COM A OCORRÊNCIA' },
];

export default originOcorrence;
