import React from 'react';
import { SubMenu as SubMenuPro } from 'react-pro-sidebar';
import { useAuth } from '../../../hooks/auth';

import { Divider } from './styles';

export interface SubMenuProps {
  can?: string;
  is?: string;
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  defaultOpen?: boolean;
  open?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  firstchild?: boolean;
  popperarrow?: boolean;
  divider?: boolean;
}

const SubMenu: React.FC<SubMenuProps> = ({
  can = '', is = '', divider = false, ...rest
}) => {
  const { iCan, iIs } = useAuth();
  return (
    <>
      { (iCan(can) && iIs(is)) && <SubMenuPro {...rest} />}
      { divider && <Divider />}
    </>
  );
};

export default SubMenu;
