import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Howl } from 'howler';

/** hooks */
import { Box } from '@material-ui/core';
import { useHttp } from '../../../hooks/http';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

/** components */
import Layout from '../../../components/Layout';
import Pagination from '../../../components/Pagination';
import Card from './Card';
import { ListIcon } from '../../../styles/icons';

import {
  Container, WrapperContent,
} from './styles';

/** interfaces */
import { IPaginate, ISupport } from '../../../interfaces';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const beep = require('../../../assets/beep.mp3');

const Technician: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { httpGet, loading } = useHttp();
  const { addToast } = useToast();
  const [supports, setSupports] = useState<ISupport[]>([]);
  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const [sound] = useState(new Howl({ src: [beep] }));

  const breadcrumbs = {
    icon: ListIcon,
    links: [{ path: '', title: `${paginate.total}  Chamados abertos técnico` }],
  };

  useEffect(() => {
    const isAlert = supports.find((support: any) => (
      support.status === 'Em andamento'));

    if (isAlert) {
      sound.play();
      sound.loop(true);
    }

    const timeout = setTimeout(() => {
      sound.stop();
    }, 6500);

    return () => {
      sound.stop();
      clearTimeout(timeout);
    };
  }, [sound, supports, user]);

  const loadOrders = useCallback(async (loading: boolean) => {
    async function load(): Promise<void> {
      const { status, data } = await httpGet(
        'supports/technician',
        { params: paginate },
        loading,
      );
      if (status === 200) {
        setSupports(data?.data);
        setPaginate(data.pagination);
      }
    }
    load();
    }, [httpGet, paginate.page]);// eslint-disable-line

  useEffect(() => {
    loadOrders(true);
      }, [paginate.page]);// eslint-disable-line

  const handleForceLoad = useCallback(() => {
    loadOrders(true);
  }, [loadOrders]);

  useEffect(() => {
    const refresh = setInterval(() => {
      loadOrders(false);
    }, 1000 * 15);
    return () => {
      clearInterval(refresh);
    };
  }, [loadOrders]);

  const handlePage = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  const handleGoToProcess = useCallback(async (id:string) => {
    const supportFiltered: any = supports.filter((support: ISupport) => support.id === id)[0];
    if (supportFiltered.status === 'Encerrado') {
      addToast({ title: 'Este chamado já foi realizado.' });
      return;
    }
    history.push(`/support/technician/${id}`);

  }, [addToast, history, supports, user]);// eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <WrapperContent>
          {supports.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box><h2>Você não tem chamados</h2></Box>
            </Box>
          )}
          {supports.map((support: any) => (
            <Card
              key={support.id}
              support={support}
              handleGoToProcess={handleGoToProcess}
              forceLoad={handleForceLoad}
            />
          ))}
          <Pagination paginate={paginate} handlePaginate={handlePage} />
        </WrapperContent>
      </Container>
    </Layout>

  );
};

export default Technician;
