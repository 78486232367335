import React, { useCallback, useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ValueGetterParams } from '@material-ui/data-grid';

import { useHttp } from '../../../../hooks/http';

import { Container, WrapperConfirm } from './styles';
import { DotVertIcon } from '../../../../styles/icons';

/** interface */
interface IAction {
  params: ValueGetterParams;
  handleRefresh():void;
}

const Actions: React.FC<IAction> = ({ params, handleRefresh }) => {
  const { httpDelete } = useHttp();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [confirm, setConfirm] = useState(false);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  useEffect(() => {
    const refresh = setTimeout(() => {
      setConfirm(false);
    }, 1000 * 5);

    return () => {
      clearTimeout(refresh);
    };
  }, [confirm]);

  const handleRemove = useCallback(async () => {
    if (confirm) {
      const { status } = await httpDelete(`/services/${params?.row?.id}`);
      if (status === 204) {
        handleRefresh();
      }
    }
    setConfirm(!confirm);
  }, [confirm, handleRefresh, httpDelete, params]);

  const handleClose = ():void => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <button type="button" onClick={handleClick}>
        <DotVertIcon />
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleRemove}>
          { !confirm && 'Remover'}
          { confirm && <WrapperConfirm>Confirma?</WrapperConfirm>}
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default Actions;
