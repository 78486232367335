import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { isNetworkError } from '../../services/api';

import getValidationError from '../../utils/getValidationsErros';

/** hooks */
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

/** components */
import { InputWrapped, Button } from '../../components/Forms';
import { UserIcon, LockIcon, WhatsIcon } from '../../styles/icons';

import logoImg from '../../assets/auth-logo.svg';
import {
  Container, Content, Background, AnimationContainer, WrapperInfo, LinkInfo,
} from './styles';

/** interfaces */
interface ISingInFormData {
  document: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data: ISingInFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        document: Yup.string().required('CPF é obrigatório'),
        password: Yup.string().required('Senha é obrigatória'),
      });

      await schema.validate(data, { abortEarly: false });

      formRef.current?.clearField('password');

      setIsLoading(true);
      await signIn({
        document: data.document.replace(/\D/g, ''),
        password: data.password,
      });

      history.push('/dashboard');
    } catch (err) {
      setIsLoading(false);

      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);

        return;
      }

      if (isNetworkError(err)) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Você está sem acesso à internet ou o servidor está off-line.',
        });
        return;
      }

      if (err.response && err.response.status === 429) {
        addToast({
          type: 'error',
          title: 'Opsss!',
          description: 'Você excedeu o limite de requisições para o servidor.',
        });
        return;
      }

      if (err.response && err.response.status === 400) {
        const { message } = Array.isArray(err.response.data)
          ? err.response.data[0]
          : err.response.data;
        addToast({
          type: 'error',
          title: message,
        });
        return;
      }

      addToast({
        title: 'Error',
        type: 'error',
        description: 'Erro ao tentar autenticar na aplicação',
      });
    }

  }, [signIn, history, addToast]);// eslint-disable-line

  return (
    <Container>
      <Background />
      <Content>

        <AnimationContainer>
          <img src={logoImg} alt="Logo" />

          <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
            <h1>Entrar no Sistema</h1>

            <InputWrapped
              name="document"
              mask="999.999.999-99"
              icon={UserIcon}
              type="tel"
              placeholder="digite seu CPF"
            />

            <InputWrapped
              name="password"
              mask=""
              icon={LockIcon}
              type="password"
              placeholder="digite sua senha"
            />

            <Button
              type="submit"
              isLoading={isLoading}
              color="secondary"
              size="large"
            >Entrar
            </Button>
            <Link to="/preview">Não é cadastrado? Recuperar a Senha? Clique aqui</Link>
            <WrapperInfo>
              <h3>Suporte Tecnico</h3>
              <a
                href="https://api.whatsapp.com/send?phone=+5521986052654"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkInfo>
                  <WhatsIcon /><span>(21) 98605-2654</span>
                </LinkInfo>
              </a>

            </WrapperInfo>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignIn;
