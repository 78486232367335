import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
/** hooks */
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHttp } from '../../hooks/http';
import { useAuth } from '../../hooks/auth';
/** Componentes */
import Layout from '../../components/Layout';
import Flex, { Grid } from '../../components/Flex';
import Card, { CardHeader, CardContent } from '../../components/Card';

import {
  WrapperLabel, Select,
} from '../../components/Forms';

import {
  UsersIcon,
} from '../../styles/icons';

/** interfaces */
import { IPoll } from '../../interfaces';

import {
  Container, WrapperPaginate, Date, Content3, Content4,
} from './styles';
import { formatDate } from '../../utils';

const breadcrumbs = {
  icon: UsersIcon,
  links: [
    { title: 'Apurações', path: '' },
  ],
};

const Polls: React.FC = () => {
  const [polls, setPolls] = useState<IPoll[]>([]);
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(12);
  const [lastPage, setLastPage] = React.useState(1);
  const { user } = useAuth();
  const { httpGet, loading } = useHttp();
  const [managements, setManagements] = useState<any>();
  const [man, setMan] = useState();

  useEffect(() => {
    async function loadManagements(): Promise<void> {
      if (!user) {
        return;
      }

      const { status, data } = await httpGet('managements/all');
      if (status === 200) {
        const stations = data
          .map((station: any) => ({
            value: station.id,
            label: `${station.initials} - ${station.name}`,
            is_core: station.is_core,
          }));
        setManagements(stations);
      }
    }

    loadManagements();

  }, [user]);// eslint-disable-line

  const handlePolls = useCallback(async (management_id: any) => {
    setMan(management_id);
    const { value } = management_id;
    const response = await httpGet('polls/management', {
      params: {
        management_id: value,
        page,
        perPage,
      },
    });

    if (response.data) {
      setPolls(response.data.data);
      setPerPage(response.data.pagination.perPage);
      setLastPage(response.data.pagination.lastPage);
    }
  }, [page]); // eslint-disable-line

  useEffect(() => {
    if (man) {
      handlePolls(man);
    }
  }, [page, handlePolls, man]);

  const handlePaginate = (event: React.ChangeEvent<unknown>, value: number): void => {
    setPage(value);
  };

  const handleGoToEdit = (id: string): void => {
    history.push(`/poll/${id}`);
  };

  const handleSubmit = useCallback(async (data: any) => {

  }, []);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>

        <Grid container justify="flex-start" spacing={1}>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Content4>
              <WrapperLabel label="Gerência" xs={12} md={6}>
                <Select name="management_id" options={managements} onChange={handlePolls} />
              </WrapperLabel>
            </Content4>
          </UForm>
          {polls?.map((poll) => (
            <Grid item key={poll.id} xs={12} sm={6} md={6}>
              <Content3 onClick={() => { handleGoToEdit(poll.id); }}>
                <Card>

                  <CardHeader>
                    <Date>{formatDate(String(poll.date_poll).split('T')[0])}</Date>
                  </CardHeader>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Flex title="Vagas Disponíveis" xs={6} sm={6} md={3}>
                        {poll.available_vacancies}
                      </Flex>
                      <Flex title="Vagas Preenchidas" xs={6} sm={6} md={3}>
                        {poll.vacancies_filled}
                      </Flex>
                      <Flex title="Faltas" xs={6} sm={6} md={3}>
                        {poll.absences}
                      </Flex>
                      <Flex title="Vagas Ociosas" xs={6} sm={6} md={3}>
                        {poll.available_vacancies - (poll.vacancies_filled - poll.absences)}
                      </Flex>
                    </Grid>
                  </CardContent>

                </Card>
              </Content3>
            </Grid>
          ))}
        </Grid>
        <WrapperPaginate>
          <Pagination count={lastPage} page={page} onChange={handlePaginate} />
        </WrapperPaginate>
      </Container>
    </Layout>
  );
};

export default Polls;
