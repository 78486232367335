import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
/** hooks */
import * as Yup from 'yup';
import { Content } from 'components/Forms/WrapperLabel/styles';
import { useAuth } from 'hooks/auth';

import { useToast } from 'hooks/toast';
import Simple from 'components/Headers/Simple';
import getCurrentPosition from 'utils/getCurrentPosition';
import getValidationError from '../../../utils/getValidationsErros';
import {
  Container,
  GridButtons,
  WrapperBtnInfo,
  WrapperBtnCancel,
  WrapperBtnSubmit,
  Text,
  WrapperRemove,
} from './styles';
import { InfoIcon, TrashIcon } from '../../../styles/icons';
import {
  WrapperLabel, Select, Button, InputHidden, ButtonIcon, Input, InputMask,
} from '../../../components/Forms';
import { useHttp } from '../../../hooks/http';

interface IFormData {
  coord?: {
    lat: number,
    lng: number
  }
}

const FormWater: React.FC<IFormData> = ({ coord }) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [municipio, setMunicipio] = useState([]);
  const [disabledCity, setDisabledCity] = useState(false);
  const [disabledNotWater, setDisabledNotWater] = useState(false);

  const { loading, httpGet, httpPost } = useHttp();
  const { user, refreshUser } = useAuth();
  const { addToast } = useToast();
  const prefix = `@${process.env.REACT_APP_NAME}`;

  const handleDelete = useCallback(() => {
    try {
      const userStore = localStorage.getItem(`${prefix}:user`);
      if (userStore) {
        const userParsed = JSON.parse(userStore);
        delete (userParsed.action);
        localStorage.setItem(`${prefix}:user`, JSON.stringify(userParsed));
        refreshUser();
        history.push('/dashboard');
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [prefix, refreshUser, history]);

  useEffect(() => {
    async function loadDitricts(): Promise<any> {
      const { status, data } = await httpGet('https://servicodados.ibge.gov.br/api/v1/localidades/estados/33/municipios');
      if (status === 200) {
        const municipioParsed = data
          .map((municipio: any) => ({
            value: municipio.nome,
            label: municipio.nome,
          }));
        setMunicipio(municipioParsed);
      }
    }

    loadDitricts();
  }, [httpGet]);

  useEffect(() => {
    formRef.current?.setData({});
  }, []);

  const handleSubmit = useCallback(async (data: any) => {
    const _ceperj = {
      ...data,
      ...coord,
      gender: data.gender?.value,
      city: data.city?.value,
      lived_city: data.lived_city?.value,
      long_lived_city: data.long_lived_city?.value,
      residence: data.residence?.value,
      ethnicity: data.ethnicity?.value,
      marital: data.marital?.value,
      schooling: data.schooling?.value,
      currently: data.currently?.value,
      income: data.income?.value,
      management_id: user?.allocation?.management_id,
      receive_running: data.receive_running?.value,
      attended_collection: data.attended_collection?.value,
      received_water: data.received_water?.value,
      lack_water: data.lack_water?.value,
      water_interruptions: data.water_interruptions?.value,
      water_pressure: data.water_pressure?.value,
      sewage_return: data.sewage_return?.value,
      bad_smell: data.bad_smell?.value,
      leak_runs_out: data.leak_runs_out?.value,
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        city: Yup.string().required('Município é obrigatório'),
        name: Yup.string().ensure().required('Nome é obrigatório'),
        gender: Yup.string().ensure().required('Gênero é obrigatório'),
        age: Yup.number().typeError('Idade é obrigatório, somente números'),
        lived_city: Yup.string().required('Reside neste Município é obrigatório'),
        long_lived_city: Yup.string().when('lived_city', {
          is: (lived_city) => lived_city === 'Sim', then: Yup.string().ensure().required('Quanto anos você reside neste município é obrigatório'),
        }),
        residence: Yup.string().ensure().required('Seu domicílio é obrigatório'),
        ethnicity: Yup.string().ensure().required('Raça/cor/etnia é obrigatório'),
        marital: Yup.string().ensure().required('Estado civil é obrigatório'),
        schooling: Yup.string().ensure().required('Escolaridade é obrigatório'),
        currently: Yup.string().ensure().required('Atualmente é obrigatório'),
        income: Yup.string().ensure().required('Renda é obrigatório'),
        receive_running: Yup.string().ensure().required('Sua residência recebe água encanada fornecida por concessionária... é obrigatório'),

        attended_collection: Yup.string().when('receive_running', {
          is: (receive_running) => receive_running === 'Sim', then: Yup.string().ensure().required('Sua residência é atendida por serviço... é obrigatório'),
        }),
        received_water: Yup.string().when('receive_running', {
          is: (receive_running) => receive_running === 'Sim', then: Yup.string().ensure().required('Em relação à água recebida em sua casa... é obrigatório'),
        }),
        lack_water: Yup.string().when('receive_running', {
          is: (receive_running) => receive_running === 'Sim', then: Yup.string().ensure().required('Nos últimos seis meses faltou água na sua casa é obrigatório'),
        }),
        water_interruptions: Yup.string().when('receive_running', {
          is: (receive_running) => receive_running === 'Sim', then: Yup.string().ensure().required('Em relação às comunicações sobre interrupções... é obrigatório'),
        }),
        water_pressure: Yup.string().when('receive_running', {
          is: (receive_running) => receive_running === 'Sim', then: Yup.string().ensure().required('Como é a pressão da água que chega na sua residência... é obrigatório'),
        }),
        sewage_return: Yup.string().when('receive_running', {
          is: (receive_running) => receive_running === 'Sim', then: Yup.string().ensure().required('Já houve casos de retorno de esgoto na rua residência? é obrigatório'),
        }),
        bad_smell: Yup.string().when('receive_running', {
          is: (receive_running) => receive_running === 'Sim', then: Yup.string().ensure().required('Já sentiu mau cheiro proveniente da rede de esgoto é obrigatório.'),
        }),
        leak_runs_out: Yup.string().when('receive_running', {
          is: (receive_running) => receive_running === 'Sim', then: Yup.string().ensure().required('Nos últimos seis meses percebeu vazamento é obrigatório'),
        }),
      });
      await schema.validate(_ceperj, { abortEarly: false });

      const position = await getCurrentPosition();

      const { status } = await httpPost('waterqualities', {
        ..._ceperj,
        current_lat: position.lat,
        current_lng: position.lng,
      });
      if (status === 200) {
        addToast({ title: 'Salvo com sucesso!', type: 'success' });
        history.push('/dashboard');
      } else {
        addToast({ title: 'Não foi possível gravar!', type: 'error' });
        history.push('/dashboard');
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [coord, user, addToast, history, httpPost]);

  const handleFieldCity = useCallback((lived_city: any) => {
    setDisabledCity(lived_city?.value === 'Não');
  }, []);

  const handleFieldReceiveRunning = useCallback((receive_running: any) => {
    setDisabledNotWater(receive_running?.value === 'Não');
  }, []);

  return (
    <>
      <Content>
        <Simple>
          <Text>{user?.action?.name}</Text>
          <WrapperRemove onClick={handleDelete}>
            <ButtonIcon
              title="Excluir"
            >
              <TrashIcon color="white" />
            </ButtonIcon>
          </WrapperRemove>
        </Simple>
      </Content>
      <Container>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <InputHidden name="id" defaultValue={user?.action?.id} />
            <WrapperLabel label="Nome do entrevistado" xs={12} sm={6}>
              <Input name="name" />
            </WrapperLabel>
            <WrapperLabel label="Município do entrevistado" xs={12} md={6} sm={6}>
              <Select
                name="city"
                options={municipio}
              />
            </WrapperLabel>
            <WrapperLabel label="Gênero" xs={12} sm={6} md={6}>
              <Select
                name="gender"
                options={[
                  { value: 'Masculino', label: 'Masculino' },
                  { value: 'Feminino', label: 'Feminino' },
                ]}
              />
            </WrapperLabel>
            <WrapperLabel label="Idade" xs={12} sm={6} md={6}>
              <InputMask name="age" type="tel" mask="99" placeholder="Somente números" />
            </WrapperLabel>
            <WrapperLabel label="Reside neste Município" xs={12} md={6} sm={6}>
              <Select
                name="lived_city"
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Não', label: 'Não' },
                ]}
                onChange={handleFieldCity}
              />
            </WrapperLabel>
            <WrapperLabel label="Há quantos anos você reside neste Município?" xs={12} md={6} sm={6}>
              <Select
                name="long_lived_city"
                options={[
                  { value: 'Menos de 1 ano', label: 'Menos de 1 ano' },
                  { value: 'Entre 1 e 5 anos', label: 'Entre 1 e 5 anos' },
                  { value: 'Entre 6 e 10 anos', label: 'Entre 6 e 10 anos' },
                  { value: 'Mais de 10 anos', label: 'Mais de 10 anos' },
                ]}
                isDisabled={disabledCity}
              />
            </WrapperLabel>

            <WrapperLabel label="Seu domicílio é?" xs={12} md={6} sm={6}>
              <Select
                name="residence"
                options={[
                  { value: 'Próprio quiado', label: 'Próprio quiado' },
                  { value: 'Próprio não quiado', label: 'Próprio não quiado' },
                  { value: 'Alugado', label: 'Alugado' },
                  { value: 'Cedido', label: 'Cedido' },
                  { value: 'Outra condição', label: 'Outra condição' },
                ]}
              />
            </WrapperLabel>

            <WrapperLabel label="Qual sua cor/raça/etnia?" xs={12} md={6} sm={6}>
              <Select
                name="ethnicity"
                options={[
                  { value: 'Preta', label: 'Preta' },
                  { value: 'Parda', label: 'Parda' },
                  { value: 'Branca', label: 'Branca' },
                  { value: 'Indígena', label: 'Indígena' },
                  { value: 'Amarela', label: 'Amarela' },
                ]}
              />
            </WrapperLabel>

            <WrapperLabel label="Qual seu estado civil?" xs={12} md={6} sm={6}>
              <Select
                name="marital"
                options={[
                  { value: 'Solteiro(a)', label: 'Solteiro(a)' },
                  { value: 'Casado(a)', label: 'Casado(a)' },
                  { value: 'Divorciado(a)', label: 'Divorciado(a)' },
                  { value: 'Separado(a)/Desquitado(a)', label: 'Separado(a)/Desquitado(a)' },
                  { value: 'Viúvo(a)', label: 'Viúvo(a)' },
                  { value: 'Em união estável', label: 'Em união estável' },
                  { value: 'Morando junto', label: 'Morando junto' },
                ]}
              />
            </WrapperLabel>

            <WrapperLabel label="Escolaridade?" xs={12} md={6} sm={6}>
              <Select
                name="schooling"
                options={[
                  { value: 'Não sabe ler/escrever', label: 'Não sabe ler/escrever' },
                  { value: 'Ensino fundamental incompleto', label: 'Ensino fundamental incompleto' },
                  { value: 'Ensino fundamental completo', label: 'Ensino fundamental completo' },
                  { value: 'Ensino médio incompleto', label: 'Ensino médio incompleto' },
                  { value: 'Ensino médio completo', label: 'Ensino médio completo' },
                  { value: 'Ensino superior incompleto', label: 'Ensino superior incompleto' },
                  { value: 'Ensino superior completo(mestrado/doutorado/pós', label: 'Ensino superior completo(mestrado/doutorado/pós' },
                ]}
              />
            </WrapperLabel>

            <WrapperLabel label="Atualmente você?" xs={12} md={6} sm={6}>
              <Select
                name="currently"
                options={[
                  { value: 'Trabalha', label: 'Trabalha' },
                  { value: 'Estuda', label: 'Estuda' },
                  { value: 'Aposentado(a)/pensionista', label: 'Aposentado(a)/pensionista' },
                  { value: 'Do lar', label: 'Do lar' },
                ]}
              />
            </WrapperLabel>

            <WrapperLabel label="Qual a sua renda individual aproximada?" xs={12} md={6} sm={6}>
              <Select
                name="income"
                options={[
                  { value: 'Menos que um salário mínimo', label: 'Menos que um salário mínimo' },
                  { value: 'Um salário mínimo', label: 'Um salário mínimo' },
                  { value: 'Dois salários mínimos', label: 'Dois salários mínimos' },
                  { value: 'Três ou mais salários mínimos', label: 'Três ou mais salários mínimos' },
                  { value: 'Não informada', label: 'Não informada' },
                ]}
              />
            </WrapperLabel>

          </Grid>
          <Grid container spacing={1}>
            <WrapperLabel
              label="Sua residência recebe água encanada fornecida por concessionária de serviços públicos(distribuida
                            por meio de tubulações e encanamentos)?"
              xs={12}
              md={6}
              sm={6}
            >
              <Select
                name="receive_running"
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Não', label: 'Não' },
                ]}
                onChange={handleFieldReceiveRunning}
              />
            </WrapperLabel>
            <WrapperLabel
              label="Sua residência é atendida por serviço de rede
                        de coleta de esgoto prestado por concessionária de serviços públicos?"
              xs={12}
              md={6}
              sm={6}
            >
              <Select
                name="attended_collection"
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Não', label: 'Não' },
                ]}
                isDisabled={disabledNotWater}
              />
            </WrapperLabel>
            <WrapperLabel label="Em relação à água recebida em sua casa, você pode nos informar se:" xs={12} md={6} sm={6}>
              <Select
                name="received_water"
                options={[
                  { value: 'Ela chega normal...', label: 'Ela chega normal, sem cheiro, gosto, cor ou impurezas.' },
                  { value: 'Raríssimas vezes...', label: 'Raríssimas vezes ele chega com gosto, cheiro, cor ou impurezas na água recebida.' },
                  { value: 'Algumas vezes ela chega com gosto...', label: 'Algumas vezes ela chega com gosto, cheiro cor ou impurezas na água recebida.' },
                  { value: 'Ela chega com gosto...', label: 'Ela chega com gosto, cheiro, cor e impurezas.' },
                ]}
                isDisabled={disabledNotWater}
              />
            </WrapperLabel>
            <WrapperLabel label="Nos últimos seis meses faltou água na sua casa?" xs={12} md={6} sm={6}>
              <Select
                name="lack_water"
                options={[
                  { value: 'Sim. Houve falta de água', label: 'Sim. Houve falta de água.' },
                  { value: 'Não. Nenhuma vez', label: 'Não. Nenhuma vez.' },
                  { value: 'Não me recordo', label: 'Não me recordo.' },
                ]}
                isDisabled={disabledNotWater}
              />
            </WrapperLabel>
            <WrapperLabel label="Em relação às comunicações sobre interrupções dos serviços de abastecimento de água:" xs={12} md={6} sm={6}>
              <Select
                name="water_interruptions"
                options={[
                  { value: 'Sempre sou notificado(a) com antecedência', label: 'Sempre sou notificado(a) com antecedência.' },
                  { value: 'Sou notificado(a) com antecedência, na maioria das vezes', label: 'Sou notificado(a) com antecedência, na maioria das vezes.' },
                  { value: 'Sou notificado(a) com antecedência, algumas das vezes', label: 'Sou notificado(a) com antecedência, algumas das vezes.' },
                  { value: 'Nunca sou notificado(a) a tempo.', label: 'Nunca sou notificado(a) a tempo.' },
                  { value: 'Não soube responder', label: 'Não soube responder.' },
                ]}
                isDisabled={disabledNotWater}
              />
            </WrapperLabel>
            <WrapperLabel label="Como é a pressão da água que chega na sua residência?" xs={12} md={6} sm={6}>
              <Select
                name="water_pressure"
                options={[
                  { value: 'Forte', label: 'Forte.' },
                  { value: 'Normal', label: 'Normal.' },
                  { value: 'Um pouco fraca', label: 'Um pouco fraca.' },
                  { value: 'Muito fraca', label: 'Muito fraca.' },
                ]}
                isDisabled={disabledNotWater}
              />
            </WrapperLabel>
            <WrapperLabel label="Já houve casos de retorno de esgoto na rua residência?" xs={12} md={6} sm={6}>
              <Select
                name="sewage_return"
                options={[
                  { value: 'Não. Nunca houve', label: 'Não. Nunca houve.' },
                  { value: 'Sim. Raríssimas vezes', label: 'Sim. Raríssimas vezes.' },
                  { value: 'Sim. Algumas vezes', label: 'Sim. Algumas vezes.' },
                  { value: 'Não me recordo', label: 'Não me recordo.' },
                ]}
                isDisabled={disabledNotWater}
              />
            </WrapperLabel>
            <WrapperLabel label="Já sentiu mau cheiro proveniente da rede de esgoto na rua onde sua residência está localizada?" xs={12} md={6} sm={6}>
              <Select
                name="bad_smell"
                options={[
                  { value: 'Não. Nunca senti', label: 'Não. Nunca senti.' },
                  { value: 'Sim. Raríssimas vezes', label: 'Sim. Raríssimas vezes.' },
                  { value: 'Sim. Algumas vezes', label: 'Sim. Algumas vezes.' },
                  { value: 'Não me recordo', label: 'Não me recordo.' },
                ]}
                isDisabled={disabledNotWater}
              />
            </WrapperLabel>
            <WrapperLabel label="Nos últimos seis meses percebeu vazamento na rede de esgoto próxima de sua residência?" xs={12} md={6} sm={6}>
              <Select
                name="leak_runs_out"
                options={[
                  { value: 'Sim', label: 'Sim.' },
                  { value: 'Não', label: 'Não.' },
                  { value: 'Não me recordo', label: 'Não me recordo.' },
                ]}
                isDisabled={disabledNotWater}
              />
            </WrapperLabel>
          </Grid>
          <GridButtons>
            <WrapperBtnInfo>
              <ButtonIcon
                title="Instruções"
              >
                <InfoIcon />
              </ButtonIcon>
            </WrapperBtnInfo>
            <WrapperBtnCancel>
              <Button
                type="button"
                onClick={() => { history.push('/dashboard'); }}
              >
                Cancelar
              </Button>
            </WrapperBtnCancel>
            <WrapperBtnSubmit>
              <Button
                type="submit"
                isLoading={loading}
              >
                Salvar
              </Button>
            </WrapperBtnSubmit>
          </GridButtons>
        </UForm>
      </Container>
    </>
  );
};

export default FormWater;
