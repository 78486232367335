import React, {
  useRef, useCallback, useEffect, useState,
} from 'react';
import Grid from '@material-ui/core/Grid';
import GoogleMapReact from 'google-map-react';

import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import getValidationError from '../../../utils/getValidationsErros';

/** services */
import searchZipToAddress from '../services/searchZipToAddress';
import resolveCoordToAddress from '../services/resolveCoordToAddress';
import getCurrentPosition from '../../../utils/getCurrentPosition';
/** hooks */
import { useHttp } from '../../../hooks/http';

/** componentes */
import {
  WrapperLabel, Input, InputMask, Button, ButtonIcon, InputHidden,
} from '../../Forms';
import { AddIcon, MapIcon, TargetIcon } from '../../../styles/icons';

/** interfaces */
import { IAddress, IFuncVoid } from '../../../interfaces';

import {
  Container, WrapperMap, WrapperTarget, WrapperButtonFooter, WrapperButton, WrapperSaveButton,
} from './styles';

interface IForm{
  address?: IAddress;
  person_id?: string | number;
  handleClose?: IFuncVoid;
  forceLoad?: IFuncVoid;
}

interface ICoord {
  lat: number;
  lng: number;
}

const Form: React.FC<IForm> = ({
  handleClose, forceLoad, address,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [isShowMap, setIsShowMap] = useState(false);
  const [isLoadingPosition, setIsLoadingPosition] = useState(false);
  const [coord, setCoord] = useState({} as ICoord);
  const [defaultCenter, setDefaultCenter] = useState(
    {
      lat: address?.latitude ? parseFloat(address.latitude.toString()) : -15.985219,
      lng: address?.longitude ? parseFloat(address.longitude.toString()) : -47.781060,
      zoon: address?.latitude ? 18 : 4,
    },
  );
  const { httpPost, httpPut, loading } = useHttp();

  useEffect(() => {
    if (address) {
      formRef.current?.setData(address);
    }
  }, [address]);

  const handleZipBlur = async (event: React.FocusEvent<HTMLInputElement>):Promise<void> => {
    const { value } = event.target;
    if (value.length > 8) {
      const zip = await searchZipToAddress(value);
      if (zip) {
        formRef.current?.setData(zip);
      }
    }
  };

  const handleMap = async (): Promise<void> => {
    const _address = await resolveCoordToAddress(coord);
    if (_address) {
      formRef.current?.setData(_address);
    }
    setIsShowMap(false);
  };

  const handleMapOpen = ():void => {
    setIsShowMap(true);
  };

  const handleSubmit = useCallback(async (data: IAddress) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
        zip: Yup.string().matches(/([0-9]{5}-[0-9]{3})/, { excludeEmptyString: true, message: 'O CEP não está em um formato válido' }),
        street: Yup.string(),
        number: Yup.string(),
        complement: Yup.string(),
        neighborhood: Yup.string(),
        city: Yup.string().min(4, 'informe uma cidade válida').required('a cidade é obrigatória'),
        state: Yup.string().min(2, 'informe as duas letas da abreviação do Estado').required('o Estado é obrigatório'),
        reference: Yup.string(),
        latitude: Yup.string()
          .matches(/-?[0-9]{1,2}[.][0-9]*/, { excludeEmptyString: true, message: 'A latitude não está em um formato válido. As casas decimais deve ser separaras por ponto Ex.: -43.123456' }),
        longitude: Yup.string()
          .matches(/-?[0-9]{1,2}[.][0-9]*/, { excludeEmptyString: true, message: 'A longitude não está em um formato válido. As casas decimais deve ser separaras por ponto Ex.: -43.123456' }),

      });

      await schema.validate(data, { abortEarly: false });

      if (address?.id) {
        const { status } = await httpPut(`addresses/${address.id}`, data);
        if (status === 200) {
          if (forceLoad) forceLoad();
          if (handleClose) handleClose();
        }
      }

      if (!address?.id) {
        const { status } = await httpPost('addresses', DataTransferItemList);
        if (status === 200) {
          if (forceLoad) forceLoad();
          if (handleClose) handleClose();
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [address, forceLoad, handleClose, httpPost, httpPut]);

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <InputHidden name="id" />
        <Grid container spacing={1}>
          <WrapperLabel label="Nome do endereço" xs={12} md={4}>
            <Input name="name" />
          </WrapperLabel>
          <WrapperLabel label="CEP" xs={12} md={4}>
            <InputMask name="zip" onBlur={handleZipBlur} mask="99999-999" />
          </WrapperLabel>
          <Grid item xs={12} md={4}>
            <WrapperButton>

              <ButtonIcon
                title="Abrir mapa"
                onClick={handleMapOpen}
              >
                <MapIcon size={18} />
              </ButtonIcon>

              <ButtonIcon
                title="Obter minha localização"
                isLoading={isLoadingPosition}
                onClick={async () => {
                  setIsLoadingPosition(true);
                  const _coord = await getCurrentPosition();
                  setIsLoadingPosition(false);
                  setDefaultCenter({ ..._coord, zoon: 18 });
                  setIsShowMap(true);
                }}
              >
                <TargetIcon size={18} />
              </ButtonIcon>

            </WrapperButton>

          </Grid>
          <WrapperLabel label="Logradouro" xs={12}>
            <Input name="street" />
          </WrapperLabel>
          <WrapperLabel label="Número" xs={12} md={2}>
            <Input name="number" />
          </WrapperLabel>
          <WrapperLabel label="Complemento" xs={12} md={10}>
            <Input name="complement" />
          </WrapperLabel>
          <WrapperLabel label="Bairro" xs={12} md={6}>
            <Input name="neighborhood" />
          </WrapperLabel>
          <WrapperLabel label="Cidade" xs={12} md={4}>
            <Input name="city" />
          </WrapperLabel>
          <WrapperLabel label="Estado" xs={12} md={2}>
            <Input name="state" maxLength={2} />
          </WrapperLabel>
          <WrapperLabel label="Referência" xs={12} md={6}>
            <Input name="reference" />
          </WrapperLabel>
          <WrapperLabel label="Latitude" xs={12} md={3}>
            <Input
              name="latitude"
              maxLength={10}
            />
          </WrapperLabel>
          <WrapperLabel label="Longitude" xs={12} md={3}>
            <Input
              name="longitude"
              maxLength={10}
            />
          </WrapperLabel>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item xs={12} md={4}>
            <WrapperSaveButton>
              <Button isLoading={loading} type="submit">Salvar</Button>
            </WrapperSaveButton>
          </Grid>
        </Grid>
      </UForm>
      {isShowMap && (
      <>
        <WrapperMap>
          <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_MAP_KEY}` }}
              defaultCenter={defaultCenter}
              defaultZoom={defaultCenter.zoon}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false,
                maxZoom: 18,
                minZoom: 4,
              }}
              onChange={(value) => {
                setCoord(value.center);
              }}
            />
          </div>
          <WrapperTarget>
            <AddIcon size={32} />
          </WrapperTarget>

        </WrapperMap>
        <WrapperButtonFooter>
          <Button color="secondary" onClick={() => { setIsShowMap(false); }}>Cancelar</Button>
          <Button onClick={handleMap}> Ok </Button>
        </WrapperButtonFooter>
      </>
      )}

    </Container>
  );
};

export default Form;
