import React, {
  useCallback,
  useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';

/** hooks */
import { useHttp } from '../../../hooks/http';

/**  componentes */
import Layout from '../../../components/Layout';
import VerticalTabs, { TabPanel } from '../../../components/VerticalTabs';
import Modal from '../../../components/Modal';
import { Button, ButtonIcon } from '../../../components/Forms';
import { PencilIcon, GearIcon } from '../../../styles/icons';
import Permission from './Permissions';
import PermissionGrid from './Permissions/Grid';

/** interfaces */
import { IRole } from '../../../interfaces';

import { Container, Card, WrapperRoles } from './styles';
import { WrapperGrid } from './Permissions/styles';

interface IRoleForm extends IRole{
  permissions?: IRole[];
}

const breadcrumbs = {
  icon: GearIcon,
  links: [
    { title: 'ACL - Access-control list', path: '' },
  ],
};

const Form: React.FC = () => {
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [forceLoad, setForceLoad] = useState(1);
  const [permissions, setPermissions] = useState([]);
  const [permission, setPermission] = useState({} as IRole);

  const { httpGet, loading } = useHttp();

  useEffect(() => {
    async function loadRoles(): Promise<void> {
      const [responseRoles, responsePermissions] = await Promise.all([
        httpGet('/roles'),
        httpGet('/permissions'),
      ]);

      setRoles(responseRoles.data);
      setPermissions(responsePermissions.data);
    }
    loadRoles();
  }, [forceLoad, httpGet]);

  const [activeTab, setActiveTab] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setActiveTab(newValue);
  };

  const [isModalPermissionOpen, setIsModalPermissionOpen] = useState(false);
  const handleModalPermissionClose = (): void => {
    setIsModalPermissionOpen(false);
  };

  const handleEdit = useCallback((id: number) => {
    history.push(`/acl-rule/${id}`);
  }, [history]);

  const handlePermissionEdit = useCallback((id: number) => {
    const permissionFiltered = permissions.find((item: IRole) => item.id === id);
    if (permissionFiltered) {
      setPermission(permissionFiltered);
      setIsModalPermissionOpen(true);
    }
  }, [permissions]);

  const handleAddRole = useCallback(() => {
    history.push('/acl-rule');
  }, [history]);

  const handleAddPermission = useCallback(() => {
    setPermission({} as IRoleForm);
    setIsModalPermissionOpen(true);
  }, []);

  const handleForceLoad = useCallback(() => {
    setForceLoad((oldState:any) => (oldState + 1));
  }, []);

  return (
    <Layout loading={loading} breadcrumbs={breadcrumbs}>

      <Modal isOpen={isModalPermissionOpen} handleClose={handleModalPermissionClose} width={600}>
        <Permission
          permission={permission}
          forceLoad={handleForceLoad}
          handleClose={handleModalPermissionClose}
        />
      </Modal>

      <Container>

        <VerticalTabs
          titles={['Roles', 'Permissions']}
          activeTab={activeTab}
          handleActiveTab={handleChange}
        >
          <TabPanel index={0} activeTab={activeTab}>
            <Button onClick={handleAddRole}>Add Role</Button>
            <WrapperRoles>
              <Grid container spacing={2}>
                { roles && roles.map((_role: any) => (
                  <Grid key={_role.id} item xs={12}>
                    <Card>
                      <header>
                        <h1>{ _role.name } <small>{ _role.slug }</small></h1>
                        <ButtonIcon
                          color="transparent"
                          placement="left"
                          title="Editar"
                          onClick={() => handleEdit(_role.id)}
                        >
                          <PencilIcon />
                        </ButtonIcon>
                      </header>
                      <ul>
                        { _role.permissions && _role.permissions.map((_permission: any) => (
                          <li key={_permission.id}> { _permission.name } </li>
                        ))}
                      </ul>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </WrapperRoles>
          </TabPanel>

          <TabPanel index={1} activeTab={activeTab}>
            <Button onClick={handleAddPermission}>Add Permission</Button>
            <WrapperGrid>
              <PermissionGrid
                permissions={permissions}
                handleEdit={handlePermissionEdit}
              />
            </WrapperGrid>
          </TabPanel>
        </VerticalTabs>
      </Container>

    </Layout>

  );
};

export default Form;
