import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const formatDateTime = (date: string | number | Date): string => {
  if (typeof date === 'string') {
    const utcDate = zonedTimeToUtc(date, 'UTC');
    const znDate = utcToZonedTime(utcDate, 'America/Sao_Paulo');
    if (typeof date === 'string') {
      return format(znDate, 'dd/MM/yyyy HH:mm');
    }
  }
  return 'erro';
};

export default formatDateTime;
