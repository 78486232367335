import Layout from 'components/Layout';
import { useAuth } from 'hooks/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { GoogleclassroomIcon } from 'styles/icons';
import { Button } from 'components/Forms';
import { useHistory } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import { useHttp } from 'hooks/http';
import HeaderSavePlus from '../../../components/Headers/SavePlus';
import { quiz } from '../data/quiz';

import {
  Container, Content, Display, Header, Item, Text, Box, GridButtons, Success,
} from './styles';

const Assessment: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const { httpGet, httpPut, loading } = useHttp();
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<boolean>();
  const [showResult, setShowResult] = useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [questionCorrect, setQuestionCorrect] = useState<any>([]);
  const [resp, setResp] = useState<any>();
  const [result, setResult] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });

  const load = useCallback(async () => {
    const { status, data } = await httpGet('userclassrooms');
    if (status === 200) {
      if (data.length < 8) {
        addToast({ title: 'Conclua as aulas primeiro!', type: 'info' });
        history.push('/dashboard');
      }
      const { questions } = quiz;
      const elemento = questions[Math.floor(Math.random() * questions.length)];
      setQuestionCorrect(elemento);
    }
  }, [addToast, history, httpGet]);

  useEffect(() => {
    load();
  }, [activeQuestion, showResult, load]);

  const onClickNext = useCallback(async (question: any) => {
    console.log(question, resp);
    setSelectedAnswerIndex(null);
    setResult((prev) => (selectedAnswer
      ? {
        ...prev,
        score: prev.score + 5,
        correctAnswers: prev.correctAnswers + 1,
      }
      : { ...prev, wrongAnswers: prev.wrongAnswers + 1 }));
    if (activeQuestion !== 9) {
      setActiveQuestion((prev) => prev + 1);
    } else {
      if (result.correctAnswers >= 7) {
        const response = await httpPut('userclassrooms', { user });
        if (response.status !== 204) {
          console.log({ title: 'Não foi possivel gravar!', type: 'error' });
        }
      }
      setActiveQuestion(0);
      setShowResult(true);
    }
  }, [resp, activeQuestion, selectedAnswer, result.correctAnswers, httpPut, user]);

  const onAnswerSelected = (answer: any, index: any): void => {
    setResp(answer);
    setSelectedAnswerIndex(index);
    if (answer === questionCorrect?.correctAnswer) {
      setSelectedAnswer(true);
    } else {
      setSelectedAnswer(false);
    }
  };

  const addLeadingZero = (number: any): any => (number > 9 ? number : `0${number}`);

  const restart = (): void => {
    setResult({
      score: 0,
      correctAnswers: 0,
      wrongAnswers: 0,
    });
    setShowResult(false);
    setActiveQuestion(0);
    setQuestionCorrect([]);
  };

  const breadcrumbs = {
    icon: GoogleclassroomIcon,
    links: [
      { title: 'Capacitação avaliaçao', path: '' },
    ],
  };
  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderSavePlus
          hiddenButton
          handlePlus={() => {}}
        >
          NOME - {user?.name}
        </HeaderSavePlus>
        <Content>
          {!showResult ? (
            <Display>
              <h4>
                <span>{addLeadingZero(activeQuestion + 1)}</span>
                <span>/{addLeadingZero(10)}</span>
              </h4>
              <Box>{questionCorrect?.question}</Box>
              {questionCorrect?.choices?.map((answer: any, index: any) => (
                <Item
                  onClick={() => onAnswerSelected(answer, index)}
                  key={answer}
                  isWarner={selectedAnswerIndex === index}
                >
                  {answer}
                </Item>
              ))}
              <div>
                <Button onClick={() => onClickNext(questionCorrect?.question)} disabled={selectedAnswerIndex === null}>
                  {activeQuestion === 10 ? 'Confirma' : 'Confirma'}
                </Button>
              </div>
            </Display>
          ) : (
            <Display>
              <Header>Resultado Final</Header>
              <Text>
                {result.correctAnswers} acertos de 10 questões (
                {Math.round((result.correctAnswers / 10) * 100)}%)
              </Text>
              {
                  result.correctAnswers < 7 ? (
                    <>
                      <h3>Respostas erradas.</h3>

                      <Button onClick={() => restart()}>Reiniciar</Button>
                    </>
                  )
                    : (
                      <>
                        <Success>Parabéns.Você foi aprovado!</Success>
                        <GridButtons>
                          <Button onClick={() => restart()}>Salvar</Button>
                        </GridButtons>
                      </>
                    )
                }
            </Display>
          )}
        </Content>
      </Container>
    </Layout>
  );
};

export default Assessment;
