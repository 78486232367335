import React from 'react';
import { useHistory } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

/** hooks */
import { useAuth } from '../../../hooks/auth';

/** components */
import Flex, { Grid } from '../../../components/Flex';
import {
  Menu, MenuItem, DotButton, ItemIcon,
} from '../../../components/CustomMenu';

import { Container, WrapperMenu } from './styles';
import { LampIcon, PencilIcon, UserIcon } from '../../../styles/icons';
import { IManagement } from '../../../interfaces';

const Card: React.FC<{management: IManagement}> = ({ management }) => {
  const history = useHistory();
  const { iCan } = useAuth();

  return (
    <Container>
      <Grid container spacing={1}>
        {iCan('manager') && (
        <WrapperMenu>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <>
                <DotButton {...bindTrigger(popupState)} />
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => { history.push(`/management/${management.id}`); }}
                    style={{ width: 150 }}
                  >
                    <ItemIcon icon={<PencilIcon />} title="Editar" />
                  </MenuItem>

                  <MenuItem
                    onClick={() => { history.push(`/management/users/${management.id}`); }}
                    style={{ width: 150 }}
                  >
                    <ItemIcon icon={<UserIcon />} title="Efetivo" />
                  </MenuItem>
                </Menu>
              </>
            )}
          </PopupState>
        </WrapperMenu>
        )}

        <Flex title="Nome" textAutoSize xs={8} md={4}>
          {management.initials} - {management.name}
          {!management.status && <b> (Inativo)</b>}
        </Flex>

        <Flex title="Estrutura" xs={4} md={1}>
          {management.level}
          {management.is_core && <LampIcon className="rotate" />}
        </Flex>

        <Flex title="Telefone" xs={4} md={2}>
          {management.phone}
        </Flex>

        {management.core
          && (
            <Flex title="Núcleo" xs={3} md={2}>
              {management.core.initials}
            </Flex>
          )}

        {management.ascendant
          && (
            <Flex title="Projeto" xs={3} md={2}>
              {management.ascendant.initials}
            </Flex>
          )}

      </Grid>
    </Container>
  );
};

export default Card;
