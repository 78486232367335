import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  height: 32px;
  background-color: aqua;
  margin-bottom: 21px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-top: 1px solid var(--color-border);
  padding: 16px 0;
  h3{
    font-size: 1em;
  }
  a{
    margin-top: 0 !important;
  }
`;

export const LinkInfo = styled.div`
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      margin-right: 8px;
      font-size: 18px;
    }
`;
