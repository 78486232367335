import React, { useCallback, useEffect, useState } from 'react';
import { useHttp } from 'hooks/http';
import { Link } from 'react-router-dom';
import { DocumentReportIcon } from 'styles/icons';
import {
  Background, Container, Content, Title, Box, ContentWapper,
} from './styles';

const Apply: React.FC = () => {
  const { httpGet } = useHttp();
  const [avaliation, setAvaliation] = useState<boolean>(false);

  const load = useCallback(async () => {
    const { status, data } = await httpGet('userclassrooms');
    if (status === 200) {
      if (data.length >= 8) {
        setAvaliation(true);
      }
    }
  }, [httpGet]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Container>
      <Background />
      <Content>
        <Title>Capacitação</Title>
        <Box>
          {
          avaliation && (
            <Link to="/assessmentv/uiouio" title="clique aqui" color="white">
              <ContentWapper>
                <h3>Avaliação Final: </h3>
                <DocumentReportIcon size={24} />
              </ContentWapper>
            </Link>
          )
        }
        </Box>
      </Content>
    </Container>
  );
};
export default Apply;
