import React from 'react';
import ReactDOMServer from 'react-dom/server';

import { Container } from './styles';

interface IWrapper{
  children: React.ReactNode;
}
const TextAutoSize: React.FC<IWrapper> = ({ children }) => {
  const title = ReactDOMServer.renderToStaticMarkup(<>{children}</>);

  return (
    <Container title={title}>
      {children}
    </Container>
  );
};

export default TextAutoSize;
