import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { HttpProvider } from './http';

const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      <HttpProvider>
        {children}
      </HttpProvider>
    </AuthProvider>
  </ToastProvider>
);

export default AppProvider;
