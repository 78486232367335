import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
/** hooks */
import { useHttp } from '../../hooks/http';
import { useAuth } from '../../hooks/auth';

/** components */
import Layout from '../../components/Layout';
import Address from '../Orders/Address';
import Form from './Form';
import { HandHoldingHeartIcon } from '../../styles/icons';

import { Container, WrapperAddress } from './styles';

interface ICoord {
  lat: number;
  lng: number;
}

interface IService {
  id: string;
}

const Social: React.FC = () => {
  const history = useHistory();
  const { httpGet, loading } = useHttp();
  const { user } = useAuth();
  const [coord, setCoords] = useState<ICoord>({} as ICoord);
  const [service, setService] = useState<IService | null>(null);
  const { id } = useParams<{ id: string }>();

  const breadcrumbs = {
    icon: HandHoldingHeartIcon,
    links: [{ path: '', title: 'Social' }],
  };

  useEffect(() => {
    async function load(): Promise<any> {
      const management_id = user?.allocation?.management_id;
      const { status, data } = await httpGet(`/orders/auth/${management_id}`);
      if (status === 200) {
        setService(data);
        return;
      }

      setService(null);
      history.push('/dashboard');
    }

    load();
  }, [history, httpGet, user]);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        {id ? <Form /> : (
          <>
            {!coord.lat && service && (
              <WrapperAddress>
                <h3><span>Selecione o </span>local da abordagem</h3>
                <Address
                  handleCoord={(coord: ICoord) => {
                    setCoords(coord);
                  }}
                />
              </WrapperAddress>
            )}
            {(coord.lat) && (
              <Form coord={coord} />
            )}
          </>
        )}
      </Container>
    </Layout>
  );
};

export default Social;
