import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 200px;
  grid-template-rows: auto;
  grid-template-areas:
    'content aside';
  width: 100%;
  height: 100%;

  @media (max-width: 767.98px) {
      grid-template-columns: auto;
      grid-template-rows: 80px auto;
      grid-template-areas:
        'aside'
        'content';
    }

`;

export const WrapperFilter = styled.div`
  grid-area: aside;
  display: flex;
  flex-direction: column;
  padding: 8px;

 .active{
    background: var(--color-button);
    color: var(--color-white);
  }

  span{
    padding: 8px 0;
  }

  @media (max-width: 767.98px) {
    flex-direction: row;
    overflow-x: auto;
  }
`;

export const WrapperContent = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;

  padding: 16px 8px;
`;

export const WrapperCore = styled.div`
  display:flex;
  align-items: center;
  padding: 16px;
  margin-top: 8px;
  cursor: pointer;

  color: var(--color-primary);
  background: var(--color-white);
  border-radius: 4px;
  border: 1px solid var(--color-button);

  svg {
    margin-right: 8px;
  }


  @media (max-width: 767.98px) {
      width: 180px;
      margin-right: 8px;
    }
`;
