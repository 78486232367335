import React from 'react';
import { IconBaseProps } from 'react-icons';

import { Container, WrapperTitle } from './styles';

interface ITabeItem {
    title: string;
    icon?: React.ComponentType<IconBaseProps>;
    show?: boolean;
    enable?: boolean;
    children: React.ReactNode;
}
const TabsItem: React.FC<ITabeItem> = ({
  title, show, icon, enable = true, children,
}) => {
  const Icon = icon;
  return (
    <li>
      <input
        type="radio"
        id={title}
        className="rd_tab"
        name="tabs"
        disabled={!enable}
        defaultChecked={show}
      />
      <label htmlFor={title} className={`tab_label ${enable ? '' : 'tab_label_disable'}`}>
        <WrapperTitle>
          {Icon && <><Icon /><h2>{title}</h2></>}
          {!Icon && <h3>{title}</h3>}
        </WrapperTitle>
      </label>
      <div className="tab-content">
        {children}
      </div>
    </li>
  );
};

interface ITabe {
    children: React.ReactNode;
}

const Tabs: React.FC<ITabe> = ({ children }) => (
  <Container>
    <nav className="nav_tabs">
      <ul>
        {children}
      </ul>
    </nav>
  </Container>
);

export default Tabs;
export { TabsItem };
