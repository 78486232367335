import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    ${css`
    .MuiTab-wrapper{ align-items: flex-start; }
  `}
`;

export const WrapperPanel = styled.div`
  flex-grow: 1;
  padding: 16px;
  overflow-y: auto;

`;
