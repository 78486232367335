/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { Container, WrapperButton } from './styles';

interface IOpenModal{
  id: string;
  title?: string;
  children?: React.ReactNode;
}
const OpenModal: React.FC<IOpenModal> = ({ id, title, children }) => (
  <label htmlFor={id}>
    <WrapperButton>
      {title}
      {children}
      {(!title && !children) && 'inserir título'}
    </WrapperButton>
  </label>
);

interface IModal {
  id: string;
  height?: number | string;
  width?:number | string;
  children: React.ReactNode;
}
const ModalSimple: React.FC<IModal> = ({
  id,
  height = 400,
  width = 600,
  children,
}) => (
  <Container>
    <input className="modal-state" id={id} type="checkbox" />
    <div className="modal">
      <label className="modal__bg" htmlFor={id} />
      <div className="modal__inner" style={{ height, width }}>
        <label className="modal__close" htmlFor={id} />
        {children}
      </div>
    </div>
  </Container>
);

export default ModalSimple;
export { OpenModal };
