import React from 'react';
import { useHistory } from 'react-router-dom';

import { formatDateTime } from '../../../../utils';

/** components */
import Flex, { Grid } from '../../../../components/Flex';
import { IProximity } from '../../../../interfaces';

import {
  Container,
} from './styles';

interface ICard {
  proximity: IProximity;
  type: string;
}

const Card: React.FC<ICard> = ({ proximity, type }) => {
  const history = useHistory();

  return (
    <Grid item xs={12}>
      <Container onClick={() => history.push(`/orders/proximity/detail/${proximity.id}`)}>
        <Grid container spacing={1}>
          <Flex title="Assistência" xs={6} sm={6} md={3}>
            <b> {proximity.assistances.description} </b>
          </Flex>
          { (type === 'list-user') && (
            <Flex title="Nome" xs={6} sm={6} md={3}>
              <b> {proximity?.name} </b>
            </Flex>
          )}
          { (type === 'list-base') && (
            <Flex title="Agente" xs={6} sm={6} md={3}>
              <b> {proximity.user?.name} </b>
            </Flex>
          )}
          <Flex title="Data" xs={6} sm={6} md={3}>
            <b> {formatDateTime(proximity?.created_at)} </b>
          </Flex>
          { (type === 'list-user') && (
            <Flex title="Gerência" xs={6} sm={6} md={3}>
              <b> {proximity.managements?.name} </b>
            </Flex>
          )}
          { (type === 'list-base') && (
          <Flex title="Setor" xs={6} sm={6} md={3}>
            <b> {proximity.sectors?.name} </b>
          </Flex>
          )}
        </Grid>
      </Container>
    </Grid>
  );
};

export default Card;
