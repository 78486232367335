import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import Layout from 'components/Layout';
import { PoliceCarFill, ELinkIcon, PencilIcon } from 'styles/icons';
import { useHttp } from 'hooks/http';
import Flex, { Grid } from '../../../../components/Flex';
import { formatDateTime } from 'utils';
/** interface */
import { IBdtd } from '../../../../interfaces';
import {
    Button
} from '../../../../components/Forms';

import {
    Content,
    Container,
    Title,
    WrapperHeader,
    GridButtons,
    WrapperBtnCancel,
    WrapperLink,
    WrapperBtnSubmit,
} from './styles';
import IFueling from 'interfaces/fueling';

const Detail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [bdtd, setBdtd] = useState<IBdtd>({} as IBdtd);
    const { httpGet, loading } = useHttp();
    const history = useHistory();

    useEffect(() => {
        async function load(): Promise<any> {
            const { status, data } = await httpGet(`/bdtds/${id}`);
            if (status === 200) {
                setBdtd(data.bdtd);
            } else {
                history.push('/dashboard')
            }
        }
        load();
    }, [httpGet, history, id]);

    const breadcrumbs = {
        icon: PoliceCarFill,
        links: [
            { path: '/sbdtds/supervisor', title: 'Consultas' },
            { path: '', title: 'Bdtd Detalhe - Fiscal' },
        ],
    };

    return (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
            <Container>
                <Content>
                    <WrapperHeader>
                        <span>
                            <PoliceCarFill />{`${bdtd?.fleet?.model?.name}/${bdtd?.fleet?.make?.name}-${bdtd?.fleet?.plate}`}
                        </span>
                        <span>
                            <PencilIcon onClick={() => { history.push(`/bdtd/edit/${bdtd?.id}`) }} cursor="pointer" />
                        </span>
                        <h2>
                            {bdtd.active ? 'Aberto' : 'Fechado'}
                        </h2>
                    </WrapperHeader>
                    <Title>Dados do Bdtd:</Title>
                    <Grid container spacing={1}>
                        <Flex title="Viatura" xs={12} sm={6} md={6}>
                            {bdtd?.fleet?.model?.name} {bdtd?.fleet?.make?.name} / {bdtd?.fleet?.plate}
                        </Flex>
                        <Flex title="Gerência" xs={12} sm={6} md={6}>
                            {bdtd?.management?.initials} - {bdtd?.management?.name}
                        </Flex>
                        <Flex title="Motorista" xs={12} sm={6} md={6}>
                            {bdtd?.user?.name}
                        </Flex>
                        <Flex title="Contato" xs={12} sm={6} md={6}>
                            {bdtd?.user?.phone}
                        </Flex>
                        <Flex title="Km Inicial" xs={6} sm={6} md={6}>
                            {bdtd?.started_km}
                        </Flex>
                        <Flex title="Data/Hora Inicial" xs={6} sm={6} md={6}>
                            {formatDateTime(bdtd?.started_at)}
                        </Flex>
                        <Flex title="Km Final" xs={6} sm={6} md={6}>
                            {bdtd?.ended_km ? bdtd?.ended_km : 'Aguardando...'}
                        </Flex>
                        <Flex title="Data/Hora Final" xs={6} md={6}>
                            {bdtd.ended_at ? formatDateTime(bdtd.ended_at) : 'Aguardando...'}
                        </Flex>
                        {bdtd.adm_id && (
                            <Flex title="Encerrado pelo Fiscal" xs={12} md={12}>
                                {bdtd.adm.name}
                            </Flex>
                        )}
                    </Grid>
                    {!bdtd.active ? (
                        <>
                            <Title>Abastecimento:</Title>
                            {bdtd?.fueling?.length === 0 ? 'Não houve abastecimento.' :
                                bdtd?.fueling?.map((abs: IFueling) => (
                                    <Grid container spacing={1} key={abs.id}>
                                        <Flex title="Km" sm={4} md={4}>
                                            {abs?.km_fueling}
                                        </Flex>
                                        <Flex title="Combustivel" sm={4} md={4}>
                                            {abs?.fuel}
                                        </Flex>
                                        <Flex title="Litros" sm={4} md={4}>
                                            {abs?.liters}
                                        </Flex>
                                    </Grid>
                                ))}
                        </>) : ''}
                    <Title>Observações:</Title>
                    <Grid container spacing={1}>
                        {bdtd?.note}
                    </Grid>


                    {bdtd.ended_at && (
                        <Grid container spacing={1} justify="flex-end">
                            <Grid item xs={12} sm={6} md={4}>
                                <WrapperLink>
                                    <Link to="/dashboard">
                                        <ELinkIcon />
                                        Dashboard
                                    </Link>
                                </WrapperLink>
                            </Grid>
                        </Grid>
                    )}
                    <GridButtons>
                        {!bdtd.ended_at && (
                            <>
                                <WrapperBtnCancel>
                                    <Button
                                        type="button"
                                        onClick={() => { history.push(`/sbdtd/close/${bdtd.id}`) }}
                                        style={{ background: '#CC0001' }}
                                    >
                                        Encerrar
                                    </Button>
                                </WrapperBtnCancel>
                                <WrapperBtnSubmit>
                                    <Button
                                        type="button"
                                        onClick={() => { history.push('/dashboard'); }}
                                    >
                                        Dashboard
                                    </Button>
                                </WrapperBtnSubmit>
                            </>)}
                    </GridButtons>
                </Content>
            </Container>
        </Layout>

    );
}

export default Detail;
