import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateTime, formatPlate } from '../../../../utils';
/** components */
import Flex, { Grid } from '../../../../components/Flex';

import {
  CarIcon,
} from '../../../../styles/icons';
import {
  Container, WrapperRow,
} from './styles';

interface ICard {
  order: any;
}

const Card: React.FC<ICard> = ({ order }) => {
  const history = useHistory();
  const [ref] = order.id.split('-');

  return (
    <Grid item xs={12}>
      <Container onClick={() => history.push(`/company/detail/${order.id}`)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <WrapperRow>
                <CarIcon />
                #{ref.toUpperCase()}

              </WrapperRow>
              <b>
                {order.situation}
              </b>
            </span>
          </Grid>

          <Flex title="Placa" xs={6} sm={6} md={4}>
            {formatPlate(order.plate) }
          </Flex>
          <Flex title="Marca/Modelo" xs={6} sm={6} md={4}>
            {`${order.marca} ${order.modelo}`}
          </Flex>
          <Flex title="Gerência" xs={6} sm={6} md={4} textAutoSize>
            {order.manegements_name}
          </Flex>

          <Flex title="Problema" xs={6} sm={6} md={4} textAutoSize>
            {order.problem}
          </Flex>

          <Flex title="Acao" xs={6} sm={6} md={4}>
            {order.order}
          </Flex>
          <Flex title="Solicitado em" xs={6} sm={6} md={6}>
            {formatDateTime(order.created_at)}
          </Flex>
          <Flex title="Encerrado em" xs={6} sm={6} md={6}>
            {formatDateTime(order.ended_at)}
          </Flex>

        </Grid>
      </Container>
    </Grid>
  );
};

export default Card;
