import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { ModalDialog } from 'components/ModalDialog';
import React, { useCallback, useRef, useState } from 'react';
import ModalFactory from 'react-modal-promise';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { InfoIcon } from 'styles/icons';
import * as Yup from 'yup';
import {
  Button, ButtonIcon, DatePicker, Input, Select, TextArea, WrapperLabel,
} from '../../../../components/Forms';
import { useAuth } from '../../../../hooks/auth';
import { useHttp } from '../../../../hooks/http';
import { useToast } from '../../../../hooks/toast';
import getValidationError from '../../../../utils/getValidationsErros';
import customStyle from './customStyle';
import {
  Container,
  GridButtons, WrapperBtnCancel, WrapperBtnInfo, WrapperBtnSubmit,
} from './styles';

interface IOptions {
    value: string;
    label: string
}

const Section: React.FC = () => {
  const [militaryChief, setMilitaryChief] = useState<IOptions | null>();
  const [cars, setCars] = useState<IOptions[]>();
  const [segovTeamCivil, setSegovTeamCivil] = useState<IOptions[]>();
  const [segovTeamMilitar, setSegovTeamMilitar] = useState<IOptions[]>();
  const formRef = useRef<FormHandles>(null);
  const { httpGet, httpPost } = useHttp();
  const { addToast } = useToast();
  const { user } = useAuth();
  const history = useHistory();
  const [btnLoading, setBtnLoading] = useState(false);

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 3) return;
    const users: any = [];
    const { status, data } = await httpGet(`/rcols/users/${inputValue}`);

    if (status === 200) {
      data.forEach((item: any) => {
        users.push({
          value: item.id,
          label: item.name,
        });
      });
    }

    callback(users);
  };

  const loadFleets = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 3) return;
    const fleets: any = [];
    const { status, data } = await httpGet(`/rcols/fleets/${inputValue}`);

    if (status === 200) {
      data.forEach((item: any) => {
        fleets.push({
          value: item.id,
          label: item.plate,
        });
      });
    }

    callback(fleets);
  };

  const handleChangeCars = useCallback((event: any) => {
    setCars(event);
  }, []);

  const handleChangeMilitaryChief = useCallback((event: any) => {
    setMilitaryChief(event);
  }, []);

  const handleChangeSegovTeamCivil = useCallback((event: any) => {
    setSegovTeamCivil(event);
  }, []);

  const handleChangeSegovTeamMilitar = useCallback((event: any) => {
    setSegovTeamMilitar(event);
  }, []);

  const handleSubmit = useCallback(async (payload: any) => {
    setBtnLoading(true);
    const _section = {
      ...payload,
      management_id: user?.allocation?.management_id,
      team: payload.team?.value,
      operation_date: payload?.operation_date,
      militaryChief_id: militaryChief?.value || null,
      segovTeamCivil_id: segovTeamCivil ? segovTeamCivil?.map((user) => user.label).join(',') : null,
      segovTeamMilitar_id: segovTeamMilitar ? segovTeamMilitar?.map((user) => user.label).join(',') : null,
      cars_id: cars ? cars?.map((vtr) => vtr.label).join(',') : null,
    };

    try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
              team: Yup.string().required('Equipe é obrigatório'),
              operation_date: Yup.date().typeError('Data da Operação é obrigatório'),
              militaryChief_id: Yup.string().typeError('Chefe Militar é obrigatório'),
              segovTeamCivil_id: Yup.string().typeError('Efetivo Civil é obrigatório'),
              segovTeamMilitar_id: Yup.string().typeError('Efetivo Militar é obrigatório'),
              cars_id: Yup.string().typeError('Viaturas Ols é obrigatório'),
              agent_detran: Yup.number().typeError('Ag. do Detran é obrigatório, somente números'),
              number_trailers: Yup.number().typeError('Quantidade de Reboques é obrigatório, somente números'),
            });

            await schema.validate(_section, { abortEarly: false });

            const { status, data } = await httpPost('rcols', _section);
            if (status === 200) {
              addToast({ title: 'Próximo passo, acesse o SEÇÃO II', type: 'warning' });
              setBtnLoading(false);
              history.push(`/rcol/${data.id}`);
            } else {
              setBtnLoading(false);
              addToast({ title: 'Não foi possível salvar!', type: 'error' });
              // history.push('/dashboard');
            }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setBtnLoading(false);
        const erros = getValidationError(err);
        if (erros.militaryChief_id) {
          await ModalDialog({
            title: 'Aviso!',
            content: erros.militaryChief_id,
            buttons: 'ok',
          });
        }
        if (erros.segovTeamCivil_id) {
          await ModalDialog({
            title: 'Aviso!',
            content: erros.segovTeamCivil_id,
            buttons: 'ok',
          });
        }
        if (erros.segovTeamMilitar_id) {
          await ModalDialog({
            title: 'Aviso!',
            content: erros.segovTeamMilitar_id,
            buttons: 'ok',
          });
        }
        if (erros.cars_id) {
          await ModalDialog({
            title: 'Aviso!',
            content: erros.cars_id,
            buttons: 'ok',
          });
        }
                formRef.current?.setErrors(erros);
      }
    }
  }, [militaryChief, segovTeamCivil, segovTeamMilitar, cars, user, addToast, history, httpPost]);

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Equipe" xs={12} md={6}>
            <Select
              name="team"
              options={[
                { value: 'A1', label: 'A1' },
                { value: 'A2', label: 'A2' },
                { value: 'A3', label: 'A3' },
                { value: 'A4', label: 'A4' },
                { value: 'A5', label: 'A5' },
                { value: 'A6', label: 'A6' },
                { value: 'A7', label: 'A7' },
                { value: 'A8', label: 'A8' },
                { value: 'A9', label: 'A9' },
                { value: 'A10', label: 'A10' },
                { value: 'B1', label: 'B1' },
                { value: 'B2', label: 'B2' },
                { value: 'B3', label: 'B3' },
                { value: 'B4', label: 'B4' },
                { value: 'B5', label: 'B5' },
                { value: 'B6', label: 'B6' },
                { value: 'B7', label: 'B7' },
                { value: 'B8', label: 'B8' },
                { value: 'B9', label: 'B9' },
                { value: 'B10', label: 'B10' },
                { value: 'C1', label: 'C1' },
                { value: 'C2', label: 'C2' },
                { value: 'C3', label: 'C3' },
                { value: 'C4', label: 'C4' },
                { value: 'C5', label: 'C5' },
                { value: 'C6', label: 'C6' },
                { value: 'C7', label: 'C7' },
                { value: 'C8', label: 'C8' },
                { value: 'C9', label: 'C9' },
                { value: 'C10', label: 'C10' },
              ]}
            />
          </WrapperLabel>
          <WrapperLabel label="Data da Operação" xs={12} md={6}>
            <DatePicker
              name="operation_date"
              maxDate={new Date()}
            />
          </WrapperLabel>
          <WrapperLabel label="Chefe da Equipe Militar" xs={12} md={6}>
            <div style={{ width: '100%' }}>
              <AsyncSelect
                placeholder="Nome"
                noOptionsMessage={() => 'Sem registos'}
                cacheOptions
                isClearable
                loadOptions={loadOptions}
                onChange={handleChangeMilitaryChief}
                value={militaryChief}
                styles={customStyle}
              />
            </div>
          </WrapperLabel>
          <WrapperLabel label="Efetivo Civil" xs={12} md={6}>
            <div style={{ width: '100%' }}>
              <AsyncSelect
                placeholder="Nome"
                noOptionsMessage={() => 'Sem registos'}
                cacheOptions
                isClearable
                loadOptions={loadOptions}
                onChange={handleChangeSegovTeamCivil}
                value={segovTeamCivil}
                styles={customStyle}
                isMulti
              />
            </div>
          </WrapperLabel>
          <WrapperLabel label="Efetivo Militar" xs={12} md={6}>
            <div style={{ width: '100%' }}>
              <AsyncSelect
                placeholder="Nome"
                noOptionsMessage={() => 'Sem registos'}
                cacheOptions
                isClearable
                loadOptions={loadOptions}
                onChange={handleChangeSegovTeamMilitar}
                value={segovTeamMilitar}
                styles={customStyle}
                isMulti
              />
            </div>
          </WrapperLabel>
          <WrapperLabel label="Quantidade de Ag. do Detran" xs={12} md={6}>
            <Input name="agent_detran" maxLength={2} />
          </WrapperLabel>
          <WrapperLabel label="Quantidade de Reboques" xs={12} md={6}>
            <Input name="number_trailers" maxLength={2} />
          </WrapperLabel>
          <WrapperLabel label="Viaturas Ols Utilizadas na Operação" xs={12} md={6}>
            <div style={{ width: '100%' }}>
              <AsyncSelect
                placeholder="Placa"
                noOptionsMessage={() => 'Sem registos'}
                cacheOptions
                isClearable
                loadOptions={loadFleets}
                onChange={handleChangeCars}
                value={cars}
                styles={customStyle}
                isMulti
              />
            </div>
          </WrapperLabel>
          <WrapperLabel label="Alterações de Efetivo OLS" xs={12}>
            <TextArea
              row={4}
              name="change_ols"
              placeholder='Atrasos, Faltas, Nome do(s) Agente(s) da "Educação" (caso houver) e outras alterações relevantes.'
            />
          </WrapperLabel>
          <WrapperLabel label="Alterações da Equipe de Apoio" xs={12}>
            <TextArea
              row={4}
              name="change_support"
              placeholder="Atrasos, Faltas e outras alterações relevantes e conferência de reboques e viaturas da OLS"
            />
          </WrapperLabel>
          <WrapperLabel label="Alterações de Material/Equipamento/Viatura" xs={12}>
            <TextArea row={4} name="changes_material" />
          </WrapperLabel>
          <WrapperLabel label="Alterações em Geral" xs={12}>
            <TextArea
              row={4}
              name="changes_general"
              placeholder='Ocorrências não registradas, troca de condutores, visibilidade, cancelamento de operações etc  ou escreva "SEM ALTERAÇÃO".'
            />
          </WrapperLabel>
        </Grid>
        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
            >
              <InfoIcon />
            </ButtonIcon>
          </WrapperBtnInfo>
          <WrapperBtnCancel>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </WrapperBtnCancel>
          <WrapperBtnSubmit>
            <Button
              type="submit"
              isLoading={btnLoading}
            >
              Salvar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>
      </UForm>
      <ModalFactory />
    </Container>
  );
};

export default Section;
