import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHttp } from '../../../hooks/http';
import Layout from '../../../components/Layout';
import HeaderSimle from '../../../components/Headers/Simple';
import HeaderFilterPlus from '../../../components/Headers/FilterPlus';
import CardUser from './CardUser';
import { BuildingIcon } from '../../../styles/icons';
import { Container } from './styles';
import { IPaginate } from '../../../interfaces';

const Users: React.FC = () => {
  const { id } = useParams<{id: string}>();
  const [users, setUsers] = useState([]);
  const [management, setManagement] = useState('');
  const [filter, setFilter] = useState('');

  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 100, lastPage: 1, total: 0,
  });

  const { httpGet, loading } = useHttp();

  const breadcrumbs = {
    icon: BuildingIcon,
    links: [
      { title: 'Gerência Efetivo', path: '' },
    ],
  };

  useEffect(() => {
    async function loadManagements(): Promise<void> {
      const { status, data } = await httpGet(`/managements/users/${id}`);

      if (status === 200) {
        setManagement(data[0]?.m_name);
        setUsers(data);
      }
    }

    loadManagements();

  }, [filter, paginate.page]); // eslint-disable-line

  const handleSearch = (value: string): void => {
    setPaginate({ ...paginate, page: 1 });
    setFilter(value);
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderFilterPlus handleSearch={handleSearch} />

        <HeaderSimle>
          { management }
        </HeaderSimle>

        {users && users.map((user: any) => (
          <CardUser key={user.id} user={user} />
        ))}

      </Container>
    </Layout>
  );
};

export default Users;
