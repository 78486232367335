import React, { useState, useCallback, useEffect } from 'react';
import Layout from 'components/Layout';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import HeaderSavePlus from '../../components/Headers/SavePlus';
import Tabs, { TabsItem } from '../../components/Tabs';
import { Container } from './styles';
import { GasPumpIcon } from '../../styles/icons';
import Profile from './Profile';
import { useHttp } from 'hooks/http';
import { IGas } from 'interfaces';

const breadcrumbs = {
    icon: GasPumpIcon,
    links: [
        { path: '/gases', title: 'Postos' },
        { path: '', title: 'Posto' },
    ],
};

const Form: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { httpGet, loading } = useHttp();
    const [gas, setGas] = useState({} as IGas);
    const history = useHistory();

    useEffect(() => {
        async function loadGas(): Promise<void> {
            const { data } = await httpGet(`/gas/${id}`);
            setGas(data);
        }

        id && loadGas();
    }, [id]);// eslint-disable-line

    const handlePlus = useCallback(() => {
        history.push('/gas');
        setGas({} as IGas);
    }, [history]);

    return (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
            <Container>
                <HeaderSavePlus
                    hiddenButton={!id}
                    handlePlus={handlePlus}
                >
                    {id ? `Editar ${gas.fantasy}` : 'Novo Posto'}
                </HeaderSavePlus>

                <Tabs>
                    <TabsItem title="Dados" show>
                        {id && <Profile gas={gas} />}
                        {!id && <Profile gas={{} as IGas} />}
                    </TabsItem>
                </Tabs>

            </Container>
        </Layout>
    );
}

export default Form;