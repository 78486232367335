import styled, { css } from 'styled-components';

import Tooltip from '../../Tooltip';

interface IContainer{
  isFocused?: boolean;
  isFilled?: boolean;
  isErrored?: boolean;
  readOnly?: boolean;
}
export const Container = styled.div<IContainer>`
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  border: 1px solid var(--color-border);
  color: var(--color-gray);
  background: var(--color-white);

  ${(props) => props.readOnly && css`
    background: var(--color-bg-disable);
  `}

  position: relative;

  ${(props) => props.isErrored && css`
    border-color: var(--color-red);
  `}

  ${(props) => props.isFocused && css`
    border-color: var(--color-grey);
  `}

  display: flex;
  align-items: center;

  & + div{
      margin-top: 12px;
    }

  input{
    flex: 1;
    background: transparent;
    border: 0;
    color: var(--color-black);

    ${(props) => props.isErrored && css`
      /* padding-right: 28px; */
    `}

    &::placeholder{
      color: #666360;
    }
  }

  span{
      font-size: 14px;
      color: var(--color-red);
      text-align: left;
      /* display: none; */
    }


`;

export const Error = styled(Tooltip)`
  height: 20px;
  top: 8px;
  right: 8px;
  background: #FFF;

  position: absolute;
  display:none;
  visibility: hidden;


  ${(props) => props.title && css`
    display:block;
    visibility: visible;
  `}

  svg{
    margin: 0;
  }

  span {
    background: var(--color-red);
    color: #FFF;

    left: 90%;
    transform: translateX(-95%);

    &::before{
      border-color: var(--color-red) transparent;
      left: 90%;
    }
  }
`;
