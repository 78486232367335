import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Content = styled.div`
  background: #fff;
  border-radius: 4px;
`;

export const WrapperModal = styled.div`
  padding: 16px 0;
`;
