import React from 'react';
import { ValueGetterParams } from '@material-ui/data-grid';
import { isBefore } from 'date-fns';
import { Container } from './styles';

interface IStatus{
  params: ValueGetterParams;
}

const handleStatus = (params: ValueGetterParams):String => {
  if (params.row?.missing_id) {
    return 'Falta';
  }

  if (params.row?.presence_ended_id
    && isBefore(new Date(params.row?.presence_ended_at), new Date(params.row?.ended_at))
  ) {
    return 'Interrompido';
  }

  if (params.row?.presence_ended_id) {
    return 'Encerrado';
  }

  if (params.row?.presence_started_id) {
    return 'Confirmado';
  }

  return 'Aguardando';
};

const Status: React.FC<IStatus> = ({ params }) => (
  <Container>
    {handleStatus(params)}
  </Container>
);

export default Status;

export { handleStatus };
