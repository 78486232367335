import React, {
  useCallback, useEffect, useState,
} from 'react';
import ReactPlayer from 'react-player';
import { DocumentReportIcon } from 'styles/icons';
import { useHttp } from 'hooks/http';
import { useToast } from 'hooks/toast';
import {
  Whapper, Content, Display, ContentWapper, Title, Description, Box,
} from './styles';

interface VideoProps {
  lessonSlug: string;
}

const Video: React.FC<VideoProps> = (props) => {
  const [acc, setAcc] = useState<any>(null);
  const { httpGet, httpPost } = useHttp();
  const [assessment, setAssessment] = useState<boolean>(false);
  const { addToast } = useToast();

  const load = useCallback(async () => {
    const [responseClass, responseUser] = await Promise.all([
      httpGet(`/classrooms/${props.lessonSlug}`),
      httpGet('/userclassrooms'),
    ]);

    const classroom = responseClass.data;

    if (classroom) {
      setAcc(classroom);
    }

    const user = responseUser.data;
    const array = await user.map((s: any) => ({ slug: s.classroom.slug, progress: s.progress }))
      .filter((d: any) => d.slug === props.lessonSlug);
    await array[0]?.progress && setAssessment(true);
  }, [httpGet, props]);

  useEffect(() => {
    setAcc(null);
    setAssessment(false);
    load();
  }, [load]);

  if (!acc) {
    return (
      <Display>
        <p>Carregando...</p>
      </Display>
    );
  }

  async function currentTimeDisplay(): Promise<void> {
    if (!assessment) {
      const { status } = await httpPost('userclassrooms', { acc });
      if (status === 200) {
        setAssessment(true);
        addToast({ title: 'Material de apoio liberado!', type: 'success' });
      }
    }
  }

  return (
    <Whapper>
      <ReactPlayer
        width="100%"
        controls
        url={`https://d8rrqdvwf4v82.cloudfront.net/${acc?.slug}`}
        onEnded={currentTimeDisplay}
      />
      <Content>
        <Title>{acc?.title}</Title>
        {
          assessment && (
            <a href={`https://d8rrqdvwf4v82.cloudfront.net/${acc?.slug}.pdf`} target="blank" title="clique aqui" color="white">
              <Box>
                <ContentWapper>
                  <h3>Material de apoio: </h3>
                  <DocumentReportIcon size={24} />
                </ContentWapper>
              </Box>
            </a>
          )
        }
        <Description>
          {acc?.description}
        </Description>
      </Content>
    </Whapper>
  );
};

export default Video;
