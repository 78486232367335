/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import Pagination from 'components/Pagination';
import { IPaginate } from 'interfaces';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { InfoIcon } from 'styles/icons';
import {
  Button, ButtonIcon, Input, InputMask, WrapperLabel,
} from '../../../components/Forms';
import Modal from '../../../components/ModalSimple';
import { useHttp } from '../../../hooks/http';
import {
  parseDate, removeAccents, session,
} from '../../../utils';
import StyleCard from './StyleCard';
import {
  Card,
  Container,
  Content,
  GridButtons, RedStyle,
  WrapperBtnCancel,
  WrapperBtnInfo,
  WrapperBtnSubmit,
} from './styles';

interface ICoord {
    coord?: {
        lat: number,
        lng: number
    }
}

const Form: React.FC<ICoord> = ({ coord }) => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [showForm] = useState(false);
  const [persons, setPersons] = useState([]);
  const [person, setPerson] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const { httpGet } = useHttp();
  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 5, lastPage: 1, total: 0,
  });

  const handleGetVictim = useCallback(async () => {
    const name = formRef.current?.getFieldValue('name');
    const birthday = formRef.current?.getFieldValue('birthday');
    const mather = formRef.current?.getFieldValue('mather');

    const personCall = {
      name: removeAccents(name?.trim()),
      mather: removeAccents(mather?.trim()),
      birthday: parseDate(birthday),
    };

    setPerson(personCall);

    const response: any = await httpGet('/sinalid/search', { params: { ...personCall, ...paginate } });
    setBtnLoading(true);
    if (response.status === 200) {
      const { data } = response;
      setPersons(data.response);
      setPaginate(data.pagination);
      setBtnLoading(false);
    }
    setBtnLoading(false);
  }, [httpGet, paginate]);

  const handlePaginate = async (payload: IPaginate) => {
    setPaginate({
      page: payload?.page,
      perPage: payload?.perPage,
      lastPage: payload?.lastPage,
      total: payload?.total,
    });
    const response: any = await httpGet('/sinalid/search', { params: { ...person, ...payload } });
    setBtnLoading(true);
    if (response.status === 200) {
      const { data } = response;
      setPersons(data.response);
      setPaginate(data.pagination);
      setBtnLoading(false);
    }
    setBtnLoading(false);
  };

  const handleGoToDetail = useCallback(async (data: any) => {
    session.remove('victim');
    session.setObjectVictim('victim', data);
    history.push(`/sinalid/detail/${data.id}`);
  }, [history]);

  const handleSubmit = useCallback(() => {
  }, []);

  return (
    <Container>
      <Content>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <WrapperLabel label="Nome Completo" xs={12}>
              <Input name="name" style={{ textTransform: 'uppercase' }} />
            </WrapperLabel>
            <WrapperLabel label="Nascimento" xs={12} sm={6}>
              <InputMask name="birthday" mask="99/99/9999" placeholder="DD/MM/YYYY" />
            </WrapperLabel>
            <WrapperLabel label="Mãe" xs={12} sm={6}>
              <Input name="mather" style={{ textTransform: 'uppercase' }} />
            </WrapperLabel>
            <WrapperLabel label="RG" xs={12} sm={6}>
              <Input name="document_secondary" type="tel" />
            </WrapperLabel>
            <WrapperLabel label="Pai" xs={12} sm={6}>
              <Input name="father" style={{ textTransform: 'uppercase' }} />
            </WrapperLabel>
          </Grid>
          <GridButtons>
            <WrapperBtnInfo>
              <ButtonIcon
                title="Instruções"
                onClick={() => labelRef.current?.click()}
              >
                <InfoIcon />
              </ButtonIcon>

              <label ref={labelRef} htmlFor="modal-instruction" />
              <Modal id="modal-instruction" width={520} height="auto">
                <h1>Instruções</h1><br />
                <p>
                  Para solicitar uma consulta de pessoa você precisa no mínimo
                  informar os seguintes dados:
                </p><br />
                <p><b>CPF</b> ou</p>
                <p><b>Nome e Nascimento</b> ou</p>
                <p><b>Nome e Mãe</b>. </p>
                <br />
              </Modal>

            </WrapperBtnInfo>
            <WrapperBtnCancel>
              <Button
                type="button"
                onClick={() => { history.push('/dashboard'); }}
              >
                Cancelar
              </Button>
            </WrapperBtnCancel>
            <WrapperBtnSubmit>
              <Button
                type="submit"
                onClick={() => {
                  if (showForm) {
                    formRef.current?.submitForm();
                  } else {
                    handleGetVictim();
                  }
                }}
                isLoading={btnLoading}
              >
                Consultar
              </Button>
            </WrapperBtnSubmit>
          </GridButtons>
        </UForm>
        {persons.length > 0 ? (<RedStyle>Clique na pessoa consultada. </RedStyle>) : ''}
        {!!persons && persons?.map((person: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Card key={index} onClick={() => { handleGoToDetail(person); }}>
            <StyleCard person={person} />
          </Card>
        ))}
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Content>
    </Container>
  );
};

export default Form;
