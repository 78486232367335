import React, { useEffect, useState } from 'react';
import { useHttp } from 'hooks/http';
import { useToast } from 'hooks/toast';
import QRCode from 'react-qr-code';
import { ButtonIcon, WrapperLabel } from '../../components/Forms';
import { Container, Content } from './styles';
import { FillEyeInvisibleIcon, FillEyeIcon, LockIcon } from '../../styles/icons';
import Layout from '../../components/Layout';

const BodyCam: React.FC = () => {
  const { httpGet, loading } = useHttp();
  const { addToast } = useToast();
  const [passwordType, setPasswordType] = useState('password');
  const [passwordInput, setPasswordInput] = useState('');

  const handlePasswordChange = (evnt: any): void => {
    setPasswordInput(evnt.target.value);
  };

  const togglePassword = ():void => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  useEffect(() => {
    async function loadPassword(): Promise<void> {
      const { status, data } = await httpGet('/bodycans');

      if (status === 200) {
        if (Object.keys(data).length !== 0) {
          const { password } = data[0];
          setPasswordInput(password);
        } else {
          addToast({ title: 'Sem senha!', type: 'error' });
        }
      }
    }

    loadPassword();
    }, []); // eslint-disable-line

  const breadcrumbs = {
    icon: LockIcon,
    links: [
      { title: 'BodyCam', path: '' },
    ],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <QRCode value={passwordInput} />
          <WrapperLabel label="" xs={6} sm={6} md={6}>
            <input
              disabled
              name="password"
              type={passwordType}
              value={passwordInput}
              onChange={handlePasswordChange}
            />
            <ButtonIcon onClick={togglePassword} title="Ocultar">
              {passwordType === 'password' ? <FillEyeInvisibleIcon /> : <FillEyeIcon />}
            </ButtonIcon>
          </WrapperLabel>
        </Content>
      </Container>
    </Layout>
  );
};

export default BodyCam;
