import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDate, parseDate } from 'utils';
import getCurrentPosition from 'utils/getCurrentPosition';
import { Button, Input, InputMask } from '../../../../components/Forms';
import Modal from '../../../../components/ModalSimple';
import { WarningIcon } from '../../../../styles/icons';
/** hooks */
import { useAuth } from '../../../../hooks/auth';
import { useHttp } from '../../../../hooks/http';

import StyleCard from './StyleCard';
import {
  Card, Container,
  Content, Death,
  Red, RedStyle, WrapperHidden,
  WrapperModal,
} from './styles';

interface IFormProps {
    approximation: any;
}

const Form: React.FC<IFormProps> = ({ approximation }) => {
  const history = useHistory();
  const [formData, setFormData] = useState<any>();
  const formRef = useRef<FormHandles>(null);
  const labelConfRef = useRef<HTMLLabelElement>(null);
  const { user } = useAuth();
  const [btnLoading, setBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const { httpPost } = useHttp();

  const handleGoToDetail = useCallback(async (data: any) => {
    setFormData(data);
        formRef.current?.setData({
          ...data,
          birthday: formatDate(data.birthday),
          confirmed: [{ value: null, label: '' }],
        });
        labelConfRef.current?.click();
  }, []);

  const handleSubmit = useCallback(async (data: any) => {
    const personSubmit = {
      ...data,
      birthday: parseDate(data.birthday),
      management_id: user?.allocation?.management_id,
      document: data.document?.replace(/\D/g, ''),
      type: 'person',
    };

    setBtnLoading(true);
    const position = await getCurrentPosition();
    const coord = { lat: position.lat, lng: position.lng };
    const response: any = await httpPost('/orders/extends', {
      ...personSubmit,
      ...coord,
      current_lat: position.lat,
      current_lng: position.lng,
    });
    if (response.response?.status === 302) {
            labelConfRef.current?.click();
            formRef.current?.reset();
            const { data } = response.response;
            history.push(`/order/detail/${data.id}`);
    }

    if (response.status === 200) {
      history.push('/orders/latest');
    }

    if (response.status !== 200) {
      setBtnLoading(false);
    }

    setBtnLoading(false);

    }, [history, httpPost, user]); // eslint-disable-line

  const handleChangeChecked = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableBtn(event.target.checked);
  }, []);

  const handleHover = useCallback(() => {
    setDisableBtn(false);
        labelConfRef.current?.click();
        formRef.current?.reset();
  }, []);

  return (
    <Container>
      {!!approximation && approximation.length > 0 && (
        <>
          <RedStyle>
            Atenção! Existem nomes semelhantes com pendências.
            Faça a análise abaixo e clique na pessoa suspeita. Caso julgar necessário.
          </RedStyle>
          <Content>
            {
                approximation?.map((approximation: any, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Card key={index} onClick={() => { handleGoToDetail(approximation); }}>
                    <StyleCard person={approximation} />
                  </Card>
                ))
            }
          </Content>
        </>
      )}

      <UForm ref={formRef} onSubmit={handleSubmit}>
        <WrapperHidden show={false}>
          <Input name="idperson" disabled={!!formData?.idpessoa} />
          <Input name="name" style={{ textTransform: 'uppercase' }} />
          <InputMask name="birthday" mask="99/99/9999" placeholder="DD/MM/YYYY" disabled={!!formData?.birthday} />
          <Input name="mather" style={{ textTransform: 'uppercase' }} disabled={!!formData?.mather} />
          <Input name="father" style={{ textTransform: 'uppercase' }} disabled={!!formData?.father} />
        </WrapperHidden>
      </UForm>

      <WrapperModal>
        <label ref={labelConfRef} htmlFor="modal-conf" />
        <Modal id="modal-conf" width={520} height="auto">
          <h2>
            <WarningIcon /> Confirma os dados da pessoa consultada ?
          </h2>
          <b>{formData?.idpessoa}</b>
          <p>Nome: <b>{formData?.name}</b></p>
          <p>Nascimento: <b>{formatDate(formData?.birthday)}</b></p>
          <p>Mãe: <b>{formData?.mather}</b></p>
          <p>Pai: <b>{formData?.father}</b></p>
          {formData?.death && (
                        formData?.death !== '0000' ? (
                          <Death> Óbito: {formData?.death}</Death>
                        ) : '')}
          {formData?.situation && (
                        formData?.situation !== 'REGULAR' ? (
                          <p>Situação cadastral: <b>{formData?.situation}</b></p>
                        ) : '')}

          <FormControlLabel
            label={<Red>Confirmo as informações acima.</Red>}
            control={(
              <Switch
                onChange={handleChangeChecked}
                color="primary"
                name="checked"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
                        )}
          />

          <span>
            <Button
              onClick={handleHover}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => formRef.current?.submitForm()}
              isLoading={btnLoading}
              disableForce={!disableBtn}
            >
              Confirmar
            </Button>
          </span>

        </Modal>
      </WrapperModal>

    </Container>
  );
};
export default Form;
