import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 4px;

  cursor: pointer;
  transition: transform 0.2s;
  &:hover{
     transform: translateX(4px);
  }
`;

export const WrapperContent = styled.div`
  widows: 100%;
  display: flex;

`;
