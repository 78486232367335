import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 64px;
  padding: 0 16px;
  margin: 4px 0;
  display:flex;
  align-items: center;

  flex-shrink:0;

  background: #fff;

  border-radius: 4px;
  margin-bottom: 8px;

  h1{
    font-size: 24px;
  }

  @media (max-width: 767.98px) {
      h1 {
        font-size: 18px;
      }
    }
`;
