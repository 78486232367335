import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  a{
    display: flex;
    align-items: center;

    text-decoration: none;
    color: var(--color-black);
    font-size: 24px;

    svg{
      margin-right: 8px;
    }
  }
`;
