import React, { useState, useEffect } from 'react';
import Simple from 'components/Headers/Simple';
import { useAuth } from 'hooks/auth';
import { useHistory } from 'react-router';
import Pagination from '../../components/Pagination';
import { useHttp } from '../../hooks/http';
import Layout from '../../components/Layout';
import Flex, { Grid } from '../../components/Flex';
import Card, { CardHeader, CardContent } from '../../components/Card';
import { PoliceCarFill, BlockIcon } from '../../styles/icons';
import { IFleet, IPaginate, IResponse } from '../../interfaces';
import {
  Container, WrapperName, Content,
} from './styles';
import Situation from './Situation';

const breadcrumbs = {
  icon: PoliceCarFill,
  links: [
    { title: 'Viaturas manutenção', path: '' },
  ],
};

interface FleetData extends IResponse {
    data: IFleet[];
}

const Maintenance: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [fleets, setFleets] = useState<FleetData>({} as FleetData);
  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const { httpGet, loading } = useHttp();

  useEffect(() => {
    async function loadFleets(): Promise<void> {
      const { status, data } = await httpGet('/fleetMechanicals/fleets', {
        params: {
          management_id: user?.allocation?.management_id,
          ...paginate,
        },
      });
      if (status === 200) {
        setFleets(data);
        setPaginate(data.pagination);
      }
    }
    loadFleets();

  }, []); // eslint-disable-line

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Simple />
        <Grid container justify="flex-start" spacing={1}>
          {fleets?.data?.map((fleet) => (
            <Grid item key={fleet.id} xs={12} sm={6} md={6}>
              <Content onClick={() => history.push(`/maintenance/${fleet.id}`)}>
                <Card>
                  <CardHeader>
                    <WrapperName>
                      {fleet?.status_vehicle
                        ? <><PoliceCarFill /><p> {fleet?.plate}</p></>
                        : <><BlockIcon /><span> {fleet?.plate}</span></>}
                    </WrapperName>
                  </CardHeader>
                  <CardContent>
                    <Grid container spacing={1}>
                      <Flex title="Marca/Modelo" xs={6} sm={6} md={4}>
                        {fleet?.make} / {fleet?.model}
                      </Flex>
                      <Flex title="Combustível" xs={6} sm={6} md={4} textAutoSize>
                        {fleet?.fuel}
                      </Flex>
                      <Flex title="Cor" xs={6} sm={6} md={4}>
                        {fleet?.color}
                      </Flex>
                      <Flex title="Status" xs={6} sm={6} md={4}>
                        {fleet?.status_vehicle
                          ? 'Ativa'
                          : 'Bloqueada'}
                      </Flex>
                      <Situation id={fleet?.id} />
                    </Grid>
                  </CardContent>
                </Card>
              </Content>
            </Grid>
          ))}
        </Grid>
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default Maintenance;
