import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;

  padding: 8px 16px;
`;

export const DrawerContent = styled.div`
  width: 100%;
  padding: 8px 0;
  display: flex;
  form{
    width: 100%;
  }
`;

export const WrapperRoles = styled.div`
  width:100%;
  display: flex;
  justify-content: start;

  min-height: 22px;

  span {
    color: #fff;
    padding: 3px 6px;
    background: var(--color-bg-gray);
    border-radius: 3px;
  }
`;

export const WrapperPaginate = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;

export const WrapperName = styled.div`
  font-size: 18px;
  display:flex;
  align-items:center;

  span{
    font-size: 18px;
    margin-left: 8px;
    text-decoration: line-through;
  }
`;