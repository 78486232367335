export const quiz = {

  questions: [
    {
      question: 'Qual é o tema abordado pela Súmula Vinculante número 11 do STF?',
      choices: [
        'A - Abuso de Autoridade',
        'B - Poder de Polícia',
        'C - Uso de Algemas',
        'D - Fundada Suspeita',
        'E - Legítima Defesa',
      ],
      justify: 'O STF elaborou a súmula 11 para tratar dos critérios excepcionais que autorizam o uso da algema.',
      correctAnswer: 'C - Uso de Algemas',
    },
    {
      question: 'Quais são as consequências para o uso indevido de algemas, de acordo com a Súmula Vinculante número 11 do STF?',
      choices: [
        'A - Nenhuma, pois o uso de algemas é sempre permitido.',
        'B - Advertência formal aos agentes de segurança.',
        'C - Responsabilização disciplinar, civil e criminal dos agentes envolvidos.',
        'D - Apenas a aplicação de multa aos agentes de segurança.',
        'E - Suspensão temporária dos agentes envolvidos.',
      ],
      justify: 'Só é lícito o uso de algemas em casos de resistência e de fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, justificada a excepcionalidade por escrito, sob pena de responsabilidade disciplinar, civil e penal do agente ou da autoridade e de nulidade da prisão ou do ato processual a que se refere, sem prejuízo da responsabilidade civil do Estado.',
      correctAnswer: 'C - Responsabilização disciplinar, civil e criminal dos agentes envolvidos.',
    },
    {
      question:
        'Quais são os benefícios da comunicação eficaz na polícia de proximidade?',
      choices: [
        'A - Transmitir ordens hierárquicas.',
        'B - Resolver conflitos interpessoais.',
        'C - Fornecer informações aos meios de comunicação.',
        'D - Monitorar as atividades dos policiais.',
        'E - Realizar investigações criminais.',
      ],
      justify: 'Quando a comunicação entre a polícia e a população é eficaz, a sensação de segurança aumenta. Os cidadãos se sentem mais confiantes de que a polícia está atenta às suas necessidades e preocupações, e a polícia pode responder de maneira mais adequada às demandas da comunidade. Isso resulta em um ambiente mais seguro para todos os envolvidos. Porém, a diminuição de críticas ao serviço policial é consequência do aumento da sensação de segurança e não o objetivo principal da aproximação entre a polícia e a comunidade.',
      correctAnswer: 'B - Resolver conflitos interpessoais.',
    },
    {
      question: 'Como a comunicação pode ajudar a fortalecer a confiança entre a polícia de proximidade e a comunidade?',
      choices: [
        'A - Divulgar apenas informações positivas sobre as ações policiais.',
        'B - Limitar o acesso da comunidade às informações policiais.',
        'C - Realizar campanhas de conscientização sobre direitos civis.',
        'D - Estabelecer canais de diálogo abertos e transparentes.',
        'E - Aumentar a presença policial ostensiva nas ruas.',
      ],
      justify: 'A comunicação eficaz entre a polícia e a população é essencial para promover a confiança, prevenir o crime, resolver problemas comunitários, aumentar a segurança e melhorar a legitimidade policial. Uma comunicação aberta, transparente e contínua é fundamental para o estabelecimento de uma relação saudável e colaborativa entre as autoridades policiais e os cidadãos',
      correctAnswer: 'D - Estabelecer canais de diálogo abertos e transparentes.',
    }, {
      question: 'Um policial, durante uma abordagem de rotina, suspeita que um indivíduo esteja portando drogas ilícitas. Diante dessa situação, ele decide realizar uma busca pessoal no suspeito. Com base no artigo 244 do Código de Processo Penal, assinale a alternativa correta quanto aos procedimentos que devem ser observados nessa busca pessoal.',
      choices: [
        'A - A busca pessoal pode ser realizada sem a necessidade de fundada suspeita, desde que a abordagem seja feita por um policial.',
        'B - A busca pessoal pode ser realizada apenas na presença de testemunhas, mesmo que exista fundada suspeita.',
        'C - A busca pessoal deve ser realizada de forma reservada e discreta, sem constrangimentos desnecessários à pessoa revistada.',
        'D - A busca pessoal só pode ser realizada mediante ordem judicial, independentemente da suspeita existente.',
        'E - A busca pessoal não pode ser feita, salvo se a pessoa revistada autorizar previamente.',
      ],
      justify: 'Art. 244.  A busca pessoal independerá de mandado, no caso de prisão ou quando houver fundada suspeita de que a pessoa esteja na posse de arma proibida ou de objetos ou papéis que constituam corpo de delito, ou quando a medida for determinada no curso de busca domiciliar.',
      correctAnswer: 'C - A busca pessoal deve ser realizada de forma reservada e discreta, sem constrangimentos desnecessários à pessoa revistada.',
    },
    {
      question: 'Sobre o uso de algemas, é CORRETO afirmar que:',
      choices: [
        'A - Não há necessidade de justificativa, por escrito, do emprego de algemas por parte da autoridade ou de agentes públicos.',
        'B - O uso indevido de algemas por autoridades ou agentes públicos não implica quaisquer responsabilidades para o Estado.',
        'C - O uso de algemas é permitido, desde que haja fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, com justificativa por escrito.',
        'D - Fica a critério da autoridade ou do agente público o uso de algemas diante das circunstâncias reais que envolvem o caso concreto.',
        'E - O uso indevido de algemas não pode resultar na nulidade da prisão e em qualquer consequência processual.',
      ],
      justify: 'Súmula vinculante 11. Só é lícito o uso de algemas em casos de resistência e de fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, justificada a excepcionalidade por escrito, sob pena de responsabilidade disciplinar, civil e penal do agente ou da autoridade e de nulidade da prisão ou do ato processual a que se refere, sem prejuízo da responsabilidade civil do Estado.',
      correctAnswer: 'C - O uso de algemas é permitido, desde que haja fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, com justificativa por escrito.',
    },
    {
      question:
        'O crime de abuso de autoridade foi estabelecido pela Lei nº 13.869/2019. Essa lei revogou a Lei nº 4.898/1965, que anteriormente tratava de alguns casos de abuso de autoridade, mas de forma mais limitada.De acordo com a Lei nº 13.869/2019, sobre os crimes de abuso de autoridade, assinale a alternativa CORRETA:',
      choices: [
        'A - A divergência na interpretação de lei ou na avaliação de fatos e provas configura abuso de autoridade.',
        'B - A lei não prevê como efeito da condenação a perda do cargo, do mandato ou da função pública, entre outros.',
        'C - As condutas previstas na lei constituem crime de abuso de autoridade quando praticadas pelo agente com a finalidade específica de prejudicar outrem ou beneficiar a si mesmo ou a terceiro, ou, ainda, por mero capricho ou satisfação pessoal.',
        'D - Servidor público não pode ser sujeito ativo do crime de abuso de autoridade.',
        'E - É admitida modalidade culposa no crime de abuso de autoridade.',
      ],
      justify: 'A justificativa está no art. 1º, parágrafo 1º da lei Lei nº 13.869/2019: § 1º As condutas descritas nesta Lei constituem crime de abuso de autoridade quando praticadas pelo agente com a finalidade específica de prejudicar outrem ou beneficiar a si mesmo ou a terceiro, ou, ainda, por mero capricho ou satisfação pessoal.',
      correctAnswer: 'C - As condutas previstas na lei constituem crime de abuso de autoridade quando praticadas pelo agente com a finalidade específica de prejudicar outrem ou beneficiar a si mesmo ou a terceiro, ou, ainda, por mero capricho ou satisfação pessoal.',
    },
    {
      question: 'A comunicação eficaz entre a polícia e a população desempenha um papel crucial em várias áreas. Qual dos seguintes benefícios é associado à comunicação eficaz entre a polícia e a população?',
      choices: [
        'A - Aumento da criminalidade nas comunidades.',
        'B - Redução da confiança e colaboração entre a polícia e a população.',
        'C - Promoção da segurança e prevenção do crime.',
        'D - Isolamento social e marginalização de grupos minoritários.',
        'E - Limitação da atuação da polícia no combate ao crime.',
      ],
      justify: 'A comunicação eficaz entre a polícia e a população é fundamental para promover a segurança e prevenir o crime. Quando a comunicação é estabelecida de forma aberta, transparente e colaborativa, a população se sente mais segura, confiante na atuação da polícia e propensa a compartilhar informações relevantes. Isso permite que as autoridades policiais ajam de maneira mais eficaz na prevenção e resolução de crimes, resultando em comunidades mais seguras e protegidas.',
      correctAnswer: 'C - Promoção da segurança e prevenção do crime.',
    },
    {
      question:
        'De acordo com o artigo 244 do Código de Processo Penal, a busca pessoal pode ser realizada por policiais em determinadas situações. Assinale a alternativa que indica corretamente uma das justificativas para a realização da busca pessoal.',
      choices: [
        'A - Quando há fundada suspeita de que o indivíduo possui objetos ilícitos ou instrumentos que possam ser utilizados na prática de um crime.',
        'B - Quando o indivíduo demonstra notório nervosismo com a presença dos policiais.',
        'C - Quando o suspeito é conhecido por ter antecedentes criminais, independentemente da existência de qualquer suspeita.',
        'D - Quando o policial responsável pela abordagem possui um pressentimento de que o indivíduo está cometendo um crime.',
        'E - Quando o suspeito está usando vestimentas inapropriadas para o local.',
      ],
      justify: 'A previsão legal para a revista pessoal está presente no artigo 244 do Código de Processo Penal. Art. 244.  A busca pessoal independerá de mandado, no caso de prisão ou quando houver fundada suspeita de que a pessoa esteja na posse de arma proibida ou de objetos ou papéis que constituam corpo de delito, ou quando a medida for determinada no curso de busca domiciliar.',
      correctAnswer: 'A - Quando há fundada suspeita de que o indivíduo possui objetos ilícitos ou instrumentos que possam ser utilizados na prática de um crime.',
    },
    {
      question: 'Modalidade de policiamento que tem por objetivo estar totalmente integrado com a comunidade local, tão próximo ao ponto de saber identificar os seus anseios, demandas e dificuldades. Nesta integração conquistar a cooperação das pessoas ao ponto de ser possível a redução do uso da força em suas ações de patrulhamento ou mesmo nas ocorrências. Este é o conceito de policiamento relacionado à:',
      choices: [
        'A - Polícia Judiciária.',
        'B - Polícia Reativa.',
        'C - Polícia de Proximidade.',
        'D - Polícia Assistencialista.',
        'E - Polícia Tradicional.',
      ],
      justify: 'O conceito de polícia de proximidade é baseado numa FILOSOFIA e ESTRATÉGIA organizacional de trabalho, onde a polícia e a comunidade atuam em parceria, buscando a identificação, priorização e resolução de problemas daquela região, com foco na melhoria da qualidade de vida da população em geral.',
      correctAnswer: 'C - Polícia de Proximidade.',
    },
    {
      question: 'O crime de abuso de autoridade foi estabelecido pela Lei nº 13.869/2019. Essa lei revogou a Lei nº 4.898/1965, que anteriormente tratava de alguns casos de abuso de autoridade, mas de forma mais limitada. /n De acordo com a Lei nº 13.869/2019, sobre os crimes de abuso de autoridade, assinale a alternativa CORRETA:',
      choices: [
        'A - A divergência na interpretação de lei ou na avaliação de fatos e provas configura abuso de autoridade.',
        'B - A lei não prevê como efeito da condenação a perda do cargo, do mandato ou da função pública, entre outros.',
        'C - As condutas previstas na lei constituem crime de abuso de autoridade quando praticadas pelo agente com a finalidade específica de prejudicar outrem ou beneficiar a si mesmo ou a terceiro, ou, ainda, por mero capricho ou satisfação pessoal.',
        'D - Servidor público não pode ser sujeito ativo do crime de abuso de autoridade.',
        'E - É admitida modalidade culposa no crime de abuso de autoridade.',
      ],
      justify: 'A justificativa está no art. 1º, parágrafo 1º da lei Lei nº 13.869/2019: /n § 1º As condutas descritas nesta Lei constituem crime de abuso de autoridade quando praticadas pelo agente com a finalidade específica de prejudicar outrem ou beneficiar a si mesmo ou a terceiro, ou, ainda, por mero capricho ou satisfação pessoal.',
      correctAnswer: 'C - As condutas previstas na lei constituem crime de abuso de autoridade quando praticadas pelo agente com a finalidade específica de prejudicar outrem ou beneficiar a si mesmo ou a terceiro, ou, ainda, por mero capricho ou satisfação pessoal.',
    },
    {
      question: 'Nos termos do Código de Processo Penal, artigo 244, a busca pessoal: ',
      choices: [
        'A - Não dependerá de mandado da autoridade judicial competente, em caso de prisão em flagrante.',
        'B - Sempre dependerá de mandado da autoridade judicial competente.',
        'C - Dependerá de mandado da autoridade policial competente, em caso de medida determinada no curso de busca domiciliar.',
        'D - Dependerá de mandado da autoridade judicial competente, em caso de fundada suspeita de que a pessoa esteja na posse de arma proibida.',
        'E - Dependerá de mandado da autoridade policial competente, em caso de fundada suspeita de que a pessoa esteja na posse de objetos ou papéis que constituam corpo de delito.',
      ],
      justify: 'A previsão legal para a revista pessoal está presente no artigo 244 do Código de Processo Penal. /n Art. 244.  A busca pessoal independerá de mandado, no caso de prisão ou quando houver fundada suspeita de que a pessoa esteja na posse de arma proibida ou de objetos ou papéis que constituam corpo de delito, ou quando a medida for determinada no curso de busca domiciliar.',
      correctAnswer: 'A - Não dependerá de mandado da autoridade judicial competente, em caso de prisão em flagrante.',
    },
    {
      question: 'Um policial, durante uma abordagem de rotina, suspeita que um indivíduo esteja portando drogas ilícitas. Diante dessa situação, ele decide realizar uma busca pessoal no suspeito. Com base no artigo 244 do Código de Processo Penal, assinale a alternativa correta quanto aos procedimentos que devem ser observados nessa busca pessoal.',
      choices: [
        'A - A busca pessoal pode ser realizada sem a necessidade de fundada suspeita, desde que a abordagem seja feita por um policial.',
        'B - A busca pessoal pode ser realizada apenas na presença de testemunhas, mesmo que exista fundada suspeita.',
        'C - A busca pessoal deve ser realizada de forma reservada e discreta, sem constrangimentos desnecessários à pessoa revistada.',
        'D - A busca pessoal só pode ser realizada mediante ordem judicial, independentemente da suspeita existente.',
        'E - A busca pessoal não pode ser feita, salvo se a pessoa revistada autorizar previamente. ',
      ],
      justify: 'A previsão legal para a revista pessoal está presente no artigo 244 do Código de Processo Penal. /n Art. 244.  A busca pessoal independerá de mandado, no caso de prisão ou quando houver fundada suspeita de que a pessoa esteja na posse de arma proibida ou de objetos ou papéis que constituam corpo de delito, ou quando a medida for determinada no curso de busca domiciliar. ',
      correctAnswer: 'C - A busca pessoal deve ser realizada de forma reservada e discreta, sem constrangimentos desnecessários à pessoa revistada.',
    },
    {
      question: 'Identifique e marque a afirmativa que NÃO está relacionada aos princípios da Polícia Moderna.',
      choices: [
        'A - A missão básica da polícia é prevenir o crime.',
        'B - O uso da força deve ser o último recurso a ser utilizado pelo policial.',
        'C - A polícia deve cumprir as leis e ser imparcial no cumprimento do dever.',
        'D - A aprovação pública das ações da polícia é muito importante.',
        'E - A polícia deve estar próxima da população em que atua. Porém, deve privilegiar grupos específicos. ',
      ],
      justify: 'A polícia deve estar próxima da população em que atua para melhor conhecer os problemas e as dificuldades de forma que a sua atuação seja mais eficiente, sem privilegiar grupos ou classes específicas. ',
      correctAnswer: 'A polícia deve estar próxima da população em que atua. Porém, deve privilegiar grupos específicos. ',
    },
    {
      question: 'De acordo com o entendimento do STF, o uso de algemas:',
      choices: [
        'A - É uma excepcionalidade e deve ser justificado previamente, de forma oral ou por escrito.',
        'B - É restrito à prisão penal, sendo inadmissível na prisão cautelar, devido ao princípio da inocência.',
        'C - Ensejará responsabilidade disciplinar, civil e penal da autoridade que o determinar, caso seja injustificado.',
        'D - Ensejará a anulabilidade da prisão e dos atos subsequentes, caso seja injustificado.',
        'E - É lícito somente nas hipóteses de fundado receio de fuga e de perigo à integridade física de terceiros.',
      ],
      justify: 'Súmula Vinculante 11: Só é lícito o uso de algemas em casos de resistência e de fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, justificada a excepcionalidade por escrito, sob pena de responsabilidade disciplinar, civil e penal do agente ou da autoridade e de nulidade da prisão ou do ato processual a que se refere, sem prejuízo da responsabilidade civil do Estado.',
      correctAnswer: 'C - Ensejará responsabilidade disciplinar, civil e penal da autoridade que o determinar, caso seja injustificado.',
    },
    {
      question: 'Qual item abaixo está relacionado aos princípios fundamentais de atuação da polícia moderna?',
      choices: [
        'A - Coerção e força como principais estratégias de atuação.',
        'B - Centralização das decisões e pouca autonomia dos agentes.',
        'C - Prevenção do crime e manutenção da ordem pública.',
        'D - Segregação das comunidades e falta de interação policial.',
        'E - Ignorar a opinião pública e agir de forma independente.',
      ],
      justify: 'Prevenção do crime e manutenção da ordem pública fazem parte dos princípios fundamentais de atuação da polícia moderna. Os outros itens são, justamente, contrários aos princípios fundamentais.',
      correctAnswer: 'C - Prevenção do crime e manutenção da ordem pública.',
    },
    {
      question: 'Segundo os princípios norteadores de ação da Polícia Moderna, a eficiência da polícia deverá ser medida:',
      choices: [
        'A - Pela ausência de crime e desordem.',
        'B - Pela capacidade da força que emprega.',
        'C - Pelos recursos de logística disponíveis.',
        'D - Pelos recursos humanos disponíveis.',
        'E - Pela quantidade de prisões em flagrante efetuadas.',
      ],
      justify: 'O teste da eficiência da polícia será pela ausência do crime e da desordem, e não pela capacidade de força de reprimir esses problemas',
      correctAnswer: 'A - Pela ausência de crime e desordem.',
    },
    {
      question: 'Os policiais são treinados para aplicar a força de forma proporcional e adequada, usando a quantidade mínima necessária para lidar com a situação em questão. A força deve ser utilizada com o objetivo de controlar a situação e garantir a segurança, buscando evitar ou reduzir danos físicos ou ameaças imediatas. Considerando o texto, marque a alternativa correta sobre o uso da força pelo policial:',
      choices: [
        'A - O uso da força deve ser amplamente empregado pelo policial de serviço, sem restrição, mas a arma de fogo não deve ser utilizada.',
        'B - O uso da força deve ser o último recurso a ser utilizado pelo policial de serviço, o seu uso se dará somente quando todas as ferramentas disponíveis não forem eficientes para a resolução do problema.',
        'C - O uso da força deve ser aplicado indiscriminadamente para garantir o cumprimento da lei.',
        'D - O uso da força é permitido apenas em casos de crimes graves ou ameaças diretas à polícia.',
        'E - O emprego da força jamais deve ser utilizado pelo policial de serviço, independentemente da situação',
      ],
      justify: 'De acordo com os princípios estabelecidos, o uso da força pela polícia deve ser aplicado como último recurso, quando todas as outras opções se mostrarem insuficientes para proteger a vida ou segurança do policial ou de terceiros.',
      correctAnswer: 'B - O uso da força deve ser o último recurso a ser utilizado pelo policial de serviço, o seu uso se dará somente quando todas as ferramentas disponíveis não forem eficientes para a resolução do problema.',
    },
    {
      question: 'Segundo Carlos Adelmar Ferreira, “a Polícia Comunitária resgata a essência da arte de polícia, pois apoia e é apoiada por toda a comunidade, acolhendo expectativas de uma sociedade democrática e pluralista, onde as responsabilidades pela mais estreita observância das leis e da manutenção da paz não incumbem apenas à polícia, mas, também a todos os cidadãos. Com base no texto acima, marque a opção correta em relação à responsabilidade pela segurança pública?',
      choices: [
        'A - A responsabilidade cabe apenas à Polícia Militar, pois exerce o policiamento ostensivo;',
        'B - A responsabilidade cabe exclusivamente à Polícia Civil, pois exerce a função de polícia investigativa;',
        'C - A responsabilidade cabe unicamente à Polícia Penal, através da manutenção da ordem nas unidades prisionais;',
        'D - A responsabilidade é de todos os cidadãos, a quem é conferido também o direito à segurança pública;',
        'E - Cabe apenas às instituições policiais, trabalhando em conjunto.',
      ],
      justify: 'A resposta está presente no artigo 144 da Constituição Federal, que prevê que a segurança pública é dever do Estado, DIREITO E RESPONSABILIDADE DE TODOS.',
      correctAnswer: 'D - A responsabilidade é de todos os cidadãos, a quem é conferido também o direito à segurança pública;',
    },
    {
      question: 'O princípio de polícia de proximidade nominado “Comprometimento da organização com a concessão de poder à comunidade” explicita que:',
      choices: [
        'A - A Polícia deve ser, nas suas estruturas básicas e em seu funcionamento, um serviço democrático. Isso pressupõe a necessária participação de todos os integrantes do serviço e do conjunto da população na elaboração das políticas policiais.',
        'B - Os objetivos da polícia devem corresponder às necessidades da comunidade, ser flexíveis, realizáveis e mensuráveis.',
        'C - Dentro da comunidade, os cidadãos devem participar como plenos parceiros da polícia, dos direitos e das responsabilidades envolvidas na identificação, priorização e solução dos problemas.',
        'D - Uma comunidade com mais poder, trabalhando em conjunto com uma polícia com mais poder, resulta numa situação em que o todo é maior do que a soma das partes.',
        'E - É exercido, visando preservar o interesse da segurança pública nas comunidades resguardando o bem comum em sua maior amplitude.',
      ],
      justify: 'A justificativa vem da análise do próprio conceito de Polícia de Proximidade: Uma estratégia de parceria entre a sociedade e os órgãos de segurança pública no combate a criminalidade. Baseia-se na premissa de que tanto a polícia quanto a comunidade devem trabalhar juntas para identificar, priorizar e resolver problemas relacionados à segurança pública.',
      correctAnswer: 'C - Dentro da comunidade, os cidadãos devem participar como plenos parceiros da polícia, dos direitos e das responsabilidades envolvidas na identificação, priorização e solução dos problemas.',
    },
    {
      question: 'A implantação do sistema de policiamento de proximidade requer que todos na instituição policial conheçam os seus princípios, praticando-os permanentemente e com total honestidade de propósitos. São eles, EXCETO.',
      choices: [
        'A - Mudança interna - O Policiamento Comunitário exige uma abordagem plenamente integrada, envolvendo toda a organização.',
        'B - Ajuda às pessoas com Necessidades Específicas - Valorizar as vidas de pessoas mais vulneráveis: jovens, idosos, minorias, pobres, deficientes, sem teto, etc.',
        'C - Extensão do Mandato Policial - Cada policial passa a atuar como um chefe de polícia local, com autonomia e liberdade para tomar iniciativa, dentro de parâmetros rígidos de responsabilidade.',
        'D - A Polícia Comunitária deve priorizar a publicidade de seus atos, assim conseguir a simpatia da comunidade.',
        'E - Comprometimento da Organização com a concessão de poder à Comunidade.',
      ],
      justify: 'A publicidade das ações de polícia de proximidade é importante, porém não e a prioridade. A prioridade é sempre a prevenção ao delito. ',
      correctAnswer: 'D - A Polícia Comunitária deve priorizar a publicidade de seus atos, assim conseguir a simpatia da comunidade.',
    },
    {
      question: 'Sobre o uso de algemas, assinale a alternativa que corresponde aos exatos termos da Súmula Vinculante editada pelo Supremo Tribunal Federal sobre o tema:',
      choices: [
        'A - Só é lícito o uso de algemas em casos de resistência, de desobediência e de fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, justificada a excepcionalidade por escrito, sob pena de responsabilidade disciplinar, civil e penal do agente ou da autoridade, sendo conservada a prisão ou o ato processual a que se refere, sem prejuízo da responsabilidade civil do Estado.',
        'B - Só é lícito o uso de algemas em casos de desobediência e de fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, justificada a excepcionalidade por escrito, sob pena de responsabilidade disciplinar, civil e penal do agente ou da autoridade e de nulidade da prisão ou do ato processual a que se refere, sem prejuízo da responsabilidade civil do Estado.',
        'C - Só é lícito o uso de algemas em casos de resistência e de fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, justificada a excepcionalidade por escrito, sob pena de responsabilidade disciplinar, civil e penal do agente ou da autoridade e de nulidade da prisão ou do ato processual a que se refere, sem prejuízo da responsabilidade civil do Estado.',
        'D - Só é lícito o uso de algemas em casos de resistência, de desobediência e de fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, justificada a excepcionalidade por escrito, sob pena de responsabilidade disciplinar, civil e penal do agente ou da autoridade e de nulidade da prisão ou do ato processual a que se refere, sem prejuízo da responsabilidade civil do Estado.',
        'E - Só é lícito o uso de algemas em casos de resistência e de fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, justificada a excepcionalidade por escrito, sob pena de responsabilidade disciplinar, civil e penal do agente ou da autoridade, sendo conservada a prisão ou o ato processual a que se refere, sem prejuízo da responsabilidade civil do Estado.',
      ],
      justify: 'Súmula vinculante 11. Só é lícito o uso de algemas em casos de resistência e de fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, justificada a excepcionalidade por escrito, sob pena de responsabilidade disciplinar, civil e penal do agente ou da autoridade e de nulidade da prisão ou do ato processual a que se refere, sem prejuízo da responsabilidade civil do Estado.',
      correctAnswer: 'C - Só é lícito o uso de algemas em casos de resistência e de fundado receio de fuga ou de perigo à integridade física própria ou alheia, por parte do preso ou de terceiros, justificada a excepcionalidade por escrito, sob pena de responsabilidade disciplinar, civil e penal do agente ou da autoridade e de nulidade da prisão ou do ato processual a que se refere, sem prejuízo da responsabilidade civil do Estado.',
    },
    {
      question: 'Qual característica abaixo é relacionada ao policiamento de proximidade? ',
      choices: [
        'A - Abordagem proativa e foco na prevenção do crime.',
        'B - Hierarquia rígida e centralização das decisões.',
        'C - Ênfase na repressão e uso da força.',
        'D - Distanciamento da comunidade e falta de interação.',
        'E - Acesso limitado aos recursos e tecnologias modernas.',
      ],
      justify: 'O policiamento de proximidade difere do policiamento tradicional ao adotar uma abordagem proativa e colocar maior ênfase na prevenção do crime. Por meio de uma presença constante na comunidade, estabelecendo laços de confiança com os moradores e colaborando com outros setores da sociedade, o policiamento de proximidade busca identificar problemas precocemente, atuar de forma preventiva e buscar soluções específicas para cada comunidade.',
      correctAnswer: 'Abordagem proativa e foco na prevenção do crime.',
    },
    {
      question: 'A Polícia de Proximidade tem em sua concepção uma filosofia, estratégia organizacional e uma ferramenta operacional de prevenção primária à disposição dos responsáveis pela segurança pública. Com base nessa informação, marque abaixo o que NÃO é um dos princípios do policiamento de proximidade:',
      choices: [
        'A - Policiamento descentralizado e personalizado.',
        'B - Extensão do mandato policial.',
        'C - Resolução preventiva de problemas em curto e longo prazo.',
        'D - Ajuda às pessoas com necessidades específicas.',
        'E - O papel da polícia é preocupar-se com o crime, em detrimento da prevenção de delitos.',
      ],
      justify: 'A Abordagem proativa e foco na prevenção do crime formam a base de atuação do policiamento de proximidade. Logo, a preocupação principal é evitar que os crimes ocorram.',
      correctAnswer: 'O papel da polícia é preocupar-se com o crime, em detrimento da prevenção de delitos.',
    },
    {
      question: 'Dentre as diferenças entre policiamento tradicional e o policiamento de proximidade, podemos destacar, exceto:',
      choices: [
        'A - No policiamento tradicional, o policial presta contas apenas a seu superior.',
        'B - No policiamento de proximidade, o patrulhamento é distribuído conforme o número de ocorrências, uma vez que é utilizada a racionalização dos meios.',
        'C - O policiamento de proximidade visa dar enfoque mais amplo a resolução de problemas por meio da prevenção.',
        'D - A eficácia do policiamento de proximidade é avaliada através das ausências de crimes e desordem.',
        'E - No policiamento tradicional, o policial é do serviço.',
      ],
      justify: 'A Polícia de proximidade difere da Polícia tradicional ao adotar uma abordagem proativa e colocar maior ênfase na prevenção do crime. Por outro lado, a polícia tradicional muitas vezes se concentra em uma abordagem reativa, respondendo a incidentes após sua ocorrência, com ênfase na repressão e no uso da força quando necessário.',
      correctAnswer: 'No policiamento de proximidade, o patrulhamento é distribuído conforme o número de ocorrências, uma vez que é utilizada a racionalização dos meios.',
    },
    {
      question: 'A Polícia de Proximidade como uma ideia, uma filosofia a ser aplicada ao policiamento, em relação ao local de trabalho, o ideal é que a residência  do policial seja:',
      choices: [
        'A - No quartel mais próximo.',
        'B - Na área em que atua.',
        'C - No quartel-general.',
        'D - Em um bairro distante.',
        'E - Em outra cidade.',
      ],
      justify: 'A resposta correta é a letra B, pois a doutrina de Polícia  de Proximidade admite que o policial terá uma tendência de cuidar melhor do local onde reside.',
      correctAnswer: 'B - Na área em que atua.',
    },
    {
      question: 'A Polícia de Proximidade utiliza um modelo de policiamento que enfatiza a interação próxima e regular entre os policiais e a comunidade em que atuam. O objetivo principal desse tipo de abordagem é fortalecer os laços de confiança entre a polícia e os cidadãos, promovendo uma maior colaboração e cooperação na prevenção e combate ao crime. São princípios da polícia de proximidade, EXCETO:',
      choices: [
        'A - Filosofia e estratégia organizacional.',
        'B - Funções de Polícia marítima e aeroportuária.',
        'C - Policiamento descentralizado e personalizado.',
        'D - Extensão do mandato policial.',
        'E - Ajuda às pessoas com necessidades especiais.',
      ],
      justify: 'Os Princípios da polícia de proximidade não estão relacionados às funções de Polícia Marítima e Aeroportuária, pois estas possuem atribuições específicas, não relacionadas ao policiamento ostensivo.',
      correctAnswer: 'Funções de Polícia marítima e aeroportuária.',
    },
    {
      question: 'Policiais militares, ao fazerem um patrulhamento ostensivo em um bairro residencial famigerado por ocorrências envolvendo tráfico de drogas, avistaram um cidadão que demonstrou nervosismo quando da chegada da equipe policial. Perante a situação, os policiais decidiram revistar o referido cidadão alegando fundada suspeita. Sobre a situação hipotética, está correto o que se afirma em:',
      choices: [
        'A - A revista foi legal, uma vez que os policiais militares detêm do poder de polícia para exercerem suas funções.',
        'B - A revista foi legal ao fato que o suspeito demonstrou nervosismo na presença dos policiais.',
        'C - A revista se caracterizará como legal somente se os policiais constatarem que o suspeito estava praticando um crime.',
        'D - A revista não foi legal, visto que a revista pessoal não é um direito do agente público.',
        'E - A revista não foi legal, dado que a percepção de nervosismo não se qualifica como uma suspeita fundada para fins de busca pessoal.',
      ],
      justify: 'A previsão legal para a revista pessoal está presente no artigo 244 do Código de Processo Penal. Art. 244.  A busca pessoal independerá de mandado, no caso de prisão ou quando houver fundada suspeita de que a pessoa esteja na posse de arma proibida ou de objetos ou papéis que constituam corpo de delito, ou quando a medida for determinada no curso de busca domiciliar.',
      correctAnswer: 'A revista não foi legal, dado que a percepção de nervosismo não se qualifica como uma suspeita fundada para fins de busca pessoal.',
    },
    {
      question: 'Segundo o princípio de Polícia de Proximidade “Policiamento descentralizado e personalizado”, podemos afirmar que:',
      choices: [
        'A - É necessário um policial plenamente envolvido com a comunidade, conhecido pela mesma e conhecedor de suas realidades.',
        'B - É necessária a manutenção de pessoas adequadas à nova polícia.',
        'C - Os problemas mais importantes para a população podem não ser os mais importantes para a polícia.',
        'D - A finalidade da existência da Polícia Militar é promover segurança à população através do policiamento ostensivo.',
        'E - A melhor solução é aquela que satisfaz a comunidade, melhora a segurança, diminui a ansiedade, aumenta a ordem, fortalece os laços entre polícia e comunidade e minimiza ações coercitivas.',
      ],
      justify: 'Somente através de um policiamento descentralizado e personalizado que o policial consegue ser plenamente envolvido com a comunidade, conhecido pela mesma e conhecedor de suas realidades.',
      correctAnswer: 'A - É necessário um policial plenamente envolvido com a comunidade, conhecido pela mesma e conhecedor de suas realidades.',
    },
    {
      question: '“A segurança pública, de forma conceitual, é uma atividade que deve ser prestada pelos órgãos estatais e pela comunidade como um todo que visa proteger a cidadania, de forma a prevenir e controlar atos de criminalidade.”',
      choices: [
        'A - A única responsável pela segurança é a força policial.',
        'B - Comunidade também deve participar da segurança.',
        'C - Controle total de atos de criminalidade é impossível.',
        'D - Prevenção trata das consequências dos atos criminosos.',
        'E - A interação dos policiais com a população não e necessária para a prevenção aos delitos.',
      ],
      justify: 'A opção correta é a letra B, pois o trecho analisado apresenta um conceito de que a segurança pública é uma responsabilidade, também, da comunidade, que deve agir de forma a manter a segurança juntamente com os órgãos estatais.',
      correctAnswer: 'B - Comunidade também deve participar da segurança.',
    },
    {
      question: 'Dentre os objetivos de estabelecer uma comunicação eficaz com a população local, qual alternativa abaixo NÃO está de acordo com esses objetivos?',
      choices: [
        'A - Estabelecer um maior vínculo entre polícia e comunidade, com o objetivo principal de evitar que sejam geradas críticas ao serviço policial.',
        'B - Subsidiar estratégias e ações preventivas e repressivas para coibir outros atos criminosos.',
        'C - Colher informações sobre o crime e características dos infratores.',
        'D - Reconhecer o modus operandi (modo de operação) dos infratores o que possibilitará a identificação de um mesmo infrator ou grupo de infratores que venham praticando vários roubos ou furtos.',
        'E - Oferecer informações às vítimas de crime com o objetivo de reduzir a revitimização.',
      ],
      justify: 'Quando a comunicação entre a polícia e a população é eficaz, a sensação de segurança aumenta. Os cidadãos se sentem mais confiantes de que a polícia está atenta às suas necessidades e preocupações, e a polícia pode responder de maneira mais adequada às demandas da comunidade. Isso resulta em um ambiente mais seguro para todos os envolvidos. Porém, a diminuição de críticas ao serviço policial é consequência do aumento da sensação de segurança e não o objetivo principal da aproximação entre a polícia e a comunidade.',
      correctAnswer: 'A - Estabelecer um maior vínculo entre polícia e comunidade, com o objetivo principal de evitar que sejam geradas críticas ao serviço policial.',
    },
    {
      question: 'Beltrano, policial militar, em operação oficial, verificando que Sicrano transitava com arma de fogo sem porte, promoveu sua prisão. Ao ser indagado por Sicrano sobre sua identidade, Beltrano declarou identidade diversa, asseverando se chamar Fulano. De acordo com a Lei nº 13.869/2019, é correto afirmar que:',
      choices: [
        'A - Caso Beltrano tivesse se negado a informar sua identidade, não praticaria em qualquer crime.',
        'B - O fato de ter se atribuído falsa identidade não corresponde a qualquer tipo penal previsto na Lei nº 13.869/2019.',
        'C - A falsa atribuição de identidade é crime previsto na Lei nº 13.869/2019.',
        'D - Apesar de não ser crime se atribuir falsa identidade, no momento da captura, é conduta típica a conduta do responsável por interrogatório, em sede de procedimento investigatório de infração penal, deixar de se identificar ao preso.',
        'E - Tem a pena agravada aquele que, na condução do interrogatório, atribui a si mesmo falso cargo ou função.',
      ],
      justify: 'A justificativa está presente no Art. 16.  Deixar de identificar-se ou identificar-se falsamente ao preso por ocasião de sua captura ou quando deva fazê-lo durante sua detenção ou prisão:  Parágrafo único.  Incorre na mesma pena quem, como responsável por interrogatório em sede de procedimento investigatório de infração penal, deixa de identificar-se ao preso ou atribui a si mesmo falsa identidade, cargo ou função.',
      correctAnswer: 'C - A falsa atribuição de identidade é crime previsto na Lei nº 13.869/2019.',
    },
  ],
};
