import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';

import {
  distanceDateTime,
} from '../../../../utils';

/** components */
import Flex, { Grid } from '../../../../components/Flex';
import Modal from '../../../../components/ModalSimple';

import { TrashIcon } from '../../../../styles/icons';
import {
  Container, WrapperRef, WrapperProcess, WrapperLink, WrapperStatus, WrapperModal,
} from './styles';

/** hooks */
import { useAuth } from '../../../../hooks/auth';
import { useHttp } from '../../../../hooks/http';

/** interface */
import { ISupport, IFuncVoid } from '../../../../interfaces';

interface ICard {
  support: ISupport;
  handleGoToProcess(id: string):void;
  forceLoad?: IFuncVoid;
}

const Card: React.FC<ICard> = ({ support, handleGoToProcess, forceLoad }) => {
  const { iIs } = useAuth();
  const RefLabel = useRef<HTMLLabelElement>(null);
  const { httpDelete } = useHttp();
  const [ref] = support.id.split('-');
  const [showProcess, setShowProcess] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowProcess(false);
    }, 1000 * 5);

    return () => clearTimeout(timeout);
  }, [showProcess]);

  const handleRemoveTechnician = useCallback(async (id: string) => {
    const { status } = await httpDelete(`/supports/technician/${id}`);
    if (status === 204) {
      RefLabel.current?.click();
      setShowProcess(false);
      if (forceLoad) forceLoad();
    }
  }, [forceLoad, httpDelete]);

  return (
    <>
      <Container onClick={() => setShowProcess(true)} isWarner={support.status === 'Aberto'}>
        <Grid container spacing={1}>
          {showProcess && (
          <WrapperProcess onClick={() => handleGoToProcess(support.id)}>
            Abrir atendimento?
          </WrapperProcess>
          )}

          <Grid item xs={12} sm={12} md={2}>
            <WrapperRef>
              #{ref.toUpperCase()}
            </WrapperRef>
          </Grid>

          <Flex title="Status" xs={6} sm={6} md={2}>
            {support.status}
          </Flex>

          <Flex title="Solicitado há" xs={6} sm={6} md={2}>
            <WrapperLink>
              {distanceDateTime(support.created_at, support.server_time, false)}
            </WrapperLink>
          </Flex>

          <Flex title="Gerência" xs={2} sm={6} md={2}>
            {support?.managements?.initials}
            {support?.managements?.initials && ` - ${support?.managements?.name}`}
          </Flex>
          <>
            {support.status === 'Em andamento'
              ? (
                <Flex title="Tecnico" xs={12} sm={6} md={4}>
                  <WrapperStatus>
                    <span title={`${support.technician?.document} - ${support.technician?.name}`}>
                      {support.technician?.name}
                    </span>
                    {iIs('l8') && <label ref={RefLabel} htmlFor={support.id}><TrashIcon /></label>}
                  </WrapperStatus>
                </Flex>
              )
              : (
                <Flex title="Problema" xs={12} sm={6} md={4}>
                  <WrapperStatus>
                    {support.problem}
                  </WrapperStatus>
                </Flex>
              )}
          </>
        </Grid>
      </Container>
      <Modal id={support.id} width={340} height={200}>
        <WrapperModal>
          <h2>Confirma remover o técnico deste atendimento?</h2>
          <p>esta ação não poderá ser desfeita.</p>
          <button type="button" onClick={() => handleRemoveTechnician(support.id)}>Confirmar</button>
        </WrapperModal>
      </Modal>
    </>
  );
};

export default Card;
