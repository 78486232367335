import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';
import {
  distanceDateTime,
} from '../../../utils';
import Flex, { Grid } from '../../../components/Flex';
import Modal from '../../../components/ModalSimple';
import {
  Container, WrapperRef, WrapperProcess, WrapperLink, WrapperStatus, WrapperModal,
} from './styles';
import { useHttp } from '../../../hooks/http';
import { IMaintenance, IFuncVoid } from '../../../interfaces';

interface ICard {
  maintenance: IMaintenance;
  handleGoToProcess(id: string):void;
  forceLoad?: IFuncVoid;
}

const Card: React.FC<ICard> = ({ maintenance, handleGoToProcess, forceLoad }) => {
  const RefLabel = useRef<HTMLLabelElement>(null);
  const { httpDelete } = useHttp();
  const [ref] = maintenance.id.split('-');
  const [showProcess, setShowProcess] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowProcess(false);
    }, 1000 * 5);

    return () => clearTimeout(timeout);
  }, [showProcess]);

  const handleRemoveTechnician = useCallback(async (id: string) => {
    const { status } = await httpDelete(`/supports/technician/${id}`);
    if (status === 204) {
      RefLabel.current?.click();
      setShowProcess(false);
      if (forceLoad) forceLoad();
    }
  }, [forceLoad, httpDelete]);

  return (
    <>
      <Container
        onClick={() => setShowProcess(true)}
        isSol={maintenance.situation === 'Solicitação'}
        isRet={maintenance.situation === 'Atendimento'}
        isWarner={maintenance.situation === 'Em manutenção'}
      >
        <Grid container spacing={1}>
          {showProcess && (
          <WrapperProcess onClick={() => handleGoToProcess(maintenance.id)}>
            Abrir atendimento?
          </WrapperProcess>
          )}

          <Grid item xs={12} sm={12} md={2}>
            <WrapperRef>
              #{ref.toUpperCase()}
            </WrapperRef>
          </Grid>

          <Flex title="Tipo" xs={6} sm={6} md={2}>
            {maintenance.order}
          </Flex>
          <Flex title="Problema" xs={2} sm={6} md={2}>
            <WrapperStatus>
              {maintenance.problem}
            </WrapperStatus>
          </Flex>
          <Flex title="Solicitado há" xs={6} sm={6} md={2}>
            <WrapperLink>
              {distanceDateTime(maintenance.created_at, maintenance.server_time, false)}
            </WrapperLink>
          </Flex>
          {maintenance.mechanicalof ? (
            <Flex title="Oficina" xs={2} sm={6} md={2}>
              <WrapperStatus>
                {maintenance.mechanicalof}
              </WrapperStatus>
            </Flex>
          ) : (
            <Flex title="Gerência" xs={2} sm={6} md={2}>
              {maintenance?.managements?.name}
            </Flex>
          )}
        </Grid>
      </Container>
      <Modal id={maintenance.id} width={340} height={200}>
        <WrapperModal>
          <h2>Confirma remover o técnico deste atendimento?</h2>
          <p>esta ação não poderá ser desfeita.</p>
          <button type="button" onClick={() => handleRemoveTechnician(maintenance.id)}>Confirmar</button>
        </WrapperModal>
      </Modal>
    </>
  );
};

export default Card;
