import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import {
  Button,
  ButtonIcon,
  Input,
  InputHidden,
  InputMask,
  TextArea,
  WrapperLabel,
} from 'components/Forms';
import React, { useCallback, useRef, useState } from 'react';
import ModalFactory from 'react-modal-promise';
import { useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { InfoIcon } from 'styles/icons';
import { validHours } from 'utils';
import * as Yup from 'yup';
import { ModalOption } from '../../../../components/ModalOption';
import { useHttp } from '../../../../hooks/http';
import { useToast } from '../../../../hooks/toast';
import getValidationError from '../../../../utils/getValidationsErros';
import customStyle from './customStyle';
import {
  Container,
  GridButtons, WrapperBtnCancel, WrapperBtnInfo, WrapperBtnSubmit,
} from './styles';

interface IOptions {
    value: string;
    label: string;
    locality: string;
}

interface ISection {
    id: string;
}

interface IFormSection {
    section: ISection;
}

const SectionTwo: React.FC<IFormSection> = ({ section }) => {
  const [addressOperation, setAddressOperation] = useState<IOptions>();
  const formRef = useRef<FormHandles>(null);
  const { httpGet, httpPost } = useHttp();
  const { addToast } = useToast();
  const history = useHistory();
  const [btnLoading, setBtnLoading] = useState(false);

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 2) return;
    const users: any = [];
    const { status, data } = await httpGet(`/rcols/addresses/${inputValue}`);

    if (status === 200) {
      data.forEach((item: any) => {
        users.push({
          value: item.id,
          label: item.description,
          locality: item.locality,
        });
      });
    }

    callback(users);
  };

  const handleChangeAddressOperation = useCallback((event: any) => {
    setAddressOperation(event);
  }, []);

    formRef.current?.setData({
      ...section,
    });

    const handleSubmit = useCallback(async (payload: any) => {
      const _sectionTwo = {
        ...payload,
        addressOperation_id: addressOperation?.label || null,
        locality: addressOperation?.locality || null,
      };

      setBtnLoading(true);

      try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
              another_not_listed: Yup.string().when('addressOperation_id', {
                is: (addressOperation_id) => addressOperation_id === null, then: Yup.string().ensure().required('Outro End. Não Listado'),
              }),
              departure_meeting_point: Yup.string().test('is-valid', 'Saída do Ponto de Encontro é obrigatório, somente números', (value: any) => validHours(value)),
              operation_assembly: Yup.string().test('is-valid', 'Montagem da Operação é obrigatório', (value: any) => validHours(value)),
              first_approach: Yup.string().test('is-valid', 'Primeira Abordagem é obrigatório', (value: any) => validHours(value)),
              closing: Yup.string().test('is-valid', 'Encerramento é obrigatório', (value: any) => validHours(value)),
              approach: Yup.number().typeError('Abordagem é obrigatório, somente números'),
              reconductor: Yup.number().typeError('Recondutor é obrigatório, somente números'),
              celebrities_authorities: Yup.number().typeError('Celebridades / Autoridades é obrigatório, somente números'),
              four_ml: Yup.number().typeError('0,00 À 0,04mg/l é obrigatório, somente números'),
              thirtythree_ml: Yup.number().typeError('0,05 À 0,33mg/l é obrigatório, somente números'),
              thirtyfour_ml: Yup.number().typeError('> ou = 0,34mg/l é obrigatório, somente números'),
              refusal: Yup.number().typeError('Recusa é obrigatório, somente números'),
              passive_tests_performed: Yup.number().typeError('Testes Passivos Realizados é obrigatório, somente números'),
              cnh_collected: Yup.number().typeError('Cnh Recolhidas é obrigatório, somente números'),
              art307: Yup.number().typeError('Art. 307 Do CTB é obrigatório, somente números'),
              fined: Yup.number().typeError('Multados é obrigatório, somente números'),
              towed: Yup.number().typeError('Rebocados é obrigatório, somente números'),
              criminal_occurrences: Yup.number().typeError('Criminais Diversas é obrigatório, somente números'),
              removal_resolutions: Yup.number().typeError('Deliberações de Remoção é obrigatório, somente números'),
              arrests_means_evidence: Yup.number().typeError('Meios de Prova Alcoolemia é obrigatório, somente números'),
              driving_canceled_license: Yup.number().typeError('CNH Cassada obrigatório, somente números'),
            });

            await schema.validate(_sectionTwo, { abortEarly: false });

            const { status } = await httpPost('rcols/sectiontwo', _sectionTwo);
            if (status === 200) {
              const response = await ModalOption({
                title: 'ATENÇÃO',
                content: 'EXISTE OUTRO LOCAL DE OPERAÇÃO ?',
                buttons: 'sim_nao',
              });

              if (!response) {
                setBtnLoading(false);
                addToast({ title: 'Salvo com Sucesso!', type: 'success' });
                history.push('/rcols');
              } else {
                setBtnLoading(false);
                    formRef.current?.reset();
                    setAddressOperation({} as IOptions);
              }
            } else {
              addToast({ title: 'Não foi possível salvar!', type: 'error' });
              // history.push('/dashboard');
            }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setBtnLoading(false);
          const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
        }
      }
    }, [addressOperation, addToast, history, httpPost]);

    return (
      <Container>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <InputHidden name="id" />
          <Grid container spacing={1}>
            <WrapperLabel label="End. da Operação" xs={12} md={6}>
              <div style={{ width: '100%' }}>
                <AsyncSelect
                  placeholder="Cod. End"
                  noOptionsMessage={() => 'Sem registos'}
                  cacheOptions
                  isClearable
                  loadOptions={loadOptions}
                  onChange={handleChangeAddressOperation}
                  value={addressOperation}
                  styles={customStyle}
                />
              </div>
            </WrapperLabel>
            <WrapperLabel label="Outro End. Não Listado" xs={12} md={6}>
              <Input name="another_not_listed" />
            </WrapperLabel>
            <WrapperLabel label="Saída do Ponto de Encontro" xs={12} md={6}>
              <InputMask type="tel" mask="99:99" name="departure_meeting_point" placeholder="__:__" />
            </WrapperLabel>
            <WrapperLabel label="Montagem da Operação" xs={12} md={6}>
              <InputMask type="tel" mask="99:99" name="operation_assembly" placeholder="__:__" />
            </WrapperLabel>
            <WrapperLabel label="Primeira Abordagem" xs={12} md={6}>
              <InputMask type="tel" mask="99:99" name="first_approach" placeholder="__:__" />
            </WrapperLabel>
            <WrapperLabel label="Encerramento" xs={12} md={6}>
              <InputMask type="tel" mask="99:99" name="closing" placeholder="__:__" />
            </WrapperLabel>
            <WrapperLabel label="Abordagem" xs={12} md={6}>
              <Input name="approach" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Recondutor" xs={12} md={6}>
              <Input name="reconductor" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Recusa" xs={12} md={6}>
              <Input name="refusal" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Celebridades / Autoridades" xs={12} md={6}>
              <Input name="celebrities_authorities" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="0,00 À 0,04mg/l" xs={12} md={6}>
              <Input name="four_ml" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="0,05 À 0,33mg/l" xs={12} md={6}>
              <Input name="thirtythree_ml" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="> ou = 0,34mg/l" xs={12} md={6}>
              <Input name="thirtyfour_ml" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Quantidade de Testes Passivos Realizados" xs={12} md={6}>
              <Input name="passive_tests_performed" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Cnh Recolhidas" xs={12} md={6}>
              <Input name="cnh_collected" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Multados" xs={12} md={6}>
              <Input name="fined" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Rebocados" xs={12} md={6}>
              <Input name="towed" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Deliberações de Remoção" xs={12} md={6}>
              <Input name="removal_resolutions" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Prisões por outros Meios de Prova Alcoolemia" xs={12} md={6}>
              <Input name="arrests_means_evidence" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Art. 307 Do CTB" xs={12} md={6}>
              <Input name="art307" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Dirigir Com CNH Cassada" xs={12} md={6}>
              <Input name="driving_canceled_license" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Ocorrências Diversas" xs={12} md={6}>
              <Input name="criminal_occurrences" maxLength={3} />
            </WrapperLabel>
            <WrapperLabel label="Observações Deliberações de Veículos" xs={12}>
              <TextArea row={4} name="vehicle_resolutions" />
            </WrapperLabel>
            <WrapperLabel label="Observações Testes Administrativos" xs={12}>
              <TextArea row={4} name="administrative_tests" />
            </WrapperLabel>
          </Grid>
          <GridButtons>
            <WrapperBtnInfo>
              <ButtonIcon
                title="Instruções"
              >
                <InfoIcon />
              </ButtonIcon>
            </WrapperBtnInfo>
            <WrapperBtnCancel>
              <Button
                type="button"
                onClick={() => { history.push('/dashboard'); }}
              >
                Cancelar
              </Button>
            </WrapperBtnCancel>
            <WrapperBtnSubmit>
              <Button
                type="submit"
                isLoading={btnLoading}
              >
                Avançar
              </Button>
            </WrapperBtnSubmit>
          </GridButtons>
        </UForm>
        <ModalFactory />
      </Container>
    );
};

export default SectionTwo;
