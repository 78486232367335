import React, {
  useRef, useEffect, useCallback,
  useState,
} from 'react';

import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import typeFunction from 'utils/typeFunction';

import getValidationError from '../../../utils/getValidationsErros';
import { formatDate, parseDate } from '../../../utils';
/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** componentes */
import Layout from '../../../components/Layout';
import HeaderSimple from '../../../components/Headers/Simple';
import {
  WrapperLabel, Input, InputMask, InputID, InputPhone, Button, Select,
} from '../../../components/Forms';
import Tabs, { TabsItem } from '../../../components/Tabs';
import { PersonIcon } from '../../../styles/icons';
import { Container } from './styles';

/** interfaces */

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [loadingButton, setLoadingButton] = useState(false);

  const {
    httpGet, httpPut, loading,
  } = useHttp();

  useEffect(() => {
    formRef.current?.reset();
    addToast({ type: 'warning', title: 'Atenção!', description: 'Antes de prosseguir, atualize seu cadastro.' });
    async function loadUser(): Promise<void> {
      const { data } = await httpGet('/me');

      formRef.current?.setData({
        ...data,
        birthday: formatDate(data?.birthday),
        function: { value: data.function, label: data.function },
      });
    }

    loadUser();

  }, []);// eslint-disable-line

  const handleSubmit = useCallback(async (payload: any) => {
    const data = {
      ...payload,
      war_name: payload.war_name.toUpperCase(),
      document_secondary: payload.document_secondary.replace(/\D/g, ''),
      birthday: parseDate(payload.birthday),
      function: payload.function?.value,
    };
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        function: Yup.string().required('Insira a sua função'),
        rg: Yup.string().required('Insira o seu RG'),
        war_name: Yup.string().required('Insira seu nome de escala'),
      });

      await schema.validate(data, { abortEarly: false });
      setLoadingButton(true);
      const { status } = await httpPut('profile', data);
      setLoadingButton(true);
      if (status === 200) {
        addToast({ title: 'Atualizado com sucesso.' });
        history.push('/dashboard');
      }
      setLoadingButton(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
        setLoadingButton(false);
      }
      setLoadingButton(false);
    }
  }, []);// eslint-disable-line

  const breadcrumbs = {
    icon: PersonIcon,
    links: [
      { path: '', title: 'Meus dados' },
    ],
  };
  return (

    <Layout breadcrumbs={breadcrumbs} loading={loading}>

      <Container>
        <HeaderSimple> Meus dados </HeaderSimple>
        <Tabs>

          <TabsItem title="Perfil" show>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <WrapperLabel label="Nome" xs={12} md={6}>
                  <Input name="name" disabled />
                </WrapperLabel>
                <WrapperLabel label="E-mail" xs={12} md={6}>
                  <Input disabled name="email" type="text" />
                </WrapperLabel>

                <WrapperLabel label="CPF" xs={12} md={3}>
                  <InputMask disabled name="document" type="tel" mask="999.999.999-99" />
                </WrapperLabel>

                <WrapperLabel label="ID Funcional" xs={12} md={3}>
                  <InputID disabled name="document_secondary" />
                </WrapperLabel>

                <WrapperLabel label="Nascimento" xs={12} md={3}>
                  <InputMask disabled name="birthday" type="tel" mask="99/99/9999" />
                </WrapperLabel>

                <WrapperLabel label="Celular" xs={12} md={3}>
                  <InputPhone disabled name="phone" />
                </WrapperLabel>

                <WrapperLabel label="Função" xs={12} sm={4} md={4}>
                  <Select name="function" options={typeFunction} />
                </WrapperLabel>

                <WrapperLabel label="Rg" xs={12} sm={6} md={4}>
                  <Input name="rg" minLength={5} />
                </WrapperLabel>

                <WrapperLabel label="Nome de Escala" xs={12} sm={4} md={4}>
                  <Input name="war_name" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

              </Grid>

              <Grid container justify="flex-end">
                <Grid item xs={12} md={4}>
                  <Button type="submit" disabled={loadingButton}>Salvar</Button>
                </Grid>
              </Grid>
            </UForm>
          </TabsItem>

        </Tabs>

      </Container>
    </Layout>
  );
};

export default Profile;
