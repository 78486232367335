import React from 'react';

import { Initials } from './styles';

interface IUserName{
  name: string;

}
const InitialIcon: React.FC<IUserName> = ({ name }) => {
  const handleInitial = (): string => {
    const _name = name.trim().split(' ');

    if (_name.length > 1) {
      const index = _name.length - 1;
      return `${_name[0].slice(0, 1)}${_name[index].slice(0, 1)}`;
    }

    return `${_name[0].slice(0, 2)}`;
  };

  return (
    <Initials>
      {handleInitial()}
    </Initials>
  );
};

export default InitialIcon;
