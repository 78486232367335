import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;

`;

export const Content = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  .first{
    margin-top: -16px;
    border-top: 0;
  }

  .hidden{
    display: none;
  }
`;

export const Title = styled.div`
  padding: 12px;
  margin: 16px -16px;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
`;

export const WrapperConduction = styled.div`
  width: 100%;
  margin-top: 8px;
  label > span {
    color: red !important;
  }
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  h2{
    font-size: 1em;
    font-weight: 700;
  }
  span{
    display: flex;
    align-items: center;
    font-weight: 700;
  }
`;

export const GridButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 40px 1fr 1fr 1fr;
  grid-template-rows: 56px;
  grid-template-areas:
    'btn-inf space btn-cancel btn-submit';


  @media (max-width: 767.98px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 56px 56px;
    grid-template-areas:
      'btn-cancel btn-submit'
      'btn-inf space';
  }
`;

export const WrapperButton = styled.span`
  display: flex;
  width: 100%;
  > div{
    width: calc(100% - 48px);
    input{
      width: calc(100% - 46px);
    }
  }

  button{
    width: 40px;
  }

`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;

export const WrapperBtnCancel = styled.div`
  grid-area: btn-cancel;
`;

export const WrapperBtnSubmit = styled.div`
  grid-area: btn-submit;
`;
