import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import {
  Button, WrapperLabel, InputMask, TextArea,
  Input,
} from '../../components/Forms';
import { WhatsIcon } from '../../styles/icons';

import {
  Container, WrapperInfo, LinkInfo, Header, Body,
} from './styles';

/** interfaces */
interface IFormData {
  document: string;
  name: string;
}

const CadTemplate: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data: IFormData) => {
    console.log(data);

  }, []);// eslint-disable-line

  return (
    <Container>
      <Header>
        TOP
      </Header>
      <Body>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Grid spacing={1}>
            <WrapperLabel label="Nome completo" xs={12}>
              <Input name="name" />
            </WrapperLabel>
            <WrapperLabel label="Documento de identidade" xs={12}>
              <Input name="document" />
            </WrapperLabel>
            <WrapperLabel label="Tipo de registro" xs={12}>
              <Input name="text" />
            </WrapperLabel>
            <WrapperLabel label="Número do registro" xs={12}>
              <InputMask mask="" name="register_number" />
            </WrapperLabel>

            <WrapperLabel label="Dinâmica" xs={12}>
              <TextArea name="description" row={3} />
            </WrapperLabel>
          </Grid>

          <Button
            type="submit"
            color="secondary"
            size="large"
          >Entrar
          </Button>
          <Link to="/preview">Não é cadastrado? Recuperar a Senha? Clique aqui</Link>
          <WrapperInfo>
            <h3>Suporte Tecnico</h3>
            <a
              href="https://api.whatsapp.com/send?phone=+5521986052654"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkInfo>
                <WhatsIcon /><span>(21) 98605-2654</span>
              </LinkInfo>
            </a>

          </WrapperInfo>
        </Form>
      </Body>
    </Container>
  );
};

export default CadTemplate;
