import React from 'react';
import {
  formatCpf, formatPlate, formatDate,
} from '../../../utils';
import Flex, { Grid } from '../../../components/Flex';
import CopyToClipboard from '../../../components/CopyToClipboard';
import { Container } from './styles';

import { IOrder } from '../../../interfaces';

const Reported: React.FC<{order: IOrder}> = ({ order }) => (
  <Container>
    <Grid container spacing={1}>
      <Flex title="Gerência" xs={12} sm={4} textAutoSize>
        { order.management?.initials }
        { order.sector && ` - ${order.sector?.initials}` }
      </Flex>

      <Flex title="Solicitado por" xs={12} sm={8} textAutoSize>
        {` ${formatCpf(order.user?.document)} - ${order.user?.first_name}, ${order.user?.phone}`}
      </Flex>

      {order.type === 'vehicle' && (
      <>
        <Flex title="Placa" xs={6} sm={4} md={4}>
          <CopyToClipboard text={order.plate}>
            {formatPlate(order.plate)}
          </CopyToClipboard>
        </Flex>
        <Flex title="Chassi" xs={6} sm={4} md={4}>
          <CopyToClipboard text={order.chassi}>
            {order.chassi || 'Não informado'}
          </CopyToClipboard>
        </Flex>
        <Flex title="Motor" xs={6} sm={4} md={4}>
          <CopyToClipboard text={order.chassi}>
            {order.engine_number || 'Não informado'}
          </CopyToClipboard>
        </Flex>
        <Flex title="Marca/Modelo" xs={6} sm={4} md={4}>
          <CopyToClipboard text={order.brand}>
            {order.brand || 'Não informado'}
          </CopyToClipboard>
        </Flex>
        <Flex title="Cor" xs={4} sm={3} md={3}>
          <CopyToClipboard text={order.color}>
            {order.color || 'Não informada'}
          </CopyToClipboard>
        </Flex>
        <Flex title="Observações" xs={6} sm={8} md={12}>
          {order.description || 'Não informado'}
        </Flex>
      </>
      )}

      {order.type === 'person' && (
      <>
        <Flex title="CPF" xs={6} sm={3} md={2} textAutoSize>
          <CopyToClipboard text={order.document}>
            {formatCpf(order.document)}
          </CopyToClipboard>
        </Flex>
        <Flex title="RG" xs={6} sm={4} md={2} textAutoSize>
          <CopyToClipboard text={order.document_secondary}>
            {order.document_secondary}
          </CopyToClipboard>
        </Flex>
        <Flex title="Nome" xs={12} sm={4} md={4} textAutoSize>
          <CopyToClipboard text={order.name}>
            {order.name}
          </CopyToClipboard>
        </Flex>
        <Flex title="Nascimento" xs={12} sm={3} md={4}>
          <CopyToClipboard text={order.birthday ? formatDate(order.birthday) : ''}>
            {order.birthday ? formatDate(order.birthday) : 'não informado'}
          </CopyToClipboard>
        </Flex>

        <Flex title="Mãe" xs={6} sm={4} md={4} textAutoSize>
          <CopyToClipboard text={order.mather}>
            {order.mather}
          </CopyToClipboard>
        </Flex>
        <Flex title="Pai" xs={6} sm={4} md={4} textAutoSize>
          <CopyToClipboard text={order.father}>
            {order.father}
          </CopyToClipboard>
        </Flex>
        <Flex title="Observação" xs={12} sm={12} md={4}>
          {order.description || 'não informado'}
        </Flex>
      </>
      )}

    </Grid>
  </Container>
);

export default Reported;
