import React, { useState, useEffect } from 'react';

import Loading from 'components/Loading';
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

import Nuint from './Nuint';
import Data from './Data';

interface IForm {
  coord: {
    lat: number;
    lng: number;
  };
}

const Person: React.FC<IForm> = ({ coord }) => {
  const { httpGet } = useHttp();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const { addToast } = useToast();

  useEffect(() => {
    (async (): Promise<void> => {
      const { status } = await httpGet('/orders/autocomplete/on');
      if (status === 200) {
        setCollapsed(true);
        setLoading(false);
      } else {
        // addToast({ title: data?.message || 'aguardando...', type: 'warning' });
        setCollapsed(false);
        setLoading(false);
      }
    })();
  }, [httpGet, addToast]);

  if (loading) {
    return <Loading loading={loading} />;
  }
  return collapsed ? <Data coord={coord} /> : <Nuint coord={coord} />;
};

export default Person;
