import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/** hooks */
import { useHttp } from '../../hooks/http';

/** componentes */
import Layout from '../../components/Layout';
import HeaderFilterPlus from '../../components/Headers/FilterPlus';
import Pagination from '../../components/Pagination';
import Card from './Card';

import { CarIcon } from '../../styles/icons';

import { Container } from './styles';

import { IPaginate } from '../../interfaces';

const breadcrumbs = {
  icon: CarIcon,
  links: [{ title: 'Veículos', path: '' }],
};

const Vehicles: React.FC = () => {
  const [vehicles, setVehicles] = useState([]);

  const [filter, setFilter] = useState('');
  const [paginate, setPaginate] = React.useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const { httpGet, loading } = useHttp();
  const history = useHistory();

  const handleSearch = (value: string): void => {
    setPaginate({
      ...paginate,
      page: 1,
    });
    setFilter(value);
  };

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  useEffect(() => {
    async function loadVehicles(): Promise<void> {
      const { status, data } = await httpGet('vehicles', {
        params: {
          ...paginate,
          filter,
        },
      });

      if (status === 200) {
        setVehicles(data.data);
        setPaginate(data.pagination);
      }
    }

    loadVehicles();

  }, [filter, paginate.page]); // eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>

        <HeaderFilterPlus handleSearch={handleSearch} handlePlus={() => history.push('/vehicle')} />
        {vehicles && vehicles.map((vehicle: any) => (
          <Card key={vehicle.id} vehicle={vehicle} />
        ))}
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />

      </Container>
    </Layout>

  );
};

export default Vehicles;
