import React, { useState, useCallback, useEffect } from 'react';
import Layout from 'components/Layout';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useHttp } from 'hooks/http';
import { IFleet } from 'interfaces';
import HeaderSavePlus from '../../components/Headers/SavePlus';
import Tabs, { TabsItem } from '../../components/Tabs';
import { Container } from './styles';
import { PoliceCarFill } from '../../styles/icons';
import Profile from './Profile';

const breadcrumbs = {
  icon: PoliceCarFill,
  links: [
    { path: '/fleets', title: 'Viaturas' },
    { path: '', title: 'Viatura' },
  ],
};

const Form: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { httpGet, loading } = useHttp();
  const [fleet, setFleet] = useState({} as IFleet);
  const history = useHistory();

  useEffect(() => {
    async function loadFleet(): Promise<void> {
      const { data } = await httpGet(`/fleets/${id}`);
      setFleet(data);
    }

    id && loadFleet();
  }, [id]);// eslint-disable-line

  const handlePlus = useCallback(() => {
    history.push('/fleet');
    setFleet({} as IFleet);
  }, [history]);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderSavePlus
          hiddenButton={!id}
          handlePlus={handlePlus}
        >
          {id ? `Editar ${fleet?.plate}` : 'Nova viatura'}
        </HeaderSavePlus>

        <Tabs>
          <TabsItem title="Dados" show>
            {id && <Profile fleet={fleet} />}
            {!id && <Profile fleet={{} as IFleet} />}
          </TabsItem>
        </Tabs>
      </Container>
    </Layout>
  );
};

export default Form;
