import React, { useCallback, useEffect, useRef } from 'react';
import { Form as UForm } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';

import getValidationError from '../../../utils/getValidationsErros';
import {
  validateCpf, validateRG, formatCpf, formatDateTime, parseDate, formatDate,
} from '../../../utils';
/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** componentes */
import { ModalDialog } from '../../../components/ModalDialog';
import Flex, { Grid } from '../../../components/Flex';
import {
  WrapperLabel, Input, InputHidden, TextArea, InputMask, Select, Button,
} from '../../../components/Forms';
import { Container } from './styles';

/** interfaces */
import { IPerson } from '../../../interfaces';

interface PersonFormProps {
  person: IPerson;
}

const Profile: React.FC<PersonFormProps> = ({ person }) => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const {
    httpGet, httpPost, httpPut, loading, unLoading,
  } = useHttp();

  useEffect(() => {
    unLoading();

    if (person) {
      formRef.current?.setData({
        ...person,
        birthday: formatDate(person.birthday),
        gender: { value: person.gender, label: person.gender },
      });
    }
  }, [person]); // eslint-disable-line

  const handleSubmit = useCallback(async (payload: any) => {
    const data = {
      ...payload,
      birthday: parseDate(payload.birthday),
      document: payload.document.replace(/\D/g, ''),
      gender: payload?.gender?.value,
    };

    formRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        document: Yup.string().nullable()
          .test('is-valid', 'Insira um CPF válido', (value) => validateCpf(value, false)),
        document_secondary: Yup.string().nullable()
          .test('is-valid', 'Insira um RG válido', (value) => validateRG(value, true)),
        birthday: Yup.date().typeError('Insira uma data válida'),
        gender: Yup.string().ensure().required('Gênro é obrigatório'),
        mather: Yup.string(),
        father: Yup.string(),
      });

      await schema.validate(data, { abortEarly: false });
      if (person?.id) {
        const { status } = await httpPut(`/people/${person.id}`, data);
        if (status === 200) {
          addToast({ title: 'Editado com sucesso' });
        }
      } else {
        const { status, data: person } = await httpPost('/people', data);
        if (status === 200) {
          history.push(`/person/${person.id}`);
          addToast({ title: 'Salvo com sucesso' });
        }
      }
    } catch (err) {
      const erros = getValidationError(err);
      formRef.current?.setErrors(erros);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, history, person]);

  const handleIsPersonExist = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const document = (event.target.value.replace(/\D/g, ''));
    const id = formRef.current?.getFieldValue('id');

    if (document.length > 10 && !id) {
      const { data } = await httpGet('people', { params: { filter: document } }, false);
      if (data.data.length) {
        const confirm = await ModalDialog({
          title: 'Opss!',
          content: `Já existe uma pessoa cadastrada com esse CPF.
          Vou redirecionar você para o cadastro dela para você atualizar
          os dados caso necessário.`,
          buttons: 'cancel_confirm',
        });

        if (confirm) {
          const _id = data.data[0].id;
          history.push(`person/${_id}`);
        }
      }
    }
  }, [formRef]);// eslint-disable-line

  return (
    <Container>

      <UForm
        ref={formRef}
        onSubmit={handleSubmit}

      >
        <Grid container spacing={1}>
          <InputHidden name="id" />
          <WrapperLabel label="CPF" xs={12} sm={3} md={3}>
            <InputMask name="document" type="tel" mask="999.999.999-99" onBlur={handleIsPersonExist} />
          </WrapperLabel>
          <WrapperLabel label="RG" xs={12} sm={3} md={3}>
            <Input name="document_secondary" />
          </WrapperLabel>
          <WrapperLabel label="Nascimento" xs={12} sm={3} md={3}>
            <InputMask name="birthday" type="tel" mask="99/99/9999" placeholder="DD/MM/YYYY" />
          </WrapperLabel>
          <WrapperLabel label="Gênero" xs={12} sm={3} md={3}>
            <Select
              name="gender"
              options={[
                { value: 'Masculino', label: 'Masculino' },
                { value: 'Feminino', label: 'Feminino' },
              ]}
            />
          </WrapperLabel>
          <WrapperLabel label="Nome" xs={12} md={8}>
            <Input name="name" />
          </WrapperLabel>
          <WrapperLabel label="Apelido" xs={12} md={4}>
            <Input name="nickname" />
          </WrapperLabel>
          <WrapperLabel label="Mãe" xs={12} md={6}>
            <Input name="mather" />
          </WrapperLabel>
          <WrapperLabel label="Pai" xs={12} md={6}>
            <Input name="father" />
          </WrapperLabel>

          <WrapperLabel label="Observações" xs={12} md={12}>
            <TextArea row={1} name="description" />
          </WrapperLabel>

        </Grid>

        <Grid container justify="flex-end" alignItems="baseline">
          {person?.user && (
            <Flex title="Última edição" xs={12} md={8} textAutoSize>
              {formatDateTime(person?.updated_at)}{' '}
              {person?.user?.name}{' '}
              {formatCpf(person?.user?.document)}
            </Flex>
          )}

          <Grid item xs={12} md={4}>
            <Button type="submit" isLoading={loading}>Salvar</Button>
          </Grid>
        </Grid>

      </UForm>

    </Container>
  );
};

export default Profile;
