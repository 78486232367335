import React from 'react';
import CountUp from 'react-countup';

import { ContainerSelect } from './styles';

interface IDashBox {
    title: string;
    amount: number;
    color: string;
}

const DashBoxSelect: React.FC<IDashBox> = ({
  title,
  amount,
  color,
}) =>{
  return (
    <ContainerSelect color={color}>
      <span>{title}</span>
      <h1>
        <CountUp
          end={amount}
        />
      </h1>
    </ContainerSelect>
  );
};

export default DashBoxSelect;
