import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';

import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../hooks/http';

/** componentes */
import {
  WrapperLabel, Input, Button, ButtonIcon,
} from '../../../components/Forms';
import { Grid } from '../../../components/Flex';
import Layout from '../../../components/Layout';
import Card from './Card';
import { GearIcon, ArrowLeftRightIcon } from '../../../styles/icons';
import {
  Container, WrapperGrid, WrapperFrmAnalise, WrapperResultA,
  WrapperAction, WrapperResultB, WrapperScroll, WrapperRemove,
  ButtonStatus,
  WrapperInputs,
} from './styles';

const FixAnswer: React.FC = () => {
  const breadcrumbs = {
    icon: GearIcon,
    links: [{ path: '', title: 'Consertar Respostas' }],
  };

  const formRef = useRef<FormHandles>(null);
  const [occurrencesA, setOccurrencesA] = useState<any>([]);
  const [occurrencesB, setOccurrencesB] = useState<any>([]);
  const [aid, setAid] = useState<string|null>(null);
  const [bid, setBid] = useState<string|null>(null);

  const {
    httpPost, httpPut, httpDelete, loading, unLoading,
  } = useHttp();

  useEffect(() => {
    unLoading();
  }, [unLoading]);

  const handleSubmit = useCallback(async (payload: any) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        aid: Yup.string().required('ID da pessoa é obrigatório'),
        bid: Yup.string().required('ID da pessoa é obrigatório'),
      });

      await schema.validate(payload, { abortEarly: false });

      setAid(payload.aid);
      setBid(payload.bid);

      const { status, data } = await httpPost('/fix-answer/person', payload);
      if (status === 200) {
        const a = data.filter((occurrence: any) => occurrence.person_id === payload.aid);
        setOccurrencesA(a);
        const b = data.filter((occurrence: any) => occurrence.person_id === payload.bid);
        setOccurrencesB(b);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
      formRef.current?.setErrors(erros);
      }
    }
  }, [httpPost]);

  const handleMove = useCallback(async () => {
    const { status, data } = await httpPut('/fix-answer/person', { aid, bid });
    if (status === 200) {
      const a = data.filter((occurrence: any) => occurrence.person_id === aid);
      setOccurrencesA(a);
      const b = data.filter((occurrence: any) => occurrence.person_id === bid);
      setOccurrencesB(b);
    }
  }, [aid, bid, httpPut]);

  const handleDestroy = useCallback(async () => {
    const { status } = await httpDelete(`/fix-answer/person/${aid}`);
    if (status === 204) {
      setOccurrencesA([]);
      setOccurrencesB([]);
      formRef.current?.reset();
    }
  }, [aid, httpDelete]);

  const handleInvert = useCallback(() => {
    const aid = formRef.current?.getFieldValue('aid');
    formRef.current?.setFieldValue('aid', formRef.current?.getFieldValue('bid'));
    formRef.current?.setFieldValue('bid', aid);
  }, []);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <WrapperGrid>
          <WrapperFrmAnalise>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1} alignItems="flex-end">
                <WrapperInputs>
                  <WrapperLabel label="de (ID da Pessoa)" style={{ width: 'calc(50% - 28px)' }}>
                    <Input name="aid" />
                  </WrapperLabel>
                  <Grid item>
                    <ButtonIcon title="Inverter" onClick={handleInvert}><ArrowLeftRightIcon /></ButtonIcon>
                  </Grid>
                  <WrapperLabel label="para (ID da Pessoa)" style={{ width: 'calc(50% - 28px)' }}>
                    <Input name="bid" />
                  </WrapperLabel>
                </WrapperInputs>
              </Grid>
              <Grid container justify="flex-end">
                <Grid item xs={4}>
                  <Button type="submit" isLoading={loading}>Análisar</Button>
                </Grid>
              </Grid>
            </UForm>
          </WrapperFrmAnalise>
          <WrapperResultA>
            Ocorrências: {occurrencesA.length}
            <WrapperScroll>
              {occurrencesA && occurrencesA
                .map((occurrence: any) => <Card key={occurrence.id} occurrence={occurrence} />)}
            </WrapperScroll>
          </WrapperResultA>
          <WrapperAction>
            <ButtonStatus
              onClick={handleMove}
              status={occurrencesA.length}
              style={{ width: '100%', height: '100%' }}
              disabled={occurrencesA.length < 1 || loading}
              title="Repassar respostas"
            >
              {'>'}
            </ButtonStatus>
          </WrapperAction>
          <WrapperResultB>
            Ocorrências: {occurrencesB.length}
            <WrapperScroll>
              {occurrencesB && occurrencesB
                .map((occurrence: any) => <Card key={occurrence.id} occurrence={occurrence} />)}
            </WrapperScroll>
          </WrapperResultB>
          <WrapperRemove>
            {occurrencesB.length > 0 && (
              <Grid container>
                <Grid item xs={4}>
                  <ButtonStatus
                    onClick={handleDestroy}
                    status={occurrencesA.length > 0 ? 0 : 1}
                    disabled={occurrencesA.length > 0 || loading}
                  >
                    Remover
                  </ButtonStatus>
                </Grid>
              </Grid>
            )}
          </WrapperRemove>
        </WrapperGrid>
      </Container>
    </Layout>
  );
};

export default FixAnswer;
