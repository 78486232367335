import React, {
    useRef, useCallback, useEffect
} from 'react';
import Flex from 'components/Flex';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import getValidationError from '../../../../../utils/getValidationsErros';
import { useHttp } from '../../../../../hooks/http';
import { useAuth } from '../../../../../hooks/auth';
import { useToast } from '../../../../../hooks/toast';
import { InfoIcon } from 'styles/icons';
import { formatDate } from '../../../../../utils';
import {
    Button,
    ButtonIcon,
    TextArea,
    WrapperLabel,
    Input,
    InputHidden
} from '../../../../../components/Forms';
import {
    Container,
    Content,
    GridButtons,
    WrapperBtnInfo,
    WrapperBtnCancel,
    WrapperBtnSubmit
} from './styles';

interface IPropsSection {
    section: any
}

const Section: React.FC<IPropsSection> = ({ section }) => {
    const formRef = useRef<FormHandles>(null);
    const { httpPut } = useHttp();
    const { addToast } = useToast();
    const { user } = useAuth();
    const history = useHistory();

    useEffect(() => {
        formRef.current?.setData({
            ...section,
        });
    }, [section]);

    const handleSubmit = useCallback(async (payload: any) => {
        const _section = {
            ...payload,
            management_id: user?.allocation?.management_id,
            team: payload.team?.value,
            operation_date: payload?.operation_date
        };

        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
                agent_detran: Yup.number().typeError('Ag. do Detran é obrigatório, somente números'),
                number_trailers: Yup.number().typeError('Quantidade de Reboques é obrigatório, somente números'),
                criminal_occurrences: Yup.number().typeError('Criminais Diversas é obrigatório, somente números'),
                external_occurrences: Yup.number().typeError('Ocorrências Externas é obrigatório, somente números'),
            });

            await schema.validate(_section, { abortEarly: false });
            const { status } = await httpPut(`rcols/${_section.id}`, _section);
            if (status === 200) {
                addToast({ title: 'Próximo passo, edite o SEÇÃO II ou Saia', type: 'warning' });
            } else {
                addToast({ title: 'Não foi possível atualizar!', type: 'error' });
                //history.push('/dashboard');
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
            }
        }
    }, [user, addToast, httpPut]);

    return (
        <Container>
            <UForm ref={formRef} onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <InputHidden name="id" />
                    <Content>
                        <Grid container spacing={1} justify="space-between">
                            <Flex title="Equipe" xs={12} sm={4} md={4}>
                                {section?.team || 'não informado'}
                            </Flex>
                            <Flex title="Data da Operação" xs={12} sm={4} md={4}>
                                {formatDate(section?.operation_date) || 'não informado'}
                            </Flex>
                            <Flex title="Chefe da Equipe Militar" xs={12} sm={4} md={4}>
                                {section?.military?.name || 'não informado'}
                            </Flex>
                            <Flex title="Efetivo SEGOV" xs={12} sm={6} md={6}>
                                {section?.segovTeam || 'não informado'}
                            </Flex>
                            <Flex title="Viaturas Ols Utilizadas na Operação" xs={12} sm={6} md={6}>
                                {section?.cars || 'não informado'}
                            </Flex>
                        </Grid>
                    </Content>
                    <WrapperLabel label="Quantidade de Ag. do Detran" xs={12} md={6}>
                        <Input name="agent_detran" maxLength={2} />
                    </WrapperLabel>
                    <WrapperLabel label="Quantidade de Reboques" xs={12} md={6}>
                        <Input name="number_trailers" maxLength={2} />
                    </WrapperLabel>
                    <WrapperLabel label="Ocorrências Criminais Diversas" xs={12} md={6}>
                        <Input name="criminal_occurrences" maxLength={3} />
                    </WrapperLabel>
                    <WrapperLabel label="Ocorrências Externas" xs={12} md={6}>
                        <Input name="external_occurrences" maxLength={3} />
                    </WrapperLabel>
                    <WrapperLabel label="Alterações de Efetivo OLS" xs={12}>
                        <TextArea
                            row={4}
                            name="change_ols"
                            placeholder='Atrasos, Faltas, Nome do(s) Agente(s) da "Educação" (caso houver) e outras alterações relevantes.'
                        />
                    </WrapperLabel>
                    <WrapperLabel label="Alterações da Equipe de Apoio" xs={12}>
                        <TextArea
                            row={4}
                            name="change_support"
                            placeholder='Atrasos, Faltas e outras alterações relevantes e conferência de reboques e viaturas da OLS'
                        />
                    </WrapperLabel>
                    <WrapperLabel label="Alterações em Geral" xs={12}>
                        <TextArea
                            row={4}
                            name="changes_general"
                            placeholder='Ocorrências não registradas, troca de condutores, visibilidade, cancelamento de operações etc  ou escreva "SEM ALTERAÇÃO".'
                        />
                    </WrapperLabel>
                </Grid>
                <GridButtons>
                    <WrapperBtnInfo>
                        <ButtonIcon
                            title="Instruções"
                        >
                            <InfoIcon />
                        </ButtonIcon>
                    </WrapperBtnInfo>
                    <WrapperBtnCancel>
                        <Button
                            type="button"
                            onClick={() => { history.push('/dashboard'); }}
                        >
                            Cancelar
                        </Button>
                    </WrapperBtnCancel>
                    <WrapperBtnSubmit>
                        <Button
                            type="submit"
                        >
                            Salvar
                        </Button>
                    </WrapperBtnSubmit>
                </GridButtons>
            </UForm>
        </Container>
    )
}

export default Section;