const aPolice = [

  { value: 'Agente de Telecomunicação', label: 'Agente de Telecomunicação' },
  { value: 'Agente Policial', label: 'Agente Policial' },
  { value: 'Atendente de Necrotério', label: 'Atendente de Necrotério' },
  { value: 'Auxiliar de Necropsia', label: 'Auxiliar de Necropsia' },
  { value: 'Auxiliar de Papiloscopista', label: 'Auxiliar de Papiloscopista' },
  { value: 'Delegado', label: 'Delegado' },
  { value: 'Desenhista', label: 'Desenhista' },
  { value: 'Escrivão', label: 'Escrivão' },
  { value: 'Fotógrafo', label: 'Fotógrafo' },
  { value: 'Investigador', label: 'Investigador' },
  { value: 'Médico Legista', label: 'Médico Legista' },
  { value: 'Papiloscopista', label: 'Papiloscopista' },
  { value: 'Perito', label: 'Perito' },
  { value: 'Reboquista', label: 'Reboquista' },

];

export default aPolice;
