/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Form as UForm } from '@unform/web';
import { Grid } from '@material-ui/core';
import { Chart } from 'react-google-charts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { ButtonDash, InputHidden } from 'components/Forms';
import { FormHandles } from '@unform/core';
import { useAuth } from 'hooks/auth';
import { HomeIcon, SearchIcon } from 'styles/icons';
import formatDateDash from 'utils/formatDateDash';
import {
  ChartStyle, Container, DashContainer, HeaderDash, Img, WrapperHeaderDash,
} from './styles';
import ImgOSP from '../../../assets/seg_presente.png';
import ImgHorus from '../../../assets/horus.png';
import DashBox from './DashBox';
import DashTable from '../../../components/DashTable';
import { useHttp } from '../../../hooks/http';
import DashBoxSelect from './DashBoxSelect';
import SelectDash from './SelectDash';

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const { httpGet } = useHttp();
  const formRef = useRef<FormHandles>(null);
  const [basesOptions, setBasesOptions] = useState([]);
  const [situation, setSituation] = useState('');
  const [typeRegistration, setTypeRegistration] = useState('');
  const [schoolEnvironment, setSchoolEnvironment] = useState('');
  const [bopmBases, setBopmBases] = useState([]);
  const [typeWarrant, setTypeWarrant] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [prime, setPrime] = useState('');
  const [filterBase, setFilterBase] = useState('');
  const [filterStartDate, setFilterStartDate] = useState('');
  const [dateRange, setDateRange] = useState(['', '']);
  const [startDate, endDate]:any = dateRange;
  const rangeDate = !startDate
    ? (`${prime} - ${format(new Date(), 'dd MMM yyyy')}`)
    : (`${format(new Date(startDate), 'dd MMM yyyy')} - ${format(new Date(endDate), 'dd MMM yyyy')}`);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const handleSubmit = useCallback(async (payload: any) => {
    setFilterStartDate(payload.dateStart ? formatDateDash(payload.dateStart) : '');
    setFilterEndDate(payload.endDate ? formatDateDash(payload.endDate) : '');
    setFilterBase(payload.bases?.value || '');
  }, []);

  const [dashBopms, setDashBopms] = useState<number>(0);
  const [dashInvolveds, setDashInvolveds] = useState<number>(0);
  const [dashGuns, setDashGuns] = useState<number>(0);
  const [dashNarcotics, setDashNarcotics] = useState<number>(0);
  const [dashMaterial, setDashMaterial] = useState<number>(0);
  const [dashAmmunitions, setDashAmmunitions] = useState<number>(0);
  const [dashCars, setDashCars] = useState<number>(0);

  useEffect(() => {
    async function getDashBopm(): Promise<void> {
      const { status, data } = await httpGet('/dashboards/dashBopms', {
        params: {
          filterStartDate,
          filterEndDate,
          filterBase,
        },
      });
      if (status === 200) {
        setDashBopms(data.bopms);
        setDashInvolveds(data.involveds);
        setDashGuns(data.guns);
        setDashNarcotics(data.narcotics);
        setDashMaterial(data.material);
        setDashAmmunitions(data.ammunitions);
        setDashCars(data.cars);
        setBasesOptions(data.management);
        setSituation(data.situation);
        setTypeRegistration(data.typeRegistration);
        setTypeWarrant(data.typeWarrant);
        setSchoolEnvironment(data.schoolEnvironment);
        setBopmBases(data.bopmBases);
        setPrime(data.prime);
      }
    }
    getDashBopm();
    }, [filterStartDate, filterEndDate, filterBase, user]); // eslint-disable-line  

  const donutOptions = {
    title: 'OCORRÊNCIAS EM AMBIENTE',
    titleTextStyle: { color: '#fff' },
    pieHole: 0.6,
    is3D: false,
    backgroundColor: '#2c2f38',
    legend: { alignment: 'center', position: 'bottom', textStyle: { color: '#fff' } },
    color: '#fff',
  };

  const options = {
    title: 'TIPOS DE OCORRÊNCIAS',
    titleTextStyle: { color: '#fff' },
    backgroundColor: '#2c2f38',
    legend: { alignment: 'center', position: 'bottom', textStyle: { color: '#fff' } },
    hAxis: { title: '25 set 2013', titleTextStyle: { color: '#fdfafa' }, textStyle: { color: '#fff' } },
    vAxis: { minValue: 0, textStyle: { color: '#fff' } },
    chartArea: { width: '50%', height: '70%' },
  };

  const HbarOptions = {
    chart: {
      legend: 'rigth',
      title: 'Teste de style',
      subtitle: ' GGGGGGG',
    },
    chartLanguage: 'pt-BR',
    bars: 'horizontal',
    chartArea: { width: '70%', height: '60%' },
    backgroundColor: '#2c2f38',
    legend: { alignment: 'center', position: 'bottom', textStyle: { color: '#fff' } },
    hAxis: { title: '25 set 2013', titleTextStyle: { color: '#fdfafa' }, textStyle: { color: '#fff' } },
    vAxis: { minValue: 0, textStyle: { color: '#fff' } },
  };

  const barOptions = {
    chartLanguage: 'pt-BR',
    backgroundColor: '#2c2f38',
    legend: { alignment: 'center', position: 'bottom', textStyle: { color: '#fff' } },
    hAxis: { titleTextStyle: { color: '#fdfafa' }, textStyle: { color: '#fff' } },
    vAxis: { minValue: 0, textStyle: { color: '#fff' } },
  };

  const listBases = useMemo(
    () => [
      {
        Header: 'Projeto (Bases)',
        accessor: 'base',
      },
      {
        Header: 'Bopm / Base',
        accessor: 'bopm',
      },
    ],
    [],
  );
  return (
    <Container>
      <HeaderDash>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Grid container justify="flex-start" spacing={1}>
            <Grid item xs={12} sm={1} md={1}>
              <Img src={ImgOSP} />
            </Grid>
            <Grid item xs={12} sm={1} md={1}>
              <ButtonDash size='large' type="button" onClick={() => { history.push('/dashboard'); }}>
                <HomeIcon />
              </ButtonDash>
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <DashBoxSelect
                title="RO-SP"
                amount={dashBopms}
                color="#2c2f38"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <WrapperHeaderDash>
                <DashContainer>
                  <DatePicker
                    name="dashData"
                    placeholderText={rangeDate}
                    onInputClick={() => setIsOpen(true)}
                    onClickOutside={() => setIsOpen(false)}
                    open={isOpen}
                    selectsRange
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update: any) => {
                      setDateRange(update);
                      if (update[1]) {
                        setIsOpen(false);
                      }
                    }}
                    minDate={new Date(prime)}
                    maxDate={new Date()}
                  />
                </DashContainer>
              </WrapperHeaderDash>
              <InputHidden name="dateStart" defaultValue={startDate} />
              <InputHidden name="endDate" defaultValue={endDate} />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <WrapperHeaderDash>
                <DashContainer>
                  <SelectDash
                    name="bases"
                    placeholder="Bases"
                    options={basesOptions}
                  />
                </DashContainer>
              </WrapperHeaderDash>
            </Grid>
            <Grid item xs={12} sm={1} md={1}>
              <ButtonDash type="submit" >
                <SearchIcon />
              </ButtonDash>
            </Grid>
            <Grid item xs={12} sm={1} md={1}>
              <Img src={ImgHorus} />
            </Grid>
          </Grid>
        </UForm>
      </HeaderDash>
      <Grid container justify="flex-start" spacing={1}>
        <Grid item xs={12} sm={4} md={4}>
          <DashTable columns={listBases} data={bopmBases} pageSize={20} />
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container justify="flex-start" spacing={1}>
              <Grid item xs={12} sm={2} md={2}>
                <DashBox
                  title="ENVOLVIDOS"
                  amount={dashInvolveds}
                  color="#0072f0"
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <DashBox
                  title="ARMAS"
                  amount={dashGuns}
                  color="#b71c1c"
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <DashBox
                  title="ENTORPECENTES"
                  amount={dashNarcotics}
                  color="#2E7D32"
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <DashBox
                  title="MATERIAIS"
                  amount={dashMaterial}
                  color="#ff8f00"
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <DashBox
                  title="MUNIÇÕES"
                  amount={dashAmmunitions}
                  color="#ffc107"
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <DashBox
                  title="VEÍCULOS"
                  amount={dashCars}
                  color="#00b6cb"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container justify="flex-start" spacing={1}>
              <Grid item xs={12} sm={4} md={4}>
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="300px"
                  data={situation}
                  options={barOptions}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Chart
                  chartType="PieChart"
                  width="100%"
                  height="300px"
                  data={schoolEnvironment}
                  options={donutOptions}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height="300px"
                  data={typeRegistration}
                  options={HbarOptions}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <ChartStyle>
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="420px"
                    data={typeWarrant}
                    options={options}
                  />
                </ChartStyle>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
