import styled from 'styled-components';

interface IContainerProps {
    color: string
}

export const Container = styled.div<IContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 600px){
        width: 96%;
    }
    width: 100%;
    height: 98%;
    background-color: ${props => props.color};
    border: 1px solid;
    border-radius: 5px;

    padding: 10px 20px;

    span {
        color: white;
    }

    h1 {
        color: white;
    }
`;


export const ContainerSelect = styled.div<IContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 600px){
        width: 96%;
    }
    width: 100%;
    height: 98%;
    background-color: ${props => props.color};
    border: 1px solid;
    border-radius: 5px;

    padding: 10px 20px;

    span {
        color: white;
    }

    h1 {
        color: white;
    }
`;