import Layout from 'components/Layout';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { ViewListIcon } from 'styles/icons';
import { useHistory } from 'react-router-dom';
/** componentes */
import HeaderSavePlus from '../../../components/Headers/SavePlus';
import Tabs, { TabsItem } from '../../../components/Tabs';
import { useHttp } from '../../../hooks/http';
import Section from './Section';
import SectionTwo from './SectionTwo';
import { Container } from './styles';

interface IReducols {
    id: string;
}

const Form: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { httpGet } = useHttp();
  const [reducols, setReducols] = useState({} as IReducols);
  const history = useHistory();

  const [active0, setActive0] = useState<any>();
  const [active1, setActive1] = useState<any>({ enable: false });

  useEffect(() => {
    setReducols({} as IReducols);
    async function loadRcols(): Promise<void> {
      const { status, data } = await httpGet(`/reducols/${id}`);
      if (status === 200) {
        setReducols(data);
        setActive1({ show: true });
        setActive0({ show: false });
      } else {
        history.push('/reducols');
      }
    }

    id ? loadRcols() : setActive0({ show: true });
    }, [id]);// eslint-disable-line

  const breadcrumbs = {
    icon: ViewListIcon,
    links: [{ path: '', title: 'LeiSeca Reducols' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <HeaderSavePlus
          hiddenButton
          handlePlus={() => { }}
        >
          REDUCOLS - RELATÓRIO DA EDUCAÇÃO
        </HeaderSavePlus>
        <Tabs>
          <TabsItem title="Seção I" {...active0}>
            <Section />
          </TabsItem>
          <TabsItem title="Seção II" {...active1}>
            <SectionTwo section={reducols} />
          </TabsItem>
        </Tabs>
      </Container>
    </Layout>
  );
};

export default Form;
