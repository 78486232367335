import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import { Form as UForm } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import getValidationError from '../../../utils/getValidationsErros';

/** components */
import { WrapperLabel, Select, Button } from '../../../components/Forms';

/** hooks */
import { useAuth } from '../../../hooks/auth';
import { useHttp } from '../../../hooks/http';

import { Container } from './styles';

const Allocation: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const prefix = `@${process.env.REACT_APP_NAME}`;

  const [managements, setManagements] = useState<any>();
  const { user, refreshUser } = useAuth();
  const { httpGet } = useHttp();

  useEffect(() => {
    async function loadManagements(): Promise<void> {
      if (!user) {
        return;
      }

      const { status, data } = await httpGet('managements/stations');
      if (status === 200) {
        const stations = data
          .map((station:any) => ({
            value: station.id,
            label: `${station.initials} - ${station.name}`,
            is_core: station.is_core,
          }));

        const options = [
          {
            label: 'Postos',
            options: stations.filter((station:any) => !station.is_core),
          },
          {
            label: 'Núcleos',
            options: stations.filter((station:any) => station.is_core),
          },
        ];
        setManagements(options);
      }
    }

    loadManagements();

  }, [user]);// eslint-disable-line

  const handleSubmit = useCallback(async (data: any) => {
    const _station = {
      allocation_id: data.allocation_id?.value,
    };

    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        allocation_id: Yup.number().required('Selecione posto de trabalho'),
      });
      await schema.validate(_station, { abortEarly: false });

      const userStore = localStorage.getItem(`${prefix}:user`);
      if (userStore) {
        const userParsed = JSON.parse(userStore);
        userParsed.allocation = {
          ended_at: null,
          management_id: _station.allocation_id,
          started_at: null,
        };

        localStorage.setItem(`${prefix}:user`, JSON.stringify(userParsed));
        refreshUser();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [prefix, refreshUser]);

  return (
    <Container>

      <UForm ref={formRef} onSubmit={handleSubmit}>

        <Grid container spacing={1} justify="center">
          <WrapperLabel label="Selecione seu posto de trabalho" xs={12} sm={6} md={6}>
            <Select name="allocation_id" options={managements} />
          </WrapperLabel>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={6}>
            <Button type="submit">Salvar</Button>
          </Grid>
        </Grid>

      </UForm>
    </Container>
  );
};

export default Allocation;
