import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #4A4A4A;
  font-size: 14px;
  padding: 4px 0;

  h3{
    color: #959ea3;
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 2px;
  }
`;

export const Whapper = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
