import styled from 'styled-components';

export const Container = styled.aside`
    width: 348px;
    background-color: #F5F5F5;
    padding: 8px;
    height: calc(100vh - 65px);
    overflow-y: scroll;
    border-left: 1px solid #A9A9A9;
`;

export const Head = styled.span`
    display: block;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #A9A9A9;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
