import React, { useCallback, useRef, useState } from 'react';
import { Form as UForm } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router';
import { useHttp } from 'hooks/http';
import { IFleet } from 'interfaces';
import ModalFactory from 'react-modal-promise';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as Yup from 'yup';
import { ModalDialog } from '../../../components/ModalDialog';
import getCurrentPosition from '../../../utils/getCurrentPosition';
import Modal from '../../../components/ModalSimple';
import { formatPlate } from '../../../utils';
import { Grid } from '../../../components/Flex';
import getValidationError from '../../../utils/getValidationsErros';
import {
  WrapperLabel,
  InputMask,
  Button,
  ButtonIcon,
  InputHidden,
  Input,
  TextArea,
} from '../../../components/Forms';
import { InfoIcon, WarningIcon } from '../../../styles/icons';
import {
  Container,
  Content,
  GridButtons,
  WrapperBtnInfo,
  WrapperBtnCancel,
  WrapperBtnSubmit,
  WrapperModal,
  Red,
} from './styles';

interface ICoord {
    coord?: {
        lat: number,
        lng: number
    }
}

const Started: React.FC<ICoord> = ({ coord }) => {
  const formRef = useRef<FormHandles>(null);
  const labelConfRef = useRef<HTMLLabelElement>(null);
  const history = useHistory();
  const [btnLoading, setBtnLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [formData, setFormData] = useState<any>();
  const [disableBtn, setDisableBtn] = useState(true);
  const [disableBtnChecked, setDisableBtnChecked] = useState(false);
  const [fleetdata, setFleetData] = useState<IFleet>({} as IFleet);
  const { httpGet, httpPost } = useHttp();

  const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const name = e?.currentTarget.name;
    let { value } = e?.currentTarget;
    setFormData(null);
    if (name === 'plate') {
      value = value.replace(/[\W,_]/g, '');
      value.length > 6
        ? setDisableBtn(false)
        : setDisableBtn(true);
    }
  }, []);

  const handleGetVehicle = useCallback(async () => {
    const plate = formRef.current?.getFieldValue('plate');
    const params = { plate };
    setBtnLoading(true);
    const response: any = await httpGet('/fleets/search', { params });

    if (response.status === 200) {
      const { data } = response;
      const { fleet, service } = data;
      if (service) {
        await ModalDialog({
          title: 'AVISO!',
          content: 'Está viatura não encerrou o servico.',
          buttons: 'ok',
        });
        setBtnLoading(false);
        return;
      }
      setFleetData(fleet);
      setShowData(true);
    }

    if (response.response?.status === 400) {
            formRef.current?.reset();
    }
    setBtnLoading(false);
  }, [httpGet]);

  const handleSubmit = useCallback(async (data: any) => {
    if (!data.id) {
      return;
    }
    try {
      const schema = Yup.object().shape({
        plate: Yup.string().required('Placa é obrigatório'),
        started_km: Yup.number().typeError('Km inicial é obrigatório, somente números'),
      });

            formRef.current?.setErrors({});
            await schema.validate(data, { abortEarly: false });

            setFormData(data);
            formRef.current?.setData({
              ...data,
              plate: data.plate,
              brand: data.brand,
              started_km: data.started_km,
              confirmed: [{ value: null, label: '' }],
            });
            labelConfRef.current?.click();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
      }
    }
  }, []);

  const handleToRecord = useCallback(async (data: any) => {
        labelConfRef.current?.click();

        const bdtdSubmit = {
          ...coord,
          fleet_id: data.id,
          started_km: data.started_km,
          started_at: new Date(),
          management_id: data.management_id,
          note: data.note,
          active: true,
        };

        setBtnLoading(true);
        const position = await getCurrentPosition();

        const response: any = await httpPost('bdtds/start', {
          ...bdtdSubmit,
          latitude_match: position.lat,
          longitude_match: position.lng,
        });

        if (response.status === 200) {
          history.push('/bdtds');
        }

        if (response.status !== 200) {
          setBtnLoading(false);
        }

        setBtnLoading(false);
  }, [coord, history, httpPost]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableBtnChecked(event.target.checked);
  };

  return (
    <Container>
      <Content>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          {!showData && (
            <Grid container spacing={1}>
              <WrapperLabel label="Placa" xs={12}>
                <InputMask
                  name="plate"
                  mask="aaa-9*99"
                  onKeyUp={handleKeyUp}
                  style={{ textTransform: 'uppercase' }}
                />
              </WrapperLabel>
            </Grid>
          )}
          {showData && (
            <Grid container spacing={1}>
              <WrapperLabel label="Viatura" xs={12} sm={6}>
                <Input
                  name="brand"
                  defaultValue={`${fleetdata.model.name} ${fleetdata.make.name} / ${formatPlate(fleetdata.plate)}`}
                  disabled
                />
              </WrapperLabel>
              <InputHidden name="id" defaultValue={fleetdata.id} />
              <InputHidden name="management_id" defaultValue={fleetdata.management_id} />
              <InputHidden name="plate" defaultValue={fleetdata.plate} />
              <WrapperLabel label="Km Inicial" xs={12} sm={6}>
                <Input name="started_km" maxLength={11} />
              </WrapperLabel>
              <WrapperLabel label="Observação" xs={12}>
                <TextArea row={2} name="note" maxLength={500} />
              </WrapperLabel>
            </Grid>
          )}
          <GridButtons>
            <WrapperBtnInfo>
              <ButtonIcon
                title="Instruções"
              >
                <InfoIcon />
              </ButtonIcon>
            </WrapperBtnInfo>
            <WrapperBtnCancel>
              <Button
                type="button"
                onClick={() => { history.push('/dashboard'); }}
              >
                Cancelar
              </Button>
            </WrapperBtnCancel>
            <WrapperBtnSubmit>
              <Button
                type="button"
                onClick={() => {
                  if (showData) {
                                        formRef.current?.submitForm();
                  } else {
                    handleGetVehicle();
                  }
                }}
                disableForce={disableBtn}
                isLoading={btnLoading}
              >
                Iniciar
              </Button>
            </WrapperBtnSubmit>
          </GridButtons>
        </UForm>
      </Content>
      <WrapperModal>
        <label ref={labelConfRef} htmlFor="modal-conf" />
        <Modal id="modal-conf" width={520} height="auto">
          <h2>
            <WarningIcon /> Confirma os dados da viatura?
          </h2>
          <p>Placa: <b>{formData?.plate}</b></p>
          <p>Marca/Modelo: <b>{formData?.brand}</b></p>
          <p>Km Início: <b>{formData?.started_km}</b></p>
          <FormControlLabel
            label={<Red>Confirmo as informações acima.</Red>}
            control={(
              <Switch
                onChange={handleChangeChecked}
                color="primary"
                name="checked"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
                        )}
          />
          <span>
            <Button onClick={() => labelConfRef.current?.click()}>
              Cancelar
            </Button>
            <Button
              onClick={() => handleToRecord(formData)}
              isLoading={btnLoading}
              disableForce={!disableBtnChecked}
            >
              Confirmar
            </Button>
          </span>

        </Modal>
        <ModalFactory />
      </WrapperModal>
    </Container>
  );
};

export default Started;
