import React, {
  useState, useRef, useCallback, useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import ModalFactory from 'react-modal-promise';
import ReturnSalvePlus from 'components/Headers/ReturnSalvePlus';
import { Divider, FormControlLabel, Switch } from '@material-ui/core';
import { useToast } from 'hooks/toast';
import ICarInvolved from 'interfaces/carInvolved';
import { useHttp } from '../../../hooks/http';
import { useAuth } from '../../../hooks/auth';
import Layout from '../../../components/Layout';
import { Button as ButtonMat } from '../../../components/Forms';
import Flex, { Grid } from '../../../components/Flex';
import Card, { CardHeader, CardContent } from '../../../components/Card';
import { PencilIcon, TrashIcon, CarIcon } from '../../../styles/icons';
import { formatDate } from '../../../utils';
import {
  Container, WrapperName, Button, WrapperModal, Red,
} from './styles';
import Modal from '../../../components/ModalSimple';
import { TitleModal } from '../styles';

const InvolvedCar: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const labelConfRef = useRef<HTMLLabelElement>(null);
  const switchs = useRef<HTMLLabelElement>(null);
  const [involvedCars, setInvolvedCars] = useState<ICarInvolved[]>();
  const [formData, setFormData] = useState<any>();
  const history = useHistory();
  const ROId = localStorage.getItem('ROId');
  const [btnLoading, setBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { httpGet, httpDelete } = useHttp();

  useEffect(() => {
    async function loadInvolvedCars(): Promise<any> {
      const { status, data } = await httpGet(`/involvedCars/list/${ROId}`);

      if (status === 200 && data.length > 0) {
        setInvolvedCars(data);
      } else { history.push('/RO/carInvolved/storage'); }
    }
    loadInvolvedCars();
  }, [httpGet, user, history, ROId]);

  const handleDeleteInvolvedCar = useCallback(async (involvedCarId) => {
    setBtnLoading(false);
    const response: any = await httpDelete(`/involvedCars/${involvedCarId}`);

    // eslint-disable-next-line no-constant-condition
    if (response.status === 200 || 204) {
      addToast({ title: 'Excluído com sucesso!', type: 'success' });
      history.push('/RO/step/3');
    } else {
      addToast({ title: 'Não foi possível excluir!', type: 'error' });
      setBtnLoading(false);
      setDisableBtn(false);
      switchs.current?.click();
      labelConfRef.current?.click();
      formRef.current?.reset();
    }
  }, [addToast, history, httpDelete]);

  const handleGoToDetail = useCallback(async (data: any) => {
    setFormData(data);
    formRef.current?.setData({
      ...data,
      document: data.document,
      birthday: formatDate(data.birthday),
      confirmed: [{ value: null, label: '' }],
    });

    labelConfRef.current?.click();
  }, []);

  const handleChangeChecked = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableBtn(event.target.checked);
  }, []);

  const handleHover = useCallback(() => {
    setBtnLoading(false);
    setDisableBtn(false);
    switchs.current?.click();
    labelConfRef.current?.click();
    formRef.current?.reset();
  }, []);

  const breadcrumbs = {
    icon: CarIcon,
    links: [
      { title: 'RO-SP/ Veículos Envolvidos / Listar', path: '/RO/step/3' },
    ],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <ReturnSalvePlus
          handleReturn={() => history.push('/RO/step/3')}
          handlePlus={() => history.push('/RO/carInvolved/storage')}
        />

        <Grid container justify="flex-start" spacing={1}>
          {involvedCars?.map((involvedCar) => (
            <Grid item key={involvedCar.id} xs={12} sm={4} md={4}>
              <Card>
                <CardHeader>
                  <WrapperName>
                    <CarIcon />&nbsp;{involvedCar.plate ? involvedCar.plate : involvedCar.chassi}
                  </WrapperName>
                </CardHeader>
                  <br></br>
                  <Divider/>                  
                <CardContent>
                  <Grid container spacing={1}>
                    <Flex title="Placa" xs={5} sm={6} md={6}>
                      {involvedCar.plate ? involvedCar.plate : 'Não registrado'}
                    </Flex>
                    <Flex title="Chassi" xs={5} sm={6} md={6}>
                      {involvedCar.chassi ? involvedCar.chassi : 'Não registrado'}
                    </Flex>
                    <Flex title="Marca" xs={5} sm={6} md={6}>
                      {involvedCar.brand}
                    </Flex>
                    <Flex title="Modelo" xs={5} sm={6} md={6}>
                      {involvedCar.model}
                    </Flex>
                    <Flex title="Condutor" xs={5} sm={6} md={6}>
                      {involvedCar.conductor ? involvedCar.conductor : 'Não registrado'}
                    </Flex>
                    <Flex title="" xs={12} sm={12} md={12} />

                    <Grid item xs={6} sm={6} md={6}>
                      <Button type="button" onClick={() => { history.push(`/RO/carInvolved/${involvedCar.id}`); }}>
                        Editar &nbsp; <PencilIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <Button type="button" onClick={() => { handleGoToDetail(involvedCar); }}>
                        Excluir &nbsp; <TrashIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <WrapperModal>
          <label ref={labelConfRef} htmlFor="modal-conf" />
          <Modal id="modal-conf" width={520} height="auto">
            <TitleModal>
              <TrashIcon />Deseja excluir o veículo envolvido?
            </TitleModal>
            {formData?.plate ?  
            <p> Placa: <b>{formData?.plate}</b></p> : <p> Chassi: <b>{formData?.chassi}</b></p>}
            <p> Marca: <b>{formData?.brand}</b> </p>
            <p> Modelo: <b>{formData?.model}</b> </p>
            <p> Condutor: <b>{formData?.conductor}</b> </p>

            <FormControlLabel
              ref={switchs}
              label={<Red>Confirmo as informações acima.</Red>}
              control={(
                <Switch
                  onChange={handleChangeChecked}
                  color="primary"
                  name="checked"
                  value="false"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            )}
            />
            <span>
              <ButtonMat onClick={handleHover}>
                Cancelar
              </ButtonMat>
              <ButtonMat
                type="button"
                onClick={() => { handleDeleteInvolvedCar(formData?.id); }}
                isLoading={btnLoading}
                disableForce={!disableBtn}
              >
                Excluir
              </ButtonMat>
            </span>
          </Modal>
        </WrapperModal>
        <ModalFactory />
      </Container>
    </Layout>
  );
};

export default InvolvedCar;
