import React from 'react';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Container, WrapperPanel } from './styles';

const CustomTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#666',
  },
})(Tabs);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: '4px',
  },

  tabs: {
    minWidth: 200,
    borderRight: `1px solid ${theme.palette.divider}`,
  },

  tab: {
    textTransform: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      color: '#333',
      opacity: 1,
    },
    '&:selected': {
      color: '#333',
      fontWeight: 500,
    },
    '&:focus': {
      color: '#333',
    },
  },
}));

interface ITabPanel{
  activeTab: number;
  index: number;
  children: React.ReactNode;
}

export const TabPanel: React.FC<ITabPanel> = ({ activeTab, index, children }) => (
  <WrapperPanel hidden={activeTab !== index} id={`tab-roles-${index}`}>
    {activeTab === index && (
    <>{ children }</>
    )}
  </WrapperPanel>
);

interface IVerticalTabs{
  titles: string[];
  activeTab?: number;
  handleActiveTab(event: React.ChangeEvent<{}>, newValue: number): void;
  children: React.ReactNode;
}

const VerticalTabs: React.FC<IVerticalTabs> = ({
  titles, activeTab = 0, handleActiveTab, children,
}) => {
  const classes = useStyles();

  return (
    <Container>
      <div className={classes.root}>
        <CustomTabs
          orientation="vertical"
          variant="scrollable"
          value={activeTab}
          onChange={handleActiveTab}
          className={classes.tabs}
        >
          {titles.map((title) => (
            <Tab
              key={`tab-${title}`}
              label={title}
              className={classes.tab}
              disableRipple
              id={`tab-${title}`}
            />
          ))}
        </CustomTabs>

        {children}

      </div>
    </Container>
  );
};

export default VerticalTabs;
