import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  max-width: 100%;

  label{
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 2px;
  }
  > div{
    min-height: 40px;
  }
`;

export const Content = styled.div`
  display:flex;
  flex-direction: row;

  button{
   flex-shrink: 0;
   margin-left: 8px;
  }
`;
