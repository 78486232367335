import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 16px;
`;

export const WrapperAddress = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 4px;

  h3{
    color: var(--color-primary);
    margin-bottom: 8px;
  }

  @media (max-width: 767.98px) {
    span {
      display: none;
    };
  }
`;

export const WrapperButton = styled.span`
  display: flex;
  width: 100%;
  > div{
    width: calc(100% - 48px);
    input{
      width: calc(100% - 46px);
    }
  }

  button{
    width: 40px;
  }

`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;
