/* eslint-disable react/button-has-type */
import React from 'react';
import { useTable, usePagination } from 'react-table';
import { Container, Pagination, Containt } from './styles';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function DashTable({ columns, data }: any) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
  );
  // useEffect(() => { setPageSize(20); });

  return (
    <Container>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination>
        <Containt>
          <span>
            <strong>
              {pageIndex + 1} / {pageOptions.length }
            </strong>{' '}
          </span>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}> {'<<'} </button> {' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}> {'<'} </button> {' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}> {'>'} </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}> {'>>'} </button> {' '}
        </Containt>
      </Pagination>
    </Container>
  );
}

export default DashTable;
