import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;

  padding: 8px 16px;
`;

export const WrapperTitle = styled.div`
  margin-bottom: 16px;
  h2{
    color: var(--color-secondary);
    font-size: 1em;
  }
`;
