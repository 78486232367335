import styled, { css } from 'styled-components';

interface BrandItf{
  collapsed: boolean;
}
export const Brand = styled.div<BrandItf>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 64px;
  padding: 8px 0 8px 12px;

  div{
    display: flex;
    align-items: center;

    img{
      height: 40px;
      ${(props) => props.collapsed && css`height: 30px; margin-left: 8px;`}
    }

    h1{
      color: rgba(255,255,255, 0.2);
      font-size: 2rem;
      font-weight: 700;
      margin-left:12px;
    }

    span{

      p{
        text-align: right;
        color: rgba(255,255,255, 0.2);
        font-size: 0.7em;


      }
      img{
        margin: 8px 0 0 8px;
        height: 24px;
      }
    }

  }

  button{
    border: 0;
    background: transparent;
    color: var(--color-secondary);
    opacity: 0;
    transition: opacity .2s;


    &:hover{
      opacity: 1;
    }
  }

`;

export const MyIcon = styled.img`
  height: 14px;
  color: #fff;
`;
