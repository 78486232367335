import React, {
  InputHTMLAttributes, useEffect, useRef, useState, useCallback,
} from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

interface TextAreaProp extends InputHTMLAttributes<HTMLTextAreaElement>{
  name: string;
  row?: number;
}

const TextArea: React.FC<TextAreaProp> = ({ name, row = 1, ...rest }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const {
    fieldName, defaultValue, error, registerField,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <Container isErrored={!!error} isFocused={isFocused}>
      <textarea
        ref={inputRef}
        onFocus={handleFocused}
        onBlur={handleInputBlur}
        rows={row}
        defaultValue={defaultValue}
        {...rest}
      />

      <Error title={error || ''}>
        <FiAlertCircle color="#c53030" size={20} />
      </Error>

    </Container>
  );
};

export default TextArea;
