import styled from 'styled-components';

export const Container = styled.main`
   display: flex;
   flex: 1;
   min-height: calc(100vh - 65px);
   
   @media screen and (max-width: 1200px) {
      flex-direction: column;
   }
`;

export const Whapper = styled.div`
   display: flex;
   flex: 1;

    @media screen and (max-width: 1200px) {
      width: 100%;
   }
`;

export const Contant = styled.div`
    @media screen and (max-width: 1200px) {
      display: none;
   }
`;
