import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  flex-shrink: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
