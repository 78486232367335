import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  grid-template-rows: 1fr 2fr 1fr;
  grid-template-areas:
    'analise analise analise'
    'result-a action result-b'
    'remove remove remove';
  width: 100%;
  height: 100%;
  gap: 8px;

  background: #fff;
  border-radius: 4px;
  padding: 16px;
`;

export const WrapperInputs = styled.span`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const WrapperFrmAnalise = styled.div`
  grid-area: analise;
`;

export const WrapperResultA = styled.div`
  grid-area: result-a;
`;

interface PropWrapperAction {
  status: number;
}
export const WrapperAction = styled.div`
  grid-area: action;
  width: 100%;
  height: 100%;
`;

export const ButtonStatus = styled.button<PropWrapperAction>`
  color: #fff;
  font-size: 1.1rem;
  font-weight: 400;
  border: none;
  background: var(--color-button);
  ${(PropWrapperAction) => PropWrapperAction.status < 1 && css`background: #ccc; cursor: not-allowed;`}
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding: 11px;
`;

export const WrapperResultB = styled.div`
  grid-area: result-b;
`;

export const WrapperScroll = styled.div`
  height: 260px;
  overflow-y: auto;

  ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--color-button);
    }
    ::-webkit-scrollbar-thumb:hover {
      background: var(--color-button-h);
    }
`;

export const WrapperRemove = styled.div`
  grid-area: remove;
`;
