import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 16px;
`;

export const WrapperForm = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  flex-grow: 1;

  form {
    width: 100%;
  }
`;
export const WrapperInfo = styled.div`

  padding: 16px;

`;
