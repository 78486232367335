import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { FormHandles, Scope } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';

import getValidationError from '../../../utils/getValidationsErros';
import parseOptions from '../../../utils/parseToSelectOptions';
import { colors, parseDate } from '../../../utils';
import searchMyBase from './services/searchMyBase';
import additionSchemaServices from './services/additionSchemaServices';

/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** components */
import Layout from '../../../components/Layout';
import { Grid } from '../../../components/Flex';
import Reported from '../Reported';
import {
  WrapperLabel, Input, InputMask,
  InputCheckbox, Select, TextArea, Button, ButtonIcon, InputHidden,
} from '../../../components/Forms';
import { PersonIcon, CarIcon, MagicIcon } from '../../../styles/icons';
import {
  Container, Title, Content, WrapperConduction,
} from './styles';

/** interface */
import { IOrder } from '../../../interfaces';

interface ISelectValues{
  value: string | number;
  label: string;
}

const Process: React.FC = () => {
  const { id } = useParams<{id: string}>();
  const {
    httpGet, httpPost, loading,
  } = useHttp();
  const { addToast } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [isHidden, setIsHidden] = useState(true);
  const [isConduction, setIsConduction] = useState(false);

  const [systems, setSystem] = useState<ISelectValues[]>([]);
  const [results, setResults] = useState([]);
  const [order, setOrder] = useState({} as IOrder);
  const [searchLoading, setSearchLoading] = useState(false);

  const handleCopy = useCallback(async () => {
    setSearchLoading(true);
    const result = await searchMyBase(order).finally(() => {
      setSearchLoading(false);
    });
    /* eslint-disable no-restricted-syntax */
    if (result) {
      for (const [key, value] of Object.entries(result)) {
        formRef.current?.setFieldValue(key, value);
      }
    }
  }, [order]);

  useEffect(() => {
    async function load():Promise<void> {
      const { status, data } = await httpGet(`/orders/${id}`);

      if (status !== 200) {
        history.push(`/order/answered/detail/${id}`);
        return;
      }

      setOrder(data);

      const [respSystems, respResults] = await Promise.all([
        httpGet('/systems'),
        httpGet('/results'),
      ]);

      if (respSystems.status === 200) {
        setSystem(parseOptions(respSystems.data));
      }

      if (respResults.status === 200 && data.type) {
        const resultsFilteredByType = respResults.data
          .filter((result: any) => (result.type === data.type || !result.type));

        const resultsParse = resultsFilteredByType.map((result: any) => ({
          value: result.id,
          label: result.name,
          conduction: result.conduction,
        }));
        // retirar o evadido
        const results = resultsParse.filter((result: any) => (result.value !== 4));
        setResults(results);
      }
    }

    load();
  }, [history, httpGet, id]);

  const handleSubmit = useCallback(async (data: any) => {
    const answer = {
      ...data,
      id: order.id,
      result_id: data?.result_id?.value,
      isConduction,
      vehicle: {
        ...data.vehicle,
        chassi: data?.vehicle?.chassi.toUpperCase() || null,
        plate: data?.vehicle ? data?.vehicle?.plate.replace(/[_\W]/g, '').toUpperCase() : null,
        engine_number: data?.vehicle ? data?.vehicle?.engine_number.replace(/\W/g, '').toUpperCase() : null,
        color: data?.vehicle?.color?.value || null,
      },
      person: {
        ...data.person,
        birthday: parseDate(data?.person?.birthday),
        document: data?.person?.document.replace(/\D/g, '') || null,
        gender: data?.person?.gender?.value || null,
      },
    };

    const additionSchema = additionSchemaServices(order, answer);
    const schema = Yup.object().shape({
      result_id: Yup.string().ensure().required('Resultado é obrigatório'),
      systems: Yup.array().required('Sistema é obrigatório').typeError('Sistema informado não é válido.'),
      note: Yup.string(),
      ...additionSchema,
    });

    try {
      formRef.current?.setErrors({});
      await schema.validate(answer, { abortEarly: false });

      const result: any = await httpPost('/answers', answer);
      const { status, response } = result;

      if (status === 200) {
        addToast({ title: 'Pedido de Consulta respondido com sucesso.' });
        history.push('/orders/awaiting');
      }

      if (response?.status === 405) {
        history.push(`/order/answered/detail/${id}`);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [addToast, isConduction, order.type]);// eslint-disable-line

  const handleShowAllFieldForms = useCallback((data: any) => {
    const { value, conduction } = data;

    setIsConduction(conduction);

    if (value === 1 || value === 9) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, []);

  const breadcrumbs = {
    icon: order.type === 'vehicle' ? CarIcon : PersonIcon,
    links: [{ path: '', title: 'Processar Consulta' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <Title className="first">
            Dados informados para consulta
          </Title>
          <Reported order={order} />
          <Title>
            Sistemas consultados
          </Title>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <WrapperLabel label="Sistemas consultados" xs={12} sm={12} md={6}>
                <InputCheckbox name="systems" options={systems} />
              </WrapperLabel>
              <WrapperLabel label="Resultado da consulta" xs={12} sm={12} md={6}>
                <Select
                  name="result_id"
                  onChange={handleShowAllFieldForms}
                  options={results}
                />
              </WrapperLabel>
              <WrapperLabel label="Observação" xs={12}>
                <TextArea name="note" />
              </WrapperLabel>
            </Grid>
            <span className={isHidden ? 'hidden' : ''}>
              {order.type === 'vehicle' && (
              <>
                <Title>
                  Veículo encontrado
                </Title>
                <Grid container spacing={1}>
                  <Scope path="vehicle">
                    <InputHidden name="vehicle_id" />
                    <WrapperLabel label="Placa" xs={12} sm={4} md={4}>
                      <InputMask name="plate" mask="aaa-9*99" style={{ textTransform: 'uppercase' }} />
                    </WrapperLabel>
                    <WrapperLabel label="Chassi" xs={12} sm={4} md={4}>
                      <InputMask name="chassi" mask="" style={{ textTransform: 'uppercase' }} />
                    </WrapperLabel>
                    <WrapperLabel label="Motor" xs={12} sm={4} md={4}>
                      <Input name="engine_number" style={{ textTransform: 'uppercase' }} />
                    </WrapperLabel>
                    <WrapperLabel label="Marca/Modelo" xs={6} sm={6} md={6}>
                      <Input name="brand" />
                    </WrapperLabel>
                    <WrapperLabel label="Cor" xs={6} sm={6} md={6}>
                      <Select name="color" options={colors} />
                    </WrapperLabel>
                  </Scope>
                </Grid>
              </>
              )}
              {order.type === 'person' && (
              <>
                <Title>
                  Pessoa encontrada
                </Title>
                <Grid container spacing={1}>
                  <Scope path="person">
                    <InputHidden name="person_id" />
                    <WrapperLabel label="CPF" xs={12} sm={4} md={3}>
                      <InputMask name="document" type="tel" mask="999.999.999-99" />
                    </WrapperLabel>
                    <WrapperLabel label="RG" xs={12} sm={4} md={3}>
                      <Input name="document_secondary" />
                    </WrapperLabel>
                    <WrapperLabel label="Nascimento" xs={12} sm={4} md={3}>
                      <InputMask name="birthday" type="tel" mask="99/99/9999" placeholder="DD/MM/YYYY" />
                    </WrapperLabel>
                    <WrapperLabel label="Gênero" xs={12} sm={12} md={3}>
                      <Select
                        name="gender"
                        options={[
                          { value: 'Masculino', label: 'Masculino' },
                          { value: 'Feminino', label: 'Feminino' },
                        ]}
                      />
                    </WrapperLabel>
                    <WrapperLabel label="Nome" xs={12} sm={6} md={6}>
                      <Input name="name" />
                    </WrapperLabel>
                    <WrapperLabel label="Vulgo" xs={12} sm={6} md={6}>
                      <Input name="nickname" />
                    </WrapperLabel>
                    <WrapperLabel label="Mãe" xs={12} sm={6} md={6}>
                      <Input name="mather" />
                    </WrapperLabel>
                    <WrapperLabel label="Pai" xs={12} sm={6} md={6}>
                      <Input name="father" />
                    </WrapperLabel>

                  </Scope>
                </Grid>
              </>
              )}
            </span>

            <Grid container alignItems="flex-end" justify="space-between">

              <WrapperConduction className={isConduction ? '' : 'hidden'}>
                <WrapperLabel label="Confirmo" xs={12} sm={12}>
                  <InputCheckbox
                    name="confirmed"
                    options={[{
                      value: 1,
                      label: 'Estou ciente que esta resposta implicará na condução à DP.',
                    }]}
                  />
                </WrapperLabel>
              </WrapperConduction>

              <Grid item xs={2}>
                <span className={isHidden ? 'hidden' : ''}>
                  <ButtonIcon
                    title="Copiar os dados"
                    onClick={handleCopy}
                    isLoading={searchLoading}
                  >
                    <MagicIcon />
                  </ButtonIcon>
                </span>
              </Grid>
              <Grid item xs={10} sm={6} md={4}>
                <Button type="submit">Salvar</Button>
              </Grid>
            </Grid>

          </UForm>

        </Content>
      </Container>
    </Layout>
  );
};

export default Process;
