import * as Yup from 'yup';
import { IOrder, IAnswer } from '../../../../interfaces';
import { validateCpf, validateRG } from '../../../../utils';

export default function additionSchemaServices(order: IOrder, answer: IAnswer):any {
  if (order.type === 'vehicle' && answer.result_id !== 1 && answer.result_id !== 9) {
    if (answer?.isConduction) {
      return {
        confirmed: Yup.array().required('Você precisa confirmar a implicação da resposta.').typeError(''),
        vehicle: Yup.object({
          plate: Yup.string()
            .test('is-valid', 'Você não pode informar uma Placa diferente da informada para consulta.', (value) => {
              if (order.plate && value !== order.plate) {
                return false;
              }
              return true;
            }),
          chassi: Yup.string().required('Chassi é obrigatório'),
          brand: Yup.string().required('Marca/Modelo é obrigatório'),
          color: Yup.string().ensure().required('Cor é obrigatório'),
        }),
      };
    }

    return {
      vehicle: Yup.object({
        plate: Yup.string()
          .test('is-valid', 'Você não pode informar uma Placa diferente da informada para consulta.', (value) => {
            if (order.plate && value !== order.plate) {
              return false;
            }
            return true;
          }),
        chassi: Yup.string().required('Chassi é obrigatório'),
        brand: Yup.string().required('Marca/Modelo é obrigatório'),
        color: Yup.string().ensure().required('Cor é obrigatório'),
      }),
    };
  }

  if (order.type === 'person' && answer.result_id !== 1 && answer.result_id !== 9) {
    if (!answer.person?.document && !answer.person?.document_secondary) {
      if (answer?.isConduction) {
        return {
          confirmed: Yup.array().required('Você precisa confirmar a implicação da resposta.').typeError(''),
          person: Yup.object({
            document: Yup.string().nullable()
              .test('is-valid', 'Insira um CPF válido ou um RG', (value) => validateCpf(value, true)),
            document_secondary: Yup.string().nullable()
              .test('is-valid', 'Insira um CPF válido ou um RG', (value) => validateRG(value, true)),
            birthday: Yup.date().typeError('Insira uma data válida'),
            name: Yup.string().required('Nome é obrigatório'),
            gender: Yup.string().ensure().required('Gênero é obrigatório'),
          }),
        };
      }

      if (answer?.isConduction) {
        return {
          confirmed: Yup.array().required('Você precisa confirmar a implicação da resposta.').typeError(''),
          person: Yup.object({
            document: Yup.string().nullable()
              .test('is-valid', 'Insira um CPF válido ou um RG', (value) => validateCpf(value, true)),
            document_secondary: Yup.string().nullable()
              .test('is-valid', 'Insira um CPF válido ou um RG', (value) => validateRG(value, true)),
            birthday: Yup.date().typeError('Insira uma data válida'),
            name: Yup.string().required('Nome é obrigatório'),
            gender: Yup.string().ensure().required('Gênero é obrigatório'),
          }),
        };
      }

      return {
        person: Yup.object({
          document: Yup.string().nullable()
            .test('is-valid', 'Insira um CPF válido ou um RG', (value) => validateCpf(value, true)),
          document_secondary: Yup.string().nullable()
            .test('is-valid', 'Insira um CPF válido ou um RG', (value) => validateRG(value, true)),
          birthday: Yup.date().typeError('Insira uma data válida'),
          name: Yup.string().required('Nome é obrigatório'),
          gender: Yup.string().ensure().required('Gênero é obrigatório'),
        }),
      };
    }

    if (answer?.isConduction) {
      return {
        confirmed: Yup.array().required('Você precisa confirmar a implicação da resposta.').typeError(''),
        person: Yup.object({
          document: Yup.string().nullable()
            .test('is-valid', 'Insira um CPF válido', (value) => validateCpf(value, false))
            .test('is-valid', 'Você não pode informar um CPF diferente do informado para consulta.', (value) => {
              if (order.document && value !== order.document) {
                return false;
              }
              return true;
            }),
          document_secondary: Yup.string().nullable()
            .test('is-valid', 'Insira um RG válido', (value) => validateRG(value, false)),
          birthday: Yup.date().typeError('Insira uma data válida'),
          name: Yup.string().required('Nome é obrigatório'),
          gender: Yup.string().ensure().required('Gênero é obrigatório'),
        }),
      };
    }

    return {
      person: Yup.object({
        document: Yup.string().nullable()
          .test('is-valid', 'Insira um CPF válido', (value) => validateCpf(value, false))
          .test('is-valid', 'Você não pode informar um CPF diferente do informado para consulta.', (value) => {
            if (order.document && value !== order.document) {
              return false;
            }
            return true;
          }),
        document_secondary: Yup.string().nullable()
          .test('is-valid', 'Insira um RG válido', (value) => validateRG(value, false)),
        birthday: Yup.date().typeError('Insira uma data válida'),
        name: Yup.string().required('Nome é obrigatório'),
        gender: Yup.string().ensure().required('Gênero é obrigatório'),
      }),
    };
  }

  return {};
}
