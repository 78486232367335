import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import getValidationError from '../../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../../hooks/http';
import { useToast } from '../../../../hooks/toast';

/** components */
import {
  WrapperLabel, Input, DatePicker, Select, Button,
} from '../../../../components/Forms';
import { Container } from './styles';

interface ISettings {
  id: number;
  spa_version: string;
  managementsIds: string;
}

const Settings: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const {
    httpGet, httpPut, loading,
  } = useHttp();
  const [settings, setSettings] = useState<ISettings>({} as ISettings);
  const [managements, setManagements] = useState([]);

  useEffect(() => {
    async function load(): Promise<void> {
      const [respSettings, respManagements] = await Promise.all([
        httpGet('settings'),
        httpGet('managements/all'),
      ]);

      const settings = respSettings.data;
      if (settings) {
        const cortexStatus = { value: settings.cortex_status, label: settings.cortex_status ? 'Ativo' : 'Inativo' };
        setSettings({
          ...settings,
          cortex_status: cortexStatus,
        });
      }

      const managements = respManagements.data;
      if (managements) {
        const managementsParsed = managements
          .map((management: any) => ({ value: management.id, label: management.initials }));
        setManagements(managementsParsed);
      }
    }

    load();
  }, [httpGet]);

  useEffect(() => {
    if (Object.keys(settings).length === 0 && Object.keys(managements).length === 0) {
      return;
    }

    const managementsIds = settings.managementsIds
      ? settings.managementsIds.split(/\s*,\s*/)
      : [];

    const managementsFiltered = managements.filter((management:any) => {
      if (managementsIds.some((id) => parseInt(id, 10) === management.value)) {
        return true;
      }
      return false;
    });

    const data = {
      ...settings,
      managementsIds: managementsFiltered,
    };

    formRef.current?.setData(data);
  }, [settings, managements]);

  const handleSubmit = useCallback(async (payload: any) => {
    const data = {
      ...payload,
      cortex_status: payload?.cortex_status.value,
      managementsIds: payload.managementsIds
        ? payload.managementsIds.map((managementsIds:any) => managementsIds.value).join(',')
        : null,
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        version: Yup.string().required('A versão é obrigatória'),
      });

      await schema.validate(data, { abortEarly: false });

      const { status } = await httpPut('settings', data);
      if (status === 200) {
        addToast({ title: 'Editado com sucesso!' });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [settings]);// eslint-disable-line
  return (

    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Versão da SPA" xs={12} sm={12} md={4}>
            <Input name="version" />
          </WrapperLabel>
          <WrapperLabel label="Data para atualizar o perfil" xs={12} sm={12} md={4}>
            <DatePicker
              name="profile_update_limit"
              timeInputLabel="Hora:"
              dateFormat="dd/MM/yyyy h:mm aa"
              maxDate={new Date()}
              showTimeInput
            />
          </WrapperLabel>
          <WrapperLabel label="Córtex Api" xs={12} sm={12} md={4}>
            <Select
              name="cortex_status"
              options={[
                { value: true, label: 'Ativo' },
                { value: false, label: 'Inativo' },
              ]}
            />
          </WrapperLabel>
        </Grid>
        <Grid container spacing={1}>
          <WrapperLabel label="Gerências que vão exigir a confirmação da presença p/ Consultar" xs={12}>
            <Select name="managementsIds" options={managements} isMulti />
          </WrapperLabel>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item xs={12} md={4}>
            <Button type="submit" isLoading={loading}>Salvar</Button>
          </Grid>
        </Grid>
      </UForm>
    </Container>

  );
};

export default Settings;
