import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { Grid } from '@material-ui/core';

/** hooks */
import { useHttp } from '../../../hooks/http';

/** components */
import Layout from '../../../components/Layout';
import Card from './Card';
import Pagination from '../../../components/Pagination';
import HeaderSimple from '../../../components/Headers/Simple';
import { Select, ButtonIcon } from '../../../components/Forms';

import { ListIcon, SearchIcon } from '../../../styles/icons';
import { Container, NoResult, WrapperSearch } from './styles';

/** interface */
import { IPaginate } from '../../../interfaces';

const breadcrumbs = {
  icon: ListIcon,
  links: [
    { path: '/dashboard', title: 'Dashboard' },
    { path: '', title: 'Minhas Consultas' },
  ],
};

const Answers: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { httpGet, loading } = useHttp();
  const [results, setResults] = useState([]);
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState({ result_id: null });
  const [paginate, setPaginate] = useState<IPaginate>({ page: 1, perPage: 6, lastPage: 1 });

  useEffect(() => {
    (async (): Promise<void> => {
      const { status, data } = await httpGet('/results');
      if (status === 200) {
        const resultsParsed = data
          .map((result: any) => ({ value: result.id, label: result.name }));

        setResults(resultsParsed);
      }
    })();
  }, [httpGet]);

  const handlePaginate = useCallback(async (payload: IPaginate) => {
    setPaginate(payload);

    const { status, data } = await httpGet(
      'orders/me',
      { params: { ...payload, ...filter } },
      true,
    );
    if (status === 200) {
      setOrders(data.data);
      setPaginate(data.pagination);
    }
  }, [filter, httpGet]);

  const loadOrders = useCallback(async (isLoading: boolean) => {
    const { status, data } = await httpGet(
      'orders/me',
      { params: { page: 1, perPage: 6 } },
      isLoading,
    );
    if (status === 200) {
      setOrders(data.data);
      setPaginate(data.pagination);
    }
  }, [httpGet]);

  useEffect(() => {
    loadOrders(true);
  }, [loadOrders]);

  useEffect(() => {
    const refresh = setInterval(() => {
      const isOrderAwaitResult = orders.filter((order: any) => !order.result);
      if (isOrderAwaitResult?.length) {
        formRef.current?.setFieldValue('result_id', null);
        loadOrders(false);
      }
    }, 1000 * 12);

    return () => {
      clearInterval(refresh);
    };
  }, [loadOrders, orders]);

  const handleSubmit = useCallback(async (payload: any) => {
    const filter = {
      result_id: payload?.result_id ? payload?.result_id.value : null,
    };

    setFilter(filter);

    const { status, data } = await httpGet(
      'orders/me',
      { params: { ...paginate, page: 1, ...filter } },
      true,
    );
    if (status === 200) {
      setOrders(data.data);
      setPaginate(data.pagination);
    }
  }, [httpGet, paginate]);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>

        <HeaderSimple>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <WrapperSearch>
                <Select name="result_id" options={results} isClearable />
                <ButtonIcon
                  title="Filtrar"
                  type="submit"
                >
                  <SearchIcon />
                </ButtonIcon>
              </WrapperSearch>
            </Grid>
          </UForm>
        </HeaderSimple>

        {orders?.length < 1 && (
          <NoResult>
            Não há Resultado.
          </NoResult>
        )}

        <Grid container spacing={1}>
          {orders.map((order: any) => <Card key={`card-${order.id}`} order={order} />)}
        </Grid>

        <Pagination paginate={paginate} handlePaginate={handlePaginate} />

      </Container>
    </Layout>

  );
};

export default Answers;
