/* eslint-disable import/no-duplicates */
import { formatDistance } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import pt from 'date-fns/locale/pt-BR';

const distanceDateTime = (
  dateMinor: string | Date,
  dateBigger: string | Date,
  precision?: boolean,
): string => {
  if (typeof dateMinor === 'string' && typeof dateBigger === 'string') {
    const distance = formatDistance(
      new Date(zonedTimeToUtc(dateMinor, 'UTC')),
      new Date(zonedTimeToUtc(dateBigger, 'UTC')),
      {
        locale: pt,
        addSuffix: false,
        includeSeconds: precision,
      },
    );

    if (precision) {
      return distance;
    }

    return distance.replace(/menos de um minuto/, 'agora');
  }

  return 'erro';
};

export default distanceDateTime;
