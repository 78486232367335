import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { FiMail } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import api, { isNetworkError } from '../../services/api';

import getValidationError from '../../utils/getValidationsErros';

import InputWrapped from '../../components/Forms/InputWrapped';
import Button from '../../components/Forms/Button';
import logoImg from '../../assets/auth-logo.svg';
import {
  Container, Content, Background, AnimationContainer,
} from './styles';

/** hooks */
import { useToast } from '../../hooks/toast';

interface ResetData{
  email: string
}

const CadPreview: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (dado: ResetData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string().required('E-mail é obrigatório').email('Digite um e-mail válido'),
      });

      await schema.validate(dado, { abortEarly: false });

      setIsLoading(true);
      await api.post('/auth/preview', {
        email: dado.email,
      }).finally(() => {
        setIsLoading(false);
      });

      addToast({
        title: 'Sucesso',
        description: 'Acesse seu e-mail para cadastrar uma senha. Lembre-se de verificar a caixa de Spam.',
        time: 0,
      });

      formRef.current?.clearField('email');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);

        return;
      }

      if (isNetworkError(err)) {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: 'Você está sem acesso à internet ou o servidor está off-line.',
        });
        return;
      }

      if (err.response && err.response.status === 429) {
        addToast({
          type: 'error',
          title: 'Opsss!',
          description: 'Você excedeu o limite de requisições para o servidor.',
        });
        return;
      }

      if (err.response && err.response.status === 400) {
        const { message } = Array.isArray(err.response.data)
          ? err.response.data[0]
          : err.response.data;
        addToast({
          type: 'error',
          title: message,
        });
        return;
      }

      addToast({
        title: 'Error',
        type: 'error',
        description: 'Erro ao tentar cadastrar acesso',
      });
    }
  }, []); // eslint-disable-line

  return (
    <Container>
      <Background />
      <Content>

        <AnimationContainer>
          <img src={logoImg} alt="DSCi" />

          <Form ref={formRef} initialData={{}} onSubmit={handleSubmit}>
            <h1>Usuário - Hórus</h1>

            <InputWrapped
              name="email"
              mask=""
              icon={FiMail}
              type="email"
              placeholder="digite seu e-mail"
            />

            <Button
              type="submit"
              color="secondary"
              isLoading={isLoading}
              size="large"
            >Enviar
            </Button>
            <Link to="/signin">Voltar para login</Link>
          </Form>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default CadPreview;
