import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { formatDateTime } from '../../../utils';
/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';
/** components */
import Layout from '../../../components/Layout';
import Flex, { Grid } from '../../../components/Flex';
import {
  ListIcon,
} from '../../../styles/icons';
import {
  Container, WrapperHeader, Title,
} from './styles';

import { ISupervision } from '../../../interfaces';

const breadcrumbs = {
  icon: ListIcon,
  links: [
    { path: '', title: 'Detalhe da Supervisão' },
  ],
};

const Detail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { httpGet, loading } = useHttp();
  const { addToast } = useToast();
  const [supervision, setSupervision] = useState<ISupervision>({} as ISupervision);

  useEffect(() => {
    async function loadAnswer(): Promise<void> {
      const { status, data } = await httpGet(`/ResearcherSupervision/${id}`);
      if (status === 200) {
        setSupervision(data);
      } else {
        addToast({ title: 'Erro ao listar.' });
        history.push('/ceperjs/list');
      }
    }
    loadAnswer();
  }, [addToast, history, id]);  // eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <>
          <WrapperHeader>
            {supervision?.action?.name}
          </WrapperHeader>
          <Title>Dados da(o): {supervision?.action?.type}</Title>
          <Grid container spacing={1} justify="space-between">
            <Flex title="Pesquisadores da equipe(nomes)" xs={12} sm={4} md={4}>
              <b> {supervision?.researchers_ids || 'não informado'} </b>
            </Flex>
            <Flex title="Região da pesquisa" xs={4} sm={4} md={4}>
              <b> {supervision?.city || 'não informado'} </b>
            </Flex>
            <Flex title="Quantidade pesquisadores" xs={4} sm={4} md={4}>
              <b> {supervision?.number_researchers || 'não informado'} </b>
            </Flex>
            <Flex title="O(s) Pesquisador(es) compriram suas tarefas ?" xs={4} sm={4} md={4}>
              <b> {supervision?.fulfilled_tasks || 'não informado'} </b>
            </Flex>
            <Flex title="Caso NÃO. Qual(is) pesquisador(es) deixou(aram) de comprir as tarefas ?" xs={12} sm={4} md={4}>
              <b> {supervision?.fulfilled_tasks_ids || 'não informado'} </b>
            </Flex>
            <Flex title="Desempenho das atividades" xs={6} sm={4} md={4}>
              <b> {supervision?.performance || 'não informado'} </b>
            </Flex>
            <Flex title="Trabalho em equipe" xs={12} sm={4} md={4}>
              <b> {supervision?.team_work || 'não informado'} </b>
            </Flex>
            <Flex title="Relacionamento interpessoal" xs={12} sm={4} md={4}>
              <b> {supervision?.interpersonal_relationship || 'não informado'} </b>
            </Flex>
            <Flex title="Registro do relato do trabalho" xs={6} sm={4} md={4}>
              <b> {supervision?.record_work_report || 'não informado'} </b>
            </Flex>
            <Flex title="Responsabilidade e regularidade" xs={12} sm={4} md={4}>
              <b> {supervision?.responsibility_regularity || 'não informado'} </b>
            </Flex>
            <Flex title="Percepção e análise crítica" xs={12} sm={4} md={4}>
              <b> {supervision?.critical_perception_analysis || 'não informado'} </b>
            </Flex>
            <Flex title="Organização e planejamento" xs={12} sm={4} md={4}>
              <b> {supervision?.organization_planning || 'não informado'} </b>
            </Flex>
            <Flex title="Proatividade" xs={6} sm={4} md={4}>
              <b> {supervision?.proactivity || 'não informado'} </b>
            </Flex>
            <Flex title="Motivação" xs={12} sm={4} md={4}>
              <b> {supervision?.motivation || 'não informado'} </b>
            </Flex>
            <Flex title="Iniciativa" xs={12} sm={4} md={4}>
              <b> {supervision?.initiative || 'não informado'} </b>
            </Flex>
            <Flex title="Capacidade de comunição" xs={12} sm={4} md={4}>
              <b> {supervision?.communication_capacity || 'não informado'} </b>
            </Flex>
            <Flex title="Cooperação" xs={12} sm={4} md={4}>
              <b> {supervision?.cooperation || 'não informado'} </b>
            </Flex>
          </Grid>
          <Grid container spacing={1} justify="space-between">
            <Flex title="Positivamente" xs={12} sm={4} md={4}>
              <b> {supervision?.positively_highlighted || 'não informado'} </b>
            </Flex>
            <Flex title="Negativamente" xs={12} sm={4} md={4}>
              <b> {supervision?.highlighted_negatively || 'não informado'} </b>
            </Flex>
          </Grid>
          <Grid container spacing={1} justify="space-between">
            <Flex title="Data" xs={6} sm={4} md={4}>
              <b>{formatDateTime(supervision?.created_at)}</b>
            </Flex>
            <Flex title="Gerência" xs={6} sm={4} md={4}>
              <b>{supervision?.managements?.name || 'não informado'}</b>
            </Flex>
          </Grid>
        </>
      </Container>
    </Layout>
  );
};

export default Detail;
