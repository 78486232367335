import { Props } from 'react-select';
import styled, { css } from 'styled-components';

export const Container = styled.div`
   width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;

  padding: 8px 16px;

  form{
    width: 100%;
  }

`;

export const ContainerButtons = styled.div`
  border-top: 16px;
  width: 100%;
  padding: 16px;

`;

export const Wrapper = styled.div`
  padding: 16px;
  margin-bottom: 40px;
  border:1px solid rgb(128,128,128);
  border-radius: 4px;

`;

export const WrapperHidden = styled.div<Props>`
    min-width: 50%;
    display:flex;
    flex-direction: column;
    margin-top: 4px;
    padding-left: 4px;
  ${(props) => !props.show && css`
    position: absolute;
    right: 9999px;
  `}

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export const WrapperAddress = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  align-items: center;

  h3{
    color: var(--color-primary);
    margin-bottom: 8px;

  }


`;

export const WrapperHeader = styled.div`
  border-bottom: solid 1px rgb(128,128,128);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span{
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  svg{
    font-size: 24px;
    margin: 8px;
    border:solid 1px;
  }

  h2{
    margin: 10px;
    font-size: 1rem;
    font-weight: 700;
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  display: flex;


  border: none;
  border-radius: 4px;
  color: var(--color-white);
  background: var(--color-button);

  font-size: 1.1rem;
  font-weight: 300;

`;

export const WrapperButton = styled.span`
  display: flex;
  width: 100%;
  > div{
    width: calc(100% - 48px);
    input{
      width: calc(100% - 46px);
    }
  }

  button{
    width: 40px;
  }

`;

export const Title = styled.div`
   font-size: 18px;
  font-weight: bold;
  display:flex;
  align-items:center;

  span{
    font-size: 20px;
    margin-left: 8px;
    text-decoration: line-through;
  }

  svg{
    font-size: 30px;
    margin-right: 8px;
  }
  `;

export const WrapperTitle = styled.div`

  border-bottom: 1px solid;


`;

export const GridButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 40px 1fr 1fr 1fr;
  grid-template-rows: 56px;
  grid-template-areas:
    'btn-inf space btn-cancel btn-submit';


  @media (max-width: 767.98px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 56px 56px;
    grid-template-areas:
      'btn-cancel btn-submit'
      'btn-inf space';
  }
`;

export const Red = styled.div<Props>`
  color: ${(props) => (props.go ? '#D3D3D3' : '#CC0001')};
`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;

export const WrapperBtnCancel = styled.div`
  grid-area: btn-cancel;
`;

export const WrapperBtnSubmit = styled.div`
  grid-area: btn-submit;
`;

export const LineTitle = styled.div`
  width: 100%;
  padding: 5px;
  margin:10px 0px;
  border-bottom: 1px solid;
  border-bottom-style: dotted;
`;

export const customStyle = {
  control: (base: any): any => ({
    ...base,
    '&:hover': '1px solid #ccc',
    minHeight: 40,
    border: '1px solid #ccc',
    background: 'transparent',
    boxShadow: 'none',
    textTransform: 'uppercase',
  }),
};

export const customStyleErr = {
  control: (base: any): any => ({
    ...base,
    '&:hover': '1px solid brown',
    minHeight: 40,
    border: '1px solid brown',
    background: 'transparent',
    boxShadow: 'none',
    textTransform: 'uppercase',
  }),
};
