import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px;
  width: 100%;
  @media (max-width: 600px){
    flex-direction: column;
    
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 10px 10px;
  
  @media (min-width: 600px){
    justify-content: space-between;
  }
  
  @media (max-width: 600px){
    flex-direction: column;
    
  }
`;

export const Img = styled.img`
  width: 180px;
  height: 80px;
  margin: 0 20px;
`;

export const User = styled.div`
    background-color: blue;
    color: white;
`;