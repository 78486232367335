import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import { useHttp } from 'hooks/http';
import Layout from 'components/Layout';
import { FaCheck, FaFileAlt, FaUserShield } from 'react-icons/fa';
import Simple from 'components/Headers/Simple';
import { BsPencil } from 'react-icons/bs';
import {
  Container, Button, WrapperAddress, Title,
} from './styles';
import {
  CarIcon, Reply, UserFriends, Handcuffs,
} from '../../../styles/icons';

interface IStatus {
  involved: boolean,
  civilPolice: boolean,
  carInvolved: boolean,
  seizure: boolean,
}

const EditBopm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { httpGet } = useHttp();
  const bopmId = localStorage.getItem('bopmId');
  const [status, setStatus] = useState<IStatus>();

  if (!bopmId) {
    history.push('/bopm');
    addToast({ title: 'Erro ID ocorrência!', type: 'error' });
  }

  useEffect(() => {
    async function load(): Promise<any> {
      const { data } = await httpGet(`/bopms/status/${bopmId}`);
      setStatus(data);
    }
    load();
  }, [bopmId, httpGet]);

  const breadcrumbs = {
    icon: FaFileAlt,
    links: [{ path: '/bopm', title: 'Bopm' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Simple>
          <Title>
            <BsPencil />Editar Ocorrencia
          </Title>
        </Simple>
        <WrapperAddress>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  type="button"
                  onClick={() => history.push('/bopm/involveds/show')}
                >
                  <UserFriends size={32} />
                  Envolvidos
                  {status?.involved === true ? <span><FaCheck size={24} /></span> : ''}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  type="button"
                  onClick={() => history.push('/bopm/polices/show')}
                >
                  <FaUserShield size={32} />
                  Autoridade Policial
                  {status?.civilPolice === true ? <span><FaCheck size={24} /></span> : ''}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  type="button"
                  onClick={() => history.push('/bopm/carInvolved/show')}
                >
                  <CarIcon size={32} />
                  Veículos Envolvidos
                  {status?.carInvolved === true ? <span><FaCheck size={24} /></span> : ''}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="displayNone">
                <Button
                  type="button"
                  onClick={() => history.push('/bopm/seizures/show')}
                >
                  <Handcuffs size={32} />
                  Apreenções
                  {status?.seizure === true ? <span><FaCheck size={24} /></span> : ''}
                </Button>
              </Grid>
              <Grid container justify="flex-end" item xs={12} sm={6} md={4}>
                <Button
                  id="volta"
                  type="button"
                  onClick={() => history.push('/bopm')}
                >
                  <Reply size={32} />
                  Volta
                </Button>
              </Grid>
            </Grid>
          </Container>
        </WrapperAddress>
      </Container>
    </Layout>
  );
};

export default EditBopm;
