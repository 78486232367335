import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
/** hooks */
import { useHttp } from '../../hooks/http';
import { useAuth } from '../../hooks/auth';

/** components */
import Layout from '../../components/Layout';
import Address from '../Orders/Address';
import { PoliceCarFill } from '../../styles/icons';

import { Container, WrapperAddress } from './styles';
import Started from './Started';
import Closed from './Closed';

interface ICoord {
    lat: number;
    lng: number;
}

interface IService {
    id: string;
}

const Bdtd: React.FC = () => {
  const history = useHistory();
  const { httpGet, loading } = useHttp();
  const { user } = useAuth();
  const [coord, setCoords] = useState<ICoord>({} as ICoord);
  const [closed, setClosed] = useState<IService | null>(null);
  const { id } = useParams<{ id: string }>();

  const breadcrumbs = {
    icon: PoliceCarFill,
    links: [{ path: '', title: 'Bdtd' }],
  };

  useEffect(() => {
    async function load(): Promise<any> {
      const { status, data } = await httpGet('/bdtds/status');
      if (status === 200) {
        setClosed(data);
        return;
      }
      setClosed(null);
    }
    load();
  }, [history, httpGet, user]);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        {id || closed
          ? (
            <>
              {!coord.lat && (
                <WrapperAddress>
                  <h3><span>Selecione o </span>local</h3>
                  <Address
                    handleCoord={(coord: ICoord) => {
                      setCoords(coord);
                    }}
                  />
                </WrapperAddress>
              )}
              {(coord.lat) && (
                <Closed coord={coord} />
              )}
            </>
          ) : (
            <>
              {!coord.lat && (
                <WrapperAddress>
                  <h3><span>Selecione o </span>local</h3>
                  <Address
                    handleCoord={(coord: ICoord) => {
                      setCoords(coord);
                    }}
                  />
                </WrapperAddress>
              )}
              {(coord.lat) && (
                <Started coord={coord} />
              )}
            </>
          )}
      </Container>
    </Layout>
  );
};

export default Bdtd;
