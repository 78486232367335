import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;

  padding: 8px 16px;

  form{
    width: 100%;
  }
`;

export const WrapperAddress = styled.div`
 background: #fff;
  padding: 16px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 8px;

  h3{
    color: var(--color-primary);
    margin-bottom: 8px;

  }
`;

export const WrapperTitle = styled.div`
  
  border-bottom: 1px solid;
  `;


export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  display:flex;
  align-items:center;

  span{
    font-size: 20px;
    margin-left: 8px;
    text-decoration: line-through;
  }

  svg{
    font-size: 30px;
    margin-right: 8px;
  }
  `;


export const Button = styled.button`
  width: 100%;
  padding: 14px;
  display: flex;

  align-items: center;
  border: none;
  border-radius: 4px;
  color: var(--color-white);
  background: var(--color-button);

  font-size: 1.1rem;
  font-weight: 300;
   
  svg{
    margin-right: 16px;
  } 

`;
