import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import ReactInputMask from 'react-input-mask';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

interface Props {
  name: string;
  disabled?: boolean;
}

const InputPhone: React.FC<Props> = ({ name, disabled = false }) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const {
    fieldName, registerField, defaultValue, error,
  } = useField(name);

  const [state, setState] = useState({
    value: '',
    mask: '(99) 9999-9999',
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref: any, value: string) => {
        if (value) {
          let mask = '(99) 9999-9999';
          if (/^.+\s9/.test(value)) {
            mask = '(99) 99999-9999';
          }
          setState({ mask, value });
        } else {
          setState({
            mask: '(99) 9999-9999',
            value: '',
          });
        }
      },
    });
  }, [fieldName, registerField]);

  const handleFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
    const { value } = event.target;

    const newState = {
      mask: '(99) 9999-9999',
      value,
    };
    if (/^.+\s9/.test(value)) {
      newState.mask = '(99) 99999-9999';
    }

    setState(newState);
  };

  return (
    <Container
      readOnly={disabled}
      isErrored={!!error}
      isFocused={isFocused}
      onBlur={handleInputBlur}
    >

      <ReactInputMask
        ref={inputRef}
        onFocus={handleFocused}
        onChange={handleChange}
        defaultValue={defaultValue}
        type="tel"
        disabled={disabled}
        {...state}

      />

      <Error title={error || ''}>
        <FiAlertCircle color="#c53030" size={20} />
      </Error>
    </Container>
  );
};

export default InputPhone;
