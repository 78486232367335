import React, { useState, useEffect, useCallback } from 'react';
/** hooks */
import { useHttp } from '../../../../hooks/http';
import { Container } from './styles';

/** componentes */
import Pagination from '../../../../components/Pagination';
import Header from '../components/Header';
import Card, { Flex, Grid } from '../components/Card';
import { IPaginate } from '../../../../interfaces';

const Ceperj: React.FC = () => {
    const { httpGet } = useHttp();
    const [actions, setActions] = useState([]);
    const [paginate, setPaginate] = useState<IPaginate>({
        page: 1, perPage: 20, lastPage: 1,
    });
    const [filter, setFilter] = useState<string | null>(null);

    const getActions = useCallback(async (filter: string | null, paginate: IPaginate) => {
        const { status, data } = await httpGet('actions', {
            params: {
                filter,
                ...paginate,
            },
        });

        if (status === 200) {
            setActions(data.data);
            setPaginate(data.pagination);
        }
    }, [httpGet]);

    useEffect(() => {
        getActions(null, { page: 1, perPage: 20 } as IPaginate);
    }, [getActions]);

    const handledPagination = useCallback((payload: IPaginate) => {
        setPaginate(payload);
        getActions(filter, payload);
    }, [filter, getActions]);

    const handledFilter = useCallback((filter: string) => {
        setFilter(filter);
        getActions(filter, { page: 1, perPage: 20 } as IPaginate);
    }, [getActions]);

    return (
        <Container>
            <Header
                handledSearch={handledFilter}
                uriAdd="/settings/ceperj/new"
            />
            {actions && actions.map((actions: any) => (
                <Card key={actions.id} UriUpdate={`/settings/ceperj/${actions.id}`}>
                    <Grid container spacing={1}>
                        <Flex textAutoSize title="Nome" xs={6}>
                            {actions.name}
                        </Flex>
                        <Flex textAutoSize title="Tipo" xs={3}>
                            {actions.type}
                        </Flex>
                        <Flex textAutoSize title="Ativo" xs={3}>
                            {actions.active === true ? 'SIM' : 'NÃO'}
                        </Flex>
                    </Grid>
                </Card>
            ))}
            <Pagination handlePaginate={handledPagination} paginate={paginate} />
        </Container>
    );
};

export default Ceperj;
