import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 4px;

  footer {
    padding-top: 5%;
    font-size: 11px;
    color: red;
  }
`;
export const WrapperConduction = styled.div`
  width: 100%;
  margin-top: 8px;
  label > span {
    color: red !important;
  }
`;

export const GridButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 40px 1fr 1fr 1fr;
  grid-template-rows: 56px;
  grid-template-areas:
    'btn-inf space btn-cancel btn-submit';


  @media (max-width: 767.98px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 56px 56px;
    grid-template-areas:
      'btn-cancel btn-submit'
      'btn-inf space';
  }
`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;

export const WrapperBtnCancel = styled.div`
  grid-area: btn-cancel;
`;

export const WrapperBtnSubmit = styled.div`
  grid-area: btn-submit;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Props{
  show?: boolean;
}

export const WrapperHidden = styled.div<Props>`
  width: 100%;
  padding: 0 4px;
  ${(props) => !props.show && css`
    position: absolute;
    right: 9999px;
  `}
`;

export const WrapperModal = styled.div`
  color: #212529;
  .modal__inner{
    background: #FFCD39;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    svg {
      flex-shrink: 0;
      font-size: 1.5em;
    }
  }
  p{
    margin-bottom: 8px;
  }
  b{
    font-size: 1.1rem;
  }
  span{
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
`;

export const Death = styled.div`
  font-weight: bold;
  color: #CC0001;
`;

export const RedStyle = styled.div`
  padding-top: 15px;
  font-weight: bold;
  color: #CC0001;
`;

export const Card = styled.div`
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span{
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  svg{
    font-size: 24px;
    margin-right: 8px;
  }

  h2{
    font-size: 1rem;
    font-weight: 700;
  }
`;

interface IProps {
  go?: any;
  off?: any;
}

export const Red = styled.div<IProps>`
  color: ${(props) => (props.go ? '#D3D3D3' : '#CC0001')};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: .3;
  }
  100%{
    opacity: 1;
  }
`;

export const Div = styled.div<IProps>`
  animation: ${(props) => (props.off ? fadeOut : fadeIn)} 0.5s linear;
`;
