import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { format } from 'date-fns';
import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useAuth } from '../../../hooks/auth';
import { useHttp } from '../../../hooks/http';

/** Componentes */
import Layout from '../../../components/Layout';
import Drawer from '../../../components/Drawer';
import HeaderFilterDrawerSimple from '../../../components/Headers/FilterDrawerSimple';
import Pagination from '../../../components/Pagination';
import Card from '../Card';
import {
  WrapperLabel, DatePicker, Button, ButtonIcon, Input, Select,
} from '../../../components/Forms';

import {
  ArrowBarRightIcon, PoliceCarFill, TrashIcon,
} from '../../../styles/icons';
import { Container, DrawerContent } from './styles';

/** interfaces */
import { IPaginate } from '../../../interfaces';

interface IFilter {
    id: string;
    startDate: Date;
    management_id: number;
    plate: string;
    name: string;
}

const Supervisor: React.FC = () => {
  const { user, iIs } = useAuth();
  const { httpGet, loading } = useHttp();
  const [managements, setManagements] = useState<any>();
  const formRef = useRef<FormHandles>(null);
  const RefLabelToggle = useRef<HTMLLabelElement>(null);
  const [bdtds, setBdtds] = useState([]);
  const [filter, setFilter] = useState<IFilter>({} as IFilter);
  const [paginate, setPaginate] = React.useState<IPaginate>({ page: 1, lastPage: 1, perPage: 10 });

  useEffect(() => {
    async function loadManagements(): Promise<void> {
      const { status, data } = await httpGet('managements/all');
      if (status === 200) {
        const stations = data
          .map((station: any) => ({
            value: station.id,
            label: `${station.initials} - ${station.name}`,
          }));

        setManagements(stations);
      }
    }
    loadManagements();
    }, []);// eslint-disable-line

  const getBdtds = useCallback(async (filter: IFilter, paginate: IPaginate) => {
    if (iIs('transport')) {
      const { status, data } = await httpGet('bdtds/supervisor', {
        params: { ...filter, ...paginate },
      });

      if (status === 200) {
        setBdtds(data.data);
        setPaginate(data.pagination);
      }
    } else {
      const management_id = user?.allocation?.management_id;
      const { status, data } = await httpGet('bdtds/supervisor', {
        params: { ...filter, ...paginate, management_id },
      });

      if (status === 200) {
        setBdtds(data.data);
        setPaginate(data.pagination);
      }
    }
  }, [httpGet, user, iIs]);

  useEffect(() => {
    getBdtds(
            { id: '' } as IFilter,
            { page: 1, lastPage: 1, perPage: 10 },
    );
  }, [getBdtds, httpGet]);

  const handlePaginate = useCallback((payload: IPaginate) => {
    setPaginate(payload);
    getBdtds(filter, payload);
  }, [filter, getBdtds]);

  const handleSubmit = useCallback(async (payload: any) => {
    try {
      const filter = {
        ...payload,
        id: null,
        page: 1,
        perPage: 10,
        startDate: payload.startDate ? format(payload.startDate, 'yyyy-MM-dd') : null,
      };

            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
              startDate: Yup.string().ensure(),
            });

            await schema.validate(filter, { abortEarly: false });
            setFilter(filter);
            const { status, data } = await httpGet('bdtds/supervisor', {
              params: { ...filter, ...paginate },
            });

            if (status === 200) {
              setBdtds(data.data);
              setPaginate(data.pagination);

              if (data.data.length > 0) {
                    RefLabelToggle?.current?.click();
              }
            }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
      }
    }
  }, [httpGet, paginate]);

  let titleOp;
  if (iIs('transport')) {
    titleOp = `${paginate.total} Bdtds - Transporte`;
  } else {
    titleOp = `${paginate.total} Bdtds - Fiscal Base`;
  }

  const breadcrumbs = {
    icon: PoliceCarFill,
    links: [{ path: '', title: titleOp }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderFilterDrawerSimple />

        <Drawer>
          <DrawerContent>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <WrapperLabel label="Data" xs={12}>
                  <DatePicker
                    name="startDate"
                    isClearable
                  />
                </WrapperLabel>
              </Grid>
              <Grid container spacing={1}>
                <WrapperLabel label="Placa" xs={12}>
                  <Input name="plate" />
                </WrapperLabel>
              </Grid>
              {iIs('transport') && (
                <WrapperLabel label="Selecione Gerência" xs={12}>
                  <Select name="managements" options={managements} />
                </WrapperLabel>
              )}
              <Grid container spacing={1} justify="space-between" alignItems="baseline">
                <Grid item xs={10} md={4}>
                  <span style={{ display: 'flex', gap: 8, marginTop: 8 }}>
                    <ButtonIcon title="Fechar">
                      <label ref={RefLabelToggle} htmlFor="pure-toggle-right" data-toggle-label="right">
                        <ArrowBarRightIcon />
                      </label>
                    </ButtonIcon>
                    <ButtonIcon
                      title="Limpar"
                      onClick={() => {
                        formRef.current?.reset();
                      }}
                    >
                      <TrashIcon />
                    </ButtonIcon>
                  </span>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Button type="submit" isLoading={loading}> Filtrar </Button>
                </Grid>
              </Grid>
            </UForm>
          </DrawerContent>
        </Drawer>

        <Grid container spacing={1}>
          {bdtds && bdtds.map((bdtd: any) => (
            <Card
              key={bdtd.id}
              bdtd={bdtd}
              supervisor
            />
          ))}
        </Grid>
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default Supervisor;
