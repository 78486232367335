import React, { useState, useEffect } from 'react';

/** hooks */
import { GasPumpIcon } from 'styles/icons';
import { useHttp } from '../../hooks/http';

/** components */
import Layout from '../../components/Layout';
import HeaderFilterPlus from '../../components/Headers/FilterPlus';
import Pagination from '../../components/Pagination';
import Card from './Card';

import { Container } from './styles';
import { IPaginate, IGas } from '../../interfaces';

const Gases: React.FC = () => {
  const [gases, setGases] = useState<IGas[]>([]);
  const [filter, setFilter] = useState('');

  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const { httpGet, loading } = useHttp();

  useEffect(() => {
    async function loadGas(): Promise<void> {
      const { status, data } = await httpGet('gas', {

        params: {
          filter,
          ...paginate,
        },
      });

      if (status === 200) {
        setGases(data.data);
        setPaginate(data.pagination);
      }
    }

    loadGas();
    }, [filter, paginate.page]); // eslint-disable-line

  const handleSearch = (value: string): void => {
    setPaginate({ ...paginate, page: 1 });
    setFilter(value);
  };

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  const breadcrumbs = {
    icon: GasPumpIcon,
    links: [
      { title: `${paginate.total} Postos`, path: '' },
    ],
  };
  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderFilterPlus handleSearch={handleSearch} />

        {gases && gases.map((gas: any) => (
          <Card key={gas.id} gas={gas} />
        ))}

        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default Gases;
