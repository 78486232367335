import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import { Grid } from '@material-ui/core';
import { HomeGearFillIcon } from 'styles/icons';
import { useHttp } from 'hooks/http';
import { Container } from './styles';
import Pagination from '../../../components/Pagination';
import { IPaginate } from '../../../interfaces';
import Card from './Card';

interface IOption {
  id: string;
  action: string;
  type: string;
  name: string;
  city: string;
  created_at: Date;
}

const CeperjSupervisionList: React.FC = () => {
  const [supervision, setSupervision] = useState<IOption[]>();
  const { httpGet } = useHttp();

  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 5, lastPage: 1, total: 0,
  });

  useEffect(() => {
    async function getSupervisionsForm(): Promise<void> {
      const { status, data } = await httpGet('/ResearcherSupervision/list-user', {
        params: {
          ...paginate,
        },
      });

      if (status === 200) {
        const { data: supervisions } = data;
        const supervisorsParse = supervisions.map((supervisor: any) => (
          {
            id: supervisor.id,
            action: supervisor.action.name,
            type: supervisor.action.type,
            city: supervisor.city,
            researchers_ids: supervisor.researchers_ids,
            number_researchers: supervisor.number_researchers,
            created_at: supervisor.created_at,
          }
        ));

        setPaginate(data.pagination);
        setSupervision(supervisorsParse);
      }
    }

    getSupervisionsForm();
  }, [httpGet, paginate.page]);// eslint-disable-line

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  const breadcrumbs = {
    icon: HomeGearFillIcon,
    links: [{ path: '', title: 'Ceperj Listagem' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Grid container spacing={1}>
          {supervision && supervision.map((supervision: IOption) => (
            <Card
              key={supervision.id}
              supervisors={supervision}
            />
          ))}
        </Grid>
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />
      </Container>
    </Layout>
  );
};

export default CeperjSupervisionList;
