import styled, { css } from 'styled-components';

interface IContainer{
  border: boolean;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  height: 64px;
  padding: 0 16px;
  margin: 4px 0;
  display:flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;

  ${(props) => props.border && css` border: 1px solid #ccc;`}

  flex-shrink:0;
  border-radius: 4px;
  margin-bottom: 8px;


  h1{
    font-size: 24px;
  }

  @media (max-width: 767.98px) {
      form{
        width: 100%;
      }
      h1 {
        font-size: 18px;
      }
    }
`;

export const WrapperInput = styled.div`
  width: 200px;
  height: 32px;
  position: relative;

  display:flex;
  align-items: center;

  background: #fff;


  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 8px;

  input {
    border: 0;
    width: 100%;
    padding: 4px 24px 4px 4px;
  }

  button {
    position: absolute;
    border: 0;
    background: transparent;

    right: 8px;
    display:flex;
    align-items: center;
    transition: color 0.2s;

    svg{
      color: var(--color-gray);

      &:hover{
        color: var(--color-gray-h);
      }
    }

  }

  @media (max-width: 767.98px) {
     width: 100%;
  }

`;

export const ButtonPlus = styled.button`
  border: 0;
  width: 32px;
  height: 32px;
  padding: 4px;
  background: var(--color-button);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;

  box-shadow: var(--shadow);
  svg{
    color: var(--color-white);
  }

  &:hover{
    background-color: var(--color-button-h);
  }

  @media (max-width: 767.98px) {
    position: fixed;
    width: 40px;
    height: 40px;

    z-index: 2;
    bottom: 32px;
    right:16px;
  }

`;
