import React, { useRef, useEffect, useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import getValidationError from '../../../utils/getValidationsErros';
import parseToSelectOptions from '../../../utils/parseToSelectOptions';

/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** componentes */
import Layout from '../../../components/Layout';
import HeaderSimple from '../../../components/Headers/Simple';
import {
  WrapperLabel, Input, Button,
} from '../../../components/Forms';

import { Container, WrapperForm, WrapperInfo } from './styles';

/** interfaces */

interface IUser {
  id?: number;
  name: string;
  email: string;
  document: string;
  phone: string;
  roles: { value: string, label: string }[];
}

const Password: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const {
    httpGet, httpPut, loading,
  } = useHttp();

  useEffect(() => {
    formRef.current?.reset();

    async function loadUser(): Promise<void> {
      const { data } = await httpGet('/me');

      formRef.current?.setData({
        ...data,
        roles: parseToSelectOptions(data.roles),
      });
    }

    loadUser();

  }, []);// eslint-disable-line

  const handleSubmit = useCallback(async (data: IUser) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        password: Yup.string().required('A Senha é obrigatória'),
        new_password: Yup
          .string()
          .required('A Senha é obrigatória')
          .matches(
            /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Precisa: no mínimo 6 caracteres; conter letras maiúsculas, minúsculas, números e caracteres especiais.',
          ),
        new_password_confirmation: Yup
          .string()
          .required()
          .oneOf([Yup.ref('new_password'), ''], 'As senhas não conferem'),
      });

      await schema.validate(data, { abortEarly: false });

      const { status } = await httpPut('profile/password', data);
      if (status === 200) {
        formRef.current?.reset();
        addToast({ title: 'Senha alterada com sucesso.' });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, []);// eslint-disable-line

  const breadcrumbs = {
    links: [
      { path: '', title: 'Alterar senha' },
    ],
  };
  return (

    <Layout breadcrumbs={breadcrumbs} loading={loading}>

      <Container>
        <HeaderSimple> Alterar senha </HeaderSimple>

        <WrapperForm>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>

              <Grid container item md={6} spacing={2} justify="flex-end">

                <WrapperLabel label="Senha" xs={12}>
                  <Input name="password" type="password" />
                </WrapperLabel>

                <WrapperLabel label="Nova Senha" xs={12}>
                  <Input name="new_password" type="password" />
                </WrapperLabel>

                <WrapperLabel label="Confirme a Nova Senha" xs={12}>
                  <Input name="new_password_confirmation" type="password" />
                </WrapperLabel>

                <Grid item xs={12} md={6}>
                  <Button type="submit">Salvar</Button>
                </Grid>

              </Grid>
              <Grid container item md={6} spacing={2}>
                <WrapperInfo>
                  Para uma senha segura é preciso combinar:
                  letras maiúsculas e minúsculas, números, caracteres especiais e
                  deve ter no mínimo 8 caracteres.
                </WrapperInfo>
              </Grid>

            </Grid>
          </UForm>

        </WrapperForm>
      </Container>
    </Layout>
  );
};

export default Password;
