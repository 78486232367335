import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import AsyncSelect from 'react-select/async';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import ModalFactory from 'react-modal-promise';
import { ModalDialog } from '../../../components/ModalDialog';
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';
import customStyle from './customStyle';
import Layout from '../../../components/Layout';
import Flex, { Grid } from '../../../components/Flex';
import {
  WrapperLabel, Select, TextArea, Button,
} from '../../../components/Forms';
import { SupportIcon } from '../../../styles/icons';
import {
  Container, Title, Content,
} from './styles';
import { ISupport } from '../../../interfaces';

interface IOptions {
  value: string;
  label: string
}
const Process: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    httpGet, httpPost, loading,
  } = useHttp();
  const { addToast } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [isHidden, setIsHidden] = useState(true);
  const [support, setSupport] = useState({} as ISupport);
  const [technician, setTechnician] = useState<IOptions>();

  useEffect(() => {
    async function load(): Promise<void> {
      const { status, data } = await httpGet(`/supports/${id}`);

      if (status !== 200) {
        history.push(`/supports/answered/detail/${id}`);
        return;
      }
      setSupport(data);
    }

    load();
  }, [history, httpGet, id]);

  const handleSubmit = useCallback(async (data: any) => {
    const answer = {
      note: data.note,
      result_id: data.result_id?.value,
      technician_id: technician?.value || null,
      id: support.id,
    };

    if (!answer.result_id) {
      const response = await ModalDialog({
        title: 'Atenção!',
        content: 'Seu registro não possui uma ação. Deseja prosseguir?',
        buttons: 'cancel_confirm',
      });

      if (response) {
        const result: any = await httpPost('/supports/process', answer);
        const { status } = result;

        if (status === 200) {
          addToast({ title: 'Atendimento encaminhado com sucesso.' });
          history.push('/support/awaiting');
        }
      }
    } else {
      const result: any = await httpPost('/supports/process', answer);
      const { status } = result;

      if (status === 200) {
        addToast({ title: 'Atendimento encaminhado com sucesso.' });
        history.push('/support/awaiting');
      }
    }
  }, [addToast, history, httpPost, support.id, technician]);

  const breadcrumbs = {
    icon: SupportIcon,
    links: [{ path: '', title: 'Processar atendimento' }],
  };

  const handleShowAllFieldForms = useCallback((data: any) => {
    const { value } = data;
    if (value === 1 || value === 9) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  }, []);

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 3) return;
    const users: any = [];
    const { status, data } = await httpGet(`/supports/technicians/${inputValue}`);

    if (status === 200) {
      data.forEach((item: any) => {
        users.push({
          value: item.id,
          label: item.name,
        });
      });
    }

    callback(users);
  };

  const handleChangeAgent = useCallback((event: any) => {
    setTechnician(event);
  }, []);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <Title className="first">
            Dados informados para atendimento
          </Title>
          <Grid container spacing={1}>
            <Flex title="Gerência" xs={12} sm={4} textAutoSize>
              {support?.managements?.initials}
              {support?.managements?.initials && ` - ${support?.managements?.name}`}
            </Flex>
            <Flex title="Solicitado por" xs={12} sm={4} textAutoSize>
              {`${support.user?.first_name}`}
            </Flex>
            <Flex title="Contato" xs={12} sm={4} textAutoSize>
              {`${support.user?.phone}`}
            </Flex>
            <Flex title="Problema" xs={12} sm={4} md={4} textAutoSize>
              {support.problem}
            </Flex>
            <Flex title="Descrição" xs={12} sm={12} md={4}>
              {support.description || 'não informado'}
            </Flex>
          </Grid>
          <Title>
            Procedimentos
          </Title>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <WrapperLabel label="Ação" xs={12} sm={12} md={12}>
                <Select
                  name="result_id"
                  onChange={handleShowAllFieldForms}
                  options={[
                    { value: 1, label: 'Enviar técnico' },
                    { value: 2, label: 'Encerrar' },
                  ]}
                />
              </WrapperLabel>
              <Grid item xs={12}>
                <span className={isHidden ? 'hidden' : ''}>
                  <WrapperLabel label="Selecione o Técnico" xs={12}>
                    <div style={{ width: '100%' }}>
                      <AsyncSelect
                        name="technician_id"
                        placeholder="Nome"
                        noOptionsMessage={() => 'Sem registos'}
                        cacheOptions
                        isClearable
                        loadOptions={loadOptions}
                        onChange={handleChangeAgent}
                        value={technician}
                        styles={customStyle}
                      />
                    </div>
                  </WrapperLabel>
                </span>
              </Grid>
              <WrapperLabel label="Observação" xs={12}>
                <TextArea name="note" />
              </WrapperLabel>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit">Salvar</Button>
            </Grid>
          </UForm>
        </Content>
      </Container>
      <ModalFactory />
    </Layout>
  );
};

export default Process;
