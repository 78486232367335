import styled from 'styled-components';
import signInBackgroundImg from '../../../assets/capacitacao.png';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #000000;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.h2`
  padding: 12px;
  font-weight: bold;
  color: white;
`;

export const Box = styled.h3`
    width: 100%;
`;

export const ContentWapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 2px;

   h3 {
    font-weight: bold;
    color: white;
   }
`;

export const Background = styled.div`
  width: 100%;
  height: 420px;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;
`;
