import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;

  form{
    width: 100%;
  }
`;

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 599.98px) {
    span: first-child{
      display: none;
    }
    justify-content: flex-end;
  }
`;

export const WrapperModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 16px 0;
  font-size: 1.1em;

  p{
    padding: 8px 0;
  }
`;
