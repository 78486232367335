import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { GridColDef, ValueGetterParams } from '@material-ui/data-grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { formatISO, format, isBefore } from 'date-fns';
import { Grid } from 'components/Flex';
import getValidationError from '../../utils/getValidationsErros';
import { formatDateTime } from '../../utils';
/** hooks */
import { useHttp } from '../../hooks/http';

/** componentes */
import Layout from '../../components/Layout';
import CustomTable from '../../components/CustomTable';
import Modal from '../../components/ModalSimple';
import ToStarted from './ToStarted';
import ToEnded from './ToEnded';
import Missing from './Missing';
import Detail from './Detail';
import ChangeSector from './ChangeSector';
import { handleStatus } from './Status';
import Menu from './Menu';
import Actions from './Actions';
import {
  Select, DatePicker, ButtonIcon, WrapperLabel,
} from '../../components/Forms';
import { CalendarIcon, FilterIcon } from '../../styles/icons';
import {
  Container, Content, Header, WrapperModal,
} from './styles';

/** interface */
import { IService } from '../../interfaces';

const Services: React.FC = () => {
  const RefLabelPresence = useRef<HTMLLabelElement>(null);
  const RefLabelMissing = useRef<HTMLLabelElement>(null);
  const RefLabelChangeSector = useRef<HTMLLabelElement>(null);
  const RefLabelDetail = useRef<HTMLLabelElement>(null);
  const formRef = useRef<FormHandles>(null);
  const { httpGet, loading } = useHttp();
  const [managements, setManagements] = useState([]);
  const [services, setServices] = useState([]);
  const [service, setService] = useState<IService | null>(null);
  const [sectors, setSectors] = useState<{value:number; label:string; }[] | null>(null);

  const breadcrumbs = {
    icon: CalendarIcon, links: [{ title: 'Serviços', path: '' }],
  };

  useEffect(() => {
    (async () => {
      const { status, data } = await httpGet('/sectors/managements');
      if (status === 200) {
        const managementParsed = data
          .map((management: any) => ({
            value: management.id,
            label: management.initials,
          }));
        setManagements(managementParsed);
      }
    })();

    formRef.current?.setFieldValue('date', formatISO(Date.now()));
  }, [httpGet]);

  const handleGetSectors = useCallback(async (management_id: string | number) => {
    const { status, data } = await httpGet('/sectors', {
      params: {
        management_id,
        status: true,
      },
    });
    if (status === 200) {
      const sectorsParsed = data.map((item:any) => ({ value: item.id, label: item.initials }));
      setSectors(sectorsParsed);
    }
  }, [httpGet]);

  const handleUsers = useCallback((service: any) => {
    const servicesUpdated: any = [...services];
    const serviceIndex = services.findIndex((item: any) => item.id === service.id);

    if (serviceIndex !== -1) {
      servicesUpdated[serviceIndex] = service;
      setServices(servicesUpdated);
    }

    const modalMissing: any = document.querySelector('input[id="modalMissing"]');
    const modalPresence: any = document.querySelector('input[id="modalPresence"]');
    const modalChangeSector: any = document.querySelector('input[id="modalChangeSector"]');

    if (modalMissing?.checked) {
      RefLabelMissing.current?.click();
    }
    if (modalPresence?.checked) {
      RefLabelPresence.current?.click();
    }
    if (modalChangeSector?.checked) {
      formRef.current?.submitForm();
      RefLabelChangeSector.current?.click();
    }
  }, [services]);

  const handleSubmit = useCallback(async (payload: any) => {
    const filter = {
      ...payload,
      date: format(payload.date, 'yyyy-MM-dd'),
      management_id: payload?.management_id?.value || null,
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        management_id: Yup.string().ensure().required('Gerência é obrigatória'),
        date: Yup.date().typeError('Data é obrigatória'),
      });

      await schema.validate(filter, { abortEarly: false });
      const { status, data } = await httpGet('/services', {
        params: { ...filter },
      });

      handleGetSectors(filter.management_id);

      if (status === 200) {
        setServices(data);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [handleGetSectors, httpGet]);

  const handlePresence = useCallback(async (user_id: string | number) => {
    RefLabelPresence.current?.click();
    setService(null);
    const { status, data } = await httpGet(`/services/${user_id}`);
    if (status === 200) {
      setService(data);
    }
  }, [httpGet]);

  const handleMissing = useCallback(async (user_id: string | number) => {
    RefLabelMissing.current?.click();
    setService(null);
    const { status, data } = await httpGet(`/services/${user_id}`);
    if (status === 200) {
      setService(data);
    }
  }, [httpGet]);

  const handleChangeSector = useCallback(async (user_id: string | number) => {
    RefLabelChangeSector.current?.click();
    setService(null);
    const { status, data } = await httpGet(`/services/${user_id}`);
    if (status === 200) {
      setService(data);
    }
  }, [httpGet]);

  const handleDetail = useCallback(async (service_id: string | number) => {
    setService(null);
    const { status, data } = await httpGet(`/services/detail/${service_id}`);
    if (status === 200) {
      setService(data);
      RefLabelDetail.current?.click();
    }
  }, [httpGet]);

  const handleRemoveService = useCallback((id: any) => {
    const serviceFiltered = services.filter((service: any) => service.id !== id);
    setServices([...serviceFiltered]);
  }, [services]);

  const handleDateBefore = useCallback((params: ValueGetterParams | any) => {
    if (params?.row?.presence_ended_at && params?.row?.ended_at) {
      if (isBefore(new Date(params?.row?.presence_ended_at), new Date(params.row?.ended_at))) {
        return <span>{formatDateTime(params?.row?.presence_ended_at)}</span>;
      }
      return formatDateTime(params?.row?.presence_ended_at);
    }
    return '';
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'action',
      headerName: ' ',
      disableColumnMenu: true,
      width: 50,
      align: 'center',
      renderCell: (params: ValueGetterParams) => (
        <Actions handle={handlePresence} params={params} />
      ),
    },
    { field: 'sector', headerName: 'Setor', width: 160 },
    { field: 'user', headerName: 'Agente', width: 300 },
    {
      field: 'started_at',
      headerName: 'Início',
      width: 140,
      valueGetter: (params: ValueGetterParams) => (
        formatDateTime(params.row?.started_at)
      ),
    },
    {
      field: 'ended_at',
      headerName: 'Término',
      width: 140,
      valueGetter: (params: ValueGetterParams) => (
        formatDateTime(params.row?.ended_at)
      ),
    },
    {
      field: 'presence_ended_at',
      headerName: 'Encerado em',
      width: 140,
      valueGetter: (params: ValueGetterParams) => (
        params.row?.presence_ended_at
          ? formatDateTime(params.row?.presence_ended_at)
          : ''
      ),
      renderCell: (params: ValueGetterParams) => (
        <> {handleDateBefore(params)} </>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      valueGetter: (params: ValueGetterParams) => (
        handleStatus(params)
      ),
    },
    {
      field: 'menu',
      headerName: ' ',
      sortable: false,
      width: 50,
      renderCell: (params: ValueGetterParams) => (
        <Menu
          params={params}
          handleMissing={handleMissing}
          handleChangeSector={handleChangeSector}
          handleDetail={handleDetail}
          handleRemoveService={handleRemoveService}
        />
      ),
    },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content>
          <Header>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1} alignItems="flex-end">

                <WrapperLabel label="Gerência" xs={12} sm={8} md={9}>
                  <Select
                    name="management_id"
                    options={managements}
                  />
                </WrapperLabel>

                <WrapperLabel label="Data" xs={12} sm={4} md={3}>
                  <div style={{ width: 'calc(100% - 48px)' }}>
                    <DatePicker name="date" />
                  </div>
                  <ButtonIcon type="submit" title="Filtrar" isLoading={loading}>
                    <FilterIcon />
                  </ButtonIcon>
                </WrapperLabel>

              </Grid>

            </UForm>
          </Header>
          <CustomTable
            columns={columns}
            rows={services}
            toolBar
          />
        </Content>
        <label ref={RefLabelPresence} htmlFor="modalPresence" style={{ opacity: 0 }}>abrir modal</label>
        <Modal id="modalPresence" height={400}>
          { service && !service?.presence_started_id && (
            <ToStarted service={service} sectors={sectors} handleStatus={handleUsers} />
          )}
          {!service && <WrapperModal> <BarLoader height={6} width={200} color="#404040" /> </WrapperModal> }
          { service && service?.presence_started_id && (
            <ToEnded service={service} handleStatus={handleUsers} />
          )}
        </Modal>

        <label ref={RefLabelMissing} htmlFor="modalMissing" style={{ opacity: 0 }}>abrir modal</label>
        <Modal id="modalMissing" height={300}>
          { service && service?.presence_started_id && (
            <WrapperModal>
              <h2>Você não pode Comunicar a falta de um serviço que teve a presença confirmada.</h2>
            </WrapperModal>
          )}
          {!service && <WrapperModal> <BarLoader height={6} width={200} color="#404040" /> </WrapperModal> }
          { service
            && !service?.missing_id
            && !service?.presence_started_id
            && <Missing service={service} handleStatus={handleUsers} />}
        </Modal>

        <label ref={RefLabelChangeSector} htmlFor="modalChangeSector" style={{ opacity: 0 }}>abrir modal</label>
        <Modal id="modalChangeSector" height={400}>
          { service && (!service?.presence_started_id || service?.presence_ended_id) && (
            <WrapperModal>
              <h2>
                Você não pode trocar o setor de um serviço que não teve a presença confirmada
                ou foi encerrado.
              </h2>
            </WrapperModal>
          )}
          {!service && <WrapperModal> <BarLoader height={6} width={200} color="#404040" /> </WrapperModal> }
          { service
            && !service?.missing_id
            && !service?.presence_ended_id
            && service?.presence_started_id
            && <ChangeSector service={service} sectors={sectors} handleStatus={handleUsers} />}
        </Modal>

        <label ref={RefLabelDetail} htmlFor="modalDetail" style={{ opacity: 0 }}>abrir modal</label>
        <Modal id="modalDetail" height={400}>
          {!service && <WrapperModal> <BarLoader height={6} width={200} color="#404040" /> </WrapperModal> }
          { service && <Detail service={service} /> }
        </Modal>
      </Container>
    </Layout>
  );
};

export default Services;
