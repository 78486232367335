const caliber = [
  { value: 'Sem Calibre', label: 'Sem Calibre' },
  { value: '.22', label: '.22' },
  { value: '.375', label: '.375' },
  { value: '.308 Win', label: '.308 Win' },
  { value: '.357 Mag', label: '.357 Mag' },
  { value: '.380', label: '.380' },
  { value: '.36GA', label: '.36GA' },
  { value: '.38SPL', label: '.38SPL' },
  { value: '.40 S&W', label: '.40 S&W' },
  { value: '.458 Win Mag', label: '.458 Win Mag' },
  { value: '.44Mag', label: '.44Mag' },
  { value: '.45ACP', label: '.45ACP' },
  { value: '.50', label: '.50' },
  { value: '5.56mm', label: '5.56mm' },
  { value: '6mm', label: '6mm' },
  { value: '6,5mm', label: '6,5mm' },
  { value: '7.62x51mm', label: '7.62x51mm' },
  { value: '9mm', label: '9mm' },
  { value: '10mm', label: '10mm' },
  { value: 'CAL 12.', label: 'CAL 12.' },
  { value: '12GA', label: '12GA' },
  { value: '17HMR', label: '17HMR' },
  { value: '20GA', label: '20GA' },
  { value: '28GA', label: '28GA' },
  { value: '223 Rem', label: '223 Rem' },
  { value: '243 Win', label: '243 Win' },
];

export default caliber;
