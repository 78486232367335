import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import { Grid } from '@material-ui/core';

/** hooks */
import { useToast } from '../../../hooks/toast';

/** services */
import getCurrentPosition from '../../../utils/getCurrentPosition';

/** componentes */
import LeafletMap from './LeafletMap';
// import GoogleMap from './GoogleMap';

import { Button } from '../../../components/Forms';

import {
  Container, Content, WrapperButtons,
} from './styles';

interface ICoords {
  lat: number;
  lng: number;
}

interface IForm {
  handleCoord(coord: ICoords): void;
}

const Address: React.FC<IForm> = ({ handleCoord }) => {
  const history = useHistory();
  const { addToast } = useToast();
  const [currentCoords, setCurrentCoords] = useState<ICoords>({}as ICoords);
  const [coord, setCoord] = useState({} as ICoords);
  const [isShowMap, setIsShowMap] = useState(false);

  useEffect(() => {
    async function loadCurrentPosition(): Promise<void> {
      const coords = await getCurrentPosition();
      if (coords) {
        setCurrentCoords(coords);
        setIsShowMap(true);
      }
    }
    loadCurrentPosition().catch(() => {
      addToast({
        title: 'Para pedir uma consulta VOCÊ PRECISA COMPARTILHAR SUA LOCALIZAÇÃO, que foi descrita nos termos de uso.',
        type: 'error',
        time: 0,
      });
    });
  }, [addToast]);

  return (
    <Container>

      <Content>
        {!isShowMap && (
        <div>
          <BarLoader
            height={6}
            width={200}
            color="#404040"
          />
          <p>carregando mapa...</p>
        </div>
        )}
        {isShowMap && (
          // <GoogleMap handleCoord={setCoord} currentCoords={currentCoords} />
          <LeafletMap handleCoord={setCoord} currentCoords={currentCoords} />
        )}
      </Content>
      <WrapperButtons>
        <Grid container spacing={2} justify="flex-end">
          <Grid item xs={6} sm={6} md={4}>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Button
              type="button"
              onClick={() => { handleCoord(coord); }}
              isLoading={!isShowMap}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </WrapperButtons>
    </Container>
  );
};

export default Address;
