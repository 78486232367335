import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;

  button{
    border: none;
    background: transparent;
  }

  span{
    position: relative;
    > svg {
      margin-right: 4px;
    }
    p{
      display: flex;
      position: absolute;
      left: 14px;
      top: 0;
      padding: 0 4px;
      font-size: 0.875em;
      background: rgba( 255,255,255, 0.9);
      > svg {
      margin-left: 2px;
    }
    }
  }
`;
