import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import AsyncSelect from 'react-select/async';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
/** hooks */
import * as Yup from 'yup';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import getCurrentPosition from 'utils/getCurrentPosition';
import { options_quetions } from './pacto';
import getValidationError from '../../../utils/getValidationsErros';
import {
  Container,
  GridButtons,
  WrapperBtnInfo,
  WrapperBtnCancel,
  WrapperBtnSubmit,
} from './styles';
import { InfoIcon } from '../../../styles/icons';
import {
  WrapperLabel, Select, Button, ButtonIcon, Input,
} from '../../../components/Forms';
import { useHttp } from '../../../hooks/http';
import customStyle from './customStyle';

interface IFormData {
  coord?: {
    lat: number,
    lng: number
  }
}

interface IOptions {
  value: string;
  label: string
}

const CeperjFormSupervision: React.FC<IFormData> = ({ coord }) => {
  const formRef = useRef<FormHandles>(null);
  const [actions, setActions] = useState<IOptions | null>(null);
  const [allocationUsers, setAllocationUsers] = useState<IOptions[]>();

  const history = useHistory();
  const [municipio, setMunicipio] = useState([]);
  const [disabledfulfilledTasks, setDisabledfulfilledTasks] = useState(false);

  const { loading, httpGet, httpPost } = useHttp();
  const { user } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    async function loadDitricts(): Promise<any> {
      const { status, data } = await httpGet('https://servicodados.ibge.gov.br/api/v1/localidades/estados/33/municipios');
      if (status === 200) {
        const municipioParsed = data
          .map((municipio: any) => ({
            value: municipio.nome,
            label: municipio.nome,
          }));
        setMunicipio(municipioParsed);
      }
    }

    loadDitricts();
  }, [httpGet]);

  useEffect(() => {
    formRef.current?.setData({});
  }, []);

  const handleSubmit = useCallback(async (data: any) => {
    const _ceperj = {
      ...data,
      ...coord,
      researchers_ids: allocationUsers ? allocationUsers?.map((user) => user.label).join(',') : null,
      action_id: actions?.value,
      city: data.city?.value,
      fulfilled_tasks: data.fulfilled_tasks?.value,
      fulfilled_tasks_ids: data.fulfilled_tasks_ids ? data.fulfilled_tasks_ids.map((fulfilledTtasksIds: any) => fulfilledTtasksIds.label).join(',') : null,
      performance: data.performance?.value,
      team_work: data.team_work?.value,
      interpersonal_relationship: data.interpersonal_relationship?.value,
      record_work_report: data.record_work_report?.value,
      responsibility_regularity: data.responsibility_regularity?.value,
      critical_perception_analysis: data.critical_perception_analysis?.value,
      organization_planning: data.organization_planning?.value,
      proactivity: data.proactivity?.value,
      motivation: data.motivation?.value,
      initiative: data.initiative?.value,
      communication_capacity: data.communication_capacity?.value,
      cooperation: data.cooperation?.value,
      positively_highlighted: data.positively_highlighted ? data.positively_highlighted.map((positivelyHighlighted: any) => positivelyHighlighted.label).join(',') : null,
      highlighted_negatively: data.highlighted_negatively ? data.highlighted_negatively.map((highlightedNegatively: any) => highlightedNegatively.label).join(',') : null,
      management_id: user?.allocation?.management_id,

    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        researchers_ids: Yup.string().ensure().required('Relação de pesquisadores... é obrigatório'),
        city: Yup.string().required('Região da pesquisa é obrigatória'),
        action_id: Yup.string().ensure().required('O projeto da pesquisa é obrigatório'),
        number_researchers: Yup.number().typeError('Quantidade de pesquisadores é obrigatório'),
        performance: Yup.string().ensure().required('Desempenho da atividade é obrigatório'),
        team_work: Yup.string().ensure().required('Trabalho em equipe é obrigatório'),
        interpersonal_relationship: Yup.string().ensure().required('Relacionamento interpessoal é obrigatório'),
        record_work_report: Yup.string().ensure().required('Registro do relato do trabalho é obrigatório'),
        responsibility_regularity: Yup.string().ensure().required('Responsabilidade e regularidade é obrigatório'),
        critical_perception_analysis: Yup.string().ensure().required('Percepção e análise crítica é obrigatório'),
        organization_planning: Yup.string().ensure().required('Organização e planejamento é obrigatório'),
        proactivity: Yup.string().ensure().required('Proatividade é obrigatório'),
        motivation: Yup.string().ensure().required('Motivação é obrigatório'),
        initiative: Yup.string().ensure().required('Iniciativa é obrigatório'),
        communication_capacity: Yup.string().ensure().required('Capacidade de Comunição é obrigatório'),
        cooperation: Yup.string().ensure().required('Cooperação é obrigatório'),
        fulfilled_tasks: Yup.string().required('O campo cumpriram as tarefas é obrigatória'),
        fulfilled_tasks_ids: Yup.string().nullable().when('fulfilled_tasks', {
          is: (fulfilled_tasks) => fulfilled_tasks === 'Nao', then: Yup.string().ensure().required('Nome do pesquisador é obrigatório'),
        }),
      });

      await schema.validate(_ceperj, { abortEarly: false });
      const position = await getCurrentPosition();

      const { status } = await httpPost('ResearcherSupervision', {
        ..._ceperj,
        current_lat: position.lat,
        current_lng: position.lng,
      });

      if (status === 200) {
        addToast({ title: 'Salvo com sucesso!', type: 'success' });
        history.push('/dashboard');
      } else {
        addToast({ title: 'Não foi possível gravar!', type: 'error' });
        history.push('/dashboard');
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [coord, user, addToast, history, httpPost, actions, allocationUsers]);

  const handleFulfilledTasks = useCallback((fulfilled_tasks_ids: any) => {
    setDisabledfulfilledTasks(fulfilled_tasks_ids?.value === 'Sim');
  }, []);

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 3) return;
    const users: any = [];
    const { status, data } = await httpGet(`/ResearcherSupervision/users/${inputValue}`);

    if (status === 200) {
      data.forEach((item: any) => {
        users.push({
          value: item.id,
          label: item.name,
        });
      });
    }

    callback(users);
  };

  const handleChangeAgent = useCallback((event: any) => {
    setAllocationUsers(event);
  }, []);

  const loadOptionsAction = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 3) return;
    const actions: any = [];
    const { status, data } = await httpGet(`/ResearcherSupervision/actions/${inputValue}`);

    if (status === 200) {
      data.forEach((item: any) => {
        actions.push({
          value: item.id,
          label: item.name,
        });
      });
    }

    callback(actions);
  };

  const handleChangeAction = useCallback((event: any) => {
    setActions(event);
  }, []);

  return (
    <>
      <Container>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <WrapperLabel label="Pesquisadores da equipe(nomes)" xs={12}>
              <div style={{ width: '100%' }}>
                <AsyncSelect
                  placeholder="Nome"
                  noOptionsMessage={() => 'Sem registos'}
                  cacheOptions
                  isClearable
                  loadOptions={loadOptions}
                  onChange={handleChangeAgent}
                  value={allocationUsers}
                  styles={customStyle}
                  isMulti
                />
              </div>
            </WrapperLabel>

            <WrapperLabel label="Ações de Pesquisa" xs={12}>
              <div style={{ width: '100%' }}>
                <AsyncSelect
                  placeholder="Ação"
                  noOptionsMessage={() => 'Sem registos'}
                  cacheOptions
                  isClearable
                  loadOptions={loadOptionsAction}
                  onChange={handleChangeAction}
                  value={actions}
                  styles={customStyle}
                />
              </div>
            </WrapperLabel>

            <WrapperLabel label="Região da pesquisa" xs={12} md={6} sm={6}>
              <Select
                name="city"
                options={municipio}
              />
            </WrapperLabel>

            <WrapperLabel label="Quantidade pesquisadores" xs={12} sm={6} md={6}>
              <Input
                name="number_researchers"
                type="text"
                maxLength={2}
                placeholder="Somente números"
              />
            </WrapperLabel>

            <WrapperLabel label="O(s) Pesquisador(es) compriram suas tarefas ?" xs={12} md={6} sm={6}>
              <Select
                name="fulfilled_tasks"
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Nao', label: 'Nao' },
                ]}
                onChange={handleFulfilledTasks}
              />
            </WrapperLabel>
            <WrapperLabel label="Caso NÃO. Qual(is) pesquisador(es) deixou(aram) de comprir as tarefas ?" xs={12} sm={6}>
              <Select
                name="fulfilled_tasks_ids"
                options={allocationUsers}
                isDisabled={disabledfulfilledTasks}
                isMulti
              />
            </WrapperLabel>

            <WrapperLabel label="Desempenho das atividades" xs={12} sm={6}>
              <Select
                name="performance"
                options={options_quetions}
              />
            </WrapperLabel>

            <WrapperLabel label="Trabalho em equipe" xs={12} sm={6}>
              <Select
                name="team_work"
                options={options_quetions}

              />
            </WrapperLabel>
            <WrapperLabel label="Relacionamento interpessoal" xs={12} sm={6}>
              <Select
                name="interpersonal_relationship"
                options={options_quetions}
              />
            </WrapperLabel>
            <WrapperLabel label="Registro do relato do trabalho" xs={12} sm={6}>
              <Select
                name="record_work_report"
                options={options_quetions}
              />
            </WrapperLabel>
            <WrapperLabel label="Responsabilidade e regularidade" xs={12} sm={6}>
              <Select
                name="responsibility_regularity"
                options={options_quetions}
              />
            </WrapperLabel>
            <WrapperLabel label="Percepção e análise crítica" xs={12} sm={6}>
              <Select
                name="critical_perception_analysis"
                options={options_quetions}
              />
            </WrapperLabel>
            <WrapperLabel label="Organização e planejamento" xs={12} sm={6}>
              <Select
                name="organization_planning"
                options={options_quetions}
              />
            </WrapperLabel>
            <WrapperLabel label="Proatividade" xs={12} sm={6}>
              <Select
                name="proactivity"
                options={options_quetions}
              />
            </WrapperLabel>
            <WrapperLabel label="Motivação" xs={12} sm={6}>
              <Select
                name="motivation"
                options={options_quetions}
              />
            </WrapperLabel>
            <WrapperLabel label="Iniciativa" xs={12} sm={6}>
              <Select
                name="initiative"
                options={options_quetions}
              />
            </WrapperLabel>
            <WrapperLabel label="Capacidade de comunição" xs={12} sm={6}>
              <Select
                name="communication_capacity"
                options={options_quetions}
              />
            </WrapperLabel>
            <WrapperLabel label="Cooperação" xs={12} sm={6}>
              <Select
                name="cooperation"
                options={options_quetions}
              />

            </WrapperLabel>
            <Grid container spacing={1}>
              <WrapperLabel label="" xs={12} sm={12}>
                <hr />

                **Algum integrante se destacou:

              </WrapperLabel>

              <WrapperLabel label="Positivamente" xs={12} md={6} sm={6}>
                <Select
                  name="positively_highlighted"
                  options={allocationUsers}
                  isMulti
                />
              </WrapperLabel>

              <WrapperLabel label="Negativamente" xs={12} md={6} sm={6}>
                <Select
                  name="highlighted_negatively"
                  options={allocationUsers}
                  isMulti
                />
              </WrapperLabel>

            </Grid>
            <WrapperLabel label="Observações" xs={12} sm={12} md={12}>
              <Input name="observation" />
            </WrapperLabel>

          </Grid>
          <GridButtons>
            <WrapperBtnInfo>
              <ButtonIcon
                title="Instruções"
              >
                <InfoIcon />
              </ButtonIcon>
            </WrapperBtnInfo>
            <WrapperBtnCancel>
              <Button
                type="button"
                onClick={() => { history.push('/dashboard'); }}
              >
                Cancelar
              </Button>
            </WrapperBtnCancel>
            <WrapperBtnSubmit>
              <Button
                type="submit"
                isLoading={loading}
              >
                Salvar
              </Button>
            </WrapperBtnSubmit>
          </GridButtons>
        </UForm>
      </Container>

    </>
  );
};

export default CeperjFormSupervision;
