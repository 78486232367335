import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Content = styled.div`
  padding: 4px 1px;
`;

export const WrapperAddress = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 4px;

  h3{
    color: var(--color-primary);
    margin-bottom: 8px;
  }

  @media (max-width: 767.98px) {
    span {
      display: none;
    };
  }
`;
