const typeInvolvement = [
  { value: 'ACUSADO', label: 'ACUSADO' },
  { value: 'APREENDIDO', label: 'APREENDIDO' },
  { value: 'AUTOR', label: 'AUTOR' },
  { value: 'DESAPARECIDO', label: 'DESAPARECIDO' },
  { value: 'SOLICITANTE', label: 'SOLICITANTE' },
  { value: 'SUSPEITO', label: 'SUSPEITO' },
  { value: 'TESTEMUNHA', label: 'TESTEMUNHA' },
  { value: 'VÍTIMA', label: 'VÍTIMA' },
  { value: 'OUTROS', label: 'OUTROS' },
];

export default typeInvolvement;
