import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import Grid from '@material-ui/core/Grid';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import * as Yup from 'yup';
import typeInvolvement from 'utils/typeInvolvement';
import handcuffs from 'utils/handcuffs';
import Simple from 'components/Headers/Simple';
import { BsPencil } from 'react-icons/bs';
import IInvolved from 'interfaces/involved';
import { parseDate } from 'utils';
import stringOptions from 'utils/stringOptions';
import Layout from '../../../../components/Layout';
import {
  Container, GridButtons, WrapperBtnCancel, WrapperBtnSubmit, WrapperAddress, Title,
} from './styles';
import { UserFriends } from '../../../../styles/icons';
import {
  WrapperLabel, Select, Button, Input, InputMask, InputHidden,
} from '../../../../components/Forms';
import { useHttp } from '../../../../hooks/http';
import getValidationError from '../../../../utils/getValidationsErros';

const UpdateInvolved: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [involved, setInvolved] = useState<IInvolved>();
  const { httpGet, httpPut } = useHttp();
  const history = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    async function load(): Promise<any> {
      const { status, data } = await httpGet(`/involveds/${id}`);
      if (status === 200) {
        setInvolved(data);
      }
    }
    load();
  }, [httpGet, id, user]);

  useEffect(() => {
    formRef.current?.setData({

      involved_id: involved?.id,
      bopm_id: involved?.bopm_id,
      people_occurrence_id: involved?.people_occurrence_id,
      type_involvement: stringOptions(`${involved?.type_involvement}`),
      handcuffs: { value: involved?.handcuffs, label: involved?.handcuffs },
      qualified: involved?.qualified,
      name: involved?.name,
      birthday: involved?.birthday,
      document_secondary: involved?.document_secondary,
      document: involved?.document,
      mather: involved?.mather,
      phone: involved?.phone,
      situation: { value: involved?.situation, label: involved?.situation },

    });
  }, [involved]);

  const handleSubmit = useCallback(async (data: any) => {
    const involvedd = {
      ...data,
      id: data.involved_id,
      bopm_id: data.bopm_id,
      type_involvement: data.type_involvement ? data.type_involvement?.map((type:any) => type.value).join(',') : null,
      handcuffs: data.handcuffs?.value,
      qualified: data.qualified,
      name: data.name,
      birthday: parseDate(data.birthday),
      document_secondary: data.document_secondary,
      document: data.document,
      phone: data.phone,
      mather: data.mather,
      situation: data.situation?.value,
    };

    try {
    formRef.current?.setErrors({});
    const schema = Yup.object().shape({
      type_involvement: Yup.string().required('Tipo de envolvimento é obrigatório'),
      handcuffs: Yup.string().required('Motivação é obrigatório'),
      name: Yup.string().required('Nome é obrigatório'),
      birthday: Yup.date().required('Data é obrigatório ou está errada'),
      mather: Yup.string().required('Mãe é obrigatório'),
      situation: Yup.string().typeError('Situação é obrigatório'),

    });

    await schema.validate(involvedd, { abortEarly: false });

    const { status } = await httpPut(`involveds/${involvedd.id}`, involvedd);

    if (status === 200) {
      addToast({ title: 'Salvo com sucesso!', type: 'success' });
      history.push('/RO/involveds/show');
    } else {
      addToast({ title: 'Não foi possível gravar!', type: 'error' });
    }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [addToast, history, httpPut]);

  const breadcrumbs = {
    icon: UserFriends,
    links: [{ path: '/RO', title: 'RO-SP / Envolvidos / Editar' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Simple>
          <Title>
            <BsPencil />Editar Envolvidos
          </Title>
        </Simple>
        <WrapperAddress>
          <Container>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <InputHidden name="involved_id" />
              <InputHidden name="bopm_id" />
              <InputHidden name="people_occurrence_id" />
              <Grid container spacing={1}>
                <WrapperLabel label="Qualificador" xs={12} md={2} sm={2}>
                  <InputMask
                    name="qualified"
                    mask="Q99"
                    placeholder="QXX"
                  />
                </WrapperLabel>

                <WrapperLabel label="Nome" xs={12} md={10} sm={10}>
                  <Input name="name" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

                <WrapperLabel label="Data de nascimento" xs={12} md={4} sm={4}>
                  <InputMask
                    name="birthday"
                    mask="99/99/9999"
                  />
                </WrapperLabel>

                <WrapperLabel label="Mãe" xs={12} md={8} sm={8}>
                  <Input name="mather" placeholder="" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

                <WrapperLabel label="CPF" xs={12} md={4} sm={4}>
                  <InputMask
                    name="document"
                    mask="999.999.999-99"
                    style={{ textTransform: 'uppercase' }}
                  />
                </WrapperLabel>

                <WrapperLabel label="RG" xs={12} md={4} sm={4}>
                  <Input name="document_secondary" placeholder="" style={{ textTransform: 'uppercase' }} />
                </WrapperLabel>

                <WrapperLabel label="Celular" xs={12} sm={4} md={4}>
                  <InputMask mask="(99) 99999-9999" name="phone" />
                </WrapperLabel>

                <WrapperLabel label="Motivo das algemas" xs={12} md={4} sm={4}>
                  <Select
                    name="handcuffs"
                    options={handcuffs}
                  />
                </WrapperLabel>

                <WrapperLabel label="Tipo de envolvimento" xs={12} md={4} sm={4}>
                  <Select
                    name="type_involvement"
                    options={typeInvolvement}
                    isMulti
                  />
                </WrapperLabel>

                <WrapperLabel label="Situação" xs={12} md={4} sm={4}>
                  <Select
                    name="situation"
                    options={[
                      { value: 'PRESO', label: 'PRESO' },
                      { value: 'APREENDIDO', label: 'APREENDIDO' },
                      { value: 'LIBERADO', label: 'LIBERADO' },
                    ]}
                  />
                </WrapperLabel>
              </Grid>
              <GridButtons>
                <WrapperBtnCancel>
                  <Button type="button" onClick={() => { history.push('/RO/involveds/show'); }}>
                    Cancelar
                  </Button>
                </WrapperBtnCancel>
                <WrapperBtnSubmit>
                  <Button type="submit">
                    Salvar
                  </Button>
                </WrapperBtnSubmit>
              </GridButtons>
            </UForm>
          </Container>
        </WrapperAddress>
      </Container>
    </Layout>
  );
};

export default UpdateInvolved;
