import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import ISupport from 'interfaces/support';
import { useAuth } from 'hooks/auth';
import {
  formatDateTime,
} from '../../../utils';
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';
import Layout from '../../../components/Layout';
import Flex, { Grid } from '../../../components/Flex';
import {
  ListIcon, SupportIcon,
} from '../../../styles/icons';
import {
  Container, WrapperHeader, Title, Content,
} from './styles';

const Detail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{id: string}>();
  const { httpGet, loading } = useHttp();
  const { addToast } = useToast();
  const { iCan } = useAuth();
  const [support, setSupport] = useState<ISupport>({}as ISupport);

  useEffect(() => {
    async function loadAnswer():Promise<void> {
      const { status, data } = await httpGet(`/supports/${id}`);

      if (status === 200) {
        const [ref] = data.id.split('-');
        setSupport({
          ...data,
          ref: ref.toUpperCase(),
        });
      } else {
        addToast({ title: 'Erro ao tentar acessar a consulta.', type: 'error' });
        history.push('/support/awaiting/list');
      }
    }
    loadAnswer();

  }, [addToast, history, id]);  // eslint-disable-line

  let breadcrumbs;

  breadcrumbs = {
    icon: ListIcon,
    links: [
      { path: '/support/awaiting/list', title: 'Chamados' },
      { path: '', title: 'Detalhe do Suporte' },
    ],
  };

  iCan('adm') && (
    breadcrumbs = {
      icon: ListIcon,
      links: [
        { path: '/support', title: 'Suporte' },
        { path: '', title: 'Detalhe do Suporte' },
      ],
    }
  );

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <WrapperHeader>
            <span>
              <SupportIcon />
              #{ support.ref}
            </span>
          </WrapperHeader>
          <Title>Solicitante:</Title>
          <Grid container spacing={1}>
            <Flex title="Nome" xs={6} sm={6} md={6}>
              {support?.user?.name}
            </Flex>
            <Flex title="Gerência" xs={6} sm={6} md={6} textAutoSize>
              {support?.managements?.initials}
              {support?.managements?.initials && ` - ${support?.managements?.name}`}
            </Flex>
            <Flex title="Descrição" xs={6} sm={6} md={6}>
              {`${support?.problem}: ${support?.description}` }
            </Flex>
            <Flex title="Solicitado em" xs={6} sm={6} md={6}>
              {formatDateTime(support?.created_at)}
            </Flex>
          </Grid>
          <Title>Suporte L8:</Title>
          <Grid container spacing={1}>
            <Flex title="Ação" xs={6} sm={6} md={6}>
              {support?.status}
            </Flex>
            {support?.status === 'Em andamento' && (
            <>
              <Flex title="Respondido em" xs={6} sm={6} md={6}>
                {support?.process_at ? formatDateTime(support?.process_at) : ''}
              </Flex>
              <Flex title="Observação do Suporte:" xs={12} sm={12} md={12}>
                {support?.obs || 'Não informado'}
              </Flex>
            </>
                )}
            {support?.status === 'Encerrado' && (
            <>
              <Flex title="Respondido em" xs={6} sm={6} md={6}>
                {support?.process_at ? formatDateTime(support?.process_at) : ''}
              </Flex>
              <Flex title="Observação do Suporte:" xs={12} sm={12} md={12}>
                {support?.obs || 'Não informado'}
              </Flex>
              {support?.technician_id && (
              <>
                <Flex title="Técnico" xs={12} sm={12} md={12} textAutoSize>
                  {`${support.technician.first_name} ${support.technician.last_name}`}
                </Flex>
                <Flex title="Relatório do Técnico" xs={6} sm={6} md={6} textAutoSize>
                  {support.report}
                </Flex>
                <Flex title="Resolvido" xs={6} sm={6} md={6} textAutoSize>
                  {support.sorted_out ? 'Sim' : 'Não'}
                </Flex>
                <Flex title="Chegada em" xs={6} sm={6} md={6}>
                  {formatDateTime(support?.started_at)}
                </Flex>
                <Flex title="Término em" xs={6} sm={6} md={6}>
                  {formatDateTime(support?.ended_at)}
                </Flex>
              </>
                    )}
            </>
                )}
          </Grid>
        </Content>
      </Container>
    </Layout>
  );
};

export default Detail;
