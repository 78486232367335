import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Howl } from 'howler';
import session from '../../../utils/session';

/** hooks */
import { useHttp } from '../../../hooks/http';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

/** components */
import Layout from '../../../components/Layout';
import Pagination from '../../../components/Pagination';
import Card from './Card';
import { ListIcon, FilterIcon, UnFilterIcon } from '../../../styles/icons';

import {
  Container, WrapperFilter, WrapperContent, WrapperCore,
} from './styles';

/** interfaces */
import { IPaginate, IOrder } from '../../../interfaces';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const beep = require('../../../assets/beep.mp3');

const Answers: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { httpGet, httpPost, loading } = useHttp();
  const { addToast } = useToast();
  const [orders, setOrders] = useState<any>([]);
  const [cores, setCores] = useState<any>([]);
  const [filter, setFilter] = useState<number[]>([]);
  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const [sound] = useState(new Howl({ src: [beep] }));

  const breadcrumbs = {
    icon: ListIcon,
    links: [{ path: '', title: `${paginate.total}  Consultas abertas` }],
  };

  useEffect(() => {
    const isAlert = orders.find((order: any) => (
      order.status === 'Aguardando' || order.operator_id === user?.id));

    if (isAlert) {
      sound.play();
      sound.loop(true);
    }

    const timeout = setTimeout(() => {
      sound.stop();
    }, 6500);

    return () => {
      sound.stop();
      clearTimeout(timeout);
    };
  }, [sound, orders, user]);

  const handlePage = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  useEffect(() => {
    async function loadCores():Promise<void> {
      const { status: stCheck } = await httpGet('/services/check-core');
      if (stCheck !== 204) {
        history.push('/dashboard');
      }

      const filterCoresId:any = session.getObject('filterCoresId') || [];
      if (filterCoresId.length < 1 && user?.allocation?.management_id) {
        filterCoresId.push(user?.allocation?.management_id);
      }

      const { status, data } = await httpGet('cores');
      if (status === 200) {
        // eslint-disable-next-line no-underscore-dangle
        const _cores = data.map((core:any) => ({
          ...core,
          selected: filterCoresId.some((id:any) => id === core.id),
        }));

        setCores(_cores);
      }

      setFilter(filterCoresId);
    }

    loadCores();
  }, [history, httpGet, user]);

  const loadOrders = useCallback(async (loading: boolean) => {
    async function load():Promise<void> {
      if (filter.length < 1) {
        return;
      }
      const { status, data } = await httpGet(
        'orders/answers',
        { params: paginate },
        loading,
        { filter },
      );
      if (status === 200) {
        setOrders(data.data);
        setPaginate(data.pagination);
      }
    }
    load();
  }, [httpGet, filter, paginate.page]);// eslint-disable-line

  useEffect(() => {
    loadOrders(true);
  }, [filter, paginate.page]);// eslint-disable-line

  const handleForceLoad = useCallback(() => {
    loadOrders(true);
  }, [loadOrders]);

  useEffect(() => {
    const refresh = setInterval(() => {
      loadOrders(false);
    }, 1000 * 15);
    return () => {
      clearInterval(refresh);
    };
  }, [filter, loadOrders]);

  const handleSelectedFilter = useCallback((id: number) => {
    if (id === user?.allocation?.management_id) {
      addToast({ title: 'Você não pode desmarcar o seu posto de trabalho.' });
      return;
    }

    const coreSelected = cores.map((core: any) => {
      if (core.id === id) {
        return {
          ...core,
          selected: !core.selected,
        };
      }
      return { ...core };
    });

    const idsSelected = coreSelected
      .filter((core:any) => core.selected)
      .map((core:any) => core.id);

    session.setObject('filterCoresId', idsSelected);

    setCores([...coreSelected]);
    setFilter(idsSelected);
  }, [addToast, cores, user]);

  const handleGoToProcess = useCallback(async (id:string) => {
    const isOrdersProcessingByMe = orders
      .filter((order: any) => order.operator_id === user?.id && order.id !== id);

    if (isOrdersProcessingByMe.length > 0) {
      addToast({
        title: 'Atenção',
        description: `Você está processando outra(s) ${isOrdersProcessingByMe.length} consulta(s). Finalize as consultas abertas com você.`,
        type: 'warning',
        time: 0,
      });
    }

    const orderFiltered: any = orders.filter((order: IOrder) => order.id === id)[0];
    if (orderFiltered?.operator_id !== user?.id && orderFiltered.status === 'Processando') {
      addToast({ title: 'Esta consulta já está sendo processada por outro operador.' });
      return;
    }

    const { status } = await httpPost('/orders/process', { id });
    if (status === 200) {
      history.push(`/order/process/${id}`);
    }

    if (status === 201) {
      addToast({ title: 'Esta consulta já está sendo processada por outro operador.' });
      loadOrders(false);
    }

  }, [addToast, history, orders, user]);// eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <WrapperFilter>

          {cores.map((core:any) => (
            <WrapperCore
              key={core.id}
              onClick={() => handleSelectedFilter(core.id)}
              className={core.selected ? 'active' : ''}
            >
              {core.selected ? <FilterIcon /> : <UnFilterIcon />}
              {core.initials}
            </WrapperCore>
          ))}

        </WrapperFilter>
        <WrapperContent>
          {orders.map((order:any) => (
            <Card
              key={order.id}
              order={order}
              handleGoToProcess={handleGoToProcess}
              forceLoad={handleForceLoad}
            />
          ))}
          <Pagination paginate={paginate} handlePaginate={handlePage} />
        </WrapperContent>
      </Container>
    </Layout>

  );
};

export default Answers;
