import React, { useEffect } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

/** hooks */
import { useToast } from '../hooks/toast';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  noPrivate?: boolean;
  title?: string;
  can?: string;
  is?: string;
  component: React.ComponentType;
}

const RoutePrivate: React.FC<RouteProps> = ({
  noPrivate = false,
  title,
  can = '',
  is = '',
  component: Component,
  ...rest
}) => {
  const {
    user, iCan, iIs,
  } = useAuth();
  const { addToast } = useToast();
  const isSigned = !!user;

  useEffect(() => {
    document.title = title || (process.env.REACT_APP_NAME ?? 'DSCii');
  }, [title]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        const isCheck = iIs(is);
        const canCheck = iCan(can);

        if ((isSigned === !noPrivate) && (isCheck === canCheck)) {
          return (<Component />);
        }

        if (isSigned && (!isCheck || !canCheck)) {
          addToast({
            type: 'error',
            title: 'Acesso restrito',
            description: 'Você tentou acessar um recurso o qual não possui privilégio.',
          });
        }

        return (
          <Redirect to={{
            pathname: !isSigned ? '/' : '/dashboard',
            state: location,
          }}
          />
        );
      }}
    />

  );
};

const Route: React.FC<RouteProps> = ({
  title,
  component: Component,
  ...rest
}) => {
  useEffect(() => {
    document.title = title || (process.env.REACT_APP_NAME ?? 'DSCii');
  }, [title]);

  return (
    <ReactDOMRoute
      {...rest}
      render={() => <Component />}
    />
  );
};

export { RoutePrivate, Route };
