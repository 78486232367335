import React, { useCallback, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

/** components */
import Aside from './Aside';
import Header from './Header';

import { Alert, Container, Main } from './styles';

/** interfaces */
import { IBreadcrumb } from '../../interfaces';

interface LayoutProps {
  title?: string;
  breadcrumbs?: IBreadcrumb,
  loading?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  title, breadcrumbs, children, loading = false,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const handleToggleSidebar = useCallback((value: boolean) => {
    setToggled(value);
  }, []);

  return (
    <div className={`app ${toggled ? 'toggled' : ''}`}>

      {process.env.REACT_APP_ENV === 'teste'
        && (
          <Alert>
            <p>Você está no ambiente de testes</p>
          </Alert>
        )}

      <Aside
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      />
      <Container>

        <Header
          title={title}
          breadcrumbs={breadcrumbs}
          handleToggleSidebar={handleToggleSidebar}
        />

        <Main isLoading={loading}>
          <article>
            <BeatLoader size={16} margin={8} color="#7F8C8D" />
          </article>
          {children}
        </Main>

      </Container>
    </div>
  );
};

export default Layout;
