export const documentation = [
  { value: 1, label: 'CONSTRUÇÃO DE UNIDADES DE ATENDIMENTO MÉDICO' },
  { value: 2, label: 'CONSTRUÇÃO DE ESCOLAS' },
  { value: 3, label: 'CONSTRUÇÃO DE CRECHES' },
  { value: 4, label: 'CONSTRUÇÃO DE MORADIAS' },
  { value: 5, label: 'CONSTRUÇÃO DE QUADRAS DE ESPORTES' },
  { value: 6, label: 'OBRAS DE SANEAMENTO BÁSICO' },
  { value: 7, label: 'OBRAS PARA ABASTECIMENTO DAS CASAS COM ÁGUA ENCANADA' },
  { value: 8, label: 'OBRAS DE PAVIMENTAÇÃO E ASFALTO DE RUAS' },
  { value: 9, label: 'OBRAS PARA MELHORIA DA REDE ELÉTRICA' },
  { value: 10, label: 'OBRAS PARA A MELHORIA DO SISTEMA DE TRANSPORTES' },
  { value: 11, label: 'DESENVOLVIMENTO DO COMÉRCIO LOCAL' },
  { value: 12, label: 'PROGRAMAS PARA CURSOS PROFISSIONALIZANTES' },
  { value: 13, label: 'MELHORIA DA ILUMINAÇÃO PÚBLICA' },
  { value: 14, label: 'MELHORIA DA COLETA DE LIXO' },
];

export const options_quetions = [
  { value: 'Excelente', label: 'Excelente' },
  { value: 'Bom', label: 'Bom' },
  { value: 'Médio', label: 'Médio' },
  { value: 'Regular', label: 'Regular' },
  { value: 'Insatisfatório', label: 'Insatisfatório' },
];
