import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

/** components */
import Layout from '../../../components/Layout';
import Menu from './components/Menu';
import System from './System';
import Upajs from './Upajs';
import UpajsSave from './Upajs/Save';
import RegisterTypes from './RegisterTypes';
import RegisterTypesSave from './RegisterTypes/Save';
import EndSituation from './EndSituations';
import EndSituationSave from './EndSituations/Save';
import Motive from './Motives';
import MotiveSave from './Motives/Save';
import Ceperj from './Ceperj';
import CeperjSave from './Ceperj/Save';

import { GearIcon } from '../../../styles/icons';
import { Container, Aside, Content } from './styles';

const breadcrumbs = {
  icon: GearIcon,
  links: [
    { path: '', title: 'Settings' },
  ],
};

const Settings: React.FC = () => {
  const { resource, id } = useParams<{ resource: string, id?: string }>();

  const handledContent = useCallback(() => {
    switch (resource) {
      case 'upajs': {
        if (id) {
          return <UpajsSave />;
        }
        return <Upajs />;
      }
      case 'register-types': {
        if (id) {
          return <RegisterTypesSave />;
        }
        return <RegisterTypes />;
      }
      case 'end-situations': {
        if (id) {
          return <EndSituationSave />;
        }
        return <EndSituation />;
      }
      case 'motives': {
        if (id) {
          return <MotiveSave />;
        }
        return <Motive />;
      }
      case 'ceperj': {
        if (id) {
          return <CeperjSave />;
        }
        return <Ceperj />;
      }

      default:
        return <System />;
    }
  }, [resource, id]);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Aside>
          <Menu resource={resource} />
        </Aside>
        <Content>
          {handledContent()}
        </Content>
      </Container>
    </Layout>
  );
};

export default Settings;
