import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { isAfter } from 'date-fns';
import Flex, { Grid } from '../../../components/Flex';
import {
  Menu, MenuItem, DotButton, ItemIcon,
} from '../../../components/CustomMenu';

import { Container, WrapperMenu } from './styles';
import { PencilIcon, InfoIcon } from '../../../styles/icons';
import { ISocial } from '../../../interfaces';
import { formatDate } from '../../../utils';

interface ICard {
  social: ISocial;
  type: string;
}

const Card: React.FC<ICard> = ({ social, type }) => {
  const history = useHistory();

  const handleDateBefore = useCallback((date) => {
    if (isAfter(new Date(date), new Date())) {
      return true;
    }

    return false;
  }, []);

  return (
    <Grid item xs={12}>
      <Container>
        <Grid container spacing={1}>
          <WrapperMenu>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  <DotButton {...bindTrigger(popupState)} />
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={() => { history.push(`/socials/detail/${social.id}`); }}
                      style={{ width: 150 }}
                    >
                      <ItemIcon icon={<InfoIcon />} title="Mostrar" />
                    </MenuItem>
                    {handleDateBefore(social.created_at) && (
                      <MenuItem
                        onClick={() => { history.push(`/social/${social.id}`); }}
                        style={{ width: 150 }}
                      >

                        <ItemIcon icon={<PencilIcon />} title="Editar" />

                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </PopupState>
          </WrapperMenu>

          {(type === 'list-user') && (
            <Flex title="Nome" xs={12} md={3} sm={4}>
              {social.name || 'Não informado'}
            </Flex>
          )}
          {(type === 'list-base') && (
            <Flex title="Agente" xs={6} sm={6} md={3}>
              <b>{social.user?.name}</b>
            </Flex>
          )}
          {(type === 'list-user') && (
            <Flex title="Gênero" xs={3} md={2} sm={4}>
              {social.gender}
            </Flex>
          )}
          <Flex title="Participativo" xs={3} md={2} sm={4}>
            {social.is_participatory === true ? 'Sim' : 'Não'}
          </Flex>
          {(type === 'list-base') && (
            <Flex title="Acolhido" xs={3} md={2} sm={4}>
              {social.name || 'Não informado'}
            </Flex>
          )}
          <Flex title="Gerência" xs={6} md={2} sm={4}>
            {social.managements.initials} - {social.sectors?.initials}
          </Flex>
          <Flex title="Data" xs={6} md={2} sm={4}>
            {formatDate(social.created_at)}
          </Flex>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Card;
