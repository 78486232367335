import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 64px;
  padding: 0 16px;
  margin: 4px 0;
  display:flex;
  justify-content: space-between;
  align-items: center;

  flex-shrink:0;

  background: #fff;

  border-radius: 4px;
  margin-bottom: 8px;

  h1{
    font-size: 24px;
  }

  @media (max-width: 767.98px) {
      h1 {
        font-size: 18px;
      }
  }

`;

export const WrapperInput = styled.div`
  width: 200px;
  height: 32px;
  position: relative;

  display:flex;
  align-items: center;

  background: #fff;


  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 8px;

  input {
    border: 0;
    width: 100%;
    padding: 4px 24px 4px 4px;

  }
  button {
    position: absolute;
    border: 0;
    background: transparent;

    right: 8px;
    display:flex;
    align-items: center;
    transition: color 0.2s;

    svg{
      color: var(--color-gray);

      &:hover{
        color: var(--color-gray-h);
      }
    }

  }

  @media (max-width: 767.98px) {
     width: 100%;
  }

`;
