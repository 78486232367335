import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid black;

    tr {
      @media (width: 600px){
        :nth-child(2) {
            display: none;
          
        }
      }
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      background: black;
      color: white;
      font-weight: bold;
      
    }

    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      background-color:  #2C2F38 ;
      color: white;
      font-weight: 500;
      text-align: center;

      :nth-child(2) {
        text-align: justify;
        width: 40%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }
`;

export const Pagination = styled.div`
  padding: 12px;
  color: white;
  background-color: black;

  button {
    width: 4rem;
    height: 2rem;
  }
`;

export const Containt = styled.div`
  @media (max-width: 600px){
    flex-direction: column;
    span {
      display: none;
    }
  }
`;