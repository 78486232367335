import React, { ButtonHTMLAttributes } from 'react';
import {
  DataGrid, GridColDef, ValueGetterParams, GridRowsProp,
  GridToolbarExport, GridSelectionModelChangeParams,
} from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import PulseLoader from 'react-spinners/PulseLoader';

import { ptBrGrid } from './ptBrGrid';
import { PencilIcon } from '../../styles/icons';
import { Container, ButtonTransp } from './styles';

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#404040' },
    },
  },
  ptBR,
);

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>{
  icon: 'edit' | 'destroy' ;
  isLoading?: boolean;
}

const Button: React.FC<IButtonProps> = ({ icon, isLoading = false, ...rest }) => (
  <ButtonTransp
    type="button"
    disabled={isLoading}
    {...rest}
  >
    {isLoading
      ? (
        <PulseLoader
          size={8}
          color="#404040"
          loading={isLoading}
        />
      )
      : (
        icon && <PencilIcon />
      )}

  </ButtonTransp>
);

interface ICustomTable{
  columns: GridColDef[];
  rows: GridRowsProp;
  toolBar?: boolean;
  onSelectionModelChange?: (params: GridSelectionModelChangeParams)=> void | undefined;
  squash?: string;
}
const CustomTable: React.FC<ICustomTable> = ({
  columns, rows, toolBar = false, onSelectionModelChange, squash = '230px',
}) => (
  <Container squash={squash}>
    <ThemeProvider theme={theme}>
      <DataGrid
        rows={rows}
        columns={columns}
        localeText={ptBrGrid}
        components={{
          Toolbar: toolBar ? GridToolbarExport : undefined,
        }}
        onSelectionModelChange={onSelectionModelChange}
      />
    </ThemeProvider>
  </Container>
);

export default CustomTable;

export { Button };
export type { GridColDef, ValueGetterParams };
