interface ISelectValues{
  value: string | number;
  label: string;
}

interface IValue{
  values?: string | {id: string | number, name: string}[]
}

interface IValues{
  id: string | number;
  name: string;
}

export default function parseToSelectOptions(values: IValue): ISelectValues[] {
  if (typeof values === 'string') {
    return [{ value: values, label: values }];
  }

  if (typeof values === 'object' && Array.isArray(values)) {
    const options = values
      .map((_value: IValues) => ({ value: _value.id, label: _value.name }));
    return options;
  }

  return [{} as ISelectValues];
}
