import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import AppProvider from './hooks';
import GlobalStyle from './styles/global';
import Routes from './routes';
import './styles/App.scss';

const queryClient = new QueryClient();

const App: React.FC = () => (
  <>
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <GlobalStyle />
      </AppProvider>
    </QueryClientProvider>
  </>
);

export default App;
