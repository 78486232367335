import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/** hooks */
import { useHttp } from '../../hooks/http';

/** componentes */
import Layout from '../../components/Layout';
import HeaderFilterPlus from '../../components/Headers/FilterPlus';
import Pagination from '../../components/Pagination';
import Card from './Card';

import { UsersIcon } from '../../styles/icons';

import { Container } from './styles';

import { IPaginate } from '../../interfaces';

const breadcrumbs = {
  icon: UsersIcon,
  links: [{ title: 'Pessoas', path: '' }],
};

const People: React.FC = () => {
  const [people, setPeople] = useState([]);

  const [filter, setFilter] = useState('');
  const [paginate, setPaginate] = React.useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const { httpGet, loading } = useHttp();
  const history = useHistory();

  const handleSearch = (value: string): void => {
    setPaginate({
      ...paginate,
      page: 1,
    });
    setFilter(value);
  };

  const handlePaginate = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  useEffect(() => {
    async function loadUsers(): Promise<void> {
      const { status, data } = await httpGet('people', {
        params: {
          ...paginate,
          filter,
        },
      });

      if (status === 200) {
        setPeople(data.data);
        setPaginate(data.pagination);
      }
    }

    loadUsers();

  }, [filter, paginate.page]); // eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>

        <HeaderFilterPlus handleSearch={handleSearch} handlePlus={() => history.push('/person')} />

        {people && people.map((person: any) => (
          <Card key={person.id} person={person} />
        ))}
        <Pagination paginate={paginate} handlePaginate={handlePaginate} />

      </Container>
    </Layout>

  );
};

export default People;
