import Laydash from "components/Layout/Laydash";
import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { Container, Img } from "./styles";
import WalletBox from "./WalletBox";
import WalletBoxPercent from "./WalletBoxPercent";
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import Grid from '@material-ui/core/Grid';
import { WrapperLabel, DatePicker, Select } from '../../../../components/Forms';
import { Table } from "../../../../components/Table";
import { useHttp } from "hooks/http";
import { useAuth } from "hooks/auth";

import logoImg from '../../../../assets/leiseca.png';

const links = [
    { path: '/dashboard', title: "HOME", key: 1 },
    { path: '/leiseca/dashboard', title: "GERAL", key: 2 },
];

const Dashboard: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const [filter, setFilter] = useState('');
    const [date, setDate] = useState('');
    const [rcols, setRcols] = useState([]);
    const [abc, setAbc] = useState(0);
    const [rec, setRec] = useState(0);
    const [adm, setAdm] = useState(0);
    const [cri, setCri] = useState(0);
    const [acoolMedia, setAcoolMedia] = useState(0);
    const { user } = useAuth();
    const { httpGet } = useHttp();

    useEffect(() => {
        async function getLeiSeca(): Promise<void> {
            const { status, data } = await httpGet(`/rcols/leiseca`, {
                params: {
                    date,
                    filter
                },
            });

            if (status === 200) {

                const cols = data.map((v: any) => ({
                    team: v.team,
                    military: v.military.name,
                    civil: v.user.name,
                    addressOperation: handleAddressOperations(v.sectiontwo),
                    locality: handleLocation(v.sectiontwo),
                    approach: handleApproachs(v.sectiontwo),
                    refusal: handleRefusals(v.sectiontwo),
                    thirtythree_ml: handleThirtythree(v.sectiontwo),
                    thirtyfour_ml: handleThirtyfour(v.sectiontwo),
                }))

                let abc = handleApproachs(cols)
                let rec = handleRefusals(cols)
                let adm = handleThirtythree(cols)
                let cri = handleThirtyfour(cols)


                const am = ((rec + adm + cri) / abc) * 100;

                setAbc(abc);
                setRec(rec);
                setAdm(adm);
                setCri(cri);
                setAcoolMedia(am);
                setRcols(cols);
            }
        }

        getLeiSeca();

    }, [filter, date, user]); // eslint-disable-line

    function handleAddressOperations(sectiontwo: any) {
        // const result = sectiontwo.map((v: any) => `${v.addressOperation}###`);
        const result = sectiontwo
            .map((p: any) => p.addressOperation === '' ? p.another_not_listed : p.addressOperation);
        return result;
    }

    function handleLocation(sectiontwo: any) {
        const result = sectiontwo.map((v: any) => `${v.locality}, `);
        return result;
    }

    function handleApproachs(sectiontwo: any) {
        function getTotal(total: any, item: any) {
            return total + item.approach;
        }
        const result = sectiontwo.reduce(getTotal, 0);
        return result;
    }

    function handleRefusals(sectiontwo: any) {
        function getTotal(total: any, item: any) {
            return total + item.refusal;
        }
        const result = sectiontwo.reduce(getTotal, 0);
        return result;
    }

    function handleThirtythree(sectiontwo: any) {
        function getTotal(total: any, item: any) {
            return total + item.thirtythree_ml;
        }
        const result = sectiontwo.reduce(getTotal, 0);
        return result;
    }

    function handleThirtyfour(sectiontwo: any) {
        function getTotal(total: any, item: any) {
            return total + item.thirtyfour_ml;
        }
        const result = sectiontwo.reduce(getTotal, 0);
        return result;
    }

    function handleSubmit() {

    }

    const columns = useMemo(
        () => [
            {
                Header: 'Equipe',
                accessor: 'team',
            },
            {
                Header: 'Endereço',
                accessor: 'addressOperation',
            },
            {
                Header: 'Chefe Militar',
                accessor: 'military',
            },
            {
                Header: 'Chefe Civil',
                accessor: 'civil',
            },
            {
                Header: 'Abordagens',
                accessor: 'approach',
            },
            {
                Header: 'Recusa',
                accessor: 'refusal',
            },
            {
                Header: 'ADM 0,5 à 0,33mg/l',
                accessor: 'thirtythree_ml',
            },
            {
                Header: 'CRIME >= 0,34mg/l',
                accessor: 'thirtyfour_ml',
            },
        ],
        []
    )

    const handleSearch = useCallback((partipation: any) => {
        setFilter(partipation.value);
    }, []);

    const handleDate = useCallback((data: any) => {
        let date = new Date(data),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        const dd = [date.getFullYear(), mnth, day].join("-")
        setDate(dd)
    }, []);

    return (
        <Laydash
            links={links}
        >
            <Container>
                <Img
                    src={logoImg}
                />
                <UForm ref={formRef} onSubmit={handleSubmit}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <WrapperLabel label="" xs={12} md={6}>
                            <DatePicker
                                name="date_poll"
                                maxDate={new Date()}
                                onSelect={handleDate}
                                placeholderText="Data"
                            />
                        </WrapperLabel>
                        <WrapperLabel label="" xs={12} md={6}>
                            <Select
                                placeholderText="Equipe"
                                name="team"
                                options={[
                                    { value: 'A1', label: 'A1' },
                                    { value: 'A2', label: 'A2' },
                                    { value: 'A3', label: 'A3' },
                                    { value: 'A4', label: 'A4' },
                                    { value: 'A5', label: 'A5' },
                                    { value: 'A6', label: 'A6' },
                                    { value: 'A7', label: 'A7' },
                                    { value: 'A8', label: 'A8' },
                                    { value: 'A9', label: 'A9' },
                                    { value: 'A10', label: 'A10' },
                                    { value: 'B1', label: 'B1' },
                                    { value: 'B2', label: 'B2' },
                                    { value: 'B3', label: 'B3' },
                                    { value: 'B4', label: 'B4' },
                                    { value: 'B5', label: 'B5' },
                                    { value: 'B6', label: 'B6' },
                                    { value: 'B7', label: 'B7' },
                                    { value: 'B8', label: 'B8' },
                                    { value: 'B9', label: 'B9' },
                                    { value: 'B10', label: 'B10' },
                                    { value: 'C1', label: 'C1' },
                                    { value: 'C2', label: 'C2' },
                                    { value: 'C3', label: 'C3' },
                                    { value: 'C4', label: 'C4' },
                                    { value: 'C5', label: 'C5' },
                                    { value: 'C6', label: 'C6' },
                                    { value: 'C7', label: 'C7' },
                                    { value: 'C8', label: 'C8' },
                                    { value: 'C9', label: 'C9' },
                                    { value: 'C10', label: 'C10' },
                                ]}
                                onChange={handleSearch}
                            />
                        </WrapperLabel>
                    </Grid>
                </UForm>
                <WalletBox
                    title="ABORDAGENS"
                    amount={abc}
                    color="#2E7D32"
                />
                <WalletBox
                    title="RECUSA"
                    amount={rec}
                    color="#FFC107"
                />
                <WalletBox
                    title="ADM"
                    amount={adm}
                    color="#FF8F00"
                />
                <WalletBox
                    title="CRIMINAL"
                    amount={cri}
                    color="#B71C1C"
                />
                <WalletBoxPercent
                    title="ALCOOLEMIA MÉDIA"
                    amount={acoolMedia}
                    color="#030303"
                />
            </Container>
            <Table columns={columns} data={rcols} />
        </Laydash>
    )
}

export default Dashboard;