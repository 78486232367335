import React, {
  useRef, useState, useCallback, useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { color, fuels, type } from '../data';
import { InfoIcon } from '../../../styles/icons';
import { useHttp } from '../../../hooks/http';
import getValidationError from '../../../utils/getValidationsErros';
import { IFleet } from '../../../interfaces';
import { useToast } from '../../../hooks/toast';
import {
  WrapperLabel,
  Input,
  InputMask,
  InputHidden,
  TextArea,
  Select,
  ButtonIcon,
  Button,
} from '../../../components/Forms';
import {
  Container, GridButtons, WrapperBtnInfo, WrapperBtnCancel, WrapperBtnSubmit,
} from './styles';

interface IForm {
  fleet: IFleet;
}

interface List {
  value: number;
  label: string;
  make?: number;
}

const Profile: React.FC<IForm> = ({ fleet }) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();
  const [managements, setManagements] = useState<any>();
  const [makes, setMakes] = useState<List[]>([]);
  const [models, setModels] = useState<List[]>([]);
  const [modelTodos, setModelTodos] = useState<List[]>([]);

  const {
    httpPost, httpPut, httpGet, loading, unLoading,
  } = useHttp();
  const [statusOptions] = useState([
    { value: true, label: 'Ativo' },
    { value: false, label: 'Bloqueado' },

  ]);

  const [rentalOptions] = useState([
    { value: 'HADDAD', label: 'HADDAD' },
    { value: 'LIBEX', label: 'LIBEX' },
    { value: 'CAXANGÁ', label: 'CAXANGÁ' },
    { value: 'LETS', label: 'LETS' },
    { value: 'CS BRASIL', label: 'CS BRASIL' },
  ]);

  useEffect(() => {
    async function loadManagements(): Promise<void> {
      const { status, data } = await httpGet('managements/all');
      if (status === 200) {
        const stations = data
          .map((station: any) => ({
            value: station.id,
            label: `${station.initials} - ${station.name}`,
          }));

        setManagements(stations);
      }
    }
    loadManagements();
  }, []);// eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const loadMakes = async () => {
      const { status, data } = await httpGet('marcas/all');
      if (status === 200) {
        // eslint-disable-next-line no-underscore-dangle
        const _makes = data
          .map((ma: any) => ({
            value: ma.id,
            label: ma.name,
          }));
        setMakes(_makes);
      }
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const loadModels = async () => {
      const { status, data } = await httpGet('modelos');
      if (status === 200) {
        // eslint-disable-next-line no-underscore-dangle
        const _models = data
          .map((item: any) => ({
            value: item.id,
            label: item.name,
            make: item.make_id,
          }));
        setModelTodos(_models);
      }
    };
    loadMakes();
    loadModels();
  }, [httpGet]);

  const handlerModels = useCallback((e: any, uf: List[]) => {
    const { value } = e;
    if (value !== undefined) {
      // eslint-disable-next-line no-underscore-dangle
      const _models = modelTodos
        .filter((e) => (e.make === value));
      setModels(_models);
    }
  }, [modelTodos]);

  useEffect(() => {
    unLoading();
    if (fleet) {
      formRef.current?.setData({
        ...fleet,
        color: { value: fleet.color, label: fleet.color },
        brand: { value: fleet.make?.id, label: fleet.make?.name },
        model: { value: fleet.model?.id, label: fleet.model?.name },
        type_vehicle: { value: fleet.type_vehicle, label: fleet.type_vehicle },
        fuel: { value: fleet.fuel, label: fleet.fuel },
        management_id: { value: fleet?.management?.id, label: fleet.management?.name },
        status_vehicle: { value: fleet?.status_vehicle, label: fleet?.status_vehicle === true ? ' Ativo' : '' },
        rental: { value: fleet?.rental, label: fleet?.rental },
      });
    }
  }, [fleet]); // eslint-disable-line

  const handleSubmit = useCallback(async (payload: any) => {
    // eslint-disable-next-line no-underscore-dangle
    const _fleet = {
      ...payload,
      plate: payload.plate.toUpperCase(),
      brand: payload?.brand?.value,
      model: payload?.model?.value,
      color: payload?.color?.value,
      type_vehicle: payload?.type_vehicle?.value,
      fuel: payload?.fuel?.value,
      status_vehicle: payload?.status_vehicle?.value,
      management_id: payload?.management_id?.value,
      rental: payload?.rental?.value,
    };

    formRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        plate: Yup.string().required('Placa especial é obrigatório'),
        brand: Yup.string().required('Marca é obrigatório'),
        model: Yup.string().required('Modelo é obrigatório'),
        color: Yup.string().required('Cor é obrigatório'),
        type_vehicle: Yup.string().required('Tipo é obrigatória'),
        fuel: Yup.string().required('Combustível é obrigatória'),
        management_id: Yup.string().required('Gerência é obrigatória'),
        status_vehicle: Yup.string().required('Status é obrigatória'),
      });

      await schema.validate(_fleet, { abortEarly: false });

      if (fleet.id) {
        const { status } = await httpPut(`fleets/${_fleet.id}`, _fleet);
        if (status === 200) {
          addToast({ title: 'Alterado com sucesso!', type: 'warning' });
          history.push('/fleets');
        }
      } else {
        const { status } = await httpPost('fleets', _fleet);
        if (status === 200) {
          addToast({ title: 'Salvo com sucesso!', type: 'success' });
          history.push('/fleets');
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [fleet.id, httpPut, addToast, history, httpPost]);

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <InputHidden name="id" />
        <Grid container spacing={1}>
          <WrapperLabel label="Número de ordem" xs={12} sm={6}>
            <Input
              name="order_number"
              maxLength={8}
              style={{ textTransform: 'uppercase' }}
            />
          </WrapperLabel>
          <WrapperLabel label="Placa" xs={12} sm={6}>
            <InputMask
              name="plate"
              mask="aaa-9*99"
              style={{ textTransform: 'uppercase' }}
              disabled={!!fleet?.id}
            />
          </WrapperLabel>
          <WrapperLabel label="Placa especial" xs={12} sm={6}>
            <InputMask
              name="special_plate"
              mask="aaa-9*99"
              style={{ textTransform: 'uppercase' }}
            />
          </WrapperLabel>
          <WrapperLabel label="Marca" xs={12} sm={6}>
            <Select
              name="brand"
              options={makes}
              onChange={(e) => handlerModels(e, makes)}
            />
          </WrapperLabel>
          <WrapperLabel label="Modelo" xs={12} sm={6}>
            <Select
              name="model"
              options={models}
              defaultValue={models}
            />
          </WrapperLabel>
          <WrapperLabel label="Cor" xs={12} sm={6}>
            <Select
              name="color"
              options={color}
            />
          </WrapperLabel>
          <WrapperLabel label="Tipo" xs={12} sm={6}>
            <Select
              name="type_vehicle"
              options={type}
            />
          </WrapperLabel>
          <WrapperLabel label="Combustível" xs={12} sm={6}>
            <Select
              name="fuel"
              options={fuels}
            />
          </WrapperLabel>
          <WrapperLabel label="Selecione Gerência" xs={12} sm={12} md={6}>
            <Select name="management_id" options={managements} />
          </WrapperLabel>
          <WrapperLabel label="Status" xs={12} sm={12} md={6}>
            <Select
              name="status_vehicle"
              options={statusOptions}
            />
          </WrapperLabel>
          <WrapperLabel label="Locadora" xs={9} sm={6} md={6}>
            <Select
              name="rental"
              options={rentalOptions}
            />
          </WrapperLabel>

          <WrapperLabel label="Observações" xs={12}>
            <TextArea row={2} name="note" />
          </WrapperLabel>
        </Grid>
        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
            >
              <InfoIcon />
            </ButtonIcon>
          </WrapperBtnInfo>
          <WrapperBtnCancel>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </WrapperBtnCancel>
          <WrapperBtnSubmit>
            <Button
              type="submit"
              isLoading={loading}
            >
              Salvar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>
      </UForm>
    </Container>
  );
};

export default Profile;
