/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { EqualizerIcon } from '../../styles/icons';
import { Container, WrapperButton } from './styles';

const DrawerToggle: React.FC = () => (
  <WrapperButton type="button">
    <label className="pure-toggle-label-custom" htmlFor="pure-toggle-right" data-toggle-label="right">
      <EqualizerIcon />
    </label>
  </WrapperButton>

);

interface IDrawer{
  children: React.ReactNode;
}
const Drawer: React.FC<IDrawer> = ({ children }) => (
  <Container>

    <div className="pure-container" data-effect="pure-effect-slide">
      <input type="checkbox" id="pure-toggle-right" className="pure-toggle" data-toggle="right" />
      <div className="pure-drawer" data-position="right">
        {children}
      </div>

      <label className="pure-overlay" htmlFor="pure-toggle-right" data-overlay="right" />
    </div>
  </Container>
);

export default Drawer;

export { DrawerToggle };
