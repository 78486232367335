import Layout from 'components/Layout';
import SideVideo from 'components/SideVideo';
import Video from 'components/Video';
import React from 'react';
import { useParams } from 'react-router';
import { GoogleclassroomIcon } from 'styles/icons';
import Apply from './Apply';

import { Container, Whapper, Contant } from './styles';

const ClassRoom: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  const breadcrumbs = {
    icon: GoogleclassroomIcon,
    links: [
      { title: 'Capacitação', path: '' },
    ],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Whapper>
          {slug ? <Video lessonSlug={slug} /> : <Apply />}
        </Whapper>
        <Contant>
          <SideVideo />
        </Contant>
      </Container>
    </Layout>
  );
};

export default ClassRoom;
