/* eslint-disable no-nested-ternary */
import LinearProgress from '@material-ui/core/LinearProgress';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import {
  formatCpf, formatDate, formatDateTime, formatPlate,
} from '../../../../utils';

/** hooks */
import { useHttp } from '../../../../hooks/http';
import { useToast } from '../../../../hooks/toast';

/** components */
import Flex, { Grid } from '../../../../components/Flex';
import Layout from '../../../../components/Layout';
import {
  CarIcon, ELinkIcon, ListIcon, PersonIcon,
} from '../../../../styles/icons';

import Form from '../Form';
import {
  Card, Container,
  Content, Title,
  WrapperConduction, WrapperHeader, WrapperLink, WrapperSeparator,
} from './styles';

/** interface */
import { IOrder } from '../../../../interfaces';
import StyleCard from './StyleCard';

const breadcrumbs = {
  icon: ListIcon,
  links: [{ path: '', title: 'Detalhe da  Consulta' }],
};

const Extends: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { httpGet } = useHttp();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [persons, setPersons] = useState([]);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [approximation, setApproximation] = useState<any[]>();

  useEffect(() => {
    async function loadAnswer(): Promise<void> {
      const { status, data } = await httpGet(`orders/detail/${id}`);

      if (status === 200) {
        const { victim } = data;
        setPersons(victim);
        const [ref] = data.id.split('-');
        setOrder({
          ...data,
          ref: ref.toUpperCase(),
        });
        setLoading(false);
      } else {
        addToast({ title: 'Erro ao tentar acessar a consulta.', type: 'error' });
        history.push('/orders/me');
      }
    }

    async function loadApproximation(): Promise<void> {
      const { status, data } = await httpGet(`orders/approximation/${id}`);
      if (status === 200) {
        setApproximation(data);
        setSpinner(false);
      }
    }

    loadAnswer();

    if (!order.result?.conduction) {
      setSpinner(true);
      loadApproximation();
    }

  }, [addToast, history, id]);  // eslint-disable-line

  let resultName;
  switch (order.result?.id) {
    case 6:
      resultName = 'Nada constatado';
      break;
    case 2:
      resultName = 'Nada constatado';
      break;
    case 11:
      resultName = 'Nada constatado';
      break;
    default:
      resultName = order.result?.name;
      break;
  }

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content isConduction={!!order.result?.conduction}>
          <WrapperHeader>
            <span>
              {order.type === 'vehicle' ? <CarIcon /> : <PersonIcon />}
              #{order.ref}
            </span>
            <h2>
              {resultName}
            </h2>
          </WrapperHeader>

          {order.result?.conduction && (
            <WrapperConduction>
              Informar a Supervisão e Conduzir à DP - Preencher formulário de encerramento
            </WrapperConduction>
          )}

          <Grid container spacing={1}>
            <Flex title="Informações" xs={12}>
              {order.note || 'não informado'}
            </Flex>
          </Grid>
          <Title>Dados informados para consulta:</Title>
          <Grid container spacing={1}>
            {order.type === 'person' && (
              <>
                <Flex title="CPF" xs={6} sm={6} md={3}>
                  {order.document ? formatCpf(order.document) : 'não informado'}
                </Flex>
                <Flex title="RG" xs={6} sm={6} md={3}>
                  {order.document_secondary || 'não informado'}
                </Flex>
                <Flex title="Nascimento" xs={12} sm={6} md={4}>
                  {order.birthday ? formatDate(order.birthday) : 'não informado'}
                </Flex>
                <Flex title="Nome" xs={12} sm={6} md={4}>
                  {order.name || 'não informado'}
                </Flex>
                <Flex title="Mãe" xs={6} sm={6} md={4} textAutoSize>
                  {order.mather || 'não informado'}
                </Flex>
                <Flex title="Pai" xs={6} sm={6} md={4} textAutoSize>
                  {order.father || 'não informado'}
                </Flex>
              </>
            )}

            {order.type === 'vehicle' && (
              <>
                <Flex title="Placa" xs={12} sm={4} md={4}>
                  {order.plate ? formatPlate(order.plate) : 'não informado'}
                </Flex>
                <Flex title="Chassi" xs={12} sm={4} md={4}>
                  {order.chassi || 'não informado'}
                </Flex>
                <Flex title="Motor" xs={12} sm={4} md={4}>
                  {order.engine_number || 'não informado'}
                </Flex>
                <Flex title="Marca/Modelo" xs={12} sm={4} md={4}>
                  {order.brand || 'não informado'}
                </Flex>
                <Flex title="Cor" xs={12} sm={4} md={4}>
                  {order.color || 'não informada'}
                </Flex>
              </>
            )}
            <Flex title="Observação" xs={12}>
              {order.description || 'não informado'}
            </Flex>
            <Flex title="Solicitada em" xs={6} sm={3}>
              {formatDateTime(order.created_at)}
            </Flex>
            <Flex title="Respondida em" xs={6} sm={3}>
              {formatDateTime(order.updated_at)}
            </Flex>
          </Grid>

          {(order.result_id !== 1 && order.result_id !== 9 && order.type === 'person') && (
            <>
              {order.person ? (
                <>
                  <Title>Pessoa encontrada:</Title>
                  <Grid container spacing={1}>
                    <Flex title="CPF" xs={6} sm={6} md={3}>
                      {order.person?.document ? formatCpf(order.person?.document) : 'não informado'}
                    </Flex>
                    <Flex title="RG" xs={6} sm={6} md={3}>
                      {order.person?.document_secondary || 'não informado'}
                    </Flex>
                    <Flex title="Nascimento" xs={6} sm={6} md={4}>
                      {order.person?.birthday ? formatDate(order.person?.birthday) : 'não informado'}
                    </Flex>
                    <Flex title="Nome" xs={12} sm={6} md={4}>
                      {order.person?.name || 'não informado'}
                    </Flex>
                    <Flex title="Mãe" xs={12} sm={6} md={4}>
                      {order.person?.mather || 'não informado'}
                    </Flex>
                    <Flex title="Pai" xs={12} sm={6} md={4}>
                      {order.person?.father || 'não informado'}
                    </Flex>
                  </Grid>
                </>
              ) : (
                <>
                  <Title>Pessoa encontrada:</Title>
                  <Grid container spacing={1}>
                    <Flex title="CPF" xs={6} sm={6} md={3}>
                      {order.extend?.document ? formatCpf(order.extend?.document) : 'não informado'}
                    </Flex>
                    <Flex title="RG" xs={6} sm={6} md={3}>
                      {order.extend?.document_secondary || 'não informado'}
                    </Flex>
                    <Flex title="Nascimento" xs={6} sm={6} md={4}>
                      {order.extend?.birthday ? formatDate(order.extend?.birthday) : 'não informado'}
                    </Flex>
                    <Flex title="Nome" xs={12} sm={6} md={4}>
                      {order.extend?.name || 'não informado'}
                    </Flex>
                    <Flex title="Mãe" xs={12} sm={6} md={4}>
                      {order.extend?.mather || 'não informado'}
                    </Flex>
                    <Flex title="Pai" xs={12} sm={6} md={4}>
                      {order.extend?.father || 'não informado'}
                    </Flex>
                  </Grid>
                </>
              )}
              {!order.result?.conduction && (
                <Grid container spacing={1} justify="flex-end">
                  <Grid item xs={12} sm={6} md={4}>
                    <WrapperLink>
                      <Link to="/dashboard">
                        <ELinkIcon />
                        Dashboard
                      </Link>
                    </WrapperLink>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {(order.result_id !== 1 && order.result_id !== 9 && order.type === 'vehicle') && (
            <>
              <Title>Veículo encontrado:</Title>
              <Grid container spacing={1}>
                <Flex title="Placa" xs={12} sm={4} md={4}>
                  {formatPlate(order.vehicle?.plate)}
                </Flex>
                <Flex title="Chassi" xs={12} sm={4} md={4}>
                  {order.vehicle?.chassi}
                </Flex>
                <Flex title="Motor" xs={12} sm={4} md={4}>
                  {order.vehicle?.engine_number}
                </Flex>
                <Flex title="Marca/Modelo" xs={12} sm={4} md={4}>
                  {order.vehicle?.brand}
                </Flex>
                <Flex title="Cor" xs={12} sm={4} md={4}>
                  {order.vehicle?.color}
                </Flex>
              </Grid>

              {!order.result?.conduction && (
                <Grid container spacing={1} justify="flex-end">
                  <Grid item xs={12} sm={6} md={4}>
                    <WrapperLink>
                      <Link to="/dashboard">
                        <ELinkIcon />
                        Dashboard
                      </Link>
                    </WrapperLink>
                  </Grid>
                </Grid>
              )}

            </>
          )}

          {order.result?.conduction && (
            <Grid container spacing={1} justify="flex-end">
              <Grid item xs={12} sm={6} md={4}>
                <WrapperLink>
                  <Link to={`/closing-forms/order/${order.id}`}>
                    <ELinkIcon />
                    Formulário de encerramento
                  </Link>
                </WrapperLink>
              </Grid>
            </Grid>
          )}

          {order.result_id === 1 && (
            <>
              <Title>Dados inconsistentes:</Title>

              <p> {order.note && order.note} </p>

              <footer>
                Os dados informados para a pesquisa não foram suficientes
                Caso consiga novas informações faça um novo pedido de consulta.
              </footer>

            </>
          )}

          {order.result_id === 9 && (
            <>
              <Title>Sistema Inoperante:</Title>
              <b>
                Senhor(a) Policial proceda com a sua DISCRICIONARIEDADE
                na condução da abordagem.
              </b>

              {order.note && <p>order.note</p>}

              <footer>
                Não foi possível acessar o(s) sistema(s):{' '}
                {order.systems.map((system: any) => (
                  <WrapperSeparator>
                    {system.name}
                  </WrapperSeparator>
                ))}
                {' '}. No momento, não vamos conseguir apoiá-lo com as nossas pesquisas
                devido a inoperância dos sistemas de consultas.

                <p>Em dúvidas faça contato com sua supervisão.</p>
              </footer>

            </>
          )}
        </Content>
        {!!persons && persons?.map((person: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key, @typescript-eslint/no-empty-function
          <Card key={index} onClick={() => { }}>
            <StyleCard person={person} />
          </Card>
        ))}
        {spinner && (<LinearProgress color="secondary" />)}
        {!order.result?.conduction
          && order.type === 'person'
          && (
            <Form approximation={approximation} />
          )}
      </Container>
    </Layout>
  );
};

export default Extends;
