import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { createModal } from 'react-modal-promise';

interface IModal {
  title: string;
  content: string | React.ReactNode | React.FC;
  buttons?: 'sim_nao';
  width?: number;
  isOpen: boolean;
  onResolve(value: boolean): Promise<any>;
}

const ModalTemplate: React.FC<IModal> = ({
  title, content, width = 300, isOpen, buttons = 'cancel_confirm', onResolve,
}) => (
  <Dialog
    open={isOpen}
    onClose={() => onResolve(false)}
    aria-labelledby="dialog"
  >
    <DialogTitle id="dialog">{title}</DialogTitle>
    <DialogContent dividers style={{ width }}> {content} </DialogContent>
    <DialogActions>
      <Button onClick={() => onResolve(false)} color="primary">
        Não
      </Button>
      <Button onClick={() => onResolve(true)} color="primary">
        Sim
      </Button>
    </DialogActions>
  </Dialog>
);

export const ModalOption = createModal(ModalTemplate);
