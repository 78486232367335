import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const DrawerContent = styled.div`
  width: 100%;
  padding: 8px 0;
  display: flex;
  form{
    width: 100%;
  }
`;
