import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { formatCpf, formatDateTime } from '../../../utils';

/** components */
import Flex from '../../../components/Flex';
import { MapIcon } from '../../../styles/icons';
import { Container, WrapperLink } from './styles';

/** interfaces */
import { IOrder } from '../../../interfaces';

interface IHistory{
  order: IOrder;
}
const History: React.FC<IHistory> = ({ order }) => {
  const history = useHistory();

  const handleGoToDetail = useCallback(() => {
    history.push(`/order/answered/detail/${order.id}`);
  }, [history, order.id]);

  return (

    <Container onClick={handleGoToDetail}>

      <Grid container spacing={1}>
        <Flex title="Resultado" xs={6} sm={3} md={2} textAutoSize>
          {order.result.name}
        </Flex>
        <Flex title="Data" xs={12} sm={3} md={2} textAutoSize>
          {formatDateTime(order.created_at)}
        </Flex>
        <Flex title="Gerência" xs={4} sm={3} md={2}>
          {order.management.name}
        </Flex>
        <Flex title="Local da Abordagem" xs={2}>
          <WrapperLink>
            {order?.address?.latitude && (
            <a
              href={`https://www.google.com.br/maps/search/${order.address.latitude},${order.address.longitude}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MapIcon />
            </a>
              )}
          </WrapperLink>
        </Flex>

        <Flex title="Abordado por:" xs={12} md={6} textAutoSize>
          {order.user.name} - {formatCpf(order.user.document)}
        </Flex>

        <Flex title="Respondida por:" xs={12} md={6} textAutoSize>
          {order.operator.name} - {formatCpf(order.operator.document)}
        </Flex>

      </Grid>

    </Container>

  );
};

export default History;
