import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateTime, formatCpf, formatPlate } from '../../../../utils';
/** components */
import Flex, { Grid } from '../../../../components/Flex';

import {
  CarIcon, PersonIcon, MapIcon,
} from '../../../../styles/icons';
import {
  Container, WrapperRow, WrapperLink,
} from './styles';

interface ICard {
  order: any;
}

const Card: React.FC<ICard> = ({ order }) => {
  const history = useHistory();
  const [ref] = order.id.split('-');

  return (
    <Grid item xs={12}>
      <Container onClick={() => history.push(`/order/answered/detail/${order.id}`)}>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <WrapperRow>
              {order.type === 'vehicle' ? <CarIcon /> : <PersonIcon />}
              #{ref.toUpperCase()}
            </WrapperRow>
          </Grid>

          <Flex title="Resultado" xs={6} sm={6} md={3}>
            <b> {order.r_name} </b>
          </Flex>

          <Flex title="Solicitado em" xs={6} sm={6} md={3}>
            <WrapperLink>
              {formatDateTime(order.created_at)}
              {order.a_latitude && (
                <a
                  href={`https://www.google.com.br/maps/search/${order.a_latitude},${order.a_longitude}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapIcon />
                </a>
              )}
            </WrapperLink>
          </Flex>

          <Flex title="Gerência" xs={6} sm={6} md={3} textAutoSize>
            {order.m_initials}
            {order.s_initials && ` - ${order.s_initials}`}
          </Flex>

          {order.type === 'vehicle' && (
            <Flex title={order.plate ? 'Placa' : 'Chassi'} xs={12} sm={12} md={5} textAutoSize>
              {order.plate ? formatPlate(order.plate) : order.chassi}
            </Flex>
          )}

          {order.type === 'person' && (
            <Flex title={order.document ? 'CPF' : 'Nome'} xs={12} sm={12} md={5} textAutoSize>
              {order.document ? formatCpf(order.document) : order.name}
            </Flex>
          )}

          <Flex title="Solicitado por" xs={6} sm={6} md={3} textAutoSize>
            {formatCpf(order.u_document)}, {`${order.u_first_name} ${order.u_last_name}` }
          </Flex>

          <Flex title="Respondida por" xs={6} sm={6} md={3} textAutoSize>
            {order.operator_id && (
              <>
                {formatCpf(order.o_document)}, {`${order.o_first_name} ${order.o_last_name}`}
              </>
            )}
          </Flex>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Card;
