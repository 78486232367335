const states = [
  { id: 12, value: 'ACRE', label: 'ACRE' },
  { id: 27, value: 'ALAGOAS', label: 'ALAGOAS' },
  { id: 16, value: 'AMAPÁ', label: 'AMAPÁ' },
  { id: 13, value: 'AMAZONAS', label: 'AMAZONAS' },
  { id: 29, value: 'BAHIA', label: 'BAHIA' },
  { id: 23, value: 'CEARÁ', label: 'CEARÁ' },
  { id: 53, value: 'DISTRITO FEDERAL', label: 'DISTRITO FEDERAL' },
  { id: 32, value: 'ESPÍRITO SANTO', label: 'ESPÍRITO SANTO' },
  { id: 52, value: 'GOIÁS', label: 'GOIÁS' },
  { id: 21, value: 'MARANHÃO', label: 'MARANHÃO' },
  { id: 51, value: 'MATO GROSSO', label: 'MATO GROSSO' },
  { id: 50, value: 'MATO GROSSO DO SUL', label: 'MATO GROSSO DO SUL' },
  { id: 31, value: 'MINAS GERAIS', label: 'MINAS GERAIS' },
  { id: 15, value: 'PARÁ', label: 'PARÁ' },
  { id: 25, value: 'PARAÍBA', label: 'PARAÍBA' },
  { id: 41, value: 'PARANÁ', label: 'PARANÁ' },
  { id: 26, value: 'PERNAMBUCO', label: 'PERNAMBUCO' },
  { id: 22, value: 'PIAUÍ', label: 'PIAUÍ' },
  { id: 33, value: 'RIO DE JANEIRO', label: 'RIO DE JANEIRO' },
  { id: 24, value: 'RIO GRANDE DO NORTE', label: 'RIO GRANDE DO NORTE' },
  { id: 43, value: 'RIO GRANDE DO SUL', label: 'RIO GRANDE DO SUL' },
  { id: 11, value: 'RONDÔNIA', label: 'RONDÔNIA' },
  { id: 14, value: 'RORAIMA', label: 'RORAIMA' },
  { id: 42, value: 'SANTA CATARINA', label: 'SANTA CATARINA' },
  { id: 35, value: 'SÃO PAULO', label: 'SÃO PAULO' },
  { id: 28, value: 'SERGIPE', label: 'SERGIPE' },
  { id: 17, value: 'TOCANTINS', label: 'TOCANTINS' },
];


export default states;
