import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon, CheckFiIcon } from '../../styles/icons';
import { Container } from './styles';

interface ICopyClipboard {
  text: string
  children: React.ReactChild;
}
const CopyClipboard: React.FC<ICopyClipboard> = ({ text, children }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 1000 * 2);

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);

  return (
    <>
      {text && (
      <CopyToClipboard text={text || 'não informado'} onCopy={() => setShow(true)}>
        <Container>
          <span>
            {show && <p>Copiado <CheckFiIcon /></p>}
            <CopyIcon title="Copiar para área de transferência" />
            {children}
          </span>
        </Container>
      </CopyToClipboard>
      )}
      {!text && 'não informado'}
    </>
  );
};

export default CopyClipboard;
