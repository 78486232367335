import styled, { css } from 'styled-components';

interface IContainer {
  squash?: string;
}
export const Container = styled.div<IContainer>`
  height: calc( 100vh - 230px);

  ${(props) => props.squash && css`
    height: calc( 100vh - ${props.squash});
  `}

  width: 100%;
`;

export const ButtonTransp = styled.button`
  border: none;
  background-color: transparent;

  svg{
    color: #666;

    &:hover{
      color: var(--color-button-h);
    }
  }
`;
