import React from 'react';
import { Link } from 'react-router-dom';

/** componentes */
import Flex, { Grid } from '../../../../../components/Flex';
import { PencilIcon } from '../../../../../styles/icons';
import { Container, Aside, Content } from './styles';

interface ICard {
  children: React.ReactNode;
  UriUpdate: string;
}
const Card: React.FC<ICard> = ({ children, UriUpdate }) => (
  <Container>
    <Aside>
      <Link to={UriUpdate}><PencilIcon /></Link>
    </Aside>
    <Content>
      {children}
    </Content>
  </Container>
);

export default Card;
export { Flex, Grid };
