import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Howl } from 'howler';
import { Box } from '@material-ui/core';
import { useHttp } from '../../hooks/http';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import Layout from '../../components/Layout';
import Pagination from '../../components/Pagination';
import Card from './Card';
import { ListIcon } from '../../styles/icons';
import {
  Container, WrapperContent,
} from './styles';
import { IPaginate, IMaintenance } from '../../interfaces';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const beep = require('../../assets/beep.mp3');

const Company: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { httpGet, loading } = useHttp();
  const { addToast } = useToast();
  const [maintenances, setMaintenances] = useState<IMaintenance[]>([]);
  const [paginate, setPaginate] = useState<IPaginate>({
    page: 1, perPage: 10, lastPage: 1, total: 0,
  });

  const [sound] = useState(new Howl({ src: [beep] }));

  const breadcrumbs = {
    icon: ListIcon,
    links: [{ path: '', title: `${paginate.total}  Chamados abertos` }],
  };

  useEffect(() => {
    const isAlert = maintenances.find((support: any) => (
      support.status === 'Solicitação'));

    if (isAlert) {
      sound.play();
      sound.loop(true);
    }

    const timeout = setTimeout(() => {
      sound.stop();
    }, 6500);

    return () => {
      sound.stop();
      clearTimeout(timeout);
    };
  }, [sound, maintenances, user]);

  const loadOrders = useCallback(async (loading: boolean) => {
    async function load(): Promise<void> {
      const { status, data } = await httpGet(
        'fleetMechanicals/companys',
        { params: paginate },
        loading,
      );
      if (status === 200) {
        setMaintenances(data?.data);
        setPaginate(data.pagination);
      }
    }
    load();
    }, [httpGet, paginate?.page]);// eslint-disable-line

  useEffect(() => {
    loadOrders(true);
      }, [paginate.page]);// eslint-disable-line

  const handleForceLoad = useCallback(() => {
    loadOrders(true);
  }, [loadOrders]);

  useEffect(() => {
    const refresh = setInterval(() => {
      loadOrders(false);
    }, 1000 * 15);
    return () => {
      clearInterval(refresh);
    };
  }, [loadOrders]);

  const handlePage = (payload: IPaginate): void => {
    setPaginate(payload);
  };

  const handleGoToProcess = useCallback(async (id:string) => {
    const maintenanceFiltered: any = maintenances
      .filter((maintenance: IMaintenance) => maintenance.id === id)[0];
    if (maintenanceFiltered.situation === 'Atendimento') {
      history.push(`/company/detail/${id}`);
      return;
    }
    history.push(`/company/${id}`);

  }, [addToast, history, maintenances, user]);// eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <WrapperContent>
          {maintenances.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box><h2>Você não tem chamados</h2></Box>
            </Box>
          )}
          {maintenances.map((maintenance: any) => (
            <Card
              key={maintenance.id}
              maintenance={maintenance}
              handleGoToProcess={handleGoToProcess}
              forceLoad={handleForceLoad}
            />
          ))}
          <Pagination paginate={paginate} handlePaginate={handlePage} />
        </WrapperContent>
      </Container>
    </Layout>

  );
};

export default Company;
