import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  
  .displayNone{
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 24px;

  display: flex;

  align-items: center;
  border: none;
  border-radius: 4px;
  color: var(--color-white);
  background: var(--color-button);

  font-size: 1.1rem;
  font-weight: 300;

  svg{
    margin-right: 16px;
  }

`;
