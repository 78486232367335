import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-template-rows: 100%;
  grid-template-areas:
    'aside content';
  width: 100%;
  height: 100%;


  @media (max-width: 767.98px) {
    grid-template-columns: 1fr;
    grid-template-rows: 150px auto;
    grid-template-areas:
      'aside'
      'content';
    }
`;

export const Aside = styled.div`
  grid-area: aside;
  display: flex;
  padding: 16px 0 16px 16px;

  @media (max-width: 767.98px) {
    padding: 16px;
    overflow-y: scroll;
  }
`;

export const Content = styled.div`
  grid-area: content;
  display: flex;
  padding: 16px 16px 16px 0;

  @media (max-width: 767.98px) {
    padding: 16px;
  }
`;
