import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Content = styled.div`
  padding: 16px;
  background: #fff;
  border-radius: 4px;
  .MuiAccordionSummary-content{
   > p{
     font-size: 16px;
     font-weight: lighter;
   }
  }
  .MuiAccordionDetails-root{
    padding: 8px 0;
  }
`;

export const DrawerContent = styled.div`
  width: 100%;
  padding: 8px 0;
  display: flex;
  form{
    width: 100%;
  }
`;

export const WrapperAccordion = styled.div`
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 8px;
  margin-bottom: 8px;

  .MuiAccordionDetails-root{
    padding: 8px 0;
  }
`;

export const WrapperModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h2{
    font-size: 1.1em;
    margin-bottom: 16px;
    text-align: center;
  }

  p{
    margin-bottom: 8px;
  }
`;
