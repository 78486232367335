import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
`;

export const Content = styled.div`
  background: #fff;
  padding: 32px 16px;
  border-radius: 4px;
`;

export const WrapperAddress = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 4px;

  h3{
    color: var(--color-primary);
    margin-bottom: 8px;
  }

  @media (max-width: 767.98px) {
    span {
      display: none;
    };
  }
`;

export const Text = styled.h5`
  display: flex;
`;

export const WrapperRemove = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 4px;
  cursor: pointer;
  svg{
    color: var(--color-secondary);
  }
  &:hover{
    svg{
      color: var(--color-secondary-h);
    }
  }
`;
