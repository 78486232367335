import React from 'react';

import 'react-pro-sidebar/dist/css/styles.css';
import Layout from '../../components/Layout';

/** componentes */
import { CheckIcon } from '../../styles/icons';
import { Container } from './styles';

const Allocation: React.FC = () => (
  <Layout breadcrumbs={{ icon: CheckIcon }}>
    <Container>Selecionar posto de serviço</Container>
  </Layout>
);

export default Allocation;
