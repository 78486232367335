import { Tooltip } from '@material-ui/core';
import styled, { css } from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ContainerProps{
  isFocused?: boolean;
  isFilled?: boolean;
  isErrored?: boolean;
  transform?: 'uppercase' | 'default';
  disable?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  background: transparent;
  padding: 0;
  position: relative;

  &::placeholder{
      text-align: center;
      font-size: 22px;
      color: #fff;
    };
  &::active

  ${(props) => props.transform === 'uppercase' && css`
    input{
      text-transform: uppercase;
    }
  `};

  ${(props) => props.isErrored && css`
    border-color: var(--color-red);
  `}

  ${(props) => props.isFocused && css`
    border-color: #fff;
  `}

  ${(props) => props.disable && css`
      background-color: #fff;

      .react-select__single-value--is-disabled{
        color: #000 !important;
      }
  `}

  

  display: flex;
  align-items: center;

  & + div{
      margin-top: 12px;
    }

  > div{
    flex: 1;
    background: transparent;
    border: 0;
    color:  #fff;

    &::placeholder{
      color: #fff;
    }


  }

  span{
      font-size: 14px;
      color: var(--color-red);
      text-align: left;
      /* display: none; */
    }


`;

export const Error = styled(Tooltip)`
  height: 20px;
  top: 8px;
  right: 42px;
  background: #FFF;

  position: absolute;
  display:none;
  visibility: hidden;


  ${(props) => props.title && css`
    display:block;
    visibility: visible;
  `}

  svg{
    margin: 0;
  }

  span {
    background: var(--color-red);
    color: #FFF;

    left: 90%;
    transform: translateX(-95%);

    &::before{
      border-color: var(--color-red) transparent;
      left: 90%;
    }
  }
`;
