import React from 'react';

import Grid from '@material-ui/core/Grid';
import Person from 'interfaces/person';
import {
  formatDate, formatCpf,
} from '../../../../../utils';
import Flex from '../../../../../components/Flex';
import { Container, WrapperHeader } from './styles';

interface IPersonCard {
  person: Person;
}

const StyleCard: React.FC<IPersonCard> = ({ person }) => (
  <Container>
    <WrapperHeader>
      <h2>
        {person?.name}
      </h2>
    </WrapperHeader>
    <Grid container spacing={1}>
      <Flex title="CPF" xs={6} md={6}>
        {formatCpf(person?.document)}
      </Flex>
      <Flex title="Nascimento" xs={6} md={6}>
        {formatDate(person?.birthday)}
      </Flex>
      <Flex title="Mãe" xs={6} md={6}>
        {person?.mather}
      </Flex>
      <Flex title="Pai" xs={6} md={6}>
        {person?.father}
      </Flex>
      {person?.status && (
        <Flex title="Status" xs={6} md={6}>
          {person?.status}
        </Flex>
      )}
    </Grid>
  </Container>
);

export default StyleCard;
