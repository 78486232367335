import React, {
  useRef, useCallback, useState,
} from 'react';
import AsyncSelect from 'react-select/async';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ModalFactory from 'react-modal-promise';
import * as Yup from 'yup';
import { InfoIcon } from 'styles/icons';
import { ModalDialog } from 'components/ModalDialog';
import getCurrentPosition from '../../../../utils/getCurrentPosition';
import getValidationError from '../../../../utils/getValidationsErros';
import { useHttp } from '../../../../hooks/http';
import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';
import customStyle from './customStyle';
import {
  Button, ButtonIcon, Select, TextArea, WrapperLabel, DatePicker, Input,
} from '../../../../components/Forms';
import {
  Container,
  GridButtons,
  WrapperBtnInfo,
  WrapperBtnCancel,
  WrapperBtnSubmit,
} from './styles';

interface IOptions {
    value: string;
    label: string
}

const Section: React.FC = () => {
  const [educationAgents, setEducationAgents] = useState<IOptions[]>();
  const [educationPcd, setEducationPcd] = useState<IOptions[]>();
  const [cars, setCars] = useState<IOptions[]>();
  const formRef = useRef<FormHandles>(null);
  const { httpGet, httpPost } = useHttp();
  const { addToast } = useToast();
  const { user } = useAuth();
  const history = useHistory();
  const [btnLoading, setBtnLoading] = useState(false);

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 3) return;
    const users: any = [];
    const { status, data } = await httpGet(`/rcols/users/${inputValue}`);

    if (status === 200) {
      data.forEach((item: any) => {
        users.push({
          value: item.id,
          label: item.name,
        });
      });
    }

    callback(users);
  };

  const loadFleets = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 3) return;
    const fleets: any = [];
    const { status, data } = await httpGet(`/rcols/fleets/${inputValue}`);

    if (status === 200) {
      data.forEach((item: any) => {
        fleets.push({
          value: item.id,
          label: item.plate,
        });
      });
    }

    callback(fleets);
  };

  const handleChangeCars = useCallback((event: any) => {
    setCars(event);
  }, []);

  const handleChangeEducationAgents = useCallback((event: any) => {
    setEducationAgents(event);
  }, []);

  const handleChangeEducationPcd = useCallback((event: any) => {
    setEducationPcd(event);
  }, []);

  const handleSubmit = useCallback(async (payload: any) => {
    const _section = {
      ...payload,
      management_id: user?.allocation?.management_id,
      team: payload.team?.value,
      operation_date: payload?.operation_date,
      educationAgents_id: educationAgents ? educationAgents?.map((user) => user.label).join(',') : null,
      educationPcd_id: educationPcd ? educationPcd?.map((user) => user.label).join(',') : null,
      cars_id: cars ? cars?.map((vtr) => vtr.label).join(',') : null,
    };

    setBtnLoading(true);

    try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
              team: Yup.string().required('Equipe é obrigatório'),
              operation_date: Yup.date().required('Data da Operação é obrigatório'),
              educationAgents_id: Yup.string().typeError('Agente Educacional é obrigatório'),
              breathalyzers: Yup.string().required('Etilômetros é obrigatório'),
              educationPcd_id: Yup.string().typeError('Agentes de Pcd é obrigatório'),
              cars_id: Yup.string().typeError('Viaturas Ols é obrigatório'),
            });

            await schema.validate(_section, { abortEarly: false });

            const position = await getCurrentPosition();

            const { status, data } = await httpPost('reducols', {
              ..._section,
              current_lat: position.lat,
              current_lng: position.lng,
            });

            if (status === 200) {
              addToast({ title: 'Próximo passo, acesse o SEÇÃO II', type: 'warning' });
              setBtnLoading(false);
              history.push(`/reducol/${data.id}`);
            } else {
              addToast({ title: 'Não foi possível salvar!', type: 'error' });
              setBtnLoading(false);
              // history.push('/dashboard');
            }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setBtnLoading(false);
        const erros = getValidationError(err);
        if (erros.educationAgents_id) {
          await ModalDialog({
            title: 'Aviso!',
            content: erros.educationAgents_id,
            buttons: 'ok',
          });
        }
        if (erros.educationPcd_id) {
          await ModalDialog({
            title: 'Aviso!',
            content: erros.educationPcd_id,
            buttons: 'ok',
          });
        }
        if (erros.cars_id) {
          await ModalDialog({
            title: 'Aviso!',
            content: erros.cars_id,
            buttons: 'ok',
          });
        }
                formRef.current?.setErrors(erros);
      }
    }
  }, [educationAgents, educationPcd, cars, user, addToast, history, httpPost]);

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Equipe" xs={12} md={6}>
            <Select
              name="team"
              options={[
                { value: 'A', label: 'A' },
                { value: 'B', label: 'B' },
                { value: 'C', label: 'C' },
                { value: 'D', label: 'D' },
                { value: 'E', label: 'E' },
                { value: 'F', label: 'F' },
                { value: 'G', label: 'G' },
                { value: 'EXTRA I', label: 'EXTRA I' },
                { value: 'EXTRA II', label: 'EXTRA II' },
                { value: 'EXTRA III', label: 'EXTRA III' },
              ]}
            />
          </WrapperLabel>
          <WrapperLabel label="Data da Operação" xs={12} md={6}>
            <DatePicker
              name="operation_date"
              maxDate={new Date()}
            />
          </WrapperLabel>
          <WrapperLabel label="Agentes Educação / Apoio" xs={12} md={6}>
            <div style={{ width: '100%' }}>
              <AsyncSelect
                placeholder="Nome"
                noOptionsMessage={() => 'Sem registos'}
                cacheOptions
                isClearable
                loadOptions={loadOptions}
                onChange={handleChangeEducationAgents}
                value={educationAgents}
                styles={customStyle}
                isMulti
              />
            </div>
          </WrapperLabel>
          <WrapperLabel label="Agentes de PCD" xs={12} md={6}>
            <div style={{ width: '100%' }}>
              <AsyncSelect
                placeholder="Nome"
                noOptionsMessage={() => 'Sem registos'}
                cacheOptions
                isClearable
                loadOptions={loadOptions}
                onChange={handleChangeEducationPcd}
                value={educationPcd}
                styles={customStyle}
                isMulti
              />
            </div>
          </WrapperLabel>
          <WrapperLabel label="Etilômetros(s) Utilizados(s)" xs={12} md={6}>
            <Input name="breathalyzers" maxLength={4} />
          </WrapperLabel>
          <WrapperLabel label="Viaturas Utilizadas" xs={12} md={6}>
            <div style={{ width: '100%' }}>
              <AsyncSelect
                placeholder="Placa"
                noOptionsMessage={() => 'Sem registos'}
                cacheOptions
                isClearable
                loadOptions={loadFleets}
                onChange={handleChangeCars}
                value={cars}
                styles={customStyle}
                isMulti
              />
            </div>
          </WrapperLabel>
          <WrapperLabel label="Contato que Recepcionou a Ação" xs={12}>
            <Input name="contact_received" maxLength={60} />
          </WrapperLabel>
          <WrapperLabel label="Alterações de Efetivo" xs={12}>
            <TextArea
              row={4}
              name="changes_staff"
              placeholder="Atrasos, Faltas e/ou outras alterações relevantes. Não havendo, inserir o texto SEM ALTERAÇÕES."
            />
          </WrapperLabel>
          <WrapperLabel label="Alterações com Materiais" xs={12}>
            <TextArea
              row={4}
              name="changes_general"
              placeholder="Inserir possíveis alterações com a materiais utilizada. Não havendo, inserir SEM ALTERAÇÕES."
            />
          </WrapperLabel>
          <WrapperLabel label="Ocorrência / Observação" xs={12}>
            <TextArea
              row={4}
              name="occurrence_observation"
              placeholder="Inserir dados de ocorrência e/ou observações referentes às ações desenvolvidas. Não havendo, inserir SEM ALTERAÇÕES."
            />
          </WrapperLabel>
        </Grid>
        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
            >
              <InfoIcon />
            </ButtonIcon>
          </WrapperBtnInfo>
          <WrapperBtnCancel>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </WrapperBtnCancel>
          <WrapperBtnSubmit>
            <Button
              type="submit"
              isLoading={btnLoading}
            >
              Salvar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>
      </UForm>
      <ModalFactory />
    </Container>
  );
};

export default Section;
