import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding: 8px 16px;
  margin-bottom: 8px;
  border: 1px solid var(--color-border);
  border-radius: 4px;

  cursor: pointer;
  transition: transform 0.2s;
  &:hover{
     transform: translateX(4px);
  }
`;

export const WrapperContent = styled.div`
  widows: 100%;
  display: flex;

`;

export const WrapperLink = styled.span`
  display: flex;
  align-items: center;

  a {
    margin-left: 8px;
    color: var(--color-primary);
    text-decoration: none;

    &:hover{
      color: var(--color-primary-h);
      text-decoration: underline;
    }
  }
  svg {
    margin-right: 4px;
  }
`;
