import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useAuth } from 'hooks/auth';
import { formatDateTime } from 'utils';
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';
import Layout from '../../../components/Layout';
import Flex, { Grid } from '../../../components/Flex';
import {
  WrapperLabel, Select, TextArea, Button,
  Input,
} from '../../../components/Forms';
import { SupportIcon } from '../../../styles/icons';
import {
  Container, Title, Content,
} from './styles';
import { IMaintenance } from '../../../interfaces';

const Process: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    httpGet, httpPost, loading,
  } = useHttp();
  const { user } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [maintenance, setMaintenance] = useState({} as IMaintenance);

  const [statusProblem] = useState([
    { value: 'NOLA', label: 'NOLA' },
    { value: 'JJJ', label: 'JJJ' },
    { value: 'UYHBNTYG', label: 'UYHBNTYG' },
    { value: 'OUTROS', label: 'OUTROS' }]);

  useEffect(() => {
    async function load(): Promise<void> {
      const { status, data } = await httpGet(`/fleetMechanicals/${id}`);
      if (status !== 200) {
        history.push('/companys');
        return;
      }
      setMaintenance(data);
    }

    load();
  }, [history, httpGet, id]);

  const handleSubmit = useCallback(async (data: any) => {
    const answer = {
      ...data,
      mechanicalof: data.mechanicalof.value,
      operator_id: user?.id,
      driver: data?.driver,
      trailer_plate: data?.trailer_plate,
      id: maintenance.id,
    };

    const result: any = await httpPost('/fleetMechanicals/process2', answer);
    const { status } = result;

    if (status === 200) {
      addToast({ title: 'Atendimento encaminhado com sucesso.' });
      history.push('/companys');
    }
  }, [addToast, history, httpPost, maintenance, user]);

  const breadcrumbs = {
    icon: SupportIcon,
    links: [{ path: '', title: 'Processar atendimento' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <Title className="first">
            Dados informados para atendimento
          </Title>
          <Grid container spacing={1}>
            <Flex title="Viatura" xs={12} sm={4} textAutoSize>
              {maintenance?.fleet?.plate}
            </Flex>
            <Flex title="Gerência" xs={12} sm={4} textAutoSize>
              {maintenance?.managements?.initials}
              {maintenance?.managements?.initials && ` - ${maintenance?.managements?.name}`}
            </Flex>
            <Flex title="Solicitado por" xs={12} sm={4} textAutoSize>
              {`${maintenance.user?.first_name}`}
            </Flex>
            <Flex title="Contato" xs={12} sm={4} textAutoSize>
              {`${maintenance.user?.phone}`}
            </Flex>
            <Flex title="Tipo" xs={12} sm={4} md={4} textAutoSize>
              {maintenance.order}
            </Flex>
            <Flex title="Problema" xs={12} sm={4} md={4} textAutoSize>
              {maintenance.problem}
            </Flex>
            <Flex title="Descrição" xs={12}>
              {maintenance.description || 'não informado'}
            </Flex>
            <Flex title="Solicitado em" xs={12}>
              {formatDateTime(maintenance.created_at)}
            </Flex>
          </Grid>
          <Title>
            Procedimentos
          </Title>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              {maintenance.order === 'REBOQUE'
                && (
                <>
                  <WrapperLabel label="Motorista" xs={12} sm={12} md={6}>
                    <Input name="driver" />
                  </WrapperLabel>
                  <WrapperLabel label="Placa" xs={12} sm={12} md={6}>
                    <Input name="trailer_plate" />
                  </WrapperLabel>
                </>
                )}
              <WrapperLabel label="Oficina" xs={12}>
                <div style={{ width: '100%' }}>
                  <Select
                    name="mechanicalof"
                    options={statusProblem}
                  />
                </div>
              </WrapperLabel>
              <WrapperLabel label="Observação" xs={12}>
                <TextArea name="note" row={2} />
              </WrapperLabel>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit">Salvar</Button>
            </Grid>
          </UForm>
        </Content>
      </Container>
    </Layout>
  );
};

export default Process;
