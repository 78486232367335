import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';

import Grid from '@material-ui/core/Grid';
import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../hooks/http';

/** componentes */
import {
  WrapperLabel, Button, Input, TextArea,
} from '../../../components/Forms';

import { Container } from './styles';

/** interface */
import { IService } from '../../../interfaces';

interface IStarted {
  service: IService | null;
  handleStatus(id:any):void;
}

const Started: React.FC<IStarted> = ({ service, handleStatus }) => {
  const formRef = useRef<FormHandles>(null);
  const { httpPost, loading } = useHttp();
  const handleSubmit = useCallback(async (payload: any) => {
    const _service = {
      ...payload,
      id: service?.id,
    };
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        description: Yup.string().required('a Alteração é obrigatória.'),
      });

      await schema.validate(_service, { abortEarly: false });

      const { status, data } = await httpPost('services/presence/missing', _service);
      if (status === 200) {
        handleStatus(data);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
      formRef.current?.setErrors(erros);
      }
    }
  }, [service]);// eslint-disable-line

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Agente" xs={12}>
            <Input name="agente" defaultValue={service?.user} readOnly />
          </WrapperLabel>
          <WrapperLabel label="Alterações" xs={12}>
            <TextArea name="description" row={4} />
          </WrapperLabel>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item xs={12}>
            <Button type="submit" isLoading={loading}>
              Comunicar falta
            </Button>
          </Grid>
        </Grid>
      </UForm>
    </Container>
  );
};

export default Started;
