import React, {
  useCallback, useRef, useState, useEffect,
} from 'react';
import { Form as UForm } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import { useHttp } from 'hooks/http';
import { useAuth } from 'hooks/auth';
import ModalFactory from 'react-modal-promise';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Layout from 'components/Layout';
import { Grid } from '../../../../components/Flex';
import { formatDateTime } from '../../../../utils';
import getValidationError from '../../../../utils/getValidationsErros';
import Modal from '../../../../components/ModalSimple';
import getCurrentPosition from '../../../../utils/getCurrentPosition';
import { IFleet } from '../../../../interfaces';

import {
  WrapperLabel,
  Button,
  ButtonIcon,
  InputHidden,
  Input,
  TextArea,
} from '../../../../components/Forms';
import { InfoIcon, WarningIcon, PoliceCarFill } from '../../../../styles/icons';
import {
  Container,
  Content,
  GridButtons,
  WrapperBtnInfo,
  WrapperBtnCancel,
  WrapperBtnSubmit,
  WrapperModal,
  Red,
} from './styles';

interface ICoord {
    coord?: {
        lat: number,
        lng: number
    }
}

interface IBdtd {
    id: string,
    plate: string,
    viatura: string,
    fleet: IFleet,
    dateFormatted: string,
    started_km: string,
}

interface IOption {
    value: string;
    label: string;
}

const Closed: React.FC<ICoord> = () => {
  const formRef = useRef<FormHandles>(null);
  const labelConfRef = useRef<HTMLLabelElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const history = useHistory();
  const { user } = useAuth();
  const [btnLoading, setBtnLoading] = useState(false);
  const [formData, setFormData] = useState<any>();
  const [disableBtnChecked, setDisableBtnChecked] = useState(false);
  const [bdtd, setBdtd] = useState<IBdtd>({} as IBdtd);
  const { httpGet, httpPut, loading } = useHttp();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    async function load(): Promise<any> {
      const { status, data } = await httpGet(`/bdtds/${id}`);
      if (status === 200) {
        const dateFormatted = formatDateTime(data.bdtd.started_at);
        const dataEx = { ...data.bdtd, dateFormatted };
        setBdtd(dataEx);
      } else {
        history.push('/dashboard');
      }
    }
    load();
  }, [httpGet, history, id]);

  const handleToRecord = useCallback(async (data: any) => {
        labelConfRef.current?.click();
        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
              started_km: Yup.number(),
              ended_km: Yup.number().typeError('Km final é obrigatório, somente números')
                .moreThan(Yup.ref('started_km'), 'Km final deve ser maior que o inicial'),
            });

            await schema.validate(data, { abortEarly: false });

            const bdtdSubmit = {
              id: data.id,
              ended_km: data.ended_km,
              ended_at: new Date(),
              management_id: user?.allocation?.management_id,
              note: data.note,
              active: false,
            };

            setBtnLoading(true);
            const position = await getCurrentPosition();

            const response: any = await httpPut(`bdtds/endsupervisor/${bdtdSubmit.id}`, {
              ...bdtdSubmit,
              latitude_retreat: position.lat,
              longitude_retreat: position.lng,
            });

            if (response.status === 200) {
              history.push('/sbdtds/supervisor');
            }

            if (response.status !== 200) {
              setBtnLoading(false);
            }
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
          }
        }

        setBtnLoading(false);
  }, [httpPut, user, history]);

  const handleSubmit = useCallback(async (dataSubmit: any) => {
    try {
            formRef.current?.setErrors({});

            const _dataSubmit = {
              ...dataSubmit,
              liters: dataSubmit.liters && dataSubmit.liters.map((e: any) => e.replace(',', '.')),
            };

            const schema = Yup.object().shape({
              ended_km: Yup.number().typeError('Km final é obrigatório, somente números')
                .moreThan(Yup.ref('started_km'), 'Km final deve ser maior que o inicial'),
            });

            await schema.validate(_dataSubmit, { abortEarly: false });

            await schema.validate(_dataSubmit, { abortEarly: false });
            setFormData(_dataSubmit);
            formRef.current?.setData({
              ..._dataSubmit,
              viatura: _dataSubmit.viatura,
              started_km: _dataSubmit.started_km,
              ended_km: _dataSubmit.ended_km,
              confirmed: [{ value: null, label: '' }],
            });

            labelConfRef.current?.click();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
      }
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableBtnChecked(event.target.checked);
  };

  const breadcrumbs = {
    icon: PoliceCarFill,
    links: [{ path: '', title: 'Bdtd Fechar - Fiscal' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <InputHidden name="id" defaultValue={bdtd?.id} />
              <WrapperLabel label="Viatura" xs={12} sm={6}>
                <Input
                  name="viatura"
                  defaultValue={bdtd?.fleet?.plate}
                  disabled
                />
              </WrapperLabel>
              <WrapperLabel label="Data/Hora Inicial" xs={12} sm={6}>
                <Input
                  name="started_at"
                  defaultValue={bdtd?.dateFormatted}
                  disabled
                />
              </WrapperLabel>
              <WrapperLabel label="Km Inicial" xs={12} sm={6}>
                <Input
                  name="started_km"
                  defaultValue={bdtd?.started_km}
                  disabled
                />
              </WrapperLabel>

              <WrapperLabel label="Km Final" xs={12} sm={6}>
                <Input name="ended_km" maxLength={11} />
              </WrapperLabel>

              <WrapperLabel label="Observação" xs={12}>
                <TextArea row={2} name="note" maxLength={50} />
              </WrapperLabel>
            </Grid>
            <GridButtons>
              <WrapperBtnInfo>
                <ButtonIcon
                  title="Instruções"
                >
                  <InfoIcon />
                </ButtonIcon>
              </WrapperBtnInfo>
              <WrapperBtnCancel>
                <Button
                  type="button"
                  onClick={() => { history.push('/dashboard'); }}
                >
                  Cancelar
                </Button>
              </WrapperBtnCancel>
              <WrapperBtnSubmit>
                <Button
                  type="button"
                  onClick={() => { formRef.current?.submitForm(); }}
                  isLoading={btnLoading}
                  style={{ background: '#CC0001' }}
                >
                  Encerrar
                </Button>
              </WrapperBtnSubmit>
            </GridButtons>
          </UForm>
        </Content>
        <label ref={labelRef} htmlFor="modal-instruction" />
        <Modal id="modal-instruction" width={520} height="auto">
          <h1>Instruções</h1><br />
          <p>
            Para fechar o BDTD você precisa informar os seguintes dados:
          </p><br />
          <p>
            <b>Km Final</b> e em caso de abastecimento:<b>Km abastecimento, Tipo, Posto e Litros.</b>
          </p><br />
        </Modal>
        <WrapperModal>
          <label ref={labelConfRef} htmlFor="modal-conf" />
          <Modal id="modal-conf" width={520} height="auto">
            <h2>
              <WarningIcon /> Confirma os dados da viatura?
            </h2>
            <p>Viatura: <b>{formData?.viatura}</b></p>
            <p>Km Início: <b>{formData?.started_km}</b></p>
            <p>Km Final: <b>{formData?.ended_km}</b></p>
            <FormControlLabel
              label={<Red>Confirmo as informações acima.</Red>}
              control={(
                <Switch
                  onChange={handleChangeChecked}
                  color="primary"
                  name="checked"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                            )}
            />
            <span>
              <Button onClick={() => labelConfRef.current?.click()}>
                Cancelar
              </Button>
              <Button
                onClick={() => handleToRecord(formData)}
                isLoading={btnLoading}
                disableForce={!disableBtnChecked}
              >
                Confirmar
              </Button>
            </span>

          </Modal>
          <ModalFactory />
        </WrapperModal>
      </Container>
    </Layout>
  );
};

export default Closed;
