const measurement = [
  { value: 'Quilograma(kg)', label: 'Quilograma(kg)' },
  { value: 'Grama(g)', label: 'Grama(g)' },
  { value: 'Miligrama(mg)', label: 'Miligrama(mg)' },
  { value: 'Litro(L)', label: 'Litro(L)' },
  { value: 'Mililitro(ml)', label: 'Mililitro(ml)' },
  { value: 'Sacolé(s)', label: 'Sacolé(s)' },
  { value: 'Pino(s)', label: 'Pino(s)' },
  { value: 'Unidade(s)', label: 'Unidade(s)' },
  { value: 'Pedra(s)', label: 'Pedra(s)' },
  { value: 'Sacos(s)', label: 'Sacos(s)' },
  { value: 'Trouxinha(s)', label: 'Trouxinha(s)' },
  { value: 'Amarrado(s)', label: 'Amarrado(s)' },
  { value: 'Garrafa(s)', label: 'Garrafa(s)' },
  { value: 'Frasco(s)', label: 'Frasco(s)' },
  { value: 'Caixa(s)', label: 'Caixa(s)' },
  { value: 'Tablete(s)', label: 'Tablete(s)' },
];

export default measurement;

