import React, {
  useRef, useCallback, useEffect, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { formatISO, isAfter } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../hooks/http';

/** componentes */
import {
  WrapperLabel, Select, Button, Input, TextArea, DatePicker,
} from '../../../components/Forms';

import { Container } from './styles';

/** interface */
import { IService } from '../../../interfaces';

interface IStarted {
  service: IService | null;
  sectors: {value: number, label: string}[] | null;
  handleStatus(id:any):void;
}

const Started: React.FC<IStarted> = ({ service, sectors, handleStatus }) => {
  const formRef = useRef<FormHandles>(null);
  const { httpPost, loading } = useHttp();
  const [sectorFiltered, setSectorFiltered] = useState<any>();

  useEffect(() => {
    const sectorFiltered = sectors?.filter((sector:any) => sector.value !== service?.sector_id);
    setSectorFiltered(sectorFiltered);
    formRef.current?.setFieldValue('started_at', formatISO(new Date()));
  }, [sectors, service]);

  const validateDate = useCallback((payload: any) => {
    if (isAfter(new Date(payload), new Date())) {
      return false;
    }
    return true;
  }, []);

  const handleSubmit = useCallback(async (payload: any) => {
    // eslint-disable-next-line no-underscore-dangle
    const _service = {
      ...payload,
      id: service?.id,
      sector_id: payload?.sector_id?.value,
    };
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        sector_id: Yup.string().ensure().required('o Setor é obrigatório'),
        started_at: Yup.string()
          .test('is-valid', 'a Data e hora não pode ser futura.', (value) => validateDate(value)),
        description: Yup.string().required('a Alteração é obrigatória'),
      });

      await schema.validate(_service, { abortEarly: false });

      const { status, data } = await httpPost('services/change-sector', _service);
      if (status === 200) {
        handleStatus(data);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
      formRef.current?.setErrors(erros);
      }
    }
  }, [handleStatus, httpPost, service, validateDate]);

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Agente" xs={12}>
            <Input name="agente" defaultValue={`${service?.user} (${service?.sector})`} readOnly />
          </WrapperLabel>

          <WrapperLabel label="Data e hora" xs={6}>
            <DatePicker
              name="started_at"
              timeInputLabel="Hora:"
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeInput
              minDate={service?.started_at && new Date(service?.started_at)}
              maxDate={service?.ended_at && new Date(service?.ended_at)}
            />
          </WrapperLabel>
          <WrapperLabel label="Novo Setor" xs={6}>
            <Select name="sector_id" options={sectorFiltered || undefined} />
          </WrapperLabel>
          <WrapperLabel label="Alterações" xs={12}>
            <TextArea name="description" row={3} />
          </WrapperLabel>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item xs={12}>
            <Button type="submit" isLoading={loading}>Trocar de Setor</Button>
          </Grid>
        </Grid>
      </UForm>
    </Container>
  );
};

export default Started;
