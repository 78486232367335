import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Content = styled.div`
    display: flex;
    width: 100%;

    flex-direction: column;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 10px
`;

export const GridButtons = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
  grid-template-columns: 40px 1fr 1fr 1fr;
  grid-template-rows: 56px;
  grid-template-areas:
    'btn-inf space btn-cancel btn-submit';


  @media (max-width: 767.98px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 56px 56px;
    grid-template-areas:
      'btn-cancel btn-submit'
      'btn-inf space';
  }
`;

export const Title = styled.div`
  border-top: 1px solid var(--color-border);
  margin: 0 -16px;
  margin-top: 8px;
  padding: 8px;
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  h5{
    font-size: 1em;
    font-weight: 500;
  }
  span{
    display: flex;
    align-items: center;
    font-weight: 700;
  }
`;

export const WrapperBtnInfo = styled.div`
  grid-area: btn-inf;
  padding-top: 16px;
`;

export const WrapperBtnCancel = styled.div`
  grid-area: btn-cancel;
`;

export const WrapperBtnSubmit = styled.div`
  grid-area: btn-submit;
`;

export const WrapperAccordion = styled.div`
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 8px;
  margin-bottom: 8px;

  .MuiAccordionDetails-root{
    padding: 8px 0;
  }
`;