import React from 'react';
import { format } from 'date-fns';
/** components */
import Flex, { Grid } from '../../../../components/Flex';

import { Container } from './styles';
import { IBlocking } from '../../../../interfaces';

const Card: React.FC<{blocking: IBlocking}> = ({ blocking }) => (
  <Container>
    <Grid container spacing={1}>
      <Flex title="Motivo" textAutoSize xs={12} md={6} sm={12}>
        {blocking.reason}
      </Flex>

      <Flex title="Data" xs={12} md={3} sm={6}>
        {format(new Date(blocking.created_at), 'dd/MM/yyyy')}
      </Flex>
      <Flex title="Atualizado" xs={12} md={3} sm={6}>
        {format(new Date(blocking.updated_at), 'dd/MM/yyyy')}
      </Flex>
    </Grid>
  </Container>
);

export default Card;
