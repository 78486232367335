import styled from 'styled-components';

interface IContainerProps {
  color: string
}

export const Container = styled.div<IContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 600px){
        width: 96%;
    }
    width: 10rem;
    height: 150%;
    margin: 10px 10px;
    background-color: ${props => props.color};
    border: 1px solid;
    border-radius: 7px;

    padding: 10px 20px;

    span {
        color: white;
    }

    h1 {
        color: white;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
    color: #212529;
    .modal__inner{
        background: #000;
    }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    svg {
      flex-shrink: 0;
      font-size: 1.5em;
    }
  }
  p{
    margin-bottom: 8px;
  }
  b{
    font-size: 1.1rem;
  }
  span{
    display: flex;
    flex-direction: column;
    justify-content: center;
    b {
      background:  #2C2F38;
      padding: 6px;
      margin-bottom: 4px;
    }
  }
`;