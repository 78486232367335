import React, {
  useEffect, useCallback, useState,
} from 'react';
import { useHistory, Link } from 'react-router-dom';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import session from '../../../utils/session';
import {
  UserIcon,
  BarIcon,
  KeyIcon,
  PowerOffIcon,
  WarningIcon,
} from '../../../styles/icons';

/** hooks */
import { useAuth } from '../../../hooks/auth';
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** components */
import InitialIcon from '../InitialsIcon';

import {
  Container,
  WrapperBreadcrumb,
  Breadcrumb,
  WrapperUpdate,
  WrapperProfile,
  WrapperMenu,
  WrapperMenuItem,
} from './styles';

import { IBreadcrumb } from '../../../interfaces';

interface HeaderProps{
  title?: string;
  breadcrumbs?: IBreadcrumb;
  handleToggleSidebar(value: true): void;
}

const Header: React.FC<HeaderProps> = ({ breadcrumbs, handleToggleSidebar }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { httpGet } = useHttp();
  const Icon = breadcrumbs?.icon;
  const {
    signOut, refreshToken, user,
  } = useAuth();
  const { addToast } = useToast();
  const [showUpdate, setShowUpdate] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>):void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = ():void => {
    setAnchorEl(null);
  };

  const goToLink = (link: string):void => {
    history.push(link);
  };

  const handleSignOut = useCallback(() => {
    history.push('/signin');
    addToast({
      type: 'success',
      title: 'Logout realizado com sucesso.',
    });
    signOut();
  }, [history, signOut, addToast]);

  const handleCheckVersion = useCallback(() => {
    if (session.get('spa_version') !== process.env.REACT_APP_VERSION) {
      setShowUpdate(true);
    } else {
      setShowUpdate(false);
    }
  }, []);

  const handleUpdate = useCallback(async () => {
    const { status, data } = await httpGet('settings');
    if (status === 200) {
      session.set('spa_version', data.version);
    }
    window.location.reload();
  }, [httpGet]);

  useEffect(() => {
    handleCheckVersion();
  }, [handleCheckVersion]);

  useEffect(() => {
    const refresh = setInterval(() => {
      refreshToken();
      handleCheckVersion();
    }, 60000 * 10); // 60000 = 1 minuto

    return () => {
      clearInterval(refresh);
    };
  }, [handleCheckVersion, refreshToken]);

  return (
    <Container>
      <button
        type="button"
        className="btn-toggle"
        onClick={() => handleToggleSidebar(true)}
      >
        <BarIcon />
      </button>

      <WrapperBreadcrumb>
        <Breadcrumb>
          {Icon && <Icon size={24} />}
          <ul>
            { breadcrumbs?.links && breadcrumbs.links.map((link) => (
              <li key={`a${link.title}`}>
                {link.path ? (<Link className="breadcrumb-link" to={link.path}>{link.title}</Link>) : <>{link.title}</>}
              </li>
            ))}
          </ul>
        </Breadcrumb>

        {showUpdate && (
          <WrapperUpdate onClick={handleUpdate}>
            <WarningIcon /> Click para Atualizar
          </WrapperUpdate>
        )}

        <WrapperProfile>
          <button type="button" aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            <InitialIcon name={user?.name || ''} />
          </button>

          <WrapperMenu>

            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              style={{ top: 40, left: -16 }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  goToLink('/profile');
                }}
              >
                <WrapperMenuItem>
                  <UserIcon /> Meus dados
                </WrapperMenuItem>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  goToLink('/password');
                }}
              >
                <WrapperMenuItem>
                  <KeyIcon /> Alterar senha
                </WrapperMenuItem>
              </MenuItem>

              <MenuItem onClick={handleSignOut}>
                <WrapperMenuItem>
                  <PowerOffIcon /> Sair
                </WrapperMenuItem>
              </MenuItem>
            </Menu>
          </WrapperMenu>

        </WrapperProfile>
      </WrapperBreadcrumb>
    </Container>
  );
};

export default Header;
