/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useRef, useCallback, useState,
} from 'react';
import * as Yup from 'yup';
import { useToast } from 'hooks/toast';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { useHttp } from '../../hooks/http';
import getValidationError from '../../utils/getValidationsErros';
import {
  WrapperLabel, Select, TextArea, Button, ButtonIcon,
} from '../../components/Forms';
/** componentes */
import Layout from '../../components/Layout';
import { InfoIcon, SupportIcon } from '../../styles/icons';

import {
  Container, Content, GridButtons, WrapperBtnInfo,
  WrapperBtnCancel, WrapperBtnSubmit,
} from './styles';

const SupportForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const { httpGet, httpPost, loading } = useHttp();
  const [btnLoading, setBtnLoading] = useState(false);
  const [managements, setManagements] = useState([]);

  useEffect(() => {
    async function loadManagements(): Promise<void> {
      const { status, data } = await httpGet('managements/all');
      if (status === 200) {
        const stations = data
          .map((station: any) => ({
            value: station.id,
            label: `${station.initials} - ${station.name}`,
          }));

        setManagements(stations);
      }
    }
    loadManagements();
    }, []);// eslint-disable-line

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSubmit = useCallback(async (data: any) => {
    try {
      setBtnLoading(true);
      // eslint-disable-next-line no-underscore-dangle
      const _support = {
        ...data,
        management_id: data?.management_id.value,
        problem: data?.problem.value,
      };

      const schema = Yup.object().shape({
        problem: Yup.string().required('Problema é obrigatório'),
        description: Yup.string().required('Descreva um breve relato.'),
      });

      formRef.current?.setErrors({});
      await schema.validate(_support, { abortEarly: false });

      const { status } = await httpPost('supports', {
        ..._support,
      });
      setBtnLoading(false);
      if (status === 201) {
        addToast({ title: 'Salvo com sucesso!', type: 'success' });
        history.push('/support');
      } else {
        addToast({ title: 'Não foi possível gravar!', type: 'error' });
        history.push('/dashboard');
      }
    } catch (err) {
      setBtnLoading(false);
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
          formRef.current?.setErrors(erros);
      }
    }
  }, [addToast, history, httpPost]);

  const breadcrumbs = {
    icon: SupportIcon,
    links: [{ path: '', title: 'Suporte' }],
  };

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <WrapperLabel label="Selecione Gerência" xs={12} sm={6} md={6}>
                <Select name="management_id" options={managements} />
              </WrapperLabel>
              <WrapperLabel label="Problema" xs={12} md={6} sm={6}>
                <Select
                  name="problem"
                  options={[
                    { value: 'BODYCAM', label: 'BODYCAM' },
                    { value: 'LINK DE DADOS', label: 'LINK DE DADOS' },
                    { value: 'DOCKSTATION', label: 'DOCKSTATION' },
                    { value: 'SOLICITAÇÃO DE IMAGEM', label: 'SOLICITAÇÃO DE IMAGEM' },
                    { value: 'REQUISIÇÃO MODO EVIDÊNCIA', label: 'REQUISIÇÃO MODO EVIDÊNCIA' },
                    { value: 'OUTROS', label: 'OUTROS' },
                  ]}
                />
              </WrapperLabel>
              <WrapperLabel label="Descrição" xs={12}>
                <TextArea row={4} name="description" />
              </WrapperLabel>

            </Grid>
            <GridButtons>
              <WrapperBtnInfo>
                <ButtonIcon
                  title="Instruções"
                  onClick={() => labelRef.current?.click()}
                >
                  <InfoIcon />
                </ButtonIcon>
              </WrapperBtnInfo>
              <WrapperBtnCancel>
                <Button
                  type="button"
                  onClick={() => { history.push('/dashboard'); }}
                >
                  Cancelar
                </Button>
              </WrapperBtnCancel>
              <WrapperBtnSubmit>
                <Button
                  type="submit"
                  isLoading={btnLoading}
                >
                  Enviar
                </Button>
              </WrapperBtnSubmit>
            </GridButtons>
          </UForm>
        </Content>
      </Container>
    </Layout>
  );
};

export default SupportForm;
