import React, {
  useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { Grid } from '@material-ui/core';
import { FaFile } from 'react-icons/fa';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import ReturnCopyPrint from 'components/Headers/ReturnCopyPrint';
import stringOptions from 'utils/stringOptions';
import { useHttp } from '../../../hooks/http';
import Layout from '../../../components/Layout';
import {
  ICarInvolved, ICivilPolice, IInvolved, IOccurrence, ISeizures,
} from '../../../interfaces';
import ImgOSP from '../../../assets/seg_presente.png';
import { Number, Container } from '../styles';
import {
  Caption, Description, Img, Subchapter, WrapperAddress,
} from './styles';

const Form: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { id2 } = useParams<{ id2: string }>();
  const { httpGet } = useHttp();
  const [isloadding, setIsloadding] = useState(true);
  const [occurrence, setOccurrence] = useState({} as IOccurrence);
  const [seizures, setSeizure] = useState<ISeizures[]>();
  const [civilPolices, setcivilPolices] = useState<ICivilPolice[]>();
  const [involveds, setInvolveds] = useState<IInvolved[]>();
  const [carInvolveds, setCarInvolveds] = useState<ICarInvolved[]>();
  const printDocument = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function loadOccurrence(): Promise<void> {
      const [
        responseDeital,
        responseSeizure,
        responsecivilPolices,
        responseInvolveds,
        responseCarInvolveds,
      ] = await Promise.all([
        httpGet(`/bopms/detail/${id}`),
        httpGet(`/seizures/list/${id}`),
        httpGet(`/civilPolices/list/${id}`),
        httpGet(`/involveds/list/${id}`),
        httpGet(`/involvedCars/list/${id}`),
      ]);

      setOccurrence(responseDeital.data);
      setSeizure(responseSeizure.data);
      setcivilPolices(responsecivilPolices.data);
      setInvolveds(responseInvolveds.data);
      setCarInvolveds(responseCarInvolveds.data);
      setIsloadding(false);
    }
    loadOccurrence();
  }, [httpGet, id]);

  const handleToPDF = useReactToPrint({
    content: () => printDocument.current,
    onPrintError: (error) => Error(error),
    pageStyle: `
    @page { size: A4; margin-bottom: 50px;}
    @media print { 
      body { 
        font-size: 11px !important;
        -webkit-print-color-adjust: exact; 
        padding-top: 5px !important;
        padding-right: 10px !important;
        padding-left: 5px !important;
      } 
      .pagebreak {
        page-break-before: always;
      }
    }
    @media all {
      .pagebreak {
        display: none;
      }
    }`,
    removeAfterPrint: true,
  });

  const breadcrumbs = {
    icon: FaFile,
    links: [
      { path: '/RO/list', title: 'RO' },
      { path: '', title: 'Detalhe' },
    ],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <ReturnCopyPrint
          handleReturn={() => history.push(id2 ? '/RO/list-fisc' : '/RO/list')}
          hiddenButtonCopy
          handleCopy={() => handleToPDF()}
          handlePrint={() => handleToPDF()}
        >
          <Number>{occurrence.type_of_registration === 'RI' ? 'RI-SP' : 'RO-SP'}: {`${occurrence.bopm_number}.${occurrence.management_id}.${occurrence.year}`}</Number>
        </ReturnCopyPrint>
        {isloadding ? <b>loading...</b> : (
          <WrapperAddress ref={printDocument}>
            <Grid container spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={2} md={2}>
                  <Caption><Img src={ImgOSP} /></Caption>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Caption>GOVERNO DO ESTADO DO RIO DE JANEIRO</Caption>
                  <Caption>SECRETÁRIA DE ESTADO DE GOVERNO - SEGOV</Caption>
                  <Caption>OPERAÇÃO SEGURANÇA PRESENTE - OSP</Caption>
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <Caption><QRCode size={96} value={occurrence.id} /></Caption>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Caption>
                  <p>
                    REGISTRO {occurrence.type_of_registration === 'RI'
                    ? 'INTERNO - RI' : 'DE OCORRÊNCIA - RO'}
                    -SP Nº {`${occurrence.bopm_number}.${occurrence.management_id}.${occurrence.year}`}
                  </p>
                </Caption>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <br />
                  <Description>
                    <span>
                      {occurrence.type_of_registration === 'RI' ? 'REGISTRO INTERNO: ' : 'REGISTRO: '}
                    </span>
                    {`${occurrence?.type_of_registration} ${occurrence.type_of_registration === 'RI'
                      ? [occurrence.id?.toUpperCase().split('-', 1)]
                      : `${occurrence?.registration_number} - ${occurrence?.dp}`}`}
                  </Description>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <Description>
                    <span>DATA E HORA DO REGISTRO: </span>
                    {occurrence.created_at}
                  </Description>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <Description>
                    <span>BASE OSP: </span>
                    {occurrence.base}
                  </Description>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <Description>
                    <span>SETOR(ES): </span>
                    { occurrence.sector
                    && stringOptions(occurrence.sector)
                      .map((s: any) => (<>{s.label} / </>))}
                  </Description>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  <Description>
                    <span>GUARNIÇÃO: </span>
                    { occurrence.teamService
                     && occurrence.teamService.map((t: any) => (<>{t.label} / </>))}
                  </Description>
                  </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Subchapter><span>OCORRÊNCIA</span></Subchapter>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Description><span>REGISTRO: </span>{occurrence.type_of_warrant}</Description>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Description><span>{occurrence.type_of_registration === 'RI' ? 'DATA E HORA DO FATO: ' : 'INÍCIO: '}</span>{occurrence.started_date_at} - {occurrence.started_time_at}</Description>
                </Grid>
                {occurrence.type_of_registration !== 'RI'
               && (
               <Grid item xs={12} sm={12} md={12}>
                 <Description>
                   <span>TÉRMINO: </span>
                   {occurrence.finish_date_at} - {occurrence.finish_time_at}
                 </Description>
               </Grid>
               )}
                <Grid item xs={12} sm={12} md={12}>
                  <Description><span>LOCAL: </span>{occurrence.street}, {occurrence.number}
                    {`, ${occurrence.complement}`
                     && occurrence.complement} - {occurrence.neighborhood} - {occurrence.city}
                  </Description>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Description>
                    <span>AMBIENTE ESCOLAR: </span>
                    { occurrence.school_environment === true ? 'Sim' : 'Não' }
                  </Description>
                </Grid>
              </Grid>
              { occurrence.type_of_registration !== 'RI' && 
              <>
              <Grid item xs={12} sm={12} md={12}>
                <Subchapter>
                  <span>ENVOLVIDO(S)</span>
                </Subchapter>
                <Grid container>
                  { involveds ? involveds.map((involv: any) => (
                    <>
                      <Grid item xs={12} sm={6} md={6}>
                        <Description><p>{involv?.qualified} - {involv?.name}</p></Description>
                        <Description><b>Envolvimento:</b> {involv?.type_involvement}</Description>
                        <Description><b>Mãe:</b> {involv?.mather}</Description>
                        <Description><b>Nasc:</b> {involv?.birthday}</Description>
                        <Description><b>CPF:</b> {involv?.document ? involv?.document : 'NÃO INFORMADO'}</Description>
                        <Description><b>RG:</b> {involv?.document_secondary ? involv?.document_secondary : 'NÃO INFORMADO'}</Description>
                        <Description><b>Situação:</b> {involv?.situation}</Description>
                        <br />
                      </Grid>
                    </>
                  )) : (<Description>NÃO HOUVE</Description>)}
                </Grid>
              </Grid>
              </>}

              { occurrence.type_of_registration !== 'RI' && 
              <>
              <Grid item xs={12} sm={12} md={12}>
                <Subchapter>
                  <span>AUTORIDADE(S)</span>
                </Subchapter>
                <Grid item xs={12} sm={12} md={12}>
                  { civilPolices ? civilPolices.map((polices: any) => (
                    <Description><span>{polices?.name}</span>{polices?.document && ` - [ ${polices?.document} ]`} - {polices?.office_dp} - {polices?.initials}</Description>
                  )) : (<Description>NÃO HOUVE</Description>)}
                </Grid>
              </Grid>
              </>}

              { occurrence.type_of_registration !== 'RI' && 
              <>
              <Grid item xs={12} sm={12} md={12}>
                <Subchapter>
                  <span>VEÍCULO(S) ENVOLVIDO(S)</span>
                </Subchapter>
                <Grid container>
                  { carInvolveds ? carInvolveds.map((cars: any) => (
                    <>
                      <Grid item xs={12} sm={6} md={6}>
                        <Description><span>{cars?.brand} - {cars?.model}</span></Description>
                        {cars?.plate && <Description><b>Placa: </b> {cars?.plate} </Description>}
                        {cars?.chassi && <Description><b>Chassi: </b> {cars?.chassi} </Description>}
                        <Description><b>Cor: </b> {cars?.color} </Description>
                        <Description><b>Ano: </b> {cars?.year} </Description>
                        {cars?.motivation && (
                        <Description>
                          <b>Envolvimento: </b> {cars?.motivation}
                        </Description>
)}
                        {cars?.conductor && (
                        <Description>
                          <b>Condutor: </b> {cars?.conductor}
                        </Description>
)}
                        {cars?.crlv && (
                        <Description>
                          <b>CRLV: </b> {cars?.crlv}
                        </Description>
)}
                        {cars?.cnh && (
                        <Description>
                          <b>CNH: </b> {cars?.cnh}
                        </Description>
)}
                        {cars?.category && (
                        <Description>
                          <b>Categoria: </b> {cars?.category}
                        </Description>
)}
                        {cars?.uf && (
                        <Description>
                          <b>UF: </b> {cars?.uf}
                        </Description>
)}
                        {cars?.infringement && (
                        <Description>
                          <b>Infração: </b> {cars?.infringement}
                        </Description>
)}
                        <br />
                      </Grid>
                    </>
                  )) : (<Description>NÃO HOUVE</Description>)}
                </Grid>
              </Grid>
              </>}

              { occurrence.type_of_registration !== 'RI' && 
              <>
              <Grid item xs={12} sm={12} md={12}>
                <Subchapter>
                  <span>APREENSÕES:</span>
                </Subchapter>
                <Grid container>
                  { seizures ? seizures.map((seizure: any) => (
                    <>
                      {seizure?.seizure_type === 'Veículos' && (
                      <>

                        <Grid item xs={12} sm={6} md={6}>
                          <Description><span>{seizure?.seizure_type}</span></Description>
                          {seizure?.plate ? (
                            <Description><b>Placa:</b> {seizure?.plate}</Description>
                     ) : (
                       <Description><b>Chassi:</b> {seizure?.serial}</Description>
                     )}
                          <Description><b>Marca:</b> {seizure?.brand} </Description>
                          <Description><b>Modelo:</b> {seizure?.model} </Description>
                          <Description><b>Ano:</b> {seizure?.year} </Description>
                          <br />
                        </Grid>
                      </>
                 )}
                      {seizure.seizure_type === 'Entorpecentes' && (
                      <>
                        <Grid item xs={12} sm={6} md={6}>
                          <Description><span>{seizure?.seizure_type}</span></Description>
                          <Description><b>Entorpecente:</b> {seizure.type} </Description>
                          <Description><b>Quantidade:</b> {seizure.amount} </Description>
                          <Description><b>Medida:</b> {seizure.measure} </Description>
                          <Description><b>Destino:</b> {seizure.destiny} </Description>
                          <br />
                        </Grid>
                      </>
                      )}
                      {seizure.seizure_type === 'Munições' && (
                      <>
                        <Grid item xs={12} sm={6} md={6}>
                          <Description><span>{seizure?.seizure_type}</span></Description>
                          <Description><b>Quantidade:</b> {seizure.amount} </Description>
                          <Description><b>Calibre:</b> {seizure.caliber} </Description>
                          <Description><b>Marca:</b> {seizure?.brand} </Description>
                          <br />
                        </Grid>
                      </>
                      )}
                      {seizure.seizure_type === 'Armas' && (
                      <>
                        <Grid item xs={12} sm={6} md={6}>
                          <Description><span>{seizure?.seizure_type}</span></Description>
                          <Description><b>Marca:</b> {seizure.brand} </Description>
                          <Description><b>Modelo:</b> {seizure.model} </Description>
                          <Description><b>Calibre:</b> {seizure.caliber} </Description>
                          <Description><b>Tipo:</b> {seizure.type} </Description>
                          <br />
                        </Grid>
                      </>
                      )}

                      {seizure.seizure_type === 'Materiais' && (
                      <>
                        <Grid item xs={12} sm={6} md={6}>
                          <Description><span>{seizure?.seizure_type}</span></Description>
                          <Description><b>Quantidade:</b> {seizure.amount} </Description>
                          <Description><b>Marca:</b> {seizure.brand} </Description>
                          <Description><b>Tipo:</b> {seizure.type} </Description>
                          <Description><b>Modelo:</b> {seizure.model} </Description>
                          <br />
                        </Grid>
                      </>
                      )}
                    </>
                  )) : (<Description>NÃO HOUVE</Description>)}
                </Grid>
              </Grid>
              </>}

              <Grid item xs={12} sm={12} md={12}>
                <Subchapter>
                  <span>HISTÓRICO:</span>
                </Subchapter>
                <Description>{occurrence.history}</Description><br />
              </Grid>
            </Grid>
          </WrapperAddress>
        )}
      </Container>
    </Layout>
  );
};

export default Form;
