import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Link)`
    padding: 16px;
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    position: relative;
    text-decoration: none;
    color: black;
    cursor: pointer;
`;

export const Title = styled.span`
    color: #A9A9A9;
`;

export const Content = styled.div`
    border: 1px solid #A9A9A9;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 0.5rem;

    &:hover {
        border-color: blue;
    }
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        display: flex;
        align-items: center;
        color : #2E8B57;
    }
`;

export const Body = styled.div`
    display: block;
    font-weight: bold;
    margin-top: 1.25rem;
`;
