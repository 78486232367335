import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';

/** componentes */
import Address from 'pages/Orders/Address';
import Layout from '../../components/Layout';
import { HomeGearFillIcon } from '../../styles/icons';
import Action from './Action';
import Form from './Form';
import FormPacto from './FormPacto';

import { validateCellPhone } from '../../utils';

import { Container, WrapperAddress } from './styles';

/** hooks */
import { useAuth } from '../../hooks/auth';
import { useHttp } from '../../hooks/http';
import FormWater from './FormWater';
import FormInProgress from './FormInProgress';

interface ICoord {
  lat: number;
  lng: number;
}

interface IService {
  id: string;
}

const Ceperj: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { httpGet, loading } = useHttp();
  const [coord, setCoords] = useState<ICoord>({} as ICoord);
  const [service, setService] = useState<IService | null>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    async function load(): Promise<any> {
      const management_id = user?.allocation?.management_id;
      const { status, data } = await httpGet(`/orders/auth/${management_id}`);
      if (status === 200) {
        setService(data);
        return;
      }
      setService(null);
      history.push('/dashboard');
    }

    load();
  }, [history, httpGet, user]);

  useEffect(() => {
    async function loadUser(): Promise<void> {
      const { data } = await httpGet('/me');
      if (!validateCellPhone(data?.phone, true)) {
        history.push('/profile');
      }
    }

    loadUser();
  }, []);// eslint-disable-line

  const breadcrumbs = {
    icon: HomeGearFillIcon,
    links: [{ path: '', title: 'Ceperj' }],
  };

  return (
    <>
      {!user?.action && <Action />}
      {user?.action?.type === 'PARTICIPAÇÃO' && (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
          <Container>
            {id ? <FormPacto /> : (
              <>
                {!coord.lat && service && (
                  <WrapperAddress>
                    <h3><span>Selecione o </span>local da abordagem</h3>
                    <Address
                      handleCoord={(coord: ICoord) => {
                        setCoords(coord);
                      }}
                    />
                  </WrapperAddress>
                )}
                {(coord.lat) && (
                  <FormPacto coord={coord} />
                )}
              </>
            )}
          </Container>
        </Layout>

      )}
      {user?.action?.type === 'SERVIÇO' && (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
          <Container>
            {id ? <Form /> : (
              <>
                {!coord.lat && service && (
                  <WrapperAddress>
                    <h3><span>Selecione o </span>local da abordagem</h3>
                    <Address
                      handleCoord={(coord: ICoord) => {
                        setCoords(coord);
                      }}
                    />
                  </WrapperAddress>
                )}
                {(coord.lat) && (
                  <Form coord={coord} />
                )}
              </>
            )}
          </Container>
        </Layout>
      )}
      {user?.action?.type === 'OBRA' && (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
          <Container>
            {id ? <Form /> : (
              <>
                {!coord.lat && service && (
                  <WrapperAddress>
                    <h3><span>Selecione o </span>local da abordagem</h3>
                    <Address
                      handleCoord={(coord: ICoord) => {
                        setCoords(coord);
                      }}
                    />
                  </WrapperAddress>
                )}
                {(coord.lat) && (
                  <Form coord={coord} />
                )}
              </>
            )}
          </Container>
        </Layout>
      )}
      {user?.action?.type === 'AGUA' && (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
          <Container>
            {id ? <FormWater /> : (
              <>
                {!coord.lat && service && (
                  <WrapperAddress>
                    <h3><span>Selecione o </span>local da abordagem</h3>
                    <Address
                      handleCoord={(coord: ICoord) => {
                        setCoords(coord);
                      }}
                    />
                  </WrapperAddress>
                )}
                {(coord.lat) && (
                  <FormWater coord={coord} />
                )}
              </>
            )}
          </Container>
        </Layout>
      )}
      {user?.action?.type === 'OBRAANDAMENTO' && (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
          <Container>
            {id ? <FormInProgress /> : (
              <>
                {!coord.lat && service && (
                  <WrapperAddress>
                    <h3><span>Selecione o </span>local da obra</h3>
                    <Address
                      handleCoord={(coord: ICoord) => {
                        setCoords(coord);
                      }}
                    />
                  </WrapperAddress>
                )}
                {(coord.lat) && (
                  <FormInProgress coord={coord} />
                )}
              </>
            )}
          </Container>
        </Layout>
      )}
    </>
  );
};

export default Ceperj;
