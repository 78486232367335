import React from 'react';

import { Container, ButtonPlus } from './styles';
import { PlusIcon } from '../../../styles/icons';
import WrapperHeader from '../WrapperHeader';

interface FilterPlusProps{
  hiddenButton?: boolean;
  handlePlus():void;
}

const FilterPlus: React.FC<FilterPlusProps> = ({ children, hiddenButton, handlePlus }) => (
  <WrapperHeader>
    <Container>
      <h1>
        {children}
      </h1>
      {!hiddenButton && (
        <ButtonPlus onClick={handlePlus}>
          <PlusIcon />
        </ButtonPlus>
      )}
    </Container>
  </WrapperHeader>
);

export default FilterPlus;
