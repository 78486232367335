import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import CSVReader from 'react-csv-reader';
import {
  format, formatISO, differenceInHours, addHours,
} from 'date-fns';
import { formatDateTime } from '../../../utils';

/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** Componentes */
import Layout from '../../../components/Layout';
import { Button } from '../../../components/Forms';
import Accordion from '../../../components/Accordion';
import Modal from '../../../components/ModalSimple';
import { CsvIcon, InfoIcon } from '../../../styles/icons';
import {
  Container, Content, WrapperInputFile, WrapperModal,
} from './styles';

const breadcrumbs = {
  icon: CsvIcon,
  links: [
    { path: '/services', title: 'Serviços' },
    { path: '', title: 'Importar eventos' },
  ],
};

const CsvUpload: React.FC = () => {
  const { httpGet, httpPost, loading } = useHttp();
  const { addToast } = useToast();
  const formRef = useRef<HTMLFormElement>(null);
  const RefLabel = useRef<HTMLLabelElement>(null);
  const [rows, setRows] = useState<object[] | null>(null);
  const [dates, setDates] = useState<any>(null);
  const [datesError, setDatesError] = useState<any>(null);
  const [fileInfo, setFileInfo] = useState<any>(null);
  const [events, setEvents] = useState<any>(null);
  const [eventsError, setEventsError] = useState<any>(null);
  const [managements, setManagements] = useState<any>(null);
  const [ids, setIds] = useState<any>(null);
  const [users, setUsers] = useState<any>(null);
  const [usersError, setUsersError] = useState<any>(null);
  const [servicesError, setServicesError] = useState<any>(null);
  const [showCreate, setShowCreate] = useState(false);

  useEffect(() => {
    async function load():Promise<void> {
      const { status, data } = await httpGet('/managements/cproeis-name/all');
      if (status === 200) {
        const sectorParsed:any = [];

        data.forEach((management: any) => {
          if (management.cproeis_name.match(/,/g)) {
            const names = management.cproeis_name.split(/\s*,\s*/g);
            names.forEach((name: string) => {
              sectorParsed.push({ ...management, cproeis_name: name });
            });
            return;
          }
          sectorParsed.push(management);
        });

        setManagements(sectorParsed);
      }
    }
    load();
  }, [httpGet]);

  const handleParseDate = useCallback((payload: string, addHs?: 'string') => {
    try {
      const [date, hour] = payload.split(' ');
      const [day, month, year] = date.split('/');
      if (addHs) {
        const hours = addHs.replace(/\D/g, '');
        const dateAdded = addHours(new Date(`${year}-${month}-${day} ${hour}:00`), parseInt(hours, 10));
        return formatISO(dateAdded);
      }
      return formatISO(new Date(`${year}-${month}-${day} ${hour}:00`));
    } catch (error) {
      return null;
    }
  }, []);

  useEffect(() => {
    if (rows) {
      const datesSingle = [...new Set(rows?.map((row: any) => row.dia_evento))];
      const datesISO = datesSingle.map((date: any) => handleParseDate(date));
      setDates(datesISO);

      const events = [...new Set(rows?.map((row: any) => row.evento))];
      setEvents(events);

      const ids = [...new Set(rows?.map((row: any) => row.id_funcional))];
      setIds(ids);
    }
  }, [handleParseDate, rows]);

  const handleValidade = useCallback(async () => {
    formRef.current?.reset();
    let showCreate = true;

    // validar datas passadas ou maior q 12h
    if (dates && dates.length > 0) {
      const errors: any = [];
      dates.forEach((date: any) => {
        const dif = differenceInHours(new Date(date), Date.now());
        if (dif > 11 || dif < 0) {
          errors.push(date);
          showCreate = false;
        }
        setDatesError(errors);
        return false;
      });
    }

    // valida os eventos
    const eventsFiltered = events.filter((event: any) => {
      if (managements.some((management: any) => management.cproeis_name === event)) {
        return false;
      }
      showCreate = false;
      return true;
    });
    setEventsError(eventsFiltered);

    // validar agentes
    const idsParsed = `'${ids.join("','")}'`;
    const { status: stUser, data: dtUser } = await httpPost('/services/presence/users', { ids: idsParsed });
    if (stUser === 200) {
      setUsers(dtUser);

      const errors = ids.filter((id: any) => {
        if (dtUser.some((user: any) => String(user.document_secondary) === String(id))) {
          return false;
        }
        showCreate = false;
        return true;
      });
      setShowCreate(showCreate);
      setUsersError(errors);
    }

    // validar serviços
    const userIds = dtUser?.map((user: any) => user.id);
    if (userIds.length === 0) {
      return;
    }

    const { status: stService, data: dtService } = await httpPost('/services/presence/users/services', { ids: userIds });
    if (stService === 200) {
      setServicesError(dtService);
    }
  }, [dates, events, httpPost, ids, managements]);

  const handleCsvReader = useCallback((data, fileInfo) => {
    setDatesError(null);
    setEventsError(null);
    setUsersError(null);
    setShowCreate(false);
    setFileInfo(fileInfo);
    setRows(data);
  }, []);

  const handleSubmit = useCallback(async () => {
    const data: any = [];
    rows?.forEach((row: any) => {
      const management = managements
        .filter((item: any) => item.cproeis_name === row.evento)[0];
      const user = users
        .filter((item: any) => String(item.document_secondary) === String(row.id_funcional))[0];

      data?.push({
        user_id: user.id,
        management_id: management.id,
        started_at: handleParseDate(row.dia_evento),
        ended_at: handleParseDate(row.dia_evento, row.turno),
      });
    });

    const { status } = await httpPost('services/presence/users/create', { services: data });
    if (status === 204) {
      setShowCreate(false);
      addToast({ title: 'Serviços salvos com sucesso' });
    }
  }, [addToast, handleParseDate, httpPost, rows, managements, users]);

  const csvReaderOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, '_'),
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content>
          <p>Carregue o arquivo .csv  <InfoIcon onClick={() => RefLabel.current?.click()} /></p>
          <WrapperInputFile ref={formRef}>
            <span>
              {!fileInfo && 'Click ou arraste o arquivo aqui.'}
              {fileInfo && fileInfo.name}
            </span>
            <CSVReader
              cssClass="react-csv-input"
              fileEncoding="ISO-8859-1"
              parserOptions={csvReaderOptions}
              onFileLoaded={handleCsvReader}
              inputStyle={{ opacity: 0 }}
            />
          </WrapperInputFile>

          <p>
            Linhas: {rows?.length } Eventos: {events?.length}
          </p>

          <Button onClick={handleValidade} disableForce={!rows} isLoading={loading}>
            Analisar
          </Button>

          {datesError?.length > 0 && (
            <Accordion
              title={`Datas e hora rejeitadas: (${datesError.length})`}
              content={<ul>{datesError.map((date: any) => <li key={date}>{format(new Date(date), 'dd/MM/yyy HH:mm')}</li>)}</ul>}
            />

          )}
          {eventsError?.length > 0 && (
            <Accordion
              title={`Eventos não encontrados: (${eventsError.length})`}
              content={<ul>{eventsError.map((event: any) => <li key={event}>{event}</li>)}</ul>}
            />
          )}
          {usersError?.length > 0 && (
            <Accordion
              title={`Agentes não encontrados: (${usersError.length})`}
              content={<ul>{usersError.map((id: any) => <li key={id}>{id}</li>)}</ul>}
            />

          )}
          {servicesError?.length > 0 && (
            <Accordion
              title={`Serviços já criados: (${servicesError.length})`}
              content={(
                <ul>{servicesError.map((service: any) => (
                  <li key={service.id}>
                    {service.management?.initials} - {service.sector?.initials}
                    {' das '}{formatDateTime(service?.started_at)} às {formatDateTime(service?.ended_at)}
                    <br />ID {service.user?.document_secondary} - {service.user?.first_name}
                  </li>
                ))}
                </ul>
              )}
            />

          )}

          {showCreate && (

          <Button onClick={handleSubmit} disableForce={!rows} isLoading={loading}>
            Criar serviços
          </Button>

          )}
        </Content>
      </Container>

      <label ref={RefLabel} htmlFor="modalInfo" style={{ opacity: 0 }}> abrir </label>
      <Modal id="modalInfo" height={200}>
        <WrapperModal>
          <p>É esperado uma planinha .csv que possua pelo menos as seguintes colunas:</p>
          <table>
            <thead>
              <tr>
                <th>EVENTO</th>
                <th>DIA EVENTO</th>
                <th>TURNO</th>
                <th>ID FUNCIONAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>LAPA PRESENTE</td>
                <td>{format(Date.now(), 'dd/MM/yyy HH:mm')}</td>
                <td>12 h</td>
                <td>43694195</td>
              </tr>
            </tbody>
          </table>
        </WrapperModal>
      </Modal>
    </Layout>
  );
};

export default CsvUpload;
