import React from 'react';
import { Container, ButtonPlus, ButtonReturn } from './styles';
import { PlusIcon, ReplyIcon } from '../../../styles/icons';
import WrapperHeader from '../WrapperHeader';

interface ReturnSalverPlusProps{
  hiddenButton?: boolean;
  handlePlus():void;
  handleReturn():void;
}

const ReturnSalvePlus: React.FC<ReturnSalverPlusProps> = ({
  children, hiddenButton, handlePlus, handleReturn,
}) => (
  <WrapperHeader>
    <Container>
      <h1>
        {children}
      </h1>
      <ButtonReturn>
        <ReplyIcon onClick={handleReturn} />
      </ButtonReturn>
      {!hiddenButton && (
        <ButtonPlus>
          <PlusIcon onClick={handlePlus} />
        </ButtonPlus>
      )}
    </Container>
  </WrapperHeader>
);

export default ReturnSalvePlus;
