import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import signInBackgroundImg from '../../assets/bg-auth.jpg';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transforme: translateX(-50px);
  }
  to{
    opacity: 1;
    transforme: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;

  animation: ${appearFromLeft} 1s;

  img{
    max-width: 240px;
  }
  @media (max-width: 540px) {
    img{
      max-width: 50%;
    }
  }

  form{
    margin: 24px 0;
    width: 288px;
    text-align: center;

    h1{
      font-size: 24px;
      margin-bottom: 16px;
    }

    a{
      display: block;
      text-decoration: none;
      color: var(--color-primary);
      margin-top: 24px;
      transition: color 0.2s;

      &:hover{
        color: var(--color-primary-h);
      }
    }
  }

  > a{
    display: block;
    text-decoration: none;
    margin-top: 24px;
    color: #FF9000;

    display: flex;
    align-items: center;
    transition: color 0.2s;

    &:hover{
      color: ${shade(0.2, '#FF9000')}
    }
    svg{
      margin-right: 16px;

    }
  }
`;
