import styled from 'styled-components';

interface IContainerProps {
    color: string
}

export const Container = styled.div<IContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 600px){
        width: 96%;
    }
    width: 10rem;
    height: 150%;
    margin: 10px 10px;
    background-color: ${props => props.color};
    border: 1px solid;
    border-radius: 7px;

    padding: 10px 20px;

    span {
        color: white;
    }

    h1 {
        color: white;
    }
`;
