import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import getValidationError from '../../../utils/getValidationsErros';
import {
  parseDate, formatDate,
} from '../../../utils';

import states from '../../../utils/states';

/** hooks */
import { useHttp } from '../../../hooks/http';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import {
  WrapperLabel, Input, Select, Button, InputMask, InputHidden, ButtonIcon,
} from '../../../components/Forms';
import { InfoIcon } from '../../../styles/icons';
import {
  Container, GridButtons, WrapperBtnInfo, WrapperBtnCancel, WrapperBtnSubmit,
} from './styles';

import { ISocial } from '../../../interfaces';

interface IFormData {
  social: ISocial;
  coord?: {
    lat: number,
    lng: number
  }
}

const Data: React.FC<IFormData> = ({ social, coord }) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [disabled, setDisabled] = useState(false);
  const [sOptions] = useState([
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ]);

  const {
    httpPost, httpPut, loading,
  } = useHttp();

  useEffect(() => {
      formRef.current?.setData({
        ...social,
        birthday: formatDate(social.birthday),
        gender: { value: social.gender, label: social.gender },
        gender_identity: { value: social.gender_identity, label: social.gender_identity },
        nationality: { value: social.nationality, label: social.nationality },
        naturalness: { value: social.naturalness, label: social.naturalness },
        ethnicity: { value: social.ethnicity, label: social.ethnicity },
        marital: { value: social.marital, label: social.marital },
        schooling: { value: social.schooling, label: social.schooling },
        is_participatory: sOptions.find((is_participatory) => is_participatory.value
        === social.is_participatory),
      });
  }, [social, sOptions]);

  const handleSubmit = useCallback(async (data: any) => {
    const _social = {
      ...data,
      ...coord,
      is_participatory: data.is_participatory?.value,
      birthday: parseDate(data.birthday) === 'erro' ? null : parseDate(data.birthday),
      gender: data.gender?.value,
      gender_identity: data.gender_identity?.value,
      nationality: data.nationality?.value,
      naturalness: data.naturalness?.value,
      ethnicity: data.ethnicity?.value,
      marital: data.marital?.value,
      schooling: data.schooling?.value,
      management_id: user?.allocation?.management_id,
    };

    try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          is_participatory: Yup.boolean().required('Participativo é obrigatório'),
          name: Yup.string().when('is_participatory', {
            is: (is_participatory) => is_participatory === true, then: Yup.string().ensure().required('Nome é obrigatório'),
          }),
          gender: Yup.string().ensure().required('Gênero é obrigatório'),
          age: Yup.number().typeError('Idade é obrigatório, somente números'),
          ethnicity: Yup.string().ensure().required('Etnia é obrigatório'),
        });

        await schema.validate(_social, { abortEarly: false });

        if (social.id) {
          const { status } = await httpPut(`socials/${_social.id}`, _social);
          if (status === 200) {
            addToast({ title: 'Próximo passo, acesse o COMPLEMENTO!', type: 'warning' });
          }
        } else {
          const { status, data } = await httpPost('socials', _social);
          if (status === 200) {
            if (_social.is_participatory) {
              addToast({ title: 'Próximo passo, acesse o COMPLEMENTO!', type: 'warning' });
              history.push(`/social/${data.id}`);
            } else {
              addToast({ title: 'Salvo com sucesso!', type: 'success' });
              history.push('/dashboard');
            }
          }
        }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
          formRef.current?.setErrors(erros);
      }
    }
    }, [social]);// eslint-disable-line

  const handleFieldStore = useCallback((partipation: any) => {
    setDisabled(!partipation?.value);
  }, []);

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <InputHidden name="id" />
          <WrapperLabel label="Participativo?" xs={12} md={12}>
            <Select
              name="is_participatory"
              options={sOptions}
              onChange={handleFieldStore}
            />
          </WrapperLabel>
          <WrapperLabel label="Nome" xs={12} sm={6}>
            <Input name="name" disabled={disabled} />
          </WrapperLabel>

          <WrapperLabel label="Nome Social" xs={12} sm={6}>
            <Input name="social_name" disabled={disabled} />
          </WrapperLabel>

          <WrapperLabel label="Mãe" xs={12} sm={6}>
            <Input name="mather" disabled={disabled} />
          </WrapperLabel>

          <WrapperLabel label="Pai" xs={12} sm={6}>
            <Input name="father" disabled={disabled} />
          </WrapperLabel>

          <WrapperLabel label="Nascimento" xs={12} sm={6} md={5}>
            <InputMask name="birthday" type="tel" mask="99/99/9999" placeholder="DD/MM/YYYY" disabled={disabled} />
          </WrapperLabel>

          <WrapperLabel label="Idade" xs={12} sm={6} md={1}>
            <Input name="age" maxLength={2} />
          </WrapperLabel>

          <WrapperLabel label="Gênero" xs={12} sm={4} md={6}>
            <Select
              name="gender"
              options={[
                { value: 'Masculino', label: 'Masculino' },
                { value: 'Feminino', label: 'Feminino' },
              ]}
            />
          </WrapperLabel>

          <WrapperLabel label="Identidade de gênero" xs={12} sm={4} md={4}>
            <Select
              name="gender_identity"
              options={[
                { value: 'Cisgênero', label: 'Cisgênero' },
                { value: 'Transgênero', label: 'Transgênero' },
                { value: 'Homossexual', label: 'Homosexual' },
              ]}
              isDisabled={disabled}
            />
          </WrapperLabel>

          <WrapperLabel label="Nacionalidade" xs={12} sm={4}>
            <Select
              name="nationality"
              options={[
                { value: 'Brasileira', label: 'Brasileira' },
                { value: 'Estrangeira', label: 'Estrangeira' },
              ]}
              isDisabled={disabled}
            />
          </WrapperLabel>

          <WrapperLabel label="Naturalidade" xs={12} md={4} sm={6}>
            <Select
              name="naturalness"
              options={states}
              isDisabled={disabled}
            />
          </WrapperLabel>

          <WrapperLabel label="Etnia" xs={12} sm={6}>
            <Select
              name="ethnicity"
              options={[
                { value: 'Branca', label: 'Branca' },
                { value: 'Negra', label: 'Negra' },
                { value: 'Parda', label: 'Parda' },
                { value: 'Amarela', label: 'Amarela' },
                { value: 'Indígena', label: 'Indígena' },
                { value: 'Outros', label: 'Outros' },
              ]}
            />
          </WrapperLabel>

          <WrapperLabel label="Estado Civil" xs={12} sm={6} md={6}>
            <Select
              name="marital"
              options={[
                { value: 'Casado', label: 'Casado' },
                { value: 'Solteiro', label: 'Solteiro' },
                { value: 'Viúvo', label: 'Viúvo' },
                { value: 'Separado', label: 'Separado' },
                { value: 'Divorciado', label: 'Divorciado' },
              ]}
              isDisabled={disabled}
            />
          </WrapperLabel>

          <WrapperLabel label="Local Nascimento" xs={12} sm={6} md={6}>
            <Input name="place_birth" disabled={disabled} />
          </WrapperLabel>

          <WrapperLabel label="Telefone" xs={12} sm={6} md={6}>
            <InputMask mask="(99) 99999-9999" name="phone" disabled={disabled} />
          </WrapperLabel>

          <WrapperLabel label="Escolaridade" xs={12} sm={6}>
            <Select
              name="schooling"
              options={[
                { value: 'Analfabeto', label: 'Analfabeto' },
                { value: 'Fundamental incompleto', label: 'Fundamental incompleto' },
                { value: 'Fundamental completo', label: 'Fundamental completo' },
                { value: 'Médio incompleto', label: 'Médio incompleto' },
                { value: 'Médio completo', label: 'Médio completo' },
                { value: 'Superior incompleto', label: 'Superior incompleto' },
                { value: 'Superior completo', label: 'Superior completo' },
                { value: 'Não Informado', label: 'Não Informado' },
              ]}
              isDisabled={disabled}
            />
          </WrapperLabel>

          <WrapperLabel label="Profissão" xs={12} sm={6}>
            <Input name="profession" disabled={disabled} />
          </WrapperLabel>

          <WrapperLabel label="Endereço de referência" xs={12} sm={12}>
            <Input name="reference_address" disabled={disabled} />
          </WrapperLabel>

        </Grid>

        <GridButtons>
          <WrapperBtnInfo>
            <ButtonIcon
              title="Instruções"
            >
              <InfoIcon />
            </ButtonIcon>
          </WrapperBtnInfo>
          <WrapperBtnCancel>
            <Button
              type="button"
              onClick={() => { history.push('/dashboard'); }}
            >
              Cancelar
            </Button>
          </WrapperBtnCancel>
          <WrapperBtnSubmit>
            <Button
              type="submit"
              isLoading={loading}
            >
              Salvar
            </Button>
          </WrapperBtnSubmit>
        </GridButtons>

      </UForm>
    </Container>
  );
};

export default Data;
