import React, { useCallback } from 'react';

import { ValueGetterParams } from '@material-ui/data-grid';

import { Container } from './styles';
import { PlayIcon, StopIcon } from '../../../styles/icons';

/** interface */
interface IAction {
  params: ValueGetterParams;
  handle(user_id: string | number):void;
}

const Actions: React.FC<IAction> = ({ params, handle }) => {
  const status = useCallback((params: ValueGetterParams) => {
    if (params.row?.missing_id) {
      return '';
    }

    if (params.row?.presence_ended_id) {
      return '';
    }

    if (params.row?.presence_started_id) {
      return <StopIcon title="Encerrar o serviço" />;
    }

    return <PlayIcon title="Confirmar presença" />;
  }, []);

  return (
    <Container>

      <button type="button" onClick={() => handle(params.row?.id)}>
        {status(params)}

      </button>

    </Container>
  );
};

export default Actions;
