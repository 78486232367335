import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../hooks/http';

import {
  WrapperLabel, Input, Select, Button, InputPhone, InputHidden,
} from '../../../components/Forms';

import { IManagement } from '../../../interfaces';

interface IFormProfile {
  profile: IManagement;
}

const FormProfile: React.FC<IFormProfile> = ({ profile }) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const {
    httpGet, httpPost, httpPut, loading,
  } = useHttp();

  const [cores, setCores] = useState([]);
  const [ascendants, setAscendants] = useState([]);
  useEffect(() => {
    async function loadCores():Promise<void> {
      const [respAscendant, respCores] = await Promise.all([
        httpGet('/managements/ascendants', undefined, false),
        httpGet('/cores', undefined, false),
      ]);

      const ascendants = respAscendant.data;
      if (ascendants) {
        const _ascendants = ascendants
          .map((ascendant: any) => ({ value: ascendant.id, label: ascendant.name }));

        setAscendants(_ascendants);
      }

      const cores = respCores.data;
      if (cores) {
        const _cores = cores
          .map((core: any) => ({ value: core.id, label: core.name }));

        setCores(_cores);
      }
    }

    loadCores();
  }, []);// eslint-disable-line

  useEffect(() => {
    formRef.current?.setData({
      ...profile,
      ascendant_id: ascendants.find((ascendant: any) => ascendant.value === profile.ascendant_id),
      core_id: cores.find((core:any) => core.value === profile.core_id),
      is_core: profile.is_core ? { value: true, label: 'Sim' } : { value: false, label: 'Não' },
      status: profile.status ? { value: true, label: 'Ativo' } : { value: false, label: 'Inativo' },
    });
  }, [ascendants, cores, profile]);

  const handleSubmit = useCallback(async (data: any) => {
    const _management = {
      ...data,
      ascendant_id: data.ascendant_id?.value,
      core_id: data.core_id?.value,
      is_core: data.is_core.value,
      status: data.status.value,
    };

    try {
      formRef.current?.setErrors({});

      let additionSchema;
      if (data.is_core === 'false') {
        additionSchema = {
          core_id: Yup.string().required().min(1, 'Nuint é obrigatório'),
        };
      }

      const schema = Yup.object().shape({
        level: Yup.string().required('Estrutura é obrigatório'),
        name: Yup.string().required('Nome é obrigatório'),
        initials: Yup.string().required('Nome é obrigatório'),
        ...additionSchema,
      });

      await schema.validate(_management, { abortEarly: false });

      if (profile.id) {
        await httpPut(`managements/${profile.id}`, _management);
      } else {
        const { status, data } = await httpPost('managements', _management);
        if (status === 200) {
          history.push(`/management/${data.id}`);
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [profile]);// eslint-disable-line

  return (
    <UForm ref={formRef} onSubmit={handleSubmit}>
      <InputHidden name="id" />
      <Grid container spacing={1}>

        <WrapperLabel label="Estrutura" xs={12} md={3}>
          <Input name="level" />
        </WrapperLabel>

        <WrapperLabel label="Ascendente" xs={9} md={6}>
          <Select name="ascendant_id" options={ascendants} isClearable />
        </WrapperLabel>

        <WrapperLabel label="Status" xs={3} md={3}>
          <Select
            name="status"
            options={[
              { value: true, label: 'Ativo' },
              { value: false, label: 'Inativo' },
            ]}
          />
        </WrapperLabel>

        <WrapperLabel label="Sigla" xs={12} md={3}>
          <Input name="initials" />
        </WrapperLabel>

        <WrapperLabel label="Nome" xs={12} md={6}>
          <Input name="name" />
        </WrapperLabel>

        <WrapperLabel label="Telefone" xs={9} md={3}>
          <InputPhone name="phone" />
        </WrapperLabel>

        <WrapperLabel label="Núcleo" xs={3} md={3}>
          <Select
            name="is_core"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
          />
        </WrapperLabel>

        <WrapperLabel label="Nuint Responsável" xs={12} md={9}>
          <Select name="core_id" options={cores} isClearable />
        </WrapperLabel>

      </Grid>

      <Grid container justify="flex-end">
        <Grid item xs={12} md={4}>
          <Button type="submit" isLoading={loading}>Salvar</Button>
        </Grid>
      </Grid>

    </UForm>
  );
};

export default FormProfile;
