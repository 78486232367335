export default function validateRG(value: string | null | undefined, required?: boolean): boolean {
  if (!required && !value) {
    return true;
  }

  if (!value) {
    return false;
  }

  const rg = value.replace(/\D/g, '');

  if (rg.length < 5 || rg.length > 11) {
    return false;
  }

  if (rg.match(/^(\d)\1{5}/g)) {
    return false;
  }

  return true;
}
