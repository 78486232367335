import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import _ from 'lodash';

/** hooks */
import { useHttp } from '../../../hooks/http';

/** components */
import Layout from '../../../components/Layout';
import Headers from '../../../components/Headers/Simple';
import Modal from '../../../components/ModalSimple';
import { ButtonIcon } from '../../../components/Forms';
import Card from './Card';

import { ListIcon, InfoIcon, DashIcon } from '../../../styles/icons';
import { Container, WrapperHeader, WrapperModal } from './styles';

const breadcrumbs = {
  icon: ListIcon,
  links: [
    { path: '/dashboard', title: 'Dashboard' },
    { path: '', title: 'Minhas Consultas Recentes' },
  ],
};

const Answers: React.FC = () => {
  const history = useHistory();
  const RefLabel = useRef<HTMLLabelElement>(null);
  const { httpGet, loading } = useHttp();
  const [orders, setOrders] = useState<any[]>([]);

  const loadOrders = useCallback(async (isLoading: boolean) => {
    const { status, data } = await httpGet(
      'orders/latest',
      undefined,
      isLoading,
    );

    if (status === 200) {
      const _orders = data?.length > 0
        ? _.orderBy(data, ['status', 'created_at'], ['asc', 'desc'])
        : [];
      setOrders(_orders);
    }
  }, [httpGet]);

  useEffect(() => {
    loadOrders(true);
  }, [loadOrders]);

  useEffect(() => {
    const refresh = setInterval(() => {
      const isOrderAwaitResult = orders.filter((order:any) => !order.result_id);

      if (isOrderAwaitResult?.length) {
        loadOrders(false);
      }
    }, 1000 * 12);

    return () => {
      clearInterval(refresh);
    };
  }, [loadOrders, orders]);

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Headers>
          <WrapperHeader>
            <ButtonIcon title="Dashboard" onClick={() => { history.push('/dashboard'); }}>
              <DashIcon />
            </ButtonIcon>

            <ButtonIcon title="Instruções" onClick={() => { RefLabel.current?.click(); }}>
              <InfoIcon />
            </ButtonIcon>
          </WrapperHeader>
        </Headers>

        <Grid container spacing={1}>
          {orders?.length > 0 && orders.map((order:any) => <Card key={`card-${order.id}`} order={order} />)}
        </Grid>
      </Container>

      <label ref={RefLabel} htmlFor="modalInfo" style={{ opacity: 0 }}> instruções </label>
      <Modal id="modalInfo" height={240}>
        <WrapperModal>
          Esta página exibe suas 20(vinte) abordagens mais recentes.
          <p>
            Caso precise listar todas consultas realizadas por você
            acesse no menu <ListIcon /> Consultas/Listar.
          </p>
          <p>
            Policial atente para as normas de segurança na sua abordagem.
          </p>
        </WrapperModal>
      </Modal>
    </Layout>

  );
};

export default Answers;
