import React, { useCallback, useEffect, useRef } from 'react';
import { Form as UForm } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import colors from '../../../utils/colors';

import getValidationError from '../../../utils/getValidationsErros';
import { formatCpf, formatDateTime } from '../../../utils';
/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** componentes */
import Flex, { Grid } from '../../../components/Flex';
import {
  WrapperLabel, Input, InputHidden, TextArea, InputMask, Select, Button,
} from '../../../components/Forms';
import { Container } from './styles';

/** interfaces */
import { IVehicle } from '../../../interfaces';

interface PersonFormProps {
  vehicle: IVehicle;
}

const Profile: React.FC<PersonFormProps> = ({ vehicle }) => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const {
    httpPost, httpPut, loading, unLoading,
  } = useHttp();

  useEffect(() => {
    unLoading();

    if (vehicle) {
      formRef.current?.setData({
        ...vehicle,
        color: { value: vehicle.color, label: vehicle.color },

      });
    }
  }, [vehicle]); // eslint-disable-line

  const handleSubmit = useCallback(async (payload: any) => {
    const data = {
      ...payload,
      plate: payload.plate.toUpperCase(),
      chassi: payload.chassi.toUpperCase(),
      engine_number: payload.engine_number.toUpperCase(),
      color: payload?.color?.value,
    };

    formRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        chassi: Yup.string().required('Chassi é obrigatório'),
        brand: Yup.string().required('Marca/Modelo é obrigatório'),
        color: Yup.string().ensure().required('Cor é obrigatória'),
      });

      await schema.validate(data, { abortEarly: false });
      if (vehicle?.id) {
        const { status } = await httpPut(`/vehicles/${vehicle.id}`, data);
        if (status === 200) {
          addToast({ title: 'Editado com sucesso' });
        }
      } else {
        const { status, data: vehicle } = await httpPost('/vehicles', data);
        if (status === 200) {
          history.push(`/vehicle/${vehicle.id}`);
          addToast({ title: 'Salvo com sucesso' });
        }
      }
    } catch (err) {
      const erros = getValidationError(err);
      formRef.current?.setErrors(erros);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, history, vehicle]);

  return (
    <Container>

      <UForm
        ref={formRef}
        onSubmit={handleSubmit}

      >
        <Grid container spacing={1}>
          <InputHidden name="id" />
          <WrapperLabel label="Placa" xs={12} sm={4}>
            <InputMask
              name="plate"
              mask="aaa-9*99"
              style={{ textTransform: 'uppercase' }}
            />
          </WrapperLabel>
          <WrapperLabel label="Chassi" xs={12} sm={4}>
            <InputMask
              name="chassi"
              mask="*****************"
              style={{ textTransform: 'uppercase' }}
            />
          </WrapperLabel>
          <WrapperLabel label="Motor" xs={12} sm={4}>
            <Input
              name="engine_number"
              style={{ textTransform: 'uppercase' }}
            />
          </WrapperLabel>
          <WrapperLabel label="Marca/Modelo" xs={12} sm={6}>
            <Input name="brand" style={{ textTransform: 'uppercase' }} />
          </WrapperLabel>
          <WrapperLabel label="Cor" xs={12} sm={6}>
            <Select name="color" options={colors} />
          </WrapperLabel>
          <WrapperLabel label="Observação" xs={12}>
            <TextArea row={3} name="description" />
          </WrapperLabel>

        </Grid>

        <Grid container justify="flex-end" alignItems="baseline">
          {vehicle?.user && (
            <Flex title="Última edição" xs={12} md={8} textAutoSize>
              {formatDateTime(vehicle?.updated_at)}{' '}
              {vehicle?.user?.name}{' '}
              {formatCpf(vehicle?.user?.document)}
            </Flex>
          )}

          <Grid item xs={12} md={4}>
            <Button type="submit" isLoading={loading}>Salvar</Button>
          </Grid>
        </Grid>

      </UForm>

    </Container>
  );
};

export default Profile;
