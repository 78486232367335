import React, {
  useCallback, useRef, useState, useEffect,
} from 'react';
import { Form as UForm } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import ModalFactory from 'react-modal-promise';
import { useHttp } from 'hooks/http';
import { useAuth } from 'hooks/auth';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IFueling from 'interfaces/fueling';
import Flex, { Grid } from '../../../components/Flex';
import { formatDateTime } from '../../../utils';
import getValidationError from '../../../utils/getValidationsErros';
/** components */
import { ModalDialog } from '../../../components/ModalDialog';
import Modal from '../../../components/ModalSimple';

import getCurrentPosition from '../../../utils/getCurrentPosition';
import { TrashIcon, WarningIcon } from '../../../styles/icons';
import Supply from '../Supply';

import {
  WrapperLabel,
  Button,
  InputHidden,
  Input,
  TextArea,
} from '../../../components/Forms';

import {
  Container,
  Content,
  ContentWapper,
  GridButtons,
  WrapperBtnCancel,
  WrapperBtnSubmit,
  WrapperModal,
  Red,
} from './styles';

interface ICoord {
    coord?: {
        lat: number,
        lng: number
    }
}

interface IBdtd {
    id: string,
    plate: string,
    viatura: string,
    dateFormatted: string,
    started_km: string,
}

const Closed: React.FC<ICoord> = ({ coord }) => {
  const formRef = useRef<FormHandles>(null);
  const labelConfRef = useRef<HTMLLabelElement>(null);
  const labelAbsRef = useRef<HTMLLabelElement>(null);
  const history = useHistory();
  const { user } = useAuth();
  const [btnLoading, setBtnLoading] = useState(false);
  const [formData, setFormData] = useState<any>();
  const [disableBtnChecked, setDisableBtnChecked] = useState(false);
  const [bdtd, setBdtd] = useState<IBdtd>({} as IBdtd);
  const [fueling, setFueling] = useState<IFueling[]>([]);
  const { httpGet, httpPut, httpDelete } = useHttp();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    async function loadBdtdFueling() {
      const { status, data } = await httpGet('/bdtds/status');
      if (status === 200) {
        const { statusCheckData, fuelings } = data;
        const dateFormatted = formatDateTime(statusCheckData.started_at);
        const dataEx = { ...statusCheckData, dateFormatted };
        setBdtd(dataEx);
        setFueling(fuelings);
      }
    }
    loadBdtdFueling();
  }, [httpGet]);

  const loadFueling = useCallback(async () => {
    const { status, data } = await httpGet('/bdtds/status');
    if (status === 200) {
      const { fuelings } = data;
      setFueling(fuelings);
    }
  }, [httpGet]);

  const handleToRecord = useCallback(async (data: any) => {
        labelConfRef.current?.click();
        try {
            formRef.current?.setErrors({});

            const schema = Yup.object().shape({
              started_km: Yup.number(),
              ended_km: Yup.number().typeError('Km final é obrigatório, somente números')
                .min(Yup.ref('started_km'), 'Km final deve ser maior que o inicial'),
            });

            await schema.validate(data, { abortEarly: false });

            const bdtdSubmit = {
              ...coord,
              id: data.id,
              started_km: data.started_km,
              ended_km: data.ended_km,
              ended_at: new Date(),
              management_id: user?.allocation?.management_id,
              note: data.note,
              active: false,
            };

            setBtnLoading(true);
            const position = await getCurrentPosition();

            const response: any = await httpPut(`bdtds/end/${bdtdSubmit.id}`, {
              ...bdtdSubmit,
              latitude_retreat: position.lat,
              longitude_retreat: position.lng,
            });

            if (response.status === 200) {
              history.push('/bdtds');
            }

            if (response.status !== 200) {
              setBtnLoading(false);
            }
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
          }
        }

        setBtnLoading(false);
  }, [httpPut, coord, user, history]);

  const handleSubmit = useCallback(async (dataSubmit: any) => {
    try {
            formRef.current?.setErrors({});

            // eslint-disable-next-line no-underscore-dangle
            const _dataSubmit = {
              ...dataSubmit,
            };

            const schema = Yup.object().shape({
              started_km: Yup.number(),
              ended_km: Yup.number().typeError('Km final é obrigatório, somente números')
                .min(Yup.ref('started_km'), 'Km final deve ser maior que o inicial'),
            });

            await schema.validate(_dataSubmit, { abortEarly: false });
            setFormData(_dataSubmit);
            formRef.current?.setData({
              ..._dataSubmit,
              viatura: _dataSubmit.viatura,
              started_km: _dataSubmit.started_km,
              ended_km: _dataSubmit.ended_km,
              confirmed: [{ value: null, label: '' }],
            });

            labelConfRef.current?.click();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
                formRef.current?.setErrors(erros);
      }
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableBtnChecked(event.target.checked);
  };

  const handleGet = useCallback(async () => {
        labelAbsRef.current?.click();
  }, []);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function handleGasDelete(id: any) {
    const response = await ModalDialog({
      title: 'Aviso!',
      content: 'Você vai excluir esse abastecimento. Deseja prosseguir?',
      buttons: 'cancel_confirm',
    });

    if (response) {
      const response: any = await httpDelete(`bdtds/supply/${id}`);
      if (response.status === 200) {
        loadFueling();
      }
    }
  }

  return (
    <Container>
      <Content>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <InputHidden name="id" defaultValue={bdtd?.id} />
            <WrapperLabel label="Viatura" xs={12} sm={6}>
              <Input
                name="viatura"
                defaultValue={bdtd?.viatura}
                disabled
              />
            </WrapperLabel>
            <WrapperLabel label="Data/Hora Inicial" xs={12} sm={6}>
              <Input
                name="started_at"
                defaultValue={bdtd?.dateFormatted}
                disabled
              />
            </WrapperLabel>
            <WrapperLabel label="Km Inicial" xs={12} sm={6} md={6}>
              <Input
                name="started_km"
                defaultValue={bdtd?.started_km}
              />
            </WrapperLabel>
            <WrapperLabel label="Km Final" xs={12} sm={6}>
              <Input name="ended_km" maxLength={11} />
            </WrapperLabel>

            {user?.allocation?.management_id === 49 && (
              <Button
                type="button"
                onClick={() => { handleGet(); }}
              >
                Abastecimento
              </Button>
            )}
            {fueling?.length === 0 ? ''
              : (
                <ContentWapper>
                  {fueling?.map((abs: IFueling) => (
                    <Grid container spacing={1} key={abs.id}>
                      <Flex title="Km" sm={3} md={3}>
                        {abs?.km_fueling}
                      </Flex>
                      <Flex title="Comb" sm={3} md={3}>
                        {abs?.fuel}
                      </Flex>
                      <Flex title="Litros" sm={3} md={3}>
                        {abs?.liters}
                      </Flex>
                      <Flex title="Cod Posto" sm={2} md={2}>
                        {abs?.gases_id}
                      </Flex>
                      <Flex title="Excluir" sm={1} md={1}>
                        <TrashIcon
                          onClick={() => { handleGasDelete(abs?.id); }}
                          cursor="pointer"
                        />
                      </Flex>
                    </Grid>
                  ))}
                </ContentWapper>
              )}
            <WrapperLabel label="Observação" xs={12}>
              <TextArea row={2} name="note" maxLength={500} />
            </WrapperLabel>
          </Grid>
          <GridButtons>
            <WrapperBtnCancel>
              <Button
                type="button"
                onClick={() => { history.push('/dashboard'); }}
              >
                Dashboard
              </Button>
            </WrapperBtnCancel>
            <WrapperBtnSubmit>
              <Button
                type="button"
                onClick={() => { formRef.current?.submitForm(); }}
                isLoading={btnLoading}
                style={{ background: '#CC0001' }}
              >
                Encerrar
              </Button>
            </WrapperBtnSubmit>
          </GridButtons>
        </UForm>
      </Content>
      <label ref={labelAbsRef} htmlFor="modal-abs" />
      <Modal id="modal-abs" width={520} height="auto">
        <Supply labelAbsRef={labelAbsRef} id={bdtd?.id} handlerLoad={loadFueling} />
      </Modal>
      <WrapperModal>
        <label ref={labelConfRef} htmlFor="modal-conf" />
        <Modal id="modal-conf" width={520} height="auto">
          <h2>
            <WarningIcon /> Confirma os dados da viatura?
          </h2>
          <p>Viatura: <b>{formData?.viatura}</b></p>
          <p>Km Início: <b>{formData?.started_km}</b></p>
          <p>Km Final: <b>{formData?.ended_km}</b></p>
          <FormControlLabel
            label={<Red>Confirmo as informações acima.</Red>}
            control={(
              <Switch
                onChange={handleChangeChecked}
                color="primary"
                name="checked"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
                        )}
          />
          <span>
            <Button onClick={() => labelConfRef.current?.click()}>
              Cancelar
            </Button>
            <Button
              onClick={() => handleToRecord(formData)}
              isLoading={btnLoading}
              disableForce={!disableBtnChecked}
            >
              Confirmar
            </Button>
          </span>
        </Modal>
      </WrapperModal>
      <ModalFactory />
    </Container>
  );
};

export default Closed;
