import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { GridColDef, ValueGetterParams } from '@material-ui/data-grid';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';

import getValidationError from '../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../hooks/http';

/** componentes */
import Layout from '../../components/Layout';
import CustomTable, { Button } from '../../components/CustomTable';
import { Select, ButtonIcon } from '../../components/Forms';
import { MapIcon, FilterIcon } from '../../styles/icons';

import {
  Container, Content, Header, WrapperSelect,
} from './styles';

/** interface */

const Sectors: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { httpGet, loading } = useHttp();
  const [managements, setManagements] = useState([]);
  const [sectors, setSectors] = useState([]);

  const breadcrumbs = {
    icon: MapIcon, links: [{ title: 'Setores', path: '' }],
  };

  useEffect(() => {
    const load = async function load():Promise<any> {
      const { status, data } = await httpGet('/sectors/managements');
      if (status === 200) {
        const managementParsed = data
          .map((management: any) => ({
            value: management.id,
            label: management.initials,
          }));
        setManagements(managementParsed);
      }
    };

    load();
  }, [httpGet]);

  const handleSubmit = useCallback(async (payload: any) => {
    const management_id = payload?.management_id?.value || null;

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        management_id: Yup.string().ensure().required('Gerência é obrigatória'),
      });

      await schema.validate(payload, { abortEarly: false });
      const { status, data } = await httpGet('/sectors', {
        params: {
          management_id,
        },
      });

      if (status === 200) {
        setSectors(data);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }

  }, []);// eslint-disable-line

  const columns: GridColDef[] = [
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params: ValueGetterParams) => (
        <Button icon="edit" style={{ marginLeft: 8 }} onClick={() => history.push(`/sector/${params.row?.id}`)} />
      ),
    },
    { field: 'initials', headerName: 'Sigla', width: 200 },
    { field: 'name', headerName: 'Nome', width: 400 },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params: ValueGetterParams) => (
        <>{params.row?.status ? 'Ativo' : 'Inativo'}</>
      ),
    },

  ];
  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content>
          <Header>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <WrapperSelect>
                <Select
                  name="management_id"
                  options={managements}
                />
              </WrapperSelect>

              <ButtonIcon type="submit" title="Filtrar" isLoading={loading}>
                <FilterIcon />
              </ButtonIcon>

            </UForm>
          </Header>
          <CustomTable columns={columns} rows={sectors} />
        </Content>
      </Container>
    </Layout>
  );
};

export default Sectors;
