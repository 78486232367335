import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useHttp } from '../../../hooks/http';

import Layout from '../../../components/Layout';
import Flex, { Grid } from '../../../components/Flex';
import { Container, WrapperLink } from './styles';
import { ListIcon, ELinkIcon } from '../../../styles/icons';
import { IClosingForm, IOrder } from '../../../interfaces';

import { formatPlate, formatDate, formatCpf } from '../../../utils';

const breadcrumbs = {
  icon: ListIcon,
  links: [
    { path: '/closing-forms', title: 'Formulário de encerramentos' },
    { path: '', title: 'Formulário de encerramento' },
  ],
};

const Detail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { httpGet } = useHttp();
  const [closingForm, setClosingForm] = useState<IClosingForm>({} as IClosingForm);
  const [order, setOrder] = useState<IOrder>({} as IOrder);

  useEffect(() => {
    (async () => {
      const [resOrder, resClosingForm] = await Promise.all([
        await httpGet(`/orders/detail/${id}`, undefined, false),
        await httpGet(`/closing-forms/order/${id}`, undefined, false),
      ]);

      if (resOrder.data) {
        const [ref] = resOrder.data?.id.split('-');
        setOrder({
          ...resOrder.data,
          ref: ref.toUpperCase(),
        });
      }

      if (resClosingForm.data) {
        setClosingForm(resClosingForm.data);
      } else {
        history.push('/dashboard');
      }
    })();
  }, [history, httpGet, id]);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <h2>Consulta #{order.ref}</h2>
        {order.type === 'vehicle' && (
          <>
            <Grid container spacing={1}>
              <Flex title="Placa" xs={12} sm={6} md={3}>
                {formatPlate(order.vehicle?.plate)}
              </Flex>
              <Flex title="Chassi" xs={12} sm={6} md={3}>
                {order.vehicle?.chassi}
              </Flex>
              <Flex title="Marca/Modelo" xs={12} sm={6} md={3}>
                {order.vehicle?.brand}
              </Flex>
              <Flex title="Color" xs={12} sm={6} md={3}>
                {order.vehicle?.color}
              </Flex>
              <Flex title="Observação" xs={12}>
                {order.note}
              </Flex>
            </Grid>
          </>
        )}
        {order.type === 'person' && (
          <>
            <Grid container spacing={1}>
              <Flex title="Nome" xs={12} sm={6} md={3}>
                {order.person?.name}
              </Flex>
              <Flex title="CPF" xs={12} sm={6} md={3}>
                {formatCpf(order.person?.document)}
              </Flex>
              <Flex title="RG" xs={12} sm={6} md={3}>
                {order.person?.document_secondary}
              </Flex>
              <Flex title="Nascimento" xs={12} sm={6} md={3}>
                {formatDate(order.person?.birthday)}
              </Flex>
              <Flex title="Observação" xs={12}>
                {order.note}
              </Flex>
            </Grid>
          </>
        )}

        <h2>
          Formulário de encerramento
        </h2>
        <Grid container spacing={1}>
          <Flex title="UPAJ" xs={12} sm={6} md={3}>
            {closingForm.upaj?.initials}
          </Flex>
          <Flex title="Tipo de registro" xs={12} sm={6} md={3}>
            {closingForm.registerType?.name}
          </Flex>
          <Flex title="Número de registro" xs={12} sm={6} md={3}>
            {closingForm.register_number}
          </Flex>
          <Flex title="Situação final da ocorrência" xs={12} sm={6} md={6}>
            {closingForm.endSituation?.name}
          </Flex>
          <Flex title="Motivo da Prisão" xs={12} sm={6} md={6}>
            {closingForm.motive?.name}
          </Flex>
          <Flex title="Dinâmica" xs={12}>
            {closingForm.description}
          </Flex>
        </Grid>
        <Grid container spacing={1} justify="flex-end">
          <Grid item xs={12} sm={6} md={4}>
            <WrapperLink>
              <Link to="/dashboard">
                <ELinkIcon />
                Dashboard
              </Link>
            </WrapperLink>
          </Grid>
        </Grid>
      </Container>

    </Layout>
  );
};

export default Detail;
