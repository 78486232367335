import React, { ButtonHTMLAttributes } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ClipLoader from 'react-spinners/ClipLoader';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>{
  title: string,
  placement?: 'top' | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | undefined;
  size?: 'large' | 'medium' | 'small';
  color?: 'primary' | 'secondary' | 'transparent' | undefined;
  isLoading?: boolean;
  disableForce?: boolean;
}

const ButtonIcon: React.FC<ButtonProps> = ({
  children,
  size = 'medium',
  color = 'primary',
  placement = 'top',
  title,
  isLoading = false,
  onClick,
  disableForce = false,
  ...rest
}) => (
  <Tooltip title={title} placement={placement} arrow>
    <span>
      <Container
        type="button"
        disabled={disableForce || isLoading}
        color={color}
        size={size}
        isLoading={isLoading}
        disableForce={disableForce}
        onClick={isLoading ? undefined : onClick}
        {...rest}
      >
        {isLoading
          ? (
            <ClipLoader
              size={16}
              color="#fff"
              loading={isLoading}
            />
          )
          : children}
      </Container>
    </span>
  </Tooltip>

);

export default ButtonIcon;
