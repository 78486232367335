import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import getValidationError from '../../../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../../../hooks/http';
import { useToast } from '../../../../../hooks/toast';

/** componentes */
import { Grid } from '../../../../../components/Flex';
import {
  WrapperLabel, Input, Select, Button,
} from '../../../../../components/Forms';
import { Container } from './styles';

const Save: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();
  const { id } = useParams<{ id?: string }>();
  const {
    httpGet, httpPost, httpPut, loading,
  } = useHttp();
  const [types] = useState([
    { value: 'person', label: 'Pessoa' },
    { value: 'vehicle', label: 'Veículo' },
  ]);

  useEffect(() => {
    (async () => {
      if (id && id !== 'new') {
        const { status, data } = await httpGet(`/end-situations/${id}`);
        if (status === 200) {
          const parsed = {
            ...data,
            type: types.find((item) => item.value === data.type),
          };

          formRef.current?.setData(parsed);
        }
      }
    })();
  }, [httpGet, id, types]);

  const handleSubmit = useCallback(async (payload: any) => {
    const data = {
      ...payload,
      type: payload?.type.value,
    };
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        type: Yup.string().required('Tipo é obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      if (id && id !== 'new') {
        await httpPut(`/end-situations/${id}`, data);
        addToast({ title: 'Editado com sucesso!' });
      } else {
        const { status, data: endSituations } = await httpPost('end-situations', data);
        if (status === 200) {
          history.push(`/settings/end-situations/${endSituations.id}`);
          addToast({ title: 'Salvo com sucesso!' });
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [id, httpPut, addToast, httpPost, history]);

  return (
    <Container>
      <h1>{id && id !== 'new' ? 'Editar' : 'Nova'} Situação final da ocorrência</h1>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Nome" xs={6}>
            <Input name="name" />
          </WrapperLabel>
          <WrapperLabel label="Tipo" xs={6}>
            <Select name="type" options={types} />
          </WrapperLabel>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item xs={12} md={2}>
            <Button type="submit" isLoading={loading}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </UForm>
    </Container>
  );
};

export default Save;
