import React from 'react';
import { useHistory } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

/** hooks */
import { useAuth } from '../../../hooks/auth';

/** components */
import Flex, { Grid } from '../../../components/Flex';
import {
    Menu, MenuItem, DotButton, ItemIcon,
} from '../../../components/CustomMenu';

import { Container, WrapperMenu } from './styles';
import { PencilIcon } from '../../../styles/icons';
import { IMake } from '../../../interfaces';

const Card: React.FC<{ make: IMake }> = ({ make }) => {
    const history = useHistory();
    const { iCan } = useAuth();

    return (
        <Container>
            <Grid container spacing={1}>
                {iCan('manager') && (
                    <WrapperMenu>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <>
                                    <DotButton {...bindTrigger(popupState)} />
                                    <Menu {...bindMenu(popupState)}>
                                        <MenuItem
                                            onClick={() => { history.push(`/model/${make.id}`); }}
                                            style={{ width: 150 }}
                                        >
                                            <ItemIcon icon={<PencilIcon />} title="Editar" />
                                        </MenuItem>
                                    </Menu>
                                </>
                            )}
                        </PopupState>
                    </WrapperMenu>
                )}
                <Flex title="Cod" xs={2}>
                    {make.id}
                </Flex>
                <Flex title="Nome" textAutoSize xs={10}>
                    {make.name}
                </Flex>
            </Grid>
        </Container>
    );
};

export default Card;
