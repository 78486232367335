import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const WrapperMenu = styled.div`
  position: absolute;
  top: 16px;
  right: 12px;
`;
