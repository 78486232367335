import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useAuth } from 'hooks/auth';
import AsyncSelect from 'react-select/async';
import { useToast } from 'hooks/toast';
import * as Yup from 'yup';
import { FaUserShield } from 'react-icons/fa';
import aPolice from 'utils/aPolice';
import ICivilPolice from 'interfaces/civilPolice';
import { BsPencil } from 'react-icons/bs';
import Simple from 'components/Headers/Simple';
import {
  Container, GridButtons, WrapperAddress, customStyle, WrapperBtnCancel, WrapperBtnSubmit, Title,
} from '../styles';
import {
  WrapperLabel, Select, Button, Input,
} from '../../../../components/Forms';
import { useHttp } from '../../../../hooks/http';
import getValidationError from '../../../../utils/getValidationsErros';
import Layout from '../../../../components/Layout';

interface IOptions {
  value: string;
  label: string;
}

const PoliceStorage: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [polices, setPolice] = useState<ICivilPolice>();
  const history = useHistory();
  const ROId = localStorage.getItem('ROId');
  const { id } = useParams<{ id: string }>();
  const [uapj, setUapj] = useState<IOptions>({} as IOptions);      
  const { httpPut, httpGet } = useHttp();
  const { user } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    async function load(): Promise<any> {
      const { status, data } = await httpGet(`/civilPolices/${id}`);
      if (status === 200) {
        setPolice(data);
      }
    }
    load();
  }, [httpGet, id, user]);

  useEffect(() => {
    formRef.current?.setData({
      bopm_id: polices?.bopm_id,
      name: polices?.name,
      document: polices?.document,
      office_dp: { value: polices?.office_dp, label: polices?.office_dp },
    });
    setUapj({ value: `${polices?.upaj_id}`, label: `${`${polices?.initials} ${polices?.local}`}`});
    
  }, [polices]);

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 2) return;
    const upaj: any = [];
    const { status, data } = await httpGet(`/upajs/?page=1&perPage=20&filter=${inputValue ?? ''}`);
    if (status === 200) {
      data.data.forEach((item: any) => {
        upaj.push({
          value: item.id,
          label: `${item.initials} ${item.name}`,
        });
      });
    }
    callback(upaj);
  };

  const handleChangeAgent = useCallback((event: any) => {
    setUapj(event);
  }, []);

  const handleSubmit = useCallback(async (data: any) => {
    const _police = {
      ...data,
      bopm_id: ROId,
      upaj_id: uapj?.value,
      name: data.name,
      document: data.document,
      office_dp: data.office_dp?.value,
    };

    try {
    formRef.current?.setErrors({});
    const schema = Yup.object().shape({
      upaj_id: Yup.string().required('DP é obrigatória'),
      name: Yup.string().required('Nome é obrigatória'),
      office_dp: Yup.string().required('Função é obrigatória'),
    });

    await schema.validate(_police, { abortEarly: false });
    const { status } = await httpPut(`civilPolices/${id}`, _police);

    if (status === 200) {
      addToast({ title: 'Salvo com sucesso!', type: 'success' });
      history.push('/RO/polices/show');
    } else {
      addToast({ title: 'Não foi possível gravar!', type: 'error' });
    }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
      formRef.current?.setErrors(erros);
      }
    }
  }, [ROId, uapj, httpPut, id, addToast, history]);

  const breadcrumbs = {
    icon: FaUserShield,
    links: [
      { title: 'RO-SP / Autoridade / Editar ', path: `/RO/${ROId}` },
    ],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Simple>
          <Title>
            <BsPencil /> Editar Autoridade
          </Title>
        </Simple>
        <WrapperAddress>
          <Container>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>

                <WrapperLabel label="Nome" xs={12} md={6} sm={6}>
                  <Input name="name" placeholder="" style={{ textTransform: 'uppercase' }}/>
                </WrapperLabel>

                <WrapperLabel label="Função" xs={12} md={6} sm={6}>
                  <Select
                    name="office_dp"
                    options={aPolice}
                    style={{ textTransform: 'uppercase' }}
                  />
                </WrapperLabel>

                <WrapperLabel label="Matrícula" xs={12} sm={6} md={6}>
                  <Input name="document" placeholder="" style={{ textTransform: 'uppercase' }}/>
                </WrapperLabel>

                <WrapperLabel label="Delegacia" xs={12} md={6} sm={6}>
                  <div style={{ width: '100%', textTransform: 'uppercase' }} >
                    <AsyncSelect
                      name="upaj_id"
                      placeholder="Digite o número"
                      noOptionsMessage={() => 'Sem registos'}
                      cacheOptions
                      isClearable
                      loadOptions={loadOptions}
                      onChange={handleChangeAgent}
                      value={uapj}
                      styles={customStyle}
                    />
                  </div>
                </WrapperLabel>
                
              </Grid>
              <GridButtons>
                <WrapperBtnCancel>
                  <Button type="button" onClick={() => { history.push('/RO/polices/show'); }}>
                    Cancelar
                  </Button>
                </WrapperBtnCancel>
                <WrapperBtnSubmit>
                  <Button type="submit">
                    Salvar
                  </Button>
                </WrapperBtnSubmit>
              </GridButtons>
            </UForm>
          </Container>
        </WrapperAddress>
      </Container>
    </Layout>
  );
};

export default PoliceStorage;
