import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import { Content } from 'components/Forms/WrapperLabel/styles';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import getCurrentPosition from 'utils/getCurrentPosition';
import Simple from '../../../components/Headers/Simple';
/** hooks */
import { useHttp } from '../../../hooks/http';
import {
  WrapperLabel, Select, Button, InputHidden, ButtonIcon, Input, InputMask,
} from '../../../components/Forms';
import { InfoIcon, TrashIcon } from '../../../styles/icons';
import {
  Container, GridButtons, WrapperBtnInfo, WrapperBtnCancel,
  WrapperBtnSubmit, Text, WrapperRemove,
} from './styles';
import getValidationError from '../../../utils/getValidationsErros';

interface IFormData {
  coord?: {
    lat: number,
    lng: number
  }
}

const Form: React.FC<IFormData> = ({ coord }) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [municipio, setMunicipio] = useState([]);
  const prefix = `@${process.env.REACT_APP_NAME}`;
  const { loading, httpGet, httpPost } = useHttp();
  const { user, refreshUser } = useAuth();
  const { addToast } = useToast();
  const [disabledCity, setDisabledCity] = useState(false);
  const [disabledKnowledge, setDisabledKnowledge] = useState(false);

  const handleDelete = useCallback(() => {
    try {
      const userStore = localStorage.getItem(`${prefix}:user`);
      if (userStore) {
        const userParsed = JSON.parse(userStore);
        delete (userParsed.action);
        localStorage.setItem(`${prefix}:user`, JSON.stringify(userParsed));
        refreshUser();
        history.push('/dashboard');
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [prefix, refreshUser, history]);

  useEffect(() => {
    async function loadDitricts(): Promise<any> {
      const { status, data } = await httpGet('https://servicodados.ibge.gov.br/api/v1/localidades/estados/33/municipios');
      if (status === 200) {
        const municipioParsed = data
          .map((municipio: any) => ({
            value: municipio.nome,
            label: municipio.nome,
          }));
        setMunicipio(municipioParsed);
      }
    }

    loadDitricts();
  }, [httpGet]);

  useEffect(() => {
    formRef.current?.setData({});
  }, []);

  const handleSubmit = useCallback(async (data: any) => {
    formRef.current?.setErrors({});
    let additionSchema;
    if (user?.action?.type === 'OBRA') {
      additionSchema = {
        quality_work: Yup.string().required('Qualidade da obra é obrigatório'),
      };
    }

    const _ceperj = {
      ...data,
      ...coord,
      action_id: data.action_id,
      gender: data.gender?.value,
      city: data.city?.value,
      lived_city: data.lived_city?.value,
      long_lived_city: data.long_lived_city?.value,
      residence: data.residence?.value,
      ethnicity: data.ethnicity?.value,
      marital: data.marital?.value,
      schooling: data.schooling?.value,
      currently: data.currently?.value,
      income: data.income?.value,
      implantation: data.implantation?.value,
      knowledge: data.knowledge?.value,
      need: data.need?.value,
      quality_work: data.quality_work?.value,
      improvement_region: data.improvement_region?.value,
      improvement_life: data.improvement_life?.value,
      impact_region: data.impact_region?.value,
      management_id: user?.allocation?.management_id,
    };

    try {
      const schema = Yup.object().shape({
        city: Yup.string().required('Município é obrigatório'),
        name: Yup.string().ensure().required('Nome é obrigatório'),
        gender: Yup.string().ensure().required('Gênero é obrigatório'),
        age: Yup.number().typeError('Idade é obrigatório, somente números'),
        lived_city: Yup.string().required('Reside neste Município é obrigatório'),
        long_lived_city: Yup.string().when('lived_city', {
          is: (lived_city) => lived_city === 'Sim', then: Yup.string().ensure().required('Quanto anos você reside neste município é obrigatório'),
        }),
        residence: Yup.string().ensure().required('Seu domicílio é obrigatório'),
        ethnicity: Yup.string().ensure().required('Raça/cor/etnia é obrigatório'),
        marital: Yup.string().ensure().required('Estado civil é obrigatório'),
        schooling: Yup.string().ensure().required('Escolaridade é obrigatório'),
        currently: Yup.string().ensure().required('Atualmente é obrigatório'),
        income: Yup.string().ensure().required('Renda é obrigatório'),
        implantation: Yup.string().ensure().required('Tem conhecimento da implantação é obrigatório'),
        knowledge: Yup.string().when('implantation', {
          is: (implantation) => implantation === 'Sim', then: Yup.string().ensure().required('Como tomou conhecimento é obrigatório, somente números'),
        }),
        need: Yup.string().required('Qual a sua opinião sobre a necessidade... é obrigatório'),
        improvement_region: Yup.string().ensure().required('A realização... é obrigatório'),
        improvement_life: Yup.string().required('A realização... é obrigatório, somente números'),
        impact_region: Yup.string().required('Como você percebe os efeitos... é obrigatório'),
        ...additionSchema,
      });

      await schema.validate(_ceperj, { abortEarly: false });

      const position = await getCurrentPosition();

      const { status } = await httpPost('quizzes', {
        ..._ceperj,
        current_lat: position.lat,
        current_lng: position.lng,
      });

      if (status === 200) {
        addToast({ title: 'Salvo com sucesso!', type: 'success' });
        history.push('/dashboard');
      } else {
        addToast({ title: 'Não foi possível gravar!', type: 'error' });
        history.push('/dashboard');
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [addToast, coord, history, httpPost, user]);

  const handleFieldCity = useCallback((lived_city: any) => {
    setDisabledCity(lived_city?.value === 'Não');
  }, []);

  const handleFieldKnowledge = useCallback((Knowledge: any) => {
    setDisabledKnowledge(Knowledge?.value === 'Não');
  }, []);

  return (
    <>
      <Content>
        <Simple>
          <Text>{user?.action?.name}</Text>
          <WrapperRemove onClick={handleDelete}>
            <ButtonIcon
              title="Excluir"
            >
              <TrashIcon color="white" />
            </ButtonIcon>
          </WrapperRemove>
        </Simple>
      </Content>
      <Container>
        <UForm ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <InputHidden name="action_id" defaultValue={user?.action?.id} />
            <InputHidden name="type" defaultValue={user?.action?.type} />
            <WrapperLabel label="Nome do entrevistado" xs={12} sm={6}>
              <Input name="name" />
            </WrapperLabel>
            <WrapperLabel label="Município da entrevista" xs={12} md={6} sm={6}>
              <Select
                name="city"
                options={municipio}
              />
            </WrapperLabel>
            <WrapperLabel label="Gênero" xs={12} sm={6} md={6}>
              <Select
                name="gender"
                options={[
                  { value: 'Masculino', label: 'Masculino' },
                  { value: 'Feminino', label: 'Feminino' },
                ]}
              />
            </WrapperLabel>
            <WrapperLabel label="Idade" xs={12} sm={6} md={6}>
              <InputMask name="age" type="tel" mask="99" placeholder="Somente números" />
            </WrapperLabel>
            <WrapperLabel label="Reside neste Município" xs={12} md={6} sm={6}>
              <Select
                name="lived_city"
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Não', label: 'Não' },
                ]}
                onChange={handleFieldCity}
              />
            </WrapperLabel>
            <WrapperLabel label="Quanto anos você reside neste município?" xs={12} md={6} sm={6}>
              <Select
                name="long_lived_city"
                options={[
                  { value: 'Menos de 1 ano    ', label: 'Menos de 1 ano' },
                  { value: 'Entre 1 e 5 anos', label: 'Entre 1 e 5 anos' },
                  { value: 'Entre 6 e 10 anos', label: 'Entre 6 e 10 anos' },
                  { value: 'Mais de 10 anos', label: 'Mais de 10 anos' },
                ]}
                isDisabled={disabledCity}
              />
            </WrapperLabel>

            <WrapperLabel label="Seu domicílio é?" xs={12} md={6} sm={6}>
              <Select
                name="residence"
                options={[
                  { value: 'Próprio quiado', label: 'Próprio quiado' },
                  { value: 'Próprio não quiado', label: 'Próprio não quiado' },
                  { value: 'Alugado', label: 'Alugado' },
                  { value: 'Cedido', label: 'Cedido' },
                  { value: 'Outra condição', label: 'Outra condição' },
                ]}
              />
            </WrapperLabel>

            <WrapperLabel label="Qual sua cor/raça/etnia?" xs={12} md={6} sm={6}>
              <Select
                name="ethnicity"
                options={[
                  { value: 'Preta', label: 'Preta' },
                  { value: 'Parda', label: 'Parda' },
                  { value: 'Branca', label: 'Branca' },
                  { value: 'Indígena', label: 'Indígena' },
                  { value: 'Amarela', label: 'Amarela' },
                ]}
              />
            </WrapperLabel>

            <WrapperLabel label="Qual seu estado civil?" xs={12} md={6} sm={6}>
              <Select
                name="marital"
                options={[
                  { value: 'Solteiro(a)', label: 'Solteiro(a)' },
                  { value: 'Casado(a)', label: 'Casado(a)' },
                  { value: 'Divorciado(a)', label: 'Divorciado(a)' },
                  { value: 'Separado(a)/Desquitado(a)', label: 'Separado(a)/Desquitado(a)' },
                  { value: 'Viúvo(a)', label: 'Viúvo(a)' },
                  { value: 'Em união estável', label: 'Em união estável' },
                  { value: 'Morando junto', label: 'Morando junto' },
                ]}
              />
            </WrapperLabel>

            <WrapperLabel label="Escolaridade?" xs={12} md={6} sm={6}>
              <Select
                name="schooling"
                options={[
                  { value: 'Não sabe ler/escrever', label: 'Não sabe ler/escrever' },
                  { value: 'Ensino fundamental incompleto', label: 'Ensino fundamental incompleto' },
                  { value: 'Ensino fundamental completo', label: 'Ensino fundamental completo' },
                  { value: 'Ensino médio incompleto', label: 'Ensino médio incompleto' },
                  { value: 'Ensino médio completo', label: 'Ensino médio completo' },
                  { value: 'Ensino superior incompleto', label: 'Ensino superior incompleto' },
                  { value: 'Ensino superior completo(mestrado/doutorado/pós', label: 'Ensino superior completo(mestrado/doutorado/pós' },
                ]}
              />
            </WrapperLabel>

            <WrapperLabel label="Atualmente você?" xs={12} md={6} sm={6}>
              <Select
                name="currently"
                options={[
                  { value: 'Trabalha', label: 'Trabalha' },
                  { value: 'Estuda', label: 'Estuda' },
                  { value: 'Aposentado(a)/pensionista', label: 'Aposentado(a)/pensionista' },
                  { value: 'Do lar', label: 'Do lar' },
                ]}
              />
            </WrapperLabel>

            <WrapperLabel label="Qual a sua renda individual aproximada?" xs={12} md={6} sm={6}>
              <Select
                name="income"
                options={[
                  { value: 'Menos que um salário mínimo', label: 'Menos que um salário mínimo' },
                  { value: 'Um salário mínimo', label: 'Um salário mínimo' },
                  { value: 'Dois salários mínimos', label: 'Dois salários mínimos' },
                  { value: 'Três ou mais salários mínimos', label: 'Três ou mais salários mínimos' },
                  { value: 'Não informada', label: 'Não informada' },
                ]}
              />
            </WrapperLabel>

            <WrapperLabel label={`Tem conhecimento da implantação do(a) ${user?.action?.type}?`} xs={12} md={6} sm={6}>
              <Select
                name="implantation"
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Não', label: 'Não' },
                ]}
                onChange={handleFieldKnowledge}
              />
            </WrapperLabel>
            <WrapperLabel label={`Como tomou conhecimento do(a) ${user?.action?.type}?`} xs={12} md={6} sm={6}>
              <Select
                name="knowledge"
                options={[
                  { value: 'Tv', label: 'Tv' },
                  { value: 'Jornal', label: 'Jornal' },
                  { value: 'Internet', label: 'Internet' },
                  { value: 'Por amigos', label: 'Por amigos' },
                  { value: 'Ao passar pelo local', label: 'Ao passar pelo local' },
                ]}
                isDisabled={disabledKnowledge}
              />
            </WrapperLabel>
            <WrapperLabel label={`Qual a sua opinião sobre a necessidade do(a) ${user?.action?.type}?`} xs={12} md={6} sm={6}>
              <Select
                name="need"
                options={[
                  { value: 'Muito importante', label: 'Muito importante' },
                  { value: 'Importante', label: 'Importante' },
                  { value: 'Razoavelmente importante', label: 'Razoavelmente importante' },
                  { value: 'Pouco importante', label: 'Pouco importante' },
                  { value: 'Sem importância', label: 'Sem importância' },
                ]}
              />
            </WrapperLabel>
            {user?.action?.type === 'OBRA' ? (
              <WrapperLabel label="Qual a sua opinião sobre a qualidade da obra" xs={12} md={6} sm={6}>
                <Select
                  name="quality_work"
                  options={[
                    { value: 'Muito ruim', label: 'Muito ruim' },
                    { value: 'Ruim', label: 'Ruim' },
                    { value: 'Regular', label: 'Regular' },
                    { value: 'Bom', label: 'Bom' },
                    { value: 'Muito bom', label: 'Muito bom' },
                  ]}
                />
              </WrapperLabel>
            ) : ''}
            <WrapperLabel label={`A realização do(a) ${user?.action?.type} trouxe alguma melhoria para a região?`} xs={12} sm={6} md={6}>
              <Select
                name="improvement_region"
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Não', label: 'Não' },
                ]}
              />
            </WrapperLabel>
            <WrapperLabel label={`A realização do(a) ${user?.action?.type} trouxe alguma melhoria para sua vida?`} xs={12} sm={6} md={6}>
              <Select
                name="improvement_life"
                options={[
                  { value: 'Sim', label: 'Sim' },
                  { value: 'Não', label: 'Não' },
                ]}
              />
            </WrapperLabel>
            <WrapperLabel label={`Como você percebe os efeitos do(a) ${user?.action?.type} e o impacto social na região?`} xs={12} sm={6} md={6}>
              <Select
                name="impact_region"
                options={[
                  { value: 'Muito ruim', label: 'Muito ruim' },
                  { value: 'Ruim', label: 'Ruim' },
                  { value: 'Regular', label: 'Regular' },
                  { value: 'Bom', label: 'Bom' },
                  { value: 'Muito bom', label: 'Muito bom' },
                ]}
              />
            </WrapperLabel>
          </Grid>
          <GridButtons>
            <WrapperBtnInfo>
              <ButtonIcon
                title="Instruções"
              >
                <InfoIcon />
              </ButtonIcon>
            </WrapperBtnInfo>
            <WrapperBtnCancel>
              <Button
                type="button"
                onClick={() => { history.push('/dashboard'); }}
              >
                Cancelar
              </Button>
            </WrapperBtnCancel>
            <WrapperBtnSubmit>
              <Button
                type="submit"
                isLoading={loading}
              >
                Salvar
              </Button>
            </WrapperBtnSubmit>
          </GridButtons>
        </UForm>
      </Container>
    </>
  );
};

export default Form;
