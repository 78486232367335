import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius : 4px;

  + div{
    margin-top: 16px;
  }
`;

export const Aside = styled.div`
  display: flex;
  width: 40px;

  flex-direction: column;
  align-items: center;
  justify-content: center;


  a{
    color: var(--color-secondary);

    &:hover{
      color: var(--color-secondary-h);
    }
  }
`;

export const Content = styled.div`
  display: flex;
  width: calc(100% - 70px);
`;
