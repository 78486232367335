import React from 'react';
import CardHeader from './CardHeader';
import CardContent from './CardContent';
import CardFooter from './CardFooter';

import { Container } from './styles';

const Card: React.FC = ({ children }) => (
  <Container>
    {children}
  </Container>
);

export default Card;

export { CardHeader, CardContent, CardFooter };
