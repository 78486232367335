import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import {
  formatDateTime, formatCpf, formatDate, formatPlate, distanceDateTime,
} from '../../../../utils';

/** hooks */
import { useHttp } from '../../../../hooks/http';
import { useToast } from '../../../../hooks/toast';

/** components */
import Layout from '../../../../components/Layout';
import Flex, { Grid } from '../../../../components/Flex';
import {
  CarIcon, PersonIcon, ListIcon, MapIcon,
} from '../../../../styles/icons';
import {
  Container, Content, WrapperHeader, Title, WrapperLink, WrapperSeparator,
} from './styles';

/** interface */
import { IOrder } from '../../../../interfaces';

const breadcrumbs = {
  icon: ListIcon,
  links: [
    { path: '/orders/answered', title: 'Consultas' },
    { path: '', title: 'Detalhe da  Resposta' },
  ],
};

const Detail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{id: string}>();
  const { httpGet, loading } = useHttp();
  const { addToast } = useToast();
  const [order, setOrder] = useState<IOrder>({}as IOrder);

  useEffect(() => {
    async function loadAnswer():Promise<void> {
      const { status, data } = await httpGet(`orders/detail/${id}`);
      if (status === 200) {
        const [ref] = data.id.split('-');
        setOrder({
          ...data,
          ref: ref.toUpperCase(),
        });
      } else {
        addToast({ title: 'Erro ao tentar acessar a consulta.', type: 'error' });
        history.push('/orders/answered');
      }
    }
    loadAnswer();

  }, [addToast, history, id]);  // eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <Content isConduction={!!order.result?.conduction}>
          <Grid container spacing={1} justify="space-between">
            <Grid item xs={12} sm={8} md={6}>
              <WrapperHeader>
                <span>
                  { order.type === 'vehicle' ? <CarIcon /> : <PersonIcon />}
                  #{ order.ref}
                </span>
                <h2>{order.result?.name}</h2>
              </WrapperHeader>
            </Grid>
            <Flex title="Gerência" xs={12} sm="auto">
              {order.management?.name}
              {order.sector && ` - ${order.sector.initials}`}
            </Flex>
          </Grid>
          <Grid container spacing={1}>
            <Flex title="Solicitado em" xs={6} sm={6} md={2}>
              <WrapperLink>
                {formatDateTime(order.created_at)}
                {order?.address?.latitude && (
                <a
                  href={`https://www.google.com.br/maps/search/${order.address.latitude},${order.address.longitude}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Local da abordagem"
                >
                  <MapIcon />
                </a>
              )}
              </WrapperLink>
            </Flex>
            <Flex title="Respondido em" xs={6} sm={6} md={2}>
              {formatDateTime(order.updated_at)}
            </Flex>

            <Flex title="Tempo de espera" xs={6} sm={6} md={2}>
              {distanceDateTime(order.created_at, order.updated_at, true)}
            </Flex>

            <Flex title="Sistemas consultados" xs={6} sm={6} md={6} textAutoSize>
              {order?.systems?.map((system: any) => (
                <WrapperSeparator key={system.id}>
                  {system.name}
                </WrapperSeparator>
              ))}.
            </Flex>

            <Flex title="Solicitado por" xs={12} sm={12} md={6}>
              <WrapperLink>
                {formatCpf(order.user?.document)} - { order.user?.name} {order.user?.phone}
                {order?.latitude && (
                <a
                  href={`https://www.google.com.br/maps/search/${order.latitude},${order.longitude}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Local da solicitação"
                >
                  <MapIcon />
                </a>
              )}
              </WrapperLink>
            </Flex>

            <Flex title="Respondido por" xs={12} sm={12} md={6} textAutoSize>
              {order.operator_id && (
              <>
                {formatCpf(order.operator?.document)} - { order.operator?.name}
              </>
              )}

            </Flex>

          </Grid>

          <Title>Dados informados para consulta:</Title>
          <Grid container spacing={1}>
            {order.type === 'person' && (
            <>
              <Flex title="CPF" xs={4} sm={6} md={3}>
                {order.document ? formatCpf(order.document) : 'não informado'}
              </Flex>
              <Flex title="RG" xs={4} sm={6} md={3}>
                {order.document_secondary || 'não informado'}
              </Flex>
              <Flex title="Nascimento" xs={4} sm={6} md={4}>
                {order.birthday ? formatDate(order.birthday) : 'não informado'}
              </Flex>
              <Flex title="Nome" xs={12} sm={6} md={4}>
                {order.name || 'não informado'}
              </Flex>
              <Flex title="Mãe" xs={6} sm={6} md={4}>
                {order.mather || 'não informado'}
              </Flex>
              <Flex title="Pai" xs={6} sm={6} md={4}>
                {order.father || 'não informado'}
              </Flex>
            </>
            ) }

            {order.type === 'vehicle' && (
            <>
              <Flex title="Placa" xs={12} sm={4} md={4}>
                {order.plate ? formatPlate(order.plate) : 'não informado'}
              </Flex>
              <Flex title="Chassi" xs={12} sm={4} md={4}>
                {order.chassi || 'não informado'}
              </Flex>
              <Flex title="Motor" xs={12} sm={4} md={4}>
                {order.engine_number || 'não informado'}
              </Flex>
              <Flex title="Marca/Modelo" xs={12} sm={4} md={4}>
                {order.brand || 'não informado'}
              </Flex>
              <Flex title="Cor" xs={12} sm={4} md={4}>
                {order.color || 'não informado'}
              </Flex>
            </>
            ) }

            <Flex title="Observação" xs={12} sm={6} md={4}>
              {order.description || 'não informado'}
            </Flex>

          </Grid>

          { (order.result_id !== 1 && order.type === 'person') && (
          <>
            <Title>Pessoa encontrada:</Title>
            <Grid container spacing={1}>
              <Flex title="CPF" xs={4} sm={6} md={3}>
                {order.person?.document ? formatCpf(order.person?.document) : 'não informado'}
              </Flex>
              <Flex title="RG" xs={4} sm={6} md={3}>
                {order.person?.document_secondary || 'não informado'}
              </Flex>
              <Flex title="Nascimento" xs={4} sm={6} md={4}>
                {order.person?.birthday ? formatDate(order.person?.birthday) : 'não informado'}
              </Flex>
              <Flex title="Nome" xs={12} sm={6} md={4}>
                {order.person?.name || 'não informado'}
              </Flex>
              <Flex title="Mãe" xs={6} sm={6} md={4}>
                {order.person?.mather || 'não informado'}
              </Flex>
              <Flex title="Pai" xs={6} sm={6} md={4}>
                {order.person?.father || 'não informado'}
              </Flex>
              <Flex title="Observação" xs={12} sm={12}>
                {order.note || 'não informado'}
              </Flex>

            </Grid>
          </>
          )}

          { (order.result_id !== 1 && order.type === 'vehicle') && (
          <>
            <Title>Veículo encontrado:</Title>
            <Grid container spacing={1}>
              <Flex title="Placa" xs={12} sm={4} md={4}>
                {formatPlate(order.vehicle?.plate)}
              </Flex>
              <Flex title="Chassi" xs={12} sm={4} md={4}>
                {order.vehicle?.chassi}
              </Flex>
              <Flex title="Motor" xs={12} sm={4} md={4}>
                {order.vehicle?.engine_number}
              </Flex>
              <Flex title="Marca/Modelo" xs={12} sm={4} md={4}>
                {order.vehicle?.brand}
              </Flex>
              <Flex title="Cor" xs={12} sm={4} md={4}>
                {order.vehicle?.color}
              </Flex>
              <Flex title="Observação" xs={12}>
                {order.note || 'não informado'}
              </Flex>
            </Grid>
          </>
          )}

          { order.result_id === 1 && (
          <>
            <Title>Dados inconsistentes:</Title>
            <Grid container spacing={1}>
              <p>{order.note && order.note}</p>
              <footer>
                Os dados informados para a pesquisa não foram suficientes
                para produzir um resultado satisfatório.{' '}
                Caso consiga novas informações faça um novo pedido de consulta.
              </footer>
            </Grid>
          </>
          )}

        </Content>
      </Container>
    </Layout>
  );
};

export default Detail;
