import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useHttp } from 'hooks/http';
import { IFleet } from 'interfaces';
import HeaderReturn from 'components/Headers/HeaderReturn';
import Tabs, { TabsItem } from '../../../components/Tabs';
import { Container } from './styles';
import { PoliceCarFill } from '../../../styles/icons';
import Main from '../Main';

const breadcrumbs = {
  icon: PoliceCarFill,
  links: [
    { path: '/maintenances', title: 'Viaturas manutenção' },
    { path: '', title: 'Viatura' },
  ],
};

const Form: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { httpGet, loading } = useHttp();
  const [fleet, setFleet] = useState({} as IFleet);
  const history = useHistory();

  useEffect(() => {
    async function loadFleet(): Promise<void> {
      const { status, data } = await httpGet(`fleets/${id}`);
      if (status === 200) {
        setFleet(data);
      } else {
        history.push('maintenances');
      }
    }

    loadFleet();
  }, [history, id]); // eslint-disable-line

  return (
    <Layout breadcrumbs={breadcrumbs} loading={loading}>
      <Container>
        <HeaderReturn handleReturn={() => history.goBack()} />

        <Tabs>
          <TabsItem title="Dados" show>
            <Main fleet={fleet} />
          </TabsItem>
        </Tabs>
      </Container>
    </Layout>
  );
};

export default Form;
