import styled from 'styled-components';

export const Container = styled.div`

  table {
    width: 100%;
    border-spacing: 0;    

    tr {
      
      :nth-child(even) {
        td {
        background-color: #434343;
        }
      }
    }

  

    th {
      margin: 0;
      padding: 0.5rem;
      background: black;
      color: white;
      font-weight: bold;
      
    }  

    td {
      margin: 0;
      padding: 0.5rem;
      background-color: #2c2f38;
      color: white;
      text-align: center;

      :nth-child(2) {
        width: 20%;
      }

      :nth-child(3) {
        text-align: justify;
        width: 20%;
      }

      :last-child {
        border-right: 0;
      }

    }
  }
`;

export const Pagination = styled.div`
  padding: 12px;
  color: white;
  background-color: #2c2f38;
  border-top: 2px solid black;

  button {
    color: var(--color-white);
    background-color:#2c2f38;
    width: 4rem;
    height: 2rem;
  }
`;

export const Containt = styled.div`
  margin-left: 45%;
 `;

 export const Button = styled.button`
  display: flex; 
  width: 100%;
  padding: 14px;
  justify-content: center;

  align-items: center;
  border: none;
  border-radius: 4px;
  color: var(--color-white);
  background-color:#2c2f38;

  font-size: 1.5rem;
  font-weight: 300;


  svg{
    margin-right: 1px;
    margin-left: 1px;
  }
`;