import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;

  padding: 8px 16px;

  ${css`
    .MuiTab-wrapper{ align-items: flex-start; }
  `}
`;

export const Content = styled.div`
  width: 100%;
  padding: 16px;
  background: #fff;
  border-radius: 4px;
`;

export const Card = styled.div`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 16px;

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h1 {
      font-size: 16px;
      font-weight: 700;

    }

    small {
      padding: 4px 8px;
      background: beige;
    }

  }

  ul{
    display: flex;
    border-top: 1px solid var(--color-border);
    margin-top: 16px;
    padding-top: 8px;
    flex-wrap: wrap;
  }

  li {
    list-style: none;

    padding: 8px;
  }

`;
