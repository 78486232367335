import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { isBefore } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../hooks/http';
import { useToast } from '../../../hooks/toast';

/** componentes */
import {
  WrapperLabel, Button, Input, TextArea,
} from '../../../components/Forms';

import { Container } from './styles';

/** interface */
import { IService } from '../../../interfaces';

interface IToEnded {
  service: IService | null;
  handleStatus(id:any):void;
}

const ToEnded: React.FC<IToEnded> = ({ service, handleStatus }) => {
  const formRef = useRef<FormHandles>(null);
  const { httpPost, loading } = useHttp();
  const { addToast } = useToast();

  const validateDate = useCallback((description: any) => {
    if (!description && service?.ended_at
    && isBefore(new Date(), new Date(service?.ended_at))
    ) {
      addToast({
        title: 'Informar Alteração',
        description: 'Você está encerrando um serviço antes do término previsto. É obrigatório informar uma alteração.',
        type: 'error',
        time: 10,
      });
      return false;
    }
    return true;
  }, [addToast, service]);

  const handleSubmit = useCallback(async (payload: any) => {
    const _service = {
      ...payload,
      id: service?.id,
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        description: Yup.string()
          .test('is-valid', 'Alteração é obrigatória.', (value) => validateDate(value)),
      });

      await schema.validate(_service, { abortEarly: false });

      const { status, data } = await httpPost('services/presence/to-ended', _service);
      if (status === 200) {
        handleStatus(data);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [service]);// eslint-disable-line

  return (
    <Container>
      <UForm ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <WrapperLabel label="Agente" xs={12}>
            <Input name="agente" defaultValue={service?.user} readOnly />
          </WrapperLabel>
          <WrapperLabel label="Alterações" xs={12}>
            <TextArea name="description" row={6} />
          </WrapperLabel>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item xs={12}>
            <Button type="submit" isLoading={loading}>Encerrar o Serviço</Button>
          </Grid>
        </Grid>
      </UForm>
    </Container>
  );
};

export default ToEnded;
