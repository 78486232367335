import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { Container } from './styles';

interface ILoading {
  loading: boolean;
}
const Loading: React.FC<ILoading> = () => (

  <Container>
    <BeatLoader
      size={16}
      margin={8}
      color="#7F8C8D"
    />
  </Container>

);

export default Loading;
