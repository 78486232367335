import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';

interface Props extends InputProps {
  name: string;
  disabled?: boolean;
}

const InputMask: React.FC<Props> = ({ name, disabled = false, ...rest }) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const {
    fieldName, registerField, defaultValue, error,
  } = useField(name);

  const [maskValue, setMaskValue] = useState('');
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref: any, value: string) => {
        if (value)setMaskValue(value);
      },
    });
  }, [fieldName, registerField]);

  const handleFocused = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
    setMaskValue(event.target.value);
  };

  return (
    <Container
      readOnly={disabled}
      isErrored={!!error}
      isFocused={isFocused}
      onBlur={handleInputBlur}
    >

      <ReactInputMask
        ref={inputRef}
        name={name}
        onFocus={handleFocused}
        onChange={handleChange}
        defaultValue={defaultValue}
        alwaysShowMask={false}
        disabled={disabled}
        value={maskValue}
        {...rest}
      />

      <Error title={error || ''}>
        <FiAlertCircle color="#c53030" size={20} />
      </Error>
    </Container>
  );
};

export default InputMask;
