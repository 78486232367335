import React, {
  useState, useRef, useCallback, useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import ModalFactory from 'react-modal-promise';
import ReturnSalvePlus from 'components/Headers/ReturnSalvePlus';
import { Divider, FormControlLabel, Switch } from '@material-ui/core';
import { useToast } from 'hooks/toast';
import IInvolved from 'interfaces/involved';
import stringOptions from 'utils/stringOptions';
import { useHttp } from '../../../hooks/http';
import { useAuth } from '../../../hooks/auth';
import Layout from '../../../components/Layout';
import { Button as ButtonMat } from '../../../components/Forms';
import Flex, { Grid } from '../../../components/Flex';
import Card, { CardHeader, CardContent } from '../../../components/Card';
import {
  UsersIcon, PencilIcon, UserIcon, TrashIcon,
} from '../../../styles/icons';
import { formatDate } from '../../../utils';

import {
  Container,
  WrapperName,
  Button,
  Involvements,
  WrapperModal,
  Red,
} from './styles';
import Modal from '../../../components/ModalSimple';
import { TitleModal } from '../styles';

const Involved: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const labelConfRef = useRef<HTMLLabelElement>(null);
  const switchs = useRef<HTMLLabelElement>(null);
  const [involveds, setInvolveds] = useState<IInvolved[]>();
  const [formData, setFormData] = useState<any>();
  const history = useHistory();
  const ROId = localStorage.getItem('ROId');
  const [btnLoading, setBtnLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { httpGet, httpDelete } = useHttp();

  useEffect(() => {
    async function loadInvolveds(): Promise<any> {
      const { status, data } = await httpGet(`/involveds/list/${ROId}`);

      if (status === 200 && data.length > 0) {
        setInvolveds(data);
      } else { history.push('/RO/involved/storage'); }
    }
    loadInvolveds();
  }, [httpGet, user, history, ROId]);

  const handleDeleteInvolved = useCallback(async (involvedId) => {
    setBtnLoading(false);
    const response: any = await httpDelete(`/involveds/${involvedId}`);

    // eslint-disable-next-line no-constant-condition
    if (response.status === 200 || 204) {
      addToast({ title: 'Excluído com sucesso!', type: 'success' });
      history.go(0);
    } else {
      addToast({ title: 'Não foi possível excluir!', type: 'error' });
      setBtnLoading(false);
      setDisableBtn(false);
      switchs.current?.click();
      labelConfRef.current?.click();
      formRef.current?.reset();
    }
  }, [addToast, history, httpDelete]);

  const handleGoToDetail = useCallback(async (data: any) => {
    setFormData(data);
    formRef.current?.setData({
      ...data,
      document: data.document,
      birthday: formatDate(data.birthday),
      confirmed: [{ value: null, label: '' }],
    });

    labelConfRef.current?.click();
  }, []);

  const handleChangeChecked = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisableBtn(event.target.checked);
  }, []);

  const handleHover = useCallback(() => {
    setBtnLoading(false);
    setDisableBtn(false);
    switchs.current?.click();
    labelConfRef.current?.click();
    formRef.current?.reset();
  }, []);

  const breadcrumbs = {
    icon: UsersIcon,
    links: [
      { title: 'RO-SP / Envolvidos / Listar', path: '/RO/step/1' },
    ],
  };

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <ReturnSalvePlus
          handleReturn={() => history.push('/RO/step/1')}
          handlePlus={() => history.push('/RO/involved/storage')}
        />

        <Grid container justify="flex-start" spacing={1}>
          {involveds?.map((involved) => (
            <Grid item key={involved.id} xs={12} sm={4} md={4}>
              <Card>
                <CardHeader>
                  <WrapperName>
                    <UserIcon />
                    <Involvements>{involved.qualified}
                      { stringOptions(`${involved.type_involvement}`).map((involv: any) => (
                        <span className={involv.value}> - {involv.value}</span>
                      ))}
                    </Involvements>
                  </WrapperName>
                </CardHeader>
                <br />
                <Divider />
                <CardContent>
                  <Grid container spacing={1}>
                    <Involvements>
                      {involved.name}
                    </Involvements>
                    <Flex title="Mãe" xs={12} sm={12} md={12}>
                      {involved.mather}
                    </Flex>
                    <Flex title="Data de Nascimento" xs={6} sm={6} md={6} textAutoSize>
                      {involved.birthday}
                    </Flex>
                    <Flex title="Celular" xs={6} sm={6} md={6}>
                      {involved.phone}
                    </Flex>
                    <Flex title="" xs={12} sm={12} md={12} />

                    <Grid item xs={6} sm={6} md={6}>
                      <Button type="button" onClick={() => { history.push(`/RO/involved/${involved.id}`); }}>
                        Editar &nbsp; <PencilIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <Button type="button" onClick={() => { handleGoToDetail(involved); }}>
                        Excluir &nbsp; <TrashIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <WrapperModal>
          <label ref={labelConfRef} htmlFor="modal-conf" />
          <Modal id="modal-conf" width={520} height="auto">
            <TitleModal>
              <TrashIcon /> Deseja excluir o envolvido?
            </TitleModal>
            <p className={formData?.type_involvement}>
              <b>{formData?.type_involvement.toUpperCase()} </b>
            </p>
            <p>CPF: <b>{formData?.document}</b></p>
            <p>Nome: <b>{formData?.name}</b></p>
            <p>Nascimento: <b>{formatDate(formData?.birthday)}</b></p>
            <p>Mãe: <b>{formData?.mather}</b></p>

            <FormControlLabel
              ref={switchs}
              label={<Red>Confirmo as informações acima.</Red>}
              control={(
                <Switch
                  onChange={handleChangeChecked}
                  color="primary"
                  name="checked"
                  value="false"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            )}
            />
            <span>
              <ButtonMat onClick={handleHover}>
                Cancelar
              </ButtonMat>
              <ButtonMat
                type="button"
                onClick={() => { handleDeleteInvolved(formData?.id); }}
                isLoading={btnLoading}
                disableForce={!disableBtn}
              >
                Excluir
              </ButtonMat>
            </span>

          </Modal>
        </WrapperModal>
        <ModalFactory />
      </Container>
    </Layout>
  );
};

export default Involved;
