import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 16px 0 16px;
  margin: 0;
`;

export const Content = styled.div`
  padding: 16px;
  border-radius: 4px;
  background: #fff;

  form{
    margin-bottom: 16px;
  }

  .MuiDataGrid-cellLeft{
    padding: 0 8px !important;
    span{
      color: var(--color-red)
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;

  form{
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
`;

export const WrapperModal = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
