import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';

import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import { format } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import { GridColDef, ValueGetterParams } from '@material-ui/data-grid';
import AsyncSelect from 'react-select/async';
import getValidationError from '../../../utils/getValidationsErros';
import { formatDateTime } from '../../../utils';
import customStyle from './customStyle';

/** hooks */
import { useHttp } from '../../../hooks/http';

/** componentes */
import Layout from '../../../components/Layout';
import Menu from './Menu';
import {
  WrapperLabel, DatePicker, Select, Button, ButtonIcon,
} from '../../../components/Forms';
import CustomTable from '../../../components/CustomTable';
import { PeopleIcon, FilterIcon } from '../../../styles/icons';
import { Container, Content } from './styles';

/** interfaces */
interface IOptions {
  label: string;
  value: string;
}

const ServiceForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const {
    httpGet, httpPost, loading,
  } = useHttp();
  const [managements, setManagements] = useState([]);
  const [user, setUser] = useState<IOptions | null>(null);
  const [services, setServices] = useState([]);

  const breadcrumbs = {
    icon: PeopleIcon,
    links: [
      { title: 'Serviços', path: '/services' },
      { title: 'Incluir Serviço', path: '' }],
  };

  useEffect(() => {
    async function loadManagements(): Promise<any> {
      const { status, data } = await httpGet('/sectors/managements');
      if (status === 200) {
        const managementParsed = data
          .map((management: any) => ({
            value: management.id,
            label: management.initials,
          }));
        setManagements(managementParsed);
      }
    }

    loadManagements();
  }, [httpGet]);

  const handleFilterServices = useCallback(async () => {
    const allData: any = formRef.current?.getData();
    const filter = {
      ...allData,
      date: format(allData?.started_at, 'yyyy-MM-dd'),
      management_id: allData?.management_id?.value,
      sector_id: allData?.sector_id?.value,
    };

    const { status, data } = await httpGet('services', { params: { ...filter } });
    if (status === 200) {
      setServices(data);
    }
  }, [httpGet]);

  const handleSubmit = useCallback(async (payload: any) => {
    const service = {
      ...payload,
      user_id: user?.value || null,
      management_id: payload?.management_id?.value || null,
      sector_id: null,
    };

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        management_id: Yup.string().ensure().required('a Gerência é obrigatória'),
        user_id: Yup.string().required('o Agente é obrigatório'),
        started_at: Yup.date().typeError('informe uma Data e Hora Válida'),
        ended_at: Yup.date().typeError('informe uma Data e Hora Válida'),
      });

      await schema.validate(service, { abortEarly: false });

      const { status } = await httpPost('services', service);
      if (status === 200) {
        setUser(null);
        handleFilterServices();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [handleFilterServices, httpPost, user]);

  const loadOptions = async (inputValue: any, callback: any): Promise<any> => {
    if (inputValue.length < 2) return;
    const users: any = [];
    const { status, data } = await httpGet(`/services/presence/users/${inputValue}`);

    if (status === 200) {
      data.forEach((item: any) => {
        users.push({
          value: item.id,
          label: `${item.document_secondary} - ${item.name}`,
        });
      });
    }

    callback(users);
  };

  const handleChangeAgent = useCallback((event: any) => {
    setUser(event);
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'menu',
      headerName: ' ',
      sortable: false,
      width: 50,
      renderCell: (params: ValueGetterParams) => (
        <Menu params={params} handleRefresh={handleFilterServices} />
      ),
    },
    { field: 'user', headerName: 'Agente', width: 350 },
    {
      field: 'started_at',
      headerName: 'Início',
      width: 140,
      renderCell: (params: ValueGetterParams) => (
        <>{formatDateTime(params.row?.started_at)}</>
      ),
    },
    {
      field: 'ended_at',
      headerName: 'Término',
      width: 140,
      renderCell: (params: ValueGetterParams) => (
        <>{formatDateTime(params.row?.ended_at)}</>
      ),
    },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content>
          <UForm ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={1} alignItems="flex-end">
              <WrapperLabel label="Gerência" xs={12} sm={12} md={6}>
                <Select
                  name="management_id"
                  options={managements}
                />
              </WrapperLabel>

              <WrapperLabel label="Início" xs={12} sm={6} md={3}>
                <DatePicker
                  name="started_at"
                  timeInputLabel="Hora:"
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeInput
                />
              </WrapperLabel>
              <WrapperLabel label="Término" xs={12} sm={6} md={3}>

                <div style={{ width: 'calc(100% - 48px)' }}>
                  <DatePicker
                    name="ended_at"
                    timeInputLabel="Hora:"
                    dateFormat="dd/MM/yyyy HH:mm"
                    showTimeInput
                  />
                </div>

                <ButtonIcon title="Filtrar serviços" onClick={handleFilterServices}>
                  <FilterIcon />
                </ButtonIcon>
              </WrapperLabel>

              <WrapperLabel label="Agente" xs={12} sm={6} md={9}>
                <div style={{ width: '100%' }}>
                  <AsyncSelect
                    placeholder="ID Funcional ou Nome"
                    noOptionsMessage={() => 'Sem registos'}
                    cacheOptions
                    isClearable
                    loadOptions={loadOptions}
                    onChange={handleChangeAgent}
                    value={user}
                    styles={customStyle}
                  />
                </div>
              </WrapperLabel>
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  type="submit"
                  isLoading={loading}
                  disableForce={!user}
                >
                  Incluir
                </Button>
              </Grid>
            </Grid>

          </UForm>

          <CustomTable
            columns={columns}
            rows={services}
            squash="285px"
          />

        </Content>
      </Container>
    </Layout>
  );
};

export default ServiceForm;
