import styled from 'styled-components';

export const Container = styled.div`
  > button{
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
  }
`;

export const WrapperConfirm = styled.span`
  color: var(--color-red);
`;
