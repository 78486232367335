import React, {
  useRef, useEffect, useState, useCallback,
} from 'react';

import {
  distanceDateTime,
} from '../../../../utils';

/** components */
import Flex, { Grid } from '../../../../components/Flex';
import Modal from '../../../../components/ModalSimple';

import {
  MapIcon, TrashIcon,
} from '../../../../styles/icons';
import {
  Container, WrapperRef, WrapperProcess, WrapperLink, WrapperStatus, WrapperModal,
} from './styles';

/** hooks */
import { useAuth } from '../../../../hooks/auth';
import { useHttp } from '../../../../hooks/http';

/** interface */
import { IOrder, IFuncVoid } from '../../../../interfaces';

interface ICard {
  order: IOrder;
  handleGoToProcess(id: string):void;
  forceLoad?: IFuncVoid;
}

const Card: React.FC<ICard> = ({ order, handleGoToProcess, forceLoad }) => {
  const { iIs } = useAuth();
  const RefLabel = useRef<HTMLLabelElement>(null);
  const { httpDelete } = useHttp();
  const [ref] = order.id.split('-');
  const [showProcess, setShowProcess] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowProcess(false);
    }, 1000 * 5);

    return () => clearTimeout(timeout);
  }, [showProcess]);

  const handleRemoveOperator = useCallback(async (id: string) => {
    const { status } = await httpDelete(`/orders/operator/${id}`);
    if (status === 204) {
      RefLabel.current?.click();
      setShowProcess(false);
      if (forceLoad) forceLoad();
    }
  }, [forceLoad, httpDelete]);

  return (
    <>
      <Container onClick={() => setShowProcess(true)} isWarner={order.status === 'Aguardando'}>
        <Grid container spacing={1}>
          {showProcess && (
          <WrapperProcess onClick={() => handleGoToProcess(order.id)}>
            Processar a consulta?
          </WrapperProcess>
          )}

          <Grid item xs={12} sm={12} md={2}>
            <WrapperRef>
              {/* {order.type === 'vehicle' ? <CarIcon /> : <PersonIcon />} */}
              #{ref.toUpperCase()}
            </WrapperRef>
          </Grid>

          <Flex title="Status" xs={6} sm={6} md={2}>
            {order.status}
          </Flex>

          <Flex title="Solicitado há" xs={6} sm={6} md={2}>
            <WrapperLink>
              {distanceDateTime(order.created_at, order.server_time, false)}
              {order?.address?.latitude && (
              <a
                href={`https://www.google.com.br/maps/search/${order.address.latitude},${order.address.longitude}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MapIcon />
              </a>
              )}
            </WrapperLink>
          </Flex>

          <Flex title="Gerência" xs={2} sm={6} md={2}>
            {order.management.initials}
          </Flex>

          {/* {order.type === 'vehicle' && (
          <Flex title={order.plate ? 'Placa' : 'Chassi'} xs={12} sm={5} md={2} textAutoSize>
            {order.plate ? formatPlate(order.plate) : order.chassi}
          </Flex>
          )}

          {order.type === 'person' && (
          <Flex title={order.document ? 'CPF' : 'Nome'} xs={12} sm={5} md={2} textAutoSize>
            {order.document ? formatCpf(order.document) : order.name}
          </Flex>
          )} */}

          <Flex title="Operador" xs={12} sm={6} md={4}>
            <>
              {order.status === 'Processando'
                ? (
                  <WrapperStatus>
                    <span title={`${order.operator.document} - ${order.operator.name}`}>
                      {order.operator.name}
                    </span>
                    {iIs('opera') && <label ref={RefLabel} htmlFor={order.id}><TrashIcon /></label>}
                  </WrapperStatus>
                )
                : 'aguardando'}
            </>
          </Flex>
        </Grid>
      </Container>
      <Modal id={order.id} width={340} height={200}>
        <WrapperModal>
          <h2>Confirma remover o operador desta consulta?</h2>
          <p>esta ação não poderá ser desfeita.</p>
          <button type="button" onClick={() => handleRemoveOperator(order.id)}>Confirmar</button>
        </WrapperModal>
      </Modal>
    </>
  );
};

export default Card;
