import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form as UForm } from '@unform/web';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import getValidationError from '../../../utils/getValidationsErros';

/** hooks */
import { useHttp } from '../../../hooks/http';

/** components */
import Layout from '../../../components/Layout';
import CustomTable, { GridColDef } from '../../../components/CustomTable';
import Modal from '../../../components/ModalSimple';
import {
  WrapperLabel, Input, TextArea, Button,
} from '../../../components/Forms';
import { GearIcon } from '../../../styles/icons';
import { Container, Content, WrapperModal } from './styles';

const breadcrumbs = {
  icon: GearIcon, links: [{ title: 'Cproeis Nome', path: '' }],
};

const CproeisName: React.FC = () => {
  const { httpGet, httpPut } = useHttp();
  const formRef = useRef<FormHandles>(null);
  const RefLabel = useRef<HTMLLabelElement>(null);
  const [Managements, setManagements] = useState([]);
  const [management, setManagement] = useState<any>(null);

  const columns: GridColDef[] = [
    { field: 'initials', headerName: 'Gerência', width: 200 },
    { field: 'cproeis_name', headerName: 'CPROEIS nomes', width: 800 },
  ];

  useEffect(() => {
    async function load(): Promise<void> {
      const { status, data } = await httpGet('managements/all');
      if (status === 200) {
        setManagements(data);
      }
    }

    load();
  }, [httpGet]);

  const handleRowSelected = useCallback(async (id: any) => {
    RefLabel.current?.click();
    const managementFiltered: any = Managements
      .filter((management:any) => parseInt(management.id, 10) === parseInt(id, 10));

    setManagement(managementFiltered[0]);
    formRef.current?.setData(managementFiltered[0]);
  }, [Managements]);

  const handleSubmit = useCallback(async (payload: any) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        //
      });

      await schema.validate(payload, { abortEarly: false });

      const { status, data } = await httpPut(`managements/cproeis-name/${management.id}`, payload);
      if (status === 200) {
        const sectorsUpdated: any = [...Managements];
        const sectorIndex = Managements.findIndex((item: any) => item.id === data.id);
        sectorsUpdated[sectorIndex] = data;
        setManagements(sectorsUpdated);
        RefLabel.current?.click();
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erros = getValidationError(err);
        formRef.current?.setErrors(erros);
      }
    }
  }, [httpPut, Managements, management]);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Container>
        <Content>
          <CustomTable
            columns={columns}
            rows={Managements}
            squash="116px"
            onSelectionModelChange={(selection) => {
              handleRowSelected(selection.selectionModel[0]);
            }}
          />
        </Content>
        <label ref={RefLabel} htmlFor="modalForm" style={{ opacity: 0 }}>Abrir</label>
        <Modal id="modalForm" height={310}>
          <WrapperModal>
            <UForm ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <WrapperLabel label="Gerência" xs={12}>
                  <Input name="initials" readOnly />
                </WrapperLabel>
                <WrapperLabel label="CPROEIS Eventos (separe por virgulas se houver mais de um evento)" xs={12} md={12}>
                  <TextArea name="cproeis_name" row={4} />
                </WrapperLabel>
              </Grid>
              <Grid container justify="flex-end">
                <Grid item xs={12} md={4}>
                  <Button type="submit">Salvar</Button>
                </Grid>
              </Grid>
            </UForm>
          </WrapperModal>
        </Modal>
      </Container>
    </Layout>
  );
};

export default CproeisName;
