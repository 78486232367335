import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';

/** componentes */
import Address from 'pages/Orders/Address';
import Layout from '../../../components/Layout';
import { HomeGearFillIcon } from '../../../styles/icons';

import CeperjFormSupervision from './CeperjFormSupervision';

import { validateCellPhone } from '../../../utils';

import { Container, WrapperAddress } from './styles';

/** hooks */
import { useAuth } from '../../../hooks/auth';
import { useHttp } from '../../../hooks/http';

interface ICoord {
  lat: number;
  lng: number;
}

interface IService {
  id: string;
}

const CeperjSupervision: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { httpGet, loading } = useHttp();
  const [coord, setCoords] = useState<ICoord>({} as ICoord);
  const [service, setService] = useState<IService | null>(null);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    async function load(): Promise<any> {
      const management_id = user?.allocation?.management_id;
      const { status, data } = await httpGet(`/orders/auth/${management_id}`);
      if (status === 200) {
        setService(data);
        return;
      }
      setService(null);
      history.push('/dashboard');
    }

    load();
  }, [history, httpGet, user]);

  useEffect(() => {
    async function loadUser(): Promise<void> {
      const { data } = await httpGet('/me');
      if (!validateCellPhone(data?.phone, true)) {
        history.push('/profile');
      }
    }

    loadUser();
  }, []);// eslint-disable-line

  const breadcrumbs = {
    icon: HomeGearFillIcon,
    links: [{ path: '', title: 'Ceperj Supervisão' }],
  };

  return (
    <>
      <Layout breadcrumbs={breadcrumbs} loading={loading}>
        <Container>
          {id ? <CeperjFormSupervision /> : (
            <>
              {!coord.lat && service && (
                <WrapperAddress>
                  <h3><span>Selecione o </span>local da abordagem</h3>
                  <Address
                    handleCoord={(coord: ICoord) => {
                      setCoords(coord);
                    }}
                  />
                </WrapperAddress>
              )}
              {(coord.lat) && (
                <CeperjFormSupervision coord={coord} />
              )}
            </>
          )}
        </Container>
      </Layout>

    </>
  );
};

export default CeperjSupervision;
