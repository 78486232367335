import React, { useCallback, useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ValueGetterParams } from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';
import { Container, WrapperConfirm } from './styles';
import { DotVertIcon } from '../../../styles/icons';

/** hooks */
import { useAuth } from '../../../hooks/auth';
import { useHttp } from '../../../hooks/http';

/** interface */
interface IAction {
  params: ValueGetterParams;
  handleMissing(user_id: string | number): Promise<void>;
  handleChangeSector(user_id: string | number): Promise<void>;
  handleDetail(user_id: string | number): Promise<void>;
  handleRemoveService(id: any):void;
}

const Actions: React.FC<IAction> = ({
  params, handleMissing, handleChangeSector, handleDetail, handleRemoveService,
}) => {
  const { iCan } = useAuth();
  const history = useHistory();
  const { httpDelete, loading } = useHttp();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    const setTimeoutId = setTimeout(() => {
      setConfirm(false);
    }, 1000 * 5);

    return () => {
      clearTimeout(setTimeoutId);
    };
  }, [confirm]);

  const handleClose = ():void => {
    setAnchorEl(null);
  };

  const handleRemove = useCallback(async () => {
    if (confirm && !loading) {
      const { status } = await httpDelete(`/services/${params?.row?.id}`);
      if (status === 204) {
        handleClose();
        handleRemoveService(params?.row?.id);
      }
    }
    setConfirm(!confirm);
  }, [confirm, loading, handleRemoveService, httpDelete, params]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>):void => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Container>
      <button type="button" onClick={handleClick}>
        <DotVertIcon />
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          handleClose();
          handleDetail(params?.row?.id);
        }}
        >
          Ver Detalhes
        </MenuItem>
        {!params?.row.missing_id && (
          <MenuItem onClick={() => {
            handleClose();
            handleChangeSector(params?.row?.id);
          }}
          >
            Trocar de Setor
          </MenuItem>
        )}
        {!params?.row.missing_id && (
          <MenuItem onClick={() => {
            handleClose();
            handleMissing(params?.row?.id);
          }}
          >
            Comunicar Falta
          </MenuItem>
        )}
        {iCan('u-service') && (
          <MenuItem onClick={() => history.push(`/services/update/${params?.row?.id}`)}>
            Editar
          </MenuItem>
        )}
        {iCan('d-service') && (
        <MenuItem onClick={handleRemove}>
          { loading && 'Processando...'}
          { !loading && !confirm && 'Remover'}
          { !loading && confirm && <WrapperConfirm> Confirma? </WrapperConfirm>}
        </MenuItem>
        )}
      </Menu>
    </Container>
  );
};

export default Actions;
