import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

const toastTypeVariations = {
  info: css`
    background: #ebf8ff;
    color: #3172b7;
  `,
  success: css`
    background: #66fffa;
    color: #2a656a;
  `,
  error: css`
    background: #fddede;
    color: #c53030;
  `,
  warning: css`
    background: #ffe8ba;
    color: #e37800;
  `,
};

interface ContainerProps{
  type?: 'info' | 'success' | 'error' | 'warning';
  description: number;
}

export const Container = styled(animated.div)<ContainerProps>`
  width: 288px;


  position: relative;
  padding: 16px 30px 16px 16px;
  border-radius: 8px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;

  & + div{
    margin-top: 8px;
  }

  background: #ebf8ff;
  color: #3172b7;

  ${(props) => toastTypeVariations[props.type || 'info']}

  > svg{
    margin: 4px 12px 0 0;
  }

  div{
    flex: 1;

  }

  p{
    margin-top: 4px;
    font-size: 14px;
    opacity: 0.8;
    line-height: 20px;
  }

  button{
    position: absolute;
    right: 8px;
    top: 19px;
    border: 0;
    background: transparent;
    color: inherit;
  }

  ${(props) => !props.description && css`
      align-items: center;
      svg{
        margin-top: 0;
      }
  `}
`;

export const Link = styled.a`
  display: block;
  text-decoration: none;
  color: #333;
  padding-top: 8px;

  &:hover{
    color:#000;
  }
`;
