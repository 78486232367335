import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

`;

export const WrapperTarget = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg{
      font-size: 32px;
      color: var(--color-red);
      background-color: rgba(255,255,255, 0.7);
      padding: 4px;
      border-radius: 50%;
      z-index: 999;
    }
`;
