import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin: 16px;

  footer{
    color: var(--color-secondary);
    margin: 8px 0;
  }

`;

export const WrapperAccordion = styled.div`
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0 8px;
  margin-bottom: 8px;

  .MuiAccordionDetails-root{
    padding: 8px 0;
  }
`;

export const Title = styled.div`
  border-top: 1px solid var(--color-border);
  margin: 0 -16px;
  margin-top: 8px;
  padding: 8px;
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  h5{
    font-size: 1em;
    font-weight: 500;
  }
  span{
    display: flex;
    align-items: center;
    font-weight: 700;
  }
`;