import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 5px;
  background: #fff;
  height: 100%;
  width: 100%;

  margin: 5px;

  footer{
    color: var(--color-secondary);
    margin: 8px 0;
  }
`;
