import React, { useState, useCallback, useEffect } from 'react';
import Layout from 'components/Layout';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import HeaderSavePlus from '../../components/Headers/SavePlus';
import Tabs, { TabsItem } from '../../components/Tabs';
import { Container } from './styles';
import { ClipboardCopyIcon } from '../../styles/icons';
import ProfileModel from './ProfileModel';
import { useHttp } from 'hooks/http';
import { IModel } from 'interfaces';

const breadcrumbs = {
    icon: ClipboardCopyIcon,
    links: [
        { path: '/makes', title: 'Modelos' },
        { path: '', title: 'Modelo' },
    ],
};

const FormModel: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { httpGet, loading } = useHttp();
    const [models, setModels] = useState({} as IModel);
    const history = useHistory();

    useEffect(() => {
        async function loadModels(): Promise<void> {
            const { data } = await httpGet(`/modelos/${id}`);
            setModels(data);
        }

        id && loadModels();
    }, [id]);// eslint-disable-line

    const handlePlus = useCallback(() => {
        history.push('/makes');
        setModels({} as IModel);
    }, [history]);

    return (
        <Layout breadcrumbs={breadcrumbs} loading={loading}>
            <Container>
                <HeaderSavePlus
                    hiddenButton={!id}
                    handlePlus={handlePlus}
                >
                    {id ? `Editar ${models.name}` : 'Novo Modelo'}
                </HeaderSavePlus>

                <Tabs>
                    <TabsItem title="Dados" show>
                        {id && <ProfileModel model={models} />}
                        {!id && <ProfileModel model={{} as IModel} />}
                    </TabsItem>
                </Tabs>

            </Container>
        </Layout>
    );
}

export default FormModel;