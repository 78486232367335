const formatDateDash = (date : string | number | Date): string => {
  if (date) {
    if (typeof date !== 'string') {
      return 'erro';
    }

    const dt = new Date(date);
    const mnth = (`0${dt.getMonth() + 1}`).slice(-2);
    const day = (`0${dt.getDate()}`).slice(-2);

    return [dt.getFullYear(), mnth, day].join('-');
  }

  return 'null';
};

export default formatDateDash;
