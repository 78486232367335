import React from 'react';
import { useHistory } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Flex, { Grid } from '../../../../components/Flex';
import {
  Menu, MenuItem, DotButton, ItemIcon,
} from '../../../../components/CustomMenu';
import { Container, WrapperMenu, Red, Green } from './styles';
import { PencilIcon, InfoIcon } from '../../../../styles/icons';
import { formatDate } from 'utils';

const Card: React.FC<any> = ({ mrcol }) => {
  const history = useHistory();

  return (
    <Container>
      <Grid container spacing={1}>
        <WrapperMenu>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <>
                <DotButton {...bindTrigger(popupState)} />
                <Menu {...bindMenu(popupState)}>
                  {mrcol?.mrcols === null ? (
                    <MenuItem
                      onClick={() => { history.push(`/mrcols/edit/${mrcol.id}`); }}
                      style={{ width: 150 }}
                    >
                      <ItemIcon icon={<PencilIcon />} title="Editar" />
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() => { history.push(`/mrcols/detail/${mrcol.id}`); }}
                      style={{ width: 150 }}
                    >
                      <ItemIcon icon={<InfoIcon />} title="Mostar" />
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
          </PopupState>
        </WrapperMenu>
        <Flex title="Chefe Civil" xs={6} md={4}>
          {mrcol.user.name}
        </Flex>
        <Flex title="Equipe" xs={6} md={2}>
          {mrcol.team}
        </Flex>
        <Flex title="Data" xs={6} md={4}>
          {formatDate(String(mrcol.operation_date).split('T')[0])}
        </Flex>
        {mrcol?.mrcols === null ? (
          <Flex title="Status" xs={6} md={2}>
            <Green>Aberto</Green>
          </Flex>
        ) : (<Flex title="Status" xs={6} md={2}>
          <Red>Fechado</Red>
        </Flex>)}
      </Grid>
    </Container>
  );
};

export default Card;
